<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/games/history?count=10&page=1">Game history</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{gameInstance.name}}</span>
      </breadcrumb-item>
    </breadcrumb>

    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col-lg-12">
        <card class="card-subcategories" card-body-classes="table-full-width">
          <img slot="image" class="card-img-bottom fixed-size-img" :src="gameInstance.url"  alt="Card image cap"/>
          <div class="row">
            <div class="col">
              <h3 slot="header" class="card-title">{{gameInstance.name}} - {{getDate(gameInstance.booking_date)}}
                <el-tooltip content="Edit game"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button v-if="!editingGame && hasPermissions('game_history_update')"
                               @click.native="editGameInstance()"
                               class="like btn-link"
                               type="warning"
                               size="sm"
                               icon>
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Delete game"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button v-if="!editingGame && hasPermissions('game_history_delete')"
                               @click.native="deleteGameInstance()"
                               class="like btn-link"
                               type="danger"
                               size="sm"
                               icon>
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
                <base-button v-if="editingGame"
                             @click.native="saveGameInstance()"
                             type="success"
                             size="sm"
                             simple
                             class="ml-3"
                >Save
                </base-button>
                <base-button v-if="editingGame"
                             @click.native="cancelEditingGame()"
                             type="info"
                             size="sm"
                             simple
                             class="ml-2"
                >Cancel
                </base-button>
              </h3>
            </div>
            <div class="col text-right" v-if="gameInstance.transaction_id">
              <p>Transaction #{{gameInstance.order_number}}
                  <router-link tag="a" :to="'/purchases/transactions/details?id='+ gameInstance.transaction_id">
                    <i class="tim-icons el-icon-top-right"></i>
                  </router-link>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row" v-if="!editingGame">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-calendar-60"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Booking Time</p>
                        <h3 v-if="!editingGame" class="card-title">{{getTime(gameInstance.booking_start_time)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="editingGame">
                  <div class="col">
                    <base-input label="Booking time" :error="errs.booking_time">
                      <el-time-picker
                        v-model="editing.booking_time"
                        :format="picker_time_format" placeholder="HH:MM"
                      ></el-time-picker>
                    </base-input>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row" v-if="!editingGame" style="margin-bottom: -8px">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-triangle-right-17"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers" >
                      <slot>
                        <p class="card-category">Start Time</p>
                        <h3 style="margin-bottom: 0" class="card-title">{{getTime(gameInstance.start_time)}}</h3>
                        <small  class="card-category">{{getDate(gameInstance.start_time)}}</small>
                      </slot>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="editingGame">
                  <div class="col">
                    <base-input label="Start date" :error="errs.start_time">
                      <el-date-picker :format="day_of_week + date_format_cal"
                        v-model="editing.start_date"
                      ></el-date-picker>
                    </base-input>
                    <base-input label="Start time" :error="errs.start_time">
                      <el-time-picker
                        v-model="editing.start_time"
                        :format="picker_time_format" placeholder="HH:MM"
                      ></el-time-picker>
                    </base-input>
                  </div>
                </div>
              </card>
            </div>


            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row" v-if="!editingGame" style="margin-bottom: -8px">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-trophy"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">End Time</p>
                        <h3 class="card-title" style="margin-bottom: 0">{{getTime(gameInstance.end_time)}}</h3>
                        <small  class="card-category">{{getDate(gameInstance.end_time)}}</small>
                      </slot>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="editingGame">
                  <div class="col">
                    <base-input label="End date" :error="errs.end_time">
                      <el-date-picker :format="day_of_week + date_format_cal"
                        v-model="editing.end_date"
                      ></el-date-picker>
                    </base-input>
                    <base-input label="End time" :error="errs.end_time">
                      <el-time-picker
                        v-model="editing.end_time"
                        :format="picker_time_format" placeholder="HH:MM"
                      ></el-time-picker>
                    </base-input>
                  </div>
                </div>

              </card>
            </div>
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-time-alarm"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Total Time</p>
                        <h3 class="card-title">{{getTotalTime(puzzleInstances)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <h4>Game completed &nbsp;
                <span v-if="!editingGame && gameInstance.completed" class="text-success">
                 <i class="text-success fas fa-check"></i>
                </span>
                <span v-if="!editingGame && !gameInstance.completed" class="text-danger">
                 <i class="fas fa-times"></i>
                </span>
                <input type="checkbox" v-if="editingGame" v-model="gameInstance.completed"/>
              </h4>
              <h4 v-if="puzzleInstances.length > 0">Percent completion &nbsp;
                <span class="text-success">
                <strong>{{getPercentCompletion(puzzleInstances)}}</strong>
              </span>

              </h4>
              <h4 v-if="!exceed_time">Time remaining &nbsp;
                <span  class="text-success">
                 <strong>{{getMinutesRemaining(gameInstance)}}</strong>
              </span>
              </h4>
              <h4 v-if="exceed_time">Extra time spent  &nbsp;
                <span  class="text-danger">
                 <strong>{{getMinutesRemaining(gameInstance)}}</strong>
              </span>
              </h4>


              <!--<h4>Corporate booking &nbsp;-->
              <!--<span v-if="gameInstance.is_corporate_booking" class="text-success">-->
              <!--<i class="text-success fas fa-check"></i>-->
              <!--</span>-->
              <!--<span v-if="!gameInstance.is_corporate_booking" class="text-danger">-->
              <!--<i class="fas fa-times"></i>-->
              <!--</span>-->
              <!--</h4>-->
            </div>
            <div class="col-lg-3">
              <h4>Total clues &nbsp;
                <span class="text-success">
                <strong>{{getTotalClues(puzzleInstances)}}</strong>
              </span>
              </h4>
              <h4>Total breakages &nbsp;
                <span class="text-success">
                <strong>{{getTotalBreakages(puzzleInstances)}}</strong>
              </span>

              </h4>
              <div v-if="!editingGame">
                <h4 >Game master &nbsp;
                  <span class="text-success">
                    <strong>{{gameInstance.first_name}} {{gameInstance.last_name}} </strong>
                </span>
                </h4>
              </div>
              <div v-else>
                <base-input>
                  <el-select
                    v-model="selectedGameMaster"
                    class="select-primary"
                    name="group"
                    placeholder="Game master"
                    value-key="id"
                  >
                    <el-option
                      v-for="u in company_users"
                      class="select-primary"
                      :label="u.first_name + u.last_name + ' (' + u.email + ')'"
                      :value="{id: u.id, first_name: u.first_name, last_name: u.last_name, email: u.email}"
                      :key="'A' + u.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

            </div>
            <div class="col-lg-6">
              <h4>Notes</h4>
              <div class="row" v-if="!editingGame">
                <div class="col-lg-6">
                  <p>
                    <span class="text-primary">Pre-game notes: </span>
                    <span v-if="gameInstance.pre_game_notes"><br>{{gameInstance.pre_game_notes}}</span>
                    <span v-if="!gameInstance.pre_game_notes"> N/A</span>
                  </p>
                </div>
                <div class="col-lg-6">
                  <p>
                    <span class="text-primary">Post-game notes: </span>
                    <span v-if="gameInstance.post_game_notes"><br>{{gameInstance.post_game_notes}}</span>
                    <span v-if="!gameInstance.post_game_notes"> N/A</span>
                  </p>
                </div>
              </div>
              <div class="row" v-if="editingGame">
                <div class="col-lg-6">
                  <p>
                    <span class="text-primary">Pre-game notes: </span>
                    <base-input>
                      <textarea class="form-control" placeholder="Notes..." v-model="gameInstance.pre_game_notes" rows="2"></textarea>
                    </base-input>
                  </p>
                </div>
                <div class="col-lg-6">
                  <p>
                    <span class="text-primary">Post-game notes: </span>
                    <base-input>
                      <textarea class="form-control" placeholder="Notes..." v-model="gameInstance.post_game_notes" rows="2"></textarea>
                    </base-input>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </card>

      </div>

      <div ref="puzzles" class="col-md-12">
        <card v-if="puzzleInstances.length > 0" card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
                <h4  slot="header" class="card-title">Puzzles
                  <el-tooltip content="Edit puzzles"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button v-if="!editingPuzzles && hasPermissions('game_history_update')"
                                 @click.native="editPuzzles()"
                                 class="like btn-link"
                                 type="warning"
                                 size="sm"
                                 icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <base-button  v-if="editingPuzzles"
                                @click.native="savePuzzleInstance()"
                                type="success"
                                size="sm"
                                simple
                                class="ml-3"
                  >Save
                  </base-button>
                  <base-button  v-if="editingPuzzles"
                                @click.native="cancelEditingPuzzles()"
                                type="info"
                                size="sm"
                                simple
                                class="ml-2"
                  >Cancel
                  </base-button>
                </h4>
            </div>
          </div>

          <!--Medium and large screens-->
          <div class="row d-none d-sm-block">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="puzzleInstances"
                       :fields="fields">
                <template v-slot:cell(puzzle_name)="row">
                  <span class="text-warning">{{row.item.puzzle_name}}</span>
                </template>
                <template v-slot:cell(completed)="row">
                  <i v-if="!editingPuzzles && row.item.is_completed" class="tim-icons icon-check-2 text-success"></i>
                  <i v-if="!editingPuzzles && !row.item.is_completed" class="tim-icons icon-simple-remove text-danger"></i>
                  <span v-if="editingPuzzles">
                  <input type="checkbox" v-model="row.item.is_completed"/>
                </span>
                </template>
                <template v-slot:cell(solve_time)="row">
                  <span v-if="!editingPuzzles && row.item.is_completed">{{getTime(row.item.solve_time)}}</span>
                  <span v-if="!editingPuzzles && !row.item.is_completed">-</span>
                  <span v-if="editingPuzzles && row.item.is_completed == 0">-</span>
                  <span v-if="editingPuzzles && row.item.is_completed == 1">

                    <div class="row">
                      <div class="col">
                        <base-input :error="errs.booking_time">
                          <el-time-picker v-on:input="validateTime(row.item.solve_time, gameInstance.start_time)"
                            v-model="row.item.solve_time"
                            :format="picker_time_format" placeholder="HH:MM"
                          ></el-time-picker>
                        </base-input>
                      </div>
                    </div>

<!--                    <div class="row mt-2">-->
<!--                      <div class="ml-2" style="width: 30%">-->
<!--                        <el-select-->
<!--                          class="select-primary"-->
<!--                          size="mini"-->
<!--                          placeholder="Hours"-->
<!--                          v-model="puzzleHours[row.index]"-->
<!--                        >-->
<!--                          <el-option-->
<!--                            v-for="(h, i) in hourOptions"-->
<!--                            class="select-primary"-->
<!--                            :value="h"-->
<!--                            :key="'F' + i"-->
<!--                          >-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </div>-->
<!--                      <div class="ml-2" style="width: 30%">-->
<!--                        <el-select-->
<!--                          class="select-primary"-->
<!--                          size="mini"-->
<!--                          placeholder="Minutes"-->
<!--                          v-model="puzzleMinutes[row.index]"-->
<!--                        >-->
<!--                          <el-option-->
<!--                            v-for="(m, i) in minuteOptionsPuzzles"-->
<!--                            class="select-primary"-->
<!--                            :value="m"-->
<!--                            :key="'B' + i"-->
<!--                          >-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </div>-->
<!--                      <div class="ml-2" style="width: 20%">-->
<!--                        <el-select-->
<!--                          class="select-primary"-->
<!--                          size="mini"-->
<!--                          placeholder="AM/PM"-->
<!--                          v-model="puzzleTimeOfDay[row.index]"-->
<!--                        >-->
<!--                          <el-option-->
<!--                            v-for="(a, i) in timeOfDayOptions"-->
<!--                            class="select-primary"-->
<!--                            :value="a"-->
<!--                            :key="'K' + i"-->
<!--                          >-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </div>-->
<!--                    </div>-->
                </span>
                </template>
                <template v-slot:cell(time_since_start)="row">
                  <span>{{getMinutesSinceStart(row.item)}}</span>
                </template>
                <template v-slot:cell(hint_number)="row">
                  <span v-if="!editingPuzzles">{{row.item.hints_given_count}}</span>
                  <span v-if="editingPuzzles">
                    <el-input-number size="mini"
                                     :min="0"
                                     v-model="row.item.hints_given_count">
                    </el-input-number>
                  <!--<base-input-->
                    <!--type="number"-->
                    <!--name="hints"-->
                    <!--placeholder="Hints"-->
                    <!--v-model="row.item.hints_given_count">-->
                  <!--</base-input>-->
                </span>
                </template>
                <template v-slot:cell(hints_given)="row">
                  <span v-if="!editingPuzzles">{{row.item.hints_given_description}}</span>
                  <span v-if="editingPuzzles">
                  <textarea
                    class="form-control"
                    placeholder="List the hints you gave..."
                    name="notes"
                    v-model="row.item.hints_given_description"
                    rows="3"></textarea>
                </span>
                </template>
                <template v-slot:cell(malfunctions)="row">
                  <span v-if="!editingPuzzles && row.item.breakage_occurred">{{row.item.breakage_description}}</span>
                  <span v-if="!editingPuzzles && !row.item.breakage_occurred">None</span>
                  <span v-if="editingPuzzles">
                  <textarea
                    class="form-control"
                    placeholder="Describe what broke..."
                    name="notes"
                    v-model="row.item.breakage_description"
                    rows="3"></textarea>
                </span>
                </template>
              </b-table>
            </div>
          </div>
          <!--Mobile-->
          <div class="row mt-3 d-block d-sm-none">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="puzzleInstances"
                       :fields="mobile_fields">
                <template v-slot:cell(puzzles)="row">
                  <div class="row">
                    <div class="col-12">
                      <span class="text-warning">{{row.item.puzzle_name}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Completed? &nbsp;
                      <i v-if="!editingPuzzles && row.item.is_completed" class="tim-icons icon-check-2 text-success"></i>
                      <i v-if="!editingPuzzles && !row.item.is_completed" class="tim-icons icon-simple-remove text-danger"></i>
                      <span v-if="editingPuzzles">
                        <input type="checkbox" v-model="row.item.is_completed"/>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Solve time: &nbsp;
                      <span v-if="!editingPuzzles && row.item.is_completed == 1">{{getTime(row.item.solve_time)}}</span>
                      <span v-if="!editingPuzzles && row.item.is_completed == 0">-</span>
                      <span v-if="editingPuzzles && row.item.is_completed == 0">-</span>
                      <span v-if="editingPuzzles && row.item.is_completed == 1">

                        <div class="row mt-2">
                          <div class="ml-2" style="width: 30%">
                            <el-select
                              class="select-primary"
                              size="mini"
                              placeholder="Hours"
                              v-model="puzzleHours[row.index]"
                            >
                              <el-option
                                v-for="(h, i) in hourOptions"
                                class="select-primary"
                                :value="h"
                                :key="'G' + i"
                              >
                              </el-option>
                            </el-select>
                          </div>
                            <div class="ml-2" style="width: 30%">
                              <el-select
                                class="select-primary"
                                size="mini"
                                placeholder="Minutes"
                                v-model="puzzleMinutes[row.index]"
                              >
                                <el-option
                                  v-for="(m, i) in minuteOptionsPuzzles"
                                  class="select-primary"
                                  :value="m"
                                  :key="'C' + i"
                                >
                                </el-option>
                              </el-select>
                            </div>
                            <div class="ml-2" style="width: 20%">
                              <el-select
                                class="select-primary"
                                size="mini"
                                placeholder="AM/PM"
                                v-model="puzzleTimeOfDay[row.index]"
                              >
                                  <el-option
                                    v-for="(a, i) in timeOfDayOptions"
                                    class="select-primary"
                                    :value="a"
                                    :key="'H' + i"
                                  >
                                  </el-option>
                                </el-select>
                              </div>
                            </div>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Time since start: &nbsp;
                      <span>{{getMinutesSinceStart(row.item)}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Number of hints: &nbsp;
                      <span v-if="!editingPuzzles">{{row.item.hints_given_count}}</span>
                      <span v-if="editingPuzzles">
                        <el-input-number size="mini"
                                         :min="0"
                                         v-model="row.item.hints_given_count">
                        </el-input-number>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Hints given: &nbsp;
                      <span v-if="!editingPuzzles">{{row.item.hints_given_description}}</span>
                      <span v-if="editingPuzzles">
                        <textarea
                          class="form-control"
                          placeholder="List the hints you gave..."
                          name="notes"
                          v-model="row.item.hints_given_description"
                          rows="3"></textarea>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Breakages: &nbsp;
                      <span v-if="!editingPuzzles && row.item.breakage_occurred">{{row.item.breakage_description}}</span>
                      <span v-if="!editingPuzzles && !row.item.breakage_occurred">None</span>
                      <span v-if="editingPuzzles">
                        <textarea
                          class="form-control"
                          placeholder="Describe what broke..."
                          name="notes"
                          v-model="row.item.breakage_description"
                          rows="3"></textarea>
                      </span>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </card>



        <!--Photos-->
        <div ref="photos" class="row">
          <div class="col">
            <card card-body-classes="table-full-width">
              <div class="row">
                <div class="col">
                  <h4 slot="header" class="card-title">Team and Photos
                  </h4>
                </div>
              </div>
              <div class="row" v-if="photos_not_enabled">
                <div class="col">
                  <base-button simple size="sm" type="danger" v-on:click="goToBilling()" v-if="hasPermissions('billing_view')">
                    <i class="tim-icons mr-1 icon-alert-circle-exc"></i> Enable photo feature for a full experience
                  </base-button>
                  <p class="text-danger" v-else><i class="tim-icons mr-1 icon-alert-circle-exc"></i> Please contact a site administrator to activate this feature.</p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <el-tooltip content="Take another photo"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button :disabled="photos_not_enabled" style="font-size: large" link icon  size="sm" v-on:click="modals.upload_photo = true" simple type="success">
                      <i class="tim-icons icon-camera-18"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Remove photo from game"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button :disabled="photos_not_enabled" style="font-size: large" link icon v-if="photo.id != ''" size="sm" v-on:click="deletePhoto()" simple type="danger">
                      <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
<!--              <div class="row mt-2" v-if="overlays.length > 0">-->
<!--                <div class="col-3">-->
<!--                  <base-input label="Add overlay">-->
<!--                    <el-select-->
<!--                      v-model="selectedOverlay"-->
<!--                      class="select-primary"-->
<!--                      name="game"-->
<!--                      placeholder="Apply overlay"-->
<!--                      v-on:change="saveOverlay()"-->
<!--                      value-key="id"-->
<!--                    >-->
<!--                      <el-option-->
<!--                        v-for="o in overlays"-->
<!--                        class="select-primary"-->
<!--                        :label="o.name"-->
<!--                        :value="{id: o.id, name: o.name, components: o.components, overlay_url: o.overlay_url}"-->
<!--                        :key="o.id"-->
<!--                      ></el-option>-->
<!--                    </el-select>-->
<!--                  </base-input>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row mb-2"  v-if="selectedOverlay">-->
<!--                <div class="col">-->
<!--                  <base-button simple size="sm" v-on:click="clearOverlay()" type="primary" class="ml-1">-->
<!--                    Clear overlay</base-button>-->
<!--                </div>-->
<!--              </div>-->

              <div class="row">
                <div class="col-lg-4 text-center" v-if="photos.length > 0">
                  <el-carousel v-on:change="setActivePhoto" :autoplay="false" indicator-position="outside">
                    <el-carousel-item v-for="(item, index) in photos" :key="'CS' + index">
                      <img v-if="item.overlay_url" :id="'img' + index" :src="item.overlay_url" crossorigin="anonymous"
                           style="max-height: 100%; max-width: 100%; border-radius: 0px" >
                      <img v-else :id="'img' + index" :src="item.url"  crossorigin="anonymous"
                           style="max-height: 100%; max-width: 100%; border-radius: 0px" >
<!--                      <img style="border: 3px solid white; object-fit: contain; width: 100%; height: 300px" :src="item.url">-->
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="col-lg-4" v-else>
                  <div class="font-icon-detail">
                    <span style="font-size: 3em">
                      <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    <h4 class="mt-3">There are no photos assigned to this game.</h4>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div>
                    <base-button v-if="team.length > 0 && !team[0].name && !creating_team_name"
                                 @click.native="createTeamName()"
                                 type="success"
                                 simple
                                 size="sm"
                                 class="mb-3"
                    >
                      Create team name
                    </base-button>
                    <base-input label="Team name" placeholder="Team name" style="width: 50%" class="mt-2" v-if="creating_team_name" v-model="team[0].name"></base-input>
                    <base-button v-if="creating_team_name"
                                 @click.native="confirmTeamNameCreate()"
                                 type="success"
                                 simple
                                 size="sm"
                                 class="mb-3"
                    >
                      Save
                    </base-button>
                    <h5 v-if="team.length > 0 && team[0].name && !creating_team_name">
                      Team name: &nbsp; <span class="text-warning" v-if="!editing_team_name" type="primary">{{team[0].name}}</span>
                      <base-input style="width: 50%" class="mt-2" v-if="editing_team_name" v-model="team[0].name"></base-input>
                      <el-tooltip v-if="!editing_team_name" content="Edit team name"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="editTeamName()"
                          class="like btn-link"
                          type="success"
                          size="sm"
                          icon>
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </el-tooltip>

                      <base-button v-if="editing_team_name"
                                   @click.native="confirmTeamName()"
                                   type="success"
                                   simple
                                   size="sm"
                      >
                        Save
                      </base-button>


                    </h5>

                    <b-table v-if="team.length > 0" striped hover :items="team"
                             :fields="teamFields">
                      <template v-slot:cell(players)="row">
                        <div class="row">
                          <div class="col-lg-6">
                            <span class="text-warning">{{row.item.first_name}} {{row.item.last_name}}</span>
                            <p v-if="row.item.is_minor == 0" class="small">{{row.item.email}}</p>
                             <badge class="ml-2" type="primary" v-if="row.item.is_minor == 1">Minor</badge>
                          </div>
                          <div v-if="row.item.is_minor == 0" class="col-lg-6">
                            <el-tooltip content="View customer details"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                              <base-button
                                @click="viewCustomerDetails(row.item)"
                                simple
                                type="info"
                                link
                                style="font-size: large"
                              > <i class="tim-icons icon-badge"></i>
                              </base-button>
                            </el-tooltip>
                            <el-tooltip v-if="photos.length > 0 && email_active == 1" content="Email photo to customer"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                              <base-button :disabled="photos_not_enabled"
                                @click="emailPhoto(row.item)"
                                simple
                                type="success"
                                link
                                style="font-size: large"
                              > <i class="tim-icons icon-email-85"></i>
                              </base-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </template>
                    </b-table>

                    <div class="row" v-if="photos.length > 0">
                      <div class="col">
                        <badge class="ml-2" v-if="photo.is_emailed_to_group" type="success">Emailed to group x{{photo.is_emailed_to_group}}</badge>
                        <badge class="ml-2" v-if="!photo.is_emailed_to_group" type="default">Not emailed to group</badge>

                      </div>
                    </div>
                    <div class="row" >
                      <div class="col">
                        <base-button :disabled="photos_not_enabled" v-if="photos.length > 0" size="sm" simple class="ml-2" type="warning" v-on:click="emailPhoto()">
                          <i class="tim-icons icon-email-85 mr-1"></i>Email photo to everyone</base-button>
                        <base-button v-show="hasPermissions('game_history_update')" size="sm" simple class="ml-2" type="warning" v-on:click="addPlayer()"> + Add a player to team</base-button>

                      </div>
                    </div>

                    <b-table class="mt-3" v-if="non_participants.length > 0" striped hover :items="non_participants"
                             :fields="['non_participating_guardians']">
                      <template v-slot:cell(non_participating_guardians)="row">
                        <div class="row">
                          <div class="col-lg-6">
                            <span class="text-warning">{{row.item.first_name}} {{row.item.last_name}}</span>
                            <p v-if="row.item.is_minor == 0" class="small">{{row.item.email}}</p>
                            <badge class="ml-2" type="primary" v-if="row.item.is_minor == 1">Minor</badge>
                          </div>
                          <div v-if="row.item.is_minor == 0 && email_active == 1" class="col-lg-6">
                            <el-tooltip content="View customer details"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                              <base-button
                                @click="viewCustomerDetails(row.item)"
                                simple
                                type="info"
                                link
                                style="font-size: large"
                              > <i class="tim-icons icon-badge"></i>
                              </base-button>
                            </el-tooltip>
                            <el-tooltip v-if="photos.length > 0" content="Email photo to customer"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                              <base-button :disabled="photos_not_enabled"
                                @click="emailPhoto(row.item)"
                                simple
                                type="success"
                                link
                                style="font-size: large"
                              > <i class="tim-icons icon-email-85"></i>
                              </base-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </template>
                    </b-table>

                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modals.upload_photo"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <div class="text-center">
          <div
            class="fileinput-new thumbnail">
            <img :src="uploaded_image" alt="preview" />

          </div>
          <span class="btn btn-primary btn-sm btn-simple btn-file mt-3">New photo
                <input type="file"
                       accept="image/*"
                       @change="handleUpload"

                />
          </span>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.upload_photo = false">Cancel</base-button>
          <base-button class="ml-3" v-on:click="savePhoto()" type="primary" >Save</base-button>
        </div>
        <div class="row text-center d-block d-sm-none mt-3">
          <base-button type="secondary" simple size="sm" @click="modals.upload_photo = false">Cancel</base-button>
          <base-button class="ml-3" simple size="sm" v-on:click="savePhoto()" type="primary" >Save</base-button>
        </div>
      </card>
    </modal>

    <!--    Add player Modal-->
    <modal :show.sync="modals.add_player"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <p class="text-white">Add a player to game instance</p>
        <base-alert type="warning"><i class="tim-icons icon-alert-circle-exc"></i>
          This will create a waiver record without customer's signature</base-alert>
        <div class="row">
          <div class="col-6">
            <base-input label="First name" v-model="user.first_name" placeholder="First name"></base-input>
            <p v-show="errors.first_name" class="text-danger small">First name cannot be empty</p>
          </div>
          <div class="col-6">
            <base-input label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
            <p v-show="errors.last_name" class="ml-1 text-danger small">Last name cannot be empty</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <base-input label="Email" v-model="user.email" placeholder="Email"></base-input>
            <p v-show="errors.email" class="ml-1 text-danger small">Please provide a valid email</p>
          </div>
          <div class="col-6">
            <base-input label="Phone (Optional)" v-mask="'(###) ###-####'" v-model="user.phone" placeholder="(999) 999-9999"></base-input>
          </div>
        </div>
        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.add_player = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="addPlayerConfirm()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.add_player = false">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="addPlayerConfirm()">Save</base-button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
  import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
  import { TimeLine, TimeLineItem, TabPane, Tabs, Collapse, CollapseItem, BaseAlert, Badge, Modal } from 'src/components';
  import { Select, Option, Tooltip, TimePicker, CarouselItem, Carousel } from 'element-ui';
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  const axios = require('axios')
  import swal from 'sweetalert2';
  import {date, dayOfWeek, formattedDate, simpleTime, time} from "@/plugins/dateFormatter";
  import html2canvas from "html2canvas";
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
  import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
  import _ from "lodash";

  export default {
    name: "GameInstance",
    components: {
      BreadcrumbItem,
      Breadcrumb,
      BFormInput,
      BTable,
      BPagination,
      TimeLine,
      TimeLineItem,
      [CarouselItem.name]: CarouselItem,
      [Carousel.name]: Carousel,
      [Select.name]: Select,
      [Option.name]: Option,
      TabPane,
      Tabs,
      Collapse,
      CollapseItem,
      BaseAlert,
      Badge,
      Modal,
      Tooltip,
      [TimePicker.name]: TimePicker
    },
    data() {
      return {
        photos: [],
        loading: false,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        date_format_cal: '',
        day_of_week: '',
        picker_time_format: 'hh:mm A',
        selectedGameMaster: '',
        company_users: [],
        editing_team_name: false,
        creating_team_name: false,
        uploaded_image: 'https://console.offthecouch.io/img/placeholder.jpg',
        modals: {
          upload_photo: false,
          send_photo: false,
          add_player: false
        },
        puzzleHours: [],
        puzzleMinutes: [],
        puzzleTimeOfDay: [],
        editingPuzzles: false,
        editingGame: false,
        user: {
          first_name: '',
          last_name: '',
          email: '',
          phone: ''
        },
        editing: {
          booking_time: '',
          start_time: '',
          end_time: '',
          start_date: '',
          end_date: ''
        },
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        filter: null,
        email_active: 0,
        fields: ['puzzle_name', 'completed', 'solve_time', 'time_since_start', 'hint_number', 'hints_given', 'malfunctions'],
        mobile_fields: ['puzzles'],
        teamFields: ['players'],
        errs: {
          start_time: '',
          end_time: '',
        },
        gameInstance: {
          booking_date: '',
          booking_start_time: '',
          start_time: '2022-06-19T14:36:00.000Z'
        },
        photo: {
          url: 'https://console.offthecouch.io/img/placeholder.jpg',
          id: ''
        },
        puzzleInstances: [],
        team: [],
        non_participants: [],
        exceed_time: false,
        overlays: [],
        selectedOverlay: '',
        layout: 'landscape',
        photos_not_enabled: false
      };
    },
    computed: {
      hourOptions() {
        let hours = []
        for(let i = 1; i < 13; i++){
          if(i<10){
            hours.push('0'+i)
          } else {
            hours.push(i)
          }
        }
        return hours
      },
      minuteOptions() {
        return ['00', '15', '30', '45']
      },
      minuteOptionsPuzzles() {
        let minutes = []
        for (let i = 1; i < 60; i++) {
          if (i < 10) {
            minutes.push('0' + i)
          } else {
            minutes.push(i)
          }
        }
        return minutes
      },
      timeOfDayOptions(){
        return ['AM', 'PM']
      }

    },
    methods: {
      goToBilling(){
        this.$router.push('/settings/billing')
      },
      hasPermissions(scope){
        let permissions = JSON.parse(localStorage.getItem('permissions'))
        const matchFound = permissions.some(element => element === scope);
        return matchFound
      },
      validateTime(time, day){
        let today = new Date(day)
        time.setMonth(today.getMonth(), today.getDate())
      },
      setActivePhoto(index){
        this.photo = this.photos[index]
      },
      base64ToFile(base64Data) {
        var byteCharacters = atob(base64Data.split(',')[1]);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/octet-stream' });
        var file = new File([blob], 'full_overlay.png', { type: 'application/octet-stream' });
        return file
      },
      async takeScreenshot() {
        const element = this.$refs.targetDiv;
        return new Promise((resolve, reject) => {
          html2canvas(element, {useCORS: true}).then(function(canvas) {
            const imgData = canvas.toDataURL('image/png');
            resolve(imgData);
          }).catch(error => {
            reject(error);
          });
        });
      },
      async saveOverlay(item, index){
        this.loading = true
        // let replaced = await this.replaceDynamicVariables(item)
        // console.log(replaced)

        //save overlay - take screenshot
        let screenshot = await this.takeScreenshot()
        this.file = this.base64ToFile(screenshot)
        let file = await this.saveFile()


        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let groupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos/' + item.id + '/overlay'
        item.overlay_url = this.uploadUrl.split("?")[0]
        item.overlay_id = item.selectedOverlay.id

        let data = {
          overlay_url:  this.uploadUrl.split("?")[0],
          overlay_id: item.selectedOverlay.id
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.file = ''
            this.uploadUrl = ''
          }).catch((err) => {
          console.log(err)
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: 'Something went wrong!',
              type: 'error',
              confirmButtonClass: 'btn btn-danger btn-fill',
              confirmButtonText: 'Try again',
              buttonsStyling: false
            })
          }
        })

      },
      async getOverlays(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let groupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photo-overlays'

        axios.get(url, config)
          .then(response => {
            this.overlays = response.data.overlays
            // this.getPhotos()
            this.loading = false
          }).catch((err) => {
          console.log(err)
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: 'Something went wrong!',
              type: 'error',
              confirmButtonClass: 'btn btn-danger btn-fill',
              confirmButtonText: 'Try again',
              buttonsStyling: false
            })
          }
        })
      },
      addPlayer(){
        this.modals.add_player = true
      },
      viewCustomerDetails(user){
        this.$router.push({ path: '/customers/details', query: { id:  user.user_id }})
      },
      async confirmTeamName(){
        this.editing_team_name = false
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/teams/' + this.team[0].team_id

        let data = {
          team_name: this.team[0].name
        }

        axios.put(url, data, config)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            if(error.response.status == 401){
              this.$router.push('/forbidden')
            }
          })

      },
      async addPlayerConfirm(){
        this.modals.add_player = false
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + this.gameInstance.id + '/waiver'

        let data = {
          user: this.user,
          game_id: this.gameInstance.game_id,
          booking_slot_id: this.gameInstance.booking_slot_id,
          timestamp: new Date(),
          team_id: null
        }
        if(this.team.length > 0){
          data.team_id = this.team[0].team_id
        }
        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.users = {
              first_name: '',
              last_name: '',
              email: '',
              phone: ''
            }
            this.getGameData()
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            if(error.response.status == 401){
              this.$router.push('/forbidden')
            }
          })

      },
      async confirmTeamNameCreate(){
        this.creating_team_name = false
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/teams'

        let data = {}

        if(this.team[0].team_id){
          data = {
            team_name: this.team[0].name,
          }
          url = API_LOCATION + 'companies/' + companyId + '/teams/' + this.team[0].team_id
          axios.put(url, data, config)
            .then(response => {
              this.loading = false
              this.getGameData()
            })
            .catch(error => {
              this.loading = false
              console.log(error)
              if(error.response.status == 401){
                this.$router.push('/forbidden')
              }
            })
        } else {
          let data = {
            name: this.team[0].name,
            users: this.team
          }
          axios.post(url, data, config)
            .then(response => {
              this.loading = false
              this.getGameData()
            })
            .catch(error => {
              this.loading = false
              console.log(error)
              if(error.response.status == 401){
                this.$router.push('/forbidden')
              }
            })
        }

      },
      createTeamName(){
        this.creating_team_name = true
      },
      editTeamName(){
        this.editing_team_name = true
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      inviteUser() {
        this.$router.push('/settings/user-invite')
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      cancelEditingGame(){
        this.editingGame = false
      },
      cancelEditingPuzzles(){
        for(let n in this.puzzleInstances){
          this.puzzleInstances[n].solve_time = this.puzzleInstances[n].solve_time.getFullYear() + "-"
            + (this.puzzleInstances[n].solve_time.getMonth() + 1) + "-"
            + this.puzzleInstances[n].solve_time.getDate() + 'T' +
            this.puzzleInstances[n].solve_time.getHours() + ':' + this.puzzleInstances[n].solve_time.getMinutes() + ':00.000Z'
        }
        this.editingPuzzles = false
      },
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      editGameInstance() {
        let dt = new Date()
        if(this.gameInstance.start_time){
          dt.setHours(this.gameInstance.booking_start_time.split(':')[0])
          dt.setMinutes(this.gameInstance.booking_start_time.split(':')[1])
        }

        this.editing.booking_time = dt

        let dtSt = new Date()
        if(this.gameInstance.start_time  && !this.gameInstance.start_time.includes('0000')){
          dtSt.setFullYear(this.gameInstance.start_time.split('T')[0].split('-')[0])
          dtSt.setMonth(this.gameInstance.start_time.split('T')[0].split('-')[1] - 1, this.gameInstance.start_time.split('T')[0].split('-')[2])
          dtSt.setHours(this.gameInstance.start_time.split('T')[1].split(':')[0])
          dtSt.setMinutes(this.gameInstance.start_time.split('T')[1].split(':')[1])
        }

        this.editing.start_time = dtSt
        this.editing.start_date = dtSt

        let dtEt = new Date()
        if(this.gameInstance.end_time && !this.gameInstance.end_time.includes('0000')){
          dtEt.setFullYear(this.gameInstance.end_time.split('T')[0].split('-')[0])
          dtEt.setMonth(this.gameInstance.end_time.split('T')[0].split('-')[1] - 1, this.gameInstance.end_time.split('T')[0].split('-')[2])
          dtEt.setHours(this.gameInstance.end_time.split('T')[1].split(':')[0])
          dtEt.setMinutes(this.gameInstance.end_time.split('T')[1].split(':')[1])
        }

        this.editing.end_time = dtEt
        this.editing.end_date = dtEt

        this.editingGame = true
      },
      editPuzzles(){
        let dt = new Date()
        for(let n in this.puzzleInstances){
          console.log(this.puzzleInstances[n].solve_time instanceof Date)
         if(this.puzzleInstances[n].solve_time && this.puzzleInstances[n].solve_time.includes('T')){
           dt = new Date()
           dt.setFullYear(this.puzzleInstances[n].solve_time.split('T')[0].split('-')[0])
           dt.setMonth(this.puzzleInstances[n].solve_time.split('T')[0].split('-')[1] - 1, this.puzzleInstances[n].solve_time.split('T')[0].split('-')[2])
           dt.setHours(this.puzzleInstances[n].solve_time.split('T')[1].split(':')[0])
           dt.setMinutes(this.puzzleInstances[n].solve_time.split('T')[1].split(':')[1])
           this.puzzleInstances[n].solve_time = dt
         }
        }
        this.editingPuzzles = true
      },
      async delete() {
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let gameInstanceId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + gameInstanceId

        axios.delete(url, config)
          .then(response => {
            this.loading = false
            this.$router.push('/games/history?count=10&page=1')
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            if(error.response.status == 401){
              this.$router.push('/forbidden')
            }
          })
      },
      async deletePhoto(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/photos/' + this.photo.id + '/assign?link_id=' + this.photo.link_id + '&link_type=' + 1

        axios.delete(url, config)
          .then(response => {
            this.loading = false
            swal({
              title: 'Photo has been unassigned',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
            this.getGameData()
          }).catch((err) => {
          console.log(err)
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: 'Something went wrong!',
              type: 'error',
              confirmButtonClass: 'btn btn-danger btn-fill',
              confirmButtonText: 'Try again',
              buttonsStyling: false
            })
          }
        })
      },
      deleteGameInstance(){
        swal({
          title: 'Are you sure?',
          text: `The game record will be permanently deleted`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then((response)=> {
          if(response.value == true){
            this.delete();
          }
        })

      },
      async getGameData(){
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let gameInstanceId =  this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + gameInstanceId

        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.gameInstance = response.data.gameInstance
            this.puzzleInstances = response.data.puzzleInstances
            this.team = response.data.team
            this.non_participants = response.data.non_participants
            this.email_active = response.data.email_active
            this.photos = response.data.photo
            this.selectedGameMaster = {
              id: response.data.gameInstance.gm_id,
              first_name: response.data.gameInstance.first_name,
              last_name: response.data.gameInstance.last_name,
              email: response.data.gameInstance.email
            }
            if(response.data.photo.length > 0){
              this.photo = response.data.photo[0]
            }

            let arrIncomplete = []
            let arrComplete = []
            for (let n in this.puzzleInstances){
              if(this.puzzleInstances[n].is_completed === 0){
                arrIncomplete.push(this.puzzleInstances[n])
              } else {
                arrComplete.push(this.puzzleInstances[n])
              }
            }
            this.puzzleInstances = arrComplete.concat(arrIncomplete)

            this.getCompanyUsers()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })

      },
      getMinutesRemaining(g){

        let total_time = parseInt(this.getTotalTime(this.puzzleInstances))
        let game_duration = parseInt(g.duration_minutes)

        if(parseInt(game_duration - total_time) >= 0){
          this.exceed_time = false
          return parseInt(game_duration - total_time) + ' min'
        } else {
          this.exceed_time = true
          return parseInt(total_time - game_duration) + ' min'
        }
      },
      getMinutesSinceStart(d){

        let start = new Date()
        start.setFullYear(this.gameInstance.start_time.split('T')[0].split('-')[0])
        start.setMonth(this.gameInstance.start_time.split('T')[0].split('-')[1] - 1, this.gameInstance.start_time.split('T')[0].split('-')[2])
        start.setHours(this.gameInstance.start_time.split('T')[1].split(':')[0])
        start.setMinutes(this.gameInstance.start_time.split('T')[1].split(':')[1])
        start.setSeconds(0)

        let time = d.solve_time
        if(!(d.solve_time instanceof Date) && d.solve_time && d.solve_time.includes('T')){
          time = new Date()
          time.setFullYear(d.solve_time.split('T')[0].split('-')[0])
          time.setMonth(d.solve_time.split('T')[0].split('-')[1] - 1, d.solve_time.split('T')[0].split('-')[2])
          time.setHours(d.solve_time.split('T')[1].split(':')[0])
          time.setMinutes(d.solve_time.split('T')[1].split(':')[1])
          time.setSeconds(0)
        }
        var diffTime = (time - start)

        var total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
        if (d.solve_time != null && d.is_completed == 1 && total_minutes >= 0) {
          return total_minutes + ' min'
        }
        return '-'
      },
      getDate(d){
        return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
      },
      getTime(d){
        return time(this.time_format, d)
      },
      getSimpleTime(d){
        return simpleTime(d)
      },
      getTotalTime(puzzles){
        let end_time = ''
        if (this.gameInstance.end_time == null){
          let index = puzzles.length
          // while (index-- && !puzzles[index].solve_time);
          while(index > 0){
            if(puzzles[index-1].is_completed == 1){
              end_time = puzzles[index-1]
              break
            }
            index--
          }
          return this.getMinutesSinceStart(end_time)
        } else {
          end_time = new Date(this.gameInstance.end_time)
          var start = new Date(this.gameInstance.start_time)
          var diffTime = (end_time - start)
          var total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
          return total_minutes + ' min'
        }


      },
      getEndTime(puzzles){
        let time = ''
        let hour = ''
        let minute = ''
        let dd = ''
        if(this.gameInstance.end_time == null){
          let index = puzzles.length
          while (index-- && !puzzles[index].solve_time);
          if(puzzles[index] != undefined){
            let d = puzzles[index].solve_time
            if (d != null) {
              time = d.split('T')[1]

              hour = parseInt(time.split(':')[0])
              minute = time.split(':')[1]
              dd = 'AM'
              if (hour >= 12) {
                hour = hour - 12;
                dd = 'PM';
              }
              if (hour == 0) {
                hour = 12;
              }

              if (hour < 10){
                hour = '0' + hour
              }

              return hour + ':' + minute + ' ' + dd
            }
            return '-'
          }
          return '-'
        } else {
          time = this.gameInstance.end_time.split('T')[1]
          hour = parseInt(time.split(':')[0])
          minute = time.split(':')[1]
          dd = 'AM'
          if (hour >= 12) {
            hour = hour - 12;
            dd = 'PM';
          }
          if (hour == 0) {
            hour = 12;
          }

          if (hour < 10){
            hour = '0' + hour
          }
          return hour + ':' + minute + ' ' + dd
        }


      },
      getPercentCompletion(puzzles){
        let completed = 0
        let total = puzzles.length
        let total_completion = 0

        for (let n in puzzles){
          if(puzzles[n].solve_time != null && puzzles[n].is_completed == 1){
            completed++
          }
        }

        total_completion = Math.floor((completed / total) * 100)

        return total_completion + '%'

      },
      getReversedTime(d){
        if(d.A == 'AM'){

          if(d.hh == 12){
            d.hh = '00'
          }
          return d.hh + ':' + d.mm
        } else {
          if(parseInt(d.hh) < 12){
            d.hh = parseInt(d.hh) + 12
          }

          return d.hh + ':' + d.mm
        }

      },
      getTotalClues(puzzles){
        let number_of_clues = 0
        if(puzzles.length > 0){
          for (let n in puzzles){
            number_of_clues = number_of_clues + parseInt(puzzles[n].hints_given_count)
          }
        } else {
          number_of_clues = this.gameInstance.number_of_hints
        }
        return number_of_clues
      },
      getTotalBreakages(puzzles){
        let number_of_breakages = 0
        if(puzzles.length > 0){
          for (let n in puzzles){
            number_of_breakages = number_of_breakages + parseInt(puzzles[n].breakage_occurred)
          }
        } else {
          number_of_breakages = this.gameInstance.number_of_breaks
        }
        return number_of_breakages

      },
      getType(p){
        if (p.is_completed == 1){
          return 'success'
        } else {
          return 'danger'
        }
      },
      async saveGameInstance() {
        if(!this.validGameInstanceFields()){
          return
        }
        this.editing.start_time.setFullYear(this.editing.start_date.getFullYear())
        this.editing.start_time.setMonth(this.editing.start_date.getMonth(), this.editing.start_date.getDate())

        this.editing.end_time.setFullYear(this.editing.end_date.getFullYear())
        this.editing.end_time.setMonth(this.editing.end_date.getMonth(), this.editing.end_date.getDate())

        this.editingGame = false
        this.saveInstance()
      },
      async savePuzzleInstance(){
        this.editingPuzzles = false

        for(let n in this.puzzleInstances){
          if(this.puzzleInstances[n].is_completed){
            this.puzzleInstances[n].solve_time = this.puzzleInstances[n].solve_time.getFullYear() + "-"
              + (this.puzzleInstances[n].solve_time.getMonth() + 1) + "-"
              + this.puzzleInstances[n].solve_time.getDate() + 'T' +
              this.puzzleInstances[n].solve_time.getHours() + ':' + this.puzzleInstances[n].solve_time.getMinutes() + ':00.000Z'
          }

          this.puzzleInstances[n].breakage_occurred = 0
          if(this.puzzleInstances[n].breakage_description !== null && this.puzzleInstances[n].breakage_description !== ''){
            this.puzzleInstances[n].breakage_occurred = 1
          }
        }

        this.saveInstance()

      },
      validGameInstanceFields(){
        let errors = 0

        if(!this.editing.start_time){
          this.errs.start_time = 'Start time cannot be empty'
          errors++
        } else {
          this.errs.start_time = ''
        }
        if(!this.editing.end_time){
          this.errs.end_time = 'End time cannot be empty'
          errors++
        } else {
          this.errs.end_time = ''
        }
        if(!this.editing.booking_time){
          this.errs.booking_time = 'Booking time cannot be empty'
          errors++
        } else {
          this.errs.booking_time = ''
        }

        return errors > 0 ? false : true

      },
      async saveInstance(){
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let gameInstanceId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + gameInstanceId

        let payload = {
          puzzles: this.puzzleInstances,
          game: this.gameInstance,
          booking_start_time: this.editing.booking_time ? this.editing.booking_time.getHours() + ':' + this.editing.booking_time.getMinutes() : this.gameInstance.booking_start_time,
          start_time: this.editing.start_time ? this.editing.start_time.getFullYear() + '-' + (this.editing.start_time.getMonth() + 1) + '-' + this.editing.start_time.getDate() + ' ' + this.editing.start_time.getHours() + ':' + this.editing.start_time.getMinutes() : this.gameInstance.start_time,
          end_time: this.editing.end_time ? this.editing.end_time.getFullYear() + '-' + (this.editing.end_time.getMonth() + 1) + '-' + this.editing.end_time.getDate() + ' ' + this.editing.end_time.getHours() + ':' + this.editing.end_time.getMinutes() : this.gameInstance.end_time,
          gm_id: this.selectedGameMaster.id
        }
        axios.put(url, payload, config)
          .then(response => {
            this.loading = false
            swal('Success', 'Game form has been updated' , 'success')
            this.getGameData()

          })
          .catch(error => {
            this.loading = false
            console.log(error)
            if(error.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })

      },
      scrollTo(location){
        let element = this.$refs[location];
        element.scrollIntoView();
      },
      handleUpload(){
        let file = event.target.files[0];
        this.uploaded_image = URL.createObjectURL(file);
        this.file = file
        this.$emit('change', file);
      },
      async getSignedURL (file) {
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'upload/photos'

        let payload = {
          filePath: file.name,
          contentType: file.type
        }

        return new Promise ( function(resolve) {
          axios.post(url, payload, config)
            .then((res) => {
              resolve(res.data)
            })
            .catch((err) => {
              swal({
                title: 'Error!',
                text: `You must upload a player photo before saving!`,
                type: 'error',
                confirmButtonColor: '#fd5d93',
                confirmButtonText: 'Try Again'
              })
              console.error(err)
            })
        })
      },
      async saveFile() {
        this.uploadUrl = await this.getSignedURL(this.file)
        let uploaded = await this.uploadFile(this.file)
      },
      uploadFile (file) {
        let url = this.uploadUrl
        let config = { headers: {
            'Content-Type': file.type
          }}

        let payload = file
        return new Promise ( function(resolve) {
          axios.put(url, payload, config)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async getLayout(){
        let img = new Image()
        img.src = URL.createObjectURL(this.file)


        return new Promise((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = reject;
        });

      },
      async savePhoto(){
        let t = new Date().toLocaleString( 'sv' );
        let layout = await this.getLayout()
        if(parseFloat(layout.width / layout.height).toFixed(2) == 0.75 ) {
          this.layout = 'portrait'
        }
        this.loading = true
        await this.saveFile()
        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let groupId = JSON.parse(localStorage.getItem('group')).id
        let bsId = this.gameInstance.booking_slot_id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos?bsId=' + bsId + '&t=' + t

        let data = {
          uploaded_image:  this.uploadUrl.split("?")[0],
          layout: this.layout
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.modals.upload_photo = false
            this.uploaded_image = 'https://console.offthecouch.io/img/placeholder.jpg'
            this.file = ''
            this.uploadUrl = ''
            swal('Success', 'Photo has been uploaded.', 'success')
            this.getGameData()
          }).catch((err) => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal({
                title: 'Something went wrong!',
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Try again',
                buttonsStyling: false
              })
            }
        })
      },
      async getCompanyUsers(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + this.gameInstance.company_group_id + '/company-users'

        axios.get(url, config)
          .then(response => {
            for(let n in response.data.company_users){
              if(response.data.company_users[n].first_name === null){
                response.data.company_users[n].first_name = ''
              }
              if(response.data.company_users[n].last_name === null){
                response.data.company_users[n].last_name = ''
              }
            }
            this.company_users = response.data.company_users
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async emailPhoto(user){
        this.loading = true
        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/photos/'+ this.photo.id + '/email-group'

        let u = []
        u.push(user)
        let data = {
          emails: user ?  u : this.team,
          link_type: user ? 0 : 1,
          link_id: this.photo.link_id
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            swal({
              title: 'Photo has been sent',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
            this.getGameData()


          }).catch((err) => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal({
                title: 'Something went wrong!',
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Try again',
                buttonsStyling: false
              })
            }

        })

      }
    },
    async beforeMount() {
      if(!(_.find(JSON.parse(localStorage.getItem('features')), {id: 5}))){
        this.photos_not_enabled = true
      } else {
        this.photos_not_enabled = false
      }
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      if (this.time_format === 'HH:mm') {
        this.picker_time_format = 'HH:mm'
      }
      this.getOverlays()
      await this.getGameData()
    }
  }
</script>

<style scoped>
  .fixed-size-img {
    width: 100%; /* width of container */
    height: 150px; /* height of container */
    object-fit: cover;
  }
  .photo-size {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

</style>
