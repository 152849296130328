<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

<!--  Feature not enabled-->
  <div v-if="feature_not_enabled" class="mt-3" >
    <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
      <h4 class="mt-3">This feature is not enabled.</h4>
      <base-button type="primary" v-if="hasPermissions('billing_view')"
        v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
      <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
    </div>
  </div>

  <!--    Create and clear schedule button -->
  <div class="row mb-2" v-if="hasPermissions('surveys_update') && !feature_not_enabled">
    <div class="col">
      <base-button v-on:click="createNewSurvey()" type="primary" >
        <i class="tim-icons icon-simple-add mr-2"></i> Create survey</base-button>
    </div>
  </div>

  <div class="row" v-if="!feature_not_enabled">
    <div class="col">
      <card>
        <h4 class="card-title">Surveys</h4>
        <!--   No data panel -->
        <div v-if="surveys.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            <h4 class="mt-3">No surveys have been created.</h4>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div class="row" v-if="surveys.length > 0">
            <div class="col">
              <b-table  striped hover :items="surveys"
                        :fields="['survey', 'status', 'date_created', 'number_of_surveys_sent', 'number_of_surveys_completed', 'actions']">
                <template v-slot:cell(survey)="row">
                  <p>{{row.item.name}}
                  </p>
                  <small>{{row.item.description}}</small>
                </template>
                <template v-slot:cell(status)="row">
                  <badge v-if="row.item.active" type="success">live</badge>
                  <badge v-if="!row.item.active" type="danger">inactive</badge>
                </template>
                <template v-slot:cell(date_created)="row">
                  <p>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                  </p>
                  <small style="font-style: italic">Created by {{row.item.first_name}} {{row.item.last_name}}</small>
                </template>
                <template v-slot:cell(number_of_surveys_sent)="row">
                  <span>{{row.item.sent_count}}
                  </span>
                </template>
                <template v-slot:cell(number_of_surveys_completed)="row">
                  <span>{{row.item.completion_count}}
                  </span>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-1 mb-2" target="_blank" :href="'https://offthecouch.io/surveys/' + row.item.hash">
                          <i class="tim-icons text-info icon-link-72 mr-2"></i> Go to survey page
                        </a>
                        <a class="dropdown-item mt-1 mb-2" @click="generateQrCode(row.item.hash)" href="#">
                          <i class="tim-icons text-default icon-cloud-download-93 mr-2"></i> Download QR code
                        </a>
                        <a class="dropdown-item mb-2" href="#" v-if="row.item.active" @click="sendToCustomersModal(row.item)">
                          <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Send to customers
                        </a>
                        <a class="dropdown-item mb-2" href="#" @click="viewSurvey(row.item)">
                          <i class="tim-icons icon-chart-bar-32 mr-2"></i> View survey responses
                        </a>
                        <a class="dropdown-item mb-2" @click="editSurvey(row.item)" href="#">
                          <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit survey
                        </a>
                        <a class="dropdown-item" v-if="row.item.active" @click="activateSurveyPrompt('deactivate', row.item)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate survey
                        </a>
                        <a class="dropdown-item" v-if="!row.item.active" @click="activateSurveyPrompt('activate', row.item)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate survey
                        </a>
                        <hr>
                        <a class="dropdown-item mb-2" @click="deleteSurveyPrompt(row.item)" href="#">
                          <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete survey
                        </a>

                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <div class="row" v-if="surveys.length > 0">
            <div class="col">
              <b-table  striped hover :items="surveys"
                        :fields="['surveys']">
                <template v-slot:cell(surveys)="row">
                  <div class="row">
                    <div class="col">
                      <p class="text-primary">{{row.item.name}}
                        <a target="_blank" :href="'https://offthecouch.io/surveys/' + row.item.hash">
                          <i class="tim-icons el-icon-top-right text-primary"></i>
                        </a>
                      </p>
                      <small>{{row.item.description}}</small>
                    </div>
                    <div class="col-3 text-right">
                      <span v-if="row.item.active" @click="activateSurveyPrompt('deactivate', row.item)"
                            style="cursor: pointer; padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px"
                            class="active-dot mr-2"></span>
                        <span v-if="!row.item.active" @click="activateSurveyPrompt('activate', row.item)"
                              style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px"
                              class="inactive-dot mr-2"></span>
                    </div>
                  </div>
                  <p>Created on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</p>
                  <small style="font-style: italic">Created by {{row.item.first_name}} {{row.item.last_name}}</small>
                  <div
                    class="btn-group btn-group-toggle mt-3 mb-2"
                    :class="'float-left'"
                    data-toggle="buttons" style="width:100%"
                  >
                    <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0px; padding-left: 0px">
                      <base-button
                        @click.native="deleteSurveyPrompt(row.item)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </label>
                    <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0px; padding-left: 0px">
                      <base-button
                        @click.native="editSurvey(row.item)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </label>
                    <label class="btn btn-sm btn-primary btn-simple" v-if="row.item.active" style="padding-right: 0px; padding-left: 0px">
                      <base-button
                        @click.native="sendToCustomersModal(row.item)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-email-85"></i>
                      </base-button>
                    </label>
                    <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0px; padding-left: 0px">
                      <base-button
                        @click.native="viewSurvey(row.item)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-chart-bar-32"></i>
                      </base-button>
                    </label>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
  <modal :show.sync="modals.send"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card card-body-classes="table-full-width"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4 class="card-title">Send {{selectedSurvey.name}} to customers</h4>

      <div class="row mb-3">
        <div class="col">
          <div
            class="btn-group btn-group-toggle"
            :class="'float-left'"
            data-toggle="buttons"
          >
            <label
              v-for="(option, index) in categories"
              :key="index + k"
              class="btn btn-sm btn-primary btn-simple"
              :class="{ active: option.active }"
              :id="index"
            >
              <input
                type="radio"
                @click="switchTab(index)"
                name="options"
                autocomplete="off"
                :checked="option.active"
              />
              <span style="font-size: small; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
              <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row" v-if="activeTabIndex === 0">
        <div class="col-lg-6 col-md-6 col-12">
          <base-input label="Customer lookup (search by name, email, or phone)">
            <el-autocomplete style="width: 100%"
                             v-model="lookup_value"
                             :fetch-suggestions="querySearch"
                             placeholder="Search"
                             :trigger-on-focus="false"
                             @select="handleSelect"
            >
              <template #default="{ item }">
                <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                <small class="font-italic">
                  <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                  <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                </small>
              </template>
            </el-autocomplete>
          </base-input>
        </div>
        <div class="col">
          <label class="mt-5"></label>
          <base-button simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
          <base-button type="primary" class="ml-2" v-on:click="addCustomer()">Add</base-button>
        </div>
      </div>
      <div v-if="activeTabIndex === 1">
        <div class="row">
          <div class="col-6">
            <base-input :error="errs.first_name" label="First name" v-model="user.first_name" placeholder="First name"></base-input>
          </div>
          <div class="col-6">
            <base-input :error="errs.last_name" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <base-input :error="errs.email" label="Email" v-model="user.email" placeholder="Email"></base-input>
          </div>
          <div class="col-6">
            <base-input label="Phone (Optional)" v-model="user.phone" :placeholder="placeholder_phone"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-button type="primary" v-on:click="addCustomer()">Add customer</base-button>
          </div>
        </div>
      </div>

      <div v-if="activeTabIndex === 2 || activeTabIndex === 3 || activeTabIndex === 4">
        <div class="row">
          <div class="col">
            <base-alert type="info">
              <i class="tim-icons el-icon-info text-white mr-1"></i> Survey will be sent to all customers who played the games you select below.</base-alert>
          </div>
        </div>
      </div>

      <div v-if="activeTabIndex === 2">
        <div class="row">
          <div class="col">
            <base-input label="Search game instances by date" style="border: 0; border-width:0px;">
              <el-date-picker
                style="border: 0; border-width:0px;"
                type="date"
                placeholder="Select date"
                value-format="yyyy-MM-dd"
                :format="day_of_week + '' + date_format_cal"
                v-model="current_game_instance"
                @change="getGameInstances(7)"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>
        <div class="row" v-if="game_instances.length > 0">
          <div class="col">
            <b-table :items="game_instances" :fields="['game', 'game_master', 'booking_time', 'select']">
              <template #head(select)="data">
                <span></span>
              </template>
              <template v-slot:cell(select)="row">
                <base-button size="sm" type="primary" v-on:click="addGameInstance(row.item)">
                Add</base-button>
              </template>
              <template v-slot:cell(game)="row">
                <span>{{row.item.name}}</span>
              </template>
              <template v-slot:cell(game_master)="row">
                <span>{{row.item.first_name}} {{row.item.last_name}}</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <span>{{getTime(row.item.booking_time)}}</span>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <div v-if="activeTabIndex === 3">
        <div class="row">
          <div class="col">
            <base-input label="Select bookings by date" style="border: 0; border-width:0px;">
              <el-date-picker
                style="border: 0; border-width:0px;"
                type="date"
                placeholder="Select date"
                value-format="yyyy-MM-dd"
                :format="day_of_week + '' + date_format_cal"
                v-model="current_booking"
                @change="getGameInstances(8)"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>
        <div class="row" v-if="bookings.length > 0">
          <div class="col">
            <b-table :items="bookings" :fields="['game', 'status', 'booking_time', 'select']">
              <template #head(select)="data">
                <span></span>
              </template>
              <template v-slot:cell(select)="row">
                <base-button size="sm" type="primary" v-on:click="addBooking(row.item)">
                  Add</base-button>
              </template>
              <template v-slot:cell(status)="row">
                <badge v-if="row.item.status === 'available' || row.item.status === 'completed'" type="success">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'booked'" type="info">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'expired'" type="default">passed</badge>
                <badge v-if="row.item.status === 'cancelled' || row.item.status === 'blocked'"
                       type="danger">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'call_to_book'"
                       type="warning">call to book</badge>
                <badge v-if="row.item.status === 'checked'"
                       type="warning">checked in, no booking</badge>
              </template>
              <template v-slot:cell(game)="row">
                <span style="display: inline-block">{{row.item.name}}</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <span>{{getTime(row.item.booking_time)}}</span>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <div v-if="activeTabIndex === 4">
        <div class="row">
          <div class="col">
            <base-input label="Games" :error="errs.game">
              <el-select
                v-model="selectedGame"
                class="select-primary"
                placeholder="Add games"
                name="games"
                value-key="id"
              >
                <el-option
                  v-for="(g, index) in games"
                  class="select-primary"
                  :label="g.name"
                  :value="{id: g.id, name: g.name}"
                  :key="'G' + index"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <base-checkbox v-model="limit_game_by_date_played">Limit by date played</base-checkbox>
          </div>
          <div class="col" v-if="limit_game_by_date_played">
            <base-input label="Booking date range">
              <el-date-picker style="background-color: transparent"
                v-model="game_date_range"
                type="daterange"
                start-placeholder="Start date"
                end-placeholder="End date"
                :format="day_of_week + '' + date_format_cal"
              />
            </base-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <base-button type="primary" v-on:click="addGames()">Add games</base-button>
          </div>
        </div>
      </div>
      <hr style="border: 0.5px solid lightgray">


      <!--   No data panel -->
      <div class="row">
        <div class="col">
          <div v-if="customers.length <= 0 && added_games.length <= 0 && added_game_instances.length <=0 && added_bookings.length <=0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Add survey recipients</h4>
            </div>
          </div>
          <h4 v-else class="card-title">Survey recipients</h4>
        </div>
      </div>


      <div class="row" v-if="customers.length > 0">
        <div class="col">
          <b-table :items="customers" :fields="['customer_name', 'customer_email', 'actions']">
            <template #head(actions)="data">
              <span></span>
            </template>
            <template v-slot:cell(actions)="row">
              <base-button
                @click.native="removeCustomer(row.index)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </template>
            <template v-slot:cell(customer_name)="row">
              <span>{{row.item.first_name}} {{row.item.last_name}}</span>
            </template>
            <template v-slot:cell(customer_email)="row">
              <span>{{row.item.email}}</span>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row" v-if="added_games.length > 0">
        <div class="col">
          <b-table :items="added_games" :fields="['game', 'date_range', 'actions']">
            <template #head(actions)="data">
              <span></span>
            </template>
            <template v-slot:cell(actions)="row">
              <base-button
                @click.native="removeGame(row.index)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </template>
            <template v-slot:cell(game)="row">
              <span>{{row.item.name}}</span>
            </template>
            <template v-slot:cell(date_range)="row">
              <span v-if="row.item.date_range">{{getFormattedDate(row.item.date_range[0])}} - {{getFormattedDate(row.item.date_range[1])}}</span>
              <span v-else>Any date</span>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row" v-if="added_game_instances.length > 0">
        <div class="col">
          <b-table :items="added_game_instances" :fields="['game', 'game_master', 'booking_time', 'select']">
            <template #head(select)="data">
              <span></span>
            </template>
            <template v-slot:cell(select)="row">
              <base-button
                @click.native="removeGameInstance(row.index)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </template>
            <template v-slot:cell(game)="row">
              <span>{{row.item.name}}</span>
            </template>
            <template v-slot:cell(game_master)="row">
              <span>{{row.item.first_name}} {{row.item.last_name}}</span>
            </template>
            <template v-slot:cell(booking_time)="row">
              <span>{{getTime(row.item.booking_time)}}</span>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row" v-if="added_bookings.length > 0">
        <div class="col">
          <b-table :items="added_bookings" :fields="['game', 'status', 'booking_time', 'select']">
            <template #head(select)="data">
              <span></span>
            </template>
            <template v-slot:cell(select)="row">
              <base-button
                @click.native="removeBooking(row.index)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </template>
            <template v-slot:cell(status)="row">
              <badge v-if="row.item.status === 'available' || row.item.status === 'completed'" type="success">{{row.item.status}}</badge>
              <badge v-if="row.item.status === 'booked'" type="info">{{row.item.status}}</badge>
              <badge v-if="row.item.status === 'expired'" type="default">passed</badge>
              <badge v-if="row.item.status === 'cancelled' || row.item.status === 'blocked'"
                     type="danger">{{row.item.status}}</badge>
              <badge v-if="row.item.status === 'call_to_book'"
                     type="warning">call to book</badge>
              <badge v-if="row.item.status === 'checked'"
                     type="warning">checked in, no booking</badge>
            </template>
            <template v-slot:cell(game)="row">
              <span style="display: inline-block">{{row.item.name}}</span>
            </template>
            <template v-slot:cell(booking_time)="row">
              <span>{{getTime(row.item.booking_time)}}</span>
            </template>
          </b-table>
        </div>
      </div>


      <div class="row justify-content-center mt-3">
        <div class="col text-center">
          <base-button type="secondary" @click="modals.send = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="sendSurvey()">Send</base-button>
        </div>
      </div>
    </card>
  </modal>

  <!--    QR code-->
  <modal :show.sync="modals.qr"
         body-classes="p-0"
  >
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4 class="card-title">QR code</h4>

      <div class="row mt-2 justify-content-center">
        <div class="col text-center">
          <qrcode-vue id="qr" :value="qr.value" :size="qr.size" level="H"></qrcode-vue>
        </div>
      </div>

      <div class="row d-none d-sm-block text-center mt-3">
        <div class="col">
          <base-button type="secondary" @click="modals.qr = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="downloadQr()">
            <i class="tim-icons icon-cloud-download-93 mr-1"></i>Download</base-button>
        </div>
      </div>
    </card>
  </modal>
</div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from 'src/api-config'
  import swal from "sweetalert2";
  import {BTable} from "bootstrap-vue";
  import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
  import axios from "axios";
  import {Autocomplete, Dropdown, DropdownItem, DropdownMenu} from 'element-ui';
  import Badge from "@/components/Badge"
  import BaseSwitch from "@/components/BaseSwitch";
  import BaseAlert from "@/components/BaseAlert";
  import _ from "lodash";
  import QrcodeVue from "qrcode.vue";

  export default {
    name: "Surveys",
    components: {
      BaseAlert,
      BaseSwitch,
      BTable,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Autocomplete.name]: Autocomplete,
      Badge,
      QrcodeVue
    },
    computed: {
      categories() {
        return [{name: 'Existing customers', icon: 'tim-icons icon-user-run', active: true},
          {name: 'New customers', icon: 'tim-icons el-icon-plus', active: false},
          {name: 'Games', icon: 'tim-icons icon-puzzle-10', active: false},
          {name: 'Bookings', icon: 'tim-icons icon-cart', active: false},
          {name: 'Date range', icon: 'tim-icons icon-calendar-60', active: false},
        ];
      },
    },
    data() {
      return {
        feature_not_enabled: false,
        current_game_instance: '',
        current_booking: '',
        date_format_cal: '',
        day_of_week: '',
        surveys: '',
        selectedSurvey: '',
        selectedGame: '',
        loading: false,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        customers: [],
        game_instances: [],
        bookings: [],
        games: [],
        limit_game_by_date_played: false,
        game_date_range: '',
        added_games: [],
        added_game_instances: [],
        added_bookings: [],
        activeTabIndex: 0,
        k: 0,
        lookup_value: '',
        user: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          end_user_id: ''
        },
        placeholder_phone: '(999) 999-9999',
        errs: {
          first_name: '',
          last_name: '',
          email: '',
          game: ''
        },
        qr: {
          value: 'https://example.com',
          size: 300
        },
        modals: {
          send: false,
          qr: false
        }
      }
    },
    methods: {
      goToBilling(){
        this.$router.push('/settings/billing')
      },
      generateQrCode(hash){
        this.qr.value = 'https://offthecouch.io/surveys/' + hash
        this.modals.qr = true
      },
      downloadQr(){
        var link = document.createElement('a');
        link.download = 'qr.png';
        console.log(document.getElementsByTagName("canvas"))
        link.href = document.getElementsByTagName('canvas')[0].toDataURL()
        link.click();
      },
      viewSurvey(item){
        this.$router.push({ path: '/surveys/results', query: { id:  item.id }})
      },
      async getGameInstances (type) {
        let date = this.current_game_instance
        if(type === 8){
          date = this.current_booking
        }
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings?type=' + type + '&day=' + date

        this.axios.get(url, config)
          .then(response => {
            if(type === 7){
              this.game_instances = response.data.bookings
            } else if(type === 8){
              this.bookings = response.data.bookings
            }

            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      clearFields(){
        this.lookup_value = ''
        this.activeTabIndex = 0
        this.selectedGame = ''
        this.errs = {
          first_name: '',
            last_name: '',
            email: ''
        }
        this.user = {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          end_user_id: ''
        }
        this.current_booking = ''
        this.game_date_range = ''
        this.current_game_instance = ''
        this.customers = []
        this.added_games = []
        this.added_game_instances = []
        this.bookings = []
      },
      removeBooking(index){
        this.added_bookings.splice(index, 1)
      },
      removeGameInstance(index){
        this.added_game_instances.splice(index, 1)
      },
      removeGame(index){
        this.added_games.splice(index, 1)
      },
      addBooking(item){
        let found = _.find(this.added_bookings, {booking_slot_id: item.booking_slot_id})

        if(!found){
          this.added_bookings.push(item)
        }
      },
      addGameInstance(item){
        let found = _.find(this.added_game_instances, {id: item.id})

        if(!found){
          this.added_game_instances.push(item)
        }
      },
      addGames(){
        if(!this.selectedGame){
          this.errs.game = 'Please choose a game'
          return
        } else {
          this.errs.game = ''
        }
        let found = _.find(this.added_games, {id: this.selectedGame.id})

        if(!found){
          this.added_games.push({
            id: this.selectedGame.id,
            name: this.selectedGame.name,
            date_range: this.game_date_range
          })
        }
      },
      removeCustomer(index){
        this.customers.splice(index, 1);
      },
      validateCustomer(){
        let errors = 0

        if(this.user.first_name === ''){
          this.errs.first_name = "First name cannot be empty"
          errors++
        } else {
          this.errs.first_name = ''
        }

        if(this.user.last_name === ''){
          this.errs.last_name = "Last name cannot be empty"
          errors++
        } else {
          this.errs.last_name = ''
        }

        if(this.user.email === ''){
          this.errs.email = "Email cannot be empty"
          errors++
        } else {
          this.errs.email = ''
        }
        return errors > 0 ? false : true
      },
      addCustomer(){
        if(!this.validateCustomer()){
          return
        }
        this.customers.push(this.user)
        this.clearCustomer()
      },
      clearCustomer(){
        this.lookup_value = ''
        this.user = {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          end_user_id: ''
        }
      },
      async querySearch(queryString, cb) {
        //make API call to /customers with queryString as filter
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
        axios.get(url, config)
          .then(response => {
            let suggestions = []
            for(let n in response.data.customers){
              if(response.data.customers[n].email != null
                && response.data.customers[n].first_name != null
                && response.data.customers[n].first_name != ''){
                suggestions.push({
                  value: JSON.stringify(response.data.customers[n]),
                  email: response.data.customers[n].email,
                  id: response.data.customers[n].id,
                  end_user_id: response.data.customers[n].end_user_id,
                  first_name: response.data.customers[n].first_name,
                  last_name: response.data.customers[n].last_name,
                  phone: response.data.customers[n].phone
                })
              }

            }
            const results = suggestions;

            cb(results)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      createFilter(queryString) {
        return (suggestion) => {
          return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
        };
      },
      handleSelect(item){
        this.lookup_value = item.email
        this.user.first_name = item.first_name
        this.user.last_name = item.last_name
        this.user.email = item.email
        this.user.phone = item.phone
        this.user.end_user_id = item.end_user_id
      },
      switchTab(index){
        this.activeTabIndex = index
        for(let n in this.categories){
          this.categories[n].active = false
        }
        this.categories[index].active = true
        this.k++
      },
      sendToCustomersModal(item){
        this.switchTab(0)
        this.selectedSurvey = item
        this.modals.send = true
      },
      deleteSurveyPrompt(item){
        swal({
          title: 'Are you sure?',
          text: `The survey will be permanently deleted`,
          type: 'warning',
          showCancelButton: true
        }).then((response)=> {
          if(response.value == true){
            this.deleteSurvey(item)
          }
        })
      },
      getLocalTime(d){
        let local = new Date(d)
        return this.getTime(local.getHours() + ':' + local.getMinutes())
      },
      getLocalDate(d){
        let local = new Date(d)
        let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
        let final = this.getFormattedDate(form)
        return this.getDayOfWeek(form) + final
      },
      getTime(d) {
        return time(this.time_format, d)
      },
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      getFormattedDate(d){
        if(d instanceof Date){
          d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
        }
        return formattedDate(this.date_format, d)
      },
      async sendSurvey(){
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + this.selectedSurvey.id + '/send'
        let data = {
          customers: this.customers,
          games: this.added_games,
          game_instances: this.added_game_instances,
          bookings: this.added_bookings
        }
        this.loading = true
        this.axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.modals.send = false
            swal('Success', 'Survey has been sent to customers ', 'success')
            this.clearFields()
            this.getSurveys()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              this.modals.send = false
              swal({
                title: 'Error',
                html: err.response.data,
                type: 'error',
              }).then((response)=> {
                if(err.response.data === 'Not enabled')
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async createNewSurvey(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys'
        let data = {
          survey: {
            name: '',
            description: ''
          },
        }

        this.axios.post(url, data, config)
          .then(response => {
            this.loading = false
            // swal('Success', 'Survey has been created', 'success')
            this.$router.push({ path: '/surveys/edit', query: { id:  response.data }})
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      hasPermissions(scope){
        let permissions = JSON.parse(localStorage.getItem('permissions'))
        const matchFound = permissions.some(element => element === scope);
        return matchFound
      },
      activateSurveyPrompt(action, item){
        let html = ''
        if(action === 'deactivate'){
          html = 'Deactivating the survey will make it inaccessible to customers.'
        }
        else if (action === 'activate'){
          html = 'Activating the survey will make it accessible to customers.'
        }

        swal.fire({
          title: 'Are you sure?',
          text: html,
          type: 'warning',
          showCancelButton: true
        }).then((response)=> {
          if(response.value == true){
            this.activateSurvey(action, item);
          }
        })

      },
      async activateSurvey(action, item){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let groupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/surveys/' + item.id + '/activate'

        let data = {
          active:  action === 'activate' ? 1 : 0,
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            swal.fire({
              title: 'Success!',
              text: `Survey has been ` + action + `d`,
              type: 'success',
            })
            this.getSurveys()

          }).catch((err) => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
        })
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async deleteSurvey(item){
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + item.id
        this.axios.delete(url, config)
          .then(response => {
            swal('Success', 'The survey has been deleted', 'success')
            this.getSurveys()
          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async getSurveys () {
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys'
        this.axios.get(url, config)
          .then(response => {
            this.surveys = response.data.surveys
            this.games = response.data.games
          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              this.feature_not_enabled = true
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      editSurvey(item){
        this.$router.push({ path: '/surveys/edit', query: { id:  item.id }})
      }
    },
    mounted () {
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      if(JSON.parse(localStorage.getItem('group')).country === 'GB'){
        this.placeholder_phone = '07700 900000'
      }
      this.getSurveys()
    }
  }
</script>

<style scoped>
</style>
