<template>
  <div>
    <h5 class="info-text">
      Add some puzzles!
    </h5>
    <div class="row d-none d-sm-block">
      <div class="col-md-12 col-lg-12 col-sm-12 col-12">
        <table class='table' v-if="model.puzzles.length > 0">
          <thead>
          <tr>
            <th scope='col'>Order</th>
            <th scope='col'>Name</th>
            <th scope='col'>Description</th>
            <th scope='col'>Solution</th>
            <th scope='col'>Image</th>
            <th class="text-right" scope='col'>Actions</th>
          </tr>
          </thead>
          <tbody tag='tbody' v-model='model.puzzles' is='draggable'>
          <tr v-for='(item, index) in model.puzzles' :key="'Y' + index" style="cursor: move">
            <td scope='row'>
              {{ index  + 1}}
            </td>
            <td scope='row'>
              <base-input style="max-width: 300px">
                        <textarea class="form-control" v-model="item.name"
                                  placeholder="Name" rows="1"></textarea>
              </base-input>
            </td>
            <td scope='row'>
              <base-input>
                        <textarea class="form-control" v-model="item.description"
                                  placeholder="Description" rows="1"></textarea>
              </base-input>
            </td>
            <td scope='row'>
              <base-input>
                        <textarea class="form-control" v-model="item.solution"
                                  placeholder="Solution" rows="1"></textarea>
              </base-input>
            </td>
            <td scope='row'>
              <img v-if="item.solution_img" slot="image" style="width: 50px; height:50px;" :src="item.solution_img"  alt="Card image cap"/>
              <span v-else>-</span>
            </td>
            <td scope='row' class="text-right">

              <el-tooltip content="Delete puzzle"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button
                  @click.native="deletePuzzle(index)"
                  class="like btn-link"
                  type="danger"
                  icon
                ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip content="Add image"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button
                  class="like btn-link btn-file"
                  type="success"
                  icon
                ><i class="tim-icons icon-camera-18" style="font-size: large"></i>
                  <input type="file"
                         accept="image/*"
                         @change="handleUpload(item)"
                  />
                </base-button>
              </el-tooltip>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-3 d-block d-sm-none" >
      <div class="col-md-12 col-lg-12 col-sm-12 col-12">
        <table class='table' v-if="model.puzzles.length > 0">
          <thead>
          <tr>
            <th scope='col'>Puzzle</th>
          </tr>
          </thead>
          <tbody tag='tbody' v-model='model.puzzles' is='draggable'>
          <tr v-for='(item, index) in model.puzzles' :key="'A' + index" style="cursor: move">
            <td scope='row'>
              <div class="row">
                <div class="col">
                  <base-input label="Puzzle name">
                        <textarea class="form-control" v-model="item.name"
                                  placeholder="Name" rows="1"></textarea>
                  </base-input>
                </div>
                <div class="col text-right">
                  <el-tooltip content="Delete puzzle"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="deletePuzzle(item, index)"
                      class="like btn-link"
                      type="danger"
                      icon
                    ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Add image"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      class="like btn-link btn-file"
                      type="success"
                      icon
                    ><i class="tim-icons icon-camera-18" style="font-size: large"></i>
                      <input type="file"
                             accept="image/*"
                             @change="handleUpload(item)"
                      />
                    </base-button>
                  </el-tooltip>
                </div>

              </div>

              <base-input label="Puzzle description">
                        <textarea class="form-control" v-model="item.description"
                                  placeholder="Description" rows="1"></textarea>
              </base-input>
              <base-input label="Puzzle solution">
                        <textarea class="form-control" v-model="item.solution"
                                  placeholder="Solution" rows="1"></textarea>
              </base-input>
              <img v-if="item.solution_img" slot="image" style="width: 100%; height: 250px;" :src="item.solution_img"  alt="Card image cap"/>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-11 col-lg-12 col-md-12 col-sm-12">
        <base-button simple v-on:click="addNewPuzzle()" size="sm" type="primary">
          <i class="tim-icons el-icon-plus"></i> Add puzzle</base-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {API_LOCATION} from "@/api-config";
  import axios from "axios";
  import draggable from 'vuedraggable'
  import {Auth} from "aws-amplify";

  export default {
    components: {
      draggable
    },
    data() {
      return {
        model: {
          puzzles: [{
            name: "",
            description: "",
            solution: "",
            solution_img: '',
            position: 1
          }]
        },
        editing: [],
        file: '',
        uploadUrl: '',
        modelValidations: {
        }
      };
    },
    methods: {
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      uploadFile (file) {
        let url = this.uploadUrl
        let config = { headers: {
            'Content-Type': file.type
          }}

        let payload = file
        return new Promise ( function(resolve) {
          axios.put(url, payload, config)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async getSignedURL (file) {
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'upload'

        let payload = {
          filePath: file.name,
          contentType: file.type
        }

        return new Promise ( function(resolve) {
          axios.post(url, payload, config)
            .then((res) => {
              resolve(res.data)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async saveFile() {
        if(this.file != ''){
          this.uploadUrl = await this.getSignedURL(this.file)
          let uploaded = await this.uploadFile(this.file)
          //Get the image URL
          let imgUrl = this.uploadUrl.split("?")
        }
      },
      async handleUpload(item){
        let file = event.target.files[0];
        item.uploaded_image = URL.createObjectURL(file);

        this.file = file
        this.$emit('change', file);
        this.loading = true
        let save = await this.saveFile()
        item.solution_img = this.uploadUrl.split("?")[0]
        this.loading = false
      },
      addNewPuzzle(){
        var data = {
          name: "",
          description: "",
          solution: "",
          solution_img: '',
          position: this.model.puzzles.length
        }
        this.model.puzzles.push(data)
      },
      deletePuzzle(index){
        this.model.puzzles.splice(index, 1)
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model);
          return res;
        });
      }
    }
  };
</script>

<style scoped>

</style>
