<template>
<div>
  <!--    Loader-->
  <!-- Contenedor donde se mostrara el Botón de Pago Yappy -->
  <head id=“Yappy_Checkout_Button”></head>
<p class="text-primary">hihi</p>
  <loading-panel :loading="loading"></loading-panel>
<!-- Header-->
<!--  <div class="row">-->
<!--    <div class="col-lg-12">-->
<!--      <card class="card-subcategories" card-body-classes="table-full-width">-->
<!--        <img slot="image" class="card-img-bottom fixed-size-img" :src="booking.img"  alt="Card image cap"/>-->
<!--        <h3 slot="header" class="card-title">{{booking.game_name}}-->

<!--&lt;!&ndash;          <badge v-if="booking.status != 'checked'" type="success">{{ booking.status }}</badge>&ndash;&gt;-->
<!--          <badge v-if="booking.status == 'available'" type="success">available</badge>-->
<!--          <badge v-if="booking.status == 'completed'" type="success">completed</badge>-->
<!--          <badge v-if="booking.status == 'booked'" type="primary">upcoming</badge>-->
<!--          <badge v-if="booking.status === 'cancelled'" type="danger">cancelled</badge>-->
<!--          <badge v-if="booking.status == 'checked'" type="warning">checked in</badge>-->
<!--        </h3>-->
<!--        <div class="row">-->
<!--          <div v-if="booking.transaction_id != null" class="col-lg-3">-->
<!--            <card class="card-stats card-white" :show-footer-line="true">-->
<!--              <div class="row">-->
<!--                <div class="col-3">-->
<!--                  <div class="info-icon text-center">-->
<!--                    <i class="tim-icons icon-calendar-60"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-9">-->
<!--                  <div class="numbers">-->
<!--                    <slot>-->
<!--                      <p class="card-category">Date</p>-->
<!--                      <h1 class="card-title">{{getFormattedDate(booking.booking_date)}} &nbsp;</h1>-->
<!--                    </slot>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </card>-->
<!--          </div>-->

<!--          <div v-if="booking.transaction_id != null" class="col-lg-3">-->
<!--            <card class="card-stats card-white" :show-footer-line="true">-->
<!--              <div class="row stats-margin">-->
<!--                <div class="col-3">-->
<!--                  <div class="info-icon text-center">-->
<!--                    <i class="tim-icons icon-time-alarm"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-9">-->
<!--                  <div class="numbers">-->
<!--                    <slot>-->
<!--                      <p class="card-category">Start time</p>-->
<!--                      <h1 class="card-title">{{ getTime(booking.start_time) }}</h1>-->
<!--                    </slot>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </card>-->
<!--          </div>-->
<!--          <div v-if="booking.transaction_id != null" class="col-lg-3">-->
<!--            <card class="card-stats card-white" :show-footer-line="true">-->
<!--              <div class="row stats-margin">-->
<!--                <div class="col-3">-->
<!--                  <div class="info-icon text-center">-->
<!--                    <i class="tim-icons icon-money-coins"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-9">-->
<!--                  <div class="numbers">-->
<!--                    <slot>-->
<!--                      <p class="card-category">Price</p>-->
<!--                      <h1 class="card-title">{{ getAmount(booking.price) }}</h1>-->
<!--                    </slot>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </card>-->
<!--          </div>-->
<!--          <div v-if="booking.transaction_id != null" class="col-lg-3">-->
<!--            <card class="card-stats card-white" :show-footer-line="true">-->
<!--              <div class="row stats-margin">-->
<!--                <div class="col-3">-->
<!--                  <div class="info-icon text-center">-->
<!--                    <i class="tim-icons icon-user-run"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-9">-->
<!--                  <div class="numbers">-->
<!--                    <slot>-->
<!--                      <p class="card-category">Group size</p>-->
<!--                      <h1 class="card-title">{{ booking.group_size }} players</h1>-->
<!--                    </slot>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </card>-->
<!--          </div>-->
<!--          <div class="row ml-0">-->
<!--            <div class="col">-->
<!--&lt;!&ndash;              <base-button v-on:click="addPayment()" simple type="success" size="sm" class="mr-2">Add payment</base-button>&ndash;&gt;-->
<!--              <base-button v-if="gameInstanceId.length > 0" v-on:click="viewGameInstance()" simple type="info" size="sm">View completed game details</base-button>-->
<!--              <base-button v-if="booking.status == 'booked'" v-on:click="cancelBooking()" simple type="danger" size="sm">Cancel booking</base-button>-->
<!--              <base-button v-if="booking.status == 'available' || booking.status == 'checked'" v-on:click="deleteBooking()" simple type="danger" size="sm">Delete booking</base-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </card>-->
<!--    </div>-->
<!--  </div>-->

<!--  &lt;!&ndash;  Waivers&ndash;&gt;-->
<!--  <div class="row" id="waivers">-->
<!--    <div class="col-lg-8">-->
<!--      <card>-->
<!--        <h4 slot="header" class="card-title">Customer waivers</h4>-->
<!--        <div v-if="waivers.length <= 0" class="mt-3" >-->
<!--          <div class="font-icon-detail">-->
<!--              <span style="font-size: 3em">-->
<!--                <i class="fas fa-exclamation-triangle"></i>-->
<!--              </span>-->
<!--            <h4 class="mt-3">No waivers have been signed.</h4>-->
<!--          </div>-->
<!--        </div>-->
<!--        <b-table v-if="waivers.length > 0" :items="waivers" :fields="waiverFields">-->
<!--          <template v-slot:cell(customer_details)="row">-->
<!--            <p>{{ row.item.first_name }} {{row.item.last_name}}</p>-->
<!--            <p v-if="row.item.birthday" class="text-warning">-->
<!--              <i class="tim-icons icon-gift-2"></i> {{getFormattedDate(row.item.birthday)}}-->
<!--            </p>-->
<!--            <p v-if="row.item.email != null">-->
<!--              <i class="tim-icons icon-email-85"></i> {{ row.item.email }}-->
<!--            </p>-->
<!--            <p v-if="row.item.phone != null && row.item.phone.length > 0 ">-->
<!--              <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}-->
<!--            </p>-->
<!--          </template>-->
<!--          <template v-slot:cell(date_signed)="row">-->
<!--            <p><i class="tim-icons icon-calendar-60 mr-1"></i> {{ getLocalDate(row.item.created_time) }}-->
<!--              <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getLocalTime(row.item.created_time)}}</p>-->
<!--          </template>-->
<!--          <template v-slot:cell(signature)="row">-->
<!--            <p v-if="!row.item.signature" class="text-white">-</p>-->
<!--            <img v-if="row.item.signature" style="background-color: white" :src="renderSignature(row.item.signature)" height="50">-->
<!--          </template>-->

<!--          <template v-slot:cell(actions)="row">-->
<!--            <div class="row">-->
<!--              <base-button-->
<!--                @click="viewCustomerDetails(row.item.user_id)"-->
<!--                simple-->
<!--                type="info"-->
<!--                size="sm"-->
<!--              >View customer details-->
<!--              </base-button>-->
<!--            </div>-->
<!--            <div class="row mt-2">-->
<!--              <vue-html2pdf-->
<!--                :show-layout="false"-->
<!--                :float-layout="true"-->
<!--                :enable-download="true"-->
<!--                :preview-modal="true"-->
<!--                :paginate-elements-by-height="1400"-->
<!--                filename="waiver"-->
<!--                :pdf-quality="2"-->
<!--                :manual-pagination="false"-->
<!--                pdf-format="a4"-->
<!--                pdf-orientation="landscape"-->
<!--                pdf-content-width="1000px"-->
<!--                @beforeDownload="beforeDownload($event)"-->
<!--                ref="html2Pdf"-->
<!--              >-->
<!--                <section slot="pdf-content">-->
<!--                  <div class="ml-5 mt-5">-->
<!--                    <p style="font-size: large" class="text-primary"> {{ selectedWaiver.game_name }} </p>-->
<!--                    <p style="color: black;">-->
<!--                      {{getFormattedDate(selectedWaiver.booking_date)}}, {{getTimeFromTime(selectedWaiver.start_time)}}-->
<!--                    </p>-->
<!--                    <p v-if="selectedWaiver.waiver_text" class="mt-2" style="color: black; font-size: xx-small">{{selectedWaiver.waiver_text}}</p>-->
<!--                    <p style="color: black;">Signed on {{ getLocalDate(selectedWaiver.created_time) }}, {{getLocalTime(selectedWaiver.created_time)}}</p>-->
<!--                    <p style="color: black;">Signed by {{ selectedWaiver.customer }}, {{selectedWaiver.email}}</p>-->
<!--                  </div>-->
<!--                  <section class="pdf-item">-->
<!--                    <div class="ml-5 mt-1">-->
<!--                      <p style="color: black;">Signature</p>-->
<!--                      <img :src="selectedWaiver.signature">-->
<!--                    </div>-->

<!--                  </section>-->
<!--                </section>-->
<!--              </vue-html2pdf>-->
<!--              <base-button-->
<!--                @click="downloadWaiver(row.item)"-->
<!--                simple-->
<!--                type="success"-->
<!--                size="sm"-->
<!--              >Download PDF-->
<!--              </base-button>-->
<!--            </div>-->
<!--          </template>-->
<!--        </b-table>-->
<!--      </card>-->
<!--    </div>-->
<!--    <div class="col-lg-4">-->
<!--      <card>-->
<!--        <h4 slot="header" class="card-title">Notes</h4>-->
<!--        <div style="overflow: scroll; max-height: 250px;">-->
<!--          <div class="row" v-for="(n, i) in notes" :key="'N' + i">-->
<!--            <div class="col">-->
<!--              <base-alert style="margin-bottom: -5px;" class="text-white mt-2">-->
<!--                <div class="row" style="margin-top: -10px;">-->
<!--                  <div class="col-1 ml-auto mr-1">-->
<!--                    <el-tooltip content="Delete note"-->
<!--                                effect="light"-->
<!--                                :open-delay="300"-->
<!--                                placement="top">-->
<!--                      <base-button-->
<!--                        @click.native="deleteNote(n.id)"-->
<!--                        class="like btn-link text-white"-->
<!--                        size="sm"-->
<!--                        icon-->
<!--                      ><i class="tim-icons icon-simple-remove"></i>-->
<!--                      </base-button>-->
<!--                    </el-tooltip>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row" style="margin-top: -20px;">-->
<!--                  <div class="col">-->
<!--                    {{n.note}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </base-alert>-->
<!--              <div class="mt-1">-->
<!--                <small class="font-italic">{{getLocalDate(n.timestamp)}}, {{getLocalTime(n.timestamp)}}, {{n.first_name}} {{n.last_name}}-->
<!--                </small>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="row justify-content-center mt-4">-->
<!--          <div class="col">-->
<!--             <textarea-->
<!--               class="form-control"-->
<!--               placeholder="Add new comment for this booking"-->
<!--               v-model="note"-->
<!--               name="notes"-->
<!--               rows="9">-->
<!--                  </textarea>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row mt-2">-->
<!--          <div class="col">-->
<!--            <base-button simple type="primary" @click="addNewComment()">Save</base-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </card>-->
<!--    </div>-->
<!--  </div>-->



  <!--    Add Payment Modal-->
  <modal :show.sync="modals.add_payment"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add payment for {{booking.game_name}}</h4>
      <div class="row mt-1">
        <div class="col-3">
          <p class="text-white text-uppercase">Quantity <span class="text-primary font-italic">{{getAmount(ppp)}}</span></p>
          <base-input>
            <el-input-number v-model="number_of_players" :min="1" @change="getSubtotal()" />
          </base-input>
        </div>
        <div class="col-4">
          <p class="text-white text-uppercase">subtotal</p>
          <h2 class="warning">{{ getAmount(subtotal) }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input label="First name" v-model="user.first_name" placeholder="First name"></base-input>
          <p v-show="errors.first_name" class="text-danger small">First name cannot be empty</p>
        </div>
        <div class="col-6">
          <base-input label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
          <p v-show="errors.last_name" class="ml-1 text-danger small">Last name cannot be empty</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input label="Email" v-model="user.email" placeholder="Email"></base-input>
          <p v-show="errors.email" class="ml-1 text-danger small">Please provide a valid email</p>
        </div>
        <div class="col-6">
          <base-input label="Phone (Optional)" v-mask="'(###) ###-####'" v-model="user.phone" placeholder="(999) 999-9999"></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-input v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
          <p class="text-danger small" v-if="errors.ccn==true">Please provide a valid credit card number</p>
        </div>

      </div>
      <div class="row">
        <div class="col-4">
          <base-input v-mask="'NNNNNNNN'" placeholder="11111" v-model="payment.zip" type="text" label="Zip code"></base-input>
          <p class="text-danger small" v-if="errors.zip==true">Please provide a valid zip code</p>
        </div>
        <div class="col-4">
          <base-input v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
          <p class="text-danger small" v-if="errors.expiration==true">Please provide a valid expiration date</p>
        </div>
        <div class="col-4">
          <base-input v-mask="'###'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
          <p class="text-danger small" v-if="errors.cvv==true">Please provide a valid CVV code</p>
        </div>
      </div>

      <div class="row d-none d-sm-block text-center mt-3">
        <base-button type="secondary" @click="modals.add_payment = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="addPaymentToBooking()">Save</base-button>
      </div>
      <div class="row d-block d-sm-none text-center mt-3">
        <base-button simple size="sm" type="secondary" @click="modals.add_payment = false">Cancel</base-button>
        <base-button simple size="sm" class="ml-3" type="primary" @click="addPaymentToBooking()">Save</base-button>
      </div>
    </card>
  </modal>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import {Auth} from "aws-amplify";
import {time, formattedDate, simpleTime} from "@/plugins/dateFormatter";
import { BTable } from 'bootstrap-vue'
import { Badge, BaseAlert, TimeLine, TimeLineItem } from 'src/components';
import VueHtml2pdf from 'vue-html2pdf'
import swal from "sweetalert2";
import currencies from "@/assets/currencies.json";


export default {
  name: "BookingDetails",
  components: {
    BTable,
    Badge,
    VueHtml2pdf,
    BaseAlert,
    TimeLine,
    TimeLineItem
  },
  data(){
    return {
      loading: false,
      email_active: 0,
      payments: [],
      note: '',
      notes: [],
      ppp: 0,
      subtotal: 0,
      number_of_players: 0,
      waivers: [],
      pricing: [],
      activity: [],
      booking: '',
      gameInstanceId: [],
      paymentFields: ['payment_details', 'amount', 'actions'],
      waiverFields: ['customer_details', 'date_signed', 'signature', 'actions'],
      selectedWaiver: '',
      modals: {
        add_payment: false
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      errors: {
        ccn: false,
        first_name: false,
        last_name: false,
        email: false
      },
      payment: {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
    }
  },
  methods: {
    async deleteNote(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + this.booking.id + '/notes/' + id

      this.axios.delete(url, config)
        .then(response => {
          this.getBookingSlot()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        })
    },
    getInitials(item){
      if(item == null){
        return
      } else if(item.company_user_first_name) {
        return item.company_user_first_name[0].toUpperCase() + item.company_user_last_name[0].toUpperCase()
      } else if(item.first_name){
        return item.first_name[0].toUpperCase() + item.last_name[0].toUpperCase()
      }
    },
    async addNewComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + this.booking.id + '/notes'
      let data = {
        note: this.note
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.note = ''
          this.getBookingSlot()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        })
    },
    getSubtotal(){
      for(let n in this.pricing){
        if(this.pricing[n].quantity == this.number_of_players){
          this.ppp = this.pricing[n].price
          this.subtotal = (parseFloat(this.number_of_players) * parseFloat(this.ppp)).toFixed(2)
          return
        }
      }
      if(this.number_of_players > this.pricing[this.pricing.length -1].quantity){
        this.ppp = this.pricing[this.pricing.length -1].price
        this.subtotal = (parseFloat(this.number_of_players) * parseFloat(this.ppp)).toFixed(2)
      }
    },
    getTimeFromTime(d){
      return simpleTime(d)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    downloadWaiver(waiver){
      console.log(waiver)
      this.selectedWaiver = {
        game_name: this.booking.game_name,
        created_time: waiver.created_time,
        waiver_text: waiver.waiver_text,
        booking_date: this.booking.booking_date,
        start_time: this.booking.start_time,
        customer: waiver.first_name + ' ' + waiver.last_name,
        email: waiver.email,
        signature: this.renderSignature(waiver.signature)
      }
      this.$refs.html2Pdf.generatePdf()

    },
    renderSignature(blob){
      if(blob == null){
        return ''
      }
      var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(blob.data)));
      let final = atob(base64String)
      return final
    },
    getDueAmount(item){
      let due = (parseFloat(item.due_amount) - parseFloat(item.paid_amount)).toFixed(2)
      return '$' + due
    },
    getTotalAmountDue(){
      let total = 0
      for(let n in this.payments){
        total = parseFloat(total) + (parseFloat(this.payments[n].due_amount) - parseFloat(this.payments[n].paid_amount))
      }
      return parseFloat(total).toFixed(2)
    },
    getTotalAmountPaid(){
      let total = 0
      for(let n in this.payments){
        total = parseFloat(total) + this.payments[n].paid_amount
      }
      return parseFloat(total).toFixed(2)
    },
    getCheckedInNumber(){
      return this.waivers.length
    },
    getGroupSize(){
      let total = 0
      for(let n in this.payments){
        total = total + this.payments[n].group_size
      }
      return total
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    addPayment(){
      this.modals.add_payment = true
    },
    getStatus(){
      let status = ''
      if(this.booking.status == 'booked'){
        status = 'Booked'
      } else if (this.booking.status == 'checked'){
        status = 'Some users checked in'
      } else if (this.booking.status == 'completed') {
        status = 'Completed'
      }
      return status
    },
    getPaymentType(type){
      if(type == 'none'){
        return 'None'
      } else if (type == 'credit_card'){
        return 'Credit card'
      } else {
        return type
      }
    },
    getLocalTime(d){
      let local = new Date(d)
      let mins =  local.getMinutes()
      if(mins < 10){
        mins = '0' + mins
      }
      return this.getTime(local.getHours() + ':' + mins)
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return final
    },
    viewCustomerDetails(user_id){
      this.$router.push({ path: '/customers/details', query: { id:  user_id }})
    },
    viewGameInstance(){
      this.$router.push({ path: '/games/history/instance', query: { id:   this.gameInstanceId[0].id }})
    },
    getFormattedDate(d){
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getBookingSlot(){
      let bookingSlotId = localStorage.getItem("bookingSlotId")
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + bookingSlotId
      this.axios.get(url, config)
        .then(response => {
          this.payments = response.data.payments
          this.notes = response.data.notes
          this.booking = response.data.booking
          this.gameInstanceId = response.data.gameInstanceId
          this.waivers = response.data.waivers
          this.pricing = response.data.pricing
          this.ppp = response.data.pricing[0].price
          this.subtotal = response.data.pricing[0].price
          this.activity = response.data.activity
          this.email_active = response.data.emailSettings[0].is_active
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }else if(err.response.status == 500){
            swal({
              title: 'Ooops',
              text: 'Booking does not exist',
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Back to bookings'
            }).then((response)=> {
              if(response.value == true){
                this.$router.push('/bookings')
              }
            })
          }
        })

    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    async addPaymentToBooking() {

      //validation
      let error = 0
      if(this.user.first_name == '') {
        this.errors.first_name = true
        error++
      } else {
        this.errors.first_name = false
      }
      if(this.user.last_name == '') {
        this.errors.last_name = true
        error++
      } else {
        this.errors.last_name = false
      }
      if(this.isEmailValid(this.user.email) == false) {
        this.errors.email = true
        error++
      } else {
        this.errors.email = false
      }
      if(error > 0){
        return
      }

      this.loading = true
      this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.booking.transaction_id + '/payments'
      let data = {
        number_of_players: this.number_of_players,
        subtotal: this.subtotal,
        user: this.user,
        payment: this.payment,
        game_id: this.booking.game_id,
        game_name: this.booking.game_name,
        booking_date: this.booking.booking_date,
        booking_id: this.booking.id,
        start_time: this.booking.start_time
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.modals.add_payment = false
          this.number_of_players = 0
          this.user.first_name = ''
          this.user.last_name = ''
          this.user.email = ''
          this.payment = {
            cardNumber: '',
            expirationDate: '',
            cardCode: '',
            zip: ''
          }

          this.getBookingSlot()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    },
    deleteBooking(){
      let html = '<small> Deleting this booking will result in the time slot becoming permanently removed from the calendar and unavailable for customers to book.' +
        '</small>'

      swal({
        title: 'Cancel booking',
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.cancelBookingRequest('delete');
        }
      })
    },
    cancelBooking(){
      let html = '<small>  Cancelling this booking will result in the time slot becoming available to book. Any amount paid will need to be manually refunded to the customer. <br>' +
        '</small>'

      swal({
        title: 'Cancel booking',
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.cancelBookingRequest('cancel');
        }
      })
    },
    async cancelBookingRequest(action){
      let bookingSlotId = localStorage.getItem("bookingSlotId")
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + bookingSlotId

      this.axios.delete(url, config)
        .then(response => {
          this.$router.push('/bookings')
          // if(action == 'delete'){
          //   this.$router.push('/bookings')
          // }else{
          //   this.getBookingSlot()
          //   this.loading = false
          // }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    },
    cancelTransaction(item){
      let html = '<small> Cancelling this transaction will result in money due back to the customer, and will need to be manually refunded. <br>' +
        'Note that cancelling the transaction will not cancel the booking.</small>'

      swal({
        title: 'Cancel transaction',
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.cancelRequest(item);
        }
      })
    },
    async cancelRequest(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.getBookingSlot()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    },
    async resendConfirmationEmail(item){
      let bookingSlotId = localStorage.getItem("bookingSlotId")
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + bookingSlotId + '/confirmation'

      let data = {
        transaction: item,
        email: item.email,
        game_id: this.booking.game_id,
        game_name: this.booking.game_name,
        booking_date: this.getFormattedDate(this.booking.booking_date),
        start_time: this.getTime(this.booking.start_time),
        payment_timestamp: this.getFormattedDate(item.payment_timestamp)
      }
      this.axios.post(url, data, config)
        .then(response => {
          //TODO swal alert
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    }
  },
  mounted(){
    // this.getBookingSlot()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 150px; /* height of container */
  object-fit: cover;
}

</style>
