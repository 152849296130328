<template>
<div>
  <h4 class="info-text">
    Group location details
  </h4>
  <div class="row justify-content-center">
    <div class="col-11 col-lg-6 col-md-8 col-sm-7">
      <div class="row">
        <div class="col-lg-12">
          <base-input label="Country">
            <el-select v-on:change="changeLabelFormat()"
              v-model="location.country"
              class="select-primary"
              name="email_group"
              placeholder="Country"
              value-key="code"
            >
              <el-option
                v-for="(c, i) in countries"
                class="select-primary"
                :label="c.name"
                :value="{name: c.name, code: c.code}"
                :key="'C' + i"
              ></el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input label="Phone number"
                      v-model="location.phone"
                      name="phone"
                      placeholder="Phone number">
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input label="Email"
                      v-model="location.email"
                      name="email"
                      placeholder="Email">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <base-input label="Address line 1"
                      v-model="location.address_line_1"
                      name="name"
                      placeholder="Address line 1">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <base-input label="Address line 2"
                      v-model="location.address_line_2"
                      name="name"
                      placeholder="Address line 2">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input :label="city_label"
                      v-model="location.city"
                      name="name"
                      :placeholder="city_label">
          </base-input>
        </div>
        <div class="col-lg-3">
          <base-input v-if="state_label === 'State'" :label="state_label">
            <el-select
              v-model="location.state"
              class="select-primary"
              name="email_group"
              :placeholder="state_label"
            >
              <el-option
                v-for="(s, i) in states"
                class="select-primary"
                :label="s.name"
                :value="s.name"
                :key="'S' + i"
              ></el-option>
            </el-select>
          </base-input>
          <base-input v-else :label="state_label" v-model="location.state"></base-input>
        </div>
        <div class="col-lg-3">
          <base-input :label="zip_label"
                      v-model="location.zip"
                      name="name"
                      :placeholder="zip_label">
          </base-input>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import countries from '/src/assets/countries.json'
import states from '/src/assets/states.json'

export default {
  name: "GroupLocationDetails",
  data(){
    return {
      countries: countries.countries,
      states: states.states,
      city_label: 'City',
      zip_label: 'Zip',
      state_label: 'State',
      location: {
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        country: {"name": "United States", "code": "US"}
      }
    }
  },
  methods: {
    changeLabelFormat(){
      if(this.location.country.name === 'United States'){
        this.city_label = 'City'
        this.zip_label = 'Zip'
        this.state_label = 'State'
      } else {
        this.city_label = 'Town'
        this.zip_label = 'Postcode'
        this.state_label = 'County'
      }
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.location);
        return res;
      });
    },
  }
}
</script>

<style scoped>

</style>
