<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col" style="display: inline-flex;">
        <card>
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>

<!--          Profile settings-->
          <div v-if="activeTabIndex === 0">
            <div class="row">
              <div class="col-lg-4">
                <base-input
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  v-model="user.first_name"
                >
                </base-input>
              </div>
              <div class="col-lg-4">
                <base-input
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  v-model="user.last_name"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <base-input
                  type="text"
                  label="Nickname"
                  placeholder="Nickname"
                  v-model="user.nickname"
                >
                </base-input>
              </div>
              <div class="col-lg-4">
                <base-input label="Birthday">
                  <el-date-picker placeholder="Birthday"
                                  value-format="yyyy-MM-dd"
                                  v-model="user.birthday"
                                  :format="date_format_cal"
                  ></el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <base-input
                  type="email"
                  label="Email address"
                  placeholder="Email"
                  :disabled="true"
                  v-model="user.email"
                >
                </base-input>
              </div>
              <div class="col-lg-4">
                <base-input
                  type="text"
                  label="Phone"
                  placeholder="Phone"
                  v-model="user.phone"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label>Role</label>
                <div class="row">
                  <div class="col">
                    <badge>{{user.role_name}}</badge>
                  </div>
                </div>
              </div>
              <div class="col">
                <label>Groups</label>
                <div class="row">
                  <div class="col" >
                    <badge v-for="(g, i) in groups" :key="'G' + i" class="mr-2" type="primary">{{g.name}}</badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!--          Availability-->
          <div class="row" v-if="activeTabIndex === 3">
            <div class="col">
              <fullCalendar :eventRender="eventRenderCallback" ref="calendar" :options="calendarOptions" />
            </div>
          </div>

          <div v-if="activeTabIndex === 1">
<!--            <div class="row">-->
<!--              <div class="col">-->
<!--                <label>Primary color</label>-->
<!--                <span-->
<!--                  v-for="item in sidebarColors"-->
<!--                >-->
<!--                  <button v-on:click="changeSidebarBackground(item)" :style="{'background-color': item.color}">color</button>-->
<!--                </span>-->

<!--              </div>-->
<!--            </div>-->
            <div class="row">
              <div class="col">
                <label>Site mode</label>
                <div class="switch-change-color mt-3">
                  <small class="label-switch text-white mr-2">LIGHT MODE</small>
                  <base-switch v-model="user.site_mode" @input="toggleMode"></base-switch>
                  <small class="label-switch text-white label-right ml-2">DARK MODE</small>
                </div>
              </div>
            </div>
          </div>

          <div class="row"  v-if="activeTabIndex === 2">
            <div class="col-lg-4">
              <h4 slot="header" class="card-title">Change Password</h4>
              <base-alert v-if="error.length > 0" type="danger">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
                {{error}}
              </base-alert>
              <base-alert v-if="message.length > 0" type="success">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
                {{message}}
              </base-alert>
              <base-input
                type="password"
                label="Old Password"
                v-model="old_password"
              >
              </base-input>
              <base-input
                type="password"
                label="New Password"
                v-model="new_password"
              >
              </base-input>
              <base-button v-on:click="changePassword()" type="primary">
                Change Password
              </base-button>
            </div>
          </div>

          <div class="row mt-3" v-if="activeTabIndex === 0 || activeTabIndex === 1">
            <div class="col">
              <base-button v-on:click="updateProfile()" type="primary">
                Save
              </base-button>
            </div>
          </div>
        </card>
      </div>

      <div class="col-lg-4" v-if="activeTabIndex === 0" style="display: inline-flex;">
        <card class="card-user">
          <p class="card-text"></p>
          <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <img class="avatar" :src="user.img" alt="..." />

            <p class="description">
              <span class="btn btn-info btn-sm btn-simple btn-file">Upload New Image
                <input
                  accept="image/*"
                  @change="handlePreview"
                  type="file"
                  name="profile"
                  class="valid"
                  :multiple="false"
                  aria-invalid="false"
                />
              </span>
            </p>
          </div>
        </card>

      </div>
    </div>
  </div>
</template>

<script>
  import { ImageUpload, BaseAlert } from 'src/components';
  import { Loading } from 'element-ui';
  import { API_LOCATION } from '../../api-config'
  const axios = require('axios')
  import { Auth } from 'aws-amplify'
  import {date} from '../../plugins/dateFormatter'
  import swal from "sweetalert2";
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
  import Badge from "@/components/Badge";
  import BaseSwitch from "@/components/BaseSwitch";


export default {
  name: "MyProfile",
  components: {
    ImageUpload,
    Loading,
    BaseAlert,
    FullCalendar,
    Badge,
    BaseSwitch
  },
  computed: {
    categories() {
      return [{name: 'Profile settings', icon: 'tim-icons icon-single-02', active: true},
        {name: 'Site settings', icon: 'tim-icons icon-settings-gear-63', active: false},
        // {name: 'My availability', icon: 'tim-icons icon-calendar-60', active: false},
        {name: 'Change password', icon: 'tim-icons icon-lock-circle', active: false},
      ];
    },
  },
  data() {
    return {
      sidebarColors: [
        { color: 'purple', active: false, value: 'primary' },
        { color: 'green', active: true, value: 'vue' },
        { color: 'blue', active: false, value: 'blue' },
        { color: 'limegreen', active: false, value: 'green' },
        { color: 'orange', active: false, value: 'orange' },
        { color: 'red', active: false, value: 'red' }
      ],
      activeTabIndex: 0,
      groups: [],
      j: 0,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        birthday: ''
      },
      company: '',
      file: '',
      loading: false,
      old_password: '',
      new_password: '',
      error: '',
      message: '',
      date_format: '',
      date_format_cal: '',
      day_of_week: '',
      calendarOptions: {
        plugins: [
          // dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick,
        ],
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        headerToolbar: {
          right: "prev,next,today",
        },
        height: 700,
        allDaySlot: false,
        scrollTime: '10:00:00',
        stickyHeaderDates: true,
        slotDuration: '00:15:00',
        slotLabelInterval: 15,
        slotLabelFormat: function (date) {
          let time_format = JSON.parse(localStorage.getItem('group')).time_format

          if(time_format === 'HH:mm') {
            let hour = date.date.hour
            let minute = '00'
            if (date.date.minute == 0){
              return hour + ':' + minute
            }
          } else {
            let hour = date.date.hour
            let dd = 'AM'
            if (date.date.minute == 0){
              if (parseInt(hour) >= 12) {
                hour = parseInt(date.date.hour) - 12;
                dd = 'PM'

              }
              if (hour == 0) {
                hour = 12;
              }
              return hour + ':00 ' + dd
            }
          }
        },
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
      }
    }
  },
  methods: {
    toggleMode(type) {
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
    },
    handleEvents(events) {
      console.log(events)
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo)
    },
    handleDateSelect(selectInfo) {
      console.log(selectInfo)
    },
    eventRenderCallback(event, element) {
      element.find('.fc-title').html(event.title + '<br>' + event.description);
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async changePassword() {
      this.loading = true
      let user = await Auth.currentAuthenticatedUser()
      try {
        await Auth.changePassword(user, this.old_password, this.new_password)
        this.message = 'Password successfully changed!'
        this.error = ''
        this.loading = false
      } catch (err) {
        if(err.code === 'InvalidParameterException'){
          this.error = 'Password must be between at least 6 characters long.'
          this.message = ''
        } else if (err.code === 'NotAuthorizedException') {
          this.error = 'Old password is not correct.'
          this.message = ''
        } else if (err.code === 'LimitExceededException') {
          this.error = 'Too many requests. Please try again later.'
          this.message = ''
        }
        this.loading = false
      }
    },
    getDate(d){
      if(d == null || d == undefined){
        return
      }
      console.log(d)
      return date(this.date_format, d)
    },
    async getProfile(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let url = API_LOCATION + 'users/me'

      axios.get(url, config)
        .then(response => {
          this.user = response.data.profile
          this.company = response.data.company
          this.groups = response.data.groups
          this.date_format = JSON.parse(localStorage.getItem('group')).date_format
          this.date_format_cal = JSON.parse(localStorage.getItem('group')).date_format
          this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
          this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
          if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
            this.day_of_week = 'ddd, '
          }
          localStorage.setItem('companyId', response.data.companyId)
          localStorage.setItem('profile', JSON.stringify(this.user))
          localStorage.setItem('company', JSON.stringify(response.data.company))
          localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
          localStorage.setItem('mode', this.user.site_mode)
          this.loading = false

        })
    },
    async updateProfile() {
      this.loading = true
      await this.saveFile()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'users/me'
      let data = this.user

      axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'Profile has been updated', 'success')
          this.getProfile()

          // axios.get(url, config)
          //   .then(response => {
          //     let user = response.data.profile
          //     localStorage.setItem('companyId', response.data.companyId)
          //     localStorage.setItem('profile', JSON.stringify(user))
          //     localStorage.setItem('company', JSON.stringify(response.data.company))
          //     localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
          //
          //     this.getProfile()
          //     this.loading = false
          //   })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handlePreview(event) {
      let file = event.target.files[0];
      this.user.img = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    async saveFile() {
      if(this.file != ''){
        this.uploadUrl = await this.getSignedURL(this.file)
        let uploaded = await this.uploadFile(this.file)
        //Get the image URL
        let imgUrl = this.uploadUrl.split("?")
        this.user.img = imgUrl[0]
      }
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
  },
  mounted(){
    this.getProfile()
  }

}
</script>

<style >

</style>
