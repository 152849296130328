<template>
  <div>
    <h5 class="info-text">
      Review and save
    </h5>
    <div class="row justify-content-center mt-5">
      <div class="col-6 col-lg-5 col-md-6 col-sm-6">
        <h4 class="card-title">{{model.name}}</h4>
        <p v-if="model.min_players_count != null">Players: &nbsp; &nbsp;{{model.min_players_count}}
          <span v-if="model.max_players_count != null"> - </span> {{model.max_players_count}}</p>
        <p v-if="model.required_resource_count != null">Required resources: &nbsp; &nbsp;{{model.required_resource_count}}</p>
        <p v-if="model.block_concurrent_events_when_booked">Will block concurrent events when booked</p>
        <p v-if="model.duration_minutes != null">Duration: &nbsp; &nbsp;{{model.duration_minutes}} minutes</p>
        <p v-if="model.include_game_difficulty && model.difficulty_level != null && model.difficulty_level > 0">Difficulty: &nbsp; &nbsp;
          <b-form-rating size="lg" show-clear style="max-width: 100px"
                         :icon-empty="model.difficulty_icon"
                         :icon-full="model.difficulty_icon + '-fill'"
                         :color="model.difficulty_color"
                         no-border
                         readonly
                         v-model="model.difficulty_level">

          </b-form-rating>&nbsp;
        </p>
        <p>Pricing type:
          <span v-if="model.pricing_type_id === 1">
             &nbsp; &nbsp;Per person
          </span>
          <span v-if="model.pricing_type_id === 2">
             &nbsp; &nbsp;Flat
          </span>
        </p>
        <div class="row mt-4"  v-if="model.game_images.length > 0">
          <div class="col">
            <el-carousel :autoplay="false" indicator-position="inside">
              <el-carousel-item style="border-radius: 5px; width: 100%; height: 250px;"
                                :style="{'background-color': item.background_color}" v-for="(item, index) in model.game_images"
                                :key="'CS' + index">
                <div  style="border-radius: 5px; width: 100%; height: 250px; position: relative">
                  <img :style="{'transform': 'translate(0%, -14%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px'}"
                       style="position: absolute; object-fit: cover; transform-origin: center center; will-change: transform;" :src="item.url">
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-6 col-md-6 col-sm-6">
          <h4  class="card-title" v-if="model.description != ''">Description: &nbsp; &nbsp;</h4>
          <base-alert v-if="model.description != ''" v-html="model.description"></base-alert>
          <h4 class="card-title mt-2">Puzzles</h4>
          <div class="mt-3" v-if="model.puzzles.length <= 0">
            <div class="font-icon-detail">
                <span style="font-size: 3em">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              <h4 class="mt-3">No puzzles were created.</h4>
            </div>
          </div>
          <b-table v-if="model.puzzles.length > 0" striped hover :fields="fields" :items="model.puzzles">
            <template v-slot:cell(image)="row">
              <img v-if="row.item.solution_img" slot="image" style="width: 50px; height:50px;" :src="row.item.solution_img"  alt="Card image cap"/>
              <span v-else>-</span>
            </template>
          </b-table>
      </div>

    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import {BFormInput, BTable, BPagination, BFormRating} from 'bootstrap-vue'
  import BaseAlert from "@/components/BaseAlert";
  const axios = require('axios')
  import { CarouselItem, Carousel } from 'element-ui';

  export default {
    components: {
      BaseAlert,
      BFormInput,
      BTable,
      BPagination,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      BFormRating
    },
    data() {
      return {
        model: {},
        fields: ['name', 'description', 'solution', 'image']
      };
    },
    methods: {
      async createGame () {

        let difficulty = 6
        if (this.model.difficulty_level && parseInt(this.model.difficulty_level) > 0){
          difficulty = parseInt(this.model.difficulty_level)
        }
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        let data = {
          name: this.model.name,
          min_players_count : parseInt(this.model.min_players_count),
          max_players_count: parseInt(this.model.max_players_count),
          duration_minutes: parseInt(this.model.duration_minutes),
          difficulty_level: difficulty,
          include_game_difficulty: this.model.include_game_difficulty,
          difficulty_icon: this.model.difficulty_icon,
          difficulty_label: this.model.difficulty_label,
          difficulty_color: this.model.difficulty_color,
          company_group_id: parseInt(this.model.company_group_id),
          pricing_type_id: this.model.pricing_type_id,
          description: this.model.description,
          img: this.model.img,
          puzzles: this.model.puzzles,
          pricing_categories: this.model.pricing_categories,
          game_images: this.model.game_images,
          deposit_type: this.model.deposit_type.id,
          require_deposit: this.model.require_deposit,
          allow_deposit: this.model.allow_deposit,
          cumulative_category_pricing: this.model.cumulative_category_pricing,
          deposit_amount: this.model.deposit_amount,
          hide_pricing_rate: this.model.hide_pricing_rate,
          required_resource_count: this.model.required_resource_count,
          block_concurrent_events_when_booked: this.model.block_concurrent_events_when_booked
        }

        return new Promise( function(resolve) {
          axios.post(url, data, config)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              console.log(err)
            })
        })
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      loadModel(data){
        this.model = data
        //update puzzle order
        for(var n in this.model.puzzles){
          this.model.puzzles[n].order = eval(n) + eval(1)
        }
        if(data.img == ""){
          this.model.img = 'https://console.offthecouch.io/img/placeholder.jpg'
        }
      }
    }
  };
</script>

<style scoped>
  .fixed-size-img {
    width: 100%; /* width of container */
    height: 300px; /* height of container */
    object-fit: cover;
  }
</style>
