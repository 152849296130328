<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/company">Company settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Data migration</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="row">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
          <div class="col text-right">
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="perPage"
              placeholder="Per page"
              v-on:change="getMigrationData(perPage, getOffset())"
            >
              <el-option
                class="select-primary"
                v-for="(item, index) in perPageOptions"
                :key="'B' + index"
                :label="item.toString()"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">

          <div class="row mb-2">
            <div class="col-lg-7">
              <div v-show="!isOTC()">
                <base-alert type="primary">
                  <i class="tim-icons icon-alert-circle-exc mr-2"></i>
                  Currently Migrations can only be performed by Off The Couch Administrators.<br/>
                  Please contact Nick at nick@offthecouch.io or on Discord to schedule your migration.
                  In preparation please look at Migration Advice to the right and
                  the Migration Types below to prepare reports in advance.
                </base-alert>
              </div>
              <div class="row mt-3" >
                <div class="col-lg-6">
                  <base-input label="Type" :error="errs.type">
                    <el-select
                      v-model="selectedMigrationType"
                      class="select-primary"
                      placeholder="Select type"
                      value-key="id"
                      v-on:input="setFields()"
                    >
                      <el-option
                        v-for="(m, i) in migration_types"
                        class="select-primary"
                        :label="m.name"
                        :value="{name: m.name, id: m.id}"
                        :key="'M' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row" v-if="selectedMigrationType">
                <div class="col-lg-10">
                  <label>
                    <div>Fields
                      <el-popover trigger="hover"
                                  placement="right">

                        <div>
                          <div class="popover-body"><small>Upload a csv file with the following column names included.<br/>
                            Mandatory fields are marked with an asterisk.</small></div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <base-alert type="secondary" style="margin-bottom: 2px">
                    <div class="row">
                      <div class="col-lg-11">
                        <code v-html="fields">
                        </code>
                      </div>
                      <div class="col-lg-1 text-right">
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <i style="cursor:pointer;" v-on:click="copy()" class="tim-icons text-default icon-single-copy-04"></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </base-alert>
                  <b-table :fields="['report_best_practices']" :items="report_best_practices">
                    <template v-slot:cell(report_best_practices)="row">
                      <div class="row">
                        <div class="col-1" style="padding-right: 0">
                          {{row.item.id}}
                        </div>
                        <div class="col" style="padding-left: 0">
                          {{row.item.text}}
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row" :key="j" v-show="isOTC()">
                <div class="col">
                  <span class="btn btn-primary btn-file mt-3"><i class="tim-icons icon-upload"></i> Upload data file
                    <input type="file"
                           accept=".csv"
                           @change="uploadFile"

                    />
                  </span>
                </div>
              </div>

            </div>
            <div class="col-lg-5">
              <div style="border: 1px solid white; padding-bottom: 15px; padding-left: 20px; padding-right: 30px; padding-top: 5px; border-radius: 3px">
                <div class="col">
                  <b-table :fields="['migration_advice']" :items="migration_advice">
                    <template v-slot:cell(migration_advice)="row">
                      <div class="row">
                        <div class="col-1" style="padding-right: 0">
                          {{row.item.id}}
                        </div>
                        <div class="col" style="padding-left: 0">
                          {{row.item.text}}
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 1">
          <div class="mt-3" v-if="migration_log.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data has been uploaded yet.</h4>
            </div>
          </div>
          <div class="d-sm-block d-none">
            <div class="row" v-if="migration_log.length > 0">
              <div class="col">
                <b-table :items="migration_log"
                         :fields="['upload_type', 'uploaded_by', 'records_imported', 'duplicate_count', 'actions']">
                  <template v-slot:cell(upload_type)="row">
                    <badge>{{row.item.type}}</badge>
                  </template>
                  <template v-slot:cell(records_imported)="row">
                    <div v-if="!row.item.purged">
                      {{row.item.number_of_records}} / {{row.item.total_rows}}
                      <div class="row">
                        <div class="col-lg-6">
                          <el-progress v-if="row.item.batch_count > row.item.completed_batch_count" :percentage="(((row.item.number_of_records)  / row.item.total_rows) * 100).toFixed(0)" />
                        </div>
                      </div>
                    </div>
                    <div v-if="row.item.purged"> <badge type="danger">purged</badge></div>
                  </template>
                  <template v-slot:cell(duplicate_count)="row">
                    <span v-if="row.item.batch_count === row.item.completed_batch_count">{{row.item.total_rows - row.item.number_of_records}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(uploaded_by)="row">
                    <p>{{row.item.first_name}} {{row.item.last_name}}</p>
                    <small style="font-style: italic">
                      {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                    </small>
                  </template>

                  <template v-slot:cell(actions)="row">
                    <div v-if="row.item.purged">-</div>

                    <el-dropdown v-if="!row.item.purged">
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2" v-if="!row.item.purged" href="" @click="viewRecords(row.item)">
                            <i class="tim-icons text-info icon-notes mr-2"></i> View records
                          </a>
                          <a class="dropdown-item mb-2" v-if="row.item.failed_migration_records" href="" @click="downloadErrorRecords(row.item.failed_migration_records)">
                            <i class="tim-icons text-default icon-cloud-download-93 mr-2"></i> Download error records
                          </a>
                          <hr v-if="!row.item.purged && hasPermissions('migrations_delete')">
                          <a v-if="!row.item.purged && hasPermissions('migrations_delete')"
                            class="dropdown-item" @click="deleteRecordsPrompt(row.item)" href="#">
                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Purge records
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>

                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-on:input="getMigrationData(perPage, getOffset())"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <div class="row" v-if="migration_log.length > 0">
              <div class="col">
                <b-table :items="migration_log"
                         :fields="['migration_logs']">
                  <template v-slot:cell(migration_logs)="row">
                    <div class="row">
                      <div class="col">
                        <p>{{row.item.first_name}} {{row.item.last_name}}
                          <i v-if="!row.item.purged" style="cursor: pointer" v-on:click="deleteRecordsPrompt(row.item)" class="tim-icons icon-trash-simple text-danger ml-2"></i>
                        </p>
                      </div>
                      <div class="col text-right">
                        <badge>{{row.item.type}}</badge>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <small style="font-style: italic">
                          {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                        </small>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{row.item.number_of_records}} records</p>
                        <badge v-if="row.item.purged" type="danger">purged</badge>
                      </div>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-on:input="getMigrationData(perPage, getOffset())"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { BTable, BPagination } from 'bootstrap-vue'
import {Auth} from "aws-amplify";
import {Dropdown, DropdownItem, DropdownMenu, Progress} from 'element-ui';
import {API_LOCATION} from "@/api-config";
import { BaseAlert } from '@/components';
import axios from "axios";
import swal from "sweetalert2";
import Badge from "@/components/Badge";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";

export default {
  name: "Migration",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BaseAlert,
    BTable,
    BPagination,
    Badge,
    [Progress.name]: Progress,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  computed: {
    categories() {
      return [{ name: 'Import CSV', icon: 'tim-icons icon-upload', active: true },
        { name: 'Migration logs', icon: 'tim-icons  icon-notes', active: false }
      ];
    }
  },
  data(){
    return {
      polling: null,
      file: null,
      uploadUrl: '',
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      migration_types: [],
      migration_log: [],
      selectedMigrationType: '',
      activeTabIndex: 0,
      j:0,
      fields: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      perPageOptions: [5, 10, 25, 50],
      errs: {
        type: ''
      },
      report_best_practices: [],
      migration_advice: [
        {id: 1, text: 'Recommended order of file uploads: 1. Customers (Global), 2.Transactions (By Group / Location), 3. Bookings (By Group / Location - needs to be associated to a transaction by an ID), ' +
            '4. Waivers (By Group / Location), 5. Gift cards, 6. Customer credit (Global), 7. Promo codes (By Group / Location)'},
        {id: 2, text: 'Make sure your headers match the ones provided. Use the copy tool to avoid typos.'},
        {id: 3, text: 'Headers marked with \'*\' are required.'},
        {id: 4, text: 'Make sure you have corresponding games created before uploading bookings or waivers and that the names match.'},
     ]
    }
  },
  methods: {
    isOTC(){
      let user = JSON.parse(localStorage.getItem('profile')).email
      if(user == 'jana.geddis+7@gmail.com' || user == 'nick+demo@offthecouchgames.com'){
        return true
      }
      return false
    },
    async downloadErrorRecords(csv){
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'error_records.csv';
      anchor.click();
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    deleteRecordsPrompt(item){
      swal({
        title: 'Are you sure?',
        text: 'Imported records from this batch will be permanently deleted',
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.deleteRecords(item)
        }
      })
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
    async deleteRecords(item){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/migrations/' + item.id + '?type=' + item.type_id
      this.loading = true

      this.axios.delete(url, config)
        .then(response => {
          swal('Success!', 'Migration log has been purged', 'success')
          this.getMigrationData(this.perPage, this.getOffset())
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    viewRecords(item){
      this.$router.push({ path: '/settings/migration/log', query: { id: item.id, type: item.type_id }})
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload?bucket=2'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async saveFile(item) {
      if(item != ''){
        this.uploadUrl = await this.getSignedURL(item)
        let uploaded = await this.uploadFileToS3(item)
      }
    },
    uploadFileToS3 (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async uploadFile(item) {
      let file = event.target.files[0];
      this.loading = true
      await this.saveFile(file)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/migrations'
      let type = this.selectedMigrationType.id

      let data = {
        file: this.uploadUrl.split("?")[0],
        type: type
      }

      axios.post(url, data, config)
        .then(response => {
          swal('Success', 'CSV file has been uploaded. Check migration logs to see what data has been imported.' , 'success')
          this.loading = false
          this.j++
        })
        .catch(err => {
          this.loading = false
          this.j++
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status === 500){

            swal.fire({
              title: 'Error!',
              text: err.response.data,
              type: 'error'
            })
          } else {
            swal('Success', 'CSV file has been uploaded. Check migration logs to see what data has been imported.' , 'success')
            this.j++
          }
        })
    },
    setFields(){
      if(this.selectedMigrationType.id === 1){
        this.report_best_practices = [
          {id: 1, text: 'Email field is required. All other fields are optional.' },
          {id: 2, text: 'Email opt out field must be boolean, with values of either 1 and 0, or true and false.' },
          {id: 3, text: 'Photo opt out field must be boolean, with values of either 1 and 0, or true and false.' },
          {id: 4, text: 'Birthday, Date joined, and Date last visited fields must have the format \'YYYY-MM-DD\'.' },
          {id: 5, text: 'If Date joined and Date last visited fields are not included, they will be set to the date of import.' }
        ]
        this.fields = "First name, Last name, Email<sup>*</sup>,\n" +
          "Birthday, Phone, Address line 1, Address line 2, City, State, Zip, Country, Email opt out, Photo opt out, Date joined, Date last visited, Notes"
      } else if (this.selectedMigrationType.id === 2){
        this.report_best_practices = [
          {id: 1, text: 'Email, Booking name, Booking start date, Booking start time and Booking end time fields are required. All other fields are optional.' },
          {id: 2, text: 'Birthday, Booking start date, and Booking end date fields must have the format \'YYYY-MM-DD\'.' },
          {id: 3, text: 'Date signed field must have the one following formats: \'YYYY-MM-DD HH:MM:SS\' or \'YYYY-MM-DD\' if you want to omit the exact time.' },
          {id: 4, text: 'Booking start time and Booking end time fields must have the format \'HH:MM:SS\'.' },
          {id: 5, text: 'Booking name field is the name of the game corresponding to the event. You must already have a game with the same name defined in Game Settings.' }
        ]
        this.fields = "First name, Last name, Email<sup>*</sup>,\n" +
          "Birthday, Phone, Date signed, Booking name<sup>*</sup>, " +
          "Booking start date<sup>*</sup>, Booking end date, Booking start time<sup>*</sup> Booking end time<sup>*</sup>"
      }
      else if (this.selectedMigrationType.id === 3){
        this.report_best_practices = [
          {id: 1, text: 'Booking name, Booking start date, Booking start time, Booking end time, and Status fields are required. All other fields are optional.' },
          {id: 2, text: 'Booking name field is the name of the game corresponding to the event. You must already have a game with the same name defined in Game Settings.' },
          {id: 3, text: 'Booking start date and Booking end date fields must have the format \'YYYY-MM-DD\'.' },
          {id: 4, text: 'Booking start time and Booking end time fields must have the format \'HH:MM:SS\'.' },
          {id: 5, text: 'Status field must be one of the following values: available, booked, cancelled, blocked, call_to_book.' },
          {id: 6, text: 'Order number field is the ID of the corresponding transaction, if applicable.' }
        ]
        this.fields = "Booking name<sup>*</sup>, " +
          "Booking start date<sup>*</sup>, Booking end date, Booking start time<sup>*</sup>, Booking end time<sup>*</sup>, " +
          "Group size, Price, Status<sup>*</sup>, Order number, Notes"
      }
      else if (this.selectedMigrationType.id === 4){
        this.report_best_practices = [
          {id: 1, text: 'Email and Order number fields are required. All other fields are optional.' },
          {id: 2, text: 'Email, First name, and Last name fields correspond to the customer linked to the transaction.' },
          {id: 3, text: 'Transaction date field must have the one following formats: \'YYYY-MM-DD HH:MM:SS\' or \'YYYY-MM-DD\' if you want to omit time.' },
          {id: 4, text: 'Transaction status field must have one of the following values: active or cancelled.' },
          {id: 5, text: 'Order number field is the ID of the corresponding transaction, if applicable.' }
        ]
        this.fields = "First name, Last name, Email<sup>*</sup>, Phone, Transaction date, Transaction status, Order number<sup>*</sup>, Price, " +
          "Total, Paid, Due, Taxes, Fees, Discount, Refunded, Notes"
      }
      else if (this.selectedMigrationType.id === 5){
        this.report_best_practices = [
          {id: 1, text: 'Promo name, Redemption type, and Value amount fields are required. All other fields are optional.' },
          {id: 2, text: 'Redemption type field must be one of the following values: value, percentage.'},
          {id: 3, text: '"Applicable to gift cards and merchandise" field must be true or false.'}
        ]
        this.fields = "Promo name<sup>*</sup>, Description, Redemption type<sup>*</sup>, " +
          "Value amount<sup>*</sup>, Quantity, Number of times used, Applicable to gift cards and merchandise"
      }
      else if (this.selectedMigrationType.id === 6){
        this.report_best_practices = [
          {id: 1, text: 'Gift card code, Total value, Spent, Customer email, and Recipient email fields are required. All other fields are optional.' },
          {id: 2, text: 'Date purchased, Expiration date, and Delivery date fields must have the format \'YYYY-MM-DD\'.' },
          {id: 3, text: 'Order number will link the gift card as a purchase under an existing transaction.' },
          {id: 4, text: 'Status field must be one of the following values: active or deactivated. When no status field is included, records will be active by default.' }
        ]
        this.fields = "Gift card code<sup>*</sup>, Personal message, Date purchased, Delivery date, Expiration date, Status, Total value<sup>*</sup>, " +
          "Spent<sup>*</sup>, Order number, Customer first name, Customer last name, Customer email<sup>*</sup>, Recipient email <sup>*</sup>, Recipient first name, Recipient last name"
      } else if (this.selectedMigrationType.id === 7){
        this.report_best_practices = [
          {id: 1, text: 'Email and Total value fields are required. All other fields are optional.' },
          {id: 2, text: 'Email, First name, and Last name fields correspond to the customer who received customer credit.' }
        ]
        this.fields = "First name, Last name, Email<sup>*</sup>, Total value<sup>*</sup>, Remaining, Spent"
      }
    },
    copy(){
      navigator.clipboard.writeText(this.fields);
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      if(this.activeTabIndex === 1){
       // this.getMigrationData(this.perPage, this.getOffset())
        this.polling = setInterval(this.getMigrationData, 500);
        // for(let n in this.migration_log){
        //   if(!this.migration_log[n].purged && this.migration_log[n].total_rows > this.migration_log[n].number_of_records){
        //     this.polling = setInterval(this.getMigrationData, 500);
        //     return
        //   }
        // }
      } else {
        clearInterval(this.polling);
        this.polling = null
      }
      this.j++
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getMigrationData(count, offset){
      if(count && offset){
        this.loading = true
      }
      if(!count){
        count = 10
      }
      if(!offset){
        offset = 0
      }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/migrations?count=' + count + '&offset=' + offset

      this.axios.get(url, config)
        .then(response => {
          this.migration_types = response.data.migration_types
          this.migration_log = response.data.logs
          this.totalRows = response.data.total_rows
          let continue_polling = 0
          for(let n in this.migration_log){
            if(this.migration_log[n].batch_count > this.migration_log[n].completed_batch_count){
              continue_polling++
            }
          }
          if(continue_polling === 0){
            clearInterval(this.polling);
            this.polling = null
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getMigrationData(this.perPage, 0)
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.polling);
  },
}
</script>

<style scoped>

</style>
