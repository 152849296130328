<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/surveys/view">Surveys</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a :href="'/surveys/results?id=' + question.survey_id">{{ question.survey_name }}</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>  {{question.question}}</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col-12">
      <card>
        <div class="d-block d-sm-none">
          <div class="row justify-content-between">
            <div class="col">
              <h4 slot="header" class="card-title">{{ question.question }}</h4>
            </div>
            <div class="col-3 text-right">
              <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
              <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
            </div>
          </div>
          <div class="row justify-content-between" v-show="show_filters">
            <div class="col">
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by response</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 400px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="Response"
                          v-model="filters.response.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.response)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getQuestionResponses(perPage, getOffset())">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Response <span v-if="filters.response.actual">: {{filters.response.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by customer name or email</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 400px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="Customer name or email"
                          v-model="filters.customer.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getQuestionResponses(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" simple size="sm" type="primary">
                    Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
              </base-input>
            </div>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div class="row">
            <div class="col">
              <h4 slot="header" class="card-title">{{ question.question }}</h4>
            </div>
          </div>
          <!--        Filters-->
          <div class="row justify-content-between">
            <div class="col-8">
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by response</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 400px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="Response"
                          v-model="filters.response.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.response)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getQuestionResponses(perPage, getOffset())">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Response <span v-if="filters.response.actual">: {{filters.response.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by customer name or email</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 400px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="Customer name or email"
                          v-model="filters.customer.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getQuestionResponses(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" simple size="sm" type="primary">
                    Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
              </base-input>
            </div>
            <div class="col text-right">
              <base-button icon class="btn-link text-white" v-if="hasPermissions('download_data')" v-on:click="downloadCSV()">
                <i class="tim-icons icon-cloud-download-93"></i>
              </base-button>
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="getQuestionResponses(perPage, getOffset())"
              >
                <el-option
                  class="select-primary"
                  v-for="(item, index) in perPageOptions"
                  :key="'B' + index"
                  :label="item.toString()"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="mt-3" v-if="responses.length <= 0 ">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">There are no responses for this question.</h4>
          </div>
        </div>

        <!--        Mobile-->
        <div class="d-block d-sm-none">
          <div v-if="responses.length > 0" class="row table-content">
            <div class="col">
              <b-table hover :items="responses"
                       :fields="['responses']">
                <template #head(responses)="data">
                  Total responses: <span>{{totalRows}}</span>
                </template>
                <template v-slot:cell(responses)="row">
                  <span v-if="!row.item.response">-</span>
                  <span v-if="row.item.response && row.item.response_field_type === 1">
                    {{getCheckboxResponses(row.item.response)}}
                  </span>
                  <span v-if="row.item.response && row.item.response_field_type !== 1">{{row.item.response}}</span>
                  <div class="row mt-2">
                    <div class="col">
                      <span v-if="!row.item.customer_first_name && !row.item.customer_phone && !row.item.email
                  && !row.item.customer_last_name"><badge>anonymous</badge></span>
                      <div>
                        <span v-if="row.item.customer_first_name">{{row.item.customer_first_name}} </span>
                        <span v-if="row.item.customer_first_name">{{row.item.customer_last_name}}</span>
                      </div>
                      <small v-if="row.item.email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.email}}</small>
                      <small v-if="row.item.customer_phone">
                        <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}</small>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <span>Submitted on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</span>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <!--        Desktop-->
        <div class="d-none d-sm-block">
          <div v-if="responses.length > 0" class="row table-content">
            <div class="col">
              <b-table hover :items="responses"
                       :fields="['response', 'customer_name', 'date']">
                <template v-slot:cell(response)="row">
                  <span v-if="!row.item.response">-</span>
                  <span v-if="row.item.response && row.item.response_field_type === 1">
                    {{getCheckboxResponses(row.item.response)}}
                  </span>
                  <span v-if="row.item.response && row.item.response_field_type !== 1">{{row.item.response}}</span>
                </template>
                <template v-slot:cell(customer_name)="row">
                  <span v-if="!row.item.customer_first_name && !row.item.customer_phone && !row.item.email
                  && !row.item.customer_last_name"><badge>anonymous</badge></span>
                  <div>
                    <span v-if="row.item.customer_first_name">{{row.item.customer_first_name}} </span>
                    <span v-if="row.item.customer_first_name">{{row.item.customer_last_name}}</span>
                  </div>
                  <small v-if="row.item.email">
                    <i class="tim-icons icon-email-85"></i> {{row.item.email}}</small>
                  <small v-if="row.item.customer_phone">
                    <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}</small>
                </template>
                <template v-slot:cell(date)="row">
                  <span>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</span>
                </template>
              </b-table>
              <b-pagination v-on:input="getQuestionResponses(perPage, getOffset())"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-button type="default" v-on:click="returnToSurveys()">Cancel</base-button>
          </div>
        </div>


      </card>
    </div>
  </div>
</div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {BPagination, BTable} from "bootstrap-vue";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import Badge from "@/components/Badge";
import axios from "axios";

export default {
  name: "SurveyQuestionResponses",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BTable,
    BPagination,
    Badge
  },
  data() {
    return {
      loading: false,
      responses: [],
      question: '',
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [5, 10, 25, 50],
      show_filters: false,
      filters: {
        customer: {
          value: '',
          actual: ''
        },
        response: {
          value: '',
          actual: ''
        },
      },
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async getAllSurveys(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let offset = 0
      let surveyId = this.$route.query.id
      let questionId = this.$route.query.question_id
      while(offset <= this.totalRows){
        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/'
          + surveyId + '/questions/' + questionId + '?count=' +  count + "&offset=" + offset +
          '&customer=' + encodeURIComponent(this.filters.customer.actual) + '&response=' + encodeURIComponent(this.filters.response.actual)

        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.responses)
            })
            .catch(err => {
              console.log(err)
              if (err.response.status == 401) {
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response) => {
                  this.$router.push('/')
                })
              } else if (err.response.status == 500) {
                swal({
                  title: 'Error',
                  html: 'The feature has not been enabled as part of your subscription plan.',
                  type: 'error',
                }).then((response) => {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      let count = 5000
      let csvData = await this.getAllSurveys(config, count)
      this.loading = false
      let csv = 'Response, Customer First Name, Customer Last Name, Customer Email,' +
        'Customer Phone, Date Submitted\n';
      console.log(csvData)
      csvData.forEach(row => {
        console.log(row)
        csv += [this.escapeCSVValue(row.response), this.escapeCSVValue(row.customer_first_name),
          this.escapeCSVValue(row.customer_last_name), this.escapeCSVValue(row.email), this.escapeCSVValue(row.customer_phone),
          JSON.stringify(this.getLocalDate(row.timestamp) + ' ' + this.getLocalTime(row.timestamp))]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'survey_question.csv';
      anchor.click();
    },
    resetAll(){
      this.filters = {
        customer: {
          value: '',
          actual: ''
        },
        response: {
          value: '',
          actual: ''
        },
      }
      this.getQuestionResponses(this.perPage, this.getOffset())
    },
    getCheckboxResponses(array){
      return JSON.parse(array).join(', ')
    },
    resetField(field1){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      this.getQuestionResponses(this.perPage, this.getOffset())
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
    returnToSurveys(){
      this.$router.push({ path: '/surveys/results', query: { id:  this.question.survey_id }})
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getQuestionResponses(count, offset){
      //set customer
      this.filters.customer.actual = this.filters.customer.value
      this.filters.response.actual = this.filters.response.value

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let questionId = this.$route.query.question_id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/'
        + surveyId + '/questions/' + questionId + '?count=' +  count + "&offset=" + offset +
        '&customer=' + encodeURIComponent(this.filters.customer.actual) + '&response=' + encodeURIComponent(this.filters.response.actual)

      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.responses = response.data.responses
          this.question = response.data.question
          this.totalRows = response.data.total_rows
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getQuestionResponses(this.perPage, this.getOffset())
  }
}
</script>

<style scoped>

</style>
