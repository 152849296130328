<template>
<!--  <div class="row" v-loading="true" id="loading" ></div>-->
  <div  v-if="loading" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" style="background: rgba(0, 0, 0, 0.7) !important; position: fixed;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            overflow: auto;
                            display: flex;
                            align-items: center;"
        v-loading="loading" class="el-loading-mask" id="loading">
  </div>
</template>
<script>
import Vue from 'vue';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);
export default {
  name: 'loading-panel',
  props: {
    loading: Boolean
  },
};
</script>
<style>
#loading {
  min-height: 1000px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #6aff8d !important;
}

.el-loading-mask {
  background: rgba(0, 0, 0, 0.7) !important;
}
</style>
