<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row mt-3 overflow-scroll">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">
          <div class="mt-3" v-if="abandoned_carts.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">You have no abandoned carts.</h4>
            </div>
          </div>
          <div class="d-sm-none d-block">
            <div class="row"  v-if="abandoned_carts.length > 0">
              <div class="col">
                <b-table striped hover :items="abandoned_carts" :per-page="perPageAbandoned"
                         :current-page="currentPageAbandoned"
                         :fields="['abandoned_carts']">
                  <template v-slot:cell(abandoned_carts)="row">
                    <badge v-if="!row.item.customer_first_name && !row.item.customer_last_name">anonymous</badge>
                    <div class="row">
                      <div class="col">
                        <p class="text-white">{{row.item.customer_first_name}} {{row.item.customer_last_name}}</p>
                      </div>
                      <div class="col text-right">
                        <badge type="success" v-if="row.item.email_sent">sent</badge>
                        <badge type="danger" v-else>not sent</badge>
                      </div>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.customer_email}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_phone">
                        <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_ip">
                        IP: {{row.item.customer_ip}}
                      </small>
                    </div>
                    <p class="text-white text-uppercase mt-2 text-underline">Activity</p>
                    <p class="text-white mt-2">{{row.item.event_name}}</p>
                    <div>
                      <small><span style="font-weight: bold">Time first visited:</span> {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                    </div>
                    <div v-if="!row.item.last_updated.includes('0000')">
                      <small><span style="font-weight: bold">Latest activity:</span>
                        {{getLocalDate(row.item.last_updated)}}, {{getLocalTime(row.item.last_updated)}}
                      </small>
                    </div>
                    <div v-if="row.item.bookings_in_cart.length > 0"
                         v-for="(booking, index) in row.item.bookings_in_cart"
                         :key="'BOOKINGS' + index" >
                      <p class="text-white text-uppercase mt-2 text-underline">Items in cart</p>
                      <p class="text-white">{{booking.game_name}}</p>
                      <div>
                        <small><i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(booking.booking_date)}}, {{getTime(booking.start_time)}}</small>
                      </div>
                    </div>
                    <p class="text-white text-uppercase mt-2 text-underline">Cart value
                    </p>
                    <h4 class="text-white">{{getAmount(row.item.cart_value)}}</h4>

                    <div
                      class="btn-group btn-group-toggle mt-3 mb-2"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%"
                    >
                      <label v-if="row.item.customer_email"
                             class="btn btn-sm btn-primary btn-simple"
                             style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="sendEmail(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-email-85"></i>
                        </base-button>
                      </label>
                      <label
                             class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="deleteCart(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                    </div>

                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPageAbandoned"
                  :total-rows="abandoned_carts.length"
                  :per-page="perPageAbandoned"
                ></b-pagination>
              </div>
            </div>
          </div>

          <div class="d-sm-block d-none">
            <div class="row"  v-if="abandoned_carts.length > 0">
              <div class="col">
                <b-table striped hover :items="abandoned_carts" :per-page="perPageAbandoned"
                         :current-page="currentPageAbandoned"
                         :fields="['customer', 'activity', 'items_in_cart', 'cart_value', 'email_status', 'actions']">
                  <template v-slot:cell(customer)="row">
                    <badge v-if="!row.item.customer_first_name && !row.item.customer_last_name">anonymous</badge>
                    <p class="text-white">{{row.item.customer_first_name}} {{row.item.customer_last_name}}</p>
                    <div>
                      <small class="text-white" v-if="row.item.customer_email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.customer_email}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_phone">
                        <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_ip">
                        IP: {{row.item.customer_ip}}
                      </small>
                    </div>
                  </template>
                  <template v-slot:cell(email_status)="row">
                    <badge type="success" v-if="row.item.email_sent">sent</badge>
                    <badge type="danger" v-else>not sent</badge>
                  </template>
                  <template v-slot:cell(activity)="row">
                    <p class="text-white">{{row.item.event_name}}</p>
                    <div>
                      <small><span style="font-weight: bold">Time first visited:</span> {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                    </div>
                    <div v-if="!row.item.last_updated.includes('0000')">
                      <small><span style="font-weight: bold">Latest activity:</span>
                        {{getLocalDate(row.item.last_updated)}}, {{getLocalTime(row.item.last_updated)}}
                      </small>
                    </div>
                  </template>
                  <template v-slot:cell(items_in_cart)="row">
                    <div v-if="row.item.bookings_in_cart.length > 0"
                         v-for="(booking, index) in row.item.bookings_in_cart"
                         :key="'BOOKINGS' + index" >
                      <p class="text-white">{{booking.game_name}}</p>
                      <div>
                        <small><i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(booking.booking_date)}}, {{getTime(booking.start_time)}}</small>
                      </div>
                    </div>
                    <p v-if="row.item.bookings_in_cart.length <= 0" class="text-white">-</p>
                  </template>
                  <template v-slot:cell(cart_value)="row">
                    <p class="text-white">{{getAmount(row.item.cart_value)}}</p>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a v-if="row.item.customer_email" class="dropdown-item mt-1" href="#" v-on:click="sendEmail(row.item)">
                            <i style="color: #2dce89" class="tim-icons icon-email-85 mr-2"></i> Send a follow-up email
                          </a>
                          <hr v-if="row.item.customer_email">
                          <a class="dropdown-item" href="#" v-on:click="deleteCartPrompt(row.item)">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete permanently
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPageAbandoned"
                  :total-rows="abandoned_carts.length"
                  :per-page="perPageAbandoned"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 1">
          <div class="mt-3" v-if="active_carts.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">You have no active carts.</h4>
            </div>
          </div>
          <div class="d-sm-none d-block">
            <div class="row"  v-if="active_carts.length > 0">
              <div class="col">
                <b-table striped hover :items="active_carts" :per-page="perPageActive"
                         :current-page="currentPageActive"
                         :fields="['active_carts']">
                  <template v-slot:cell(active_carts)="row">
                    <badge v-if="!row.item.customer_first_name && !row.item.customer_last_name">anonymous</badge>
                    <div class="row">
                      <div class="col">
                        <p class="text-white">{{row.item.customer_first_name}} {{row.item.customer_last_name}}</p>
                      </div>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.customer_email}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_phone">
                        <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_ip">
                        IP: {{row.item.customer_ip}}
                      </small>
                    </div>
                    <p class="text-white text-uppercase mt-2 text-underline">Activity</p>
                    <p class="text-white mt-2">{{row.item.event_name}}</p>
                    <div>
                      <small><span style="font-weight: bold">Time first visited:</span> {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                    </div>
                    <div v-if="!row.item.last_updated.includes('0000')">
                      <small><span style="font-weight: bold">Latest activity:</span>
                        {{getLocalDate(row.item.last_updated)}}, {{getLocalTime(row.item.last_updated)}}
                      </small>
                    </div>
                    <div v-if="row.item.bookings_in_cart.length > 0"
                         v-for="(booking, index) in row.item.bookings_in_cart"
                         :key="'BOOKINGS' + index" >
                      <p class="text-white text-uppercase mt-2 text-underline">Items in cart</p>
                      <p class="text-white">{{booking.game_name}}</p>
                      <div>
                        <small><i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(booking.booking_date)}}, {{getTime(booking.start_time)}}</small>
                      </div>
                    </div>
                    <p class="text-white text-uppercase mt-2 text-underline">Cart value
                    </p>
                    <h4 class="text-white">{{getAmount(row.item.cart_value)}}</h4>
                  </template>
                </b-table>

                <b-pagination
                  v-model="currentPageActive"
                  :total-rows="active_carts.length"
                  :per-page="perPageActive"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="d-sm-block d-none">
            <div class="row"  v-if="active_carts.length > 0">
              <div class="col">
                <b-table striped hover :items="active_carts" :per-page="perPageActive"
                         :current-page="currentPageActive"
                         :fields="['customer', 'activity', 'items_in_cart', 'cart_value']">
                  <template v-slot:cell(customer)="row">
                    <badge v-if="!row.item.customer_first_name && !row.item.customer_last_name">anonymous</badge>
                    <p class="text-white">{{row.item.customer_first_name}} {{row.item.customer_last_name}}</p>
                    <div>
                      <small class="text-white" v-if="row.item.customer_email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.customer_email}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_phone">
                        <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                      </small>
                    </div>
                    <div>
                      <small class="text-white" v-if="row.item.customer_ip">
                        IP: {{row.item.customer_ip}}
                      </small>
                    </div>
                  </template>
                  <template v-slot:cell(activity)="row">
                    <p class="text-white">{{row.item.event_name}}</p>
                    <div>
                      <small><span style="font-weight: bold">Time first visited:</span> {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                    </div>
                    <div v-if="!row.item.last_updated.includes('0000')">
                      <small><span style="font-weight: bold">Latest activity:</span>
                        {{getLocalDate(row.item.last_updated)}}, {{getLocalTime(row.item.last_updated)}}
                      </small>
                    </div>
                  </template>
                  <template v-slot:cell(items_in_cart)="row">
                    <div v-if="row.item.bookings_in_cart.length > 0"
                         v-for="(booking, index) in row.item.bookings_in_cart"
                         :key="'BOOKINGS' + index" >
                      <p class="text-white">{{booking.game_name}}</p>
                      <div>
                        <small><i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(booking.booking_date)}}, {{getTime(booking.start_time)}}</small>
                      </div>
                    </div>
                    <p v-if="row.item.bookings_in_cart.length <= 0" class="text-white">-</p>
                  </template>
                  <template v-slot:cell(cart_value)="row">
                    <p class="text-white">{{getAmount(row.item.cart_value)}}</p>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPageActive"
                  :total-rows="active_carts.length"
                  :per-page="perPageActive"
                ></b-pagination>
              </div>
            </div>
          </div>

        </div>

        <div v-if="activeTabIndex === 2">

          <div class="row"  v-if="analytics.length > 0">
            <div class="col">
              <b-table striped hover :items="analytics"
                       :fields="['event_name', 'event_count', 'total_amount']">
                <template v-slot:cell(event_name)="row">
                  <span>{{ row.item.event_name }}</span>
                </template>
                <template v-slot:cell(event_count)="row">
                  <span>{{ row.item.count }}</span>
                </template>
                <template v-slot:cell(total_amount)="row">
                  <span>{{ getAmount(row.item.value_amount) }}</span>
                </template>
              </b-table>
<!--              <b-pagination v-on:input="getCustomers(perPage, getOffset())"-->
<!--                            v-model="currentPage"-->
<!--                            :total-rows="totalRows"-->
<!--                            :per-page="perPage"-->
<!--              ></b-pagination>-->
            </div>
          </div>

        </div>

      </card>
    </div>
  </div>
</div>
</template>

<script>
import Badge from "@/components/Badge";
import {BIcon, BIconArrowCounterclockwise, BIconFilter, BPagination, BTable} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import currencies from "@/assets/currencies.json";

export default {
  name: "ActiveCarts",
  components: {
    Badge,
    BTable,
    BPagination,
    BIcon,
    BIconFilter,
    BIconArrowCounterclockwise,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      show_filters: false,
      loading: false,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      active_carts: [],
      abandoned_carts: [],
      analytics: [],
      activeTabIndex: 0,
      j: 0,
      currentPageActive: 0,
      perPageActive: 10,
      currentPageAbandoned: 0,
      perPageAbandoned: 10
    }
  },
  computed:{
    categories() {
      return [{ name: 'Abandoned carts', icon: 'tim-icons icon-alert-circle-exc', active: true },
        { name: 'Active carts', icon: 'tim-icons icon-cart', active: false },
        // { name: 'Cart analytics', icon: 'tim-icons icon-chart-bar-32', active: false }
      ];
    },
  },
  methods: {
    async sendEmail(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/carts/' + item.id

      let data = item

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Cart abandonment email has been sent",
            type: 'success'
          })
          this.getCarts()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteCart(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/carts/' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Cart has been deleted",
            type: 'success'
          })
          this.getCarts()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteCartPrompt(item){
      swal({
        title: 'Are you sure?',
        text: "This action will permanently delete the cart",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then((response)=> {
        if(response.value == true){
          this.deleteCart(item)
        }
      })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getLocalTime(d){
      let local = new Date(d)
      let mins =  local.getMinutes()
      if(mins < 10){
        mins = '0' + mins
      }
      return this.getTime(local.getHours() + ':' + mins)
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getCarts(count, offset){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/carts?count=' + count + '&offset=' + offset
      axios.get(url, config)
        .then(response => {

          this.loading = false
          this.active_carts = response.data.active_carts
          this.abandoned_carts = response.data.abandoned_carts
          this.analytics = response.data.analytics

          for(let n in this.active_carts){
            this.active_carts[n].bookings_in_cart = JSON.parse(this.active_carts[n].bookings_in_cart)
          }
          for(let n in this.abandoned_carts){
            this.abandoned_carts[n].bookings_in_cart = JSON.parse(this.abandoned_carts[n].bookings_in_cart)
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getCarts()
  }

}
</script>

<style scoped>

</style>
