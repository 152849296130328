<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--    Breadcrumbs-->
  <breadcrumb v-if="editing" style="margin-left: -15px">
    <breadcrumb-item>
      <a href="" v-on:click="editing = false">Automated email settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ getType(selectedType.email_type) }}</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row" v-if="!editing">
    <div class="col">
      <card>
        <div class="row">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">
          <div class="row">
            <div class="col-lg-4">
              <div class="row mt-4">
                <div class="col-lg-5">
                  <p>From email</p>
                  <small>Indicates the sender's email address, showing who sent the message. If you want to use a custom domain, it must be verified by updating your DNS settings.</small>
                </div>
                <div class="col">
                  <base-input :error="errors.from_email" v-model="from_email"
                              placeholder="From email">
                  </base-input >
                  <badge type="default" v-if="setting.from_email === from_email && setting.from_email === 'noreply@offthecouch.io'">default</badge>
                  <badge type="success" v-if="setting.from_email !== 'noreply@offthecouch.io' && setting.custom_domain_verified">verified</badge>
                  <badge type="danger" v-if="setting.from_email === from_email && setting.from_email !== 'noreply@offthecouch.io' && !setting.custom_domain_verified">not verified</badge>
                  <base-button v-if="setting.from_email !== from_email" simple type="primary"
                               v-on:click="confirmDomain()">
                    Confirm domain
                  </base-button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-5">
                  <p>Sender name</p>
                  <small>Displays the name associated with the email account of the individual or entity sending the message.</small>
                </div>
                <div class="col">
                  <base-input v-model="setting.sender_name"></base-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-5">
                  <p>Reply to email</p>
                  <small>Specifies the email address to which responses should be sent, differing from the sender's address if necessary.</small>
                </div>
                <div class="col">
                  <base-input :error="errors.reply_to_email" v-model="setting.reply_to_email"></base-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <base-button v-on:click="saveGeneralSettings()" type="primary">Save settings</base-button>
                </div>
              </div>
            </div>
            <div class="col-lg-8 mt-4" v-if="setting.from_email !== 'noreply@offthecouch.io'">
              <div class="row">
                <div class="col">
                  <div style="border: 1px solid white; padding-bottom: 15px; padding-left: 20px; padding-right: 30px;
                  padding-top: 5px; border-radius: 3px; overflow-wrap: anywhere">
                    <div class="col">
                      <h4 class="card-title mt-3">Verify domain</h4>
                      <base-alert type="default">
                        <i class="tim-icons el-icon-info mr-1"></i>
                        If you run into any issues, please use <a target="_blank" class="font-weight-700"
                                        href="https://dnschecker.org/">DNSChecker</a>
                        to identify which record is failing.
                      </base-alert>
                      <div class="d-sm-block d-none">
                        <b-table :fields="['type', 'name', 'value']" :items="dns_fields">
                          <template v-slot:cell(name)="row">
                            <div class="row" v-if="row.item.name !== ''">
                              <div class="col">
                                <small>{{row.item.name}}</small>
                                <i class="tim-icons el-icon-document-copy ml-2"
                                   v-on:click="copy(row.item.name)"
                                   style="cursor: pointer"></i>
                              </div>
                            </div>
                          </template>
                          <template v-slot:cell(value)="row">
                            <div class="row">
                              <div class="col">
                                <small>{{row.item.value}}</small>
                                <i class="tim-icons el-icon-document-copy ml-2"
                                   v-on:click="copy(row.item.value)"
                                   style="cursor: pointer"></i>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </div>

                      <div class="d-sm-none d-block">
                        <b-table :fields="['fields']" :items="dns_fields">
                          <template v-slot:cell(fields)="row">
                            <div class="row" v-if="row.item.type !== ''">
                              <div class="col">
                                <small>Type: {{row.item.type}}</small>
                              </div>
                            </div>
                            <div class="row" v-if="row.item.name !== ''">
                              <div class="col">
                                <small>Name: {{row.item.name}}</small>
                                <i class="tim-icons el-icon-document-copy ml-2"
                                   v-on:click="copy(row.item.name)"
                                   style="cursor: pointer"></i>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <small>Value: {{row.item.value}}</small>
                                <i class="tim-icons el-icon-document-copy ml-2"
                                   v-on:click="copy(row.item.value)"
                                   style="cursor: pointer"></i>
                              </div>
                            </div>
                          </template>
                          <template v-slot:cell(value)="row">
                            <div class="row">
                              <div class="col">
                                <small>Value: {{row.item.value}}</small>
                                <i class="tim-icons el-icon-document-copy ml-2"
                                   v-on:click="copy(row.item.value)"
                                   style="cursor: pointer"></i>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </div>

                    </div>
                    <base-button v-on:click="verifyDomain()" type="primary">Verify my domain</base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div v-if="activeTabIndex === 1">
          <div class="row mt-3">
            <div class="col">
              <b-table :items="configurations" :fields="['email_type', 'status', 'actions']">
                <template v-slot:cell(email_type)="row">
                  {{getType(row.item.email_type)}}
                </template>

                <template v-slot:cell(status)="row">
                  <badge type="success" v-if="row.item.is_active">active</badge>
                  <badge type="danger" v-if="!row.item.is_active">disabled</badge>

                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mb-2" href="#" @click="previewEmail(row.item)">
                          <i class="tim-icons text-info el-icon-view mr-2"></i> Preview email
                        </a>
                        <a class="dropdown-item mb-2" @click="selectType(row.item)" href="#">
                          <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit email template
                        </a>
                        <hr>
                        <a class="dropdown-item" v-if="row.item.is_active" @click="toggleActivation(0, row.item)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate email type
                        </a>
                        <a class="dropdown-item" v-if="!row.item.is_active" @click="toggleActivation(1, row.item, 'list')" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate email type
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

      </card>
    </div>
  </div>

  <div class="row" v-if="editing" :key="j">
    <div class="col-lg-9">
      <card>
        <div class="row justify-content-between">
          <div class="col-lg-10 col-8">
            <h4>{{ getType(selectedType.email_type) }}</h4>
          </div>
          <div class="col-lg-2 col-4 text-right">
            <el-dropdown>
              <base-button
                simple
                type="primary"
                link
              > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
              </base-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <a class="dropdown-item mb-2" href="#" @click="previewEmail()">
                    <i class="tim-icons text-info el-icon-view mr-2"></i> Preview email
                  </a>
                  <hr>
                  <a class="dropdown-item" v-if="selectedType.is_active" @click="toggleActivation(0, selectedType)" href="#">
                    <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate email type
                  </a>
                  <a class="dropdown-item" v-if="!selectedType.is_active" @click="toggleActivation(1, selectedType)" href="#">
                    <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate email type
                  </a>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
<!--            <base-button v-if="selectedType.is_active == 0" v-on:click="toggleActivation(1)" simple size="sm" type="success">Activate</base-button>-->
<!--            <base-button v-if="selectedType.is_active == 1" v-on:click="toggleActivation(0)" simple size="sm" type="danger">Deactivate</base-button>-->
          </div>
        </div>
        <div v-if="selectedType.email_type == 'photos'" class="row">
          <div class="col-lg-6 col-12">
            <base-alert type="primary"><b>Note:</b> Players will receive their photos as an email attachment.</base-alert>
          </div>
        </div>

        <div class="row">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">Email subject</p>
            <small>Email subject for all emails.</small>
          </div>
          <div class="col-lg-10" >
            <base-input :error="errors.email_subject" label="Email subject" v-model="selectedType.email_subject"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">Sender information</p>
            <small>The specified name will appear in the From field of the email. The specified email address with appear in the reply-to field.</small>
          </div>
          <div class="col-lg-4 col-12" >
            <base-input :error="errors.sender_name" placeholder="Name" label="Sender name" v-model="selectedType.sender_name"></base-input>
          </div>
          <div class="col-lg-6" >
            <base-input :error="errors.email" placeholder="Email" label="Sender email address" v-model="selectedType.sender_address"></base-input>
          </div>
        </div>

        <div v-if="selectedType.email_type == 'reminder'" class="row mt-3">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">General settings</p>
            <small>Time when customers receive the reminder email, before the date of their event/booking.</small>
          </div>

          <div class="col-lg-10" >
            <label>Send reminder emails...</label>
            <div class="row">
              <div class="col-lg-2 col-4" style="padding-right: 0px">
                <base-input :error="errors.period" v-mask="'###'" v-model="selectedType.frequency"></base-input>
              </div>
              <div class="col-lg-6 col-8">
                <base-input>
                  <el-select
                    v-model="selectedType.period"
                    class="select-primary"
                    name="group"
                    placeholder="Period"
                  >
                    <el-option
                      v-for="(o, i) in options"
                      class="select-primary"
                      :label="o.before"
                      :value="o.name"
                      :key="'A' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedType.email_type == 'follow_up'" class="row mt-3">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">General settings</p>
            <small>Time when customers receive the follow-up email, after the date of their event/booking.</small>
          </div>

          <div class="col-lg-10" >
            <label>Send follow-up emails...</label>
            <div class="row">
              <div class="col-lg-2 col-4" style="padding-right: 0px">
                <base-input :error="errors.period" v-mask="'###'" v-model="selectedType.frequency"></base-input>
              </div>
              <div class="col-lg-6 col-8">
                <base-input>
                  <el-select
                    v-model="selectedType.period"
                    class="select-primary"
                    name="group"
                    placeholder="Period"
                  >
                    <el-option
                      v-for="(o, i) in options"
                      class="select-primary"
                      :label="o.after"
                      :value="o.name"
                      :key="'A' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedType.email_type == 'abandoned_cart'" class="row mt-3">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">General settings</p>
            <small>Time when customers receive the email after abandoning the cart.</small>
          </div>

          <div class="col-lg-10" >
            <label>Send abandoned cart emails...</label>
            <div class="row">
              <div class="col-lg-2 col-4" style="padding-right: 0px">
                <base-input :error="errors.period" v-mask="'###'" v-model="selectedType.frequency"></base-input>
              </div>
              <div class="col-lg-6 col-8">
                <base-input>
                  <el-select
                    v-model="selectedType.period"
                    class="select-primary"
                    name="group"
                    placeholder="Period"
                  >
                    <el-option
                      v-for="(o, i) in abandonment_options"
                      class="select-primary"
                      :label="o.label"
                      :value="o.name"
                      :key="'AB' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedType.email_type == 'surveys'" class="row mt-3">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">General settings</p>
            <small>Time when customers receive the survey email, after the date of their event/booking.</small>
          </div>

          <div class="col-lg-10" >
            <label>Send survey emails...</label>
            <div class="row">
              <div class="col-lg-2 col-4" style="padding-right: 0px">
                <base-input :error="errors.period" v-mask="'###'" v-model="selectedType.frequency"></base-input>
              </div>
              <div class="col-lg-6 col-8">
                <base-input>
                  <el-select
                    v-model="selectedType.period"
                    class="select-primary"
                    name="group"
                    placeholder="Period"
                  >
                    <el-option
                      v-for="(o, i) in options"
                      class="select-primary"
                      :label="o.after"
                      :value="o.name"
                      :key="'A' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(s, i) in selectedType.sections" :key="'B' + i">
          <div class="row mt-3" >
            <div class="col-2 d-none d-sm-block" >
              <p style="font-size: small">{{getSectionType(s.section_type)}}</p>
              <small>{{getSectionDescription(s.section_type)}}</small>
            </div>
            <div class="col-lg-10" >
              <el-switch active-color="#e14eca"
                         v-model="s.is_enabled"  />
              <small class="text-white"> &nbsp; Show in all emails</small>

              <base-input class="mt-3" v-if="s.is_enabled" label="Title" v-model="s.title"></base-input>
              <label v-if="s.is_enabled">Content</label>
              <editor v-model="s.content" v-if="s.is_enabled"
                api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                :init="{
                   height: 500,
                   menubar: false,
                   font_formats: 'Andale Mono=andale mono,times; ' +
                    'Arial=arial,helvetica,sans-serif; ' +
                     'Arial Black=arial black,avant garde; ' +
                      'Book Antiqua=book antiqua,palatino; ' +
                       'Comic Sans MS=comic sans ms,sans-serif; ' +
                        'Courier New=courier new,courier; ' +
                         'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                          'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                           'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                            'Terminal=terminal,monaco; ' +
                             'Times New Roman=times new roman,times; ' +
                              'Trebuchet MS=trebuchet ms,geneva; ' +
                               'Ubuntu;' +
                               'Verdana=verdana,geneva;' +
                                ' Webdings=webdings; ' +
                                 'Wingdings=wingdings,zapf dingbats',
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   force_br_newlines : true,
                  force_p_newlines : false,
                  forced_root_block : '',
                  branding: false,
                  statusbar: false,
                   toolbar:
                     'fontselect | formatselect | bold italic forecolor backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | image | link '
                 }"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="selectedType.email_type == 'gift_cards'">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">Redemption button</p>
            <small>Optional button included in gift card delivery emails.</small>
          </div>
          <div class="col-lg-10" >
            <p >
              <el-switch active-color="#e14eca"
                         v-model="selectedType.display_button"  />
              <small class="text-white"> &nbsp; Include in gift card delivery email</small>
            </p>
            <div class="row" v-if="selectedType.display_button">
              <div class="col">
                <base-input placeholder="Redeem gift card" label="Button text" v-model="selectedType.button_text"></base-input>
              </div>
              <div class="col-lg-2">
                <base-input label="Button color">
                  <el-color-picker v-model="selectedType.button_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Button text color">
                  <el-color-picker v-model="selectedType.button_text_color" show-alpha></el-color-picker>
                </base-input>
              </div>


            </div>
            <p v-if="selectedType.display_button">
              <el-switch active-color="#e14eca"
                         v-model="selectedType.redirect_off_domain"  />
              <small class="text-white"> &nbsp; Custom redirect link</small>
            </p>
            <div class="row" v-if="selectedType.display_button && selectedType.redirect_off_domain">
              <div class="col-lg-6">
                <base-input label="Redirect link" placeholder="https://example.com" v-model="selectedType.redirect_url"></base-input>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-3" >
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">Additional settings</p>
          </div>
          <div class="col-lg-10" >
            <p v-if="selectedType.email_type == 'updated_booking' || selectedType.email_type == 'confirmation' || selectedType.email_type == 'reminder'">
              <el-switch active-color="#e14eca"
                         v-model="selectedType.display_waiver_link"  />
              <small class="text-white"> &nbsp; Include waiver link</small>
            </p>
            <p v-if="selectedType.email_type == 'confirmation' || selectedType.email_type == 'gift_card_confirmation' ">
              <el-switch active-color="#e14eca"
                         v-model="selectedType.include_pdf"  />
              <small class="text-white"> &nbsp; Include PDF invoice</small>
            </p>
            <p v-if="selectedType.email_type == 'updated_booking' || selectedType.email_type == 'gift_card_confirmation'
            || selectedType.email_type == 'confirmation' || selectedType.email_type == 'reminder'">
              <el-switch active-color="#e14eca"
                         v-model="selectedType.hide_pricing"  />
              <small class="text-white"> &nbsp; Hide pricing information</small>
            </p>
            <p>
              <el-switch active-color="#e14eca"
                         v-model="selectedType.show_location"  />
              <small class="text-white"> &nbsp; Show business location</small>
            </p>
            <p v-if="selectedType.email_type == 'follow_up' || selectedType.email_type == 'surveys'">
              <el-switch active-color="#e14eca"
                         v-model="selectedType.send_to_group"  />
              <small class="text-white"> &nbsp; Send to everyone in the group</small>
            </p>
          </div>
        </div>
        <div class="row mt-3" v-if="selectedType.email_type == 'surveys'">
          <div class="col-2 d-none d-sm-block">
            <p style="font-size: small">Attached survey</p>
            <small>Select a survey to include as a link in your email.</small>
          </div>
          <div class="col-lg-4">
            <base-input :error="errors.survey" label="Select a survey">
              <el-select
                label="Select time"
                v-model="selectedSurvey"
                class="select-primary"
                value-key="id"
                placeholder="Surveys">
                <el-option
                  v-for="(s, index) in surveys"
                  class="select-primary"
                  :label="s.name"
                  :value="{id: s.id,
                          name: s.name, hash: s.hash}"
                  :key="'S' + index"
                ></el-option>
              </el-select>
            </base-input>

          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <base-button v-on:click="editing = false" type="default">Cancel</base-button>
            <base-button class="ml-2" v-on:click="updateConfigs()" type="primary">Save</base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-lg-3">
      <card>
        <h4>Dynamic variables reference</h4>
        <base-alert type="success">
          You can include dynamic variables in the subject or content of your email. Paste the variables into your text including the brackets.
        </base-alert>
        <div class="row">
          <div class="col-12" >
            <p v-if="selectedType.email_type !== 'abandoned_cart' && selectedType.email_type !== 'gift_card_confirmation' &&
            selectedType.email_type !== 'refund_issued'">[Booking Id] <el-tooltip content="Copy"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
              <base-button
                @click.native="copyVariable('[Booking Id]')"
                class="like btn-link text-white"
                size="sm"
                icon
              ><i class="tim-icons icon-single-copy-04"></i>
              </base-button>
            </el-tooltip>
            </p>
            <p>[Company Name]<base-button
              @click.native="copyVariable('[Company Name]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p>[Customer First Name]<base-button
              @click.native="copyVariable('[Customer First Name]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p>[Customer Last Name]<base-button
              @click.native="copyVariable('[Customer Last Name]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p v-if="selectedType.email_type !== 'surveys' && selectedType.email_type !== 'photos' && selectedType.email_type !== 'abandoned_cart'">[Order Number]<base-button
              @click.native="copyVariable('[Order Number]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p  v-if="selectedType.email_type == 'follow_up'
            || selectedType.email_type == 'surveys'
            || selectedType.email_type == 'photos' ">[Host First Name]<base-button
              @click.native="copyVariable('[Host First Name]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p  v-if="selectedType.email_type == 'follow_up'
            || selectedType.email_type == 'surveys'
            || selectedType.email_type == 'photos' ">[Host Last Name]<base-button
              @click.native="copyVariable('[Host Last Name]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p  v-if="selectedType.email_type == 'follow_up'
            || selectedType.email_type == 'surveys'
            || selectedType.email_type == 'photos' ">[Host Nickname]<base-button
              @click.native="copyVariable('[Host Nickname]')"
              class="like btn-link text-white"
              size="sm"
              icon
            ><i class="tim-icons icon-single-copy-04"></i>
            </base-button>
            </p>
            <p  v-if="selectedType.email_type == 'refund_issued'">[Refund Amount]<base-button
                @click.native="copyVariable('[Refund Amount]')"
                class="like btn-link text-white"
                size="sm"
                icon
              ><i class="tim-icons icon-single-copy-04"></i>
              </base-button>
            </p>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import {Auth} from "aws-amplify";
import BaseSwitch from "@/components/BaseSwitch";
import _ from "lodash";
import BaseAlert from "@/components/BaseAlert";
import swal from "sweetalert2";
import Editor from '@tinymce/tinymce-vue'
import {BTable} from "bootstrap-vue";
import Badge from "@/components/Badge";
import {ColorPicker, Dropdown, DropdownItem, DropdownMenu, Switch} from "element-ui";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";

export default {
  name: "AutomatedEmails",
  components: {
    BaseAlert,
    BaseSwitch,
    'editor': Editor,
    BTable,
    Badge,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [ColorPicker.name]: ColorPicker,
    [Switch.name]: Switch,
    Breadcrumb,
    BreadcrumbItem
  },
  computed: {
    categories() {
      return [{ name: 'General settings', icon: 'tim-icons icon-settings-gear-63', active: true },
        { name: 'Email templates', icon: 'tim-icons icon-email-85',active: false },
      ];
    },
  },
  data(){
    return {
      dns_fields: [],
      from_email: '',
      editing: false,
      group: '',
      loading: false,
      configurations: '',
      setting: '',
      selectedType: '',
      selectedSurvey: '',
      surveys: [],
      errors: {
        reply_to_email: '',
        from_email: '',
        email: '',
        sender_name: '',
        subject: '',
        period: '',
        survey: '',
        email_subject: ''
      },
      j: 0,
      activeTabIndex: 0,
      options: [
        {
          name: 'minute',
          before: 'minute(s) before the event',
          after: 'minute(s) after the event'
        },
        {
          name: 'hour',
          before: 'hour(s) before the event',
          after: 'hour(s) after the event'
        },
        {
          name: 'day',
          before: 'day(s) before the event',
          after: 'days(s) after the event'
        },
        {
          name: 'week',
          before: 'week(s) before the event',
          after: 'weeks(s) after the event'
        },
        {
          name: 'month',
          before: 'month(s) before the event',
          after: 'month(s) after the event'
        }
      ],
      abandonment_options: [
        {
          name: 'minute',
          label: 'minute(s) after abandonment'
        },
        {
          name: 'hour',
          label: 'hour(s) after abandonment',
        },
        {
          name: 'day',
          label: 'days(s) after abandonment'
        }
      ]
    }
  },
  methods: {
    copy(item){
      navigator.clipboard.writeText(item);
    },
    validateGeneralSettingFields(){
      let errors = 0

      if(this.setting.reply_to_email !== '' && !this.isEmailValid(this.setting.reply_to_email)){
        this.errors.reply_to_email = 'Email must be valid'
        errors++
      } else {
        this.errors.reply_to_email = ''
      }
      if(this.setting.from_email !== this.from_email && !this.isEmailValid(this.from_email)){
        this.errors.from_email = 'Email must be valid'
        errors++
      } else {
        this.errors.from_email = ''
      }
      return errors > 0 ? false : true
    },
    saveGeneralSettingsPrompt(){
      swal({
        title: 'Note',
        text: `Sender name and reply to fields will overwrite the same fields on all email templates for this group.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.saveGeneralSettings();
        }
      })
    },
    async saveGeneralSettings(){
      if(!this.validateGeneralSettingFields()){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + this.setting.id + '/general'
      let data = {
        sender_name: this.setting.sender_name,
        reply_to_email: this.setting.reply_to_email
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.getAutomatedEmailConfigurations(this.selectedType)
          swal('Success!', 'Domain has been verified', 'success')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async verifyDomain(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + this.setting.id + '/custom-domain'
      let data = {
        from_email: this.setting.from_email,
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.getAutomatedEmailConfigurations(this.selectedType)
          swal('Success!', 'Domain has been verified', 'success')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async confirmDomain(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + this.setting.id + '/custom-domain'
      let data = {
        from_email: this.from_email,
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.getAutomatedEmailConfigurations(this.selectedType)
          swal('Success!', 'Domain has been confirmed. Copy over DNS values from the table to finish the setup.', 'success')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    copyVariable(text){
      navigator.clipboard.writeText(text);
    },
    previewEmail(item){
      if(item){
        this.selectedType = item
      }
      localStorage.setItem('emailTemplate', JSON.stringify(this.selectedType))
      let routeData = this.$router.resolve('/email/preview')
      window.open(routeData.href);
    },
    getSectionDescription(type){
      if(type == undefined){
        return
      }
      if(type == 'introduction'){
        return "This content will be included in an introduction in all emails sent to customers."
      } else if(type == 'additional_information'){
        return "This section is meant to give customers additional information regarding their purchase. for example what to bring, what to wear and if they should arrive earlier."
      } else if(type == 'reschedule_policy'){
        return "Use this section to give customers information on your rescheduling policy."
      } else if(type == 'cancellation_policy'){
        return "Use this section to give customers information on your cancellation policy."
      }
    },
    getSectionType(type){
      if(type == undefined){
        return
      }
      if(type == 'introduction'){
        return "Introduction content"
      } else if(type == 'additional_information'){
        return "Additional information"
      } else if(type == 'reschedule_policy'){
        return "Reschedule policy"
      } else if(type == 'cancellation_policy'){
        return "Cancellation policy"
      }
    },
    getType(type){
      if(type == undefined){
        return
      }
      if(type == 'abandoned_cart'){
        return "Abandoned cart"
      }
      if(type == 'refund_issued'){
        return "Refund issued"
      }
      if(type == 'confirmation'){
        return "Booking confirmation"
      } else if(type == 'updated_booking'){
        return "Updated booking"
      } else if(type == 'cancelled_transaction'){
        return "Cancelled transaction"
      } else if(type == 'cancelled_booking'){
        return "Cancelled booking"
      } else if(type == 'reminder'){
        return "Reminder email"
      } else if(type == 'follow_up'){
        return "Follow-up email"
      } else if(type == 'photos'){
        return "Photos"
      } else if (type == 'payment_request'){
        return "Payment request"
      } else if (type == 'gift_cards'){
        return "Gift card delivery"
      } else if (type == 'surveys'){
        return "Surveys"
      } else if (type == 'gift_card_confirmation'){
        return "Gift card purchase confirmation"
      }
    },
    getJSON(item){
      if(item == undefined){
        return
      }
      let arr =  _.orderBy(JSON.parse(item), 'id')
      return  arr
    },
    selectType(type){
      this.errors = {
        email: '',
        sender_name: '',
        subject: '',
        period: '',
        survey: ''
      }
      this.selectedType = type
      this.selectedType.display_waiver_link = Boolean(this.selectedType.display_waiver_link)
      this.selectedType.include_pdf = Boolean(this.selectedType.include_pdf)
      this.selectedType.hide_pricing = Boolean(this.selectedType.hide_pricing)
      this.selectedType.show_location = Boolean(this.selectedType.show_location)
      this.selectedType.send_to_group = Boolean(this.selectedType.send_to_group)
      this.selectedType.display_button = Boolean(this.selectedType.display_button)
      this.selectedType.redirect_off_domain = Boolean(this.selectedType.redirect_off_domain)
      for(let n in this.selectedType.sections){
        this.selectedType.sections[n].is_enabled = Boolean(this.selectedType.sections[n].is_enabled)
      }
      this.editing = true
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async toggleActivation(status, item, source){
      let status_message = ''
      if(status === 1){
        status_message = 'activated'
        if(!this.validateFields(item)){
          if(source === 'list'){
            swal('Error', 'Email template contains errors', 'error')
          }
          return
        }
      } else {
        status_message = 'deactivated'
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + this.setting.id + '/activate'
      let data = {
        id: item.id,
        active: status
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.getAutomatedEmailConfigurations(this.selectedType)
          this.selectedType.is_active = status
          swal('Success!', 'Email template has been ' + status_message, 'success')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    validateFields(item){
      let error = 0
      if(this.isEmailValid(item.sender_address) == false) {
        this.errors.email = "You must provide a valid email address"
        error++
      } else {
        this.errors.email = ''
      }
      if(item.sender_name == '') {
        this.errors.sender_name = "Sender name cannot be empty"
        error++
      } else {
        this.errors.sender_name = ''
      }

      if(item.email_subject == '') {
        this.errors.email_subject = "Email subject cannot be empty"
        error++
      } else {
        this.errors.email_subject = ''
      }

      if((item.email_type == 'surveys' || item.email_type == 'follow_up' || item.email_type == 'reminder' )
        && (!item.frequency || !item.period)) {
        this.errors.period = "Field cannot be empty"
        error++
      } else {
        this.errors.period = ''
      }
      if(item.email_type == 'surveys' && !this.selectedSurvey) {
        this.errors.survey = "You must choose a survey"
        error++
      } else {
        this.errors.survey = ''
      }

      return error > 0 ? false : true
    },
    async updateConfigs(){
      if(!this.validateFields(this.selectedType)){
        this.j++
        return
      }

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + this.setting.id
      let data = {
        automated_email_configuration_id: this.selectedType.id,
        email_subject: this.selectedType.email_subject,
        include_pdf: this.selectedType.include_pdf,
        hide_pricing: this.selectedType.hide_pricing,
        display_waiver_link: this.selectedType.display_waiver_link,
        show_location: this.selectedType.show_location,
        sections: this.selectedType.sections,
        email_type: this.selectedType.email_type,
        sender_name: this.selectedType.sender_name,
        sender_address: this.selectedType.sender_address,
        send_to_group: this.selectedType.send_to_group
      }

      if(this.selectedType.email_type == 'surveys'){
        data.survey = this.selectedSurvey.id
      }
      if(this.selectedType.email_type == 'reminder'
        || this.selectedType.email_type == 'follow_up'
        || this.selectedType.email_type == 'surveys'
        || this.selectedType.email_type == 'abandoned_cart'){
        data.frequency = this.selectedType.frequency
        data.period = this.selectedType.period
      }

      if(this.selectedType.email_type == 'gift_cards'){
        data.button_color = this.selectedType.button_color
        data.button_text_color = this.selectedType.button_text_color
        data.button_text = this.selectedType.button_text
        data.display_button = this.selectedType.display_button
        data.redirect_off_domain = this.selectedType.redirect_off_domain
        data.redirect_url = this.selectedType.redirect_url
      }

      this.loading = true
      this.axios.put(url, data, config)
        .then(response => {
          this.getAutomatedEmailConfigurations(this.selectedType)
          swal('Success!', 'Email template has been updated', 'success')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getAutomatedEmailConfigurations(item){

      let aesId = this.$route.query.id
      // if(!localStorage.getItem('editing')){
      //   aesId = JSON.parse(localStorage.getItem('group')).automated_email_settings_id
      //   this.$router.push({ query: { id:  aesId } });
      // }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/automated-email-settings/' + aesId
      this.axios.get(url, config)
        .then(response => {
          localStorage.removeItem('editing')
          this.configurations = _.orderBy(response.data.configurations, 'email_type')
          this.setting = response.data.settings
          this.surveys = response.data.surveys
          this.from_email = this.setting.from_email
          this.dns_fields = [
            {
              type: 'CNAME',
              name: this.setting.dkim_token_1 + '._domainkey.' + this.setting.domain,
              value: this.setting.dkim_token_1 + '.dkim.amazonses.com'
            },
            {
              type: 'CNAME',
              name: this.setting.dkim_token_2 + '._domainkey.' + this.setting.domain,
              value: this.setting.dkim_token_2 + '.dkim.amazonses.com'
            },
            {
              type: 'CNAME',
              name: this.setting.dkim_token_3 + '._domainkey.' + this.setting.domain,
              value: this.setting.dkim_token_3 + '.dkim.amazonses.com'
            },
            {
              type: 'TXT',
              name: '_amazonses.' + this.setting.domain,
              value: this.setting.verification_token
            },
            {
              type: 'TXT',
              name: '_dmarc.' + this.setting.domain,
              value: 'v=DMARC1; p=none;'
            },
            {
              type: 'TXT',
              name: 'mail.' + this.setting.domain,
              value: 'v=spf1 include:amazonses.com ~all'
            },
            {
              type: 'MX',
              name: 'mail.' + this.setting.domain,
              value: '10 feedback-smtp.us-east-1.amazonses.com'
            }
          ]
          for(let n in this.configurations){
            if(this.configurations[n].email_type === 'surveys'){
              this.selectedSurvey = _.find(this.surveys, { id: this.configurations[n].survey_id})
            }

            this.configurations[n].sections = this.getJSON(this.configurations[n].sections)
            this.swapElementsByKey(this.configurations[n].sections, 'section_type', 'cancellation_policy', 'reschedule_policy')
          }
          if(item == undefined){
            this.selectedType = this.configurations[0]
          } else {
            this.selectedType = item
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    swapElementsByKey(array, key, value1, value2) {
      const index1 = array.findIndex(element => element[key] === value1);
      const index2 = array.findIndex(element => element[key] === value2);

      if (index1 !== -1 && index2 !== -1 && index2 > index1) {
        [array[index1], array[index2]] = [array[index2], array[index1]];
      }
    }
  },
  mounted(){
    this.group = JSON.parse(localStorage.getItem('group'))
    this.getAutomatedEmailConfigurations()
  }
}
</script>

<style scoped>
</style>
