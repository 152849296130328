<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/games/clues/select">Hint settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a href="/games/clues/configure">{{ game.name }}</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Hint display configuration</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row mt-3">
    <div class="col-5">
      <card >
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div v-show="activeTabIndex === 0">
          <div class="row">
            <div class="col-6">
              <base-checkbox @input="toggleTimerLayout()" v-model="gameConfig.has_timer">Include timer</base-checkbox>
            </div>
            <div v-if="gameConfig.has_timer" class="col-5">
              <base-input label="Time limit (hh:mm:ss)" v-mask="'##:##:##'" v-model="gameConfig.max_time"></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <base-checkbox @input="toggleHintsLayout()" v-model="gameConfig.has_clue_limit">Limit the number of hints</base-checkbox>
            </div>
            <div v-if="gameConfig.has_clue_limit" class="col-5">
              <base-input label="Maximum number of clues allowed" v-model="gameConfig.max_clues"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_hints">Play a sound before a hint is displayed</base-checkbox>
                </div>
              </div>
              <div class="row" v-if="gameConfig.play_sound_before_hints">
                <div class="col ml-4">
                  <audio v-if="gameConfig.pre_hint_sound_file" controls class="mt-3">
                    <source :src="gameConfig.pre_hint_sound_file">
                  </audio>
                  <!--                <p class="text-white mt-3">{{pre_hint_sound_file.name}}</p>-->
                  <span class="btn btn-primary btn-sm btn-simple btn-file mt-2">Upload audio file
                  <input
                    accept="audio/*"
                    @change="handlePreviewSound"
                    type="file"
                    :multiple="false"
                    aria-invalid="false"
                  />
                </span>
                </div>
              </div>
            </div>
            <div class="col" v-if="gameConfig.play_sound_before_hints">
              <div class="row mt-2">
                <div class="col">
                  <label>When to play the sound:</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_text">Before text hints</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_custom">Before custom hints</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_img">Before image hints</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_audio">Before audio hints</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_sound_before_video">Before video hints</base-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="gameConfig.play_background_music">Play background music</base-checkbox>
                </div>
                <div class="col" v-if="gameConfig.play_background_music">
                  <base-checkbox v-model="gameConfig.loop_background_music">Loop background music</base-checkbox>
                </div>
              </div>
              <div class="row" v-if="gameConfig.play_background_music" :key="i">
                <div class="col ml-4">
                  <audio v-if="gameConfig.background_music_sound_file" controls class="mt-3">
                    <source :src="gameConfig.background_music_sound_file">
                  </audio>
                </div>
              </div>
              <div class="row" v-if="gameConfig.play_background_music">
                <div class="col ml-4">
                  <span class="btn btn-primary btn-sm btn-simple btn-file mt-2">Upload audio file
                    <input
                      accept="audio/*"
                      @change="handleBackgroundMusicFileUpload"
                      type="file"
                      :multiple="false"
                      aria-invalid="false"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-checkbox v-model="gameConfig.is_image_fullscreen">Display image hints in full screen mode</base-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-checkbox v-model="gameConfig.is_video_fullscreen">Play video hints in full screen mode</base-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-checkbox @input="toggleLogoLayout()" v-model="gameConfig.display_logo">Include company logo</base-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <base-checkbox @input="setLayoutCss()" v-model="gameConfig.display_background_image">Include a background image</base-checkbox>
            </div>
          </div>


<!--          <div class="row">-->
<!--            <div class="col">-->
<!--              <base-checkbox @input="setLayoutCss()" v-model="gameConfig.auto_sync">Automatically sync timer and hint count to control panel (Experimental)</base-checkbox>-->
<!--            </div>-->
<!--          </div>-->

          <div v-if="gameConfig.display_background_image" class="row mt-3">
<!--            <div class="col-6">-->
<!--              <img class="fixed-size-img" v-if="gameConfig.img.length <= 0"-->
<!--                   src="https://console.offthecouch.io/img/image_placeholder.jpg"-->
<!--                   alt="preview" />-->
<!--              <img class="fixed-size-img" v-if="gameConfig.img.length > 0" :src="gameConfig.img" alt="preview" />-->
<!--              <span class="btn btn-primary btn-sm btn-simple btn-file mt-2">Upload-->
<!--                  <input-->
<!--                    accept="image/*"-->
<!--                    @change="handlePreview"-->
<!--                    type="file"-->
<!--                    :multiple="false"-->
<!--                    aria-invalid="false"-->
<!--                  />-->
<!--                </span>-->
<!--            </div>-->
            <div class="col">
              <image-editor :show_slider="false" @saveImage="saveImage"></image-editor>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-checkbox v-on:input="setLayoutCss()" v-model="gameConfig.is_portrait_mode">Portrait mode</base-checkbox>
            </div>
          </div>
        </div>
        <div v-show="activeTabIndex === 1">
          <h4 class="card-title">General</h4>
          <div class="row">
            <div class="col">
              <base-input label="Background color">
                <el-color-picker @input="setLayoutCss()" v-model="gameConfig.background_color" show-alpha></el-color-picker>
              </base-input>
            </div>
          </div>
          <h4 class="card-title">Timer</h4>
          <div class="row">
            <div class="col-5">
              <base-input class="text-danger" label="Font">
                <el-select @input="setTimerCss()"
                           v-model="gameConfig.font_family"
                           class="select-primary"
                           :style="{'font-family': gameConfig.font_family}"
                           name="font"
                           placeholder="Font"
                >
                  <el-option
                    v-for="(f, i) in orderedFonts" :key="'B' + i"
                    :style="{'font-family': f.family}"
                    class="select-primary"
                    :label="f.family"
                    :value="f.family"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-3">
              <base-input @input="setTimerCss()" label="Font size" type="number" v-model="gameConfig.font_size">
              </base-input>
            </div>
            <div class="col-4">
              <base-input label="Text color">
                <el-color-picker @input="setTimerCss()" v-model="gameConfig.text_color" show-alpha></el-color-picker>
              </base-input>
            </div>
          </div>

          <h4 class="card-title">Hints</h4>
          <div class="row">
            <div class="col-5">
              <base-input class="text-danger" label="Font">
                <el-select @input="setHintCss()"
                           v-model="gameConfig.hint_text_font"
                           class="select-primary"
                           :style="{'font-family': gameConfig.hint_text_font}"
                           name="font"
                           placeholder="Font"
                >
                  <el-option
                    v-for="(f, i) in orderedFonts" :key="'B' + i"
                    :style="{'font-family': f.family}"
                    class="select-primary"
                    :label="f.family"
                    :value="f.family"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-3">
              <base-input @change="setHintCss()" label="Font size" type="number" v-model="gameConfig.hint_font_size">
              </base-input>
            </div>
            <div class="col-4">
              <base-input label="Text color">
                <el-color-picker @input="setHintCss()" v-model="gameConfig.hint_text_color" show-alpha></el-color-picker>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div v-for="(f, i) in fonts" :key="'C' + i">
              <link rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + f.family">
            </div>
          </div>
          <h4 class="card-title">Hint icons</h4>
          <div class="row">
            <div class="col-4">
              <base-input label="Available hint icon color">
                <el-color-picker @input="setHintIconCss()" v-model="gameConfig.available_hint_color" show-alpha></el-color-picker>
              </base-input>
            </div>
            <div class="col-4">
              <base-input label="Used hint icon color">
                <el-color-picker @input="setHintIconCss()" v-model="gameConfig.used_hint_color" show-alpha></el-color-picker>
              </base-input>
            </div>
            <div class="col">
              <base-input @change="setHintIconCss()" label="Font size" type="number" v-model="gameConfig.hint_icon_font_size">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input label="Icon style">
                <el-select
                  v-model="icon_style"
                  class="select-primary"
                  name="email_group"
                  placeholder="Select icon"
                  value-key="name"
                >
                  <el-option
                    v-for="(icon, index) in icons"
                    class="select-primary"
                    :label="icon.description "
                    :value="{name: icon.name, description: icon.description}"
                    :key="'IC' + index"
                  >
                  <span style="float: left">
                    <i :class="'text-primary tim-icons ' + icon.name "></i> {{ icon.description }}</span>
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <base-button type="primary" :disabled="loading" v-on:click="saveDisplayConfiguration()">Save</base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-7">
      <card>
        <h4 slot="header" class="card-title">Layout</h4>
        <div :key="j" :style="{'width': gameConfig.is_portrait_mode ? '50%' : '100%',
                  'height': gameConfig.is_portrait_mode ? '700px' : '500px'}">
          <grid-layout :layout.sync="layout"
                       :style="layoutCss"
                       :col-num="3"
                       :max-rows="4"
                       :row-height="gameConfig.is_portrait_mode ? 150 : 100"
                       :is-draggable="true"
                       :is-resizable="true"
                       :vertical-compact="false"
                       :use-css-transforms="true"
          >
            <grid-item v-for="(item, index) in layout" :key="index + 'A'"
                       :style="{'background-color': 'transparent',
                      'border': '1px solid ' + invert(gameConfig.background_color), 'border-radius': '3px'}"
                       :x="item.x"
                       :is-bounded="true"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
            >
              <div v-if="item.name == 'Timer'" class="text">
                <p :style="timerCss" style="margin: auto">
                  {{ gameConfig.max_time }}</p>
              </div>
              <div v-if="item.name == 'Logo'" class="text">
                <img style="object-fit: contain" :src="item.content">
              </div>
              <div v-if="item.name == 'Hints'" class="text">
                <div style="float:right; margin-top: auto; margin-bottom: auto; overflow: hidden" v-for="j in parseInt(gameConfig.max_clues)" :key="j">
                  <i :style="hintIconCss" :class="'tim-icons ' + icon_style.name" class="ml-1"></i>
                </div>
              </div>
              <div v-if="item.name == 'Content'" class="text">
                <p :style="hintCss"
                   style="margin: auto">
                  [Hints will be displayed here]</p>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </card>
    </div>
<!--    <div class="col-4">-->
<!--      -->

<!--    </div>-->
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import { ColorPicker, Select, Option, Tooltip } from 'element-ui';
import {BFormInput, BTable} from "bootstrap-vue";
import {BaseAlert, ImageUpload} from "@/components";
import _ from "lodash";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import VueGridLayout from 'vue-grid-layout';
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import icons from "@/assets/icons.json";
import ImageEditor from "@/components/ImageEditor";

export default {
  name: "DisplayConfiguration",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    [ColorPicker.name]: ColorPicker,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    BFormInput,
    BTable,
    ImageUpload,
    BaseAlert,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ImageEditor
  },
  data() {
    return {
      layout: [
        {"x":0,"y":1,"w":3,"h":3,"i":"3", "name": "Content"},
      ],
      draggable: true,
      layoutCss: {},
      hintCss: {},
      timerCss: {},
      hintIconCss: {},
      resizable: true,
      index: 0,
      loading: false,
      game: '',
      fonts: '',
      uploadUrl: '',
      uploadAudioUrl: '',
      gameConfig: {
        id: '',
        img: '',
        background_color: '',
        button_color: '',
      },
      file: '',
      pre_hint_sound_file: '',
      background_music_sound_file: '',
      activeTabIndex: 0,
      j: 0,
      icons: icons.icons,
      icon_style: '',
      i: 0,
      background_image: ''
    }
  },
  computed: {
    categories() {
      return [{ name: 'General settings', icon: 'tim-icons icon-settings-gear-63', active: true },
        { name: 'Appearance', icon: 'tim-icons icon-palette',active: false }
      ];
    },
    orderedFonts: function () {
      _.remove(this.fonts, {
        family: 'Libre Baskerville'
      });
      _.remove(this.fonts, {
        family: 'Material Icons'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Outlined'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Sharp'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Round'
      });
      _.remove(this.fonts, {
        family: 'M PLUS Rounded 1c'
      });
      return _.orderBy(this.fonts, 'family')
    }
  },
  methods: {
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    },
    invert(rgb) {
      if(rgb.includes('#')){
        rgb = this.hexToRGB(rgb, 1)
      }

      let temp_rgb = rgb.match(/(-?[0-9\.]+)/g)

      for (var i = 0; i < temp_rgb.length; i++) {
        temp_rgb[i] = (i === 3 ? 1 : 255) - temp_rgb[i];
      }
      return 'rgba(' +  temp_rgb[0] + ', ' + temp_rgb[1] + ', ' + temp_rgb[2] + ', 1)';
    },
    setHintIconCss(){
      let size = parseInt(this.gameConfig.hint_icon_font_size) / 2
      this.hintIconCss = {
        'color': this.gameConfig.available_hint_color,
        'font-size': size + 'px'
      }
    },
    setTimerCss(){
      let size = parseInt(this.gameConfig.font_size) / 2
      this.timerCss = {
        'color': this.gameConfig.text_color,
        'font-family': this.gameConfig.font_family,
        'font-size': size + 'px'
      }
    },
    setHintCss(){
      let size = parseInt(this.gameConfig.hint_font_size) / 2
      this.hintCss = {
        'color': this.gameConfig.hint_text_color,
        'font-family': this.gameConfig.hint_text_font,
        'font-size': size + 'px'
      }
    },
    changePortraitMode(){

    },
    setLayoutCss(){
      if(this.gameConfig.display_background_image){
        console.log(this.gameConfig.is_portrait_mode)
        let fit = ' / contain'
        if(this.background_image.object_fit === 'cover'){ fit = ' / cover'}
        else if(this.background_image.object_fit === 'none'){ fit = ''}
        this.layoutCss = {'border-radius': '3px',
          'border': '1px solid ' + this.invert(this.gameConfig.background_color),
          'height': this.gameConfig.is_portrait_mode ? '650px' : '450px',
          'background': 'url('+ this.background_image.url + ') '
            + this.background_image.background_color + ' no-repeat center' + fit}
      } else {
        this.layoutCss = {'background-color': this.gameConfig.background_color, 'border-radius': '3px',
          'border': '1px solid ' + this.invert(this.gameConfig.background_color),
          'height': this.gameConfig.is_portrait_mode ? '650px' : '450px'}
      }
      this.j++
    },
    toggleHintsLayout(){
      if(this.gameConfig.has_clue_limit){
        this.layout.push({"x":2,"y":0,"w":1,"h":1,"i":"2", "name": "Hints"})
      } else {
        this.layout = this.layout.filter(item => item.name !== 'Hints');
      }
    },
    toggleLogoLayout(){
      if(this.gameConfig.display_logo){
        let logo = JSON.parse(localStorage.getItem('company')).img
        this.layout.push({"x":0,"y":0,"w":1,"h":1,"i":"1", "name": "Logo", "content": logo})
      } else {
        this.layout = this.layout.filter(item => item.name !== 'Logo');
      }
    },
    toggleTimerLayout(){
      if(this.gameConfig.has_timer){
        this.layout.push({"x":1,"y":0,"w":1,"h":1,"i":"0", "name": "Timer"})
      } else {
        this.layout = this.layout.filter(item => item.name !== 'Timer');
      }
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file, this.uploadUrl)
    },
    async saveAudioFile() {
      this.uploadAudioUrl = await this.getSignedURL(this.pre_hint_sound_file)
      let uploaded = await this.uploadFile(this.pre_hint_sound_file, this.uploadAudioUrl)
    },
    async saveBackgroundAudioFile(){
      this.uploadAudioUrl = await this.getSignedURL(this.background_music_sound_file)
      let uploaded = await this.uploadFile(this.background_music_sound_file, this.uploadAudioUrl)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file, url) {
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    getFonts(){
      let url = API_LOCATION + 'fonts'

      axios.get(url)
        .then(response => {
          this.fonts = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getClueDisplayConfigurations(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/clue-display-configurations'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameConfig = response.data.configurations
          this.background_image = response.data.background_image[0]
          this.icon_style = { name: this.gameConfig.icon_style}
          if(response.data.layout.length > 0){
            console.log(response.data.layout)
            this.layout = response.data.layout
            for(let n in this.layout){
              if(this.layout[n].name === 'Logo'){
                this.layout[n].content = JSON.parse(localStorage.getItem('company')).img
              }
            }
          } else {
            this.toggleLogoLayout()
            this.toggleTimerLayout()
            this.toggleHintsLayout()
          }
          this.setHintIconCss()
          this.setLayoutCss()
          this.setHintCss()
          this.setTimerCss()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    validLayout(){
      //1. y cannot be greater than max rows
      //2. max - y cannot be greater than height
      for(let n in this.layout){
        if(this.layout[n].y > 4){
          console.log('1')

          console.log(this.layout[n])
          return false
        }
        if((4-this.layout[n].y) < this.layout[n].h){
          console.log('2')

          console.log(this.layout[n])

          return false
        }
        return true
      }
    },
    async saveImage(value){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images'

      let data = {
        clue_display_configurations: this.gameConfig.id,
        url: value.url,
        scale: value.scale,
        description: value.description,
        background_color: value.background_color,
        x: value.x,
        y: value.y,
        is_primary: 0,
        object_fit: value.object_fit
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.gameConfig.img = value.url
          this.getClueDisplayConfigurations()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveDisplayConfiguration(){
      this.loading = true
      if(!this.validLayout()){
        swal('Error', 'Layout components are out of bounds.', 'error')
        return
      }
      // if(this.file != ''){
      //   await this.saveFile()
      //   this.gameConfig.img = this.uploadUrl.split("?")[0]
      // }
      if(this.pre_hint_sound_file != ''){
        await this.saveAudioFile()
        this.gameConfig.pre_hint_sound_file = this.uploadAudioUrl.split("?")[0]
      }

      if(this.background_music_sound_file != ''){
        await this.saveBackgroundAudioFile()
        this.gameConfig.background_music_sound_file = this.uploadAudioUrl.split("?")[0]
      }

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/clue-display-configurations'
      let data = {
        img: this.gameConfig.img,
        background_color: this.gameConfig.background_color,
        text_color: this.gameConfig.text_color,
        font_family: this.gameConfig.font_family,
        has_timer: this.gameConfig.has_timer,
        max_clues: this.gameConfig.max_clues,
        max_time: this.gameConfig.max_time,
        has_clue_limit: this.gameConfig.has_clue_limit,
        display_background_image: this.gameConfig.display_background_image,
        display_logo: this.gameConfig.display_logo,
        hint_text_color: this.gameConfig.hint_text_color,
        hint_text_font: this.gameConfig.hint_text_font,
        is_video_fullscreen: this.gameConfig.is_video_fullscreen,
        is_image_fullscreen: this.gameConfig.is_image_fullscreen,
        available_hint_color: this.gameConfig.available_hint_color,
        used_hint_color: this.gameConfig.used_hint_color,
        font_size: this.gameConfig.font_size,
        hint_font_size: this.gameConfig.hint_font_size,
        play_sound_before_hints: this.gameConfig.play_sound_before_hints,
        play_sound_before_text: this.gameConfig.play_sound_before_text,
        play_sound_before_custom: this.gameConfig.play_sound_before_custom,
        play_sound_before_img: this.gameConfig.play_sound_before_img,
        play_sound_before_audio: this.gameConfig.play_sound_before_audio,
        play_sound_before_video: this.gameConfig.play_sound_before_video,
        pre_hint_sound_file: this.gameConfig.pre_hint_sound_file,
        hint_icon_font_size: this.gameConfig.hint_icon_font_size,
        auto_sync: this.gameConfig.auto_sync,
        id: this.gameConfig.id,
        layout: this.layout,
        icon_style: this.icon_style.name,
        play_background_music: this.gameConfig.play_background_music,
        loop_background_music: this.gameConfig.loop_background_music,
        background_music_sound_file: this.gameConfig.background_music_sound_file,
        is_portrait_mode: this.gameConfig.is_portrait_mode
      }
      axios.put(url, data, config)
        .then(response => {
          swal('Success', 'Hint configuration have been updated.', 'success')
          this.getClueDisplayConfigurations()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handlePreviewSound(){
      let file = event.target.files[0];
      this.gameConfig.pre_hint_sound_file = URL.createObjectURL(file);
      this.pre_hint_sound_file = file
      this.$emit('change', file);
    },
    handleBackgroundMusicFileUpload(){
      let file = event.target.files[0];
      console.log(file)
      this.gameConfig.background_music_sound_file = URL.createObjectURL(file);
      this.background_music_sound_file = file
      console.log(this.background_music_sound_file)
      this.i++
      this.$emit('change', file);
    },
    handlePreview (){
      let file = event.target.files[0];
      this.gameConfig.img = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
  },
  mounted(){
    this.game = JSON.parse(localStorage.getItem("game"))
    this.getFonts()
    this.getClueDisplayConfigurations()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 200px; /* height of container */
  object-fit: cover;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  justify-content:center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 120px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='10'><circle cx='5' cy='5' r='5' fill='#22213'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
