<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="row mb-2">
          <div class="col">
            <h4 class="card-title">Photo overlays</h4>
          </div>
          <div class="col text-right d-block d-sm-none">
            <base-button icon type="primary" v-on:click="createNewOverlay()">
              <i class="tim-icons el-icon-plus"></i></base-button>
          </div>
          <div class="col text-right d-none d-sm-block">
            <base-button size="sm" type="primary" v-on:click="createNewOverlay()">
              <i class="tim-icons el-icon-plus"></i> Create new overlay</base-button>
          </div>
        </div>

        <div class="row" v-if="overlays.length <= 0">
          <div class="col">
            <div class="mt-3">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No overlays have been created.</h4>
              </div>
            </div>
          </div>
        </div>
        <div v-if="overlays.length > 0" class="d-block d-sm-none">
          <b-table  striped hover :items="overlays"
                   :fields="['overlays']">
            <template v-slot:cell(overlays)="row">
              <div class="row">
                <div class="col">
                  <p>{{row.item.name}}</p>
                  <small>{{row.item.description}}</small>
                </div>
                <div class="col text-right">
                  <badge>{{row.item.aspect_ratio}}</badge>
                </div>
              </div>

              <div
                class="btn-group btn-group-toggle mt-3 mb-2"
                :class="'float-left'"
                data-toggle="buttons" style="width:100%"
              >
                <label class="btn btn-sm btn-primary btn-simple">
                  <base-button
                    @click.native="editOverlay(row.item)"
                    type="primary"
                    class="btn btn-link"
                    size="sm"
                    icon
                  ><i class="tim-icons icon-pencil"></i>
                  </base-button>
                </label>
                <label class="btn btn-sm btn-primary btn-simple">
                  <base-button
                    @click.native="deleteOverlay(row.item)"
                    type="primary"
                    class="btn btn-link"
                    size="sm"
                    icon
                  ><i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </label>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="overlays.length > 0" class="d-sm-block d-none">
          <b-table  striped hover :items="overlays"
                    :fields="['name', 'aspect_ratio', 'actions']">
            <template v-slot:cell(name)="row">
              <p>{{row.item.name}}</p>
              <small>{{row.item.description}}</small>
            </template>
            <template v-slot:cell(actions)="row">
              <el-dropdown>
                <base-button
                  link
                  type="primary"
                  size="sm"
                > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item" @click="editOverlay(row.item)" href="#">
                      <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit overlay
                    </a>
                    <hr>
                    <a class="dropdown-item" @click="deleteOverlay(row.item)" href="#">
                      <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete overlay
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </b-table>
        </div>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {BTable} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import Badge from "@/components/Badge";

export default {
  name: "PhotoSettingsTemplate",
  components: {
    BTable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    Badge
  },
  data() {
    return {
      loading: false,
      overlays: []
    }
  },
  methods: {
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getTemplate() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/photo-settings/' + this.$route.query.id

      axios.get(url, config)
        .then(response => {
          this.overlays = response.data.overlays
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createNewOverlay(){
      this.$router.push({ path: '/photos/overlays', query: { id:  this.$route.query.id }})
    },
    editOverlay(item){
      this.$router.push({ path: '/photos/overlays', query: { id:  this.$route.query.id, oid: item.id }})
    },
    async deleteOverlay(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let settingId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/photo-settings/' + settingId + '/overlays/' + item.id

      axios.delete(url, config)
        .then(response => {
          swal('Overlay deleted', '', 'success')
          this.getTemplate()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted(){
    this.getTemplate()
  }
}
</script>

<style scoped>
</style>
