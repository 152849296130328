<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/user-management">Users</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a href="/settings/roles">Roles</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>New Role</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col-lg-4">
      <card>
        <div class="row">
          <div class="col">
            <h4 class="card-title">Edit role</h4>
          </div>
          <div class="col text-right">
            <el-dropdown>
              <base-button
                link
                type="primary"
                size="sm"
              > <i style="font-size: large" class="tim-icons el-icon-more"></i>
              </base-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <a class="dropdown-item" @click="deleteRolePrompt()" href="#">
                    <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete role
                  </a>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <base-input v-model="role.name" placeholder="Role name" label="Name"></base-input>
            <base-input label="Description">
              <el-input
                v-model="role.description"
                :rows="5"
                type="textarea"
                placeholder="Role description"
              />
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-button v-on:click="cancel()" type="default">Cancel</base-button>
            <base-button class="ml-2" v-on:click="saveRole()" type="primary">Save</base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-lg-8">
      <card>
        <div class="row">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter">{{ option.name }}</span>
              </label>
            </div>
          </div>
        </div>
<!--        Permissions-->
        <div v-if="activeTabIndex === 0">

          <div class="row" v-for="(p, i) in permissions" :key="i + 'P'">
            <div class="col">
              <h4 class="mt-3 text-primary text-uppercase card-title">{{ p.parent_feature }}</h4>
              <div class="row">
                <div class="col-lg-6">
                  <div class="row" v-for="(pp, j) in filteredItems(p.items, 0)" :key="j + 'PP'">
                    <div class="col-lg-8">
                      <p>{{pp.name}}</p>
                    </div>
                    <div class="col text-right">
                      <base-switch  style="font-size: x-small" v-model="pp.enabled">
                        <i class="tim-icons icon-check-2" slot="on"></i>
                        <i class="tim-icons icon-simple-remove" slot="off"></i>
                      </base-switch>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row" v-for="(pp, j) in filteredItems(p.items, 1)" :key="j + 'PP'">
                    <div class="col-lg-8">
                      <p>{{pp.name}}</p>
                    </div>
                    <div class="col-lg-4 text-right">
                      <base-input v-if="pp.has_options === 1">
                        <el-select
                          v-model="pp.enabled"
                          class="select-primary"
                          name="email_group"
                          placeholder="Email Group"
                          value-key="id"
                        >
                          <el-option
                            v-for="(a, i) in access_options"
                            class="select-primary"
                            :label="a.label"
                            :value="{id: a.id, label: a.label}"
                            :key="'A' + i"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        Notifications-->
        <div v-if="activeTabIndex === 1">
          <h4 class="mt-3 text-primary text-uppercase card-title">Email notifications</h4>
          <div class="row" v-for="(n, i) in notifications" :key="i + 'N'">
            <div class="col-lg-3">
              <p style="font-size: small">{{n.name}}</p>
            </div>
            <div class="col-lg-2 text-right">
              <base-switch style="font-size: x-small" v-model="n.enabled">
                <i class="tim-icons icon-check-2" slot="on"></i>
                <i class="tim-icons icon-simple-remove" slot="off"></i>
              </base-switch>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {BTable} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {BaseSwitch} from "@/components";
import _ from "lodash";

export default {
  name: "EditRole",
  components: {
    BTable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BreadcrumbItem,
    Breadcrumb,
    BaseSwitch
  },
  data() {
    return {
      loading: false,
      permissions: [],
      notifications: [],
      role: '',
      activeTabIndex: 0,
      j: 0,
      access_options: [{id: 2, label: 'All'}, {id: 1, label: 'Only my own'}, {id: 0, label: 'No access'}]
    }
  },
  computed: {
    categories() {
      return [{ name: 'Permissions', icon: 'tim-icons icon-bullet-list-67', active: true },
        { name: 'Notifications', icon: 'tim-icons icon-chart-bar-32', active: false }
      ];
    },
  },
  methods: {
    deleteRolePrompt(){
      swal({
        title: 'Are you sure?',
        text: `The role will be permanently deleted. Users may lose access.`,
        showCancelButton: true,
        type: 'warning',
      }).then((response)=> {
        if(response.value == true){
          this.deleteRole()
        }
      })
    },
    async deleteRole(){
      let companyId = localStorage.getItem('companyId')
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/roles/' + this.role.id
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'The role has been deleted', 'success')
          this.$router.push('/settings/roles')
        })
        .catch(err => {
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    cancel(){
      this.$router.push('/settings/roles')
    },
    filteredItems(array, type) {
      return array.filter(item => item.has_options === type);
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    flattenGroupedArray(groupedArray) {
      return groupedArray.reduce((acc, group) => {
        return acc.concat(group.items);
      }, []);
    },
    groupByParentFeature(arr) {
      for(let n in arr){
        if(arr[n].has_options === 1){
          arr[n].enabled = _.find(this.access_options, {id: arr[n].enabled})
        }
      }

      const grouped = arr.reduce((acc, item) => {
        const parentFeature = item.parent_feature;
        if (!acc[parentFeature]) {
          acc[parentFeature] = [];
        }
        acc[parentFeature].push(item);
        return acc;
      }, {});

      return Object.keys(grouped)
        .sort()
        .map(key => ({
          parent_feature: key,
          items: grouped[key]
      }));
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async saveRole(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let roleId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/roles/' + roleId

      let data = {
        role: this.role,
        permissions: this.flattenGroupedArray(this.permissions),
        notifications: this.notifications
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.getRole()
          swal('Success', 'Role has been updated' , 'success')
        })
        .catch(err => {
          this.loading = false
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getRole() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let roleId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/roles/' + roleId

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.role = response.data.role
          this.notifications = response.data.notifications
          this.permissions = this.groupByParentFeature(response.data.permissions)

        })
        .catch(err => {
          this.loading = false
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.getRole()
  }
}
</script>

<style scoped>

</span>
