<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb v-if="createEmailGroup || view_contacts" style="margin-left: -15px">
      <breadcrumb-item>
        <a href="" v-on:click="createEmailGroup = false">Email groups</a>
      </breadcrumb-item>
      <breadcrumb-item v-if="createEmailGroup">
        <span>Create new email group</span>
      </breadcrumb-item>
      <breadcrumb-item v-if="view_contacts">
        <span>Contacts for {{selectedGroup.name}}</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--    Add email group-->
    <div class="row d-sm-block d-none container">
      <base-button v-if="createEmailGroup == false && view_contacts == false" @click="createEmailGroup=true" type="primary" >Create Email Group</base-button>
    </div>


    <!--Medium and large screens-->
    <div v-if="createEmailGroup == false && view_contacts == false" class="row mt-3 d-none d-sm-block overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Groups</h4>
          <!--   No data panel -->
          <div v-if="empty==true && createEmailGroup == false" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">You don't have any email groups yet.</h4>
            </div>
          </div>
          <b-table v-if="empty==false" striped hover :items="email_groups"
                    :fields="fields">
            <template v-slot:head(name)="row">
              <span>Email Group</span>
            </template>
            <template v-slot:cell(name)="row">
              <h4 class="text-primary">{{row.item.name}}</h4>
            </template>
<!--            <template v-slot:cell(contacts)="row">-->
<!--              <p>0</p>-->
<!--            </template>-->
<!--            <template v-slot:cell(subscribed)="row">-->
<!--              <i v-if="row.item.subscribed == 1" class="text-success tim-icons icon-check-2"></i>-->
<!--              <i v-if="row.item.subscribed == 0" class="text-success tim-icons icon-simple-remove"></i>-->

<!--            </template>-->
            <template v-slot:cell(actions)="row">
              <div class="row">
                <el-tooltip content="Delete group"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    class="like btn-link"
                    type="danger"
                    size="sm"
                    @click.native="deleteGroup(row.item)"
                    icon
                  ><i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="View contacts"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    class="like btn-link"
                    type="success"
                    size="sm"
                    @click.native="viewContacts(row.item, perPageExistingContacts, 0)"
                    icon
                  ><i class="tim-icons el-icon-view"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </template>
          </b-table>
        </card>
      </div>
    </div>

    <!--Medium and large screens-->
    <div v-if="createEmailGroup == true && view_contacts == false" class="row mt-3 d-none d-sm-block overflow-scroll">
      <div class="col">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Create email group</h4>
          <div class="row">
            <div class="col-9">
              <base-input label="Name" type="text" v-model="group_name" placeholder="Email group name"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <base-input label="Description" type="text" v-model="group_description" placeholder="Description"></base-input>
            </div>
          </div>
          <!--          <div class="row table-content">-->
          <!--            <div v-if="empty === false" class="row table-content">-->
          <!--              <div class="col-md-12 col-lg-12 col-sm-12 col-12">-->
          <label>Select contacts</label>
          <p v-if="this.selected_contacts > 0">Number selected: {{selected_contacts}}</p>

          <div class="row">
            <div class="col d-flex justify-content-between">
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Name"
                            v-model="filterContacts.first_name.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filterContacts.first_name, 'new')" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateContacts('new')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First name
                      <span v-if="filterContacts.first_name.actual">: {{filterContacts.first_name.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by last name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Name"
                            v-model="filterContacts.last_name.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filterContacts.last_name, 'new')" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateContacts('new')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Last name
                      <span v-if="filterContacts.last_name.actual">: {{filterContacts.last_name.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filterContacts.email.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filterContacts.email, 'new')" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateContacts('new')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Email
                      <span v-if="filterContacts.email.actual">: {{filterContacts.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select multiple
                                       v-model="filterContacts.game.value"
                                       class="select-primary"
                                       name="game"
                                       placeholder="Game"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="game.name"
                                :key="game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filterContacts.game, 'new')" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateContacts('new')">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game: <span v-if="filterContacts.game.actual" v-for="(g, i) in filterContacts.game.actual" :key="'GA' + i">&nbsp; {{g}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll('new')"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="ml-3 text-white">Number of&nbsp;contacts:&nbsp;{{totalRowsContacts}}</span>

                </base-input>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPageContacts"
                  placeholder="Per page"
                  v-on:change="getContacts()"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--   no data panel -->
              <div v-if="contacts.length <= 0" class="mt-3" >
                <div class="font-icon-detail">
                  <span style="font-size: 3em">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                  <h4 class="mt-3">No customers to display.</h4>
                </div>
              </div>
              <b-table v-if="contacts.length > 0" striped hover :items="contacts"
                       :fields="contactFields">
                <template #head(select)="data">
                  <base-checkbox v-on:input="selectAll()" v-model="select_all"></base-checkbox>
                </template>
                <template v-slot:cell(select)="row">
                  <base-checkbox v-on:input="selectCount()" v-model="row.item.selected"></base-checkbox>

                </template>
                <template v-slot:cell(games)="row">
               <span v-for="(g, i) in getJSON(row.item.games)" :key="'GS' + i">
                <badge type="primary">{{g.game_name}}</badge>&nbsp;
              </span>
                </template>
                <template v-slot:cell(subscribed)="row">
                  <i v-if="row.item.subscribed == 1" class="text-success tim-icons icon-check-2"></i>
                  <i v-if="row.item.subscribed == 0" class="text-danger tim-icons icon-simple-remove"></i>
                </template>
              </b-table>
              <b-pagination v-if="contacts.length > 0"
                            v-model="currentPageContacts"
                            :total-rows="totalRowsContacts"
                            :per-page="perPageContacts"
                            v-on:input="getContacts()"
              ></b-pagination>
            </div>
          </div>
          <div class="row ml-1">
            <div class="col-6">
              <base-button v-on:click="cancel()" simple class="mr-3" type="primary">Cancel</base-button>
              <base-button v-on:click="saveGroup()" type="primary">Save</base-button>
            </div>
          </div>

        </card>
      </div>
    </div>

    <div v-if="view_contacts == true && createEmailGroup == false" class="row mt-3 d-none d-sm-block overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4>Contacts for {{selectedGroup.name}}</h4>



          <div class="row">

            <div class="col-lg-10 col-md-8 col-sm-10 col-8">

              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by first name</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filtersExistingContacts.first_name.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filtersExistingContacts.first_name, 'existing')" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts('existing')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    First name
                    <span v-if="filtersExistingContacts.first_name.actual">: {{filtersExistingContacts.first_name.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by last name</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filtersExistingContacts.last_name.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filtersExistingContacts.last_name, 'existing')" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts('existing')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Last name
                    <span v-if="filtersExistingContacts.last_name.actual">: {{filtersExistingContacts.last_name.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by email</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Email"
                          v-model="filtersExistingContacts.email.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filtersExistingContacts.email, 'existing')" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts('existing')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Email
                    <span v-if="filtersExistingContacts.email.actual">: {{filtersExistingContacts.email.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select multiple
                                     v-model="filtersExistingContacts.game.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Game"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="game.name"
                              :key="game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filtersExistingContacts.game, 'existing')" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts('existing')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Game: <span v-if="filtersExistingContacts.game.actual" v-for="(g, i) in filtersExistingContacts.game.actual" :key="'GA' + i">&nbsp; {{g}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <base-button size="sm" v-if="remove_button == true" v-on:click="removeContactsFromGroup()" simple class="ml-2" type="danger">Remove selected contacts</base-button>
                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll('existing')"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="ml-3 text-white">Number of&nbsp;contacts:&nbsp;{{totalRowsExistingContacts}}</span>

              </base-input>

            </div>
            <div class="row justify-content-end">
              <base-button icon class="btn-link text-white" v-if="hasPermissions('download_data')" v-on:click="downloadCSV()">
                <i class="tim-icons icon-cloud-download-93"></i>
              </base-button>
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPageExistingContacts"
                placeholder="Per page"
                v-on:change="viewContacts(selectedGroup)"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!--   no data panel -->
          <div v-if="group_contacts.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
                  <span style="font-size: 3em">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
              <h4 class="mt-3">No customers to display.</h4>
            </div>
          </div>
          <b-table v-if="group_contacts.length > 0" striped hover :items="group_contacts"
                    :fields="contactFields">
            <template #head(select)="data">
              <span class="text-info"></span>
            </template>
            <template v-slot:cell(select)="row">
              <base-checkbox v-on:input="showActionButtons()" v-model="row.item.selected"></base-checkbox>
            </template>
            <template v-slot:cell(games)="row">
               <span v-for="(g, i) in getJSON(row.item.games)" :key="'G' + i">
                <badge type="primary">{{g.game_name}}</badge>&nbsp;
              </span>
            </template>
            <template v-slot:cell(subscribed)="row">
              <i v-if="row.item.subscribed == 1" class="text-success tim-icons icon-check-2"></i>
              <i v-if="row.item.subscribed == 0" class="text-danger tim-icons icon-simple-remove"></i>
            </template>
          </b-table>
          <b-pagination v-if="group_contacts.length > 0"
                        v-on:input="viewContacts(selectedGroup)"
                        v-model="currentPageExistingContacts"
                        :total-rows="totalRowsExistingContacts"
                        :per-page="perPageExistingContacts"
          ></b-pagination>
          <div class="row">
            <div class="col-6">
              <base-button v-on:click="view_contacts = false" simple class="mr-3" type="primary">Back</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import {BFormInput, BPagination, BTable} from "bootstrap-vue";
import swal from "sweetalert2";
import axios from "axios";
import Badge from "@/components/Badge";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "EmailGroups",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    Badge,
    BFormInput,
    BTable,
    BPagination
  },
  data() {
    return {
      pending_index: -1,
      loading: false,
      gameOptions: [],
      empty: false,
      email_groups: [],
      group_name: '',
      selectedGroup: '',
      group_contacts: [],
      group_description: '',
      contacts: '',
      contactFields: ["select", "first_name", "last_name", "email", "games", "subscribed"],
      createEmailGroup: false,
      fields: ["name", "description", "contacts", "actions"],
      view_contacts: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageContacts: 10,
      currentPageContacts: 1,
      totalRowsContacts: 0,
      selected_contacts: 0,
      select_all: false,
      remove_button: false,
      currentPageExistingContacts: 1,
      totalRowsExistingContacts: 0,
      perPageExistingContacts: 10,
      perPageOptions: [5, 10, 25, 50, 100, 250],
      filterContacts: {
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: '',
        },
        game: {
          value: '',
          actual: ''
        }
      },
      filtersExistingContacts: {
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: '',
        },
        game: {
          value: '',
          actual: ''
        }
      },
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    getJSON(item){
      return JSON.parse(item)
    },
    async getGames () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameOptions = response.data
          console.log(this.gameOptions)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    resetAll(table){
      if(table == 'new'){
        this.filterContacts = {
          first_name: {
            value: '',
              actual: ''
          },
          last_name: {
            value: '',
              actual: ''
          },
          email: {
            value: '',
              actual: '',
          },
          game: {
            value: '',
            actual: ''
          }
        }
        this.getContacts()
      } else {
        this.filtersExistingContacts = {
          first_name: {
            value: '',
              actual: ''
          },
          last_name: {
            value: '',
              actual: ''
          },
          email: {
            value: '',
              actual: '',
          },
          game: {
            value: '',
            actual: ''
          }
        }
        let count = this.perPageExistingContacts
        let offset = parseInt((this.currentPageExistingContacts * this.perPageExistingContacts) - this.perPageExistingContacts)
        this.viewContacts(this.selectedGroup, count, offset)
      }
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
    selectAll(){
      for(let n in this.contacts){
        if(this.select_all == true){
          this.contacts[n].selected = true
          this.selected_contacts = this.totalRowsContacts
        } else {
          this.contacts[n].selected = false
          this.selected_contacts = 0
        }
      }
    },
    selectCount(){
      let count = 0
      let r_count = 0
      for(let n in this.contacts){
        if(this.contacts[n].selected == true){
          count++
        } else {
          r_count++
        }
      }
      if(this.select_all == true){
        this.selected_contacts = this.totalRowsContacts - r_count
      } else {
        this.selected_contacts = count
      }
    },
    async downloadCSV(){
      //get the entire data set
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      var count = 1000000
      var csvData = await this.getAllGroupContacts(config, count, this.selectedGroup)
      this.loading = false

      let csv = 'First Name,Last Name,Email,Subscribed\n';
      csvData.forEach(row => {
        csv += [row.first_name, row.last_name, row.email, this.formatSubscribed(row.subscribed)]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'contacts.csv';
      anchor.click();
    },
    formatSubscribed(subscribed){
      if(subscribed === 1){
        return true
      } else {
        return false
      }
    },
    async getAllGroupContacts(config, count, group){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')

      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId  + '/mail/groups/' + group.id + '?count='
          + count + "&offset=" + offset
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.contacts)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  this.$router.push('/forbidden')
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    resetField(field, table){
      field.value = ''
      field.actual = ''
      if(table == 'existing'){
        this.viewContacts(this.selectedGroup)
      } else if (table == 'new'){
        this.getContacts()
      }
    },
    updateContacts(table){
      if(table == 'existing'){
        this.filtersExistingContacts.first_name.actual = this.filtersExistingContacts.first_name.value
        this.filtersExistingContacts.last_name.actual = this.filtersExistingContacts.last_name.value
        this.filtersExistingContacts.email.actual = this.filtersExistingContacts.email.value
        this.filtersExistingContacts.game.actual = this.filtersExistingContacts.game.value
        this.viewContacts(this.selectedGroup)
      } else {
        this.filterContacts.first_name.actual = this.filterContacts.first_name.value
        this.filterContacts.last_name.actual = this.filterContacts.last_name.value
        this.filterContacts.email.actual = this.filterContacts.email.value
        this.filterContacts.game.actual = this.filterContacts.game.value
        this.getContacts()
      }

    },
    showActionButtons(){
      let count = 0
      for(let n in this.group_contacts){
        if(this.group_contacts[n].selected == 1){
          count++
        }
      }
      if(count > 0){
        this.remove_button = true
      } else {
        this.remove_button = false
      }
    },
    async removeContactsFromGroup(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups/' + this.selectedGroup.id
      let data = { contacts: [] }

      for(let n in this.group_contacts){
        if(this.group_contacts[n].selected == 1){
          data.contacts.push(this.group_contacts[n])
        }
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.viewContacts(this.selectedGroup)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async viewContacts(group){
      let count = this.perPageExistingContacts
      let offset = parseInt((this.currentPageExistingContacts * this.perPageExistingContacts) - this.perPageExistingContacts)
      this.selectedGroup = group
      this.view_contacts = true
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups/' + group.id +
              '?count=' + count + '&offset=' + offset + '&first_name=' + encodeURIComponent(this.filtersExistingContacts.first_name.value) +
              '&last_name=' + encodeURIComponent(this.filtersExistingContacts.last_name.value) + '&email=' + encodeURIComponent(this.filtersExistingContacts.email.value) + '&game=' + (this.filtersExistingContacts.game.actual)
      this.axios.get(url, config)
        .then(response => {
          this.group_contacts = response.data.contacts
          this.totalRowsExistingContacts = response.data.totalRows
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getEmailGroups (pending) {
      if(!pending){
        this.loading = true
      }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups?count=&offset='
      this.axios.get(url, config)
        .then(response => {
          this.email_groups = response.data.email_groups
          if(this.email_groups.length <= 0 ){
            this.empty = true
          } else {
            this.empty = false
          }
          this.totalRows = response.data.totalRows
          if(!pending){
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteGroup(item){
      swal({
        title: 'Are you sure?',
        text: `The email group will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.delete(item);
        }
      })
    },
    cancel(){
      this.createEmailGroup = false
      this.group_name = ""
      this.group_description = ""
    },
    async saveGroup() {
      let c = []
      let r = []
      for(let n in this.contacts){
        if(this.contacts[n].selected == true){
          c.push(this.contacts[n])
        } else {
          r.push(this.contacts[n])
        }
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups'
      let data = {
        name: this.group_name,
        description: this.group_description,
        contacts: c,
        select_all: this.select_all,
        removed_contacts: r
      }
      this.axios.post(url, data, config)
        .then(response => {

          setTimeout( () => {
            this.createEmailGroup = false
            this.select_all = false
            this.group_name = ''
            this.group_description = ''
            this.resetAll('new')
            this.loading = false
            this.getEmailGroups(true)
          }, 5000)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async delete(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.getEmailGroups()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getContacts () {
      var count = this.perPageContacts
      var offset = parseInt((this.currentPageContacts * this.perPageContacts) - this.perPageContacts)
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/contacts?count=' + count + '&offset=' + offset + '&first_name=' + encodeURIComponent(this.filterContacts.first_name.value) +
        '&last_name=' + encodeURIComponent(this.filterContacts.last_name.value) + '&email=' + encodeURIComponent(this.filterContacts.email.value) + '&game=' + encodeURIComponent(this.filterContacts.game.actual)
      this.axios.get(url, config)
        .then(response => {
          this.contacts = response.data.contacts
          if(this.contacts.length <= 0 ){
            this.empty = true
          } else {
            this.empty = false
          }
          this.totalRowsContacts = response.data.totalRows

          this.selectAll()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getGames()
    this.getEmailGroups()
    this.getContacts()
  }
}
</script>

<style scoped>

</style>
