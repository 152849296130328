<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="#" v-on:click="$router.back()">Gift cards</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span v-if="!$route.query.hasOwnProperty('id')">Create gift card type</span>
        <span v-else>{{gift_card_type_name}}</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row mt-3 overflow-scroll">
      <div class="col-lg-4">
        <card>
          <div class="row">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span style="font-size: xx-small" class="d-block d-sm-none">
                    {{ option.name }}
                  </span>
                </label>
              </div>
            </div>
            <div class="col text-right">
              <el-dropdown>
                <base-button
                  simple
                  type="primary"
                  link
                > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item" v-if="giftCardType.active" @click="activateGiftCardTypePrompt($event, 0)" href="#">
                      <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate gift card type
                    </a>
                    <a class="dropdown-item" v-if="!giftCardType.active" @click="activateGiftCardTypePrompt($event, 1)" href="#">
                      <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate gift card type
                    </a>
                    <hr>
                    <a class="dropdown-item mt-2 mb-2" @click="archiveGiftCardTypePrompt(giftCardType, $event)" href="#">
                      <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Archive gift card type
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>

          <div v-if="activeTabIndex === 0">
            <div class="row mt-3">
              <div class="col">
                <base-input :error="errs.name"
                            label="Customer facing name" v-model="giftCardType.name" placeholder="Gift card type name"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-input :error="errs.name"
                            label="Internal label" v-model="giftCardType.internal_label" placeholder="Internal label"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input label="Description">
                  <el-input
                    v-model="giftCardType.description"
                    :rows="5"
                    type="textarea"
                    placeholder="Description"
                  />
                </base-input>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <base-input label="Value type">
                  <el-select
                    v-model="giftCardType.value_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Select option">
                    <el-option
                      v-for="(tp, index) in type_options"
                      class="select-primary"
                      :label="tp.name"
                      :value="{name: tp.name, id: tp.id}"
                      :key="'TP' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row" v-if="giftCardType.value_type.id === 0">
              <div class="col">
                <base-input :error="errs.min_purchase_value" :label="'Minimum load amount (' + currency_symbol + ')'"
                            v-model.number="giftCardType.min_purchase_value"
                            placeholder="Minimum load amount"></base-input>
              </div>
              <div class="col">
                <base-input  :error="errs.max_purchase_value" :label="'Maximum load amount (' + currency_symbol + ')'"
                            v-model.number="giftCardType.max_purchase_value"
                            placeholder="Maximum load amount"></base-input>
              </div>
            </div>
            <div class="row" v-if="giftCardType.value_type.id === 1">
              <div class="col">
                <base-input  :error="errs.redemption_amount" :label="'Redemption amount (' + currency_symbol + ')'"
                            v-model.number="giftCardType.redemption_amount"
                            placeholder="Redemption amount"></base-input>
              </div>
              <div class="col">
                <base-input :error="errs.price" :label="'Price (' + currency_symbol + ')'"
                            v-model.number="giftCardType.price"
                            placeholder="Price"></base-input>
              </div>
            </div>
            <div class="row" v-if="giftCardType.value_type.id === 2">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.include_taxes_and_fees"  />
                <label> &nbsp; Include taxes and fees in the calculated value</label>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.validate_emails"  />
                <label>
                  <div>&nbsp; Validate redemption email
                    <el-popover trigger="hover"
                                placement="top">

                      <div>
                        <div class="popover-body">This ensures that the gift card recipient email is the only one able to use the gift card</div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.allow_bulk_purchase"  />
                <label> &nbsp; Allow multiple gift cards per purchase</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.allow_customer_set_delivery_date"  />
                <label> &nbsp; Allow customer to choose delivery date</label>
              </div>
            </div>
            <div class="row mt-2" v-if="!giftCardType.allow_customer_set_delivery_date">
              <div class="col">
                <base-input label="Deliver gift cards" :key="j">
                  <el-select v-on:change="j++"
                    v-model="giftCardType.delivery_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Select option">
                    <el-option
                      v-for="(d, index) in delivery_options"
                      class="select-primary"
                      :label="d.name"
                      :value="{name: d.name, id: d.id}"
                      :key="'DT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

<!--            Delivery time after purchase-->
            <label v-if="!giftCardType.allow_customer_set_delivery_date && giftCardType.delivery_type.id === 1">
              Deliver gift cards after
            </label>
            <div class="row mt-2" v-if="!giftCardType.allow_customer_set_delivery_date && giftCardType.delivery_type.id === 1">
              <div class="col-lg-4">
                <base-input :error="errs.delivery_value" placeholder="Days" v-model="giftCardType.delivery_value">
                </base-input>
              </div>
              <div class="col">
                <base-input :key="j">
                  <el-select v-on:change="j++"
                             v-model="giftCardType.delivery_period"
                             class="select-primary"
                             value-key="id"
                             placeholder="Select option">
                    <el-option
                      v-for="(p, index) in periods"
                      class="select-primary"
                      :label="p.name"
                      :value="{name: p.name, id: p.id}"
                      :key="'DT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
  <!--            Delivery time on date-->
            <div class="row" v-if="!giftCardType.allow_customer_set_delivery_date && giftCardType.delivery_type.id === 2">
              <div class="col">
                <base-input label="Delivery date" :error="errs.delivery_date">
                  <el-date-picker
                    type="date"
                    placeholder="Select date"
                    :format="day_of_week + date_format_cal"
                    v-model="giftCardType.delivery_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.has_expiration_date"  />
                <label> &nbsp; Allow gift cards to expire</label>
              </div>
            </div>
            <div class="row mt-2" v-if="giftCardType.has_expiration_date">
              <div class="col">
                <base-input label="Gift cards expire" :key="j">
                  <el-select v-on:change="j++"
                             v-model="giftCardType.expiration_type"
                             class="select-primary"
                             value-key="id"
                             placeholder="Select option">
                    <el-option
                      v-for="(d, index) in time_options"
                      class="select-primary"
                      :label="d.name"
                      :value="{name: d.name, id: d.id}"
                      :key="'DT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <!--            Expiration time on period-->
            <div v-if="giftCardType.has_expiration_date && giftCardType.expiration_type.id === 1">
              <label>
                Gift cards expire after
              </label>
              <div class="row mt-2">
                <div class="col-lg-4">
                  <base-input :error="errs.expiration_value" placeholder="Days" v-model="giftCardType.expiration_value">
                  </base-input>
                </div>
                <div class="col">
                  <base-input :key="j">
                    <el-select v-on:change="j++"
                               v-model="giftCardType.expiration_period"
                               class="select-primary"
                               value-key="id"
                               placeholder="Select option">
                      <el-option
                        v-for="(p, index) in periods"
                        class="select-primary"
                        :label="p.name"
                        :value="{name: p.name, id: p.id}"
                        :key="'XT' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>

            <!--            Expiration time on date-->
            <div class="row" v-if="giftCardType.has_expiration_date && giftCardType.expiration_type.id === 2">
              <div class="col">
                <base-input label="Expiration date" :error="errs.expiration_date">
                  <el-date-picker
                    type="date"
                    placeholder="Select date"
                    :format="day_of_week + date_format_cal"
                    v-model="giftCardType.expiration_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.activate_immediately"  />
                <label> &nbsp; Activate gift cards immediately after purchase</label>
              </div>
            </div>

            <div class="row mt-2" v-if="!giftCardType.activate_immediately">
              <div class="col">
                <base-input label="Activate gift cards">
                  <el-select
                    v-model="giftCardType.activation_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Select option">
                    <el-option
                      v-for="(t, index) in time_options"
                      class="select-primary"
                      :label="t.name"
                      :value="{name: t.name, id: t.id}"
                      :key="'CT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <!--            Activation time on period-->
            <div v-if="!giftCardType.activate_immediately && giftCardType.activation_type.id === 1">
              <label>
                Gift cards are activated after
              </label>
              <div class="row mt-2">
                <div class="col-lg-4">
                  <base-input :error="errs.activation_value" placeholder="Days" v-model="giftCardType.activation_value">
                  </base-input>
                </div>
                <div class="col">
                  <base-input :key="j">
                    <el-select v-on:change="j++"
                               v-model="giftCardType.activation_period"
                               class="select-primary"
                               value-key="id"
                               placeholder="Select option">
                      <el-option
                        v-for="(p, index) in periods"
                        class="select-primary"
                        :label="p.name"
                        :value="{name: p.name, id: p.id}"
                        :key="'DT' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>

            <!--            Delivery time on date-->
            <div class="row" v-if="!giftCardType.activate_immediately && giftCardType.activation_type.id === 2">
              <div class="col">
                <base-input label="Activation date" :error="errs.activation_date">
                  <el-date-picker
                    type="date"
                    placeholder="Select date"
                    :format="day_of_week + date_format_cal"
                    v-model="giftCardType.activation_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>

          </div>

<!--          Policies-->
          <div v-if="activeTabIndex === 1">
            <div class="row mt-3">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.include_policy_section"  />
                <label> &nbsp; Include a policy section</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.require_terms"  />
                <label> &nbsp; Require user to accept terms</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="giftCardType.include_tos_link"  />
                <label> &nbsp; Include an external terms of service link</label>
              </div>
            </div>
            <div class="row mt-2" v-if="giftCardType.include_policy_section">
              <div class="col">
                <base-input :error="errs.policy_title"
                            label="Policy section title"
                            v-model="giftCardType.policy_section_title"
                            placeholder="Redemption policy"></base-input>
              </div>
            </div>

            <div class="row" v-if="giftCardType.include_policy_section">
              <div class="col">
                <editor
                        v-model="giftCardType.redemption_policy"
                        api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                        :init="{
                     height: 200,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent ',

                   }"
                />
              </div>
            </div>

            <div class="row mt-3" v-if="giftCardType.require_terms">
              <div class="col">
                <base-input label="Terms of service custom text" :error="errs.tos_text" >
                  <el-input v-model="giftCardType.tos_text" placeholder="...">
                    <template #prepend>I agree to</template>
                  </el-input>
                </base-input>
              </div>
            </div>

            <div class="row mt-2" v-if="giftCardType.include_tos_link && giftCardType.require_terms">
              <div class="col">
                <base-input :error="errs.tos_link"
                            label="External terms of service link" v-model="giftCardType.external_tos_link" placeholder="http://example.com">
                </base-input>
              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col">
              <base-button type="secondary" @click="$router.back()">Cancel</base-button>
              <base-button v-if="!$route.query.hasOwnProperty('id')" class="ml-2" type="primary"
                           :disabled="loading" @click="createGiftCardType()">Create</base-button>
              <base-button v-else class="ml-2" type="primary"
                           :disabled="loading" @click="updateGiftCardType()">Update</base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col">
        <card>
          <div class="row">
            <div class="col">
              <h4 class="card-title">Designs</h4>
            </div>
            <div class="col text-right">
<!--              <base-button type="primary" size="sm" v-on:click="newDesign()">-->
<!--                <i class="tim-icons el-icon-plus"></i>Add design-->
<!--              </base-button>-->
              <image-editor size="sm" text="Add design" @saveImage="saveImage"></image-editor>
            </div>
          </div>

          <div class="mt-3" v-if="designs.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No gift card designs added.</h4>
            </div>
          </div>
          <label style="font-size: small" v-if="designs.length > 0">
            Drag and drop to arrange designs in the order that you prefer your customers to see them.
          </label>
          <div class="gift-cards-wrapper">
            <draggable v-model="designs" :options="dragOptions" class="draggable-container">
              <div v-for="(card, i) in designs" :key="i + 'L'" class="gift-card-item">
                <div class="gift-card-container"  :style="{'background-color': card.background_color}">
                  <button class="delete-button" @click="deleteDesign(i)">✕</button>
                  <img :style="{'transform': 'translate(0%, 0%) scale(' + card.scale*0.01 + ')',
                  'left': card.x + 'px', 'top': card.y + 'px', 'object-fit':  card.object_fit, 'height': '100%'}"
                       style="cursor: all-scroll;" :src="card.url" alt="Gift Card">
                </div>
              </div>
            </draggable>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {Dropdown, DropdownItem, DropdownMenu, Switch} from "element-ui";
import _ from "lodash";
import Editor from '@tinymce/tinymce-vue'
import ImageEditor from "@/components/ImageEditor";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import draggable from 'vuedraggable'
import currencies from "@/assets/currencies.json";
import moment from 'moment'

export default {
  name: "GiftCardType",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    [Switch.name]: Switch,
    Editor,
    ImageEditor,
    draggable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  computed: {
    categories() {
      return [{name: 'Settings', icon: 'tim-icons icon-gear', active: true},
        {name: 'Policies', icon: 'tim-icons icon-notes', active: false}
      ];
    },
  },
  data() {
    return {
      loading: false,
      currencies: currencies.currencies,
      day_of_week: '',
      date_format_cal: '',
      currency_symbol: '$',
      errs: {
        name: '',
        min_purchase_value: '',
        max_purchase_value: '',
        redemption_amount: '',
        price: '',
        delivery_date: '',
        expiration_date: '',
        activation_date: '',
        delivery_value: '',
        expiration_value: '',
        activation_value: '',
        tos_link: '',
        tos_text: '',
        policy_title: ''
      },
      giftCardType: {
        name: '',
        description: '',
        validate_emails: false,
        has_expiration_date: false,
        allow_bulk_purchase: false,
        expiration_type:  {id: 1},
        expiration_value: 1,
        expiration_period: {id: 2},
        expiration_date: '',
        activate_immediately: true,
        activation_type: {id: 1},
        activation_date: '',
        activation_value: 1,
        activation_period: {id: 2},
        value_type: {id: 0},
        min_purchase_value: 20,
        max_purchase_value: 2000,
        redemption_amount: 20,
        price: 20,
        include_taxes_and_fees: false,
        allow_customer_set_delivery_date: true,
        delivery_date: '',
        delivery_type: {id: 0},
        delivery_value: 1,
        delivery_period: {id: 2},
        include_policy_section: false,
        require_terms: false,
        include_tos_link: false,
        policy_section_title: '',
        redemption_policy: '',
        policy_url: '',
        external_tos_link: '',
        tos_text: '',
        internal_label: ''
      },
      gift_card_type_name: '',
      j: 0,
      activeTabIndex: 0,
      time_options: [
        {name: 'On a specific date', id: 2},
        {name: 'At a time after purchase', id: 1}
      ],
      delivery_options: [
        {name: 'Immediately after purchase', id: 0},
        {name: 'At a time after purchase', id: 1},
        {name: 'On a specific date', id: 2}
      ],
      type_options: [
        {name: 'Arbitrary value', id: 0},
        {name: 'Fixed value', id: 1},
        {name: 'Game value', id: 2}
      ],
      designs: [],
      periods: [
        {id: 2, name: 'day(s)'},
        {id: 3, name: 'week(s)'},
        {id: 4, name: 'month(s)'},
        {id: 5, name: 'years(s)'}
      ],
      dragOptions: {
        animation: 200,
        axis: 'x', // Restrict dragging to the horizontal axis
      },
    }
  },
  methods: {
    archiveGiftCardTypePrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        text: `The gift card type will be permanently archive and will no longer be available on the booking site`,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.archiveGiftCardType(item)
        }
      })
    },
    async archiveGiftCardType(item){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types/' + item.id
      this.loading = true
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'Gift card type has been archived', 'success')
            .then((response)=> {
              this.$router.push('/purchases/gift-cards?tab=1')
            })

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    ensureTextIsBlack(input) {
      // Replace newlines with <br> to make it valid HTML
      const safeHTML = input.replace(/\n/g, '<br>');

      // Parse the string into an HTML document
      const parser = new DOMParser();
      const doc = parser.parseFromString(safeHTML, 'text/html');

      const allElements = doc.body.querySelectorAll('*');
      allElements.forEach((element) => {
        const currentStyle = element.getAttribute('style') || '';
        if (!currentStyle.includes('color:')) {
          // If no color is defined, add color: black
          element.setAttribute('style', `${currentStyle} color: black;`.trim());
        }
      });

      // Ensure plain text directly in the body also has black color
      if (doc.body.childNodes.length > 0) {
        doc.body.childNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
            const span = doc.createElement('span');
            span.style.color = 'black';
            span.textContent = node.textContent;
            node.replaceWith(span);
          }
        });
      }

      // Serialize back to a string
      return doc.body.innerHTML;
    },
    deleteDesign(index){
      this.designs.splice(index, 1)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async createGiftCardType(){
      if(!this.validateSettings()){
        return
      }
      if(this.designs.length <=0){
        swal('Error', 'You must have at least one gift card design', 'error')
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types'

      let data = { ...this.giftCardType }
      data.expiration_type = data.expiration_type.id
      data.activation_type = data.activation_type.id
      data.delivery_type = data.delivery_type.id
      data.delivery_date = this.giftCardType.delivery_date ?
        moment(this.giftCardType.delivery_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.expiration_date = this.giftCardType.expiration_date ?
        moment(this.giftCardType.expiration_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.activation_date = this.giftCardType.activation_date ?
        moment(this.giftCardType.activation_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.activation_period = data.activation_period.id
      data.expiration_period = data.expiration_period.id
      data.delivery_period = data.delivery_period.id
      data.value_type = data.value_type.id
      data.designs = this.designs

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success', 'Gift card type has been created', 'success')
            .then((response)=> {
              this.$router.push('/purchases/gift-cards?tab=1')
            })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: err.response.data,
              type: 'error',
            }).then((response)=> {
              this.$router.push('/purchases/gift-cards?tab=1')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    activateGiftCardTypePrompt(event, action){
      event.preventDefault()
      let action_type = 'activated and will immediately appear on the booking site'
      if(action === 0){
        action_type = 'permanently deactivated and will no longer be available on the booking site'
      }
      swal({
        title: 'Are you sure?',
        text: `The gift card type will be ` + action_type,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.activateGiftCardType(action, action_type)
        }
      })
    },
    async activateGiftCardType(action, action_type){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' +
        companyGroupId + '/gift-card-types/' + this.giftCardType.id + '/activate'

      let data = {
        action: action
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.getGiftCardType(this.giftCardType.id)
          swal('Success', 'Gift card type has been ' + action_type, 'success')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: err.response.data,
              type: 'error',
            }).then((response)=> {
              this.$router.push('/purchases/gift-cards?tab=1')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    validateSettings(){
      let errors = 0
      if(this.giftCardType.name === ''){
        this.errs.name = 'Name cannot be empty'
        errors++
      } else {
        this.errs.name = ''
      }

      if(this.giftCardType.value_type.id === 0 && (this.giftCardType.min_purchase_value === '' || isNaN(this.giftCardType.min_purchase_value)
        || this.giftCardType.min_purchase_value < 0)){
        this.errs.min_purchase_value = 'Invalid value'
        errors++
      } else {
        this.errs.min_purchase_value = ''
      }
      if(this.giftCardType.value_type.id === 0 && (this.giftCardType.max_purchase_value === '' ||
        isNaN(this.giftCardType.max_purchase_value) || this.giftCardType.max_purchase_value < 0)){
        this.errs.max_purchase_value = 'Invalid value'
        errors++
      } else {
        this.errs.max_purchase_value = ''
      }
      if(this.giftCardType.value_type.id === 1 &&
        (this.giftCardType.redemption_amount === '' ||
          isNaN(this.giftCardType.redemption_amount) ||
          this.giftCardType.redemption_amount < 0)){
        this.errs.redemption_amount = 'Invalid value'
        errors++
      } else {
        this.errs.redemption_amount = ''
      }

      if(this.giftCardType.value_type.id === 1 &&
        (this.giftCardType.price === '' || isNaN(this.giftCardType.price) || this.giftCardType.price < 0)){
        this.errs.price = 'Invalid value'
        errors++
      } else {
        this.errs.price = ''
      }

      if(this.giftCardType.allow_customer_set_delivery_date === false &&
        this.giftCardType.delivery_type.id === 1 && (this.giftCardType.delivery_value === '' ||
          isNaN(this.giftCardType.delivery_value) || this.giftCardType.delivery_value < 0)){
        this.errs.delivery_value = 'Invalid value'
        errors++
      } else {
        this.errs.delivery_value = ''
      }

      if(this.giftCardType.allow_customer_set_delivery_date === false &&
        this.giftCardType.delivery_type.id === 2 && (this.giftCardType.delivery_date === null)){
        this.errs.delivery_date = 'Invalid date'
        errors++
      } else {
        this.errs.delivery_date = ''
      }

      if(this.giftCardType.has_expiration_date === true &&
        this.giftCardType.expiration_type.id === 1 && (this.giftCardType.expiration_value === '' ||
          isNaN(this.giftCardType.expiration_value) || this.giftCardType.expiration_value < 0)){
        this.errs.expiration_value = 'Invalid value'
        errors++
      } else {
        this.errs.expiration_value = ''
      }

      if(this.giftCardType.has_expiration_date === true &&
        this.giftCardType.expiration_type.id === 2 && (this.giftCardType.expiration_date === null)){
        this.errs.expiration_date = 'Invalid date'
        errors++
      } else {
        this.errs.expiration_date = ''
      }

      if(this.giftCardType.activate_immediately === false &&
        this.giftCardType.activation_type.id === 1 && (this.giftCardType.activation_value === '' ||
          isNaN(this.giftCardType.activation_value) || this.giftCardType.activation_value < 0)){
        this.errs.activation_value = 'Invalid value'
        errors++
      } else {
        this.errs.activation_value = ''
      }

      if(this.giftCardType.activate_immediately === false &&
        this.giftCardType.activation_type.id === 2 && (this.giftCardType.activation_date === null)){
        this.errs.activation_date = 'Invalid date'
        errors++
      } else {
        this.errs.activation_date = ''
      }

      if(this.giftCardType.include_policy_section === true &&
        this.giftCardType.policy_section_title === ''){
        this.errs.policy_title = 'Policy title cannot be empty'
        errors++
      } else {
        this.errs.policy_title = ''
      }

      if(this.giftCardType.require_terms === true &&
        this.giftCardType.tos_text === ''){
        this.errs.tos_text = 'Text cannot be empty'
        errors++
      } else {
        this.errs.tos_text = ''
      }

      if(this.giftCardType.include_tos_link === true &&
        this.giftCardType.external_tos_link === ''){
        this.errs.tos_link = 'Link cannot be empty'
        errors++
      } else {
        this.errs.tos_link = ''
      }

      return errors > 0 ? false : true
    },
    async updateGiftCardType(){
      if(!this.validateSettings()){
        return
      }
      if(this.designs.length <=0){
        swal('Error', 'You must have at least one gift card design', 'error')
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types/' + this.giftCardType.id

      let data = { ...this.giftCardType }
      data.expiration_type = data.expiration_type.id
      data.activation_type = data.activation_type.id
      data.delivery_type = data.delivery_type.id
      data.delivery_date = this.giftCardType.delivery_date ?
        moment(this.giftCardType.delivery_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.expiration_date = this.giftCardType.expiration_date ?
        moment(this.giftCardType.expiration_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.activation_date = this.giftCardType.activation_date ?
        moment(this.giftCardType.activation_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      data.activation_period = data.activation_period.id
      data.expiration_period = data.expiration_period.id
      data.delivery_period = data.delivery_period.id
      data.value_type = data.value_type.id
      data.designs = this.designs
      // data.redemption_policy = this.ensureTextIsBlack(data.redemption_policy)

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.getGiftCardType(this.giftCardType.id)
          swal('Success', 'Gift card type has been updated', 'success')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: err.response.data,
              type: 'error',
            }).then((response)=> {
              this.getGiftCardType(this.giftCardType.id)
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async saveImage(value){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images'

      let data = {
        url: value.url,
        scale: value.scale,
        description: value.description,
        background_color: value.background_color,
        x: value.x,
        y: value.y,
        is_primary: 0,
        object_fit: value.object_fit
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          data.image_id = response.data
          this.designs.push(data)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    async getGiftCardType(id){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types/' + id
      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.giftCardType = response.data.gift_card_type
          this.gift_card_type_name = this.giftCardType.name
          this.giftCardType.value_type = _.find(this.type_options, {id: this.giftCardType.value_type})
          this.giftCardType.delivery_type = _.find(this.delivery_options, {id: this.giftCardType.delivery_type})
          this.giftCardType.delivery_period = _.find(this.periods, {id: this.giftCardType.delivery_period})
          this.giftCardType.expiration_type = _.find(this.time_options, {id: this.giftCardType.expiration_type}) ?
            _.find(this.time_options, {id: this.giftCardType.expiration_type}) : {id: 1}
          this.giftCardType.expiration_period = _.find(this.periods, {id: this.giftCardType.expiration_period})
          this.giftCardType.activation_type = _.find(this.time_options, {id: this.giftCardType.activation_type}) ?
            _.find(this.time_options, {id: this.giftCardType.activation_type}) : {id: 1}
          this.giftCardType.activation_period = _.find(this.periods, {id: this.giftCardType.activation_period})
          this.giftCardType.validate_emails = Boolean(this.giftCardType.validate_emails)
          this.giftCardType.allow_bulk_purchase = Boolean(this.giftCardType.allow_bulk_purchase)
          this.giftCardType.allow_customer_set_delivery_date = Boolean(this.giftCardType.allow_customer_set_delivery_date)
          this.giftCardType.has_expiration_date = Boolean(this.giftCardType.has_expiration_date)
          this.giftCardType.activate_immediately = Boolean(this.giftCardType.activate_immediately)
          this.giftCardType.include_taxes_and_fees = Boolean(this.giftCardType.include_taxes_and_fees)
          this.giftCardType.include_policy_section = Boolean(this.giftCardType.include_policy_section)
          this.giftCardType.require_terms = Boolean(this.giftCardType.require_terms)
          this.giftCardType.include_tos_link = Boolean(this.giftCardType.include_tos_link)
          this.giftCardType.expiration_date = this.giftCardType.expiration_date ? moment(this.giftCardType.expiration_date, 'YYYY-MM-DD') : null
          this.giftCardType.delivery_date = this.giftCardType.delivery_date ? moment(this.giftCardType.delivery_date, 'YYYY-MM-DD') : null
          this.giftCardType.activation_date = this.giftCardType.activation_date ? moment(this.giftCardType.activation_date, 'YYYY-MM-DD') : null
          this.designs = response.data.designs
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted(){
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.date_format_cal = JSON.parse(localStorage.getItem('group')).date_format
    this.date_format_cal = JSON.parse(localStorage.getItem('group')).date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if(this.$route.query.hasOwnProperty('id')){
      this.getGiftCardType(this.$route.query.id)
    }
  }
}
</script>

<style scoped>
.gift-cards-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  gap: 20px; /* Space between cards */
  padding: 10px; /* Padding around the container */
}

.draggable-container {
  display: flex;
  flex-wrap: wrap; /* Ensure draggable items also wrap */
  gap: 20px; /* Spacing between items */
}

.gift-card-item {
  flex: 0 0 auto; /* Keep items fixed size */
  position: relative; /* Ensure the delete button is positioned relative to the container */
}

.gift-card-container {
  width: 300px; /* Card width */
  height: 200px; /* Card height */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensure content fits within rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Shadow for depth */
  background: #fff;
  position: relative; /* Allows positioning of the delete button */
}

.gift-card-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image scales properly */
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgb(0, 0, 0); /* Semi-transparent red background */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10; /* Ensure it appears above the image */
  transition: background 0.2s ease;
}

.delete-button:hover {
  background: rgb(31, 221, 255); /* Solid red on hover */
}
</style>
