<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--   No data panel -->
    <div v-if="empty==true" class="mt-3" >
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">No data available.</h4>
      </div>
    </div>
<!--    <base-alert type="info">-->
<!--      <p>-->
<!--        Optionally apply a maximum of one payment gateway, to accept online card payments on your booking site, and take payments in your admin.-->
<!--      </p>-->
<!--      Please note: Your booking site will be offline until you activate at least one payment option (payment on arrival, invoice, paypal or a payment gateway)-->
<!--    </base-alert>-->
<!--    Payment Gateways-->
    <div class="row mt-3" v-if="!gateway_configured && payment_gateways.length >0">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12" v-for="(p, i) in payment_gateways" :key="'A' + i">
        <card class="card-white">
          <div class="row justify-content-center">
            <img :src="p.img" style="object-fit: scale-down" height="60"/>
          </div>
          <div class="row mt-3 justify-content-center">
            {{p.description}}
          </div>
          <div class="row mt-3 justify-content-center">
            <base-button class="button-wide" v-on:click="goToIntegrationSetup(p)" type="info" >Set up integration</base-button>
          </div>
        </card>
      </div>
    </div>

    <div class="row mt-3" v-if="gateway_configured">
      <div class="col">
        <card card-body-classes="table-full-width" style="word-break: break-word">
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>

<!-- Taxes-->
          <div v-if="activeTabIndex === 1">
            <div class="row">
              <div class="col">
                <base-button v-if="!create_new_tax" size="sm" v-on:click="create_new_tax = true" type="primary">
                  <i class="tim-icons icon-simple-add mr-2"></i>
                  Create new tax</base-button>
              </div>
            </div>

            <div class="mt-3" v-if="taxes.length <= 0 && !create_new_tax">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No taxes have been created.</h4>
              </div>
            </div>
            <div class="row" v-if="create_new_tax">
              <div class="col">
                <base-input required :error="errs.tax_title" label="Title" type="text" placeholder="Example - Sales tax" v-model="tax_title"></base-input>
              </div>
              <div class="col">
                <base-input label="Tax number" type="text" placeholder="123456" v-model="tax_number"></base-input>
              </div>
              <div class="col">
                <base-input required label="Pricing type" :error="errs.pricing_type">
                  <el-select
                    v-model="pricing_type"
                    class="select-primary"
                    name="game"
                    placeholder="Pricing type"
                    value-key="id"
                  >
                    <el-option
                      v-for="option in pricingTypes"
                      class="select-primary"
                      :label="option.name"
                      :value="{id: option.id, name: option.name}"
                      :key="'A' + option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col">
                <base-input required label="Value type" :error="errs.value_type">
                  <el-select
                    v-model="value_type"
                    class="select-primary"
                    v-on:change="changeTaxAmount(value_type)"
                    name="game"
                    placeholder="Pricing type"
                    value-key="id"
                  >
                    <el-option
                      v-for="option in valueTypesTaxes"
                      class="select-primary"
                      :label="option.name"
                      :value="{id: option.id, name: option.name}"
                      :key="'B' + option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col">
                <base-input required :error="errs.tax_amount" :label="tax_amount_label" type="number" placeholder="0.00" v-model="tax_amount"></base-input>
              </div>
            </div>
            <div class="row" v-if="create_new_tax">
              <div class="col">
                <base-switch  v-model="is_compound_tax">
                  <i class="tim-icons icon-check-2" slot="on"></i>
                  <i class="tim-icons icon-simple-remove" slot="off"></i>
                </base-switch>
                <small class="text-white"> &nbsp; This is a compound tax (calculated on top of the subtotal and any other tax totals)</small>
              </div>
            </div>
            <div class="row" v-if="create_new_tax">
              <div class="col">
                <base-switch  v-model="optional_tax">
                  <i class="tim-icons icon-check-2" slot="on"></i>
                  <i class="tim-icons icon-simple-remove" slot="off"></i>
                </base-switch>
                <small class="text-white"> &nbsp; This tax is optional (will not be automatically applied to transactions)</small>
              </div>
            </div>
            <div class="row" v-if="create_new_tax">
              <div class="col">
                <base-switch  v-model="apply_after_discount">
                  <i class="tim-icons icon-check-2" slot="on"></i>
                  <i class="tim-icons icon-simple-remove" slot="off"></i>
                </base-switch>
                <small class="text-white"> &nbsp; Apply tax after promotions and discounts</small>
              </div>
            </div>
            <div class="row" v-if="create_new_tax">
              <div class="col">
                <base-button size="sm" simple type="primary" v-on:click="cancelTaxCreation()">Cancel</base-button>
                <base-button size="sm" v-on:click="createTax()" class="ml-2" type="primary">Save</base-button>
              </div>
            </div>

            <div class="row" v-if="!create_new_tax">
<!--              Desktop-->
              <div class="col d-none d-sm-block">
                <b-table v-if="taxes.length > 0" :items="taxes" :fields="['title', 'pricing_type', 'value_type', 'amount', 'compound', 'optional', 'actions']">
                  <template v-slot:cell(title)="row">
                    <p class="text-white">{{ row.item.name }}</p>
                    <small v-if="row.item.tax_number">#{{row.item.tax_number}}</small>
                  </template>
                  <template v-slot:cell(pricing_type)="row">
                    <p class="text-white">{{ getPricingType(row.item.pricing_type) }}</p>
                  </template>
                  <template v-slot:cell(value_type)="row">
                    <p class="text-white">{{ getValueType(row.item.value_type) }}</p>
                  </template>
                  <template v-slot:cell(amount)="row">
                    <p class="text-white">{{ getAmount(row.item.value_type, row.item.amount) }}</p>
                  </template>
                  <template v-slot:cell(compound)="row">
                    <i v-if="row.item.is_compound" class="text-success tim-icons icon-check-2"></i>
                    <i v-if="!row.item.is_compound" class="text-danger tim-icons icon-simple-remove"></i>
                  </template>
                  <template v-slot:cell(optional)="row">
                    <i v-if="row.item.optional" class="text-success tim-icons icon-check-2"></i>
                    <i v-if="!row.item.optional" class="text-danger tim-icons icon-simple-remove"></i>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-tooltip v-if="row.item.is_active"
                                content="Deactivate tax"
                                effect="light"
                                :open-delay="300"
                                placement="top"
                    >
                      <base-button
                        @click="toggleActivationPrompt(row.item)"
                        icon
                        class="like btn-link"
                        size="sm"
                      ><span class="active-dot"></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip v-if="!row.item.is_active"
                                content="Activate tax"
                                effect="light"
                                :open-delay="300"
                                placement="top"
                    >
                      <base-button
                        @click="toggleActivationPrompt(row.item)"
                        icon
                        class="like btn-link"
                        size="sm"
                      ><span class="inactive-dot"></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit tax"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="editTaxPrompt(row.item)"
                        icon
                        class="like btn-link"
                        type="warning"
                        size="sm"
                      ><i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Delete tax"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="deleteTax(row.item.id)"
                        icon
                        class="like btn-link"
                        type="danger"
                        size="sm"
                      ><i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </b-table>
              </div>

<!--              Mobile-->
              <div class="col d-block d-sm-none">
                <b-table v-if="taxes.length > 0" :items="taxes" :fields="['taxes']">
                  <template v-slot:cell(taxes)="row">
                    <div class="row">
                      <div class="col">
                        <h4 class="card-title">{{ row.item.name }}</h4>
                      </div>
                      <div class="col text-right">
                        <badge type="success" class="ml-2" v-if="row.item.is_active">active</badge>
                        <badge type="danger" class="ml-2" v-if="!row.item.is_active">deactivated</badge>
                        <badge type="warning" class="ml-2" v-if="row.item.is_compound">compound</badge>
                        <badge type="info" class="ml-2" v-if="row.item.optional">optional</badge>
                      </div>
                    </div>

                    <div>Pricing type: {{ getPricingType(row.item.pricing_type) }}</div>
                    <div>Value type: {{ getValueType(row.item.value_type) }}</div>
                    <div>Amount: {{ getAmount(row.item.value_type, row.item.amount) }}</div>
                    <div
                      class="btn-group btn-group-toggle mt-3"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%; max-width: 100%"
                    >
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button style="padding-left: 0; padding-right: 0"
                                     @click="deleteTax(row.item.id)"
                                     simple
                                     type="primary"
                                     link
                        > <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button style="padding-left: 0; padding-right: 0"
                                     @click="editTaxPrompt(row.item)"
                                     simple
                                     type="primary"
                                     link
                        > <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

<!--          Fees-->
          <div v-if="activeTabIndex === 2">
            <div class="row">
              <div class="col">
                <base-button v-if="!create_new_fee" size="sm" v-on:click="create_new_fee = true" type="primary">
                  <i class="tim-icons icon-simple-add mr-2"></i>
                  Create new fee</base-button>
              </div>
            </div>


            <div class="mt-3" v-if="fees.length <= 0 && !create_new_fee">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No fees have been created.</h4>
              </div>
            </div>

            <div class="row" v-if="create_new_fee">
              <div class="col-lg-3">
                <base-input :error="errs.fee_title" label="Title" type="text" placeholder="Example - Service fee" v-model="fee.title"></base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="Value type" :error="errs.fee_value_type">
                  <el-select
                    v-model="fee.value_type"
                    class="select-primary"
                    v-on:change="changeTaxAmount(fee.value_type)"
                    name="game"
                    placeholder="Pricing type"
                    value-key="id"
                  >
                    <el-option
                      v-for="option in valueTypesTaxes"
                      class="select-primary"
                      :label="option.name"
                      :value="{id: option.id, name: option.name}"
                      :key="'B' + option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input :error="errs.fee_value" :label="tax_amount_label" type="number" placeholder="0.00" v-model="fee.amount"></base-input>
              </div>
            </div>

            <div v-if="create_new_fee">
              <div class="row">
                <div class="col">
                  <el-switch active-color="#e14eca"
                             v-model="optional_fee"  />
                  <label> &nbsp; This fee is optional (will not be automatically applied to transactions)</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <el-switch active-color="#e14eca"
                             v-model="apply_fee_to_gift_cards"  />
                  <label> &nbsp; Apply fee to gift cards</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-button size="sm" simple type="primary" v-on:click="cancelFeeCreation()">Cancel</base-button>
                  <base-button size="sm" v-on:click="createFee()" class="ml-2" type="primary">Save</base-button>
                </div>
              </div>
            </div>


            <div class="row" v-if="!create_new_fee">
<!--              Desktop-->
              <div class="col d-none d-sm-block">
                <b-table v-if="fees.length > 0" :items="fees"
                         :fields="['title', 'value_type', 'amount', 'optional', 'applicable_to_gift_cards','actions']">
                  <template v-slot:cell(title)="row">
                    <p class="text-white">{{ row.item.name }}</p>
                  </template>
                  <template v-slot:cell(value_type)="row">
                    <p class="text-white">{{ getValueType(row.item.value_type) }}</p>
                  </template>
                  <template v-slot:cell(amount)="row">
                    <p class="text-white">{{ getAmount(row.item.value_type, row.item.amount) }}</p>
                  </template>
                  <template v-slot:cell(optional)="row">
                    <i v-if="row.item.optional" class="text-success tim-icons icon-check-2"></i>
                    <i v-if="!row.item.optional" class="text-danger tim-icons icon-simple-remove"></i>
                  </template>
                  <template v-slot:cell(applicable_to_gift_cards)="row">
                    <i v-if="row.item.apply_fee_to_gift_cards" class="text-success tim-icons icon-check-2"></i>
                    <i v-if="!row.item.apply_fee_to_gift_cards" class="text-danger tim-icons icon-simple-remove"></i>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-tooltip v-if="row.item.is_active"
                                content="Deactivate fee"
                                effect="light"
                                :open-delay="300"
                                placement="top"
                    >
                      <base-button
                        @click="toggleActivationFeePrompt(row.item)"
                        icon
                        class="like btn-link"
                        size="sm"
                      ><span class="active-dot"></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip v-if="!row.item.is_active"
                                content="Activate fee"
                                effect="light"
                                :open-delay="300"
                                placement="top"
                    >
                      <base-button
                        @click="toggleActivationFeePrompt(row.item)"
                        icon
                        class="like btn-link"
                        size="sm"
                      ><span class="inactive-dot"></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit fee"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="editFeePrompt(row.item)"
                        icon
                        class="like btn-link"
                        type="warning"
                        size="sm"
                      ><i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Delete fee"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="deleteFee(row.item.id)"
                        icon
                        class="like btn-link"
                        type="danger"
                        size="sm"
                      ><i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </b-table>
              </div>
<!--              Mobile-->
              <div class="col d-block d-sm-none">
                <b-table v-if="fees.length > 0" :items="fees" :fields="['fees']">
                  <template v-slot:cell(fees)="row">
                    <div class="row">
                      <div class="col">
                        <h4 class="card-title">{{ row.item.name }}</h4>
                      </div>
                      <div class="col text-right">
                        <badge type="success" v-if="row.item.is_active" class="ml-2">active</badge>
                        <badge type="danger" v-if="!row.item.is_active" class="ml-2">deactivated</badge>
                        <badge type="info" v-if="row.item.optional" class="ml-2">optional</badge>
                      </div>
                    </div>
                    <div>Value type: {{ getValueType(row.item.value_type) }}</div>
                    <div>Amount: {{ getAmount(row.item.value_type, row.item.amount) }}</div>
                    <div
                      class="btn-group btn-group-toggle mt-3"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%; max-width: 100%"
                    >
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button style="padding-left: 0; padding-right: 0"
                                     @click="deleteFee(row.item.id)"
                                     simple
                                     type="primary"
                                     link
                        > <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button style="padding-left: 0; padding-right: 0"
                                     @click="editFeePrompt(row.item)"
                                     simple
                                     type="primary"
                                     link
                        > <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>

          </div>
<!--          Payment methods-->
          <div v-if="activeTabIndex === 0">
<!--            <div class="row">-->
<!--              <div class="col">-->
<!--                <p class="card-title text-uppercase">Payment methods</p>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row">
              <div class="col-lg-6">
                <base-button size="sm" type="primary" v-on:click="createCustomPaymentPrompt()">
                  <i class="tim-icons icon-simple-add mr-2"></i> Add custom payment method</base-button>
              </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col">
<!--                <label>Supported payment methods</label>-->
                <div style="border: 1px solid #ffffff;
                      border-radius: 7px !important; padding-top: 15px; padding-left: 15px; padding-right: 15px;">
                  <table class='table' v-if="payment_methods.length > 0">
                    <thead>
                    <tr>
                      <th scope='col'></th>
                      <th scope='col'>Supported payment methods</th>
                      <th scope='col'>Available on internal payments</th>
                      <th scope='col'>Available on booking site</th>
                      <th scope='col'>Actions</th>
                    </tr>
                    </thead>
                    <tbody tag='tbody' v-model='payment_methods' is='draggable'>
                    <tr v-on:dragend="updatePaymentMethodOrder()" v-for='(item, index) in payment_methods' :key="'G' + index">
                      <td scope='row'>
                        <i style="cursor: move" class="tim-icons el-icon-rank"></i>
                      </td>
                      <td scope='row'>
                        <div class="row" >
                          <div class="col">
                            <p class="text-white">{{item.name}}
                              <badge v-if="item.is_default" class="ml-2">default</badge>
                            </p>
                          </div>
                        </div>
                      </td>
<!--                      <td scope='row'>-->
<!--                        <div class="row" >-->
<!--                          <div class="col">-->
<!--                            <badge type="success" v-if="item.status">active</badge>-->
<!--                            <badge type="danger" v-if="!item.status">deactivated</badge>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </td>-->
                      <td scope='row'>
                        <div class="row" >
                          <div class="col">
                            <i class="tim-icons icon-check-2 text-success" v-if="item.enabled_internally"></i>
                            <i class="tim-icons icon-simple-remove text-danger" v-if="!item.enabled_internally"></i>
                          </div>
                        </div>
                      </td>
                      <td scope='row'>
                        <div class="row" >
                          <div class="col">
                            <i class="tim-icons icon-check-2 text-success" v-if="item.enabled_externally"></i>
                            <i class="tim-icons icon-simple-remove text-danger" v-if="!item.enabled_externally"></i>
                          </div>
                        </div>
                      </td>
                      <td scope='row'>
                        <div>
                          <el-dropdown>
                            <base-button
                              link
                              type="primary"
                              size="sm"
                            > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                            </base-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a v-if="item.is_default === 0"
                                   class="dropdown-item mt-1 mb-2"  href="#" v-on:click="editPaymentMethod(item)">
                                  <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit payment method
                                </a>
                                <a v-if="item.enabled_internally === 0"
                                   class="dropdown-item mt-1 mb-2"  href="#" v-on:click="togglePaymentMethodActivation(item, 'activate_internal', 'activated on internal payments')">
                                  <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate on internal payments
                                </a>
                                <a v-if="item.enabled_internally === 1"
                                   class="dropdown-item mt-1 mb-2"  href="#" v-on:click="togglePaymentMethodActivation(item, 'deactivate_internal', 'deactivated on internal payments')">
                                  <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate on internal payments
                                </a>
                                <a v-if="item.enabled_externally === 0"
                                   class="dropdown-item mt-1 mb-2"  href="#" v-on:click="togglePaymentMethodActivation(item, 'activate_external', 'activated on booking site')">
                                  <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate on booking site
                                </a>
                                <a v-if="item.enabled_externally === 1"
                                   class="dropdown-item mt-1 mb-2"  href="#" v-on:click="togglePaymentMethodActivation(item, 'deactivate_external', 'deactivated on booking site')">
                                  <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate on booking site
                                </a>
                                <hr v-if="item.is_default === 0">
                                <a v-on:click="deletePaymentMethod(item)" v-if="item.is_default === 0" class="dropdown-item mt-1 mb-2" target="_blank">
                                  <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete payment method
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.allow_pay_on_arrival" />
                <label class="text-white"> &nbsp; Allow pay on arrival</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.allow_gift_card_purchase_on_gift_card" />
                <label class="text-white"> &nbsp; Allow gift card purchases using Gift Card payment method</label>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <p class="card-title text-uppercase">Tips</p>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.allow_tipping" />
                <label class="text-white"> &nbsp; Allow tipping</label>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3" v-if="payment_settings.allow_tipping">
                <base-input label="Tip field label" v-model="payment_settings.tip_label">
                </base-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3" v-if="payment_settings.allow_tipping">
                <base-input label="Tip line item label" v-model="payment_settings.tip_line_item_label">
                </base-input>
              </div>
            </div>
            <div class="row" v-if="payment_settings.allow_tipping">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.allow_custom_tipping_options" />
                <label class="text-white"> &nbsp; Allow arbitrary tipping values</label>
              </div>
            </div>
            <div class="row mt-2" v-if="payment_settings.allow_tipping && payment_settings.allow_custom_tipping_options">
              <div class="col-lg-3" >
                <base-input label="Value type">
                  <el-select
                    v-model="payment_settings.custom_tipping_value_type"
                    class="select-primary"
                    name="tipping_value_type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(v, index) in valueTypesTipping"
                      class="select-primary"
                      :label="v.name"
                      :value="{id: v.id, name: v.name}"
                      :key="'V' + index"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row" v-if="payment_settings.allow_tipping">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.allow_preset_tipping_options" />
                <label class="text-white"> &nbsp; Show preset tipping options</label>
              </div>
            </div>

            <div class="row mt-2" v-if="payment_settings.allow_tipping && payment_settings.allow_preset_tipping_options">
              <div class="col">
                <base-button size="sm" v-on:click="addTipPresetValue()" type="primary">
                  <i class="tim-icons icon-simple-add mr-2"> </i> Add tipping option</base-button>
              </div>
            </div>
            <div class="row mt-2" v-if="payment_settings.allow_tipping && payment_settings.allow_preset_tipping_options && tip_preset_values.length > 0">
              <div class="col-lg-6">
                <div style="border: 1px solid #ffffff;
                      border-radius: 7px !important; padding-top: 15px; padding-left: 15px; padding-right: 15px;">
                  <table class='table' v-if="payment_methods.length > 0">
                    <thead>
                    <tr>
                      <th scope='col'></th>
                      <th scope='col'>Label</th>
                      <th scope='col'>Value</th>
                      <th scope='col'>Actions</th>
                    </tr>
                    </thead>
                    <tbody tag='tbody' v-model='tip_preset_values' is='draggable'>
                    <tr  v-for='(item, index) in tip_preset_values' :key="'TPV' + index">
                      <td scope='row'>
                        <i style="cursor: move" class="tim-icons el-icon-rank"></i>
                      </td>
                      <td scope='row'>
                        <div class="row" >
                          <div class="col">
                            <p class="text-white">{{item.name}}</p>
                          </div>
                        </div>
                      </td>
                      <td scope='row'>
                        <div class="row" >
                          <div class="col">
                            {{getAmount(item.value_type.id, item.amount, 'tip')}}
                          </div>
                        </div>
                      </td>
                      <td scope='row'>
                        <div>
                          <el-dropdown>
                            <base-button
                              link
                              type="primary"
                              size="sm"
                            > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                            </base-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a class="dropdown-item" @click="editTipOption(item, index)" href="#">
                                  <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit tip option
                                </a>
                                <hr/>
                                <a class="dropdown-item" @click="deleteTipOption(item, index)" href="#">
                                  <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete tip option
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <modal :show.sync="modals.add_tip_option"
                   body-classes="p-0"
                   modal-classes="modal-dialog-centered">
              <card type="secondary"
                    header-classes="bg-white pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">

                <h4 v-if="!selectedTipOption.editing">Create a tip option</h4>
                <h4 v-if="selectedTipOption.editing">Edit tip option</h4>

                <div class="row" >
                  <div class="col">
                    <base-input :error="errs.tip_option_name" label="Label (optional)" type="text" placeholder="Value 1" v-model="selectedTipOption.name"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-input label="Value type">
                      <el-select
                        v-model="selectedTipOption.value_type"
                        class="select-primary"
                        name="game"
                        placeholder="Pricing type"
                        value-key="id"
                      >
                        <el-option
                          v-for="(option, index) in valueTypesTipping"
                          class="select-primary"
                          :label="option.name"
                          :value="{id: option.id, name: option.name}"
                          :key="'VT' + index"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col" v-if="selectedTipOption.hasOwnProperty('value_type')">
                    <label v-if="selectedTipOption.value_type.id === 0">Value (%)</label>
                    <label v-if="selectedTipOption.value_type.id === 1">Value ({{currency_symbol}}) </label>
                    <base-input :error="errs.tip_amount" placeholder="0.00" v-model.number="selectedTipOption.amount"></base-input>
                  </div>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="col text-center">
                    <base-button type="secondary" @click="cancelTipCreation()">Cancel</base-button>
                    <base-button v-if="!selectedTipOption.editing" class="ml-3" type="primary" @click="createTip()">Save</base-button>
                    <base-button v-if="selectedTipOption.editing" class="ml-3" type="primary" @click="saveTip()">Save</base-button>
                  </div>
                </div>
              </card>
            </modal>

            <div class="row mt-4">
              <div class="col">
                <p class="card-title text-uppercase">Payment requests</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.payment_request_allow_custom_amounts" />
                <label class="text-white"> &nbsp; Allow custom payment amounts</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="payment_settings.payment_request_has_timeout" />
                <label class="text-white"> &nbsp; Enable payment request expiration
                  <el-popover trigger="hover"
                              placement="right">

                    <div>
                      <div class="popover-body">This setting defines the threshold after which transactions and all <br>related bookings will be cancelled if not paid.</div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </label>
              </div>
            </div>
            <div v-if="payment_settings.payment_request_has_timeout">
              <label>Payment request timeout</label>
              <div class="row">
                <div class="col-lg-2">
                  <base-input v-model.number="payment_settings.payment_request_timeout_value"></base-input>
                </div>
                <div class="col-lg-3">
                  <base-input>
                    <el-select
                      label="Select period"
                      v-model="payment_settings.payment_request_timeout_period"
                      class="select-primary"
                      value-key="id"
                    >
                      <el-option
                        v-for="(rp, index) in request_periods"
                        class="select-primary"
                        :label="rp.label"
                        :value="{id: rp.id, label: rp.label}"
                        :key="'RPX' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <label>Expires when the following percentage of the transaction total has not been paid (%)</label>
              <div class="row">
                <div class="col-lg-2">
                  <base-input :error="errs.payment_request_threshold" v-model.number="payment_settings.payment_request_threshold"></base-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-button type="primary" @click="savePaymentRequestSettings()">Save settings</base-button>
              </div>
            </div>
          </div>

<!--          Payment gateways-->
          <div v-if="activeTabIndex === 3">
            <div class="row mb-5 mt-5">
              <div class="col-3 d-sm-block d-none">
                <p class="text-primary" style="font-size: medium">Payment gateway
                </p>
                <p v-if="payment_gateway.id === 1">Refer to Authorize.net's <a href="https://support.authorize.net/knowledgebase/Knowledgearticle/?code=000001271" target="_blank">documentation</a>
                  for instructions on where to find your API credentials.</p>
                <p v-if="payment_gateway.id === 2">Refer to Square's <a href="https://developer.squareup.com/docs/build-basics/access-tokens" target="_blank">documentation</a>
                  for instructions on where to find your API credentials.</p>
                <p v-if="payment_gateway.id === 3">Refer to Stripe's <a href="https://docs.stripe.com/keys" target="_blank">documentation</a>
                  for instructions on where to find your API credentials.</p>
                <p v-if="payment_gateway.id === 4">Refer to Paystacks's <a href="https://support.paystack.com/hc/en-us/articles/360011508199-How-do-I-generate-new-API-keys" target="_blank">documentation</a>
                  for instructions on where to find your API credentials.</p>
                <p v-if="payment_gateway.id === 5">Refer to Yappy's <a href="https://www.bgeneral.com/desarrolladores/boton-de-pago-yappy/activacion-del-boton-de-pago-yappy/" target="_blank">documentation</a>
                  for instructions on where to find your API credentials.</p>
              </div>
              <div class="col d-sm-none d-block">
                <label>Payment gateway</label>
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-2">
                    <card class="card-white">
                      <div class="row justify-content-center">
                        <img style="object-fit: scale-down" height="40" :src="payment_gateway.img">
                      </div>
                    </card>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button type="primary" size="sm" v-on:click="changePaymentGateway()">Change payment gateway</base-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-5" v-if="payment_gateway.id === 3">
              <div class="col-3 d-sm-block d-none">
                <p class="text-primary" style="font-size: medium">Webhook  <badge class="ml-4" type="danger">important</badge>
                </p>
                <p v-if="payment_gateway.id === 3">Add the following webhook to your Stripe account and set it to listen to all payment_intent events.
                  For more details, please follow this <a href="https://docs.stripe.com/webhooks#add-a-webhook-endpoint" target="_blank">guide</a>.</p>
              </div>
              <div class="col d-sm-none d-block">
                <label>Webhook  <badge class="ml-4" type="danger">important</badge>
                </label>
              </div>
              <div class="col-lg-9">
                <div class="row mt-3">
                  <div class="col-lg-6">
                    <base-alert type="white" class="text-muted">
                      <div class="row">
                        <div class="col-10">
                          <code v-if="payment_gateway.id === 3"> https://api.offthecouch.io/stripe-webhook</code>
                        </div>
                        <div class="col text-right">
                          <base-button class="like btn-link" style="padding: 0; margin: -10px"
                                       type="default"
                                       size="sm"
                                       icon
                                       v-on:click="copy('https://api.offthecouch.io/stripe-webhook')">
                            <i class="tim-icons el-icon-copy-document"></i>
                          </base-button>
                        </div>
                      </div>
                    </base-alert>
                  </div>
                </div>
              </div>
            </div>

<!--            <div class="row mt-3 mb-5" v-if="payment_gateway.id === 3">-->
<!--              <div class="col-3 d-sm-block d-none">-->
<!--                <p class="text-primary" style="font-size: medium">Custom description text-->
<!--                </p>-->
<!--                <p>Define the text that will appear on the description field on your Stripe dashboard.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="col-lg-9">-->
<!--                <div class="row">-->
<!--                  <div class="col-lg-6">-->
<!--                    <label>Description-->
<!--                      <span class="text-danger">*</span>-->
<!--                    </label>-->
<!--                    <base-input v-model="credentials.custom_description"></base-input>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-lg-6">-->
<!--                    <base-button v-on:click="updateCredentials('live', 2)" simple type="success" size="sm">Save description</base-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="row">
              <div class="col-3 d-sm-block d-none">
                <p class="text-primary" style="font-size: medium">Test credentials
                  <badge class="ml-4" type="success" v-if="test_credentials.is_active">active</badge>
                  <badge class="ml-4" type="danger" v-else>inactive</badge>
                </p>
                <p>Use these credentials to execute payments in a sandbox environment. We recommend you test payments before going live.</p>
              </div>
              <div class="col d-sm-none d-block">
                <h4 class="card-list">Test credentials
                  <badge class="ml-4" type="success" v-if="test_credentials.is_active">active</badge>
                  <badge class="ml-4" type="danger" v-else>inactive</badge>
                </h4>
              </div>
              <div class="col-lg-9">
                <div v-if="!test_credentials.api_name">
                  <div class="row" v-if="!edit_test_creds">
                    <div class="col-lg-6">
                      <div class="font-icon-detail">
                    <span style="font-size: 3em">
                      <i class="fas fa-exclamation-triangle"></i>
                    </span>
                        <h4 class="mt-3">No credentials have been added.</h4>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="!edit_test_creds" :key="'C' + j">
                    <div class="col-lg-6">
                      <base-button v-on:click="editTestCreds()" type="primary" size="sm">
                        <i class="tim-icons icon-simple-add mr-1"></i> Add credentials</base-button>
                    </div>
                  </div>
                  <div class="row" v-if="edit_test_creds" :key="'A' + j">
                    <div class="col-lg-6">
                      <label>{{api_name}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.test_credentials.api_name" v-model="test_credentials.api_name_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="edit_test_creds" :key="'B' + j">
                    <div class="col-lg-6">
                      <label>{{api_key}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.test_credentials.api_key" v-model="test_credentials.api_key_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="payment_gateway.id === 2 && edit_test_creds">
                    <div class="col-lg-6">
                      <label>{{api_location}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.test_credentials.api_location" v-model="test_credentials.api_location_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="edit_test_creds">
                    <div class="col-lg-6">
                      <span >
                        <base-button class="mr-2" v-on:click="edit_test_creds = false" type="default" size="sm">Cancel</base-button>
                        <base-button  v-on:click="updateCredentials('test', 2)" type="primary" size="sm">Save</base-button>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-lg-6">
                      <label>{{api_name}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_test_creds">{{test_credentials.api_name}} </p>
                      <base-input v-else :error="err.test_credentials.api_name" v-model="test_credentials.api_name_edit"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label>{{api_key}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_test_creds">******************************</p>
                      <base-input v-else :error="err.test_credentials.api_key" v-model="test_credentials.api_key_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="payment_gateway.id === 2">
                    <div class="col-lg-6">
                      <label>{{api_location}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_test_creds">{{test_credentials.api_location}} </p>
                      <base-input v-else :error="err.test_credentials.api_location" v-model="test_credentials.api_location_edit"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                    <span v-if="edit_test_creds">
                      <base-button class="mr-2" v-on:click="edit_test_creds = false" type="default" size="sm">Cancel</base-button>
                      <base-button  v-on:click="updateCredentials('test', 2)" type="primary" size="sm">Save</base-button>
                    </span>
                      <span v-if="!edit_test_creds">
                       <base-button v-on:click="editTestCreds()" type="primary" size="sm">
                          <i class="tim-icons icon-pencil mr-1"></i> Edit</base-button>
                      <base-button class="ml-2" v-if="!test_credentials.is_active" v-on:click="updateCredentials('test', 1)" link type="primary" size="sm">Activate</base-button>
                      <base-button class="ml-2" v-if="test_credentials.is_active" v-on:click="updateCredentials('test', 0)" link type="primary" size="sm">Deactivate</base-button>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-3 d-sm-block d-none">
                <p class="text-primary" style="font-size: medium">Live credentials
                  <badge class="ml-4" type="success" v-if="live_credentials.is_active">active</badge>
                  <badge class="ml-4" type="danger" v-else>inactive</badge>
                </p>
                <p>Use these credentials to execute live payments.
                  Please ensure that you've tested your gateway before accepting real payments from your customers.
                </p>
              </div>
              <div class="col d-sm-none d-block">
                <h4 class="card-list">Live credentials
                  <badge class="ml-4" type="success" v-if="live_credentials.is_active">active</badge>
                  <badge class="ml-4" type="danger" v-else>inactive</badge>
                </h4>
              </div>
              <div class="col-lg-9">
                <div v-if="!live_credentials.api_name">
                  <div class="row" v-if="!edit_live_creds">
                    <div class="col-lg-6">
                      <div class="font-icon-detail">
                    <span style="font-size: 3em">
                      <i class="fas fa-exclamation-triangle"></i>
                    </span>
                        <h4 class="mt-3">No credentials have been added.</h4>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="!edit_live_creds">
                    <div class="col-lg-6">
                      <base-button v-on:click="editLiveCreds()" type="primary" size="sm">
                        <i class="tim-icons icon-simple-add mr-1"></i> Add credentials</base-button>
                    </div>
                  </div>
                  <div class="row" v-if="edit_live_creds" :key="'E' + j">
                    <div class="col-lg-6">
                      <label>{{api_name}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.live_credentials.api_name" v-model="live_credentials.api_name_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="edit_live_creds" :key="'F' + j">
                    <div class="col-lg-6">
                      <label>{{api_key}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.live_credentials.api_key" v-model="live_credentials.api_key_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="payment_gateway.id === 2 && edit_live_creds" :key="'D' + j">
                    <div class="col-lg-6">
                      <label>{{api_location}}
                        <span class="text-danger">*</span>
                      </label>
                      <base-input v-on:change="j++" :error="err.live_credentials.api_location" v-model="live_credentials.api_location_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="edit_live_creds">
                    <div class="col-lg-6">
                      <span >
                        <base-button class="mr-2" v-on:click="edit_live_creds = false" type="default" size="sm">Cancel</base-button>
                        <base-button  v-on:click="updateCredentials('live', 2)" type="primary" size="sm">Save</base-button>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-lg-6">
                      <label>{{api_name}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_live_creds">{{live_credentials.api_name}} </p>
                      <base-input v-else :error="err.live_credentials.api_name" v-model="live_credentials.api_name_edit"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label>{{api_key}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_live_creds">******************************</p>
                      <base-input v-else :error="err.live_credentials.api_key" v-model="live_credentials.api_key_edit"></base-input>
                    </div>
                  </div>
                  <div class="row" v-if="payment_gateway.id === 2">
                    <div class="col-lg-6">
                      <label>{{api_location}}
                        <span class="text-danger">*</span>
                      </label>
                      <p v-if="!edit_live_creds">{{live_credentials.api_location}} </p>
                      <base-input v-else :error="err.live_credentials.api_location" v-model="live_credentials.api_location_edit"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <span v-if="edit_live_creds">
                        <base-button class="mr-2" v-on:click="edit_live_creds = false" type="default" size="sm">Cancel</base-button>
                        <base-button  v-on:click="updateCredentials('live', 2)" type="primary" size="sm">Save</base-button>
                      </span>
                      <span v-if="!edit_live_creds">
                         <base-button v-on:click="editLiveCreds()" type="primary" size="sm">
                            <i class="tim-icons icon-pencil mr-1"></i> Edit</base-button>
                        <base-button class="ml-2" v-if="!live_credentials.is_active" v-on:click="updateCredentials('live', 1)" link type="primary" size="sm">Activate</base-button>
                        <base-button class="ml-2" v-if="live_credentials.is_active" v-on:click="updateCredentials('live', 0)" link type="primary" size="sm">Deactivate</base-button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="payment_gateway.id === 2" class="mt-4">
              <div class="row mb-5">
                <div class="col-3 d-sm-block d-none">
                  <p class="text-primary" style="font-size: medium">Custom fields
                  </p>
                  <p>Customize additional fields to capture on your Square payments.
                  </p>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col">
                      <el-switch active-color="#e14eca" v-on:change="saveSquareCustomFields('customer')"
                                 v-model="square_settings.create_customer"  />
                      <label> &nbsp; Automatically create a customer in Square and link customers to payments</label>
                    </div>
                  </div>
<!--                  <div class="row mt-1" v-if="square_settings.create_customer">-->
<!--                    <div class="col">-->
<!--                      <el-switch active-color="#e14eca" v-on:change="saveSquareCustomFields('payment_method')"-->
<!--                                 v-model="square_settings.store_credit_card"  />-->
<!--                      <label> &nbsp; Save payment on file</label>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="row mt-2">
                    <div class="col-lg-6">
                      <base-input
                        placeholder="Note"
                        v-model="square_settings.note" label="Custom note (max 500 characters)"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-button size="sm"
                        type="primary" v-on:click="saveSquareCustomFields()">
                        Save
                      </base-button>
                    </div>
                  </div>
                  <p class="mt-2 card-title text-primary">Supported dynamic variables
                    <i class="tim-icons icon-minimal-down ml-1" v-on:click="show_dynamic_variables = !show_dynamic_variables" style="cursor: pointer"></i>
                  </p>
                  <div v-if="show_dynamic_variables">
                    <div>
                      <code>[Customer First Name]
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="copy('[Customer First Name]')"
                            class="like btn-link text-white"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </code>
                    </div>
                    <div>
                      <code>[Customer Last Name]
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="copy('[Customer Last Name]')"
                            class="like btn-link text-white"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </code>
                    </div>
                    <div>
                      <code>[Customer Phone]
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="copy('[Customer Phone]')"
                            class="like btn-link text-white"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </code>
                    </div>
                    <div>
                      <code>[Customer Email]
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="copy('[Customer Email]')"
                            class="like btn-link text-white"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </code>
                    </div>
                    <div>
                      <code>[Transaction Id]
                        <el-tooltip content="Copy"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="copy('[Transaction Id]')"
                            class="like btn-link text-white"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </code>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div class="row mb-5">-->
<!--                <div class="col-3 d-sm-block d-none">-->
<!--                  <p class="text-primary" style="font-size: medium">Hardware integration-->
<!--                  </p>-->
<!--                  <p>Our platform supports integration with Square Terminal and Square Register,-->
<!--                    enabling seamless payment processing.-->
<!--                    You can send payment requests directly to the terminal from an existing transaction,-->
<!--                    and the transaction will automatically update upon payment completion.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="col d-sm-none d-block">-->
<!--                  <label>Terminal integration-->
<!--                  </label>-->
<!--                </div>-->
<!--                <div class="col-lg-9">-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                      <p>1. Set up a webhook in Square</p>-->
<!--                      <small class="text-white">-->
<!--                        To subscribe to notifications about events in Square,-->
<!--                        register the following webhook URL on your Square Developer Console. Choose the latest API version-->
<!--                        and be sure to subscribe to all events.-->
<!--                      </small>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="row mt-3">-->
<!--                    <div class="col-lg-6">-->
<!--                      <base-alert type="white" class="text-muted">-->
<!--                        <div class="row">-->
<!--                          <div class="col-10">-->
<!--                            <code> https://api.offthecouch.io/square-webhook</code>-->
<!--                          </div>-->
<!--                          <div class="col text-right">-->
<!--                            <base-button class="like btn-link" style="padding: 0; margin: -10px"-->
<!--                                         type="default"-->
<!--                                         size="sm"-->
<!--                                         icon-->
<!--                                         v-on:click="copy('https://api.offthecouch.io/square-webhook')">-->
<!--                              <i class="tim-icons el-icon-copy-document"></i>-->
<!--                            </base-button>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </base-alert>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                      <p>2. Add your Signature key</p>-->
<!--                      <small class="text-white">You can find the Signature key in the <b>Properties</b> section of your webhook.</small>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="row mt-2">-->
<!--                    <div class="col-lg-6">-->
<!--                      <div class="mb-2" v-if="square_settings.has_signature && !edit_signature">-->
<!--                        <label>Signature key</label>-->
<!--                        <p>*************</p>-->
<!--                        <base-button v-on:click="edit_signature = true" type="primary" size="sm">-->
<!--                          <i class="tim-icons icon-pencil mr-1"></i> Edit</base-button>-->
<!--                      </div>-->

<!--                      <div v-if="edit_signature || (!square_settings.has_signature && !edit_signature)">-->
<!--                        <label>Signature key</label>-->
<!--                        <base-input :error="errs.webhook_signature"-->
<!--                          placeholder="Signature key"-->
<!--                          v-model="square_settings.webhook_signature"></base-input>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="row mb-2" v-if="square_settings.has_signature && edit_signature">-->
<!--                    <div class="col-lg-6">-->
<!--                      <base-button  v-on:click="edit_signature = false" type="default" size="sm">-->
<!--                       Cancel</base-button>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                      <p>3. Choose your POS hardware type</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                      <base-input class="text-danger" label="Hardware type" :error="errs.hardware_type">-->
<!--                        <el-select-->
<!--                                   v-model="square_settings.hardware_type"-->
<!--                                   class="select-primary"-->
<!--                                   name="font"-->
<!--                                   placeholder="Hardware type"-->
<!--                                   value-key="id"-->
<!--                        >-->
<!--                          <el-option-->
<!--                            v-for="(h, i) in square_hardware_types" :key="'HT' + i"-->
<!--                            class="select-primary"-->
<!--                            :label="h.name"-->
<!--                            :value="{name: h.name, id: h.id}"-->
<!--                          >-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </base-input>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                      <base-button size="sm"-->
<!--                                   type="primary" v-on:click="saveSquareCustomFields('hardware')">-->
<!--                        Save-->
<!--                      </base-button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--&lt;!&ndash;                  <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                      <base-input label="Device ID" placeholder="device:995CS397A6475287"></base-input>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="row mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                      <base-button type="primary" size="sm" v-on:click="generateSquareDeviceCode()">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="tim-icons icon-refresh-02 mr-1"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                        Generate device code</base-button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <hr style="border-top: 1px solid #f4f5f7;">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="row mt-2" v-if="square_device_codes.length > 0">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div style="border: 1px solid #ffffff;&ndash;&gt;-->
<!--&lt;!&ndash;                      border-radius: 7px !important; padding-top: 15px; padding-left: 15px; padding-right: 15px;">&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-table :items="square_device_codes" :fields="['code', 'pair_by', 'status']">&ndash;&gt;-->
<!--&lt;!&ndash;                          <template v-slot:cell(code)="row">&ndash;&gt;-->
<!--&lt;!&ndash;                            {{row.item.code}}&ndash;&gt;-->
<!--&lt;!&ndash;                            <el-tooltip content="Copy"&ndash;&gt;-->
<!--&lt;!&ndash;                                        effect="light"&ndash;&gt;-->
<!--&lt;!&ndash;                                        :open-delay="300"&ndash;&gt;-->
<!--&lt;!&ndash;                                        placement="top">&ndash;&gt;-->
<!--&lt;!&ndash;                              <i class="tim-icons icon-single-copy-04 ml-2" v-on:click="copyItem(row.item.code)" style="cursor: pointer"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                            </el-tooltip>&ndash;&gt;-->
<!--&lt;!&ndash;                          </template>&ndash;&gt;-->
<!--&lt;!&ndash;                          <template v-slot:cell(pair_by)="row">&ndash;&gt;-->
<!--&lt;!&ndash;                            {{getLocalDate(row.item.pairBy)}}, {{getLocalTime(row.item.pairBy)}}&ndash;&gt;-->
<!--&lt;!&ndash;                          </template>&ndash;&gt;-->
<!--&lt;!&ndash;                          <template v-slot:cell(status)="row">&ndash;&gt;-->
<!--&lt;!&ndash;                            <badge>{{row.item.status}}</badge>&ndash;&gt;-->
<!--&lt;!&ndash;                          </template>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-table>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="row mt-3">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                      <p>3. Once paired, you can start sending payments from your <a href="/purchases/transactions?count=10&page=1">Transactions</a> to the Square Terminal.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="modals.edit_tax"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-xl">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Exit tax</h4>
        <div class="row" >
          <div class="col-lg-3">
            <base-input required :error="errs.tax_title" label="Title" type="text" placeholder="Example - Sales tax" v-model="tax_title"></base-input>
          </div>
          <div class="col">
            <base-input label="Tax number" type="text" placeholder="123456" v-model="tax_number"></base-input>
          </div>
          <div class="col">
            <base-input required label="Pricing type" :error="errs.pricing_type">
              <el-select
                v-model="pricing_type"
                class="select-primary"
                name="game"
                placeholder="Pricing type"
                value-key="id"
              >
                <el-option
                  v-for="option in pricingTypes"
                  class="select-primary"
                  :label="option.name"
                  :value="{id: option.id, name: option.name}"
                  :key="'A' + option.id"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <base-input required label="Value type" :error="errs.value_type">
              <el-select
                v-model="value_type"
                class="select-primary"
                v-on:change="changeTaxAmount(value_type)"
                name="game"
                placeholder="Pricing type"
                value-key="id"
              >
                <el-option
                  v-for="option in valueTypesTaxes"
                  class="select-primary"
                  :label="option.name"
                  :value="{id: option.id, name: option.name}"
                  :key="'B' + option.id"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <base-input required :error="errs.tax_amount" :label="tax_amount_label" type="number" placeholder="0.00" v-model="tax_amount"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-switch  v-model="is_compound_tax">
              <i class="tim-icons icon-check-2" slot="on"></i>
              <i class="tim-icons icon-simple-remove" slot="off"></i>
            </base-switch>
            <small class="text-white"> &nbsp; This is a compound tax (calculated on top of the subtotal and any other tax totals)</small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-switch  v-model="optional_tax">
              <i class="tim-icons icon-check-2" slot="on"></i>
              <i class="tim-icons icon-simple-remove" slot="off"></i>
            </base-switch>
            <small class="text-white"> &nbsp; This tax is optional (will not be automatically applied to transactions)</small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-switch  v-model="apply_after_discount">
              <i class="tim-icons icon-check-2" slot="on"></i>
              <i class="tim-icons icon-simple-remove" slot="off"></i>
            </base-switch>
            <small class="text-white"> &nbsp; Apply tax after promotions and discounts</small>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="cancelTaxCreation()">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="editTax()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="cancelTaxCreation()">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="editTax()">Save</base-button>
        </div>
      </card>
    </modal>


    <modal :show.sync="modals.edit_fee"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-xl">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Edit fee</h4>
        <div class="row" >
          <div class="col-4">
            <base-input :error="errs.fee_title" label="Title" type="text" placeholder="Example - Service fee" v-model="fee.title"></base-input>
          </div>
          <div class="col">
            <base-input label="Value type" :error="errs.fee_value_type">
              <el-select
                v-model="fee.value_type"
                class="select-primary"
                v-on:change="changeTaxAmount(fee.value_type)"
                name="game"
                placeholder="Pricing type"
                value-key="id"
              >
                <el-option
                  v-for="option in valueTypesTaxes"
                  class="select-primary"
                  :label="option.name"
                  :value="{id: option.id, name: option.name}"
                  :key="'B' + option.id"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-2">
            <base-input :error="errs.fee_amount" :label="tax_amount_label" type="number" placeholder="0.00" v-model="fee.amount"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="optional_fee"  />
            <label> &nbsp; This fee is optional (will not be automatically applied to transactions)</label>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="apply_fee_to_gift_cards"  />
            <label> &nbsp; Apply fee to gift cards</label>
          </div>
        </div>
        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="cancelFeeCreation()">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="editFee()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="cancelFeeCreation()">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="editFee()">Save</base-button>
        </div>
      </card>
    </modal>

    <modal :show.sync="modals.custom_payment"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary" :key="j + 'CPMM'"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <h4 v-if="payment_method.action === 'editing'" class="card-title">Edit custom payment</h4>
        <h4 v-else class="card-title">Create custom payment</h4>
        <div class="row">
          <div class="col-lg-12">
            <label>Payment method name
              <el-popover trigger="hover"
                          placement="right">

                <div>
                  <div class="popover-body">This is the name of the payment method as it will appear on the booking site.</div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <base-input :error="payment_method.name_error" placeholder="e.g. Bank Transfer" v-model="payment_method.name"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <label>Payment method content
              <el-popover trigger="hover"
                          placement="right">

                <div>
                  <div class="popover-body">This content will appear to your customer when they select this <br> payment method on the booking site.</div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <editor v-if="modals.custom_payment"
                    v-model="payment_method.content"
                    api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                    :init="{
                     height: 200,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent '
                   }"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="payment_method.enabled_internally" />
            <label class="text-white"> &nbsp; Activate on internal payments</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="payment_method.enabled_externally" />
            <label class="text-white"> &nbsp; Activate on booking site</label>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col text-center">
            <base-button type="default" v-on:click="modals.custom_payment = false">Cancel</base-button>
            <base-button type="primary" v-if="payment_method.hasOwnProperty('id') && payment_method.id" v-on:click="updatePaymentMethod()" class="ml-2">Save</base-button>
            <base-button type="primary" v-else v-on:click="createPaymentMethod()" class="ml-2">Save</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>


<script>
import {API_LOCATION} from "@/api-config";
import {Auth} from "aws-amplify";
import BaseAlert from "@/components/BaseAlert";
import axios from "axios";
import Badge from "@/components/Badge";
import swal from "sweetalert2";
import { BaseSwitch } from '@/components';
import {TimePicker, Form, FormItem, Upload, Dialog, Dropdown, DropdownItem, DropdownMenu, Switch} from "element-ui";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import {BTable, BPagination} from "bootstrap-vue";
import {formattedDate, localTime, time} from "@/plugins/dateFormatter";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import draggable from "vuedraggable";
import { ImageUpload } from '@/components';
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "PaymentGateways",
  components: {BaseAlert, Badge, BaseSwitch,
    [TimePicker.name]: TimePicker,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Switch.name]: Switch,
    ImageUpload,
    draggable,
    Editor,
    Breadcrumb, BreadcrumbItem, BTable, BPagination},

  data() {
    return {
      edit_signature: false,
      square_hardware_types: [],
      square_settings: { note: '', create_customer: 0, webhook_signature: '', store_credit_card: 0},
      show_dynamic_variables: false,
      edit_test_creds: false,
      edit_live_creds: false,
      payment_method: {id: 0, name: 'asdasd', status: 1, content: 'asdsd'},
      payment_methods: [{id: 0, name: 'Card', status: 1, content: ''}, {id: 1, name: 'Gift card', status: 1, content: ''}],
      request_periods: [{id: 0, label: 'minute(s)'}, {id: 1, label: 'hour(s)'}, {id: 2, label: 'day(s)'}, {id: 3, label: 'week(s)'}, {id: 4, label: 'month(s)'}],
      periods: [{id: 0,label: 'day(s)'}, {id: 1, label: 'week(s)'}, {id: 2, label: 'month(s)'}, {id: 3,label: 'year(s)'}],
      tip_preset_values: [],
      group: '',
      currencies: currencies.currencies,
      currency_symbol: '$',
      picker_time_format: 'hh:mm A',
      loading: false,
      empty: false,
      api_name: '',
      api_key: '',
      api_location: '',
      payment_gateways: '',
      payment_settings: '',
      payment_gateway: '',
      credentials: '',
      gateway_configured: false,
      games: [],
      valueTypesTipping: [
        {
          name: 'Percentage of subtotal',
          id: 0
        },
        {
          name: 'Flat value',
          id: 1
        }
      ],
      err: {
        live_credentials: {
          api_name: '',
          api_key: '',
          api_location: '',
        },
        test_credentials: {
          api_name: '',
          api_key: '',
          api_location: '',
        }
      },
      test_credentials: {
        api_name: '',
        api_key: '',
        api_location: '',
        is_active: 0,
        api_name_edit: '',
        api_key_edit: '',
        api_location_edit: '',
      },
      live_credentials: {
        api_name: '',
        api_key: '',
        api_location: '',
        is_active: 0,
        api_name_edit: '',
        api_key_edit: '',
        api_location_edit: '',
      },
      activeTabIndex: 0,
      j: 0,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      date_format_cal: '',
      create_new_tax: false,
      create_new_fee: false,
      taxes: [],
      fees: [],
      pricing_type: '',
      value_type: '',
      tax_title: '',
      tax_number: '',
      tax_amount: '',
      tax_amount_label: 'Percentage amount (%)',
      is_compound_tax: false,
      optional_tax: false,
      optional_fee: false,
      apply_fee_to_gift_cards: true,
      apply_after_discount: false,
      pricingTypes: [
        {
          id: 0,
          name: 'Exclusive of pricing'
        },{
          id: 1,
          name: 'Inclusive of pricing'
        }
      ],
      valueTypesTaxes: [
        {
          id: 0,
          name: 'Value (total)'
        },{
          id: 1,
          name: 'Value (per person)'
        },{
          id: 2,
          name: 'Percentage'
        },

      ],
      errs: {
        payment_request_timeout_value: '',
        payment_request_threshold: '',
        tos_url: '',
        redirect_url: '',
        tax_title: '',
        pricing_type: '',
        value_type: '',
        tax_amount: '',
        fee_title: '',
        fee_value_type: '',
        fee_value: '',
        webhook_signature: '',
        hardware_type: ''

      },
      selectedTaxId: '',
      selectedFeeId: '',
      fee: {
        title: '',
        value_type: '',
        amount: ''
      },
      modals: {
        edit_tax: false,
        edit_fee: false,
        custom_payment: false,
        add_tip_option: false
      },
      selectedTipOption: '',
      square_device_codes: []
    }
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return mobileRegex.test(userAgent);
    },
    categories() {
      return [
        { name: 'Payment methods', icon: 'tim-icons  icon-credit-card',active: true },
        { name: 'Taxes', icon: 'tim-icons  icon-bank',active: false },
        { name: 'Fees', icon: 'tim-icons  icon-paper',active: false },
        { name: 'Payment gateways', icon: 'tim-icons icon-money-coins',active: false }
      ];
    }
  },
  methods: {
    validateHardwareFields(){
      let errors = 0
      if(!this.square_settings.hardware_type){
        this.errs.hardware_type = 'You must select a hardware type'
        errors++
      } else {
        this.errs.hardware_type = ''
      }

      if((!this.square_settings.has_signature && this.square_settings.webhook_signature === '') ||
        (this.square_settings.has_signature && this.square_settings.webhook_signature === '' && this.edit_signature)){
        this.errs.webhook_signature = 'You must add the Signature key'
        errors++
      } else {
        this.errs.webhook_signature = ''
      }

      return errors > 0 ? false : true
    },
    async saveSquareCustomFields(source){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let settingsId = JSON.parse(localStorage.getItem('group')).payment_settings_id
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + settingsId
        +  '/square-settings'

      let data = this.square_settings
      if(source === 'customer'){
        data.customer = 1
      }
      if(source === 'payment_method'){
        data.payment_method = 1
      }

      if(source === 'hardware'){
        if(!this.validateHardwareFields()){
          return
        }
        data.hardware_type_id = data.hardware_type.id
        data.hardware = 1
        data.update_webhook_key = (this.square_settings.has_signature && this.edit_signature) || !this.square_settings.has_signature ? 1 : 0
      }

      this.loading = true
      axios.put(url, data, config)
        .then((res) => {
          swal('Success', 'Square settings have been updated', 'success')
          this.getDeviceCodes()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async generateSquareDeviceCode(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let settingsId = JSON.parse(localStorage.getItem('group')).payment_settings_id
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + settingsId
        +  '/square-terminal'

      let data = {}
      this.loading = true
      axios.post(url, data, config)
        .then((res) => {
          console.log(res)
          this.getDeviceCodes()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getDeviceCodes(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let settingsId = JSON.parse(localStorage.getItem('group')).payment_settings_id
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + settingsId
        +  '/square-terminal'

      this.loading = true
      axios.get(url, config)
        .then((res) => {
          this.square_device_codes = res.data.device_codes
          this.square_settings = res.data.square_settings
          this.square_settings.create_customer = Boolean(res.data.square_settings.create_customer)
          this.square_settings.store_credit_card = Boolean(res.data.square_settings.store_credit_card)
          this.square_hardware_types = res.data.square_hardware_types
          this.square_settings.hardware_type = _.find(this.square_hardware_types, {name: this.square_settings.hardware_type})
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editLiveCreds(){
      this.live_credentials.api_name_edit = this.live_credentials.api_name
      this.live_credentials.api_key_edit = this.live_credentials.api_key
      this.live_credentials.api_location_edit = this.live_credentials.api_location
      this.edit_live_creds = true
    },
    editTestCreds(){
      this.test_credentials.api_name_edit = this.test_credentials.api_name
      this.test_credentials.api_key_edit = this.test_credentials.api_key
      this.test_credentials.api_location_edit = this.test_credentials.api_location
      this.edit_test_creds = true
    },
    editTipOption(item, index){
      this.selectedTipOption = {
        name: item.name,
        amount: item.amount,
        value_type:  item.value_type,
        editing: true,
        index: index
      }
      this.modals.add_tip_option = true
    },
    deleteTipOption(index){
      this.tip_preset_values.splice(index, 1);
    },
    cancelTipCreation(){
      this.modals.add_tip_option = false
    },
    createTip(){
      this.tip_preset_values.push(this.selectedTipOption)
      this.modals.add_tip_option = false
    },
    saveTip(){
      this.tip_preset_values[this.selectedTipOption.index].name = this.selectedTipOption.name
      this.tip_preset_values[this.selectedTipOption.index].value_type = this.selectedTipOption.value_type
      this.tip_preset_values[this.selectedTipOption.index].amount = this.selectedTipOption.amount
      this.modals.add_tip_option = false
    },
    addTipPresetValue(){
      this.modals.add_tip_option = true
      this.selectedTipOption = {
        name: '',
        amount: 0,
        value_type:  {id: 0},
        editing: false
      }
    },
    editPaymentMethod(item){
      this.payment_method = {
        name: item.name,
        content: item.content,
        id: item.id,
        enabled_externally: Boolean(item.enabled_externally),
        enabled_internally: Boolean(item.enabled_internally),
        action: 'editing'
      }
      this.modals.custom_payment = true
    },
    async updatePaymentMethodOrder(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/payment-methods'

      let data = {
        payment_methods: this.payment_methods,
      }

      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deletePaymentMethod(item){
      if(this.payment_method.name === '') {
        this.payment_method.name_error = 'Payment name cannot be empty'
        this.j++
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/payment-methods/' + item.id

      axios.delete(url, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          swal({
            title: 'Success',
            text: `Payment method has been deleted`,
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async togglePaymentMethodActivation(item, action, action_text){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/payment-methods/' + item.id

      let data = {
        action: action
      }

      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          swal({
            title: 'Success',
            text: `Payment method has been ` + action_text,
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updatePaymentMethod(){
      if(this.payment_method.name === '') {
        this.payment_method.name_error = 'Payment name cannot be empty'
        this.j++
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/payment-methods/' + this.payment_method.id

      let data = this.payment_method

      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.modals.custom_payment = false
          this.loading = false
          swal({
            title: 'Success',
            text: `Payment method has been updated`,
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createPaymentMethod(){
      if(this.payment_method.name === '') {
        this.payment_method.name_error = 'Payment name cannot be empty'
        this.j++
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/payment-methods'

      let data = this.payment_method

      axios.post(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.modals.custom_payment = false
          this.loading = false
          swal({
            title: 'Success',
            text: `Payment method has been created`,
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createCustomPaymentPrompt(){
      this.payment_method = {
        name: '',
        content: '',
        id: '',
        enabled_externally: false,
        enabled_internally: false,
        position: this.payment_methods.length,
        action: 'new'
      }
      this.modals.custom_payment = true
    },
    copyItem(item) {
      navigator.clipboard.writeText(item);
    },
    copy(text){
      navigator.clipboard.writeText(text);
    },
    getLocalTime(d){
      if(!d){
        return
      }
      return localTime(this.time_format, d)
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getDate(form)
      return final
    },
    validatePaymentRequestFields(){
      let errors = 0

      if(this.payment_settings.payment_request_threshold === ''){
        this.errs.payment_request_threshold = 'Field cannot be empty'
        errors++
      } else {
        this.errs.payment_request_threshold = ''
      }

      if(this.payment_settings.payment_request_timeout_value === ''){
        this.errs.payment_request_timeout_value = 'Field cannot be empty'
        errors++
      } else {
        this.errs.payment_request_timeout_value = ''
      }
      return errors > 0 ? false : true
    },
    async savePaymentRequestSettings(){
      if(!this.validatePaymentRequestFields()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + this.payment_settings.id + '/payment-request'

      let data = {
        payment_request_timeout_value: this.payment_settings.payment_request_timeout_value,
        payment_request_timeout_period: this.payment_settings.payment_request_timeout_period.id,
        payment_request_has_timeout: this.payment_settings.payment_request_has_timeout,
        payment_request_threshold: this.payment_settings.payment_request_threshold,
        allow_pay_on_arrival: this.payment_settings.allow_pay_on_arrival,
        allow_tipping: this.payment_settings.allow_tipping,
        allow_custom_tipping_options: this.payment_settings.allow_custom_tipping_options,
        allow_gift_card_purchase_on_gift_card: this.payment_settings.allow_gift_card_purchase_on_gift_card,
        custom_tipping_value_type: this.payment_settings.custom_tipping_value_type.id,
        allow_preset_tipping_options: this.payment_settings.allow_preset_tipping_options,
        tip_preset_values: this.tip_preset_values,
        tip_label: this.payment_settings.tip_label,
        tip_line_item_label: this.payment_settings.tip_line_item_label,
        payment_request_allow_custom_amounts: this.payment_settings.payment_request_allow_custom_amounts
      }

      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          swal({
            title: 'Success',
            text: `Payment method settings have been updated`,
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    toggleActivationFeePrompt(item){
      if(item.is_active){
        swal({
          title: 'Are you sure?',
          text: `This action will deactivate the fee and remove it from all future bookings.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.toggleFeeActivation(item)
          }
        })
      } else {
        swal({
          title: 'Are you sure?',
          text: `This action will activate the fee and apply it to all future bookings.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.toggleFeeActivation(item)
          }
        })
      }
    },
    async editFee(){
      if(this.validateFeeFields() > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/fees/' + this.selectedFeeId

      let data = {
        'name': this.fee.title,
        'value_type': this.fee.value_type.id,
        'amount': this.fee.value_type.id === 2 ? parseFloat(this.fee.amount).toFixed(3) :  parseFloat(this.fee.amount).toFixed(2),
        'optional': this.optional_fee,
        'apply_fee_to_gift_cards': this.apply_fee_to_gift_cards
      }
      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          this.clearFeeFields()
          swal({
            title: 'Success',
            text: `Fee has been updated`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteFee(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/fees/' + id

      axios.delete(url, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false

          swal({
            title: 'Success!',
            text: `Fee has been deleted`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editFeePrompt(item){
      this.create_new_fee = false
      this.selectedFeeId = item.id
      this.fee.title = item.name
      this.fee.amount = item.amount
      this.optional_fee = Boolean(item.optional)
      this.apply_fee_to_gift_cards = Boolean(item.apply_fee_to_gift_cards)
      this.fee.value_type = { id: item.value_type }
      this.modals.edit_fee = true
      this.errs.fee_title =  ''
      this.errs.fee_value_type =  ''
      this.errs.fee_amount =  ''
      this.changeTaxAmount(this.fee.value_type)
    },
    cancelFeeCreation(){
      this.create_new_fee = false
      this.fee.title = ''
      this.fee.value_type = ''
      this.fee.amount = ''
      this.optional_fee = false
      this.apply_fee_to_gift_cards = true
      this.tax_amount_label = 'Percentage amount (%)'
      this.errs.fee_title =  ''
      this.errs.fee_value_type =  ''
      this.errs.fee_value =  ''
      this.modals.edit_fee = false
    },
    clearFeeFields(){
      this.create_new_fee = false
      this.fee.title = ''
      this.fee.value_type = ''
      this.fee.amount = ''
      this.optional_fee = false
      this.apply_fee_to_gift_cards = true
      this.tax_amount_label = 'Percentage amount (%)'
      this.modals.edit_fee = false
    },
    validateFeeFields(){
      let errors = 0
      if(this.fee.value_type.id === 2){
        if(this.fee.amount > 100 || this.fee.amount <= 0){
          this.errs.fee_value = 'Amount must be between 0.01 and 100'
          errors++
        } else {
          this.errs.fee_value = ''
        }
      }
      if(this.fee.amount.length <=0){
        this.errs.fee_value = 'Amount cannot be empty'
        errors++
      } else {
        this.errs.fee_value = ''
      }
      if(this.fee.title.length <=0){
        this.errs.fee_title = 'Fee title cannot be empty'
        errors++
      } else {
        this.errs.fee_title = ''
      }
      if (this.fee.value_type.length <= 0) {
        this.errs.fee_value_type = 'Value type cannot be empty'
        errors++
      } else {
        this.errs.fee_value_type = ''
      }

      return errors
    },
    async createFee(){
      if(this.validateFeeFields() > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/fees'

      let data = {
        name: this.fee.title,
        value_type: this.fee.value_type.id,
        amount: this.fee.value_type.id === 2 ? parseFloat(this.fee.amount).toFixed(3) :  parseFloat(this.fee.amount).toFixed(2),
        optional: this.optional_fee,
        apply_fee_to_gift_cards: this.apply_fee_to_gift_cards
      }

      axios.post(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          this.create_new_fee = false
          this.apply_fee_to_gift_cards = true
          this.optional_fee = false
          this.fee = {
            title: '',
            value_type: '',
            amount: ''
          }
          swal({
            title: 'Success',
            text: `Fee has been created`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async editTax(){
      if(this.validateTaxFields() > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/taxes/' + this.selectedTaxId

      let data = {
        'name': this.tax_title,
        'pricing_type': this.pricing_type.id,
        'value_type': this.value_type.id,
        'tax_number': this.tax_number,
        'amount': this.value_type.id === 2 ? parseFloat(this.tax_amount).toFixed(3) :  parseFloat(this.tax_amount).toFixed(2),
        'is_compound': this.is_compound_tax,
        'apply_after_discount': this.apply_after_discount,
        'optional': this.optional_tax
      }
      axios.put(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          this.clearTaxFields()
          swal({
            title: 'Success',
            text: `Tax has been updated`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editTaxPrompt(item){
      this.create_new_tax = false
      this.selectedTaxId = item.id
      this.tax_title = item.name
      this.tax_amount = item.amount
      this.tax_number = item.tax_number
      this.is_compound_tax = item.is_compound
      this.optional_tax = item.optional
      this.apply_after_discount = item.apply_after_discount
      this.pricing_type = { id: item.pricing_type, name: this.getPricingType(item.pricing_type) }
      this.value_type = { id: item.value_type, name: this.getPricingType(item.value_type) }
      this.modals.edit_tax = true
      this.errs.tax_title =  ''
      this.errs.pricing_type =  ''
      this.errs.value_type =  ''
      this.errs.tax_amount =  ''
      this.changeTaxAmount(this.value_type)
    },
    async deleteTax(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/taxes/' + id

      axios.delete(url, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false

          swal({
            title: 'Success!',
            text: `Tax has been deleted`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    toggleActivationPrompt(item){
      if(item.is_active){
        swal({
          title: 'Are you sure?',
          text: `This action will deactivate the tax and remove it from all future bookings.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.toggleActivation(item)
          }
        })
      } else {
        swal({
          title: 'Are you sure?',
          text: `This action will activate the tax and apply it to all future bookings.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.toggleActivation(item)
          }
        })
      }
    },
    async toggleFeeActivation(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/fees/' + item.id + '/activate'

      let data = {
        'is_active': !item.is_active
      }
      axios.post(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async toggleActivation(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/taxes/' + item.id + '/activate'

      let data = {
        'is_active': !item.is_active
      }
      axios.post(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    clearTaxFields(){
      this.create_new_tax = false
      this.tax_title = ''
      this.is_compound_tax = false
      this.optional_tax = false
      this.pricing_type = ''
      this.value_type = ''
      this.tax_amount = ''
      this.tax_number = ''
      this.tax_amount_label = 'Percentage amount (%)'
      this.modals.edit_tax = false
    },
    validateTaxFields(){
      let errors = 0
      if(this.value_type.id === 2){
        if(this.tax_amount > 100 || this.tax_amount <= 0){
          this.errs.tax_amount = 'Amount must be between 0.01 and 100'
          errors++
        } else {
          this.errs.tax_amount = ''
        }
      }
      if(this.tax_amount.length <=0){
        this.errs.tax_amount = 'Amount cannot be empty'
        errors++
      } else {
        this.errs.tax_amount = ''
      }
      if(this.tax_title.length <=0){
        this.errs.tax_title = 'Tax title cannot be empty'
        errors++
      } else {
        this.errs.tax_title = ''
      }
      if (this.pricing_type.length <= 0) {
        this.errs.pricing_type = 'Pricing type cannot be empty'
        errors++
      } else {
        this.errs.pricing_type = ''
      }
      if (this.value_type.length <= 0) {
        this.errs.value_type = 'Value type cannot be empty'
        errors++
      } else {
        this.errs.value_type = ''
      }

      return errors
    },
    async createTax(){

      if(this.validateTaxFields() > 0){
        return
      }
      if(this.pricingTypeConflict()){
        swal({
          title: 'Error',
          text: `You cannot mix exclusive and inclusive taxes`,
          type: 'error',
          confirmButtonClass: 'btn btn-success btn-fill',
          confirmButtonText: 'Ok',
          buttonsStyling: false
        })
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/taxes'

      let data = {
        'name': this.tax_title,
        'tax_number': this.tax_number,
        'pricing_type': this.pricing_type.id,
        'value_type': this.value_type.id,
        'amount': this.value_type.id === 2 ? parseFloat(this.tax_amount).toFixed(3) :  parseFloat(this.tax_amount).toFixed(2),
        'is_compound': this.is_compound_tax,
        'apply_after_discount': this.apply_after_discount,
        'optional': this.optional_tax
      }
      axios.post(url, data, config)
        .then((res) => {
          this.getPaymentSettings()
          this.loading = false
          swal({
            title: 'Success',
            text: `Tax has been created`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
          this.clearTaxFields()

        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    cancelTaxCreation(){
      this.create_new_tax = false
      this.tax_title = ''
      this.is_compound_tax = false
      this.optional_tax = false
      this.pricing_type = ''
      this.value_type = ''
      this.tax_amount = ''
      this.tax_number = ''
      this.tax_amount_label = 'Percentage amount (%)'
      this.modals.edit_tax = false
      this.errs.tax_title =  ''
      this.errs.pricing_type =  ''
      this.errs.value_type =  ''
      this.errs.tax_amount =  ''
    },
    changeTaxAmount(value_type){
      if(value_type.id === 0){
        this.tax_amount_label = 'Total amount (' + this.currency_symbol + ')'
      } else if (value_type.id === 1){
        this.tax_amount_label = 'Per person amount (' + this.currency_symbol + ')'
      } else {
        this.tax_amount_label = 'Percentage amount (%)'
      }
    },
    getAmount(type, amount, feature){
      if(type === 2 || (type === 0 && feature === 'tip')){
        return amount + '%'
      } else {
        if(this.number_format === 1){
          return this.currency_symbol + amount.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        }
        return this.currency_symbol + amount.toFixed(2)
      }
    },
    getPricingType(type){
      if(type === 0){
        return 'Exclusive of pricing'
      } else {
        return 'Inclusive of pricing'
      }
    },
    getValueType(type){
      if(type === 0){
        return 'Value (total)'
      } else if (type === 1){
        return 'Value (per person)'
      } else {
        return 'Percentage'
      }
    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      if(this.activeTabIndex === 3 && this.payment_gateway.id === 2){
        this.getDeviceCodes()
      }
      this.addQueryParameters()
      this.j++
    },
    isValidForm(type){
      let errors = 0
      if(type === 'test'){
        if(this.test_credentials.api_name_edit === '' || this.test_credentials.api_name_edit === null){
          this.err.test_credentials.api_name = 'Invalid ' + this.api_name
          errors++
        } else {
          this.err.test_credentials.api_name = ''
        }
        if(this.test_credentials.api_key_edit === '' || this.test_credentials.api_key_edit === null){
          this.err.test_credentials.api_key = 'Invalid ' + this.api_key
          errors++
        } else {
          this.err.test_credentials.api_key = ''
        }
        if(this.payment_gateway.id === 2 && (this.test_credentials.api_location_edit === '' || this.test_credentials.api_location_edit === null)){
          if(this.test_credentials.api_location_edit === '' || this.test_credentials.api_location_edit === null){
            this.err.test_credentials.api_location = 'Invalid ' + this.api_location
            errors++
          } else {
            this.err.test_credentials.api_location = ''
          }
        }

      } else {
        if(this.live_credentials.api_name_edit === '' || this.live_credentials.api_name_edit === null){
          this.err.live_credentials.api_name = 'Invalid ' + this.api_name
          errors++
        } else {
          this.err.live_credentials.api_name = ''
        }
        if(this.live_credentials.api_key_edit === '' || this.live_credentials.api_key_edit === null){
          this.err.live_credentials.api_key = 'Invalid ' + this.api_key
          errors++
        } else {
          this.err.live_credentials.api_key = ''
        }
        if(this.payment_gateway.id === 2 && (this.live_credentials.api_location_edit === '' || this.live_credentials.api_location_edit === null)){
          if(this.live_credentials.api_location_edit === '' || this.live_credentials.api_location_edit === null){
            this.err.live_credentials.api_location = 'Invalid ' + this.api_location
            errors++
          } else {
            this.err.live_credentials.api_location = ''
          }
        }
      }
      if(errors > 0){
        return false
      }
      return true
    },
    async updateCredentials(type, status){
      let companyId = localStorage.getItem('companyId')
      let settingsId = JSON.parse(localStorage.getItem('group')).payment_settings_id
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + settingsId
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}

      let data = {}
      if(type === 'test'){
        data.id = this.test_credentials.id
        data.api_name = this.test_credentials.api_name_edit
        data.api_key = this.test_credentials.api_key_edit
        data.api_location = this.test_credentials.api_location_edit
        data.is_live = 0
      } else {
        data.id = this.live_credentials.id
        data.api_name = this.live_credentials.api_name_edit
        data.api_key = this.live_credentials.api_key_edit
        data.api_location = this.live_credentials.api_location_edit
        data.is_live = 1
      }
      data.update_only = false
      if(status === 0){
        if(type === 'test'){
          data.id = this.test_credentials.id
          data.api_name = this.test_credentials.api_name
          data.api_key = this.test_credentials.api_key
          data.api_location = this.test_credentials.api_location
        } else {
          data.id = this.live_credentials.id
          data.api_name = this.live_credentials.api_name
          data.api_key = this.live_credentials.api_key
          data.api_location = this.live_credentials.api_location
        }

        data.is_active = 0
      } else if(status === 1) {
        //check that other creds aren't also live
        if(type === 'test'){
          data.id = this.test_credentials.id
          data.api_name = this.test_credentials.api_name
          data.api_key = this.test_credentials.api_key
          data.api_location = this.test_credentials.api_location
          if(this.live_credentials.is_active === 1){
            let cont = true
            await swal({
              title: 'Are you sure?',
              text: 'Activating test credentials will deactivate your live credentials.',
              type: 'warning',
              confirmButtonText: 'Activate',
              showCancelButton: true,
            }).then((response)=> {
              console.log(response.dismiss)
              if(response.dismiss === 'cancel'){
                cont = false
              }
            })
            if(!cont){
              return
            }
          }
        } else {
          data.id = this.live_credentials.id
          data.api_name = this.live_credentials.api_name
          data.api_key = this.live_credentials.api_key
          data.api_location = this.live_credentials.api_location
          if(this.test_credentials.is_active === 1){
            let cont = true
            await swal({
              title: 'Are you sure?',
              text: 'Activating live credentials will deactivate your test credentials.',
              type: 'warning',
              confirmButtonText: 'Activate',
              showCancelButton: true,
            }).then((response)=> {
              console.log(response.dismiss)
              if(response.dismiss === 'cancel'){
                cont = false
              }
            })
            if(!cont){
              return
            }
          }
        }
        data.is_active = 1
      } else {
        if(!this.isValidForm(type)){
          return
        }
        data.update_only = true
      }

      data.payment_gateway_id = this.payment_gateway.id

      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          if(status === 1){
            if(type === 'test'){
              swal('Success!', 'Test credentials have been activated.', 'success')
            } else {
              swal('Success!', 'Live credentials have been activated.', 'success')
            }

          } else if(status === 0){
            if(type === 'test'){
              swal('Success!', 'Test credentials have been deactivated.', 'success')
            } else {
              swal('Success!', 'Live credentials have been deactivated.', 'success')
            }
          } else {
            if(type === 'test'){
              swal('Success!', 'Test credentials have been updated.', 'success')
              this.edit_test_creds = false
            } else {
              swal('Success!', 'Live credentials have been updated.', 'success')
              this.edit_live_creds = false
            }
          }

          this.err= {
            live_credentials: {
              api_name: '',
              api_key: '',
              api_location: '',
            },
            test_credentials: {
              api_name: '',
              api_key: '',
              api_location: '',
            }
          }
          this.getPaymentSettings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    pricingTypeConflict(){
      if(this.pricing_type.id === 0){
        for(let n in this.taxes){
          if(this.taxes[n].pricing_type === 1 && this.taxes[n].is_active){
            return true
          }
        }
      } else {
        for(let m in this.taxes){
          if(this.taxes[m].pricing_type === 0 && this.taxes[m].is_active){
            return true
          }
        }
      }
      return false
    },
    async getPaymentSettings() {
      let settingsId = this.$route.query.id
      this.loading = true
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/payment-settings/' + settingsId
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}

      axios.get(url, config)
        .then(response => {
          this.payment_settings = response.data.payment_settings
          this.payment_settings.payment_request_timeout_period = _.find(this.request_periods, {id: this.payment_settings.payment_request_timeout_period})
          this.payment_settings.custom_tipping_value_type = _.find(this.valueTypesTipping, {id: this.payment_settings.custom_tipping_value_type})
          this.payment_settings.allow_custom_tipping_options = Boolean(this.payment_settings.allow_custom_tipping_options)
          this.payment_settings.allow_tipping = Boolean(this.payment_settings.allow_tipping)
          this.payment_settings.allow_pay_on_arrival = Boolean(this.payment_settings.allow_pay_on_arrival)
          this.payment_settings.allow_gift_card_purchase_on_gift_card = Boolean(this.payment_settings.allow_gift_card_purchase_on_gift_card)
          this.payment_settings.allow_preset_tipping_options = Boolean(this.payment_settings.allow_preset_tipping_options)
          this.payment_settings.allow_preset_tipping_options = Boolean(this.payment_settings.allow_preset_tipping_options)
          this.payment_settings.payment_request_allow_custom_amounts = Boolean(this.payment_settings.payment_request_allow_custom_amounts)
          this.payment_settings.payment_request_has_timeout = Boolean(this.payment_settings.payment_request_has_timeout)

          if(response.data.payment_methods.length > 0){
            this.payment_methods = response.data.payment_methods
          } else {
            if(response.data.payment_gateway.id === 5){
              this.payment_methods.push({id: 2, name: 'Yappy', status: 1, content: ''})
            }
          }
          this.tip_preset_values = response.data.tip_preset_values
          for(let n in this.tip_preset_values){
            this.tip_preset_values[n].value_type = _.find(this.valueTypesTipping, {id: this.tip_preset_values[n].value_type})
          }
          this.credentials = response.data.credentials
          this.taxes = response.data.taxes
          this.fees = response.data.fees

          if(response.data.payment_gateway === undefined){
            this.gateway_configured = false

          } else {
            this.gateway_configured = true
            this.payment_gateway = response.data.payment_gateway
            if(this.payment_gateway.id === 1){
              this.api_name = 'API Login ID'
              this.api_key = 'Transaction Key'
            } else if (this.payment_gateway.id === 2){
              this.api_name = 'Application ID'
              this.api_key = 'Access token'
              this.api_location = 'Location ID'
            } else if (this.payment_gateway.id === 3){
              this.api_name = 'Publishable key'
              this.api_key = 'Secret key'

            } else if (this.payment_gateway.id === 4){
              this.api_name = 'Public key'
              this.api_key = 'Secret key'
            }
            else if (this.payment_gateway.id === 5){
              this.api_name = 'Merchant ID'
              this.api_key = 'Secret key'
            }

            for(let n in this.credentials){
              if(this.credentials[n].is_live === 0 && this.payment_gateway.id === this.credentials[n].payment_gateway_id){
                this.test_credentials.id = this.credentials[n].id
                this.test_credentials.api_key = this.credentials[n].api_key
                this.test_credentials.api_name = this.credentials[n].api_name
                this.test_credentials.is_active = this.credentials[n].is_active
                this.test_credentials.is_live = this.credentials[n].is_live
                this.test_credentials.api_location = this.credentials[n].api_location
              } else if(this.credentials[n].is_live === 1 && this.payment_gateway.id === this.credentials[n].payment_gateway_id) {
                this.live_credentials.id = this.credentials[n].id
                this.live_credentials.api_key = this.credentials[n].api_key
                this.live_credentials.api_name = this.credentials[n].api_name
                this.live_credentials.is_active = this.credentials[n].is_active
                this.live_credentials.is_live = this.credentials[n].is_live
                this.live_credentials.api_location = this.credentials[n].api_location

              }
            }
          }
          if(this.activeTabIndex === 3 && this.payment_gateway.id === 2){
            this.getDeviceCodes()
          }
          this.addQueryParameters()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async getPaymentGateways(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/integrations/payment-gateways'
      this.axios.get(url, config)
        .then(response => {
          this.payment_gateways = response.data.payment_gateways
          if(this.payment_gateways.length <= 0){
            this.empty = true
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async changePaymentGateway(){
      let cont = true
      await swal({
        title: 'Are you sure?',
        text: 'This will deactivate credentials for your current payment gateway.',
        type: 'warning',
        confirmButtonText: 'Change payment gateway',
        showCancelButton: true,
      }).then((response)=> {
        if(response.dismiss === 'cancel'){
          cont = false
        }
      })
      if(!cont){
        return
      }
      this.gateway_configured = false
    },
    goToIntegrationSetup(gateway){
      this.payment_gateway = gateway
      this.gateway_configured = true

      this.test_credentials = {
        api_name: '',
        api_key: '',
        api_location: '',
        is_active: 0
      }
      this.live_credentials = {
        api_name: '',
        api_key: '',
        api_location: '',
        is_active: 0
      }

      if(this.payment_gateway.id === 1){
        this.api_name = 'API Login ID'
        this.api_key = 'Transaction Key'
      } else if (this.payment_gateway.id === 2){
        this.api_name = 'Application ID'
        this.api_key = 'Access token'
        this.api_location = 'Location ID'
      }  else if (this.payment_gateway.id === 3){
        this.api_name = 'Publishable key'
        this.api_key = 'Secret key'
      } else if (this.payment_gateway.id === 4){
        this.api_name = 'Public key'
        this.api_key = 'Secret key'
      } else if (this.payment_gateway.id === 5){
        this.api_name = 'Merchant ID'
        this.api_key = 'Secret key'
      }

      for(let n in this.credentials){
        if(this.credentials[n].is_live === 0 && this.payment_gateway.id === this.credentials[n].payment_gateway_id){
          this.test_credentials.id = this.credentials[n].id
          this.test_credentials.api_key = this.credentials[n].api_key
          this.test_credentials.api_name = this.credentials[n].api_name
          this.test_credentials.is_active = this.credentials[n].is_active
          this.test_credentials.is_live = this.credentials[n].is_live
          this.test_credentials.api_location = this.credentials[n].api_location
        } else if(this.credentials[n].is_live === 1 && this.payment_gateway.id === this.credentials[n].payment_gateway_id) {
          this.live_credentials.id = this.credentials[n].id
          this.live_credentials.api_key = this.credentials[n].api_key
          this.live_credentials.api_name = this.credentials[n].api_name
          this.live_credentials.is_active = this.credentials[n].is_active
          this.live_credentials.is_live = this.credentials[n].is_live
          this.live_credentials.api_location = this.credentials[n].api_location

        }
      }
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        id: this.payment_settings.id,
        tab: this.activeTabIndex
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
  mounted() {
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    this.group = JSON.parse(localStorage.getItem('group'))
    if (this.time_format === 'HH:mm') {
      this.picker_time_format = 'HH:mm'
    }
    this.getGames()
    this.getPaymentSettings()
    this.getPaymentGateways()
    let tab = this.$route.query.hasOwnProperty('tab') ? this.$route.query.tab : 0
    this.switchTab(Number(tab))
  }
}
</script>

<style scoped>
.button-wide{
  width: 90%;
}
.card-list {
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  display: flex;
}

.rounded-table {
  border: 2px solid #ffffff; /* Outer border */
  border-radius: 15px;    /* Rounded corners for the outer border */
  border-collapse: collapse; /* Collapse inner borders to remove them */
  /*overflow: hidden; !* Ensures table content respects the rounded border *!*/
  width: 100%; /* Optional: Table width adjustment */
}
/*.rounded-table td, .rounded-table th {*/
/*  padding: 10px;*/
/*  border: 1px solid #000; !* Optional: Add borders to cells if needed *!*/
/*}*/

</style>
