import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Authentication/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import Unauthorized from 'src/pages/GeneralViews/UnauthorizedPage'

//Authentication
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Authentication/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Authentication/Register.vue');

//Bookings
import Bookings from '@/pages/Bookings/Bookings/Bookings.vue'
import BookingDetails from '@/pages/Bookings/Bookings/BookingDetails.vue'
import BookingSlots from "@/pages/Bookings/BookingSlots"
import ScheduleList from "@/pages/Schedules/ScheduleList";
import PaymentGateways from "@/pages/Purchases/PaymentSettings/PaymentSettings";

//Purchases
import Transactions from "@/pages/Purchases/Transactions/Transactions";
import TransactionDetails from "@/pages/Purchases/Transactions/TransactionDetails";
import NewTransaction from "@/pages/Purchases/Transactions/NewTransaction";
import GiftCards from "@/pages/Purchases/GiftCards/GiftCards";
import GiftCardType from "@/pages/Purchases/GiftCards/GiftCardType";


// Games
import AddGame from 'src/pages/Games/GameSetup/AddGame'
import EditGame from 'src/pages/Games/GameSetup/EditGame'
import GameInstance from 'src/pages/Games/GameHistory/GameInstance'
import GameInstances from 'src/pages/Games/GameHistory/GameInstances'
import GameSetup from 'src/pages/Games/GameSetup/GameSetup'
import GameTracker from 'src/pages/Games/GameTracker/GameTracker'
import GameTrackerSelect from "@/pages/Games/GameTracker/GameTrackerSelect";
import GameTrackerSettingsTemplate from "@/pages/Games/GameTrackerSettings/GameTrackerSettingsTemplate"

//Checklists
import ChecklistSettings from "@/pages/Checklists/ChecklistSettings";
import Clues from 'src/pages/Games/Clues/Clues'
import ClueDisplay from 'src/pages/Games/Clues/ClueDisplay'
import GameSelect from 'src/pages/Games/Clues/GameSelect'
import GameSelectGMView from 'src/pages/Games/Clues/GameSelectGMView'
import ConfigureClues from "@/pages/Games/Clues/ConfigureClues";
import DisplayConfiguration from "@/pages/Games/Clues/DisplayConfiguration";
import ClueHistory from "@/pages/Games/Clues/ClueHistory";



//Mail
import Customers from "@/pages/Mail/Customers";
import CreateEmail from "@/pages/Mail/CreateEmail";
import EmailGroups from "@/pages/Mail/EmailGroups";
import AutomatedEmails from "@/pages/Mail/AutomatedEmails/AutomatedEmails";
import PreviewEmail from "@/pages/Mail/AutomatedEmails/PreviewEmail";

//Settings
import InviteUser from '@/pages/Settings/UserManagement/InviteUser'
import UserManagement from '@/pages/Settings/UserManagement/UserManagement'
import CompanySettings from 'src/pages/Settings/CompanySettings'
import MyProfile from 'src/pages/Settings/MyProfile'
import DeactivatedUsers from '@/pages/Settings/UserManagement/DeactivatedUsers'
import BillingPage from "@/pages/Settings/Billing/BillingPage";

//Surveys
import Surveys from 'src/pages/Surveys/Surveys'


// Waivers
import Waivers from 'src/pages/Waivers/Waivers.vue';
import WaiverTemplate from 'src/pages/Waivers/WaiverTemplate';

//customers
import PlayerInfo from 'src/pages/Players/PlayerInfo'
import Players from "@/pages/Players/Players";

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import GameDashboards from 'src/pages/Dashboard/GameDashboards'
import GameStats from 'src/pages/Dashboard/GameStats'
import Leaderboard from 'src/pages/Dashboard/Leaderboard'
import LeaderboardSettings from "@/pages/Dashboard/LeaderboardSettings";
import SelectSettings from "@/pages/Dashboard/SelectSettings";
import TransactionDashboard from "@/pages/Dashboard/TransactionDashboard";
import ReportBuilder from "@/pages/Dashboard/Reports/ReportBuilder";
import NewReport from "@/pages/Dashboard/Reports/NewReport";

//Photo pages
import TakePhotos from "@/pages/Photos/TakePhotos";
import PhotoSettingsTemplate from "@/pages/Photos/PhotoSettingsTemplate";

//Betas
import Changelog from "@/pages/Changelog/Changelog";
import BookingSiteTemplate from "@/pages/Bookings/BookinSiteSettings/BookingSiteTemplate";
import StartNewForm from "@/pages/Games/GameTracker/StartNewForm";
import GroupSettings from "@/pages/Settings/GroupSettings/GroupSettings";
import ChecklistDetails from "@/pages/Checklists/ChecklistDetails";
import Checklists from "@/pages/Checklists/Checklists";
import ChecklistHistory from "@/pages/Checklists/ChecklistHistory";
import ActiveChecklist from "@/pages/Checklists/ActiveChecklist";
import EditReport from "@/pages/Dashboard/Reports/EditReport";
import GeneratedReport from "@/pages/Dashboard/Reports/GeneratedReport";
import CreateSchedule from "@/pages/Schedules/CreateSchedule";
import EditSchedule from "@/pages/Schedules/EditSchedule";
import EditSurvey from "@/pages/Surveys/EditSurvey";
import Migration from "@/pages/Settings/Migration/Migration";
import MigrationLog from "@/pages/Settings/Migration/MigrationLog";
import SurveyHistory from "@/pages/Surveys/SurveyHistory";
import SurveyResponse from "@/pages/Surveys/SurveyResponse";
import SurveyQuestionResponses from "@/pages/Surveys/SurveyQuestionResponses";
import PhotoOverlays from "@/pages/Photos/PhotoOverlays";
import UserRoles from "@/pages/Settings/UserManagement/UserRoles";
import EditRole from "@/pages/Settings/UserManagement/EditRole";
import EventLogs from "@/pages/Settings/EventLogs/EventLogs";
import ActiveCarts from "@/pages/Purchases/ActiveCarts";
import InventoryManagement from "@/pages/Purchases/InventoryManagement";
import PromoCodes from "@/pages/Purchases/PromoCodes/PromoCodes";

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    }
  ]
};

let dashboardMenu = {
  path: '/dashboard',
  component: DashboardLayout,
  redirect: '/dashboard/games',
  name: 'Dashboards',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/dashboard/main',
      name: 'Dashboard',
      components: { default: Dashboard }
    },
    {
      path: '/dashboard/games',
      name: 'Game Dashboards',
      components: { default: GameDashboards }
    },
    {
      path: '/dashboard/bookings',
      name: 'Bookings Dashboards',
      components: { default: TransactionDashboard }
    },
    {
      path: '/dashboard/game/analytics',
      name: 'Analytics',
      components: { default: GameStats }
    },
    {
      path: '/dashboard/game/leaderboard',
      name: 'Leaderboard',
      components: { default: Leaderboard }
    },
    {
      path: '/dashboard/settings',
      name: 'Dashboard Settings',
      components: { default: SelectSettings }
    },
    {
      path: '/dashboard/game/leaderboard/settings',
      name: 'Leaderboard Settings',
      components: { default: LeaderboardSettings }
    },
    {
      path: '/analytics/reports',
      name: 'Report Builder',
      components: { default: ReportBuilder }
    },
    {
      path: '/analytics/reports/new',
      name: 'Report Builder',
      components: { default: NewReport }
    },
    {
      path: '/analytics/reports/edit',
      name: 'Report Builder',
      components: { default: EditReport }
    },
    {
      path: '/analytics/reports/run',
      name: 'Report Builder',
      components: { default: GeneratedReport }
    }
  ]
};

let bookingsMenu = {
  path: '/bookings',
  component: DashboardLayout,
  redirect: '/bookings/list',
  name: 'Bookings',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/bookings/transactions/new',
      name: 'New Transaction',
      components: { default: NewTransaction }
    },
    {
      path: '/bookings/list',
      name: 'Bookings',
      components: { default: Bookings }
    },
    {
      path: '/booking/details',
      name: 'Booking Details',
      components: { default: BookingDetails }
    },
    {
      path: '/bookings/calendar',
      name: 'Calendar',
      components: { default: BookingSlots }
    },
    {
      path: '/bookings/schedules',
      name: 'Schedules',
      components: { default: ScheduleList }
    },
    {
      path: '/bookings/schedules/create',
      name: 'Schedules',
      components: { default: CreateSchedule }
    },
    {
      path: '/bookings/schedules/edit',
      name: 'Schedules',
      components: { default: EditSchedule }
    },
    {
      path: '/bookings/settings',
      name: 'Booking Site Settings',
      components: { default: BookingSiteTemplate }
    }
  ]
};

let purchasesMenu = {
  path: '/purchases',
  component: DashboardLayout,
  redirect: '/purchases/transactions?count=10&page=1',
  name: 'Purchases',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/purchases/transactions',
      name: 'Transactions',
      components: { default: Transactions }
    },
    {
      path: '/purchases/gift-cards',
      name: 'Gift Cards',
      components: { default: GiftCards }
    },
    {
      path: '/purchases/gift-cards/settings',
      name: 'Gift Cards',
      components: { default: GiftCardType }
    },
    {
      path: '/purchases/transactions/details',
      name: 'Transaction Details',
      components: { default: TransactionDetails }
    },
    {
      path: '/purchases/promos',
      name: 'Promo Codes',
      components: { default: PromoCodes }
    },
    {
      path: '/purchases/settings',
      name: 'Payment Settings',
      components: { default: PaymentGateways }
    },
    {
      path: '/purchases/carts',
      name: 'Carts',
      components: { default: ActiveCarts }
    },
    {
      path: '/purchases/inventory',
      name: 'Inventory',
      components: { default: InventoryManagement }
    },
  ]
};

let checklistMenu = {
  path: '/checklists',
  component: DashboardLayout,
  redirect: '/checklists/new',
  name: 'Checklists',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'settings',
      name: 'Create Checklist',
      components: { default: ChecklistSettings }
    },
    {
      path: 'edit',
      name: 'Edit Checklist',
      components: { default: ChecklistDetails }
    },
    {
      path: 'new',
      name: 'Checklists',
      components: { default: Checklists }
    },
    {
      path: 'active',
      name: 'Checklists',
      components: { default: ActiveChecklist }
    },
    {
      path: 'history',
      name: 'Checklist History',
      components: { default: ChecklistHistory }
    },
  ]
}

let gamesMenu = {
  path: '/games',
  component: DashboardLayout,
  redirect: '/games/tracker',
  name: 'Games',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'tracker/start',
      name: 'Game Tracker',
      components: { default: StartNewForm }
    },
    {
      path: 'tracker/select',
      name: 'Game Tracker',
      components: { default: GameTrackerSelect }
    },
    {
      path: 'tracker/settings',
      name: 'Game Tracker Settings',
      components: { default: GameTrackerSettingsTemplate }
    },
    {
      path: 'tracker',
      name: 'Game Tracker',
      components: { default: GameTracker }
    },
    {
      path: 'settings',
      name: 'Game Settings',
      components: { default: GameSetup }
    },
    {
      path: 'create',
      name: 'Create Game',
      components: { default: AddGame }
    },
    {
      path: 'edit',
      name: 'Edit Game',
      components: { default: EditGame }
    },
    {
      path: 'history',
      name: 'Game History',
      components: { default: GameInstances }
    },
    {
      path: 'history/instance',
      name: 'Game Data',
      components: { default: GameInstance }
    },
    {
      path: 'clues',
      name: 'Hints',
      components: { default: Clues }
    },
    {
      path: 'clues/select',
      name: 'Select Game',
      components: { default: GameSelectGMView }
    },
    {
      path: 'clues/configure',
      name: 'Configure Hints',
      components: { default: ConfigureClues }
    },
    {
      path: 'clues/configure/display',
      name: 'Configure Display',
      components: { default: DisplayConfiguration }
    },
    {
      path: 'clues/history',
      name: 'Hint History',
      components: { default: ClueHistory }
    },
  ]
};

let mailMenu = {
  path: '/mail',
  component: DashboardLayout,
  redirect: '/mail/contacts',
  name: 'Mail',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/mail/automated/settings',
      name: 'Automated Emails',
      components: { default: AutomatedEmails }
    },
    {
      path: 'contacts',
      name: 'Contacts',
      components: { default: Customers }
    },
    {
      path: 'create',
      name: 'Create email',
      components: { default: CreateEmail }
    },
    {
      path: 'groups',
      name: 'Email groups',
      components: { default: EmailGroups }
    }
  ]
};

let photosMenu = {
  path: '/photos',
  component: DashboardLayout,
  redirect: '/photos/new',
  name: 'Photos',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'new',
      name: 'Photos',
      components: { default: TakePhotos }
    },
    {
      path: 'settings/',
      name: 'Photo Settings',
      components: { default: PhotoSettingsTemplate }
    },
    {
      path: 'overlays',
      name: 'Photo Overlays',
      components: { default: PhotoOverlays }
    }
  ]
};

let waiverMenu = {
  path: '/waivers',
  component: DashboardLayout,
  redirect: '/waivers/history',
  name: 'Waiver',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'history',
      name: 'Waivers',
      components: { default: Waivers }
    },
    {
      path: 'settings',
      name: 'Waivers',
      components: { default: WaiverTemplate }
    }
  ]
};

let playerMenu = {
  path: '/customers',
  component: DashboardLayout,
  redirect: '/customers/all',
  name: 'Customers',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'details',
      name: 'Customer Details',
      components: { default: PlayerInfo }
    },
    {
      path: 'all',
      name: 'Customers',
      components: { default: Players }
    }
  ]
};

let surveysMenu = {
  path: '/surveys',
  component: DashboardLayout,
  redirect: '/surveys',
  name: 'Surveys',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/surveys/view',
      name: 'Surveys',
      components: { default: Surveys }
    },
    {
      path: '/surveys/edit',
      name: 'Surveys',
      components: { default: EditSurvey }
    },
    {
      path: '/surveys/results',
      name: 'Surveys',
      components: { default: SurveyHistory }
    },
    {
      path: '/surveys/history/response',
      name: 'Surveys',
      components: { default: SurveyResponse }
    },
    {
      path: '/surveys/history/question',
      name: 'Surveys',
      components: { default: SurveyQuestionResponses }
    }
  ]
};

let settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/profile',
  name: 'Settings',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'user-management',
      name: 'User Management',
      components: { default: UserManagement }
    },
    {
      path: 'user-invite',
      name: 'User Management',
      components: { default: InviteUser }
    },
    {
      path: 'roles',
      name: 'User Roles',
      components: { default: UserRoles }
    },
    {
      path: 'roles/edit',
      name: 'User Roles',
      components: { default: EditRole }
    },
    {
      path: 'company',
      name: 'Company Settings',
      components: { default: CompanySettings }
    },
    {
      path: 'group',
      name: 'Company Settings',
      components: { default: GroupSettings }
    },
    {
      path: 'profile',
      name: 'My Profile',
      components: { default: MyProfile }
    },
    {
      path: 'deactivated-users',
      name: 'User Management',
      components: { default: DeactivatedUsers }
    },
    {
      path: 'migration',
      name: 'Data Migration',
      components: { default: Migration }
    },
    {
      path: 'migration/log',
      name: 'Data Migration',
      components: { default: MigrationLog }
    },
    {
      path: 'billing',
      name: 'Billing',
      components: { default: BillingPage }
    },
    {
      path: 'logs',
      name: 'Event Logs',
      components: { default: EventLogs }
    },
  ]
};

let betasMenu = {
  path: '/beta',
  component: DashboardLayout,
  redirect: '/beta/changelog',
  name: 'Beta',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'changelog',
      name: 'Changelog',
      components: { default: Changelog }
    },
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/settings/profile',
    name: 'Home',
    meta: { requiresAuth: true }
  },
  {
    path: '/email/preview',
    name: 'Preview Email',
    components: { default: PreviewEmail },
    meta: { requiresAuth: true }
  },
  {
    path: '/clues/display',
    name: 'ClueDisplay',
    components: { default: ClueDisplay },
    meta: { requiresAuth: false }
  },
  {
    path: '/clues/select',
    name: 'GameSelect',
    components: { default: GameSelect },
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/game/leaderboard/fullscreen',
    name: 'Leaderboard',
    components: { default: Leaderboard },
    meta: { requiresAuth: true }
  },
  dashboardMenu,
  bookingsMenu,
  purchasesMenu,
  gamesMenu,
  settingsMenu,
  surveysMenu,
  mailMenu,
  waiverMenu,
  photosMenu,
  authPages,
  betasMenu,
  playerMenu,
  checklistMenu,
  { path: '/forbidden', component: Unauthorized },
  { path: '*', component: NotFound }
];

export default routes;
