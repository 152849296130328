<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Create and clear schedule button -->
  <div class="row mb-2">
    <div class="col">
      <base-button v-on:click="createNewReport()" type="primary" >
        <i class="tim-icons icon-simple-add mr-2"></i> Create custom report</base-button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <card>
        <h4 class="card-title">Reports</h4>
        <!--   No data panel -->
        <div v-if="reports.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No reports have been created.</h4>
          </div>
        </div>

        <b-table v-if="reports.length > 0" striped hover :items="reports"
                 :fields="['name', 'type', 'date_range', 'created_by', 'last_generated_by', 'actions']">
          <template v-slot:cell(name)="row">
            <p>{{row.item.name}}</p>
            <small>{{row.item.description}}</small>
          </template>
          <template v-slot:cell(type)="row">
            <badge type="primary">{{row.item.report_type}}</badge>
          </template>
          <template v-slot:cell(date_range)="row">
            <span v-if="!row.item.is_date_range">All time</span>
            <span v-if="row.item.is_date_range">
              {{getFormattedDate(row.item.report_start_date)}} - {{getFormattedDate(row.item.report_end_date)}}
            </span>
          </template>
          <template v-slot:cell(created_by)="row">
            <p>{{row.item.first_name}} {{row.item.last_name}}</p>
            <small>{{getLocalDate(row.item.created_date)}}, {{getLocalTime(row.item.created_date)}}</small>
          </template>
          <template v-slot:cell(last_generated_by)="row">
            <p v-if="row.item.last_run_date">{{row.item.ran_by}}</p>
            <small v-if="row.item.last_run_date">{{getLocalDate(row.item.last_run_date)}}, {{getLocalTime(row.item.last_run_date)}}</small>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(actions)="row">
            <el-tooltip content="Delete report"
                        effect="light"
                        :open-delay="300"
                        placement="top">
              <base-button
                @click.native="deleteReportPrompt(row.item)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon
              ><i class="tim-icons icon-trash-simple"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip content="Edit report"
                        effect="light"
                        :open-delay="300"
                        placement="top">
              <base-button
                @click.native="editReport(row.item)"
                class="like btn-link"
                type="warning"
                size="sm"
                icon
              ><i class="tim-icons icon-pencil"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip content="Run report"
                        effect="light"
                        :open-delay="300"
                        placement="top">
              <base-button
                @click.native="runReport(row.item)"
                class="like btn-link"
                type="success"
                size="sm"
                icon
              ><i class="tim-icons icon-triangle-right-17"></i>
              </base-button>
            </el-tooltip>
          </template>
        </b-table>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import { BTable } from 'bootstrap-vue'
import Badge from "@/components/Badge";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";

export default {
  name: "ReportBuilder",
  components: {BTable, Badge},
  data() {
    return {
      loading: false,
      reports: [],
      report_types: [],
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
    }
  },
  methods: {
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    deleteReportPrompt(item){
      swal({
        title: 'Are you sure?',
        text: `This report will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((response)=> {
        if(response.value == true){
          this.deleteReport(item);
        }
      })
    },
    async deleteReport(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Report has been deleted', 'success')
          this.getReports()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    editReport(item){
      this.$router.push('/analytics/reports/edit?id=' + item.id)
    },
    createNewReport(){
      this.$router.push('/analytics/reports/new')
    },
    runReport(item){
      this.$router.push('/analytics/reports/run?id=' + item.id)
    },
    async getReports(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports'

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.reports = response.data.reports
          this.report_types = response.data.report_types
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.getReports()
  }
}
</script>

<style scoped>

</style>
