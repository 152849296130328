<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/checklists/new">Checklists</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{ checklist.name }}</span>
      </breadcrumb-item>
    </breadcrumb>

    <loading-panel :loading="loading"></loading-panel>
    <div class="row">
      <div class="col-lg-5" >
        <card>
          <div class="row">
            <div class="col-lg-10 col-9">
              <h4 class="card-title">{{checklist.name}}
                <badge type="primary" class="ml-2">{{checklist.game_name}}</badge>
              </h4>
              <small>{{checklist.description}}</small>
            </div>

            <div class="col text-right">
              <base-button v-if="selectedView === 'List'"
                @click.native="toggleView()"
                simple
                size="sm"
                type="primary"
                           class="btn btn-link"
                           icon
              ><i class="tim-icons icon-bullet-list-67"></i>
              </base-button>
              <base-button v-if="selectedView === 'Card'"
                           @click.native="toggleView()"
                           simple
                           size="sm"
                           type="primary"
                           class="btn btn-link"
                           icon
              ><i class="tim-icons icon-double-right"></i>
              </base-button>
            </div>
          </div>

          <div class="mt-3" v-if="noGuideAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">A reset guide has not been created for {{game.name}}</h4>
            </div>
          </div>

          <div v-if="noGuideAvailable === false" class="mt-3">
            <b-table v-if="selectedView === 'Card'" striped :fields="fields" :items="steps">
              <template v-slot:cell(completed)="row">
                <base-checkbox class="ml-3" v-model="resetSteps[row.index]">
                </base-checkbox>
<!--                {{row.item}}-->
              </template>
              <template v-slot:cell(step)="row">
                <p> {{row.item.name}}  <span style="color: red" v-if="!row.item.is_optional">*</span>
                </p>
                <small>{{row.item.step_description}}</small>
              </template>
            </b-table>
            <base-button v-on:click="finishReset()" v-if="selectedView === 'Card'" type="primary">Complete checklist</base-button>

            <div v-if="selectedView === 'List'">
              <b-progress :max="totalSteps" :value="steps[currentStep].position" variant="primary" class="mt-3"></b-progress>

              <div class="row mb-2 justify-content-between">
                <div class="col-lg-8">
                  <h4 class="mt-4">{{steps[currentStep].name}}
                    <span style="color: red" v-if="!steps[currentStep].is_optional">*</span>
                  </h4>
                  <p>{{steps[currentStep].step_description}} </p>
                </div>
                <div class="mt-4 text-center">
                  <img style="width:95%" v-if="steps[currentStep].img_url" :src="steps[currentStep].img_url">
                </div>
              </div>

              <base-button class="mr-2" v-if="currentStep > 0" simple v-on:click="previousStep()" type="primary">Previous</base-button>
              <base-button  v-if="currentStep < totalSteps-1" v-on:click="nextStep()" type="primary">Next</base-button>
              <base-button class="ml-2" v-if="currentStep == totalSteps-1" v-on:click="finishReset()" type="primary">Complete checklist</base-button>

            </div>
          </div>

        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import {API_LOCATION} from "@/api-config";
const axios = require('axios')
import { BFormInput, BTable, BProgress } from 'bootstrap-vue'
import swal from 'sweetalert2';
import Badge from "@/components/Badge";
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "ActiveChecklist",
  data() {
    return {
      loading: false,
      checklist: '',
      steps: [],
      reset: [0],
      fields: ['completed', 'step'],
      resetSteps: [],
      selectedView: 'List',
      totalSteps: 0,
      currentStep: 0,
      noGuideAvailable: false,
    }
  },
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BFormInput,
    BTable,
    BProgress,
    Badge
  },
  beforeDestroy() {
    localStorage.removeItem('checklist_start')
  },
  methods:{
    getGameName(id){
      if(!id){
        return '-'
      }
      return _.find(this.games, { id: id }).name
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async finishReset() {
      this.resetSteps[this.currentStep] = true
      //check optional steps
      for(let n in this.steps){
        if(!this.steps[n].is_optional && !this.resetSteps[n]){
          swal('Oops!', 'You have not completed all required steps', 'error');
          return
        }
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let checklistId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + checklistId + '/instances'

      let data = {
        "game_instance_id": this.$route.query.gid ? this.$route.query.gid : null,
        "game_master_id": JSON.parse(localStorage.getItem('profile')).id,
        "start_time": localStorage.getItem('checklist_start')
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal('Good job!', 'Your reset has been recorded!', 'success');
          this.$router.push('/checklists/history?count=10&page=1')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    nextStep() {
      this.resetSteps[this.currentStep] = true
      this.currentStep += 1
    },
    previousStep() {
      this.resetSteps[this.currentStep] = false
      this.currentStep -= 1
    },
    toggleView(){
      if(this.selectedView === 'List'){
        this.selectedView = 'Card'
      } else {
        this.selectedView = 'List'
      }
    },
    async getChecklist(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let checklistId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + checklistId
      this.axios.get(url, config)
        .then(response => {
          this.checklist = response.data.checklist
          this.steps = response.data.steps
          this.steps.sort((a, b) => (a.position > b.position) ? 1 : -1)
          this.totalSteps = this.steps.length
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted () {
    this.getChecklist()
  }
}
</script>

<style scoped>
#loading {
  min-height: 1000px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #6aff8d !important;
}

.el-loading-mask {
  background: rgba(0, 0, 0, 0.7) !important;
}

</style>
