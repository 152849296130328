<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/settings/user-management">Users</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Invite user</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="col-md-12 col-sm-12 col-12">
      <card class="card-user">
        <p class="card-text"></p>
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-two"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
          <h3>Invite User</h3>
        </div>
        <div class="card-description">
          <div class="row justify-content-center">
            <div class="col-md-5 col-lg-5 col-sm-6 col-10">
              <p>Adding a user will send the invitation to the email you specify. The user will be required to complete the registration process.</p>
              <base-input class="mt-3" type="email" placeholder="Email" v-model="email"></base-input>
              <base-input>
                <el-select
                  v-model="selectedRole"
                  class="select-primary"
                  name="role"
                  placeholder="Role"
                  value-key="id"
                >
                  <el-option
                    v-for="(role, i) in roles"
                    class="select-primary"
                    :label="role.name"
                    :value="{id: role.id, name: role.name, default_role: role.default_role}"
                    :key="'R' + i"
                  >
                    <div class="row">
                      <div class="col">
                        {{ role.name }}
                      </div>
                      <div class="col text-right">
                        <badge class="ml-2" v-if="role.default_role" type="default">default</badge>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </base-input>
              <base-input>
                <el-select multiple
                  v-model="selectedGroup"
                  class="select-primary"
                  name="group"
                  placeholder="Group membership"
                >
                  <el-option
                    v-for="group in groups"
                    class="select-primary"
                    :label="group.name"
                    :value="group.id"
                    :key="group.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>

        <div slot="footer" class="button-container d-none d-sm-block">
          <base-button type="secondary" v-on:click="cancelInvite()">
            Cancel
          </base-button>
          <base-button v-on:click="createUser()" type="primary" class="ml-3">
          Invite User
          </base-button>
        </div>
        <div slot="footer" class="button-container d-block d-sm-none">
          <base-button type="secondary" v-on:click="cancelInvite()" size="sm" simple>
            Cancel
          </base-button>
          <base-button v-on:click="createUser()" type="primary" class="ml-2" size="sm" simple>
            Invite User
          </base-button>
        </div>
      </card>

    </div>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui';
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import swal from 'sweetalert2'
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
  import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
  import Badge from "@/components/Badge";

  const axios = require('axios')

  export default {
    name: "InviteUser",
    components: {
      BreadcrumbItem,
      Breadcrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      Badge
    },
    data() {
      return {
        loading: false,
        selectedRole: '',
        roles: '',
        selectedGroup: '',
        groups: '',
        email: ''
      }
    },
    methods: {
      cancelInvite() {
        this.$router.push('/settings/user-management')
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGroups() {
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/groups'

        axios.get(url, config)
          .then(response => {
            this.groups = response.data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async getRoles() {
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/roles'

        axios.get(url, config)
          .then(response => {
            this.roles = response.data.default_roles.concat(response.data.roles)
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async createUser() {
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/users'
        let data = {
          email: this.email,
          default_role: this.selectedRole.default_role ? 1 : 0,
          role_id: this.selectedRole.id,
          company_groups: this.selectedGroup
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            swal({
              title: 'Success',
              text: "User has been invited!",
              type: 'success',
              confirmButtonText: 'Ok'
            }).then((response)=> {
              if(response.value == true){
                this.$router.push('/settings/user-management')
              }
            })
          })
          .catch(error => {
            this.loading = false
            if(error.response.data == 'User already exists.'){
              swal({
                title: 'Error',
                text: "User already exists!",
                type: 'error',
                confirmButtonText: 'Try again'
              })
            } else if(error.response.status == 401){
              this.$router.push('/forbidden')
            }  else if(error.response.data.includes('email')){
              swal('Ooops', 'The email is invalid', 'error');
            } else if(error.response.data.includes('hasCompanyGroups')){
              swal('Ooops', 'A user must be assigned to at least one group', 'error');
            } else if(error.response.data.includes('roleId')){
              swal('Ooops', 'A user must have a role', 'error');
            }
          })
      },

    },
    mounted () {
      this.getRoles()
      this.getGroups()
    }
  }
</script>

<style scoped>

</style>
