<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/surveys/view">Surveys</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ survey.name }}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col-12">
      <card>
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + 'j'"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">
          <div class="mt-3" v-if="survey_questions.length <= 0 ">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No surveys have been filled out yet.</h4>
            </div>
          </div>
<!--          Mobile-->
          <div class="d-block d-sm-none">
            <div v-if="survey_questions.length > 0" class="row table-content">
              <div class="col">
                <b-table hover :items="survey_questions"
                         :fields="['question']">
                  <template v-slot:cell(question)="row">
                    <div class="row">
                      <div class="col">
                        <span style="font-size: large">{{ row.item.question }}</span>
                        <i style="cursor: pointer" v-on:click="viewQuestionResponses(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                      </div>
                      <div class="col-4 text-right">
                        <badge class="ml-2" type="danger" v-if="!row.item.active">inactive</badge>
                        <badge class="ml-2">{{ row.item.type }}</badge>
                      </div>
                    </div>

                    <div v-if="row.item.type === 'Input'">Response count: {{ row.item.input_count }}</div>
                    <div v-if="row.item.type === 'Rating'">Average rating:
                      <span v-if="row.item.rating_average">{{ row.item.rating_average.toFixed(1) }}</span>
                      <span v-else>-</span>
                    </div>
                    <div v-if="row.item.type === 'Checkbox'">
                      <span v-if="!row.item.checkbox_count">-</span>
                      <div v-for="(c, i) in JSON.parse(row.item.checkbox_count)" :key="'C' + i">
                        {{c.response}}: {{c.count}}
                      </div>
                    </div>
                    <div v-if="row.item.type === 'Dropdown'">
                      <span v-if="!row.item.dropdown_count">-</span>
                      <div v-for="(d, i) in JSON.parse(row.item.dropdown_count)" :key="'D' + i">
                        {{d.response}}: {{d.count}}
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <!--        Desktop-->
          <div class="d-sm-block d-none">
            <div v-if="survey_questions.length > 0" class="row table-content">
              <div class="col">
                <b-table hover :items="survey_questions"
                         :fields="['question', 'type', 'response_summary', 'actions']">
                  <template v-slot:cell(question)="row">
                    <span>{{ row.item.question }}</span>
                    <badge class="ml-2" type="danger" v-if="!row.item.active">inactive</badge>
                  </template>
                  <template v-slot:cell(type)="row">
                    <badge>{{ row.item.type }}</badge>
                  </template>
                  <template v-slot:cell(response_summary)="row">
                    <span v-if="row.item.type === 'Input'">Response count: {{ row.item.input_count }}</span>
                    <span v-if="row.item.type === 'Rating'">Average rating:
                      <span v-if="row.item.rating_average">{{ row.item.rating_average.toFixed(1) }}</span>
                      <span v-else>-</span>
                    </span>
                    <span v-if="row.item.type === 'Checkbox'">
                      <span v-if="!row.item.checkbox_count">-</span>
                      <div v-for="(c, i) in getAggregates(row.item.checkbox_count)" :key="'C' + i">
                        {{c.response}}: {{c.count}}
                      </div>
                    </span>
                    <span v-if="row.item.type === 'Dropdown'">
                      <span v-if="!row.item.dropdown_count">-</span>
                      <div v-for="(d, i) in getAggregates(row.item.dropdown_count)" :key="'D' + i">
                        {{d.response}}: {{d.count}}
                      </div>
                    </span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item" href="#" @click="viewQuestionResponses(row.item)">
                            <i class="tim-icons icon-notes mr-2 text-info"></i> View all responses for this question
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>


        </div>

        <div v-if="activeTabIndex === 1">
          <div class="d-block d-sm-none">
            <div class="row justify-content-between">
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row justify-content-between" v-show="show_filters">
              <div class="col-8">
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer name or email</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 400px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Customer name or email"
                            v-model="filters.customer.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>


                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container"  style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select multiple
                                       v-model="filters.game.value"
                                       class="select-primary"
                                       name="game"
                                       placeholder="Game"
                                       value-key="id"
                            >
                              <el-option
                                v-for="(game, i) in games"
                                class="select-primary"
                                :label="game.name"
                                :value="{ id: game.id, name: game.name}"
                                :key="'GG' + i"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game master name</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 400px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Game master name"
                            v-model="filters.game_master.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game_master)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Game master <span v-if="filters.game_master.actual">: {{filters.game_master.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by date range</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.submitted_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.submitted_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.submitted_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.submitted_day, filters.submitted_range)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Time submitted
                      <span v-if="filters.submitted_day.actual">: within {{filters.submitted_day.actual}} days</span>
                      <span v-if="filters.submitted_range.actual_from">:
                          between {{filters.submitted_range.actual_from}} and {{filters.submitted_range.actual_to}}
                        </span>
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                </base-input>
              </div>
            </div>
          </div>


          <div class="d-none d-sm-block">
            <!--          <div class="row justify-content-between">-->
            <!--            <div class="col">-->
            <!--              <h4 slot="header" class="card-title">{{ survey.name }}</h4>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        Filters-->
            <div class="row justify-content-between">
              <div class="col-8">
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer name or email</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 400px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Customer name or email"
                            v-model="filters.customer.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container"  style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select multiple
                                       v-model="filters.game.value"
                                       class="select-primary"
                                       name="game"
                                       placeholder="Game"
                                       value-key="id"
                            >
                              <el-option
                                v-for="(game, i) in games"
                                class="select-primary"
                                :label="game.name"
                                :value="{ id: game.id, name: game.name}"
                                :key="'GG' + i"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" simple size="sm" type="primary">
                      Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game master name</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 400px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Game master name"
                            v-model="filters.game_master.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game_master)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" simple size="sm" type="primary">
                      Game master <span v-if="filters.game_master.actual">: {{filters.game_master.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by date range</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.submitted_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.submitted_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.submitted_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.submitted_day, filters.submitted_range)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getSurveyHistory(perPage, getOffset())">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Time submitted
                      <span v-if="filters.submitted_day.actual">: within {{filters.submitted_day.actual}} days</span>
                      <span v-if="filters.submitted_range.actual_from">:
                          between {{filters.submitted_range.actual_from}} and {{filters.submitted_range.actual_to}}
                        </span>
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>


                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Display the following columns</h3>
                      <div class="pop popover-body">
                        <div class="row" v-for="(q, i) in survey_questions" :key="'Q' + i">
                          <div class="col" >
                            <base-checkbox v-model="q.selected" v-on:input="updateColumnViews(q)">{{ q.question }}

                            </base-checkbox>
                            <!--                    <i class="tim-icons icon-minimal-down text-primary"></i>-->
                          </div>

                        </div>
                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" simple size="sm" type="primary">
                      Questions <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="col text-right">
                <base-button icon class="btn-link text-white" v-if="hasPermissions('download_data')" v-on:click="downloadCSV()">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="getSurveyHistory(perPage, getOffset())"
                >
                  <el-option
                    class="select-primary"
                    v-for="(item, index) in perPageOptions"
                    :key="'B' + index"
                    :label="item.toString()"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>


          <div class="mt-3" v-if="survey_history.length <= 0 ">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No surveys have been filled out yet.</h4>
            </div>
          </div>

          <!--        Mobile-->
          <div class="d-block d-sm-none">
            <div v-if="survey_history.length > 0" class="row table-content">
              <div class="col">
                <b-table hover :items="survey_history"
                         :current-page="currentPage"
                         :fields="['surveys']">
                  <template v-slot:head(surveys)="row">
                    <span>Total results: {{totalRows}}</span>
                  </template>
                  <template v-slot:cell(surveys)="row">
                    <p class="text-primary">{{row.item.name}}</p>
                    <small>{{row.item.description}}</small>
                    <div v-if="!row.item.customer_first_name && !row.item.customer_last_name && !row.item.email && !row.item.customer_phone">
                      <badge>anonymous</badge>
                    </div>
                    <p v-else> Submitted by {{row.item.customer_first_name}} {{row.item.customer_last_name}}
                      <el-tooltip content="View customer"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <i v-if="row.item.email" v-on:click="viewCustomer(row.item)"
                           style="cursor: pointer" class="tim-icons el-icon-top-right text-primary"></i>
                      </el-tooltip>
                    </p>
                    <div class="row">
                      <div class="col">
                        <small>
                          <i v-if="row.item.email" class="tim-icons icon-email-85"></i> {{row.item.email}}
                          <i v-if="row.item.customer_phone" class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                        </small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <small>Submitted on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="row.item.game">
                      <div class="col">
                        <div>
                          {{row.item.game}}
                          <i v-if="row.item.game_instance_id" style="cursor: pointer" v-on:click="viewGameInstance(row.item.game_instance_id)"
                             class="tim-icons el-icon-top-right text-primary"></i>
                        </div>
                        <small v-if="row.item.booking_date && row.item.start_time">
                          <i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(row.item.booking_date)}}
                          <i class="tim-icons el-icon-alarm-clock ml-2"></i> {{getTime(row.item.start_time)}}
                        </small>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="row.item.game_master">
                      <div class="col">
                        <span>Game master: {{row.item.game_master}}</span>
                      </div>
                    </div>

                    <div
                      class="btn-group btn-group-toggle mt-3 mb-2"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%"
                    >
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button
                          @click.native="deleteSurveyResponsePrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                      <label class="btn btn-sm btn-primary btn-simple">
                        <base-button
                          @click.native="viewSurveyResponse(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-notes"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
                <b-pagination v-on:input="getSurveyHistory(perPage, getOffset())"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>

          <!--        Desktop-->
          <div class="d-sm-block d-none">
            <div v-if="survey_history.length > 0" class="row table-content">
              <div class="col">
                <b-table hover :items="survey_history"
                         :current-page="currentPage"
                         :fields="surveyResponderFields">
                  <template v-slot:cell()="row">
                    <span>{{ getAnswer(JSON.parse(row.item.questions), row.field.key) }}</span>
                  </template>
                  <template v-slot:cell(submitted_by)="row">
                    <div v-if="!row.item.customer_first_name && !row.item.customer_last_name && !row.item.email && !row.item.customer_phone">
                      <badge>anonymous</badge>
                    </div>
                    <p>{{row.item.customer_first_name}} {{row.item.customer_last_name}}
                      <el-tooltip content="View customer"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <i v-if="row.item.email" v-on:click="viewCustomer(row.item)"
                           style="cursor: pointer" class="tim-icons el-icon-top-right text-primary"></i>
                      </el-tooltip>
                    </p>
                    <small>
                      <i v-if="row.item.email" class="tim-icons icon-email-85"></i> {{row.item.email}}
                      <i v-if="row.item.customer_phone" class="tim-icons el-icon-mobile-phone"></i> {{row.item.customer_phone}}
                    </small>
                  </template>
                  <template v-slot:cell(game)="row">
                    <span v-if="!row.item.game">-</span>
                    <div v-if="row.item.game">
                     {{row.item.game}}
                        <i v-if="row.item.game_instance_id" style="cursor: pointer" v-on:click="viewGameInstance(row.item.game_instance_id)"
                           class="tim-icons el-icon-top-right text-primary"></i>
                    </div>
                    <div v-if="row.item.booking_date && row.item.start_time">
                      <i class="tim-icons icon-calendar-60 mr-1"></i> {{getFormattedDate(row.item.booking_date)}}
                      <i class="tim-icons el-icon-alarm-clock ml-2"></i> {{getTime(row.item.start_time)}}
                    </div>
                  </template>
                  <template v-slot:cell(game_master)="row">
                    <span v-if="!row.item.game_master">-</span>
                    <span v-else>{{row.item.game_master}}</span>
                  </template>
                  <template v-slot:cell(time_submitted)="row">
                    <span>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2" href="#" @click="viewSurveyResponse(row.item)">
                            <i class="tim-icons icon-notes mr-2 text-info"></i> View customer's survey
                          </a>
                          <hr>
                          <a class="dropdown-item mb-2" @click="deleteSurveyResponsePrompt(row.item)" href="#">
                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete this survey
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
                <b-pagination v-on:input="getSurveyHistory(perPage, getOffset())"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import _ from "lodash";
import swal from "sweetalert2";
import {BTable, BPagination, BRow, BCol} from "bootstrap-vue";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import axios from "axios";
import Badge from "@/components/Badge";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";

export default {
  name: "SurveyHistory",
  components: {
    BTable,
    BPagination,
    Badge,
    Breadcrumb, BreadcrumbItem,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BRow,
    BCol
  },
  data(){
    return {
      selectedDateRangeOption: 'radio0',
      loading: false,
      show_filters: false,
      survey_history: [],
      survey_questions: [],
      survey: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [5, 10, 25, 50],
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      day_of_week: '',
      date_format_cal: '',
      activeTabIndex: 0,
      j: 0,
      surveyResponderFields: ['submitted_by', 'game', 'game_master', 'time_submitted', 'actions'],
      games: [],
      filters: {
        customer: {
          value: '',
          actual: ''
        },
        submitted_day: {
          value: '',
          actual: '',
        },
        submitted_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
        game: {
          value: '',
          actual: ''
        },
        game_master: {
          value: '',
          actual: ''
        },
      },
    }
  },
  computed: {
    categories() {
      return [{ name: 'All questions', icon: 'tim-icons icon-notes', active: true },
        { name: 'Responders', icon: 'tim-icons icon-chart-bar-32', active: false }
      ];
    },
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAnswer(responses, question){
      let response = _.find(responses, { question: question})
      if(response){
        if(response.type === 1){
          if(response.response && JSON.parse(response.response).length > 0){

            return JSON.parse(response.response).join(', ')
          }
          return '-'
        }
        return response.response
      }
      return '-'
    },
    updateColumnViews(question){
      if(question.selected){
        let insertPosition = this.surveyResponderFields.length - 1; // Calculate the second-to-last position
        this.surveyResponderFields.splice(insertPosition, 0, question.question);
      } else {
        this.surveyResponderFields = this.surveyResponderFields.filter(item => item !== question.question);
      }
    },
    viewGameInstance(id){
      this.$router.push({ path: '/games/history/instance', query: { id: id }})
    },
    getAggregates(item){
      console.log(item)
      return _.orderBy(JSON.parse(item), 'count', 'desc')
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    viewCustomer(user){
      this.$router.push({ path: '/customers/details', query: { id:  user.end_user_id }})
    },
    async getAllSurveyQuestions(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let offset = 0
      let surveyId = this.$route.query.id
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId + '/questions?count=' + count + "&offset=" + offset
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.survey_history)
            })
            .catch(err => {
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else if(err.response.status == 500){
                swal({
                  title: 'Error',
                  html: 'The feature has not been enabled as part of your subscription plan.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else {
                swal('Error!', 'Something went wrong', 'error')
              }

            })
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    async getAllSurveys(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let offset = 0
      let surveyId = this.$route.query.id
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId + '/responders?count=' + count + "&offset=" + offset
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.survey_history)
            })
            .catch(err => {
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else if(err.response.status == 500){
                swal({
                  title: 'Error',
                  html: 'The feature has not been enabled as part of your subscription plan.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      let count = 5000
      let csvData = await this.getAllSurveys(config, count)
      this.loading = false
      let csv = 'Survey, Customer First Name, Customer Last Name, Customer Email,' +
        'Customer Phone, Date Submitted\n';
      csvData.forEach(row => {
        console.log(row)
        csv += [this.escapeCSVValue(row.name), this.escapeCSVValue(row.customer_first_name),
          this.escapeCSVValue(row.customer_last_name), this.escapeCSVValue(row.email), this.escapeCSVValue(row.customer_phone),
          JSON.stringify(this.getLocalDate(row.timestamp) + ' ' + this.getLocalTime(row.timestamp))]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'surveys.csv';
      anchor.click();
    },
    resetAll(){
      this.filters = {
        customer: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        },
        game_master: {
          value: '',
          actual: ''
        },
        survey: {
          value: '',
          actual: ''
        },
        submitted_day: {
          value: '',
          actual: '',
        },
        submitted_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
      }
      this.getSurveyHistory(this.perPage, this.getOffset())
    },
    resetField(field1, field2){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      if(field2){
        field2.actual_from = ''
        field2.value_from = ''
        field2.actual_to = ''
        field2.value_to = ''
      }
      this.getSurveyHistory(this.perPage, this.getOffset())
    },
    viewQuestionResponses(item){
      this.$router.push({ path: '/surveys/history/question', query: { id:  item.survey_id, question_id: item.id }})
    },
    viewSurveyResponse(item){
      this.$router.push({ path: '/surveys/history/response', query: { id:  item.id }})
    },
    deleteSurveyResponsePrompt(item){
      swal({
        title: 'Are you sure?',
        text: 'This will permanently delete the survey response.',
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.deleteSurveyResponse(item)
        }
      })
    },
    async deleteSurveyResponse(item){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/history/' + item.id

      this.loading = true
      this.axios.delete(url, config)
        .then(response => {
          swal('Success!', 'Survey response has been deleted.', 'success')
         this.getSurveyHistory(this.perPage, this.getOffset())
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getSurveyHistory(count, offset){

      //set customer
      this.filters.customer.actual = this.filters.customer.value
      this.filters.game_master.actual = this.filters.game_master.value
      this.filters.game.actual = this.filters.game.value.name

      let game_id = ''
      if(this.filters.game.value){
        this.filters.game.actual = this.filters.game.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.name)
          .join(', ');
        game_id = this.filters.game.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.id)
          .join(',');
      }

      let to = ''
      let from = ''
      if (this.selectedDateRangeOption === 'radio0' && this.filters.submitted_day.value !== ''){
        this.filters.submitted_range.actual_from = ''
        this.filters.submitted_range.actual_to = ''
        this.filters.submitted_range.value_from = ''
        this.filters.submitted_range.value_to = ''
        this.filters.submitted_day.actual = this.filters.submitted_day.value
        let today = new Date()
        let dt = new Date()
        dt.setDate(dt.getDate() - this.filters.submitted_day.actual)
        let userStartDate = dt.getFullYear() + "-"  + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
        let userEndDate = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        from = startLocalDate.toISOString();
        to = endLocalDate.toISOString();
      }
      if (this.selectedDateRangeOption === 'radio1' && this.filters.submitted_range.value_to !== '' && this.filters.submitted_range.value_from !== ''){
        this.filters.submitted_range.actual = ''
        this.filters.submitted_range.value = ''
        this.filters.submitted_range.actual_from = this.filters.submitted_range.value_from
        this.filters.submitted_range.actual_to = this.filters.submitted_range.value_to
        let userStartDate = this.filters.submitted_range.actual_from
        let userEndDate = this.filters.submitted_range.actual_to


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        from = startLocalDate.toISOString();
        to = endLocalDate.toISOString();
      }

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId + '/responders?count=' + count +
      '&offset=' + offset + '&customer=' + encodeURIComponent(this.filters.customer.actual)
        + '&game_master=' + encodeURIComponent(this.filters.game_master.actual)
       + '&game=' + game_id + "&to=" + to + "&from=" + from

      this.axios.get(url, config)
        .then(response => {
          this.survey_history = response.data.survey_history
          this.survey = response.data.survey
          this.totalRows = response.data.total_rows
        })
        .catch(err => {
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getSurveyQuestions(){

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId + '/questions'

      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.survey_questions = response.data.survey_history
          this.survey = response.data.survey
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted() {
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.getGames()
    this.getSurveyHistory(this.perPage, this.getOffset())
    this.getSurveyQuestions()
  }
}
</script>

<style scoped>

</style>
