/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_E8vTvIWqU",
  "aws_user_pools_web_client_id": "5v40tev3e3p8bca3ih82sq1ljv"
};


export default awsmobile;
