<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="mt-3" v-if="noDataAvailable === true">
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">No games have been set up yet.</h4>
      </div>
    </div>
    <div v-if="noDataAvailable === false" class="row mt-3">
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="game in games" :key="game.id">
        <card style="height: 19rem">
          <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
          <h4 class="card-title">Dashboard settings for <span class="text-success">{{game.name}}</span></h4>
          <div class="row mt-3">
            <div class="col">
              <base-button size="sm" simple v-on:click="leaderboardSettings(game)" class="mr-2" type="warning">
                <i class="tim-icons icon-settings"></i>Leaderboard settings</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Card} from 'src/components'
import { Auth } from 'aws-amplify'
import { API_LOCATION } from '../../api-config'
import swal from "sweetalert2";
const axios = require('axios')

export default {
  name: "SelectSettings",
  components: {
    Card,
  },
  data() {
    return {
      games: '',
      loading: false,
      noDataAvailable: false
    }
  },
  methods: {
    leaderboardSettings(game){
      localStorage.setItem('selectedGameStats', JSON.stringify(game))
      this.$router.push('/dashboard/game/leaderboard/settings')
    },
    gameAnalytics(game){
      localStorage.setItem('selectedGameStats', JSON.stringify(game))
      this.$router.push('/dashboard/game/analytics')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGames () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.games = response.data
          for (var n in this.games){
            if (this.games[n].img === null){
              this.games[n].img = 'https://console.offthecouch.io/img/placeholder.jpg'
            }
          }
          if (this.games.length <= 0){
            this.noDataAvailable = true
          }
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        })
    },
  },
  mounted () {
    this.getGames()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 200px; /* height of container */
  object-fit: contain;
}
</style>
