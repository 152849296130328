import VueRouter from 'vue-router';
import routes from './routes';
import { Auth } from 'aws-amplify'


// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user;
    Auth.currentSession().then(data => {
      if (data && data.signInUserSession) {
        user = data;
      }
      next()
    }).catch((e) => {
      next({
        path: '/login'
      });
    });
  }
  //If authenticated user goes to login or register, send them to dashboard
  if(to.matched.some(record => record.name == "Authentication")) {

    Auth.currentSession().then(data => {
      if (data && data.idToken) {
        next({
          path: '/'
        });
      }
    }).catch((e) => {
      next();
    });
  }
  next()
})

export default router;
