<template>
<div>
  <h4 class="info-text">
    Locale and formatting
  </h4>
  <div class="row justify-content-center">
    <div class="col-11 col-lg-10 col-md-8 col-sm-7">
      <div class="row">
        <div class="col-lg-4">
          <h4 class="card-title">Locale</h4>
          <small >These are regional settings for your location.</small>
        </div>
        <div class="col-lg-4">
          <base-input label="Currency">
            <el-select
                       v-model="locale.currency"
                       class="select-primary"
                       name="currency"
                       placeholder="Currency"
                       value-key="code"
            >
              <el-option
                v-for="(c, i) in currencies"
                class="select-primary"
                :label="c.currency + ' (' + c.symbol + ')'"
                :value="{code: c.code, symbol: c.symbol, currency: c.currency}"
                :key="'C' + i"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-lg-4">
          <base-input label="Timezone">
            <el-autocomplete style="width: 100%"
                             v-model="locale.timezone"
                             :fetch-suggestions="querySearch"
                             placeholder="Search"
                             :trigger-on-focus="false"
                             :value="locale.timezone"
                             @select="handleSelect"
            >
              <template #default="{ item }">
                <span>{{ item.value }} - {{item.abbr}} ({{item.offset}})</span>
              </template>
            </el-autocomplete>
          </base-input>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <h4 class="card-title">Formats</h4>
          <small>Define how dates, times, currency, and numbers will be displayed throughout the application.</small>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-4">
              <base-input label="Date format">
                <el-select
                  v-model="locale.date_format"
                  class="select-primary"
                  name="date_format"
                  placeholder="Date format"
                  value-key="format"
                >
                  <el-option
                    v-for="(d, i) in date_formats"
                    class="select-primary"
                    :label="d.example + ' (' + d.format + ')'"
                    :value="{format: d.format, example: d.example}"
                    :key="'D' + i"
                  ></el-option>
                </el-select>
              </base-input>
              <base-checkbox v-model="locale.show_day_of_week">Display day of the week</base-checkbox>
            </div>
            <div class="col-lg-4">
              <base-input label="Time format">
                <el-select
                  v-model="locale.time_format"
                  class="select-primary"
                  name="time_format"
                  placeholder="Time format"
                  value-key="format"
                >
                  <el-option
                    v-for="(f, i) in time_formats"
                    class="select-primary"
                    :label="f.example + ' (' + f.format + ')'"
                    :value="{format: f.format, example: f.example}"
                    :key="'F' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-4">
              <base-input label="First day of the week">
                <el-select
                  v-model="locale.first_day_of_week"
                  class="select-primary"
                  name="first_day_of_week"
                  placeholder="First day of the week"
                  value-key="id"
                >
                  <el-option
                    v-for="(w, i) in days_of_the_week"
                    class="select-primary"
                    :label="w.name"
                    :value="{id: w.id, name: w.name}"
                    :key="'W' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-6">
              <base-input label="Currency format">
                <el-select
                  v-model="locale.currency_format"
                  class="select-primary"
                  name="currency_format"
                  placeholder="Currency format"
                  value-key="id"
                >
                  <el-option
                    v-for="(cf, i) in currency_formats"
                    class="select-primary"
                    :label="cf.example"
                    :value="{id: cf.id, example: cf.example}"
                    :key="'CF' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input label="Number format">
                <el-select
                  v-model="locale.number_format"
                  class="select-primary"
                  name="time_format"
                  placeholder="Time format"
                  value-key="id"
                >
                  <el-option
                    v-for="(n, i) in number_formats"
                    class="select-primary"
                    :label="n.example"
                    :value="{example : n.example, id: n.id} "
                    :key="'N' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import currencies from "@/assets/currencies.json";
import date_formats from "@/assets/date_formats.json";
import time_formats from "@/assets/time_formats.json";
import days_of_the_week from "@/assets/days_of_the_week.json";
import number_formats from "@/assets/number_formats.json";
import moment from "moment-timezone";
import {Autocomplete} from "element-ui";
import _ from "lodash";

export default {
  name: "GroupLocale",
  components: {
    [Autocomplete.name]: Autocomplete,
  },
  data(){
    return {
      locale: {
        currency: {
          "country": "United States",
          "currency": "United States Dollar",
          "code": "USD",
          "symbol": "$"
        },
        timezone:  {
          "value": "Pacific Standard Time",
          "abbr": "PST",
          "offset": -8,
          "isdst": false,
          "tz_id": 7,
          "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
          "utc": [
            "America/Los_Angeles",
            "America/Tijuana",
            "America/Vancouver",
            "PST8PDT"
          ]
        },
        date_format: { example: '06/28/2021', format: 'MM/DD/YYYY'},
        currency_format: {example: "$12.34", id: 0},
        first_day_of_week: {name: "Monday", id: 1},
        time_format:  { example: '02:45 PM', format: 'hh:mm'},
        number_format: {example: "12.34", id: 0},
        show_day_of_week: 1
      },
      date_formats: date_formats.dateFormats,
      timezones: [],
      currencies: currencies.currencies,
      currency_formats: currencies.formats,
      time_formats: time_formats.timeFormats,
      days_of_the_week: days_of_the_week.daysOfTheWeek,
      number_formats: number_formats.numberFormats
    }
  },
  methods: {
    handleSelect(item){
      this.locale.timezone = item.name
      this.locale.timezone_object = item
    },
    async querySearch(queryString, cb) {
      const results = queryString
        ? this.timezones.filter(this.createFilter(queryString))
        : this.timezones
      cb(results)
    },
    createFilter(queryString) {
      return (timezone) => {
        // Normalize query for case-insensitive matching
        const lowerCaseQuery = queryString.toLowerCase();

        // Check if the query matches any field of the timezone object
        return (
          timezone.value.toLowerCase().includes(lowerCaseQuery) || // Match on timezone name
          timezone.abbr.toLowerCase().includes(lowerCaseQuery) || // Match on abbreviation
          timezone.offset.includes(lowerCaseQuery) // Match on offset
        );
      };
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.locale);
        return res;
      });
    },
    fetchTimezonesWithAbbreviations() {
      const timezones = moment.tz.names();
      const timezoneAbbreviations = timezones.map((timezone) => {
        return {
          value: timezone,
          abbr: moment.tz(timezone).format("z"), // Get the current abbreviation
          offset: moment.tz(timezone).format("Z"),
          name: timezone + ' - ' + moment.tz(timezone).format("z") + ' (' + moment.tz(timezone).format("Z") + ')'
        };
      });
      this.timezones = timezoneAbbreviations; // Update the component's data

      this.locale.timezone_object = _.find(this.timezones, {value: moment.tz.guess()});
      this.locale.timezone =  this.locale.timezone_object.value + ' - '
        + this.locale.timezone_object.abbr + ' (' + this.locale.timezone_object.offset + ')'
    }
  },
  async mounted() {
    await this.fetchTimezonesWithAbbreviations();
  }
}
</script>

<style scoped>

</style>
