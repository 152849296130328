<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/checklists/new">Checklists</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Edit {{ checklist.name }}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row">
    <div class="col-lg-4">
      <card>
        <h4 class="card-title">Edit checklist</h4>
        <div class="row">
          <div class="col">
            <base-input v-model="checklist.name" placeholder="Checklist name" label="Name"></base-input>
            <base-input label="Description">
              <el-input
                v-model="checklist.description"
                :rows="3"
                type="textarea"
                placeholder="Checklist description"
              />
            </base-input>
            <base-input label="Link to game (optional)">
              <el-select
                class="select-primary"
                name="games"
                placeholder="Game"
                v-model="selectedGame"
                value-key="id"
              >
                <el-option
                  v-for="(g, i) in games"
                  class="select-primary"
                  :label="g.name"
                  :value="{ name: g.name, id: g.id }"
                  :key="'G' + i"
                ></el-option>
              </el-select>
            </base-input>

          </div>
        </div>

        <div class="row" >
          <div class="col">
            <base-button v-on:click="cancel()" type="default" >
              Cancel</base-button>
            <base-button class="ml-2" v-on:click="updateChecklist()" type="primary" >
              Update</base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col">
      <card>
        <h4 class="card-title">Checklist steps</h4>
        <div class="row d-none d-sm-block">
          <div class="col">
            <table class='table' v-if="steps.length > 0">
              <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Description</th>
                <th scope='col'>Optional</th>
                <th scope='col'>Image</th>
                <th class="text-right" scope='col'>Actions</th>
              </tr>
              </thead>
              <tbody tag='tbody' v-model='steps' is='draggable'>
              <tr v-for='(item, index) in steps' :key="'A' + item.id" style="cursor: pointer">
                <td scope='row'>
                  <base-input>
                            <textarea class="form-control" v-model="item.name"
                                      placeholder="Name" rows="1"></textarea>
                  </base-input>
                </td>
                <td scope='row'>
                  <base-input>
                            <textarea class="form-control" name="clue" v-model="item.step_description"
                                      placeholder="Description" rows="1"></textarea>
                  </base-input>
                </td>
                <td scope='row'>
                  <base-checkbox v-model="item.is_optional">
                  </base-checkbox>
                </td>
                <td scope='row'>
                  <img v-if="item.img_url" slot="image" style="width: 50px; height:50px;" :src="item.img_url"  alt="Card image cap"/>
                  <span v-else>-</span>
                </td>
                <td scope='row' class="text-right">

                  <el-tooltip content="Delete step"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="deleteStep(index)"
                      class="like btn-link"
                      type="danger"
                      icon
                    ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Add photo"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      class="like btn-link btn-file"
                      type="success"
                      icon
                    ><i class="tim-icons icon-camera-18" style="font-size: large"></i>
                      <input type="file"
                             accept="image/*"
                             @change="handleUpload(item)"

                      />
                    </base-button>
                  </el-tooltip>
                </td>
              </tr>

              </tbody>
            </table>
            <div class="row">
              <div class="col">
                <base-button size="sm" v-on:click="addNewStepPrompt()" type="primary" >
                  <i class="tim-icons el-icon-plus"></i> Add step</base-button>
              </div>
            </div>

          </div>
        </div>
        <div class="row d-block d-sm-none">
          <div class="col">
            <table class='table' v-if="steps.length > 0">
              <thead>
              <tr>
                <th scope='col'></th>
              </tr>
              </thead>
              <tbody tag='tbody' v-model='steps' is='draggable'>
              <tr v-for='(item, index) in steps' :key="'A' + item.id">
                <td scope='row'>
                  <div class="row">
                    <div class="col-9">
                      <base-input label="Checklist name" v-model="item.name">

                      </base-input>
                    </div>
                    <div class="col text-right">
                      <el-tooltip content="Delete step"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="deleteStep(index)"
                          class="like btn-link"
                          type="danger"
                          icon
                        ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">

                      <base-input label="Checklist description">
                        <el-input
                          v-model="item.step_description"
                          :rows="3"
                          type="textarea"
                          placeholder="Checklist description"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col">
                        <base-checkbox class="d-inline-block" type="checkbox" v-model="item.is_optional"> Optional
                        </base-checkbox>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <img v-if="item.img_url" slot="image" style="width: 100%" :src="item.img_url"  alt="Card image cap"/>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col">
                        <base-button
                          class="btn-file"
                          style="width: 100%"
                          size="sm"
                          type="success"
                        >Add photo
                          <input type="file"
                                 accept="image/*"
                                 @change="handleUpload(item)"

                          />
                        </base-button>
                    </div>
                  </div>


                </td>
              </tr>

              </tbody>
            </table>
            <div class="row">
              <div class="col">
                <base-button size="sm" v-on:click="addNewStepPrompt()" type="primary" >
                  <i class="tim-icons el-icon-plus"></i> Add step</base-button>
              </div>
            </div>

          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import {Auth} from "aws-amplify";
import draggable from 'vuedraggable'
import swal from "sweetalert2";
import axios from "axios";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "ChecklistDetails",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    draggable,
  },
  data() {
    return {
      loading: false,
      checklist: '',
      editing: false,
      steps: [],
      drag: false,
      games: [],
      selectedGame: '',
      uploadUrl: ''
    };
  },
  methods: {
    deleteStep(index){
      this.steps.splice(index, 1);
    },
    addNewStepPrompt(){
      this.steps.push({ name: '', step_description: '', img_url: '', uploaded_image: '', is_optional: false})
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload an image before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async handleUpload(item){
      console.log(item)
      let file = event.target.files[0];
      item.uploaded_image = URL.createObjectURL(file);

      this.file = file
      this.$emit('change', file);
      this.loading = true
      let save = await this.saveFile()
      item.img_url = this.uploadUrl.split("?")[0]
      this.loading = false
    },
    cancel(){
      this.$router.push('/checklists/new')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updateChecklist(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let checklistId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + checklistId
      this.checklist.game_id = this.selectedGame.id
      let data = {
        checklist: this.checklist,
        steps: this.steps
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.creating = false
          swal('Success', 'Checklist has been updated', 'success')
          this.$router.push('/checklists/new')
          this.getChecklist()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getChecklist(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let checklistId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + checklistId
      this.axios.get(url, config)
        .then(response => {
          this.checklist = response.data.checklist
          this.selectedGame = {id: this.checklist.game_id}
          this.steps = response.data.steps
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },

  },
  mounted() {
    this.getGames()
    this.getChecklist()
  }
}
</script>

<style scoped>

</style>
