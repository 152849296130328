<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb v-if="new_template" style="margin-left: -15px">
      <breadcrumb-item>
        <a href="" v-on:click="new_template = false">Mass emails</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Create new email template</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--    Add template-->
    <div class="row d-sm-block d-none container">
      <base-button v-if="new_template == false" @click="createPrompt()" type="primary" >Create Email Template</base-button>
    </div>
    <!--   No data panel -->
    <div v-if="empty==true && new_template == false" class="mt-3" >
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">No data available.</h4>
      </div>
    </div>

<!--    View templates-->
    <div v-if="new_template == false && empty == false" class="row mt-3">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-for="t in templates" :key="t.id">
        <card>
          <h2 class="ml-3 mt-3">{{t.name}}</h2>
          <div class="row ml-1">
            <div class="col-9">
              <h4 slot="header" class="text-primary card-title">To</h4>
              <p class="text-white">{{t.email_group_name}}</p>
            </div>
          </div>
          <hr class="hr-primary">
          <div class="row ml-1">
            <div class="col-9">
              <h4 slot="header" class="text-primary card-title">From</h4>
              <p class="text-white">{{t.from_name}}<span v-if="t.from_name && t.replyTo">, </span>
                {{t.replyTo}}</p>
            </div>
          </div>
          <hr class="hr-primary">
          <div class="row ml-1">
            <div class="col-9">
              <h4 slot="header" class="text-primary card-title">Subject</h4>
              <p class="text-white">{{t.subject}}</p>
            </div>
          </div>
          <hr class="hr-primary">
          <div class="row ml-1">
            <div class="col-12">
              <h4 slot="header" class="text-primary card-title">Content</h4>
              <iframe style="background-color: white; width: 100%" :src="t.contentUrl"></iframe>
            </div>
          </div>
          <hr class="hr-primary">

          <div class="row ml-1">
            <div class="col-9">
              <base-button simple class="mr-3" type="primary" v-on:click="deleteTemplate(t)">Delete</base-button>
              <base-button simple class="mr-3" type="primary" v-on:click="editTemplate(t)">Edit</base-button>
<!--              <base-button class="mr-3" type="primary">Schedule</base-button>-->
              <base-button type="primary" v-on:click="sendEmails(t)">Send</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--   Create template -->
    <div v-if="new_template == true" class="row">
      <div class="col-lg-8 col-md-6 col-sm-6 col-12">
        <card>
          <h4 class="card-title">{{template_name}}</h4>
          <div class="row">
            <div class="col">
              <label style="font-size: medium">To</label>
              <el-popover trigger="hover"
                          placement="top">
                <div class="popover-body">The list of email recipients.</div>
                <base-button class="like btn-link"
                             type="info"
                             size="sm"
                             icon
                             slot="reference">
                  <i class="tim-icons el-icon-info"></i>
                </base-button>
              </el-popover>
              <base-input>
                <el-select
                  v-model="template.email_group_name"
                  class="select-primary"
                  name="email_group"
                  placeholder="Email Group"
                >
                  <el-option
                    v-for="group in email_groups"
                    class="select-primary"
                    :label="group.name"
                    :value="group.name"
                    :key="group.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label style="font-size: medium">From</label>
              <el-popover trigger="hover"
                          placement="top">
                <div class="popover-body">The specified name will appear as the name of the From field of your email. <br/>The specified email will appear in the replyTo email field.</div>
                <base-button class="like btn-link"
                             type="info"
                             size="sm"
                             icon
                             slot="reference">
                  <i class="tim-icons el-icon-info"></i>
                </base-button>
              </el-popover>
              <div class="row">
                <div class="col-4">
                  <base-input v-model="template.from_name" type="text" placeholder="Name"></base-input>
                </div>
                <div class="col-8">
                  <base-input :error="errors.email" v-model="template.replyTo" type="email" placeholder="Email"></base-input>
                </div>
              </div>


            </div>
          </div>
          <div class="row">
            <div class="col">
              <label style="font-size: medium">Subject</label>
              <base-input v-model="template.subject" type="text" placeholder="Subject"></base-input>

            </div>
          </div>

          <div class="row">
            <div class="col">
              <label style="font-size: medium">Content</label>
              <editor v-model="template.contentHtml"
                      api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                      :init="{
                     height: 500,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | image | link'
                   }"
              />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <base-button v-on:click="returnToList()" class="mr-3" simple type="primary">Cancel</base-button>
              <base-button v-if="editing == false" v-on:click="saveEmailTemplate()" class="mr-3" type="primary">Save</base-button>
              <base-button v-if="editing == true" v-on:click="updateEmailTemplate()" class="mr-3" type="primary">Save</base-button>
<!--              <base-button class="mr-3" type="primary">Schedule</base-button>-->
<!--              <base-button v-if="editing == true" v-on:click="sendEmails(template)" type="primary">Send</base-button>-->
            </div>
          </div>
        </card>
      </div>

    </div>
<!--    Create template name modal-->
    <modal :show.sync="modals.new_template"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <h4>Template name</h4>
        <base-input type="text" placeholder="Template name" v-model="template_name">

        </base-input>


        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.new_template = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="dismissModal()">Create</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.new_template = false">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="dismissModal()">Create</base-button>
        </div>
      </card>
    </modal>


  </div>
</template>

<script>
import { API_LOCATION } from '../../api-config'
import {Auth} from "aws-amplify";
import {Option, Select} from "element-ui";
import swal from "sweetalert2";
import Editor from '@tinymce/tinymce-vue'
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "CreateEmail",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    'editor': Editor
  },
  data() {
    return {
      loading: false,
      empty: false,
      email_groups: '',
      templates: '',
      modals: {
        new_template: false
      },
      new_template: false,
      editing: false,
      template_name: '',
      add_to: false,
      add_from: false,
      add_subject: false,
      add_content: false,
      errors: {
        email: ''
      },
      template: {
        email_group_name: '',
        email_group_id: '',
        from_name: '',
        replyTo: '',
        subject: '',
        content: ''
      }

    }
  },
  methods: {
    createPrompt(){
      this.modals.new_template=true
      this.template_name = ''
      this.errors.email = false
      this.template = {
        email_group_name: '',
          email_group_id: '',
          from_name: '',
          replyTo: '',
          subject: '',
          content: ''
      }
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    returnToList(){
      this.new_template = false
      this.editing = false
    },
    dismissModal(){
      this.new_template = true
      this.modals.new_template = false
      this.editing = false
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getTemplates () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/templates'
      this.axios.get(url, config)
        .then(response => {
          this.templates = response.data.email_templates
          if(this.templates.length <= 0 ){
            this.empty = true
          } else {
            this.empty = false
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteTemplate (template) {
      swal({
        title: 'Are you sure?',
        text: `The email template will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.delete(template);
        }
      })

    },
    async delete(template){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/templates/' + template.id
      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Email template has been deleted', 'success')
          this.getTemplates()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async editTemplate(template){
      this.template_name = template.name
      this.template = template
      let url = this.template.contentUrl
      this.axios.get(url)
        .then(response => {
          this.template.contentHtml = response.data
          this.editing = true
          this.new_template = true
        })
        .catch(err => {
          this.loading = false
          console.log(err)

        })

    },
    async updateEmailTemplate(){
      let selectedGroup = this.email_groups.filter(obj => {
        return obj.name === this.template.email_group_name
      })
      let error = 0
      if(this.isEmailValid(this.template.replyTo) == false) {
        this.errors.email = "You must provide a valid email address"
        error++
      }
      if(error > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/templates/' + this.template.id
      let data = {
        from_name: this.template.from_name,
        replyTo: this.template.replyTo,
        subject: this.template.subject,
        contentUrl: this.template.contentUrl,
        content: this.template.contentHtml,
        name: this.template_name,
        email_group_id: selectedGroup[0].id
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.new_template = false
          this.template_name = ''
          this.template = {
            email_group_name: '',
              email_group_id: '',
              from_name: '',
              replyTo: '',
              subject: '',
              content: ''
          }
          swal('Success!', 'Email template has been updated', 'success')
          this.getTemplates()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getEmailGroups () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups'
      this.axios.get(url, config)
        .then(response => {
          this.email_groups = response.data.email_groups
          this.loading = false
          this.getTemplates()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async sendEmails(t){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/send'
      let data = t
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          console.log(response.data)
          // this.new_template = false
          // this.getTemplates()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveEmailTemplate(){
      let selectedGroup = this.email_groups.filter(obj => {
        return obj.name === this.template.email_group_name
      })

      let error = 0
      if(this.isEmailValid(this.template.replyTo) == false) {
        this.errors.email = "You must provide a valid email address"
        error++
      }
      if(error > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/templates'
      let data = {
        from_name: this.template.from_name,
        replyTo: this.template.replyTo,
        subject: this.template.subject,
        content: this.template.contentHtml,
        contentUrl: this.template.contentUrl,
        name: this.template_name,
        email_group_id: selectedGroup[0].id
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.new_template = false
          this.template_name = ''
          this.template = {
            email_group_name: '',
            email_group_id: '',
            from_name: '',
            replyTo: '',
            subject: '',
            content: ''
          }
          swal('Success!', 'Email template has been created', 'success')
          this.getTemplates()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    }
  },
  mounted() {
    this.getTemplates()
    this.getEmailGroups()
  }
}
</script>

<style scoped>
.hr-primary{
  background-color: #7e7e7e;
}
</style>
