<template>
<div>
  <!--    Loading-->
  <loading-panel :loading="loading"></loading-panel>
  <div v-if="drafts.length > 0" class="row">
    <div :key="k" class="col-lg-9 col-md-10 col-sm-10 col-12 ml-auto mr-auto">
      <b-table striped hover :items="drafts" :fields="fields">
        <template v-slot:cell(game)="row">
          <h4 v-if="row.item.game_name">{{row.item.game_name}}
            <base-button
              @click.native="deleteDraftPrompt(row.item)"
              class="like btn-link"
              type="danger"
              size="sm"
              icon>
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </h4>
          <h4 v-else>Empty draft
            <base-button
              @click.native="deleteDraftPrompt(row.item)"
              class="like btn-link"
              type="danger"
              size="sm"
              icon>
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </h4>
          <p v-if="row.item.booking_date" style="font-weight: lighter; font-style: italic" >
            {{getFormattedDate(row.item.booking_date)}}, {{getTime(row.item.start_time)}}
          </p>
        </template>
        <template v-slot:cell(created)="row">
          <p style="font-weight: lighter; font-style: italic" >
            {{getLocalDate(row.item.time_created)}}, {{getLocalTime(row.item.time_created)}}
          </p>
        </template>
        <template v-slot:cell(last_updated)="row">
          <p v-if="row.item.time_updated" style="font-weight: lighter; font-style: italic" >
            {{getLocalDate(row.item.time_updated)}}, {{getLocalTime(row.item.time_updated)}}
          </p>
          <p v-else style="font-weight: lighter; font-style: italic" >
            -
          </p>
        </template>
        <template v-slot:cell(select)="row">
          <div class="row">
            <div
              class="form-check form-check-radio form-check-in-table"
            >
              <label class="form-check-label">
                <input
                  v-model="selectedDraft"
                  v-on:change="setDraft()"
                  class="form-check-input" :name="row.item.id" :value="row.item"
                  type="radio"
                />
                <slot></slot> <span class="form-check-sign"></span>
              </label>
            </div>
          </div>
        </template>
      </b-table>
      <p v-if="error" class="text-danger">You must select a draft or create a new one</p>
    </div>

  </div>
  <div v-if="drafts.length > 0" class="mt-3" >

  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import { BTable } from 'bootstrap-vue'
import {time, formattedDate, localDate, localTime, dayOfWeek} from "@/plugins/dateFormatter";


export default {
  name: "StartNewForm",
  data() {
    return {
      loading: false,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      k: 0,
      drafts: [],
      error: false,
      selectedDraft: '',
      fields: ['game', 'created', 'last_updated', 'select'],
      model: {
        draft: {
          id: '',
          name: ''

        }
      },
    };
  },
  components:{
    BTable
  },
  methods: {
    setDraft(){
      console.log("this.selectedDraft")
      console.log(this.selectedDraft)

      if(this.selectedDraft){
        this.model.draft = this.selectedDraft
        localStorage.setItem('draft', JSON.stringify(this.selectedDraft))
        localStorage.setItem('gameInstanceId', this.selectedDraft.id)
      }
    },
    nextStep(){
      return this.selectedDraft.current_step - 1
    },
    getLocalDate(d){
      if(d == undefined){
        return
      }
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      return this.getDayOfWeek(form) + localDate(this.date_format, d)
    },
    getLocalTime(d){
      if(!d){
        return
      }
      return localTime(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getDrafts(){
      this.loading = true
      let companyId = localStorage.getItem('companyId')

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/games/instances/drafts'

      axios.get(url, config)
        .then(response => {
          this.drafts = response.data
          this.k++
          //set draft
          for(let n in this.drafts){
            if(this.selectedDraft.hasOwnProperty('id') && this.selectedDraft.id === this.drafts[n].id){
              this.selectedDraft = this.drafts[n]
              this.setDraft()
              this.loading = false
              return
            }
          }

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    validate() {
      if(this.selectedDraft){
        this.error = false
        return true
      }
      this.error = true
      return false
    },
    deleteDraftPrompt(d){
      swal({
        title: 'Are you sure?',
        text: "The draft will be permanently deleted.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((response)=> {
        if(response.value == true){
          this.deleteDraft(d)
        }
      })
    },
    async deleteDraft(d){
      this.loading = true
      let companyId = localStorage.getItem('companyId')

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + d.id + '/drafts'
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.selectedDraft = ''
          localStorage.removeItem('draft')
          this.getDrafts()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createInstance(){
      this.loading = true
      let companyId = localStorage.getItem('companyId')

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/games/instances/drafts'
      let data = {}
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.validate()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }  else if (err.response.status == 502){
            swal({
              title: 'Ooops!',
              text: "Duplicate submission",
              type: 'warning',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: 'OK'
            })
          } else {
            swal({
              title: 'Ooops!',
              text: "There were some issues with your submission. Please check your form and try again!",
              type: 'warning',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: 'OK'
            })
          }

        })
    },
  },
  mounted() {
    this.getDrafts()
  }
}
</script>

<style scoped>
.form-check-in-table input[type="radio"]:checked + .form-check-sign::after{
  width: 6px;
  height: 6px;
  top: -11px;
  left: 10px;
  opacity: 1;
}
</style>
