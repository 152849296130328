<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
<!--    <notifications></notifications>-->
    <sidebar-fixed-toggle-button />
    <side-bar :key="j"
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="title"
      :logo="logo"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item v-show="hasPermissions('dashboards_view') || hasPermissions('leaderboards_view')"
          :link="{
            name: $t('sidebar.dashboards'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
<!--          <sidebar-item v-show="hasPermissions('dashboards_view')"-->
<!--            :link="{ name: $t('sidebar.dashboard'), path: '/dashboard/main' }"-->
<!--          ></sidebar-item>-->
          <sidebar-item v-show="hasPermissions('dashboards_view')"
                        :link="{ name: $t('sidebar.bookingDashboards'), path: '/dashboard/bookings' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('dashboards_view') || hasPermissions('leaderboards_view')"
            :link="{ name: $t('sidebar.gameDashboards'), path: '/dashboard/games' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('leaderboards_update')"
                        :link="{ name: $t('sidebar.dashboardSettings'), path: '/dashboard/settings' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('reports_view')"
            :link="{ name: $t('sidebar.reportBuilder'), path: '/analytics/reports' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('schedules_view')"
                      :link="{
            name: $t('sidebar.schedules'),
            icon: 'tim-icons icon-molecule-40',
            path: '/bookings/schedules'
          }"
        >
        </sidebar-item>


        <sidebar-item v-show="hasPermissions('bookings_view') && featureEnabled(1)"
                      :link="{
            name: $t('sidebar.bookings'),
            icon: 'tim-icons icon-calendar-60',
            path: '/bookings'
          }"
        >
          <sidebar-item v-show="hasPermissions('bookings_view')"
            :link="{ name: $t('sidebar.upcoming-bookings'), path: '/bookings/list' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('booking_site_update')"
            :link="{ name: $t('sidebar.booking-settings'), path: '/bookings/settings?id=' + getBookingSiteSettingsId() }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('bookings_view') && !featureEnabled(1)"
                      :link="{
            name: $t('sidebar.bookings'),
            icon: 'tim-icons icon-calendar-60',
            path: '/bookings/settings?id=' + getBookingSiteSettingsId()
          }"
        >
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('transactions_view') || hasPermissions('gift_cards_view')"
                      :link="{
            name: $t('sidebar.purchases'),
            icon: 'tim-icons icon-bag-16',
            path: '/bookings'
          }"
        >
          <sidebar-item v-show="hasPermissions('transactions_view')"
            :link="{ name: $t('sidebar.transactions'), path: '/purchases/transactions?count=10&page=1' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('gift_cards_view')"
            :link="{ name: $t('sidebar.gift_cards'), path: '/purchases/gift-cards?count=10&page=1&tab=0' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('inventory_view')"
                        :link="{ name: $t('sidebar.inventory'), path: '/purchases/inventory?count=10&page=1' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('carts_view')"
                        :link="{ name: $t('sidebar.carts'), path: '/purchases/carts' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('payments_configure')"
                        :link="{ name: $t('sidebar.promos'), path: '/purchases/promos?count=10&page=1&archived=0' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('payments_configure')"
                        :link="{ name: $t('sidebar.payment-gateways'), path: '/purchases/settings?id=' + getPaymentSettingsId() }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('game_history_view')"
          :link="{
            name: $t('sidebar.games'),
            icon: 'tim-icons icon-puzzle-10',
            path: '/games'
          }"
        >
          <sidebar-item v-show="hasPermissions('game_tracker_submit') && featureEnabled(2)"
            :link="{ name: $t('sidebar.gameTracker'), path: '/games/tracker/select' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('game_tracker_configure') && featureEnabled(2)"
            :link="{ name: $t('sidebar.gameTrackerSettings'), path: '/games/tracker/settings?id=' + getGameTrackerSettingsId}"
          ></sidebar-item>

          <sidebar-item v-if="hasPermissions('games_configure')"
            :link="{ name: $t('sidebar.gameSetup'), path: '/games/settings' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('game_history_view') && featureEnabled(2)"
            :link="{ name: $t('sidebar.gameHistory'), path: '/games/history?count=10&page=1' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('hint_log_view') && featureEnabled(2)"
            :link="{ name: $t('sidebar.clues'), path: '/games/clues/select' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('checklists_view') && featureEnabled(3)"
          :link="{
            name: $t('sidebar.checklists'),
            icon: 'tim-icons icon-check-2',
            path: '/checklists'
          }"
        >
          <sidebar-item v-show="hasPermissions('checklists_view')"
            :link="{ name: $t('sidebar.activeChecklists'), path: '/checklists/new' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('checklists_view')"
            :link="{ name: $t('sidebar.checklistHistory'), path: '/checklists/history?count=10&page=1' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('checklists_view') && !featureEnabled(3)"
                      :link="{
            name: $t('sidebar.checklists'),
            icon: 'tim-icons icon-check-2',
            path: '/checklists/new'
          }"
        >
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('surveys_view')"
                      :link="{ name: $t('sidebar.surveys'), icon: 'tim-icons icon-pencil', path: '/surveys/view' }"
        >
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('photos_view') && featureEnabled(5)"
          :link="{
            name: $t('sidebar.photos'),
            icon: 'tim-icons icon-camera-18',
            path: '/photos'
          }"
        >
          <sidebar-item v-show="hasPermissions('photos_view')"
            :link="{ name: $t('sidebar.photos'), path: '/photos/new' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('photo_settings_update')"
            :link="{ name: $t('sidebar.photoSettings'), path: '/photos/settings?id='+ getPhotoSettingsId() }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('photos_view') && !featureEnabled(5)"
                      :link="{
            name: $t('sidebar.photos'),
            icon: 'tim-icons icon-camera-18',
            path: '/photos'
          }"
        >
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('automated_emails_update')"
                      :link="{ name: $t('sidebar.mail'), icon: 'tim-icons icon-email-85', path: '/mail' }"
        >
          <sidebar-item v-show="hasPermissions('automated_emails_update')"
            :link="{ name: $t('sidebar.automatedEmails'), path: '/mail/automated/settings?id=' + getAutomatedEmailSettingsId()}"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('customer_view')"
                      :link="{ name: $t('sidebar.customers'), icon: 'tim-icons icon-user-run', path: '/customers?count=10&page=1' }"
        >
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('waivers_view') && featureEnabled(6)"
          :link="{ name: $t('sidebar.waivers'), icon: 'tim-icons icon-notes', path: '/waivers' }"
        >
          <sidebar-item v-show="hasPermissions('waivers_view')"
            :link="{ name: $t('sidebar.waiverHistory'), path: '/waivers/history?count=10&page=1' }"
          ></sidebar-item>
          <sidebar-item v-show="hasPermissions('waiver_settings_update')"
            :link="{ name: $t('sidebar.waiverSetup'), path: '/waivers/settings?id=' + getWaiverSettingsId() }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-show="hasPermissions('waivers_view') && !featureEnabled(6)"
                      :link="{ name: $t('sidebar.waivers'), icon: 'tim-icons icon-notes', path: '/waivers/history?count=10&page=1' }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.settings'),
            icon: 'tim-icons icon-settings-gear-63',
            path: '/settings'
          }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.myProfile'), path: '/settings/profile' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userManagement'), path: '/settings/user-management' }" v-show="hasPermissions('company_users_view')"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.companySettings'), path: '/settings/company' }" v-show="hasPermissions('company_settings_configure')"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.billing'), path: '/settings/billing' }" v-show="hasPermissions('billing_view')"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.migration'), path: '/settings/migration' }" v-show="hasPermissions('migrations_view')"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.logs'), path: '/settings/logs?count=10&page=1' }" v-show="hasPermissions('logs_view')"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.changelog'),
            icon: 'tim-icons icon-refresh-02',
            path: '/beta/changelog'
          }"
        >
        </sidebar-item>

      </template>
    </side-bar>
    <div id="mp" class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import SidebarShare from './SidebarSharePlugin';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary,
      title: '',
      logo: '',
      groupExists: false,
      j: 0,
      polling: null,
      features: []
    };
  },
  methods: {
    featureEnabled(feature_id){
      const matchFound = _.find(this.features, {id: feature_id})
      return matchFound
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    setTitle(){
      this.title = JSON.parse(localStorage.getItem('company')).name
      this.logo = JSON.parse(localStorage.getItem('company')).img

    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isOTC(){
      let user = JSON.parse(localStorage.getItem('profile')).email
      if(user == 'jana.geddis+7@gmail.com' || user == 'nick@offthecouchgames.com' || user == 'ken@offthecouchgames.com'){
        return true
      }
      return false
    },
    getWaiverSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))){
        return JSON.parse(localStorage.getItem('group')).waiver_configuration_id
      }
      return null
    },
    getCompanyGroupId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).id
      }
      return null
    },
    getPaymentSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).payment_settings_id
      }
      return null
    },
    getBookingSiteSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).booking_site_settings_id
      }
      return null
    },
    getGameTrackerSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).game_tracker_settings_id
      }
      return null
    },
    getPhotoSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).photo_settings_id
      }

      return null
    },
    getAutomatedEmailSettingsId(){
      if(JSON.parse(localStorage.getItem('group'))) {
        return JSON.parse(localStorage.getItem('group')).automated_email_settings_id
      }
      return null
    },
    hasGroup(){
      if(localStorage.getItem('group')){
        this.groupExists = true
        clearInterval(this.polling);
        this.j++
      }
      this.groupExists  = false
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getProfile(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let url = API_LOCATION + 'users/me'
      axios.get(url, config)
        .then(response => {
          this.features = response.data.features
          if(new Date(response.data.company.trial_end) > new Date()){
            let trial = {
              trial_start: response.data.company.trial_start,
              trial_end: response.data.company.trial_end
            }
            localStorage.setItem('trial', JSON.stringify(trial))
          } else {
            localStorage.removeItem('trial')
          }

          localStorage.setItem('features', JSON.stringify(this.features))
          localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
        })
    },
  },
  mounted() {
    this.getProfile()
    this.polling = setInterval(this.hasGroup, 100);
    this.setTitle();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

/* total width */
body::-webkit-scrollbar {
  background-color: rgba(50, 50, 93, 0.24);
  width: 7px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 93, 0.14);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: rgba(218, 18, 18, 0.05);
  border-radius: 16px;
  border: 4px solid rgba(50, 50, 93, 0.79);
}
</style>
