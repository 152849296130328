<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav row ml-auto justify-content-between " style="width:100%">
      <div class="col-2 mt-1 d-none d-lg-block">
        <base-dropdown
          tag="li"
          title-tag="a"
          class="nav-item"
          title-classes="nav-link"

        >
          <template
            slot="title"
          >
          <span style="width: 300px;">
            {{ selectedGroup.name }}
             <i style="font-size: small" class="tim-icons icon-minimal-down"></i>
          </span>
          </template>
          <span v-for="(g, i) in groups" :key="'G' + i">
          <li class="nav-link">
             <span v-on:click="changeDefaultGroup(g)" class="nav-item dropdown-item">{{ g.name }}</span>
          </li>
        </span>

        </base-dropdown>
      </div>
      <div class="col d-lg-none">
        <base-dropdown
          tag="li"
          title-tag="a"
          class="nav-item"
          title-classes="nav-link"
          menu-classes="dropdown-navbar"
        >
          <template
            slot="title"
          >
          <span style="width: 300px;">
            {{ selectedGroup.name }}
             <i style="font-size: small" class="tim-icons icon-minimal-down"></i>
          </span>
          </template>
          <span v-for="(g, i) in groups" :key="'G' + i">
            <li class="nav-link">
               <span v-on:click="changeDefaultGroup(g)" class="nav-item dropdown-item">{{ g.name }}</span>
            </li>
          </span>
        </base-dropdown>

        <div class="row">
          <div class="col-12">
            <span class="ml-2" style="color: #222a42; font-weight: 300" v-on:click="logout"><i class="tim-icon el-icon-switch-button"></i> Sign out</span>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-5" v-if="daysRemaining > 0" :key="j">
        <p style="font-size: medium;" class="mt-2">
          <i class="mr-2 fas fa-exclamation-triangle"></i>Your trial ends in {{getTrialDaysRemaining()}}</p>
      </div>


      <div class="col-4 mt-2 d-sm-none d-none d-lg-block d-md-none text-right">
        <span class="mr-3 text-white">{{user_first_name}} {{user_last_name}}</span>
        <a href="#" v-on:click="logout"><i class="tim-icon el-icon-switch-button"></i> Sign out</a>
      </div>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import BaseAlert from "@/components/BaseAlert";
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    BaseAlert,
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      trial_start: '',
      trial_end: '',
      daysRemaining: 0,
      groups: [],
      j: 0,
      selectedGroup: 'test',
      logging_out: false,
      user_first_name: JSON.parse(localStorage.getItem('profile')) ? JSON.parse(localStorage.getItem('profile')).first_name : '',
      user_last_name: JSON.parse(localStorage.getItem('profile')) ? JSON.parse(localStorage.getItem('profile')).last_name : ''
    };
  },
  methods: {
    getTrialDaysRemaining() {
      if(this.daysRemaining === 1){
        return this.daysRemaining + ' day'
      } else if(this.daysRemaining < 1) {
        return ''
      } else {
        return this.daysRemaining + ' days'
      }
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGroups(group_updated) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/groups'

      axios.get(url, config)
        .then(response => {
          this.groups = response.data
          this.selectedGroup = this.groups[0]
          localStorage.setItem('group', JSON.stringify(this.groups[0]))
          for(let n in this.groups){
            if(this.groups[n].default_group === this.groups[n].id){
              this.selectedGroup = this.groups[n]
              localStorage.setItem('group', JSON.stringify(this.groups[n]))
            }
          }

          if(this.$route.path === '/bookings/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.booking_site_settings_id
              }
            });
          }

          if(this.$route.path === '/purchases/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.payment_settings_id
              }
            }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                console.error(err);
              }
            });
          }

          if(this.$route.path === '/games/tracker/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.game_tracker_settings_id
              }
            });
          }

          if(this.$route.path === '/photos/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.photo_settings_id
              }
            });
          }

          if(this.$route.path === '/waivers/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.waiver_configuration_id
              }
            });
          }

          if(this.$route.path === '/mail/automated/settings'){
            this.$router.push({
              path: this.$route.path,
              query: {
                id: this.selectedGroup.automated_email_settings_id
              }
            });
          }

          if(this.$route.path === '/games/edit'){
            let games = JSON.parse('[' + this.selectedGroup.games + ']')
            let game_exists = _.find(games, {id: this.$route.query.id})
            if(!game_exists){
              this.$router.push({path: '/games/settings'});
            }
          }

          if(group_updated){
            location.reload();
          }
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    },
    async changeDefaultGroup(g){
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + g.id + '/default'

      let data = {}

      axios.put(url, data, config)
        .then(response => {
          this.getGroups(1)
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })
    },
    navigateTo(location){
      this.$router.push(location)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getName(){
      return JSON.parse(localStorage.getItem('profile')).first_name + ' ' + JSON.parse(localStorage.getItem('profile')).last_name
    },
    getImage(){
      return JSON.parse(localStorage.getItem('profile')).img
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout (event) {
      event.preventDefault()
      this.logging_out = true
      await Auth.signOut()
      let mode = localStorage.getItem('mode')
      let showScheduleNameOnCalendar = localStorage.getItem('showScheduleNameOnCalendar')
      let calGameSettings = localStorage.getItem('calGameSettings')
      localStorage.clear()
      if(mode){
        localStorage.setItem('mode', mode)
      }
      if(calGameSettings){
        localStorage.setItem('calGameSettings', calGameSettings)
      }
      if(showScheduleNameOnCalendar){
        localStorage.setItem('showScheduleNameOnCalendar', showScheduleNameOnCalendar)
      }

      AmplifyEventBus.$emit('authState', 'signedOut')
      this.$router.push('/login')
    },
    async getProfile(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let url = API_LOCATION + 'users/me'
      axios.get(url, config)
        .then(response => {
          const trialEndDate = moment(response.data.company.trial_end, 'YYYY-MM-DD'); // Parse trial end date
          const today = moment(); // Get today's date
          if(trialEndDate.isAfter(today, 'day') || trialEndDate.isSame(today, 'day')){
            let trial = {
              trial_start: response.data.company.trial_start,
              trial_end: response.data.company.trial_end
            }
            localStorage.setItem('trial', JSON.stringify(trial))
            this.trial_start = new Date(trial.trial_start);
            this.trial_end = new Date(trial.trial_end);

            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let trialEnd = new Date(this.trial_end);
            trialEnd.setHours(0, 0, 0, 0);

            let timeDifference = trialEnd - today;

            this.daysRemaining = (Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1) + 1;
          } else {
            localStorage.removeItem('trial')
          }
        })
    },
  },
  mounted() {
    //mode
    let docClasses = document.body.classList;
    if (localStorage.getItem('mode') == '0') {
      docClasses.add('white-content');
    } else {
      docClasses.remove('white-content');
    }

    if(!localStorage.getItem('trial')){
      this.getProfile()
    } else {
      let trial = JSON.parse(localStorage.getItem('trial'))
      this.trial_start = new Date(trial.trial_start);
      this.trial_end = new Date(trial.trial_end);
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let trialEnd = new Date(this.trial_end);
      trialEnd.setHours(0, 0, 0, 0);

      let timeDifference = trialEnd - today;
      this.daysRemaining = (Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1) + 1;
    }
    this.getGroups()
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
