<template>
  <div>
    <h5 class="info-text">
      Track how players progress through the game
    </h5>
    <div class="d-sm-block d-none">
      <div class="row container justify-content-end">
        <base-button
          @click.native="toggleView()"
          simple
          size="sm"
          type="primary"
        >{{selectedView}} View
        </base-button>
      </div>
      <div v-show="selectedView==='compact'" class="row justify-content-center mt-4">
        <div class="col-10" v-for="(puzzle, i) in model.puzzles" :key="'A'+i">

          <div class="mt-4">
            <div class="row">
              <div class="col">
                <p style="font-size: xx-large">{{puzzle.name}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p v-if="puzzle.description"><strong class="text-primary text-uppercase">Description: </strong> {{puzzle.description}}</p>
                <p v-if="puzzle.solution"><strong class="text-primary text-uppercase">Solution: </strong>{{puzzle.solution}}</p>
              </div>
              <div class="col text-right">
                <img
                  v-on:click="expandImage(puzzle.solution_img)"
                  v-if="puzzle.solution_img" slot="image" style="height: 150px; cursor: pointer"
                  :src="puzzle.solution_img"  alt="Card image cap"/>
              </div>
            </div>
            <div class="row">
              <div class="offset-2">
                <base-checkbox class="ml-3" v-on:input="changeValidation(puzzle)" v-model="puzzle.did_not_finish">Did not finish
                </base-checkbox>
              </div>

              <div>
                <el-popover trigger="hover"
                            placement="bottom">
                  <div>
                    <div class="popover-body">Select for walkouts <br>
                      and players running<br> out of time</div>
                  </div>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons icon-alert-circle-exc"></i>
                  </base-button>
                </el-popover>
              </div>

            </div>

            <div class="row mt-1">
              <div class="col-2">
                <p class="mt-1">Solve time: </p>
              </div>
              <div class="col-4 col-lg-4 col-md-5 col-sm-6">
                <div class="row">
                  <div class="offset-1">
                    <small class="text-danger" v-if="puzzle.error">{{puzzle.error}}</small>

                  </div>
                </div>
                <div class="row">

                  <div class="col">
                    <base-input :error="puzzle.error">
                      <el-time-picker v-on:input="validateTime(puzzle)"
                        v-model="puzzle.solve_time" v-on:change="setDraft()"
                        :format="picker_time_format" placeholder="HH:MM"
                      ></el-time-picker>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="col">
                <base-button
                  class="now-field"
                  @click.native="setNow(puzzle)"
                  type="info"
                  simple
                >Now
                </base-button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-2">
                <p class="mt-1">Hints: </p>
              </div>
              <div class="col-3">
                <label>Number of hints</label>
                <base-input :error="getHintsError('hints', puzzle.hints_given_count, puzzle.did_not_finish)"
                            type="number"
                            name="hints" v-on:change="setDraft()"
                            placeholder="Hints"
                            v-validate="puzzle.modelValidations.hints"
                            v-model="puzzle.hints_given_count">
                </base-input>
              </div>
              <div class="col-6">
            <textarea
              class="form-control"
              placeholder="List the hints you gave..."
              name="notes" v-on:change="setDraft()"
              v-model="puzzle.hints_given_description"
              rows="3"></textarea>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-2">
                <p class="mt-1">Breakage: </p>
              </div>
              <div class="col-3 ">
                <label>Did the puzzle break?</label>
                <base-checkbox v-on:input="setDraft()" class="mb-3" v-model="puzzle.breakage_occurred"></base-checkbox>
              </div>
              <div class="col-6">
            <textarea
              class="form-control"
              placeholder="How did the puzzle fail?..."
              name="notes" v-on:change="setDraft()"
              v-model="puzzle.breakage_description"
              rows="3"></textarea>
              </div>
            </div>
          </div>
          <hr class="mt-5 c-white">
        </div>
      </div>
      <div v-show="selectedView==='list'" class="row justify-content-center mt-4">
        <div class="col-sm-12" >
          <b-table fixed :key="componentKey" striped hover :items="model.puzzles" :fields="fields">
            <template v-slot:cell(name)="row">
              <div style="max-width: 400px">
                <p class="text-primary">{{ row.item.name }}</p>
                <p v-if="row.item.description"><small><strong class="text-warning">Description: </strong>{{ row.item.description }}</small></p>
                <p v-if="row.item.solution"><small><strong class="text-warning">Solution: </strong>{{ row.item.solution }}</small></p>
                <img  v-on:click="expandImage(row.item.solution_img)"
                      v-if="row.item.solution_img" slot="image"
                      style="height: 100px; cursor: pointer" :src="row.item.solution_img"  alt="Card image cap"/>
              </div>

            </template>
            <template v-slot:cell(solve_time)="row">
              <label>Time players solved this puzzle</label>
              <div class="row">
                <div class="col">
                  <base-input :error="row.item.error">
                    <el-time-picker v-on:input="validateTime(row.item)"
                      v-model="row.item.solve_time" v-on:change="setDraft()"
                      :format="picker_time_format" placeholder="HH:MM"
                    ></el-time-picker>
                  </base-input>
                </div>
                <div class="col">
                  <base-button
                    @click.native="setNow(row.item)"
                    simple
                    type="info"
                  >Now
                  </base-button>
                </div>
              </div>
              <div class="row">

                <div class="col-1">
                  <base-checkbox v-on:input="changeValidation(row.item)" v-model="row.item.did_not_finish"></base-checkbox>

                </div>
                <div class="col-9">
                  <label>Did not finish</label>
                  <el-popover trigger="hover"
                              placement="bottom">
                    <div>
                      <div class="popover-body">Select for walkouts <br>
                        and players running<br> out of time</div>
                    </div>
                    <base-button class="like btn-link"
                                 type="info"
                                 size="sm"
                                 icon
                                 slot="reference">
                      <i class="tim-icons icon-alert-circle-exc"></i>
                    </base-button>
                  </el-popover>
                </div>
              </div>
            </template>
            <template v-slot:cell(hints)="row">
              <label>Number of hints</label>
              <base-input :error="getHintsError('hints', row.item.hints_given_count, row.item.did_not_finish)"
                          type="number"
                          name="hints"
                          v-on:change="setDraft()"
                          placeholder="Hints"
                          v-validate="row.item.modelValidations.hints"
                          v-model="row.item.hints_given_count">
              </base-input>
              <textarea
                class="form-control"
                placeholder="List the hints you gave..."
                name="notes"
                v-model="row.item.hints_given_description"
                v-on:change="setDraft()"
                cols="25"
                rows="3"></textarea>
            </template>
            <template v-slot:cell(puzzle_breakage)="row">
              <label>Did the puzzle break?</label>
              <base-checkbox v-on:input="setDraft()" class="mb-3" v-model="row.item.breakage_occurred"></base-checkbox>
              <textarea
                class="form-control mt-2"
                placeholder="How did the puzzle fail?..."
                name="notes" v-on:change="setDraft()"
                v-model="row.item.breakage_description"
                rows="3"></textarea>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <div v-show="selectedView==='list'" class="row d-sm-none d-block justify-content-center mt-4">
      <div class="col-12">
        <b-table :key="componentKey" striped hover :items="model.puzzles" :fields="mobileFields">
          <template v-slot:cell(puzzles)="row">
            <div class="row">
              <div class="col-12">
                <p class="text-primary">{{ row.item.name }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span v-if="row.item.description"><small><strong class="text-warning">Description: </strong>{{ row.item.description }}</small></span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span v-if="row.item.solution"><small><strong class="text-warning">Solution: </strong>{{ row.item.solution }}</small></span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <img
                  v-on:click="expandImage(row.item.solution_img)"
                  v-if="row.item.solution_img" slot="image" style="height: 150px; cursor: pointer"
                  :src="row.item.solution_img"  alt="Card image cap"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <small class="text-warning">Time players solved this puzzle:</small>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-input :error="row.item.error">
                  <el-time-picker v-on:input="validateTime(row.item)"
                    v-model="row.item.solve_time" v-on:change="setDraft()"
                    :format="picker_time_format" placeholder="HH:MM"
                  ></el-time-picker>
                </base-input>
              </div>
              <div class="col">
                <base-button
                  @click.native="setNow(row.item)"
                  simple
                  class="ml-1"
                  size="sm"
                  type="info"
                >Now
                </base-button>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <small class="text-warning">Did not finish: </small> <base-checkbox v-on:input="changeValidation(row.item)" v-model="row.item.did_not_finish"></base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <small class="text-warning">Number of hints: </small>
                <base-input :error="getHintsError('hints', row.item.hints_given_count, row.item.did_not_finish)"
                            type="number"
                            name="hints"
                            placeholder="Hints"
                            v-on:change="setDraft()"
                            v-validate="row.item.modelValidations.hints"
                            v-model="row.item.hints_given_count">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <textarea
                    class="form-control"
                    placeholder="List the hints you gave..."
                    name="notes" v-on:change="setDraft()"
                    v-model="row.item.hints_given_description"
                    rows="3">
                  </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <small class="text-warning">Did the puzzle break? </small>
                <base-checkbox v-on:input="setDraft()" v-model="row.item.breakage_occurred"></base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                   <textarea
                     class="form-control"
                     placeholder="How did the puzzle fail?..."
                     name="notes" v-on:change="setDraft()"
                     v-model="row.item.breakage_description"
                     rows="3"></textarea>

              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <modal :show.sync="modals.image"
           body-classes="p-0"
           modal-classes="modal-dialog-centered">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <img slot="image" style="width: 100%" :src="selectedImage"  alt="Card image cap"/>
      </card>
    </modal>
  </div>
</template>

<script>
import {TimeSelect, Popover, TimePicker} from 'element-ui';
  import { BTable } from 'bootstrap-vue'
  import {API_LOCATION} from "@/api-config";
  import axios from "axios";
  import {Auth} from "aws-amplify";
  import swal from "sweetalert2";

  export default {
    name: "PuzzleStep",
    components: {
      [TimeSelect.name]: TimeSelect,
      [Popover.name]: Popover,
      [TimePicker.name]: TimePicker,
      BTable
    },
    data() {
      return {
        timer: '',
        fields: ['name', 'solve_time', 'hints', 'puzzle_breakage'],
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        picker_time_format: 'hh:mm A',
        mobileFields: ['puzzles'],
        selectedView: 'list',
        model: {
          puzzles: [],
        },
        selectedGame: '',
        componentKey: 0,
        selectedImage: '',
        modals: {
          image: false
        }
      }
    },
    methods: {
      validateTime(puzzle){
        console.log(puzzle)
        let today = new Date()
        console.log(puzzle.solve_time)
        puzzle.solve_time.setMonth(today.getMonth(), today.getDate())
      },
      expandImage(image){
        this.selectedImage = image
        this.modals.image = true
      },
      convertTime() {
        let today = new Date()
        for (let n in this.model.puzzles) {
          if(this.model.puzzles[n].solve_time != null
            && this.model.puzzles[n].solve_time && this.model.puzzles[n].solve_time != ''
            && !this.model.puzzles[n].did_not_finish){
            if(typeof this.model.puzzles[n].solve_time === 'string' && this.model.puzzles[n].solve_time.includes('T')){
              let time = new Date(this.model.puzzles[n].solve_time)

              time.setHours(this.model.puzzles[n].solve_time.split('T')[1].split(':')[0])
              this.model.puzzles[n].solve_time = today.getFullYear() + '-'
                + ('0' + (today.getMonth()+1)).slice(-2) + '-'
                + ('0' +today.getDate()).slice(-2) +
                ' ' +  ('0' +time.getHours()).slice(-2) + ':'
                +  ('0' +time.getMinutes()).slice(-2)

            } else {
              this.model.puzzles[n].solve_time = new Date(this.model.puzzles[n].solve_time)
              this.model.puzzles[n].solve_time = today.getFullYear() + '-'
                + ('0' + (today.getMonth()+1)).slice(-2) + '-'
                + ('0' +this.model.puzzles[n].solve_time.getDate()).slice(-2) +
                ' ' +  ('0' +this.model.puzzles[n].solve_time.getHours()).slice(-2) + ':'
                +  ('0' +this.model.puzzles[n].solve_time.getMinutes()).slice(-2)
            }
          }

          if (this.model.puzzles[n].did_not_finish == true) {
            this.model.puzzles[n].solve_time = ''
            this.model.puzzles[n].error = null
          }
        }
      },
      changeValidation(row) {
        //test2
        if (row.did_not_finish == true) {
          row.modelValidations = {
            booking_hour: {
              required: false,
              numeric: true,
              max_value: 12
            },
            booking_minute: {
              required: false,
              numeric: true,
              max_value: 59
            },
            hints: {
              required: false,
              min_value: 0
            }
          }

        } else if (row.did_not_finish == false) {
          row.modelValidations = {
            booking_hour: {
              required: true,
              numeric: true,
              max_value: 12
            },
            booking_minute: {
              required: true,
              numeric: true,
              max_value: 59
            },
            hints: {
              required: true,
              min_value: 0
            }
          }
        }
        this.setDraft()
      },
      getError(fieldName, fieldValue1, fieldValue2, didNotFinish) {
        if ((fieldValue1 === null || fieldValue2 == null) && didNotFinish === false) {
          return this.errors.first(fieldName)
        }
      },
      getHintsError(fieldName, fieldValue, didNotFinish) {
        if (fieldValue < 0) {
          return this.errors.first(fieldName)
        } else if ((fieldValue === null || fieldValue < 0 || fieldValue === '') && didNotFinish === false) {
          return this.errors.first(fieldName)
        }
      },
      getPuzzles() {
        this.model.puzzles = JSON.parse(localStorage.getItem('puzzles'))
      },
      async setDraft() {
        localStorage.setItem('puzzles', JSON.stringify(this.model.puzzles))
        //save draft
        let companyId = localStorage.getItem('companyId')
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'companies/' + companyId
          + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'

        this.convertTime()

        let data = {
          puzzles: this.model.puzzles,
        }
        return new Promise(function (resolve) {
          axios.put(url, data, config)
            .then(response => {
              resolve(true)
            })
            .catch(err => {
              if (err.response.status == 401) {
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              if (err.response.status == 500) {
                swal({
                  title: 'This booking slot is already attached to another draft',
                  text: "Delete the draft or select another booking slot",
                  type: 'error',
                  confirmButtonColor: '#1d8cf8',
                  confirmButtonText: 'OK'
                })
                resolve(false)
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        })
      },
      setNow(row) {
        row.did_not_finish = false
        row.solve_time = new Date()
        this.setDraft()
      },
      toggleView() {
        if (this.selectedView === 'compact') {
          this.selectedView = 'list'
        } else {
          this.selectedView = 'compact'
        }
      },
      getAuthToken() {
        return new Promise(function (resolve) {
          Auth.currentSession()
            .then(data => {
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async createPuzzles() {
        let companyId = localStorage.getItem('companyId')
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'companies/' + companyId
          + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts/puzzles'

        let data = {}
        axios.post(url, data, config)
          .then(response => {
            this.model.puzzles = []
            for (let i in response.data) {
              this.model.puzzles.push({
                puzzle_id: response.data[i].id,
                name: response.data[i].name,
                description: response.data[i].description,
                solution: response.data[i].solution,
                solution_img: response.data[i].solution_img,
                position: response.data[i].position,
                solve_time: response.data[i].solve_time,
                game_id: response.data[i].game_id,
                error: null,
                hints_given_count: response.data[i].hints_given_count,
                did_not_finish: Boolean(!response.data[i].is_completed),
                hints_given_description: response.data[i].hints_given_description,
                breakage_occurred: Boolean(response.data[i].breakage_occurred),
                breakage_description: response.data[i].breakage_description,
                modelValidations: {
                  booking_hour: {
                    required: true,
                    numeric: true,
                    max_value: 12
                  },
                  booking_minute: {
                    required: true,
                    numeric: true,
                    max_value: 59
                  },
                  hints: {
                    required: true,
                    min_value: 0

                  }
                }
              })

              // if(this.model.puzzles[i].solve_time && !this.model.puzzles[i].solve_time.includes('NaN')
              //   && !this.model.puzzles[i].solve_time.includes('000')){
              //   let time = new Date(response.data[i].solve_time)
              //   time.setHours(this.model.puzzles[i].solve_time.split('T')[1].split(':')[0])
              //   time.setMinutes(this.model.puzzles[i].solve_time.split('T')[1].split(':')[1])
              //   this.model.puzzles[i].solve_time = time
              // }
            }
            this.convertTime()
            this.model.puzzles.sort((a, b) => (a.position > b.position) ? 1 : -1)


          })
          .catch(err => {
            if (err.response.status == 401) {
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      validate() {
        let errors = 0
        for (var n in this.model.puzzles) {
          console.log(this.model.puzzles[n].solve_time)
          //validation
          if (( this.model.puzzles[n].solve_time === null || this.model.puzzles[n].solve_time === '')
            && this.model.puzzles[n].did_not_finish == false) {
            this.model.puzzles[n].error = "Time cannot be empty"
            this.model.puzzles[n].solve_time = ''
          } else {
            this.model.puzzles[n].error = null
          }
          if (this.model.puzzles[n].error != null && this.model.puzzles[n].did_not_finish == false) {
            errors++
          }
        }
        console.log(errors)
        if (errors > 0) {
          swal({
            title: 'Ooops!',
            text: 'You have empty or invalid time fields. Do you want to mark them as not finished?',
            type: 'warning',
            confirmButtonText: 'Mark as not finished',
            cancelButtonColor: '#fd5d93',
            cancelButtonText: 'Fix errors',
            showCancelButton: true,
            confirmButtonColor: '#1d8cf8',
          }).then((response) => {
            if (response.value) {
              for (var n in this.model.puzzles) {
                //validation
                if (( this.model.puzzles[n].solve_time === null || this.model.puzzles[n].solve_time === '')
                  && this.model.puzzles[n].did_not_finish == false) {
                  this.model.puzzles[n].error = null
                  this.model.puzzles[n].did_not_finish = true
                }
              }
              return true
            } else {
              return false
            }
          })
        } else {
          return true
        }
      }
    },
    mounted() {
      if (this.time_format === 'HH:mm') {
        this.picker_time_format = 'HH:mm'
      }
    }
  }
</script>

<style scoped>
  .c-white {
    background: #797688;
  }
  .hour-field{
    margin-left: 1rem;
    width: 4rem;
  }
  .colon-field{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0.5rem;
  }
  .minute-field{
    width: 4rem;
    margin-right: 0.5rem;
  }
  .dd-field{
    font-size: x-small;
    width: 2.5rem;
    height: 2.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: -0.05rem;
  }
  .now-field{
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.3rem;
    margin-left: -1.5rem;
    margin-top: -0.05rem;
  }

</style>
