<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="d-block d-sm-none">
          <div class="row justify-content-between">
            <div class="col">
              <h4 slot="header" class="card-title">Event logs</h4>
            </div>
            <div class="col text-right">
              <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
              <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
            </div>
          </div>
          <div class="row" v-show="show_filters">
            <div
              class="col d-flex"
            >
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by user</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    User"
                          v-model="filters.user.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.user)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" :simple="!filters.user.actual" size="sm" type="primary">
                    User <span v-if="filters.user.actual">: {{filters.user.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by feature</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.feature.value"
                              class="select-primary"
                              name="game"
                              placeholder="Feature"
                              value-key="id"
                            >
                              <el-option
                                v-for="(f, i) in features"
                                class="select-primary"
                                :label="f.name"
                                :value="{id: f.id, name: f.name}"
                                :key="'FS' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.feature)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.feature.actual" size="sm" type="primary">
                      Feature <span v-if="filters.feature.actual">: {{filters.feature.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by event</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Event"
                          v-model="filters.event.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.event)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.event.actual" size="sm" type="primary">
                    Event <span v-if="filters.event.actual">: {{filters.event.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
              </base-input>
            </div>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div class="row justify-content-between">
            <div class="col">
              <h4 slot="header" class="card-title">Event logs</h4>
            </div>
          </div>
          <div class="row" :key="j">
            <div
              class="col"
            >
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by user</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    User"
                          v-model="filters.user.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.user)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" :simple="!filters.user.actual" size="sm" type="primary">
                    User <span v-if="filters.user.actual">: {{filters.user.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by feature</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select
                                     v-model="filters.feature.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Feature"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(f, i) in features"
                              class="select-primary"
                              :label="f.name"
                              :value="{id: f.id, name: f.name}"
                              :key="'FS' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.feature)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.feature.actual" size="sm" type="primary">
                    Feature <span v-if="filters.feature.actual">: {{filters.feature.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by event</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Event"
                          v-model="filters.event.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.event)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getEvents(perPage, getOffset())">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.event.actual" size="sm" type="primary">
                    Event <span v-if="filters.event.actual">: {{filters.event.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
              </base-input>
            </div>
            <div class="mr-3">
<!--              <base-button icon class="btn-link text-white" v-on:click="downloadCSV()">-->
<!--                <i class="tim-icons icon-cloud-download-93"></i>-->
<!--              </base-button>-->
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="getEvents(perPage, getOffset())"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div v-if="events.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            <h4 class="mt-3">No events recorded.</h4>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div class="row" >
            <div class="col">
              <b-table v-if="events.length > 0" striped hover :items="events"
                        :fields="['user', 'date', 'feature', 'event']">
                <template v-slot:cell(user)="row">
                  <div>{{row.item.first_name}} {{row.item.last_name}}</div>
                  <small><i class="tim-icons icon-email-85"></i> {{row.item.email}}</small>
                </template>
                <template v-slot:cell(date)="row">
                  <div>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</div>
                </template>
                <template v-slot:cell(event)="row">
                  <div>{{row.item.description}}
                    <a :href="makeLink(row.item.additional_information, row.item.feature)">
                      {{row.item.additional_information}}
                    </a>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                v-on:input="getEvents(perPage, getOffset())"
              ></b-pagination>
            </div>
          </div>
        </div>

        <div class="d-block d-sm-none">
          <div class="row" >
            <div class="col">
              <b-table v-if="events.length > 0" striped hover :items="events"
                       :fields="['event']">
                <template #head(event)="row">
                  <span>Event count: {{totalRows}}</span>
                </template>
                <template v-slot:cell(event)="row">
                  <div>{{row.item.description}}
                    <a :href="makeLink(row.item.additional_information, row.item.feature)">
                      {{row.item.additional_information}}
                    </a>
                  </div>
                  <div>
                    <small>Performed by: {{row.item.first_name}} {{row.item.last_name}}</small>
                  </div>
                  <div>
                    <small>Timestamp: {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                v-on:input="getEvents(perPage, getOffset())"
              ></b-pagination>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {BTable, BPagination} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {BaseSwitch} from "@/components";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import _ from "lodash";

export default {
  name: "EventLogs",
  components: {
    BTable,
    BPagination,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BreadcrumbItem,
    Breadcrumb,
    BaseSwitch
  },
  data() {
    return {
      loading: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      perPageOptions: [5, 10, 25, 50],
      events: [],
      features: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      show_filters: false,
      j: 0,
      filters: {
        user: {
          value: '',
          actual: ''
        },
        event: {
          value: '',
          actual: ''
        },
        feature: {
          value: '',
          actual: ''
        },
        event_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        }
      }
    }
  },
  methods: {
    makeLink(string, feature){
      let id = ''
      if(feature === 'Bookings'){
        id = string.replace(/[#\s]/g, '');
        return '/bookings?selected_booking=' + id
      }
      if(feature === 'Purchases'){
        return '/purchases/transactions'
      }
      if(feature === 'Game management'){
        id = string.replace(/[#\s]/g, '');
        if(id === 'copy'){
          return
        }
        return '/games/settings'
      }
      if(feature === 'Checklists'){
        id = string.replace(/[#\s]/g, '');
        if(id === 'copy'){
          return
        }
        return '/checklists/new'
      }
      if(feature === 'User management'){
        return '/settings/user-management'
      }
    },
    resetAll(){
      this.filters = {
        user: {
          value: '',
          actual: ''
        },
        event: {
          value: '',
          actual: ''
        },
        feature: {
          value: '',
          actual: ''
        },
        event_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        }
      }
      this.getEvents(this.perPage, this.getOffset())
    },
    resetField(field1, field2){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      if(field2){
        field2.actual_from = ''
        field2.value_from = ''
        field2.actual_to = ''
        field2.value_to = ''
      }
      this.getEvents(this.perPage, this.getOffset())
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getEvents(count, offset, init) {

      this.filters.user.actual = this.filters.user.value
      this.filters.event.actual = this.filters.event.value

      this.filters.feature.actual = this.filters.feature.value.name
      let feature = this.filters.feature.value.id
      if(init && this.$route.query.feature !== ''){
        feature = Number(this.$route.query.feature)
      }
      if(feature === undefined || isNaN(feature)){
        feature = ''
      }


      // let feature = ''
      // // if(this.filters.feature.value){
      // //   this.filters.feature.actual = this.filters.feature.value
      // //     .filter(item => item.id !== undefined) // Filter out objects without an `id`
      // //     .map(item => item.name)
      // //     .join(', ');
      // //   feature = this.filters.feature.value
      // //     .filter(item => item.id !== undefined) // Filter out objects without an `id`
      // //     .map(item => item.id)
      // //     .join(',');
      // // }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/logs?count=' + count + '&offset=' + offset +
        "&user=" + encodeURIComponent(this.filters.user.actual) +
        "&event=" + encodeURIComponent(this.filters.event.actual)
        +"&feature=" + encodeURIComponent(feature)

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.events = response.data.events
          this.features = response.data.features
          this.totalRows = response.data.total_rows

          if(init && this.$route.query.feature){
            this.$nextTick(() => {
              let featureObj = _.find(this.features, {id: feature})
              this.filters.feature.value = featureObj
              this.filters.feature.actual = featureObj ?  featureObj.name : ''
              this.j++
            });
          }
          this.addQueryParameters()


        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getOffset(){
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        user: this.filters.user.value,
        event: this.filters.event.value,
        feature: this.filters.feature.value.hasOwnProperty('id') ? this.filters.feature.value.id : this.filters.feature.value,
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
  mounted() {
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)

    this.filters = {
      user: {
        value: this.$route.query.user ? this.$route.query.user : '',
        actual: this.$route.query.user ? this.$route.query.user : ''
      },
      event: {
        value: this.$route.query.event ? this.$route.query.event : '',
        actual: this.$route.query.event ? this.$route.query.event : ''
      },
      feature: {
        value: this.$route.query.feature ? this.$route.query.feature : '',
        actual: this.$route.query.feature ? this.$route.query.feature : ''
      }
    }
    this.getEvents(this.perPage, this.getOffset(), 1)
  }
}
</script>

<style scoped>

</style>
