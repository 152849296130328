<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <!--        Title-->
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>

          <div class="col text-right">
            <el-dropdown>
              <base-button
                link
                type="primary"
                size="sm"
              > <i style="font-size: large" class="tim-icons el-icon-more"></i>
              </base-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <a class="dropdown-item" @click="deleteAccountPrompt($event)" href="#">
                    <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete account
                  </a>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

        </div>
<!--        Plans-->
        <div v-if="activeTabIndex === 0">
          <div class="row">
            <div class="col">
              <h4>Billing period: {{getMonthAndYear(statement.billing_cycle_start)}}</h4>
            </div>
          </div>
          <div class="d-none d-sm-block">
            <div class="row">
              <div class="col">
                <b-table :items="features" :fields="['feature', 'minimum_price', 'price_per_unit', 'enabled', 'current_usage', 'current_cost']">
                  <!--                <template #head(feature)="data">-->
                  <!--                  <span></span>-->
                  <!--                </template>-->
                  <template #head(cost)="data">
                    <span></span>
                  </template>
                  <template #head(enable)="data">
                    <span></span>
                  </template>
                  <template v-slot:cell(feature)="row">
                    <p>{{row.item.name}}</p>
                    <small v-if="row.item.name === 'Waivers' || row.item.name === 'Surveys' || row.item.name === 'Checklists'"> Free when bundled with Bookings, Photos, or Game Management</small>
                  </template>
                  <template v-slot:cell(minimum_price)="row">
                    {{getAmount(row.item.minimum_price)}}
                  </template>
                  <template v-slot:cell(price_per_unit)="row">
                    {{getAmount(row.item.price_per_unit)}} / {{row.item.unit}}
                  </template>
                  <template v-slot:cell(current_cost)="row">
                    {{getAmount(row.item.amount_due)}}
                  </template>
                  <template v-slot:cell(current_usage)="row">
                    {{row.item.feature_usage}} {{row.item.unit}}s
                  </template>
                  <template v-slot:cell(enabled)="row">
                    <el-switch active-color="#e14eca" v-on:change="enableFeaturePrompt(row.item.is_enabled)"
                               v-model="row.item.is_enabled"  />
                  </template>

                </b-table>
              </div>
            </div>
          </div>
<!--          Mobile-->
          <div class="d-block d-sm-none">
            <div class="row">
              <div class="col">
                <b-table :items="features" :fields="['features']">
                  <!--                <template #head(feature)="data">-->
                  <!--                  <span></span>-->
                  <!--                </template>-->
                  <template v-slot:cell(features)="row">
                    <div class="row">
                      <div class="col">
                        <p style="font-size: large">{{row.item.name}}</p>
                      </div>
                      <div class="col-3 text-right">
                        <el-switch active-color="#e14eca"
                                   v-model="row.item.is_enabled"  />
                      </div>
                    </div>
                    <small class="mb-2" v-if="row.item.name === 'Waivers' || row.item.name === 'Surveys' || row.item.name === 'Checklists'">
                      Free when bundled with Bookings, Photos, or Game Management</small>
                    <p>Minimum price: {{getAmount(row.item.minimum_price)}}</p>
                    <p>Price per unit: {{getAmount(row.item.price_per_unit)}} / {{row.item.unit}}</p>
                    <p>Current cost: {{getAmount(row.item.amount_due)}}</p>
                    <p>Current usage: {{row.item.feature_usage}} {{row.item.unit}}</p>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col d-sm-none d-block text-right">
              <h4>Total due: {{ getAmount(statement.total) }}</h4>
            </div>
            <div class="col d-sm-block d-none">
              <h4>Total due: {{ getAmount(statement.total) }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-button v-on:click="updatePlanPrompt()" type="primary">Update</base-button>
            </div>
          </div>
        </div>

<!--        Billing-->
        <div v-if="activeTabIndex === 1">
          <div v-if="billing && !add_payment">
            <div class="row">
              <div class="col">
                <label>Payment method</label>
                <p><span class="font-weight-600">{{ billing.card_type }}: </span>xxxx-xxxx-xxxx-{{billing.last_four}}</p>
                <p>
                  <span class="font-weight-600"></span>
                  {{billing.accountholder_first_name}} {{billing.accountholder_last_name}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Billing address</label>
                <p>{{billing.address_line_1}}</p>
                <p>{{billing.address_line_2}}</p>
                <p>{{billing.city}}, {{billing.state}}, {{billing.zip}}</p>
                <p>{{billing.country.name}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-button type="primary" v-on:click="editBillingInfo()">
                  Edit billing information</base-button>
              </div>
            </div>
          </div>


          <div v-if="!billing && !add_payment">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Billing information has not been added yet.</h4>
            </div>
            <div class="row">
              <div class="col">
                <base-button type="primary" v-on:click="addBillingInfo()">
                  Add billing information</base-button>
              </div>
            </div>
          </div>

          <div v-if="add_payment">
            <div class="row">
              <div class="col">
                <h4 class="card-title">Add a new credit card</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3">
                <base-input v-model="billing.accountholder_first_name" label="Cardholder first name" placeholder="Cardholder first name"></base-input>
              </div>
              <div class="col-lg-3">
                <base-input v-model="billing.accountholder_last_name" label="Cardholder last name" placeholder="Cardholder last name"></base-input>
              </div>
            </div>
            <!--                  Square-->
            <label>Card details</label>
            <div class="row">
              <div class="col-lg-6">
                <form id="payment-form-square" >
                  <div id="card-container"></div>
                </form>
                <div id="payment-status-container"></div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="card-title">Billing address</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <base-input v-model="billing.address_line_1" label="Address line 1" placeholder="Address line 1"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <base-input v-model="billing.address_line_2" label="Address line 2" placeholder="Address line 2"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3">
                <base-input v-model="billing.city"  :label="billing.country.city_label" :placeholder="billing.country.city_label"></base-input>
              </div>
              <div class="col-lg-3">
                <base-input v-if="billing.country.state_label === 'State'" :label="billing.country.state_label">
                  <el-select
                    v-model="billing.state"
                    class="select-primary"
                    :placeholder="billing.country.state_label"
                  >
                    <el-option
                      v-for="(s, i) in states"
                      class="select-primary"
                      :label="s.name"
                      :value="s.name"
                      :key="'S' + i"
                    ></el-option>
                  </el-select>
                </base-input>
                <base-input v-else :placeholder="billing.country.state_label" :label="billing.country.state_label" v-model="billing.state"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2">
                <base-input v-model="billing.zip"  :label="billing.country.zip_label" :placeholder="billing.country.zip_placeholder"></base-input>
              </div>
              <div class="col-lg-4">
                <base-input label="Country">
                  <el-select
                             v-model="billing.country"
                             class="select-primary"
                             name="email_group"
                             placeholder="Country"
                             value-key="code"
                  >
                    <el-option
                      v-for="(c, i) in countries"
                      class="select-primary"
                      :label="c.name"
                      :value="{name: c.name, code: c.code, city_label: c.city_label,
                      state_label: c.state_label, zip_label: c.zip_label, zip_placeholder: c.zip_placeholder}"
                      :key="'C' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <base-button type="default" v-on:click="cancelAddPayment()">Cancel</base-button>
                <base-button type="primary" class="ml-2" v-on:click="createBillingInfo()">Save billing information</base-button>
              </div>
            </div>
          </div>
        </div>

  <!--        Invoice-->
        <div v-if="activeTabIndex === 2">
          <div class="row">
<!--            <div class="col">-->
<!--              <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>-->
<!--            </div>-->
            <div class="col">
<!--              <base-button icon class="btn-link text-white" v-on:click="downloadCSV()">-->
<!--                <i class="tim-icons icon-cloud-download-93"></i>-->
<!--              </base-button>-->
              <el-select
                class="select-primary pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="getInvoices(perPage, getOffset())"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="invoices.length <= 0" class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No invoices exist yet.</h4>
          </div>

          <div class="d-none d-sm-block">
            <div class="row" v-if="invoices.length > 0">
              <div class="col">
                <b-table :items="invoices" :fields="['billing_period', 'invoice_id', 'payment_date',  'payment_method', 'status', 'amount', 'actions']">
                  <template v-slot:cell(amount)="row">
                    {{getAmount(row.item.actual_total)}}
                  </template>
                  <template v-slot:cell(invoice_id)="row">
                    <span v-if="row.item.invoice_id">#{{row.item.invoice_id}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-tooltip content="Download invoice"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click.native="downloadInvoice(row.item)"
                        class="like btn-link text-white"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-cloud-download-93"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                  <template v-slot:cell(payment_date)="row">
                    <span v-if="row.item.time_paid">{{getLocalDate(row.item.time_paid)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(payment_method)="row">
                    <span v-if="row.item.status === 1">Card ending in {{row.item.payment_last_four}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(billing_period)="row">
                    {{getMonthAndYear(row.item.billing_cycle_start)}}
                  </template>
                  <template v-slot:cell(status)="row">
                    <badge v-if="row.item.status === 2" type="warning">payment failed</badge>
                    <badge v-if="row.item.status === 1" type="success">paid</badge>
                    <badge v-if="row.item.status === 0" type="danger">due</badge>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>

          <div class="d-block d-sm-none">
            <div class="row" v-if="invoices.length > 0">
              <div class="col">
                <b-table :items="invoices" :fields="['invoices']">
                  <template v-slot:cell(invoices)="row">
                    <div class="row">
                      <div class="col-8">
                        <p style="font-size: large" >{{getMonthAndYear(row.item.billing_cycle_start)}}
                          <el-tooltip content="Download invoice"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                            <base-button
                              @click.native="downloadInvoice(row.item)"
                              class="like btn-link text-white"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-cloud-download-93"></i>
                            </base-button>
                          </el-tooltip>
                        </p>
                        <small v-if="row.item.invoice_id">#{{row.item.invoice_id}}</small>
                      </div>
                      <div class="col text-right">

                        <badge v-if="row.item.status" type="success">paid</badge>
                        <badge v-if="!row.item.status" type="danger">due</badge>
                      </div>
                    </div>
                    <p v-if="row.item.status === 1">Payment date: {{getLocalDate(row.item.time_paid)}}</p>
                    <p v-if="row.item.status === 1">Payment method: Card ending in {{row.item.payment_last_four}}</p>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>


          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :paginate-elements-by-height="800"
            :enable-download="false"
            filename="invoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="invoicePdf"
            @beforeDownload="beforeDownload($event)"
          >
            <section slot="pdf-content">
              <div style="color: #222a42;
                font-family: 'Poppins', sans-serif;
                padding: 40px;
                width: 800px;">
                <!-- Header Section -->
                <div style=" display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 20px;
                      color: #222a42;">
                  <h1 style="color: #222a42">Invoice</h1>
                  <div style="margin: 0;
                        text-align: right;">
                    <img style="z-index: 100;" height="50" src="/img/otc-logo.png">
                    <p style="font-size: large; color: #222a42">Off The Couch Inc.</p>
                    <p style="font-size: small; color: #222a42">555 Mowry Ave.</p>
                    <p style="font-size: small; color: #222a42">Fremont
                      <span>CA</span> 94536</p>

                    <p style="font-size: small; color: #222a42">+1 (408) 478-2341</p>
                  </div>
                </div>

                <!-- Billing Section -->
                <div class="row" style="display: flex;
                    margin-bottom: 20px; margin-top: 10px;">
                  <div class="col-5">
                    <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Billed To</strong></p>
                    <p style="color: #222a42; margin: 0;" v-if="selectedInvoice.billed_to_first_name">{{ selectedInvoice.billed_to_first_name }} {{ selectedInvoice.billed_to_last_name }}</p>
                    <p v-if="selectedInvoice.billed_to_email" style="color: #222a42; margin: 0;">{{ selectedInvoice.billed_to_email }}</p>
                    <p v-if="selectedInvoice.billed_to_phone" style="color: #222a42; margin: 0;">{{ selectedInvoice.billed_to_phone }}</p>
                    <p v-if="selectedInvoice.address_line_1" style="color: #222a42; margin: 0;">{{ selectedInvoice.address_line_1 }}</p>
                    <p v-if="selectedInvoice.address_line_2" style="color: #222a42; margin: 0;">{{ selectedInvoice.address_line_2 }}</p>
                    <p v-if="selectedInvoice.address_line_3" style="color: #222a42; margin: 0;">{{ selectedInvoice.address_line_3 }}</p>
                    <p v-if="!selectedInvoice.billed_to_first_name">-</p>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Date Issued</strong></p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Date Due</strong></p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Invoice Number</strong></p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p style="color: #222a42; margin: 0;">{{ getLocalDate(selectedInvoice.billing_cycle_start) }}</p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;">{{ getLocalDate(selectedInvoice.time_due) }}</p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;">#{{selectedInvoice.invoice_id}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style="border: 1px solid #3358f4">

                <!-- Line Items Section -->
                <table style="width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;">
                  <thead>
                  <tr>
                    <th style="color: #3358f4; padding-bottom: 10px;
                    text-align: left;">Feature</th>
                    <th style="color: #3358f4; padding-bottom: 10px;
                    text-align: right;">Price</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(b, i) in selectedInvoice.items" :key="'ITEMS' + i">
                    <td style="padding-bottom: 7px;">
                      <span>{{ b.name }}</span>
                    </td>
                    <td style="padding-bottom: 7px; text-align: right;">
                      <span>{{ getAmount(b.amount) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <hr style="border: 1px solid #3358f4">

<!--                 Totals Section -->
                <div class="row justify-content-end">
                  <div class="col-6 text-right" style="margin-bottom: 20px;">
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Total</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(selectedInvoice.actual_total)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between" v-if="selectedInvoice.discount > 0 || computed_dicount > 0 ">
                      <div class="col-9 text-left">
                        <p style="color: #ee0c0c"><strong>Discount</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #ee0c0c; ">-{{getAmount(selectedInvoice.discount)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Amount paid</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(selectedInvoice.paid)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Amount due</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(selectedInvoice.due)}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col">
                    <p style="color: #222a42">If you have questions, please email us at
                      <a href="mailto:contact@offthecouch.io">contact@offthecouch.io</a>
                    </p>
                  </div>
                </div>
              </div>
            </section>

          </vue-html2pdf>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import countries from "@/assets/countries.json";
import states from "@/assets/states.json";
import {BPagination, BTable} from "bootstrap-vue";
import currencies from "@/assets/currencies.json";
import _ from "lodash";
import BaseSwitch from "@/components/BaseSwitch";
import Badge from "@/components/Badge";
import {dayOfWeek, formattedDate} from "@/plugins/dateFormatter";
import {Dropdown, DropdownItem, DropdownMenu, Switch} from "element-ui";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import {AmplifyEventBus} from "aws-amplify-vue";


export default {
  name: "BillingPage",
  components: {
    BaseSwitch,
    BTable,
    BPagination,
    Badge,
    [Switch.name]: Switch,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    VueHtml2pdf
  },
  data(){
    return {
      loading: false,
      trial_ended: true,
      trial_start: '',
      trial_end: '',
      computed_dicount: 0,
      countries: countries.countries,
      states: states.states,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      currency_symbol: '$',
      activeTabIndex: 0,
      add_payment: false,
      perPageOptions: [5, 10, 25, 50],
      j: 0,
      billing: {
        country:{
          "city_label": "City",
          "state_label": "State",
          "zip_label": "Zip",
          "zip_placeholder": "95133",
        }
      },
      square: '',
      card: '',
      squareAppId: '',
      squareLocationId: '',
      features: [],
      invoices: [],
      statement: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      selectedInvoice: {
        billed_to_first_name: '',
        billed_to_last_name: '',
        billed_to_email: '',
        billed_to_phone: '',
        time_due: '',
        time_paid: ''
      }
    }
  },
  computed: {
    categories() {
      return [{ name: 'Your plan', icon: 'tim-icons el-icon-goods', active: true },
        { name: 'Billing information', icon: 'tim-icons icon-bank', active: false },
        { name: 'Invoices', icon: 'tim-icons el-icon-tickets', active: false }
      ];
    }
  },
  methods: {
    deleteAccountPrompt(event){
      event.preventDefault()

      swal({
        title: 'Are you sure?',
        text: "This will delete your account and all users will lose access. If there is anything we can help you with, please reach out to contact@offthecouch.io",
        type: 'warning',
        confirmButtonText: 'Delete',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          this.deleteAccount()
        }
      })
    },
    async deleteAccount(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          Auth.signOut().then(response => {
            AmplifyEventBus.$emit('authState', 'signedOut')
            this.$router.push('/login')
          })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else if (err.response.status == 500) {
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getTrialDaysEligibilityThisMonth(trial_start, trial_end) {
      // Parse start and end dates
      let start = new Date(trial_start);
      let end = new Date(trial_end);

      // Get the current date and calculate the start of the month and the start of the next month
      let today = new Date();
      let currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let nextMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);

      // Find the eligible start and end dates in this month
      let eligibleStart = (start > currentMonthStart) ? start : currentMonthStart;
      let eligibleEnd = (end < nextMonthStart) ? end : new Date(nextMonthStart - 1);

      console.log("trial_start: " + this.trial_start)
      console.log("trial_end: " + this.trial_end)
      // Calculate the total eligible days
      if (eligibleEnd >= eligibleStart) {
        return (eligibleEnd - eligibleStart) / (1000 * 60 * 60 * 24) + 1;
      } else {
        return 0;
      }
    },
    async beforeDownload({html2pdf, options, pdfContent}) {
      this.loading = true
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      })
        .save()
      this.loading = false
    },
    downloadInvoice(item) {
      this.selectedInvoice = item
      console.log(this.selectedInvoice)
      this.$refs.invoicePdf.generatePdf()
    },
    getDayOfWeek(d) {
      return ''
    },
    getFormattedDate(d) {
      return formattedDate(this.date_format, d)
    },
    getLocalDate(d) {
      let local = moment(d, 'YYYY-MM-DD').format('YYYY-MM-DD')
      let final = this.getFormattedDate(local)
      return this.getDayOfWeek(local) + final
    },
    getMonthAndYear(dateString) {
      // Manually parse the date string to avoid any timezone issues
      if (!dateString) {
        return
      }
      const parts = dateString.split('T')[0].split('-');
      const year = parts[0];
      const monthIndex = parseInt(parts[1], 10) - 1; // Months are 0-indexed
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const month = monthNames[monthIndex];
      return `${month} ${year}`;
    },
    getAmount(integer) {
      if (integer == null) {
        return
      }
      if (this.number_format === 1 && this.currency_format === 0) {
        return integer.toFixed(3).endsWith('0') ?
          '$' + integer.toLocaleString(undefined, {minimumFractionDigits: 2}).replace('.', ',') :
          '$' + integer.toLocaleString(undefined, {minimumFractionDigits: 3}).replace('.', ',')
      } else if (this.number_format === 1 && this.currency_format === 1) {
        return integer.toFixed(3).endsWith('0') ?
          integer.toLocaleString(undefined, {minimumFractionDigits: 2}).replace('.', ',') + ' ' + 'USD' :
          integer.toLocaleString(undefined, {minimumFractionDigits: 3}).replace('.', ',') + ' ' + 'USD'

      } else if (this.number_format === 1 && this.currency_format === 1) {
        return integer.toFixed(3).endsWith('0') ?
          parseFloat(integer).toFixed(2) + ' ' + 'USD' :
          parseFloat(integer).toFixed(3) + ' ' + 'USD'
      }
      return integer.toFixed(3).endsWith('0') ?
        '$' + parseFloat(integer).toFixed(2) :
        '$' + parseFloat(integer).toFixed(3)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    addBillingInfo() {
      if (!this.billing) {
        this.billing = {
          accountholder_first_name: '',
          accountholder_last_name: '',
          address_line_1: '',
          address_line_2: '',
          zip: '',
          state: '',
          city: '',
          country: _.find(this.countries, {'name': 'United States'})
        }
      }
      this.add_payment = true
      this.initializeSquare()
    },
    cancelAddPayment() {
      this.add_payment = false
    },
    editBillingInfo() {
      this.add_payment = true
      this.initializeSquare()
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },
    async createBillingInfo() {
      this.loading = true
      let token = ''
      try {
        token = await this.tokenize(this.card);
      } catch (error) {
        console.error('Error:', error);
        this.loading = false
        return
      }

      this.loading = true
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/billing'
      let data = {
        billing: this.billing,
        token: token
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'Billing information has been updated', 'success')
          this.add_payment = false
          this.getBillingInfo()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    enableFeaturePrompt(status) {
      if (status && this.trial_ended) {
        swal({
          title: 'Are you sure?',
          text: `By enabling this feature, I acknowledge that I will be charged for this feature.`,
          type: 'warning',
          showCancelButton: true,
        })
      }
    },
    async updatePlanPrompt() {
      await this.updatePlan()
    },
    async updatePlan() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/billing/plan'
      let data = {
        plan_id: this.statement.id,
        features: this.features
      }
      this.axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Plan has been updated.', 'success')
          this.getBillingInfo()
          this.getInvoices(this.perPage, this.getOffset())
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else if (err.response.status == 500) {
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getBillingInfo() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/billing'
      this.axios.get(url, config)
        .then(response => {
          this.billing = response.data.billing
          if (this.billing) {
            this.billing.country = _.find(this.countries, {'code': this.billing.country})
          }
          this.features = response.data.features

          for (let n in this.features) {
            this.features[n].is_enabled = Boolean(this.features[n].is_enabled)
          }
          this.statement = response.data.statement
          this.squareAppId = response.data.square.squareAppId
          this.squareLocationId = response.data.square.squareLocationId
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async getInvoices(count, offset) {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/billing/invoices?count=' + count + '&offset=' + offset
      this.axios.get(url, config)
        .then(response => {
          this.invoices = response.data.invoices
          for (let n in this.invoices) {
            this.invoices[n].items = JSON.parse(this.invoices[n].items)
          }
          this.totalRows = response.data.total_rows
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    getOffset() {
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
    switchTab(index) {
      this.activeTabIndex = index
      for (let n in this.categories) {
        this.categories[n].active = false
      }
      this.categories[index].active = true
      if (this.activeTabIndex === 1) {
        this.loadSquare()
      }
      this.j++
    },
    async loadSquare() {
      const script = document.createElement('script');
      script.src = 'https://web.squarecdn.com/v1/square.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeSquare() {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if (this.card) {
        await this.card.destroy()
      }

      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      try {
        this.card = await this.initializeCard(this.square);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
  },
  mounted() {
    if(localStorage.getItem('trial')){
      let trial = JSON.parse(localStorage.getItem('trial'))
      this.trial_ended = false
      this.trial_start = trial.trial_start
      this.trial_end = trial.trial_end
      let today = new Date();
      let trial_end = new Date(trial.trial_end);
      if(today > trial_end){
        this.trial_ended = true
      }
    }

    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.getBillingInfo()
    this.getInvoices(this.perPage, this.getOffset())
  }
}
</script>

<style scoped>

</style>
