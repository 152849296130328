<template>
  <div>
    <div v-if="loading" element-loading-text="Loading..."
         element-loading-spinner="el-icon-loading"
         v-loading="loading" class="el-loading-mask" id="loading">
    </div>
    <div class="mt-3" v-if="empty === true">
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">You don't have any games set up.</h4>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-3 col-md-3 col-sm-3 col-12" v-for="game in games" :key="game.id">
        <card>
          <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
          <div class="row mt-3 justify-content-center">
            <div class="col-lg-12 ">
              <base-button simple size="lg" v-on:click="selectGame(game)"
                           class="game-button"
                           type="success">
                {{game.name}}</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: "GameSelect",
  data() {
    return {
      loading: false,
      empty: false,
      games: []
    }
  },
  methods: {
    selectGame(item){
      localStorage.setItem("selectedGameForClues", JSON.stringify(item.id))
      this.$router.push('/clues/display')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGames(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.games = response.data
          if(this.games.length <= 0){
            this.empty = true
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted(){
    this.getGames()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 150px; /* height of container */
  object-fit: contain;
}
.game-button{
  width: 100%;
}
</style>
