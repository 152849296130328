<template>
  <div>
    <h5 class="info-text">
       Details about your group
    </h5>
    <div class="row justify-content-center mt-5">
      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>Players</label>
            <el-popover trigger="hover"
                        placement="bottom">
              <div>
                <div class="popover-body">Confirm which players are part of this group</div>
              </div>
              <base-button class="like btn-link"
                           type="info"
                           size="sm"
                           icon
                           slot="reference">
                <i class="tim-icons icon-alert-circle-exc"></i>
              </base-button>
            </el-popover>

          </div>
          <div class="col-sm-8">
            <div class="mt-3" v-if="model.users.length <= 0">
              <div class="font-icon-detail">
                <span style="font-size: 2em">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <h5 class="mt-3 ml-auto mr-auto">No players available. Make sure everyone in the group has checked in.</h5>
              </div>
            </div>
            <b-table :key="k" v-if="model.users.length > 0" striped hover :items="model.users"
                      :fields="fields">
              <template v-slot:cell(name)="row">
                <div class="row">
                  <div class="col-10">
                    {{ row.item.first_name }} {{row.item.last_name}}
                    <span class="ml-2 text-primary small text-uppercase" type="primary" v-if="row.item.is_minor == 1">minor</span>
                  </div>
                  <div class="col-2">
                    <base-checkbox v-on:change="toggleConfirmed(row.item.confirmed, selectedUser[row.index])" v-model="selectedUser[row.index]"></base-checkbox>
                  </div>
                </div>
              </template>
            </b-table>
         </div>
        </div>
      </div>

      <div class="col-sm-7" v-if="model.users.length > 0">
        <div class="row">
          <div class="col-sm-4">
            <label>Team</label>
          </div>
          <div class="col-sm-8">
            <base-input v-model="model.team" placeholder="Team name"></base-input>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
           <label>Start time</label>
          </div>
          <div class="col">
            <base-input :error="modelValidations.start_time">
              <el-time-picker v-on:input="validateTime()"
                v-model="model.start_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
          <div class="col">
            <base-button
              @click.native="setNow()"
              class="now-field"
              type="info"
            >Now
            </base-button>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>Pre-game notes</label>
            <el-popover trigger="hover"
                        placement="bottom">
              <div>
                <div class="popover-body">Anything noteworthy before the game?<br>
                  (Problem during reset, late players, etc.)</div>
              </div>
              <base-button class="like btn-link"
                           type="info"
                           size="sm"
                           icon
                           slot="reference">
                <i class="tim-icons icon-alert-circle-exc"></i>
              </base-button>
            </el-popover>

          </div>
          <div class="col-sm-8">
            <base-input :error="getError('notes')">
              <textarea
                class="mt-1 form-control"
                placeholder="Start typing..."
                name="notes"
                v-model="model.pre_notes"
                v-validate="modelValidations.pre_notes"
                rows="2"></textarea>
            </base-input>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>Team experience</label>
          </div>
          <div class="col-sm-8">
            <base-radio name="New" class="mb-3" v-model="model.team_experience">
              New
            </base-radio>

            <base-radio name="Some have played before" class="mb-3" v-model="model.team_experience">
              Some have played before
            </base-radio>


            <base-radio name="Experienced" class="mb-3" v-model="model.team_experience">
              Experienced
            </base-radio>

            <base-radio name="Expert" class="mb-3" v-model="model.team_experience">
              Expert
            </base-radio>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { BaseRadio } from 'src/components/index';
  import {Table, TableColumn, TimeSelect, Popover, TimePicker} from 'element-ui';
  import { API_LOCATION } from '../../../api-config'
  import { Auth } from 'aws-amplify'
  import { BTable } from 'bootstrap-vue'
  import swal from "sweetalert2";
  const axios = require('axios')


  export default {
      name: "PreGameNoteStep",
      components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [TimeSelect.name]: TimeSelect,
        [Popover.name]: Popover,
        [TimePicker.name]: TimePicker,
        BTable,
        BaseRadio
      },
      data() {
        return {
          selectedUser: [],
          picker_time_format: 'hh:mm A',
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          k: 0,
          fields: ['name'],
          model: {
            start_time: '',
            pre_notes: '',
            team_experience: 'New',
            team: '',
            users: []
          },
          dd: 'AM',
          hh: '',
          mm: '',
          modelValidations: {
            start_time: '',
            pre_notes: {
              required: false,
            },
            team_experience: {
              required: false,
            },
          }
        };
      },
      methods: {
        validateTime(){
          let today = new Date()
          this.model.start_time.setMonth(today.getMonth(), today.getDate())
        },
        toggleConfirmed(destination, source){
          this.model.users[0].confirmed = false
          this.k++
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        },
        setNow () {
          this.model.start_time = new Date()
        },
        populateDraft(){
          let draft = JSON.parse(localStorage.getItem('draft'))
          if(draft){
            if(draft.actual_start_time){
              let time = new Date(draft.actual_start_time)
              time.setHours(draft.actual_start_time.split('T')[1].split(':')[0])
              time.setMinutes(draft.actual_start_time.split('T')[1].split(':')[1])
              this.model.start_time = time
            } else {
              this.model.start_time = null
            }
            if(draft.team_experience_level){
              if(draft.team_experience_level === 1){
                this.model.team_experience = 'New'
              } else if(draft.team_experience_level === 2){
                this.model.team_experience = 'Some have played before'
              } else if(draft.team_experience_level === 3){
                this.model.team_experience = 'Experienced'
              } else if(draft.team_experience_level === 4){
                this.model.team_experience = 'Expert'
              }
            }
            if(this.model.users.length > 0){
              this.model.team = this.model.users[0].team_name
            }
            this.model.pre_notes = draft.pre_game_notes
          }
        },
        async setPreNotesData () {
          localStorage.setItem('preNotes', JSON.stringify(this.model))

          //save draft
          let companyId = localStorage.getItem('companyId')
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'companies/' + companyId
            + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'

          if(this.model.team == null){
            this.model.team = ''
          }

          let data = {
            start_time: this.model.start_time.getFullYear() + '-' + (this.model.start_time.getMonth()+1) + '-' + this.model.start_time.getDate() +
              ' ' + this.model.start_time.getHours() + ':' + this.model.start_time.getMinutes(),
            team_experience_level: this.model.team_experience,
            team_name: this.model.team,
            team_id: '',
            pre_game_notes: this.model.pre_notes,
            users: this.model.users
          }
          if(this.model.users.length > 0){
            data.team_id = this.model.users[0].team_id
          }
          for(let n in data.users){
            data.users[n].confirmed = this.selectedUser[n]
          }
          axios.put(url, data, config)
            .then(response => {
              let draft = JSON.parse(localStorage.getItem('draft'))
              draft.actual_start_time = this.model.start_time.getFullYear() + '-' +
                ('0' + (this.model.start_time.getMonth()+1)).slice(-2) + '-' + ('0' + this.model.start_time.getDate()).slice(-2) +
                'T' + ('0' + (this.model.start_time.getHours())).slice(-2) + ':' + ('0' + (this.model.start_time.getMinutes())).slice(-2) + ':00.000Z'
              draft.team_experience_level = data.team_experience_level
              draft.team_name = data.team_name
              draft.team_id = data.team_id
              draft.pre_game_notes = data.pre_game_notes
              draft.users = data.users
              localStorage.setItem('draft', JSON.stringify(draft))
            })
            .catch(err => {
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        async getUsers(gameId, bookingId){
          this.loading = true
          var token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId +
            '/bookings/' + bookingId + '/users'
          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.model.users = response.data.users
              for(let n in this.model.users){
                this.model.users[n].confirmed = true
                this.selectedUser[n] = true
              }
              if(this.model.users.length > 0){
                this.model.team = this.model.users[0].team_name
              }

            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async validate() {
          if(!this.model.start_time || this.model.start_time === "NaN:"){
            this.modelValidations.start_time = 'Start time cannot be empty'
            return
          } else {
            this.modelValidations.start_time = ''
          }
          await this.setPreNotesData()
          return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
          });
        }
      },
    mounted() {
      if(this.time_format === 'HH:mm'){
        this.picker_time_format = 'HH:mm'
      }
    }
  }
</script>

<style scoped>
  .now-field{
    height: 2.3rem;
    margin-top: -0.05rem;
  }
</style>
