<template>
  <div class="container not-found-page ">
    <div class="not-found-page">
      <h1>404</h1>
      <img src="https://console.offthecouch.io/img/pandas/search.png">
      <p class="mt-4">The page you requested could not be found.</p>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
    let mode = localStorage.getItem('mode')
    localStorage.clear()
    localStorage.setItem('mode', mode)
    this.$router.push('/login')
  }
};
</script>
<style scoped>
.not-found-page {
  min-height: calc(120vh - 160px);
  display: flex;
  padding-bottom: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
