<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/bookings/schedules">Schedules</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Create schedule</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row mt-3 overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4  class="card-title">Schedule settings</h4>
          <div>
            <div class="row">
              <div class="col-lg-3">
                <base-input :error="errs.name" label="Schedule name" type="text" v-model="schedule.name"  placeholder="Name"></base-input>
              </div>
              <div class="col-lg-2">
                <base-input label="Schedule type">
                  <el-select
                    v-model="schedule.type"
                    class="select-primary"
                    name="email_group"
                    placeholder="Schedule type"
                    value-key="id"
                    v-on:change="adjustScheduleDuration()"
                  >
                    <el-option
                      v-for="(t, i) in schedule_types"
                      class="select-primary"
                      :label="t.name"
                      :value="{ id: t.id, name: t.name}"
                      :key="'ST' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4" v-if="schedule.type.id === 0">
                <base-input label="Associated events">
                  <el-select
                    label="Select game"
                    v-model="schedule.games"
                    class="select-primary"
                    value-key="id"
                    multiple
                  >
                    <el-option
                      v-for="(g, i) in games"
                      class="select-primary"
                      :label="g.name"
                      :value="{ name: g.name, id: g.id} "
                      :key="'G' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label>
                  <span>Schedule duration
                    <el-popover v-if="schedule.type.id === 0" trigger="hover"
                                placement="right">

                      <div>
                        <div class="popover-body">When an event is assigned to multiple schedules,
                          priority is <br/> given to closed periods over finite schedules,
                          and finite <br/> schedules take precedence over recurring schedules.</div>
                      </div>
                      <base-button class="like btn-link"
                                   type="info"
                                   size="sm"
                                   icon
                                   slot="reference">
                        <i class="tim-icons el-icon-info"></i>
                      </base-button>
                    </el-popover>
                  </span>
                </label>
                <base-input>
                  <el-select
                    v-model="schedule.duration_type"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(f, i) in duration_types"
                      class="select-primary"
                      :label="f.label"
                      :value="{id: f.id, label: f.label}"
                      :key="'F' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div v-if="schedule.duration_type.label !== 'Recurring'" class="col-lg-2">
                <base-input label="Start date" :error="errs.start_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    :format="day_of_week + '' + date_format_cal"
                    value-format="yyyy-MM-dd"
                    v-on:change="validateDateRange()"
                    name="date"
                    v-model="schedule.start_date"
                    :picker-options="{ disabledDate: (time) => disabledDates(time, schedule.start_date) }"
                  >
                  </el-date-picker>
                </base-input>
              </div>
              <div v-if="schedule.duration_type.label !== 'Recurring'" class="col-lg-2">
                <base-input label="End date" :error="errs.end_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    :format="day_of_week + '' + date_format_cal"
                    v-on:change="validateDateRange()"
                    name="date"
                    v-model="schedule.end_date"
                    :picker-options="{ disabledDate: (time) => disabledDates(time, schedule.end_date) }"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>

            <label class="mt-3">Schedule status on creation</label>
            <div class="row">
              <div class="col">
                <base-radio style="display: inline-block" name="1" v-model="schedule.active">
                  Generate live time slots on creation of this schedule.
                </base-radio>
                <badge style="display: inline-block" type="success" class="ml-2">active</badge>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-radio style="display: inline-block" name="0" v-model="schedule.active">
                  Do not generate time slots until the schedule is activated.
                </base-radio>
                <badge style="display: inline-block" type="danger" class="ml-2">inactive</badge>
              </div>
            </div>

            <!--            Time slots-->
            <div v-show="schedule.duration_type.label !== 'Closed period'">
              <hr style="background-color: rgba(255,255,255,0.32)"/>
              <h4  class="card-title">Time slots</h4>
              <div class="row">
                <div class="col">
                  <base-button size="sm" class="hover-link" link style="padding-left: 0px; padding-top: 0px" simple type="primary" v-on:click="helpGenerate()">Help me generate slots
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                  <base-button size="sm" class="hover-link" link style="padding-left: 0px; padding-top: 0px" simple type="primary" v-on:click="bulkUpdate()">Bulk update time slot settings
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </div>
              </div>

              <div v-if="bulk_update_settings">
                <div class="row" >
                  <div class="col-lg-2">
                    <base-input label="Slot status" >
                      <el-select
                        v-model="schedule.status"
                        class="select-primary"
                        placeholder="Status"
                        value-key="id"
                        v-on:change="j++"
                      >
                        <el-option v-if="schedule.type.id === 0"
                                   v-for="(s, i) in booking_statuses"
                                   class="select-primary"
                                   :label="s.name"
                                   :value="{id: s.id, name: s.name, label: s.label}"
                                   :key="'S' + i"
                        ></el-option>
                        <el-option v-if="schedule.type.id === 1"
                                   v-for="(s, i) in resource_statuses"
                                   class="select-primary"
                                   :label="s.name"
                                   :value="{id: s.id, name: s.name, label: s.label}"
                                   :key="'S' + i"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-6" v-if="schedule.type.id === 1">
                    <label>
                      <div>Concurrent events
                        <el-popover trigger="hover"
                                    placement="top">

                          <div>
                            <div class="popover-body">Number of events this resource can support at the same time</div>
                          </div>
                          <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                        </el-popover>
                      </div>
                    </label>
                    <div class="row">
                      <div class="col-lg-5">
                        <base-input>
                          <el-input-number size="small" v-on:input="j++" v-model="schedule.supported_event_count" :min="1" />
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="schedule.type.id === 1">
                  <div class="row">
                    <div class="col">
                      <el-switch v-on:input="j++" active-color="#e14eca"
                                 v-model="schedule.enable_autoblock"  />
                      <label> &nbsp; Block events with insufficient resource coverage</label>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="schedule.enable_autoblock">
                    <div class="col-lg-3">
                      <base-input label="Block slots with the following status">
                        <el-select
                          v-model="schedule.block_status"
                          class="select-primary"
                          placeholder="Status"
                          value-key="id"
                          v-on:change="j++"
                        >
                          <el-option
                            v-for="(s, i) in block_statuses"
                            class="select-primary"
                            :label="s.name"
                            :value="{id: s.id, name: s.name, label: s.label}"
                            :key="'S' + i"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col">
                      <el-switch v-on:input="j++" active-color="#e14eca" v-on:change="toggleAnonymousResource(schedule)"
                                 v-model="schedule.anonymous_resource"  />
                      <label> &nbsp; Anonymous resource</label>
                    </div>
                  </div>

                  <div class="row" v-if="schedule.anonymous_resource">
                    <div class="col-lg-6">
                      <base-input label="Resource label" :error="schedule.label_error" placeholder="Anonymous" v-model="schedule.label"></base-input>
                    </div>
                  </div>

                  <div class="row" v-if="!schedule.anonymous_resource">
                    <div class="col-lg-6">
                      <base-input label="Add staff (search by name or email)" :error="schedule.staff_error">
                        <el-autocomplete style="width: 100%" v-on:input="j++"
                                         v-model="schedule.email"
                                         :fetch-suggestions="querySearchCu"
                                         placeholder="Search"
                                         :trigger-on-focus="false"
                                         @select="handleSelectRuS"
                        >
                          <template #default="{ item }">
                            <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                            <small class="font-italic">
                              <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                            </small>
                          </template>
                        </el-autocomplete>
                      </base-input>
                    </div>
                  </div>
                </div>

                <!--              Min/max-->
                <div class="row" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                  <div class="col-lg-3">
                    <label>Minimum notice</label>
                  </div>
                  <div class="col-lg-3">
                    <label>Maximum notice in advance</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-1" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                    <base-input v-on:change="j++" v-model="schedule.min_booking_value"
                                type="number">
                    </base-input>
                  </div>
                  <div class="col-lg-2" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                    <base-input>
                      <el-select
                        label="Select game"
                        v-model="schedule.min_booking_period"
                        class="select-primary"
                        value-key="id"
                        v-on:change="j++"
                      >
                        <el-option
                          v-for="(p, index) in min_periods"
                          class="select-primary"
                          :label="p.label"
                          :value="{id: p.id, label: p.label}"
                          :key="'M' + index"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-1" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                    <base-input v-on:change="j++" v-model="schedule.max_booking_value"
                                type="number">
                    </base-input>
                  </div>
                  <div class="col-lg-2" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                    <base-input >
                      <el-select
                        label="Select period"
                        v-model="schedule.max_booking_period"
                        class="select-primary"
                        v-on:change="j++"
                        value-key="id"
                      >
                        <el-option
                          v-for="(p, index) in max_periods"
                          class="select-primary"
                          :label="p.label"
                          :value="{id: p.id, label: p.label}"
                          :key="'X' + index"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <!--              Call to book-->
                <div class="row" v-if="schedule.type.id === 0">
                  <!--              Call to book or blocked-->
                  <div class="col-lg-3"  :key="'B' + j">
                    <label v-if="schedule.status.id === 0">Minimum notice block off text
                      <el-popover trigger="hover" placement="right">
                        <div>
                          <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                          </div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </label>
                    <label v-else>Time slot text
                      <el-popover trigger="hover" placement="right">
                        <div>
                          <div class="popover-body">This text will be visible to customers when a slot has the status 'Call to book'.
                          </div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </label>
                    <base-input v-on:change="j++" v-model="schedule.slot_text"></base-input>
                  </div>
                  <div class="col-lg-3">
                    <base-input label="External link" v-on:change="j++"  v-if="schedule.has_external_link"  v-model="schedule.external_link" placeholder="https://example.com"></base-input>
                    <base-checkbox v-on:input="j++" v-model="schedule.has_external_link">Include an external link
                    </base-checkbox>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col">
                    <base-input label="Applicable to days">
                      <div class="row">
                        <div class="col">
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[0]">Mon</base-checkbox>
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[1]">Tue</base-checkbox>
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[2]">Wed</base-checkbox>
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[3]">Thu</base-checkbox>
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[4]">Fri</base-checkbox>
                          <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[5]">Sat</base-checkbox>
                          <base-checkbox style="display: inline-block" v-model="applicableDays[6]">Sun</base-checkbox>
                        </div>
                      </div>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="row" v-if="help_generate" >
                <div class="col-lg-2">
                  <base-input label="Event duration (minutes)" v-on:change="validateHelperFields()" v-model="schedule.slot_duration">
                  </base-input>
                </div>
                <div class="col-lg-2" v-if="schedule.type.id === 0">
                  <base-input v-on:change="validateHelperFields()" label="Gap duration (minutes)" v-model="schedule.gap_duration">
                  </base-input>
                </div>
                <div class="col-lg-2">
                  <base-input label="Start slots on">
                    <el-time-picker v-on:input="validateTime(start_time)"
                      v-model="start_time"
                      :format="picker_time_format" placeholder="HH:MM"
                    ></el-time-picker>
                  </base-input>
                </div>
                <div class="col-lg-2">
                  <base-input label="End slots on">
                    <el-time-picker v-on:input="validateTime(end_time)"
                      v-model="end_time"
                      :format="picker_time_format" placeholder="HH:MM"
                    ></el-time-picker>
                  </base-input>
                </div>
              </div>
              <div class="row" v-if="help_generate">
                <div class="col">
                  <base-input label="Applicable to days">
                    <div class="row">
                      <div class="col">
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[0]">Mon</base-checkbox>
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[1]">Tue</base-checkbox>
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[2]">Wed</base-checkbox>
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[3]">Thu</base-checkbox>
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[4]">Fri</base-checkbox>
                        <base-checkbox class="mr-2" style="display: inline-block" v-model="applicableDays[5]">Sat</base-checkbox>
                        <base-checkbox style="display: inline-block" v-model="applicableDays[6]">Sun</base-checkbox>
                      </div>
                    </div>
                  </base-input>
                </div>
              </div>

              <div class="row mt-2" v-if="help_generate">
                <div class="col-lg-2">
                  <base-input label="Slot status" >
                    <el-select
                      v-model="schedule.status"
                      class="select-primary"
                      placeholder="Status"
                      value-key="id"
                      v-on:change="j++"
                    >
                      <el-option v-if="schedule.type.id === 0"
                                 v-for="(s, i) in booking_statuses"
                                 class="select-primary"
                                 :label="s.name"
                                 :value="{id: s.id, name: s.name, label: s.label}"
                                 :key="'S' + i"
                      ></el-option>
                      <el-option v-if="schedule.type.id === 1"
                                 v-for="(s, i) in resource_statuses"
                                 class="select-primary"
                                 :label="s.name"
                                 :value="{id: s.id, name: s.name, label: s.label}"
                                 :key="'S' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-4 col-6" v-if="schedule.type.id === 1">
                  <label>
                    <div>Concurrent events
                      <el-popover trigger="hover"
                                  placement="top">

                        <div>
                          <div class="popover-body">Number of events this resource can support at the same time</div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <div class="row">
                    <div class="col-lg-5">
                      <base-input>
                        <el-input-number size="small" v-on:input="j++" v-model="schedule.supported_event_count" :min="1" />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="help_generate && schedule.type.id === 0 && schedule.status.id === 0">
                <div class="col-lg-3">
                  <label>Minimum notice</label>
                </div>
                <div class="col-lg-3">
                  <label>Maximum notice in advance</label>
                </div>
              </div>
              <div class="row" v-if="help_generate">
                <div class="col-lg-1" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                  <base-input v-on:change="j++" v-model="schedule.min_booking_value"
                              type="number">
                  </base-input>
                </div>
                <div class="col-lg-2" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                  <base-input>
                    <el-select
                      label="Select game"
                      v-model="schedule.min_booking_period"
                      class="select-primary"
                      value-key="id"
                      v-on:change="j++"
                    >
                      <el-option
                        v-for="(p, index) in min_periods"
                        class="select-primary"
                        :label="p.label"
                        :value="{id: p.id, label: p.label}"
                        :key="'M' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-1" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                  <base-input v-on:change="j++" v-model="schedule.max_booking_value"
                              type="number">
                  </base-input>
                </div>
                <div class="col-lg-2" v-if="schedule.type.id === 0 && schedule.status.id === 0">
                  <base-input>
                    <el-select
                      label="Select period"
                      v-model="schedule.max_booking_period"
                      class="select-primary"
                      v-on:change="j++"
                      value-key="id"
                    >
                      <el-option
                        v-for="(p, index) in max_periods"
                        class="select-primary"
                        :label="p.label"
                        :value="{id: p.id, label: p.label}"
                        :key="'X' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row" v-if="help_generate && schedule.type.id === 0">
                <!--              Call to book or blocked-->
                <div class="col-lg-3"  :key="'B' + j">
                  <label v-if="schedule.status.id === 0">Minimum notice block off text
                    <el-popover trigger="hover" placement="right">
                      <div>
                        <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                        </div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </label>
                  <label v-else>Time slot text
                    <el-popover trigger="hover" placement="right">
                      <div>
                        <div class="popover-body">This text will be visible to customers when a slot has the status 'Call to book'.
                        </div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </label>
                  <base-input v-on:change="j++" v-model="schedule.slot_text"></base-input>
                </div>
                <div class="col-lg-3">
                  <base-input label="External link" v-on:change="j++"  v-if="schedule.has_external_link"  v-model="schedule.external_link" placeholder="https://example.com"></base-input>
                  <base-checkbox v-on:input="j++" v-model="schedule.has_external_link">Include an external link
                  </base-checkbox>
                </div>
              </div>

              <!--Block rules-->
              <div v-if="schedule.type.id === 1 && help_generate">
                <div class="row">
                  <div class="col">
                    <el-switch v-on:input="j++" active-color="#e14eca"
                               v-model="schedule.enable_autoblock"  />
                    <label> &nbsp; Block events with insufficient resource coverage</label>
                  </div>
                </div>
                <div class="row mt-2" v-if="schedule.enable_autoblock">
                  <div class="col-lg-3">
                    <base-input label="Block slots with the following status">
                      <el-select
                        v-model="schedule.block_status"
                        class="select-primary"
                        placeholder="Status"
                        value-key="id"
                        v-on:change="j++"
                      >
                        <el-option
                          v-for="(s, i) in block_statuses"
                          class="select-primary"
                          :label="s.name"
                          :value="{id: s.id, name: s.name, label: s.label}"
                          :key="'S' + i"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col">
                    <el-switch v-on:input="j++" active-color="#e14eca" v-on:change="toggleAnonymousResource(schedule)"
                               v-model="schedule.anonymous_resource"  />
                    <label> &nbsp; Anonymous resource</label>
                  </div>
                </div>

                <div class="row" v-if="schedule.anonymous_resource">
                  <div class="col-lg-6">
                    <base-input label="Resource label" :error="schedule.label_error" placeholder="Anonymous" v-model="schedule.label"></base-input>
                  </div>
                </div>

                <div class="row" v-if="!schedule.anonymous_resource">
                  <div class="col-lg-6">
                    <base-input label="Add staff (search by name or email)" :error="schedule.staff_error">
                      <el-autocomplete style="width: 100%" v-on:input="j++"
                                       v-model="schedule.email"
                                       :fetch-suggestions="querySearchCu"
                                       placeholder="Search"
                                       :trigger-on-focus="false"
                                       @select="handleSelectRuS"
                      >
                        <template #default="{ item }">
                          <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                          <small class="font-italic">
                            <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                          </small>
                        </template>
                      </el-autocomplete>
                    </base-input>
                  </div>
                </div>
              </div>

<!--              Custom pricing-->
              <div v-if="(help_generate || bulk_update_settings) && schedule.type.id === 0" :key="j+'GTS'">
                <div class="row">
                  <div class="col-lg-6">
                    <base-checkbox v-model="is_custom_pricing_enabled">Enable custom pricing</base-checkbox>
                  </div>
                </div>
                <div v-if="is_custom_pricing_enabled">
                  <div class="row mt-2">
                    <div class="col-lg-3">
                      <base-input label="Pricing type">
                        <el-select
                          label="Select time"
                          v-model="pricing_type"
                          class="select-primary"
                          value-key="id"
                          placeholder="Pricing type">
                          <el-option
                            v-for="(pt, index) in pricing_types"
                            class="select-primary"
                            :label="pt.name"
                            :value="{id: pt.id,
                          name: pt.name}"
                            :key="'PT' + index"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" >
                      <base-button class="mr-2" v-on:click="addNewCategory(pricing_categories)" size="sm" type="primary">
                        <i class="tim-icons el-icon-plus"></i> Add new pricing category</base-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div v-for="(p, i) in pricing_categories" :key="'PP' + i">

                        <div class="row">
                          <div class="col-lg-4" >
                            <label>Pricing category name</label>

                            <el-tooltip class="d-inline"
                                        content="Remove category"
                                        effect="light"
                                        :open-delay="300"
                                        placement="right"
                            >
                              <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                            </el-tooltip>
                            <base-input class="d-inline" placeholder="Category name" v-model="p.category">
                            </base-input>
                          </div>
                          <div class="col-lg-4" >
                            <base-input label="Minimum required participants">
                              <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                            </base-input>
                          </div>
                        </div>

                        <div class="col">
                          <div class="row" v-for="(k, j) in p.pricing" :key="'PPP' + j">
                            <div class="col" >
                              <base-input  :label="'Minimum participants'">
                                <el-input-number size="small" v-model="k.min_players" :min="1" placeholder="1" :step="1" />
                              </base-input>
                            </div>
                            <div class="col" >
                              <base-input :label="'Maximum participants'">
                                <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                              </base-input>
                            </div>
                            <div class="col">
                              <base-input :label="'Price (' + currency_symbol + ')'">
                                <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                              </base-input>
                            </div>
                            <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                              <el-tooltip
                                content="Remove price range"
                                effect="light"
                                :open-delay="300"
                                placement="right"
                              >
                                <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                              </el-tooltip>
                              <el-tooltip
                                content="Add price range"
                                effect="light"
                                :open-delay="300"
                                placement="right"
                              >
                                <base-button v-if="j == pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(pricing_categories[i], i)" style="padding: 0;" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <base-checkbox v-model="cumulative_category_pricing">Cumulative category pricing</base-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <base-checkbox v-model="allow_deposit">Allow deposit</base-checkbox>
                      <base-checkbox v-if="allow_deposit" v-model="require_deposit">Require only deposit amount during checkout</base-checkbox>
                    </div>
                    <div class="col-lg-2 mt-2" v-if="allow_deposit">
                      <base-input label="Deposit type">
                        <el-select
                          v-model="deposit_type"
                          class="select-primary"
                          name="deposit_type"
                          placeholder="Value type"
                          value-key="id"
                        >
                          <el-option
                            v-for="(type, index) in valueTypes"
                            class="select-primary"
                            :label="type.name"
                            :value="{name: type.name, id: type.id}"
                            :key="index + 'VT'"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-lg-2 mt-2" v-if="allow_deposit">
                      <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount"
                                  v-model.number="deposit_amount"></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-checkbox v-model="hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2" v-if="help_generate">
                <div class="col">
                  <base-button size="sm" simple type="primary" v-on:click="generateSlots()">Generate slots
                  </base-button>
                  <base-button size="sm" class="text-white" link v-on:click="clearSlots()">Clear slots
                  </base-button>
                </div>
              </div>

              <div class="row mt-2" v-if="bulk_update_settings">
                <div class="col">
                  <base-button size="sm" simple type="primary" v-on:click="bulkUpdateSlots()">Update slot settings
                  </base-button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <fullCalendar :eventRender="eventRenderCallback" ref="calendar" :options="calendarOptions"/>
                </div>
              </div>
            </div>
          </div>

          <!--    Create schedule-->
          <div class="row mt-2">
            <div class="col">
              <base-button  v-on:click="cancelCreateSchedule()" type="default" class="mr-2" >Cancel</base-button>
              <base-button  v-on:click="createSchedulePrompt()" type="primary" >Create schedule</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--    Create time slot-->
    <modal :show.sync="modals.create_slot"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <div class="row justify-content-between">
          <div class="col">
            <h4 v-if="!selectedSlot.editing">Create time slot</h4>
            <h4 v-else>Edit time slot</h4>
          </div>
          <div class="col text-right">
            <base-button
              class="like btn-link"
              type="danger"
              size="sm"
              @click.native="deleteTimeSlot()"
              icon
            ><i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input label="Start day">
              <el-select
                v-model="selectedSlot.start_day"
                class="select-primary"
                name="email_group"
                placeholder="Day of the week"
                value-key="id"
                v-on:change="adjustDate(selectedSlot.start_day.id, selectedSlot.start, 0)"
              >
                <el-option
                  v-for="(day, index) in days_of_week"
                  class="select-primary"
                  :label="day.name"
                  :value="{name: day.name, id: day.id}"
                  :key="'SD' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <base-input :label="'Start time'" :error="errs.start_date_slot">
              <el-time-picker v-on:input="validateTime(selectedSlot.start, selectedSlot.start_reference)"
                              v-model="selectedSlot.start"
                              :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input label="End day">
              <el-select
                v-model="selectedSlot.end_day"
                class="select-primary"
                name="email_group"
                placeholder="Day of the week"
                value-key="id"
                v-on:change="adjustDate(selectedSlot.end_day.id, selectedSlot.end, 1)"
              >
                <el-option
                  v-for="(day, index) in days_of_week"
                  class="select-primary"
                  :label="day.name"
                  :value="{name: day.name, id: day.id}"
                  :key="'ED' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <base-input :label="'End time: '" :error="errs.end_date_slot">
              <el-time-picker v-on:input="validateTime(selectedSlot.end, selectedSlot.end_reference)"
                              v-model="selectedSlot.end"
                              :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <!--        Slot status-->
        <div class="row">
          <div class="col-lg-6">
            <base-input label="Slot status">
              <el-select
                v-model="selectedSlot.status"
                class="select-primary"
                placeholder="Status"
                value-key="id"
              >
                <el-option v-if="schedule.type.id === 0"
                           v-for="(s, i) in booking_statuses"
                           class="select-primary"
                           :label="s.name"
                           :value="{id: s.id, name: s.name, label: s.label}"
                           :key="'S' + i"
                ></el-option>
                <el-option v-if="schedule.type.id === 1"
                           v-for="(s, i) in resource_statuses"
                           class="select-primary"
                           :label="s.name"
                           :value="{id: s.id, name: s.name, label: s.label}"
                           :key="'S' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-6 col-6" v-if="schedule.type.id === 1">
            <label>
              <div>Concurrent events
                <el-popover trigger="hover"
                            placement="top">

                  <div>
                    <div class="popover-body">Number of events this resource can support at the same time</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <div class="row">
              <div class="col-lg-6">
                <base-input>
                  <el-input-number size="small" v-model="selectedSlot.supported_event_count" :min="1" />
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="schedule.type.id === 0 && selectedSlot.status.id === 0">
          <div class="col">
            <label v-if="schedule.type.id === 0">
              <div>Minimum booking notice
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how soon an event can be booked.<br/>
                      This setting will help prevent last minute bookings.</div>
                  </div>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons el-icon-info"></i>
                  </base-button>
                </el-popover>
              </div>
            </label>
            <label v-if="schedule.type.id === 1">
              <div>Minimum notice
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how soon a resource can be notified for a shift.</div>
                  </div>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons el-icon-info"></i>
                  </base-button>
                </el-popover>
              </div>
            </label>
            <div class="row">
              <div class="col-4">
                <base-input v-model="selectedSlot.min_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="selectedSlot.min_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in min_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'M' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col">
            <label v-if="schedule.type.id === 0">
              <div>Maximum bookings in advance
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how far in advance customers can book this event.<br/>
                      By default, bookings are generate 12 months in advance.</div>
                  </div>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons el-icon-info"></i>
                  </base-button>
                </el-popover>
              </div>
            </label>
            <label v-if="schedule.type.id === 1">
              <div>Maximum notice
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how far in advance to notify a resource for a shift.</div>
                  </div>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons el-icon-info"></i>
                  </base-button>
                </el-popover>
              </div>
            </label>
            <div class="row">
              <div class="col-4">
                <base-input v-model="selectedSlot.max_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="selectedSlot.max_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in max_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'X' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="schedule.type.id === 0">
          <!--              Call to book or blocked-->
          <div class="col-lg-6"  :key="'B' + j">
            <label v-if="selectedSlot.status.id === 0">Minimum notice block off text
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <label v-else>Time slot text
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This text will be visible to customers when a slot has the status 'Call to book'.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <base-input v-on:change="j++" v-model="selectedSlot.slot_text"></base-input>
          </div>
          <div class="col-lg-6">
            <base-input label="External link" v-on:change="j++"  v-if="selectedSlot.has_external_link"  v-model="selectedSlot.external_link" placeholder="https://example.com"></base-input>
            <base-checkbox v-on:input="j++" v-model="selectedSlot.has_external_link">Include an external link
            </base-checkbox>
          </div>
        </div>

        <div v-if="schedule.type.id === 1">
          <div class="row">
            <div class="col">
              <el-switch active-color="#e14eca"
                         v-model="selectedSlot.enable_autoblock"  />
              <label> &nbsp; Block events with insufficient resource coverage</label>
            </div>
          </div>
          <div class="row mt-2" v-if="selectedSlot.enable_autoblock">
            <div class="col-lg-6">
              <base-input label="Block slots with the following status">
                <el-select
                  v-model="selectedSlot.block_status"
                  class="select-primary"
                  placeholder="Status"
                  value-key="id"
                >
                  <el-option
                    v-for="(s, i) in block_statuses"
                    class="select-primary"
                    :label="s.name"
                    :value="{id: s.id, name: s.name, label: s.label}"
                    :key="'S' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row mb-2" :key="'AN' + j">
            <div class="col">
              <el-switch v-on:input="j++" active-color="#e14eca" v-on:change="toggleAnonymousResource(selectedSlot)"
                         v-model="selectedSlot.anonymous_resource"  />
              <label> &nbsp; Anonymous resource</label>
            </div>
          </div>

          <div class="row" v-if="selectedSlot.anonymous_resource">
            <div class="col-lg-6">
              <base-input label="Resource label" :error="selectedSlot.label_error" placeholder="Anonymous" v-model="selectedSlot.label"></base-input>
            </div>
          </div>

          <div class="row" v-if="!selectedSlot.anonymous_resource">
            <div class="col-lg-6">
              <base-input label="Add staff (search by name or email)" :error="selectedSlot.staff_error">
                <el-autocomplete style="width: 100%"
                                 v-model="selectedSlot.email"
                                 :fetch-suggestions="querySearchCu"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelectRu"
                >
                  <template #default="{ item }">
                    <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                    <small class="font-italic">
                      <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    </small>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>
        </div>

<!--        Custom pricing-->
        <div class="row" v-if="schedule.type.id === 0">
          <div class="col">
            <base-button v-on:click="showCustomPricingSettings()" style="padding-left: 0; font-weight: normal; font-size: small" link type="primary">
              Custom pricing
              <i v-if="!expand_price_settings" class="tim-icons icon-minimal-down"></i>
              <i v-if="expand_price_settings" class="tim-icons icon-minimal-up"></i>
            </base-button>
          </div>
        </div>

        <div v-if="expand_price_settings && schedule.type.id === 0" :key="j+'EPS'">
          <div class="row">
            <div class="col">
              <base-checkbox v-model="selectedSlot.is_custom_pricing_enabled">Enable custom pricing</base-checkbox>
            </div>
          </div>
          <div v-if="selectedSlot.is_custom_pricing_enabled">
            <div class="row mt-2">
              <div class="col-lg-6">
                <base-input label="Pricing type">
                  <el-select
                    label="Select time"
                    v-model="selectedSlot.pricing_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Pricing type">
                    <el-option
                      v-for="(pt, index) in pricing_types"
                      class="select-primary"
                      :label="pt.name"
                      :value="{id: pt.id,
                          name: pt.name}"
                      :key="'PT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col" >
                <base-button class="mr-2" v-on:click="addNewCategory(selectedSlot.pricing_categories)" size="sm" type="primary">
                  <i class="tim-icons el-icon-plus"></i> Add new pricing category</base-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-for="(p, i) in selectedSlot.pricing_categories" :key="'PP' + i">

                  <div class="row">
                    <div class="col-lg-4" >
                      <label>Pricing category name</label>

                      <el-tooltip class="d-inline"
                                  content="Remove category"
                                  effect="light"
                                  :open-delay="300"
                                  placement="right"
                      >
                        <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(selectedSlot.pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                      </el-tooltip>
                      <base-input class="d-inline" placeholder="Category name" v-model="p.category">
                      </base-input>
                    </div>
                    <div class="col-lg-4" >
                      <base-input label="Minimum required participants">
                        <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                    <div class="col-lg-4">
                      <base-input label="Default number of participants">
                        <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                  </div>

                  <div class="col">
                    <div class="row" v-for="(k, j) in p.pricing" :key="'PPP' + j">
                      <div class="col" >
                        <base-input  :label="'Minimum participants'">
                          <el-input-number size="small" v-model="k.min_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col" >
                        <base-input :label="'Maximum participants'">
                          <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col">
                        <base-input :label="'Price (' + currency_symbol + ')'">
                          <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                        </base-input>
                      </div>
                      <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                        <el-tooltip
                          content="Remove price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(selectedSlot.pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                        </el-tooltip>
                        <el-tooltip
                          content="Add price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button v-if="j == selectedSlot.pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(selectedSlot.pricing_categories[i], i)" style="padding: 0;" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-checkbox v-model="selectedSlot.cumulative_category_pricing">Cumulative category pricing</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-checkbox v-model="selectedSlot.allow_deposit">Allow deposit</base-checkbox>
                <base-checkbox v-if="selectedSlot.allow_deposit" v-model="selectedSlot.require_deposit">Require only deposit amount during checkout</base-checkbox>
              </div>
              <div class="col-lg-3 mt-2" v-if="selectedSlot.allow_deposit">
                <base-input label="Deposit type">
                  <el-select
                    v-model="selectedSlot.deposit_type"
                    class="select-primary"
                    name="deposit_type"
                    placeholder="Value type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in valueTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'VT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 mt-2" v-if="selectedSlot.allow_deposit">
                <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount"
                            v-model.number="selectedSlot.deposit_amount"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="selectedSlot.hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.create_slot = false">Cancel</base-button>
            <base-button v-if="!selectedSlot.editing" class="ml-3" type="primary" @click="createSlotSave()">Create</base-button>
            <base-button v-else class="ml-3" type="primary" @click="updateSlot()">Update</base-button>
          </div>
        </div>
      </card>
    </modal>

<!--    Future slots-->
    <modal :show.sync="modals.future_slots"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <div class="row">
          <div class="col">
            <h4 class="card-title">Review future slots</h4>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-alert v-if="!resource_check"  type="info"><i class="tim-icons el-icon-info mr-1"></i> The following slots will remained unchanged unless selected below.
              Please review and confirm the time slots you want to keep or remove.</base-alert>
            <base-alert v-if="!resource_check && has_insufficient_resource_slots" type="warning"><i class="tim-icons el-icon-warning mr-1"></i> You have existing booking slots with resources allocated to them with automatic blocking enabled.
              Any new concurrent slots may be automatically blocked if the resource coverage is insufficient.</base-alert>
            <base-alert v-if="resource_check && has_insufficient_resource_slots" type="warning"><i class="tim-icons el-icon-warning mr-1"></i> You have existing booking slots with resources allocated to them with automatic blocking enabled.
              Concurrent available slots may be automatically blocked if the resource coverage is insufficient.</base-alert>
          </div>
        </div>

        <div class="row" v-if="!resource_check">
          <div class="col">
            <b-table striped hover :items="future_slots"
                     :fields="['time_slot_status', 'slot_count', 'action']">
              <template v-slot:cell(time_slot_status)="row">
                <badge v-if="row.item.status === 'booked'" type="info">booked</badge>
                <badge v-if="row.item.status === 'call_to_book'" type="warning">call to book</badge>
                <badge v-if="row.item.status === 'blocked'" type="danger">blocked</badge>
              </template>
              <template v-slot:cell(slot_count)="row">
                <span>{{row.item.count}}</span>
              </template>
              <template v-slot:cell(action)="row">
                <el-switch v-if="row.item.status !== 'booked'" :disabled="row.item.count <= 0" active-color="#e14eca"
                           v-model="row.item.remove"  />
                <label v-if="row.item.status !== 'booked'"> &nbsp; Remove slots</label>

                <el-switch v-if="row.item.status === 'booked'" :disabled="row.item.count <= 0" active-color="#e14eca"
                           v-model="row.item.remove"  />
                <label v-if="row.item.status === 'booked'"> &nbsp; Create overlapping slots</label>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.future_slots = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="createSchedule()">Create</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import {TimePicker, Switch, Autocomplete} from "element-ui";
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from '@fullcalendar/daygrid'
import _ from "lodash";
import Badge from "@/components/Badge";
import { BTable } from 'bootstrap-vue'
import BaseAlert from "@/components/BaseAlert";
import {time} from "@/plugins/dateFormatter";
import currencies from "@/assets/currencies.json";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "CreateSchedule",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    [TimePicker.name]: TimePicker,
    [Switch.name]: Switch,
    [Autocomplete.name]: Autocomplete,
    FullCalendar,
    Badge,
    BTable,
    BaseAlert
  },
  data() {
    return {
      days_of_week: [
        { "id": 0, "name": "Sunday" },
        { "id": 1, "name": "Monday" },
        { "id": 2, "name": "Tuesday" },
        { "id": 3, "name": "Wednesday" },
        { "id": 4, "name": "Thursday" },
        { "id": 5, "name": "Friday" },
        { "id": 6, "name": "Saturday" }
      ],
      c: 0,
      currency_symbol: '$',
      currencies: currencies.currencies,
      expand_price_settings: false,
      allow_deposit: 0,
      deposit_amount: 1,
      deposit_type: {id: 0},
      require_deposit: 0,
      pricing_categories: [{
        category: 'Person',
        minimum_participants: 0,
        default_participants: 0,
        pricing: [{min_players: 1, max_players: 2, price: 0}]
      }],
      pricing_type: {id: 1},
      cumulative_category_pricing: 1,
      hide_pricing_rate: 0,
      is_custom_pricing_enabled: false,
      pricing_types: [
        {
          id: 1,
          name: 'Per person'
        },
        {
          id: 2,
          name: 'Flat'
        },
      ],
      valueTypes: [
        {
          name: 'Per booking',
          id: 0
        },
        {
          name: 'Per participant',
          id: 1
        }
      ],
      has_insufficient_resource_slots: 0,
      future_slots: [
        {
          status: 'booked', count: 0, remove: false
        },
        {
          status: 'call_to_book', count: 0, remove: false
        },
        {
          status: 'blocked', count: 0, remove: false
        }
      ],
      resource_check: false,
      help_generate: false,
      bulk_update_settings: false,
      j: 0,
      loading: false,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      applicableDays: [false, false, false, false, true, true, true],
      start_time: new Date(),
      end_time: new Date(),
      picker_time_format: 'hh:mm A',
      day_of_week: '',
      date_format_cal: '',
      schedule: {
        name: '',
        active: "1",
        type: {id: 0, name: 'Booking schedule'},
        start_date: new Date(),
        end_date: new Date(),
        duration_type: {
          id: 1,
          label: 'Recurring',
        },
        games: '',
        resource_count: 1,
        slot_duration: 60,
        gap_duration: 30,
        status: {id: 0, name: 'Available', label: 'available'},
        min_booking_period: {id: 1, label: 'hour(s)'},
        min_booking_value: 1,
        max_booking_period: {id: 2, label: 'day(s)'},
        max_booking_value: 6,
        slot_text: 'Call to book',
        external_link: '',
        has_external_link: false,
        enable_autoblock: false,
        block_status:  {id: 1, name: 'Blocked', label: 'blocked'},
      },
      block_statuses: [
        {id: 1, name: 'Blocked', label: 'blocked'},
        {id: 2, name: 'Call to book', label: 'call_to_book'}
      ],
      games: [],
      max_periods: [{id: 0,label: 'day(s)'}, {id: 1, label: 'week(s)'}, {id: 2, label: 'month(s)'}, {id: 3,label: 'year(s)'}],
      min_periods: [{id: 0, label: 'minute(s)'}, {id: 1, label: 'hour(s)'}, {id: 2, label: 'day(s)'}, {id: 3, label: 'week(s)'}, {id: 4, label: 'month(s)'}],
      errs: {
        start_time: '',
        end_time: '',
        name: '',
        start_date: '',
        end_date: '',
        start_date_slot: '',
        end_date_slot: '',
      },
      duration_types: [
        {
          id: 1,
          label: 'Recurring',
        },
        {
          id: 0,
          label: 'Between selected dates',
        },
        {
          id: 2,
          label: 'Closed period',
        }
      ],
      schedule_types: [
        {id: 0, name: 'Booking schedule'}, {id: 1, name: 'Resource schedule'}
      ],
      calendarOptions: {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        slotLabelFormat: function (date) {
          let time_format = JSON.parse(localStorage.getItem('group')).time_format

          if(time_format === 'HH:mm') {
            let hour = date.date.hour
            let minute = '00'
            if (date.date.minute == 0){
              return hour + ':' + minute
            }
          } else {
            let hour = date.date.hour
            let dd = 'AM'
            if (date.date.minute == 0){
              if (parseInt(hour) >= 12) {
                hour = parseInt(date.date.hour) - 12;
                dd = 'PM'

              }
              if (hour == 0) {
                hour = 12;
              }
              return hour + ':00 ' + dd
            }
          }
        },
        // schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        headerToolbar: false,
        height: 'auto',
        allDaySlot: false,
        slotMinTime: '09:00:00',
        slotMaxTime: '20:00:00',
        stickyHeaderDates: true,
        slotDuration: '00:15:00',
        slotLabelInterval: 15,
        initialView: 'timeGridWeek',
        firstDay: 1,
        visibleRange: {
          start: '2023-01-02', // A Monday to ensure the week starts from Monday
          end: '2023-01-09'
        },
        dayHeaderContent: (args) => {
          return { html: args.date.toLocaleString('en', { weekday: 'long' }) };
        },
        nowIndicator: false,
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        select: this.createTimeSlot,
        eventClick: this.editSlot,
        eventChange: this.updateSlotInfo,
        eventContent: function (arg) {
          let time_format = JSON.parse(localStorage.getItem('group')).time_format
          if(time_format === 'hh:mm'){
            time_format = time_format + ' A'
          }
          let start = moment(arg.event.start).format(time_format)
          let end = moment(arg.event.end).format(time_format)
          let customHtml = "<div style='padding-left: 3px; overflow: hidden'>" +
            "<p class='text-white' style='font-size: x-small'>" + start +
            " - " + end + "</p>"
          customHtml += "<h5 class='text-white'>" + arg.event._def.title + "</h5>" +
            "</div>"
          return { html: customHtml }
        },
      },
      modals: {
        create_slot: false,
        future_slots: false
      },
      booking_statuses: [
        {id: 0, name: 'Available', label: 'available'},
        {id: 2, name: 'Call to book', label: 'call_to_book'}
      ],
      resource_statuses: [{id: 0, name: 'Available', label: 'available'}],
      start: '',
      selectedSlot: {
        start: new Date('2023-01-02'),
        start_reference: new Date('2023-01-02'),
        end: new Date('2023-01-09'),
        end_reference: new Date('2023-01-09'),
        status: {id: 0, name: 'Available'},
        min_booking_period: {id: 1, label: 'hour(s)'},
        min_booking_value: 1,
        max_booking_period: {id: 2, label: 'day(s)'},
        max_booking_value: 6,
        call_to_book_text: '',
        include_call_to_book_link: false,
        call_to_book_link: '',
        editing: false,
        resource_count: 1,
      },
    }
  },
  methods: {
    adjustDate(day, date, type) {
      let currentDate = moment(date);
      const currentDay = currentDate.day();
      let diff = day - currentDay;

      if (type === 0) {
        const newStartDate = currentDate.clone().add(diff, 'days');
        this.selectedSlot.start = newStartDate.toDate();
        this.selectedSlot.start_reference = this.selectedSlot.start;

        if (moment(this.selectedSlot.start).isAfter(moment(this.selectedSlot.end))) {
          this.selectedSlot.end = newStartDate.clone().add(7, 'days').toDate();
        } else {
          const maxEndDate = newStartDate.clone().add(7, 'days');
          if (moment(this.selectedSlot.end).isAfter(maxEndDate)) {
            this.selectedSlot.end = moment(this.selectedSlot.end).clone().subtract(8, 'days').toDate();
          }
        }
        this.selectedSlot.end_reference = this.selectedSlot.end;
      } else {
        const newEndDate = currentDate.clone().add(diff, 'days');
        this.selectedSlot.end = newEndDate.toDate();

        if (moment(this.selectedSlot.end).isBefore(moment(this.selectedSlot.start))) {
          this.selectedSlot.end = moment(this.selectedSlot.end).clone().add(7, 'days').toDate();
        }  else {
          const maxEndDate = moment(this.selectedSlot.start).clone().add(7, 'days');
          if (moment(this.selectedSlot.end).isAfter(maxEndDate)) {
            this.selectedSlot.end = moment(this.selectedSlot.end).clone().subtract(7, 'days').toDate();
          }
        }
        this.selectedSlot.end_reference = this.selectedSlot.end;
      }
    },
    handleSelectRuS(item){
      this.schedule.email = item.email
      this.schedule.resource_assignee_object = { first_name: item.first_name, last_name: item.last_name, id: item.id, email: item.email }
    },
    handleSelectRu(item){
      this.selectedSlot.email = item.email
      this.selectedSlot.resource_assignee_object = { first_name: item.first_name, last_name: item.last_name, id: item.id, email: item.email }
    },
    async querySearchCu(queryString, cb) {
      //make API call to /customers with queryString as filter
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/company-users?count=10000000&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.company_users){
            suggestions.push({
              value: JSON.stringify(response.data.company_users[n]),
              email: response.data.company_users[n].email,
              id: response.data.company_users[n].id,
              first_name: response.data.company_users[n].first_name,
              last_name: response.data.company_users[n].last_name
            })
          }
          const results = suggestions;
          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    toggleAnonymousResource(item){
      if(item.anonymous_resource){
        item.resource_assignee_object = null
        item.company_user_id = null
      }
    },
    addNewCategory(pricing){
      pricing.push({
        category: 'Person',
        minimum_participants: 0,
        default_participants: 0,
        pricing: [{min_players: 1, max_players: 2, price: 0}]
      })
      this.c++
    },
    removePriceRange(item, i1, i2){
      item.splice(i2, 1);
      // this.errs.pricing_categories[i1].per_person_pricing.splice(i2, 1);
    },
    addPriceRange(category, index){
      if(category.pricing.length > 0){
        category.pricing.push({
          min_players: category.pricing[category.pricing.length - 1].max_players + 1,
          max_players: category.pricing[category.pricing.length - 1].max_players + 2,
          price: 0})
      } else {
        category.pricing.push({
          min_players: 1,
          max_players: 2,
          price: 0})
      }
    },
    removeCategory(item, index){
      item.splice(index, 1);
      this.c++
    },
    showCustomPricingSettings(){
      this.expand_price_settings = !this.expand_price_settings

      // this.enable_custom_pricing = this.selectedSlot.is_custom_pricing_enabled
      // this.custom_pricing_type = custom_pricing ? _.find(this.pricing_types, {id: this.selectedBookingSlot.pricing_type_id}) : {id : 1}
      // this.deposit_type = custom_pricing ? _.find(this.valueTypes, {id: this.selectedBookingSlot.deposit_type}) : {id : 0}
      //
      // if(this.expand_price_settings){
      //   if(!custom_pricing || custom_pricing.length <= 0){
      //     this.custom_pricing_categories = []
      //     this.custom_pricing_categories.push({
      //       category: 'Person',
      //       minimum_participants: 0,
      //       default_participants: 0,
      //       pricing: [{min_players: 1, max_players: 2, price: 0}]
      //     })
      //   } else {
      //     this.custom_pricing_type = _.find(this.pricing_types, {id: this.selectedBookingSlot.pricing_type_id})
      //     this.custom_pricing_categories = this.getCategories(custom_pricing)
      //     this.allow_deposit = this.selectedBookingSlot.allow_deposit
      //     this.cumulative_category_pricing = this.selectedBookingSlot.cumulative_category_pricing
      //     this.deposit_amount = this.selectedBookingSlot.deposit_amount
      //     this.require_deposit = this.selectedBookingSlot.require_deposit
      //   }
      // }
      // this.j++
    },
    validateTime(time, reference){
      if(!time){
        return
      }
      let today = new Date()
      if(reference){
        today = new Date(reference)
      }
      time.setMonth(today.getMonth(), today.getDate())
    },
    disabledDates(time) {
      let date = new Date();
      let previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    bulkUpdate(){
      this.bulk_update_settings = !this.bulk_update_settings
      this.help_generate = false
      this.applicableDays = [true, true, true, true, true, true, true]
      this.schedule.min_booking_period = {id: 1, label: 'hour(s)'}
      this.schedule.min_booking_value = 1
      this.schedule.max_booking_period = {id: 2, label: 'day(s)'}
      this.schedule.max_booking_value = 6
      this.schedule.enable_autoblock = false
      this.schedule.block_status = {id: 1, name: 'Blocked', label: 'blocked'}
      this.schedule.resource_count = 1
      this.schedule.has_external_link = false
      this.schedule.slot_text = ''
      this.schedule.external_link = ''
      this.schedule.status = {id: 0, name: 'Available', label: 'available'}
      this.schedule.anonymous_resource = true
      this.schedule.label = 'Anonymous'
      this.schedule.label_error = ''
      this.schedule.staff_error = ''
      this.schedule.email = null
      this.schedule.company_user_id = null
      this.schedule.supported_event_count = 1
    },
    helpGenerate(){
      this.help_generate = !this.help_generate
      this.bulk_update_settings = false
      this.applicableDays = [false, false, false, false, true, true, true]
      this.schedule.min_booking_period = {id: 1, label: 'hour(s)'}
      this.schedule.min_booking_value = 1
      this.schedule.max_booking_period = {id: 2, label: 'day(s)'}
      this.schedule.max_booking_value = 6
      this.schedule.enable_autoblock = false
      this.schedule.block_status = {id: 1, name: 'Blocked', label: 'blocked'}
      this.schedule.resource_count = 1
      this.schedule.has_external_link = false
      this.schedule.slot_text = ''
      this.schedule.external_link = ''
      if(this.schedule.type.id === 1){
        this.schedule.slot_duration = 480
        this.schedule.gap_duration = 0
      } else {
        this.schedule.slot_duration = 60
        this.schedule.gap_duration = 30
      }
      this.schedule.status = {id: 0, name: 'Available', label: 'available'}
      this.schedule.anonymous_resource = true
      this.schedule.label = 'Anonymous'
      this.schedule.email = null
      this.schedule.supported_event_count = 1
    },
    eventRenderCallback(event, element) {
      element.find('.fc-title').html(event.title + '<br>' + event.description);
    },
    adjustScheduleDuration(){
      if(this.schedule.type.id === 0){
        this.duration_types = [
          {
            id: 1,
            label: 'Recurring',
          },
          {
            id: 0,
            label: 'Between selected dates',
          },
          {
            id: 2,
            label: 'Closed period',
          }
        ]
      } else {
        this.duration_types = [
          {
            id: 1,
            label: 'Recurring',
          },
          {
            id: 0,
            label: 'Between selected dates',
          }
        ]
        if(this.schedule.duration_type.id === 2){
          this.schedule.duration_type = {
            id: 1,
            label: 'Recurring',
          }
        }
      }
    },
    validateHelperFields(){
      if(isNaN(this.schedule.slot_duration) || this.schedule.slot_duration <= 0){
        this.schedule.slot_duration = 1
      } else if (this.schedule.slot_duration > 1440){
        this.schedule.slot_duration = 1440
      }
      if(isNaN(this.schedule.gap_duration) || this.schedule.gap_duration < 0){
        this.schedule.gap_duration = 0
      } else if (this.schedule.gap_duration > 1440){
        this.schedule.gap_duration = 1440
      }
      this.j++
    },
    clearSlots(){
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.removeAllEvents();
      calendarApi.setOption('slotMinTime', '09:00:00')
      calendarApi.setOption('slotMaxTime', '20:00:00')
    },
    validateSlot(slot){
      let error = 0

      if(slot.start == '' || slot.start === null){
        this.errs.start_date_slot = "Start time cannot be empty"
        error++
      } else {
        this.errs.start_date_slot = ''
      }

      let start = moment(slot.start)
      let end = moment(slot.end)
      const isDateSequenceBeforeOrSame = start.isBefore(end) || start.isSame(end)

      if(slot.end == '' || slot.end === null){
        this.errs.end_date_slot = "End time cannot be empty"
        error++
      } else if(!isDateSequenceBeforeOrSame){
        this.errs.end_date_slot = "End time cannot be before start time"
        error++
      } else {
        this.errs.end_date_slot = ''
      }

      this.j++
      return error > 0 ? false : true
    },
    updateSlot(){
      if(!this.validateResourceSlot(this.selectedSlot)){
        return
      }
      if(!this.validateSlot(this.selectedSlot)){
        return
      }
      let calendarApi = this.$refs.calendar.getApi();
      let event = calendarApi.getEventById(this.selectedSlot.id);
      event.setDates(this.selectedSlot.start, this.selectedSlot.end);
      event.setExtendedProp('slotInfo', this.selectedSlot);
      event.setProp('title', this.selectedSlot.status.name);

      if(this.selectedSlot.status.id === 0){
        event.setProp('backgroundColor', "#1d8cf8");
      } else if(this.selectedSlot.status.id === 1){
        event.setProp('backgroundColor', "#fd5d93");
      } else if(this.selectedSlot.status.id === 2){
        event.setProp('backgroundColor', "#ff8d72");
      }

      this.expandTimeLineRange(this.selectedSlot.start, this.selectedSlot.end)
      this.modals.create_slot = false
    },
    updateSlotInfo(info){
      let calendarApi = this.$refs.calendar.getApi();
      const event = calendarApi.getEventById(info.event._def.publicId);
      event.extendedProps.slotInfo.start = event.start
      event.extendedProps.slotInfo.end = event.end
      this.expandTimeLineRange(event.start, event.end)
    },
    bulkUpdateSlots(){
      if(!this.validateResourceSlot(this.schedule)){
        this.j++
        return
      }
      let calendarApi = this.$refs.calendar.getApi();
      let events = calendarApi.getEvents();
      for(let n in events){
        //day
        let day = events[n].start.getDay() - 1
        if(day <0){
          day = 6
        }
        if(this.applicableDays[day]){
          //status
          if(this.schedule.status.id === 0){
            events[n].setProp('backgroundColor', "#1d8cf8")
            events[n].setProp('title', 'Available')
          } else if(this.schedule.status.id === 2){
            events[n].setProp('backgroundColor', "#ff8d72")
            events[n].setProp('title', 'Call to book')
          }
          events[n]._def.extendedProps.slotInfo.status = this.schedule.status

          //min/max
          events[n]._def.extendedProps.slotInfo.min_booking_value = this.schedule.min_booking_value
          events[n]._def.extendedProps.slotInfo.min_booking_period = this.schedule.min_booking_period
          events[n]._def.extendedProps.slotInfo.max_booking_value = this.schedule.max_booking_value
          events[n]._def.extendedProps.slotInfo.max_booking_period = this.schedule.max_booking_period

          //call to book
          events[n]._def.extendedProps.slotInfo.has_external_link = this.schedule.has_external_link
          events[n]._def.extendedProps.slotInfo.external_link = this.schedule.external_link
          events[n]._def.extendedProps.slotInfo.slot_text = this.schedule.slot_text

          //resources
          events[n]._def.extendedProps.slotInfo.resource_count = this.schedule.resource_count
          events[n]._def.extendedProps.slotInfo.enable_autoblock = this.schedule.enable_autoblock
          events[n]._def.extendedProps.slotInfo.block_status = this.schedule.block_status
          events[n]._def.extendedProps.slotInfo.anonymous_resource = this.schedule.anonymous_resource
          events[n]._def.extendedProps.slotInfo.label = this.schedule.label
          events[n]._def.extendedProps.slotInfo.email = this.schedule.email
          events[n]._def.extendedProps.slotInfo.resource_assignee_object = this.schedule.resource_assignee_object
          events[n]._def.extendedProps.slotInfo.supported_event_count = this.schedule.supported_event_count

          //pricing
          events[n]._def.extendedProps.slotInfo.is_custom_pricing_enabled = this.is_custom_pricing_enabled
          events[n]._def.extendedProps.slotInfo.pricing_type = {id: this.pricing_type.id}
          events[n]._def.extendedProps.slotInfo.cumulative_category_pricing = this.cumulative_category_pricing
          events[n]._def.extendedProps.slotInfo.hide_pricing_rate = this.hide_pricing_rate
          events[n]._def.extendedProps.slotInfo.allow_deposit = this.allow_deposit
          events[n]._def.extendedProps.slotInfo.deposit_type = {id: this.deposit_type.id}
          events[n]._def.extendedProps.slotInfo.deposit_amount = this.deposit_amount
          events[n]._def.extendedProps.slotInfo.require_deposit = this.require_deposit
          events[n]._def.extendedProps.slotInfo.pricing_categories = JSON.parse(JSON.stringify(this.pricing_categories))
        }
      }
    },
    validateResourceSlot(item){
      let errors = 0
      if(item.anonymous_resource && item.label === ''){
        item.label_error = 'Label cannot be empty'
        errors++
      } else {
        item.label_error = ''
      }
      if(!item.anonymous_resource && !item.email ){
        item.staff_error = 'Assignee cannot be empty'
        errors++
      } else {
        item.staff_error = ''
      }
      if(errors > 0){
        return false
      }
      return true
    },
    generateSlots(){
      if(!this.validateResourceSlot(this.schedule)){
        this.j++
        return
      }
      let calendarApi = this.$refs.calendar.getApi();
      let today = new Date()
      let day = today.getDay()
      let day_diff = 0
      let dayStart = new Date()
      let dayEnd = new Date()
      let slotStart = new Date()
      let slotEnd = new Date()
      let event = {}
      let selectedSlot = {}

      let count = 0
      for(let n in this.applicableDays){
        if(this.applicableDays[n] === true){
          today = new Date()
          day = today.getDay()

          day_diff = 0
          dayStart = new Date()
          dayEnd = new Date()
          slotStart = new Date()
          slotEnd = new Date()

          if(day === 0){
            dayStart.setDate(dayStart.getDate() - 7)
            dayEnd.setDate(dayEnd.getDate() - 7)
            slotStart.setDate(slotStart.getDate() - 7)
            slotEnd.setDate(slotEnd.getDate() - 7)
          }
          day_diff = day - (parseInt(n) + 1)
          dayStart.setDate(dayStart.getDate() - day_diff)
          dayStart.setHours(this.start_time.getHours())
          dayStart.setMinutes(this.start_time.getMinutes())

          dayEnd.setDate(dayEnd.getDate() - day_diff)
          dayEnd.setHours(this.end_time.getHours())
          dayEnd.setMinutes(this.end_time.getMinutes())

          slotStart.setFullYear(dayStart.getFullYear())
          slotStart.setMonth(dayStart.getMonth(), dayStart.getDate())
          slotStart.setHours(dayStart.getHours())
          slotStart.setMinutes(dayStart.getMinutes())
          slotEnd.setFullYear(dayStart.getFullYear())
          slotEnd.setMonth(dayStart.getMonth(), dayStart.getDate())
          slotEnd.setHours(dayStart.getHours())
          slotEnd.setMinutes(dayStart.getMinutes() + parseInt(this.schedule.slot_duration))

          while (slotEnd <= dayEnd){
            let sdt = new Date(slotStart)
            let edt = new Date(slotEnd)
            selectedSlot = {
              start: sdt,
              end: edt,
              start_reference: sdt,
              end_reference: edt,
              status: this.schedule.status,
              min_booking_period: this.schedule.min_booking_period,
              min_booking_value: this.schedule.min_booking_value,
              max_booking_period: this.schedule.max_booking_period,
              max_booking_value: this.schedule.max_booking_value,
              slot_text: this.schedule.slot_text,
              has_external_link: this.schedule.has_external_link,
              external_link: this.schedule.external_link,
              editing: false,
              id: uuidv4(),
              start_day: _.find(this.days_of_week, {id: slotStart.getDay()}),
              end_day:  _.find(this.days_of_week, {id: slotEnd.getDay()}),
              resource_count: this.schedule.resource_count,
              enable_autoblock: this.schedule.enable_autoblock,
              block_status: this.schedule.block_status,
              is_custom_pricing_enabled: this.is_custom_pricing_enabled,
              pricing_type: {id: this.pricing_type.id},
              cumulative_category_pricing: this.cumulative_category_pricing,
              hide_pricing_rate: this.hide_pricing_rate,
              allow_deposit: this.allow_deposit,
              deposit_type: {id: this.deposit_type.id},
              deposit_amount: this.deposit_amount,
              require_deposit: this.require_deposit,
              pricing_categories: JSON.parse(JSON.stringify(this.pricing_categories)),
              anonymous_resource: this.schedule.anonymous_resource,
              label: this.schedule.label,
              email: this.schedule.email,
              resource_assignee_object: this.schedule.resource_assignee_object,
              supported_event_count: this.schedule.supported_event_count
            }
            event = {
              title: 'Available',
              start: slotStart,
              end: slotEnd,
              allDay: false,
              id: selectedSlot.id,
              backgroundColor: "#1d8cf8",
              slotInfo: selectedSlot
            }
            if(selectedSlot.status.id === 0){
              event.title = 'Available'
              event.backgroundColor = "#1d8cf8"
            } else if(selectedSlot.status.id === 1){
              event.title = this.schedule.type.id === 0? 'Blocked' : 'Not available'
              event.backgroundColor = "#fd5d93"
            } else if(selectedSlot.status.id === 2){
              event.title = 'Call to book'
              event.backgroundColor = "#ff8d72"
            }
            calendarApi.addEvent(event);
            count++
            this.expandTimeLineRange(slotStart, slotEnd)
            let newSlotStart = new Date(slotEnd);
            newSlotStart.setMinutes(newSlotStart.getMinutes() + parseInt(this.schedule.gap_duration));
            slotStart = newSlotStart;

            let newSlotEnd = new Date(slotStart);
            newSlotEnd.setMinutes(newSlotEnd.getMinutes() + parseInt(this.schedule.slot_duration));
            slotEnd = newSlotEnd;
          }
        }
      }
      if(count === 0){
        swal('Warning!', 'No slots were created. Check your settings and try again.', 'warning')
      }
    },
    cancelCreateSchedule(){
      this.$router.push('/bookings/schedules')
    },
    validateResourceCount(){
      if(isNaN(this.schedule.resource_count) || this.schedule.resource_count < 0){
        this.schedule.resource_count = 1
      }
      else if(this.schedule.resource_count > 100){
        this.schedule.resource_count = 100
      }
    },
    validateScheduleFields(){
      let errors = 0
      if(this.schedule.name == '') {
        this.errs.name = 'Schedule name cannot be empty'
        errors++
      } else {
        this.errs.name = ''
      }

      if(this.schedule.duration_type.id !== 1 && !this.schedule.start_date){
        this.errs.start_date = 'Start date cannot be empty'
        errors++
      } else {
        this.errs.start_date = ''
      }

      if(this.schedule.duration_type.id !== 1 && !this.schedule.end_date){
        this.errs.end_date = 'End date cannot be empty'
        errors++
      } else {
        this.errs.end_date = ''
      }

      return errors
    },
    validateDateRange(){
      let errors = 0
      if(this.schedule.duration_type.id !== 1){
        if(this.schedule.start_date > this.schedule.end_date){
          this.errs.start_date = 'Start time cannot be greater than end time'
          errors ++
        } else {
          this.errs.start_date = ''
        }
        if(((this.schedule.end_date - this.schedule.start_date ) /31536000000) > 1){
          this.errs.end_date = 'Date range cannot be greater than a year'
          errors ++
        } else {
          this.errs.end_date = ''
        }
      }
      return errors
    },
    deleteTimeSlot(){
      const calendarApi = this.$refs.calendar.getApi();
      const event = calendarApi.getEventById(this.selectedSlot.id);
      event.remove();
      this.modals.create_slot = false
      this.expandTimeLineRange(event.start, event.end)
    },
    getDayOfTheWeek(dt){
      if(!dt){
        return
      }
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return days[dt.getDay()]
    },
    editSlot(info){
      this.expand_price_settings = false
      this.selectedSlot = info.event._def.extendedProps.slotInfo
      this.errs.start_date_slot = ''
      this.errs.end_date_slot = ''
      this.selectedSlot.start = info.event.start
      this.selectedSlot.end = info.event.end
      this.selectedSlot.editing = true
      this.modals.create_slot = true
    },
    createSlotSave(){
      if(!this.validateSlot(this.selectedSlot)){
        return
      }
      let calendarApi = this.$refs.calendar.getApi();

      let event = {
        title: this.selectedSlot.status.name,
        start: this.selectedSlot.start,
        end: this.selectedSlot.end,
        allDay: false,
        slotInfo: this.selectedSlot,
        backgroundColor: "#1d8cf8",
        id: this.selectedSlot.id
      }
      if(this.selectedSlot.status.id === 1){
        event.backgroundColor = "#fd5d93"
      }
      else if(this.selectedSlot.status.id === 2){
        event.backgroundColor = "#ff8d72"
      }
      calendarApi.addEvent(event);
      this.expandTimeLineRange(this.selectedSlot.start, this.selectedSlot.end)
      this.modals.create_slot = false
    },
    getLatestSlot() {
      let calendarApi = this.$refs.calendar.getApi();
      let events = calendarApi.getEvents();
      let latestEndTime = null;

      events.forEach(event => {
        const eventEndTime = new Date(event.end);
        const timeString = eventEndTime.toTimeString().split(' ')[0]; // Extracts "HH:mm:ss"

        if (!latestEndTime || timeString > latestEndTime) {
          latestEndTime = timeString;
        }
      });

      return latestEndTime;
    },
    getEarliestSlot(){
      let calendarApi = this.$refs.calendar.getApi();
      let events = calendarApi.getEvents();
      let earliestTime = null;
      let earliestEndTime = null;
      events.forEach(event => {
        const eventTime = new Date(event.start);
        const timeString = eventTime.getHours().toString().padStart(2, '0') + ':' +
          eventTime.getMinutes().toString().padStart(2, '0') + ':' +
          eventTime.getSeconds().toString().padStart(2, '0');

        if (!earliestTime || timeString < earliestTime) {
          earliestTime = timeString;
        }
      });
      events.forEach(event => {
        const eventTime = new Date(event.end);
        const timeString = eventTime.getHours().toString().padStart(2, '0') + ':' +
          eventTime.getMinutes().toString().padStart(2, '0') + ':' +
          eventTime.getSeconds().toString().padStart(2, '0');

        if (!earliestEndTime || timeString < earliestEndTime) {
          earliestEndTime = timeString;
        }
      });
      return earliestTime < earliestEndTime ? earliestTime : earliestEndTime
    },
    expandTimeLineRange(start, end){
      let calendarApi = this.$refs.calendar.getApi();
      let min_time = new Date(start)
      min_time.setHours(9)
      min_time.setMinutes(0)
      let new_min_time = ''
      let earliestTime =  this.getEarliestSlot()
      let earliestSlot = new Date()
      earliestSlot.setFullYear(min_time.getFullYear())
      earliestSlot.setMonth(min_time.getMonth(), min_time.getDate())
      earliestSlot.setHours(earliestTime.split(':')[0])
      earliestSlot.setMinutes(Math.round(earliestTime.split(':')[1] / 15) * 15);
      if(start < min_time){
        new_min_time = ('0' + earliestSlot.getHours()).slice(-2) + ':' + ('0' + earliestSlot.getMinutes()).slice(-2) + ':00'
        calendarApi.setOption('slotMinTime', new_min_time)
        calendarApi.scrollToTime(new_min_time)
      } else if (min_time < earliestSlot){
        new_min_time = ('0' + min_time.getHours()).slice(-2) + ':' + ('0' + min_time.getMinutes()).slice(-2) + ':00'
        calendarApi.setOption('slotMinTime', new_min_time)
      }
      let max_time = new Date(end)
      max_time.setHours(19)
      max_time.setMinutes(0)
      let latestTime =  this.getLatestSlot()
      let latestSlot = new Date()
      latestSlot.setFullYear(max_time.getFullYear())
      latestSlot.setMonth(max_time.getMonth(), max_time.getDate())
      latestSlot.setHours(latestTime.split(':')[0])
      latestSlot.setMinutes(Math.round(latestTime.split(':')[1] / 15) * 15);
      latestSlot.setSeconds(0);

      if(this.isMidnight(latestSlot)){
        latestSlot.setMinutes(latestSlot.getMinutes() - 1);
      }

      if(end > max_time){
        let new_end_time =('0' + latestSlot.getHours()).slice(-2) + ':' + ('0' + latestSlot.getMinutes()).slice(-2) + ':00'
        calendarApi.setOption('slotMaxTime', new_end_time);
      } else if (max_time > latestSlot) {
        let new_end_time =('0' + max_time.getHours()).slice(-2) + ':' + ('0' + max_time.getMinutes()).slice(-2) + ':00'
        calendarApi.setOption('slotMaxTime', new_end_time);
      }
    },
    isMidnight(date) {
      return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
    },
    createTimeSlot(selectInfo) {
      this.expand_price_settings = false
      this.selectedSlot = {
        start: selectInfo.start,
        end: selectInfo.end,
        start_reference: selectInfo.start,
        end_reference: selectInfo.end,
        status: {id: 0, name: 'Available', label: 'available'},
        block_status: {id: 1, name: 'Blocked', label: 'blocked'},
        min_booking_period: {id: 1, label: 'hour(s)'},
        min_booking_value: 1,
        max_booking_period: {id: 2, label: 'day(s)'},
        max_booking_value: 6,
        slot_text: '',
        has_external_link: false,
        external_link: '',
        editing: false,
        id: uuidv4(),
        start_day: _.find(this.days_of_week, {id: selectInfo.start.getDay()}),
        end_day: _.find(this.days_of_week, {id: selectInfo.end.getDay()}),
        resource_count: 1,
        enable_autoblock: false,
        is_custom_pricing_enabled: false,
        pricing_type: {id: 1},
        cumulative_category_pricing: true,
        hide_pricing_rate: false,
        allow_deposit: false,
        deposit_type: {id: 1},
        deposit_amount: 1,
        require_deposit: false,
        pricing_categories: [{
          category: 'Person',
          minimum_participants: 0,
          default_participants: 0,
          pricing: [{min_players: 1, max_players: 2, price: 0}]
        }],
        anonymous_resource: true,
        label: 'Anonymous',
        company_user_id: null,
        email: null,
        supported_event_count: 1
      }
      this.errs.start_date_slot = ''
      this.errs.end_date_slot = ''
      this.modals.create_slot = true
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getAutoBlockingResourceSlots(){
      let calendarApi = this.$refs.calendar.getApi();
      let events = calendarApi.getEvents();
      let blocking_events = []
      for(let n in events){
        if(events[n]._def.extendedProps.slotInfo.enable_autoblock){
          blocking_events.push({start_time: events[n].start.getHours() + ':' + events[n].start.getMinutes(),
            end_time: events[n].end.getHours() + ':' + events[n].end.getMinutes(),
            resource_count: events[n]._def.extendedProps.slotInfo.resource_count
          })
        }
      }
      return JSON.stringify(blocking_events)
    },
    createSchedulePrompt(){
      if(this.validateScheduleFields()){
        return
      }
      if(this.schedule.active === '1'){
        this.checkFutureSlots()
      } else {
        this.createSchedule()
      }
    },
    async checkFutureSlots(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dt = new Date()
      let today = dt.getFullYear() + '-' +  ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)
      let gameIds = []
      for(let n in this.schedule.games){
        gameIds.push(this.schedule.games[n].id)
      }

      let resources = this.getAutoBlockingResourceSlots()

      let sd = this.schedule.start_date ? moment(this.schedule.start_date, 'YYYY-MM-DD') : moment()
      let ed =  this.schedule.end_date ? moment(this.schedule.end_date, 'YYYY-MM-DD') : moment()

      if(this.schedule.duration_type.id === 1){
        sd = moment()
        ed = sd.clone().add(365, 'days');
      }

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/schedules/check-future-slots?today=' + today + '&start_date=' + sd.format('YYYY-MM-DD') +
        `&end_date=` + ed.format('YYYY-MM-DD') +
        '&games=' + gameIds + '&resources=' + encodeURIComponent(resources)

      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          if(response.data.booked <= 0 && response.data.call_to_book <= 0 && response.data.blocked <= 0){
            this.createSchedule()
          } else {
            this.modals.future_slots = true
            this.future_slots = [
              {
                status: 'booked', count: response.data.booked, remove: false
              },
              {
                status: 'call_to_book', count: response.data.call_to_book, remove: false
              },
              {
                status: 'blocked', count: response.data.blocked, remove: false
              }
            ]
            if(response.data.resource_check){
              this.resource_check = true
            } else {
              this.resource_check = false
            }
            this.has_insufficient_resource_slots = response.data.insufficient_resources > 0 ? 1 : 0

          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status === 500){
            swal.fire({
              title: 'Error!',
              text: err.response.data,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Correct error'
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async createSchedule(){
      let calendarApi = this.$refs.calendar.getApi();
      let events = calendarApi.getEvents();
      let slots = []
      let slot = {}
      for(let n in events){
        slot = events[n]._def.extendedProps.slotInfo
        let start = slot.start.getHours() + ':' + slot.start.getMinutes()
        let end = slot.end.getHours() + ':' + slot.end.getMinutes()

        // slot.start_time = slot.start_time.split('T')[1].split(':')[0] + ':' + slot.start_time.split('T')[1].split(':')[1]
        // slot.end_time = slot.end_time.split('T')[1].split(':')[0] + ':' + slot.end_time.split('T')[1].split(':')[1]
        slots.push({
          start: start,
          end: end,
          start_day: slot.start_day.id,
          end_day: slot.end_day.id,
          status: slot.status,
          min_booking_period: slot.min_booking_period,
          min_booking_value: slot.min_booking_value,
          max_booking_period: slot.max_booking_period,
          max_booking_value: slot.max_booking_value,
          slot_text: slot.slot_text,
          has_external_link: slot.has_external_link,
          external_link: slot.external_link,
          editing: false,
          id: slot.id,
          resource_count: slot.resource_count,
          enable_autoblock: slot.enable_autoblock,
          block_status: slot.block_status,
          is_custom_pricing_enabled: slot.is_custom_pricing_enabled,
          pricing_type_id: slot.pricing_type.id,
          cumulative_category_pricing: slot.cumulative_category_pricing,
          hide_pricing_rate: slot.hide_pricing_rate,
          allow_deposit: slot.allow_deposit,
          deposit_type_id: slot.deposit_type.id,
          deposit_amount: slot.deposit_amount,
          require_deposit: slot.require_deposit,
          custom_pricing: slot.pricing_categories,
          label: slot.label,
          company_user_id: slot.resource_assignee_object && !slot.anonymous_resource ? slot.resource_assignee_object.id : slot.company_user_id,
          supported_event_count: slot.supported_event_count
        })
      }
      let time = new Date()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/schedules'
      let data = {
        schedule: this.schedule,
        slots: slots,
        remove_call_to_book: this.future_slots[1].remove,
        remove_block: this.future_slots[2].remove,
        create_overlap_on_booked_slots: this.future_slots[0].remove,
        time: time.getFullYear() + '-' + (time.getMonth()+1) + '-' + time.getDate(),
        active: this.schedule.active
      }
      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          this.modals.future_slots = false
          let documentClasses = document.body.classList;
          documentClasses.remove('modal-open');
          this.$router.push('/bookings/schedules')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', err.response.data, 'error')
          }
        })

    },
    async getGames () {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.getGames()
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if (this.time_format === 'HH:mm') {
      this.picker_time_format = 'HH:mm'
    }
    this.start_time = new Date()
    this.start_time.setHours(10, 0)
    this.end_time = new Date()
    this.end_time.setHours(21, 0)
  }
}
</script>

<style scoped>
.hover-link:hover{
  /*color: #e14eca !important;*/
  font-weight: normal;
}
.hover-link{
  color: #e14eca !important;
  font-weight: normal;
}


</style>
