<template>
  <div class="container-fluid">
    <div class="row d-flex">
      <div class="col-lg-12">
        <card class="card-calendar"> <calendar></calendar> </card>
      </div>
    </div>
  </div>


</template>

<script>
import Loading from "@/pages/Layout/LoadingMainPanel";

const Calendar = () => ({
  component: import('./Calendar.vue'),
  loading: Loading,
  delay: 100
});

export default {
  name: "BookingSlots",
  components: {
    Calendar
  }
}
</script>

<style scoped>
#fullCalendar {
  min-height: 400px;
}
</style>
