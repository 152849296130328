<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col">
        <card card-body-classes="table-full-width">
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="activeTabIndex === 0">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="mt-3" v-if="games.length <= 0">
                    <div class="font-icon-detail">
                      <span style="font-size: 3em">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                      <h4 class="mt-3">No games have been set up.</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="games.length > 0">
                <div class="col">
                  <b-table  striped hover :items="games"
                            :fields="['game', 'control', 'actions']">
                    <template v-slot:cell(game)="row">
                      <div class="mt-1">
                        <img :src="row.item.url"/>
                      </div>
                      <span class="ml-2">
                    {{row.item.name}}
                  </span>
                    </template>
                    <template #head(control)="row">
                      <span></span>
                    </template>
                    <template v-slot:cell(control)="row">
                      <base-button size="sm" v-on:click="selectGame(row.item)"
                                   type="primary">
                        Hint control panel</base-button>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <span v-if="!hasPermissions('hints_configure')">-</span>
                      <el-tooltip content="Hint settings"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button v-show="hasPermissions('hints_configure')"
                          @click.native="configureClues(row.item)"
                          class="like btn-link"
                          type="info"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-settings-gear-63"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="activeTabIndex === 1">
            <div class="col">
              <div class="row">
                <div class="col">
                  <p>Total records: {{totalRows}}</p>

                </div>
                <div class="text-right mr-3">
<!--                  <base-button icon class="btn-link text-white" v-on:click="downloadCSV()">-->
<!--                    <i class="tim-icons icon-cloud-download-93"></i>-->
<!--                  </base-button>-->
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="perPage"
                    placeholder="Per page"
                    v-on:change="getHintLog()"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row" v-if="hint_instances.length <=0">
                <div class="col">
                  <div class="mt-3" v-if="games.length <= 0">
                    <div class="font-icon-detail">
                      <span style="font-size: 3em">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                      <h4 class="mt-3">No games have been set up.</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="hint_instances.length >0">
                <div class="col">
                  <b-table  striped hover :items="hint_instances"
                            :fields="['time_completed', 'game_master', 'game', 'actions']">
                    <template v-slot:cell(time_completed)="row">
                      <p class="text-white">
                        <i class="tim-icons icon-calendar-60 text-primary"></i> {{getLocalDate(row.item.timestamp)}}
                        <i class="tim-icons el-icon-alarm-clock text-primary"></i> {{getLocalTime(row.item.timestamp)}}
                      </p>
                    </template>
                    <template v-slot:cell(game_master)="row">
                  <span class="ml-2">
                    {{row.item.first_name}} {{row.item.last_name}}
                  </span>
                    </template>
                    <template v-slot:cell(game)="row">
                      <div v-if="row.item.booking_slot_id">
                        <p>{{row.item.name}}</p>
                        <p class="text-white">
                          <i class="tim-icons icon-calendar-60 text-primary"></i> {{getDate(row.item.booking_date)}}
                          <i class="tim-icons el-icon-alarm-clock text-primary"></i> {{getTime(row.item.start_time)}}
                        </p>
                      </div>
                      <span v-else>-</span>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <el-tooltip content="Delete log"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="deleteLogPrompt(row.item)"
                          class="like btn-link"
                          type="danger"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip content="View log details"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          class="like btn-link"
                          type="info"
                          size="sm"
                          @click.native="viewLogDetails(row.item)"
                          icon
                        ><i class="tim-icons icon-paper"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </b-table>
                  <b-pagination v-on:input="getHintLog()"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                :limit="3"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {BTable, BPagination} from 'bootstrap-vue'
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import swal from "sweetalert2";

export default {
  name: "GameSelectGMView",
  data() {
    return {
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      empty: false,
      games: [],
      hint_instances: [],
      activeTabIndex: 0,
      j: 0,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [5, 10, 25, 50]
    }
  },
  components: {BTable, BPagination},
  computed: {
    categories() {
      return [{name: 'Game hints', icon: 'tim-icons icon-bulb-63', active: true},
        {name: 'Hint logs', icon: 'tim-icons icon-note', active: false}
      ];
    },
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    configureClues(game){
      localStorage.setItem('game', JSON.stringify(game))
      this.$router.push('/games/clues/configure')
    },
    viewLogDetails(item){
      localStorage.setItem('hintLogId', item.id)
      this.$router.push('/games/clues/history')
    },
    async deleteLog(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/clues/' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Hint log has been deleted.', 'success')
          this.getHintLog()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteLogPrompt(item){
      swal({
        title: 'Are you sure?',
        text: `The hint log will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.deleteLog(item)
        }
      })

    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getLocalDate(d){
      let date = new Date(d)
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes();
      return this.getDayOfWeek(date) + formattedDate(this.date_format, date)
    },
    getLocalTime(d){
      let date = new Date(d)
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes();
      return time(this.time_format, date)
    },
    getDate(d){
      return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    selectGame(item){
      localStorage.setItem("selectedGameDetailsForClues", JSON.stringify(item))
      let routeData = this.$router.resolve('/games/clues');
      window.open(routeData.href, '_blank');
       // this.$router.push('/games/clues')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGames(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.games = response.data
          if(this.games.length <= 0){
            this.empty = true
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getHintLog(){
      let count = this.perPage
      let offset = parseInt((this.currentPage * this.perPage) - this.perPage)
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/clues?count=' + count + "&offset=" + offset
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.hint_instances = response.data.hint_instances
          this.totalRows = response.data.total_rows
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted(){
    this.getHintLog()
    this.getGames()
  }
}
</script>

<style scoped>
img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}

</style>
