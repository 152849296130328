<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

<!--  Mobile-->
  <div class="d-lg-none">
    <div class="row" v-if="hasPermissions('game_history_update')">
      <div class="col">
        <base-button style="width: 100%" v-if="incomplete_view" v-on:click="getTodaysGames()" type="primary">View forms by day</base-button>
        <base-button style="width: 100%" v-if="!incomplete_view" v-on:click="getIncompleteForms()" type="primary">View incomplete forms</base-button>
      </div>
    </div>
    <div class="row">
      <div v-if="!incomplete_view" class="col">
        <base-input style="width: 100%">
          <el-date-picker
            type="date"
            placeholder="Select date"
            value-format="yyyy-MM-dd"
            :format="day_of_week + date_format_cal"
            v-model="current"
            @change="getTodaysGames()"
          >
          </el-date-picker>
        </base-input>
      </div>
    </div>
  </div>

<!--  Desktop-->
  <div class="d-none d-lg-block">
    <div class="row justify-content-between" v-if="hasPermissions('game_history_update')">
      <div v-if="!incomplete_view" class="col">
        <base-input style="width: 200px">
          <el-date-picker
            type="date"
            placeholder="Select date"
            value-format="yyyy-MM-dd"
            format="ddd, dd MMMM yyyy"
            v-model="current"
            @change="getTodaysGames()"
          >
          </el-date-picker>
        </base-input>
      </div>
      <div class="col text-right">
        <base-button v-if="incomplete_view" v-on:click="getTodaysGames()" type="primary">View forms by day</base-button>
        <base-button v-if="!incomplete_view" v-on:click="getIncompleteForms()" type="primary">View incomplete forms</base-button>
      </div>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col" >
      <card card-body-classes="table-full-width">
        <h4 v-if="!incomplete_view" class="card-title">Games for <span class="text-primary"> {{getFormattedDate(current)}}</span></h4>
        <h4 v-else>All incomplete forms</h4>
        <div class="mt-3" v-if="game_instances.length <= 0">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No upcoming bookings today.</h4>
          </div>
        </div>
<!--        Mobile-->
        <div v-if="game_instances.length > 0" class="row d-lg-none">
          <div class="col">
            <b-table striped hover :items="game_instances"
                     :fields="mobileFields">
              <template v-slot:head(game)="row">
                <span></span>
              </template>
              <template v-slot:cell(game)="row">
                <div class="row justify-content-between">
                  <div class="col">
                    <p class="text-primary" style="font-size: medium">{{row.item.game_name}}</p>
                  </div>
                  <div class="col text-right" v-if="row.item.players">
                    <el-popover trigger="hover"
                                placement="bottom">
                      <div>
                        <div class="popover-body">
                          <div v-for="(p, i) in parseJSON(row.item.players)" :key="'C' + i">
                            {{p.first_name}} {{p.last_name}}
                          </div>
                        </div>
                      </div>
                      <base-button class="like btn-link"
                                   type="info"
                                   size="sm"
                                   icon
                                   slot="reference">
                        <span class="text-white">x{{row.item.waiver_count}}</span>
                      </base-button>
                    </el-popover>
                  </div>
                  <div class="col text-right" v-else>
                    <base-button class="like btn-link"
                                 type="info"
                                 size="sm"
                                 icon
                                 slot="reference">
                      <span class="text-white">x{{row.item.waiver_count}}</span>
                    </base-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <i class="text-primary tim-icons icon-calendar-60"></i> {{getFormattedDate(row.item.booking_date)}}
                    <i class="ml-2 text-primary tim-icons icon-time-alarm"></i> {{getTimeFromTime(row.item.start_time)}}
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <base-input label="Start time" :error="errors.start_time" >
                      <el-time-picker v-on:input="validateTime(row.item.game_start_time, current)"
                        v-model="row.item.game_start_time"
                        :format="picker_time_format" placeholder="HH:MM"
                      ></el-time-picker>
                    </base-input>
                  </div>
                  <div class="col-6">
                    <base-input label="End time" :error="errors.end_time">
                      <el-time-picker placeholder="HH:MM" v-on:input="validateTime(row.item.game_end_time, current)"
                                      v-model="row.item.game_end_time"
                                      :format="picker_time_format"
                      ></el-time-picker>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-input label="Team name" placeholder="Team name" v-model="row.item.team_name"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-input label="Number of hints" placeholder="0"
                                :error="errors.hints"  v-model="row.item.hints" type="number" ></base-input>
                  </div>
                  <div class="col">
                    <base-input label="Number of breaks" :error="errors.breaks" v-model="row.item.breaks"
                                placeholder="0" type="number" ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-input :error="errors.completed">
                      <el-select
                        v-model="row.item.completed"
                        class="select-primary"
                        name="game"
                        placeholder="Did they complete the game?"
                        value-key="id"
                      >
                        <el-option
                          v-for="option in completionOptions"
                          class="select-primary"
                          :label="option.name"
                          :value="option.id"
                          :key="'A' + option.id"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="row" v-if="row.item.uploaded_image">
                  <div class="col">
                    <img :key="img_key" height="200" style="width: 100%; object-fit: cover" alt="Card image cap" :src="row.item.uploaded_image"/>
                  </div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col">
                    <span class="btn btn-sm btn-success btn-simple btn-file mt-3" style="width: 100%; display: inline-block;">
                    Take photo
                      <input v-if="isAndroid14" type="file"
                             @change="takePhoto(row.item)"/>
                      <input v-else type="file"
                             accept="image/*"
                             @change="takePhoto(row.item)"

                      />
                    </span>
<!--                    <base-button style="width: 100%"-->
<!--                                 @click.native="takePhoto(row.item)"-->
<!--                                 type="success"-->
<!--                                 size="sm"-->
<!--                                 simple-->
<!--                    > Take photo-->
<!--                    </base-button>-->
                  </div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col">
                    <base-button style="width: 100%"
                                 @click.native="submitForm(row.item)"
                                 type="success"
                                 size="sm"
                                 simple
                    > Submit
                    </base-button>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
<!--        Desktop-->
        <div v-if="game_instances.length > 0" class="row d-none d-lg-block">
          <div class="col">
            <b-table striped hover :items="game_instances"
                     :fields="fields">
              <template v-slot:cell(game)="row">
                <div class="row">
                  <div class="col">
                    <p class="text-primary" style="font-size: medium">{{row.item.game_name}}</p>
                  </div>
                </div>
              </template>
              <template v-slot:cell(photo)="row">
                <img v-if="row.item.uploaded_image" :key="img_key" height="50" width="50" style="object-fit: cover" alt="Card image cap" :src="row.item.uploaded_image"/>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <div >
                  <span>{{getFormattedDate(row.item.booking_date)}}</span><br/>
                  <span>{{getTimeFromTime(row.item.start_time)}}</span>
                </div>

              </template>
              <template v-slot:cell(time)="row">
                <div >
                    <span>{{getTimeFromTime(row.item.start_time)}}</span>
                </div>

              </template>
              <template v-slot:cell(start_time)="row">
                <div >
                  <base-input :error="errors.start_time" >
                    <el-time-picker v-on:input="validateTime(row.item.game_start_time, current)"
                      v-model="row.item.game_start_time"
                      :format="picker_time_format" placeholder="HH:MM"
                    ></el-time-picker>
                  </base-input>
                </div>

              </template>
              <template v-slot:cell(end_time)="row">
                <div >
                  <base-input :error="errors.end_time">
                    <el-time-picker placeholder="HH:MM" v-on:input="validateTime(row.item.game_end_time, current)"
                      v-model="row.item.game_end_time"
                      :format="picker_time_format"
                    ></el-time-picker>
                  </base-input>
                </div>

              </template>
              <template v-slot:cell(players)="row">
                <div v-if="row.item.players">
                  <el-popover trigger="hover"
                              placement="bottom">
                    <div >
                      <div class="popover-body" >
                        <div v-for="(p, i) in parseJSON(row.item.players)" :key="'C' + i">
                          {{p.first_name}} {{p.last_name}}
                        </div>
                      </div>
                    </div>
                    <base-button class="like btn-link"
                                 type="info"
                                 size="sm"
                                 icon
                                 slot="reference">
                      <span class="text-white">x{{row.item.waiver_count}}</span>
                    </base-button>
                  </el-popover>

                </div>
                <div v-else>
                  <base-button class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <span class="text-white">x{{row.item.waiver_count}}</span>
                  </base-button>
                </div>
              </template>
              <template v-slot:cell(team_name)="row">
                <div>
                  <base-input placeholder="Team name" v-model="row.item.team_name"></base-input>
                </div>
              </template>
              <template v-slot:cell(#_of_hints)="row">
                <div :style="{ width: '100px' }">
                  <base-input placeholder="0"
                    :error="errors.hints"  v-model="row.item.hints" type="number" ></base-input>

                </div>
              </template>
              <template v-slot:cell(#_of_breaks)="row">
                <div >
                  <base-input :error="errors.breaks" v-model="row.item.breaks"
                              placeholder="0" type="number" ></base-input>

                </div>

              </template>
              <template v-slot:cell(completion)="row">
                <div >
                  <base-input :error="errors.completed">
                    <el-select
                      v-model="row.item.completed"
                      class="select-primary"
                      name="game"
                      placeholder="Did they complete the game?"
                      value-key="id"
                    >
                      <el-option
                        v-for="option in completionOptions"
                        class="select-primary"
                        :label="option.name"
                        :value="option.id"
                        :key="'A' + option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

              </template>
              <template v-slot:cell(actions)="row">
                <div style="margin-top: -20px" >
                  <el-tooltip content="Take photo"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                  <span v-if="!photos_not_enabled" class="btn btn-success btn-link btn-file mt-3" style="display: inline-block; font-size: large">
                    <i class="tim-icons icon-camera-18"></i>
                      <input v-if="isAndroid14" type="file"
                           @change="takePhoto(row.item)"/>
                      <input v-else type="file"
                             accept="image/*"
                             @change="takePhoto(row.item)"

                      />
                  </span>
                  </el-tooltip>
                  <el-tooltip content="Submit form"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                  <span class="btn btn-primary btn-link btn-file mt-3" v-on:click="submitForm(row.item)" style="display: inline-block; font-size: large">
                    <i class="tim-icons icon-check-2"></i>
                  </span>
                  </el-tooltip>
                </div>

              </template>

            </b-table>
          </div>
        </div>
      </card>

    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import { Badge } from 'src/components';
import { BTable } from 'bootstrap-vue'
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import swal from "sweetalert2";
import {Popover, TimePicker} from 'element-ui';
import _ from "lodash";


export default {
  name: "GameTrackerSelect",
  components:{
    BTable,
    Badge,
    [TimePicker.name]: TimePicker,
    [Popover.name]: Popover,
  },
  data(){
    return {
      photos_not_enabled: '',
      img_key: '',
      uploadUrl: '',
      uploaded_image: '',
      file: '',
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format_cal: '',
      day_of_week: '',
      picker_time_format: 'hh:mm A',
      start_time: '',
      end_time: '',
      incomplete_view: false,
      hints: 0,
      breaks: 0,
      completed: '',
      completionOptions: [
        {
          id: 0,
          name: 'Completed'
        },
        {
          id: 1,
          name: 'Not completed'
        }
      ],
      errors: {
        hints: '',
        breaks: '',
        completed: '',
        start_time: '',
        end_time: ''
      },
      loading: false,
      current: '',
      template: {},
      userAgent: navigator.userAgent,
      game_instances: [],
      mobileFields: ['game'],
      fields: ['time', 'game', 'players', 'team_name', 'start_time', 'end_time', '#_of_hints', '#_of_breaks', 'completion', 'photo', 'actions'],
    }
  },
  computed: {
    isAndroid14() {
      return navigator.userAgent.match(/Android/i) !== null;
    },
  },
  methods: {
    validateTime(time, day){
      let today = new Date(day)
      time.setMonth(today.getMonth(), today.getDate())
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
    },
    setToday(){
      let current = new Date()
      this.current = current.getFullYear() + '-' +  ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' +current.getDate()).slice(-2)
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    parseJSON(item){
      return JSON.parse(item)
    },
    getTimeFromTime(d) {
      return time(this.time_format, d);
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getLocalTime(d){
      return this.current.split('-')[0] +
        "-" + this.current.split('-')[1] + "-" + this.current.split('-')[2] +
        'T' + d.getHours() + ':' + d.getMinutes() + ':00.000Z'
    },
    async submitForm(item){
      let errors = 0
      if(!item.hasOwnProperty('game_start_time')){
        this.errors.start_time = "Must not be empty"
        errors++
      } else {
        this.errors.start_time = ""
      }
      if(!item.hasOwnProperty('game_end_time')){
        this.errors.end_time = "Must not be empty"
        errors++
      } else {
        this.errors.end_time = ""
      }
      if(!item.hasOwnProperty('hints') || item.hints === ""){
        errors++
        this.errors.hints = "Must not be empty"
      } else if (item.hints < 0){
        errors++
        this.errors.hints = "Invalid amount"
      } else {
        this.errors.hints = ""
      }

      if(!item.hasOwnProperty('breaks') || item.breaks === ""){
        errors++
        this.errors.breaks = "Must not be empty"
      } else if (item.breaks < 0){
        errors++
        this.errors.breaks = "Invalid amount"
      } else {
        this.errors.breaks = ""
      }

      if(!item.hasOwnProperty('completed')){
        this.errors.completed = "Must not be empty"
        errors++
      } else {
        this.errors.completed = ""
      }

      if(errors > 0){
        return
      }


      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/games/instances/simple'
      let data = {
        booking_slot_id: item.id,
        game_id: item.game_id,
        start_time: this.getLocalTime(item.game_start_time),
        end_time: this.getLocalTime(item.game_end_time),
        hints: item.hints,
        breaks: item.breaks,
        completed: !item.completed,
        team_name: item.team_name,
        users: JSON.parse(item.players)
      }
      axios.post(url,data, config)
        .then(response => {
          swal('Game tracker form submitted!', '', 'success')
          if(this.incomplete_view){
            this.getIncompleteForms()
          } else {
            this.getTodaysGames()
          }

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getDefaultTracker() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/game-tracker-settings/default?group=' + companyGroupId

      axios.get(url, config)
        .then(response => {
          this.template = response.data.game_tracker_settings
          if(this.template.mode === 1){
            this.$router.push('/games/tracker')
          } else {
            this.getTodaysGames()
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getTodaysGames() {
      this.loading = true
      this.incomplete_view = false
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings?day=' + this.current + '&type=0'
      axios.get(url, config)
        .then(response => {
          let start_dt = new Date()
          let end_dt = new Date()
          for(let n in response.data.bookings){
            start_dt = new Date()
            end_dt = new Date()
            start_dt.setHours(response.data.bookings[n].start_time.split(':')[0])
            start_dt.setMinutes(response.data.bookings[n].start_time.split(':')[1])
            end_dt.setHours(response.data.bookings[n].end_time.split(':')[0])
            end_dt.setMinutes(response.data.bookings[n].end_time.split(':')[1])
            response.data.bookings[n].hints = 0
            response.data.bookings[n].breaks = 0
            response.data.bookings[n].completed = 0
            response.data.bookings[n].game_start_time = start_dt
            response.data.bookings[n].game_end_time = end_dt

          }
          if(this.fields.includes('booking_time')){
            this.fields.splice(0,1)
            this.fields.splice(0, 0, 'time')
          }
          this.game_instances = response.data.bookings
          this.loading = false
        })
        .catch(err => {
          // this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getIncompleteForms() {
      this.loading = true
      this.incomplete_view = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings?type=4'
      axios.get(url, config)
        .then(response => {
          let start_dt = new Date()
          let end_dt = new Date()
          for(let n in response.data.bookings){
            if(response.data.bookings[n].start_time != null){
              start_dt.setHours(response.data.bookings[n].start_time.split(':')[0])
              start_dt.setMinutes(response.data.bookings[n].start_time.split(':')[1])
            }
            if(response.data.bookings[n].end_time != null) {
              end_dt.setHours(response.data.bookings[n].end_time.split(':')[0])
              end_dt.setMinutes(response.data.bookings[n].end_time.split(':')[1])
            }

            response.data.bookings[n].hints = 0
            response.data.bookings[n].breaks = 0
            response.data.bookings[n].completed = 0
            response.data.bookings[n].game_start_time = start_dt
            response.data.bookings[n].game_end_time = end_dt

          }
          this.fields.splice(0, 1, )
          this.fields.splice(0, 0, 'booking_time')
          this.game_instances = response.data.bookings
          this.loading = false
        })
        .catch(err => {
          // this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload/photos'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async saveFile(item) {
      this.uploadUrl = await this.getSignedURL(item.file)
      let uploaded = await this.uploadFile(item.file)
    },
    handleUpload(item){
      let file = event.target.files[0];
      if (file && !file.type.match('image.*')) {
        swal('Error!', 'You must upload an image', 'error')
        return
      }
      item.uploaded_image = URL.createObjectURL(file);
      item.file = file
      this.$emit('change', file);
      this.img_key++
    },
    async takePhoto(item){
      let t = new Date().toLocaleString( 'sv' );
      this.handleUpload(item)
      if(!item.file){return}
      this.loading = true
      await this.saveFile(item)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos?bsId='+ item.id +'&t=' + t

      let data = {
        uploaded_image:  this.uploadUrl.split("?")[0],
        aspect_ratio: this.default_aspect_ratio
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''
          this.getPhotos()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        } else {
          swal({
            title: 'Something went wrong!',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Try again',
            buttonsStyling: false
          })
        }
      })
    },
  },
  mounted() {
    if(!(_.find(JSON.parse(localStorage.getItem('features')), {id: 5}))){
      this.photos_not_enabled = true
    } else {
      this.photos_not_enabled = false
    }
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if(this.time_format === 'HH:mm'){
      this.picker_time_format = 'HH:mm'
    }
    this.setToday()
    this.getDefaultTracker()
  }

}
</script>

<style scoped>
/*img {*/
/*  width: 60px;*/
/*  height: 60px;*/
/*  -webkit-border-radius: 60px;*/
/*  -webkit-background-clip: padding-box;*/
/*  -moz-border-radius: 60px;*/
/*  -moz-background-clip: padding;*/
/*  border-radius: 60px;*/
/*  background-clip: padding-box;*/
/*  margin: 7px 0 0 5px;*/
/*  float: left;*/
/*  background-size: cover;*/
/*  background-position: center center;*/
/*  margin-top: -7px;*/
/*  margin-bottom: -7px;*/
/*}*/
</style>
