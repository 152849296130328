<template>
<div>
  <loading-panel :loading="loading"></loading-panel>

<!--  Big chart-->
  <div class="row">
    <div class="col-12">
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6 text-left">
<!--              <h5 class="card-category">Totals by day</h5>-->
              <h2 class="card-title">Transactions overview</h2>

            </div>


            <div class="col-sm-6 d-flex d-sm-block">

              <div
                class="btn-group btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in bigLineChartCategories"
                  :key="index"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: bigLineChart.activeIndex === index }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initBigChart(index)"
                    name="options"
                    autocomplete="off"
                    :checked="bigLineChart.activeIndex === index"
                  />
                  <span class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <base-input>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="period"
                  placeholder="Per page"
                  v-on:change="getMetrics()"
                  value-key="id"
                >
                  <el-option
                    class="select-primary"
                    v-for="(item, index) in periodOptions"
                    :key="'I' + index"
                    :label="item.label"
                    :value="{ label : item.label, id: item.id}"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-2" v-if="custom_range">
              <base-input style=" margin-left: -15px">
                <el-date-picker
                  style="border: 0; border-width:0px;"
                  type="date"
                  placeholder="From"
                  :format="day_of_week + date_format_cal"
                  v-model="from_cal"
                  @change="validateCustomDates()"
                >
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-lg-2" v-if="custom_range">
              <base-input style=" margin-left: -15px">
                <el-date-picker
                  style="border: 0; border-width:0px;"
                  type="date"
                  placeholder="To"
                  :format="day_of_week + date_format_cal"
                  v-model="to_cal"
                  @change="validateCustomDates()"
                >
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-lg-2" v-if="custom_range">
             <base-button v-on:click="getMetrics('custom')"
               style="margin-top: 0; margin-left: -15px; padding-bottom: 9px; padding-top: 9px" type="primary">Update</base-button>
            </div>
          </div>
        </template>
        <!-- Stats Cards -->
        <div class="row">
          <div class="col-lg-2 col-md-6" v-for="(card, index) in statsCards" :key="index">
            <stats-card
              :title="card.title"
              :sub-title="card.subTitle"
              :type="card.type"
              :icon="card.icon"
            >
              <!--        <div slot="footer" v-html="card.footer"></div>-->
            </stats-card>
          </div>
        </div>
        <div class="chart-area mt-3">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>
  </div>





</div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from "@/components/Charts/config";
import config from "@/config";
import { Select, Option } from 'element-ui';
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import swal from "sweetalert2";

let bigChartData = [[], []]
let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
let bigChartLabels = []
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
}

export default {
  name: "TransactionDashboard",
  components: {
    StatsCard,
    LineChart,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    bigLineChartCategories() {
      return [{ name: 'Sales', icon: 'tim-icons icon-puzzle-10' }, { name: 'Transactions', icon: 'tim-icons  icon-single-02' }];
    }
  },
  data() {
    return {
      loading: false,
      currency_symbol: '$',
      date_format_cal: '',
      day_of_week: '',
      from_cal: '',
      to_cal: '',
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      custom_range: false,
      transaction_count: 0,
      sales_total: 0,
      taxes: 0,
      fees: 0,
      paid: 0,
      unpaid: 0,
      period: {
        id: 1,
        label: 'Last 7 days'
      },
      periodOptions: [
        {
          id: 0,
          label: 'Last 24 hours'
        },
        {
          id: 1,
          label: 'Last 7 days'
        },
        {
          id: 2,
          label: 'Last 30 days'
        },
        {
          id: 3,
          label: 'Custom'
        }
      ],
      statsCards: [
        {
          title: '0',
          subTitle: 'Transactions',
          type: 'success',
          icon: 'tim-icons el-icon-caret-top',
          footer: '<a href="/purchases/transactions?count=10&page=1">View all transactions</a>'
        },
        {
          title: '0',
          subTitle: 'Sales',
          type: 'success',
          icon: 'tim-icons el-icon-caret-top',
          footer: '<a href="/games/history?count=10&page=1">See game details</a>'
        },
        {
          title: '0',
          subTitle: 'Taxes',
          type: 'danger',
          icon: 'tim-icons el-icon-caret-bottom',
          footer: '<i class="tim-icons icon-notes"></i> ' +
            '<a href="/waivers/history?count=10&page=1">See player details</a>'
        },
        {
          title: '0',
          subTitle: 'Fees',
          type: 'success',
          icon: 'tim-icons icon-simple-delete\n' +
            '\n',
          footer: '<i class="tim-icons icon-pencil"></i> ' +
            '<a href="/surveys">Review surveys</a>'
        },
        {
          title: '0',
          subTitle: 'Paid',
          type: 'primary',
          icon: 'tim-icons icon-send',
          footer: '<i class="tim-icons icon-pencil"></i> ' +
            '<a href="/surveys">Review surveys</a>'
        },
        {
          title: '0',
          subTitle: 'Unpaid',
          type: 'primary',
          icon: 'tim-icons icon-send',
          footer: '<i class="tim-icons icon-pencil"></i> ' +
            '<a href="/surveys">Review surveys</a>'
        }
      ],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
    }
  },
  methods: {
    validateCustomDates(){
      if(this.to_cal < this.from_cal){
        this.from_cal = this.to_cal
      }
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getMetrics(type){

      bigChartData = [[], []]
      bigChartLabels = []
      let startDate = new Date()
      let endDate = new Date()
      let userStartDate = ''
      let userEndDate = endDate.getFullYear() + "-" + ('0' + (endDate.getMonth() + 1)).slice(-2) + "-" + ('0' + endDate.getDate()).slice(-2);

      if(this.period.id === 0){
        startDate.setDate(startDate.getDate()-1)
        userStartDate = startDate.getFullYear() + "-" + ('0' + (startDate.getMonth() + 1)).slice(-2) + "-" + ('0' + startDate.getDate()).slice(-2);
      } else if (this.period.id === 1){
        startDate.setDate(startDate.getDate()-7)
        userStartDate = startDate.getFullYear() + "-" + ('0' + (startDate.getMonth() + 1)).slice(-2) + "-" + ('0' + startDate.getDate()).slice(-2);
      } else if (this.period.id === 2){
        startDate.setMonth(startDate.getMonth()-1)
        userStartDate = startDate.getFullYear() + "-" + ('0' + (startDate.getMonth() + 1)).slice(-2) + "-" + ('0' + startDate.getDate()).slice(-2);
      } else if (type === 'custom' && this.period.id === 3){
        startDate = new Date(this.from_cal)
        endDate = new Date(this.to_cal)
        userStartDate = startDate.getFullYear() + "-" + ('0' + (startDate.getMonth() + 1)).slice(-2) + "-" + ('0' + startDate.getDate()).slice(-2);
        userEndDate = endDate.getFullYear() + "-" + ('0' + (endDate.getMonth() + 1)).slice(-2) + "-" + ('0' + endDate.getDate()).slice(-2);
      }

      if(type !== 'custom' && this.period.id === 3){
        this.to_cal = new Date()
        this.from_cal = new Date()
        this.from_cal.setDate(this.from_cal.getDate()-7)
        this.custom_range = true
        return
      } else if(this.period.id !== 3) {
        this.custom_range = false
      }


      let startLocalDate = new Date(`${userStartDate}T00:00:00`);
      let endLocalDate = new Date(`${userEndDate}T23:59:59`);

      let from = startLocalDate.toISOString();
      let to = endLocalDate.toISOString();

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/dashboards/transactions?from=' + from + '&to=' + to

      axios.get(url, config)
        .then(response => {

          //Stats
          this.statsCards[0].title = JSON.stringify(response.data.stats.transaction_count)
          this.statsCards[1].title = this.currency_symbol + parseFloat(response.data.stats.sales_total).toFixed(2)
          this.statsCards[2].title = this.currency_symbol + parseFloat(response.data.stats.taxes_total).toFixed(2)
          this.statsCards[3].title = this.currency_symbol + parseFloat(response.data.stats.fee_total).toFixed(2)
          this.statsCards[4].title = this.currency_symbol + parseFloat(response.data.stats.paid_total).toFixed(2)
          this.statsCards[5].title = this.currency_symbol + parseFloat(response.data.stats.unpaid_total).toFixed(2)

          if(parseInt(response.data.stats.transaction_count) < parseInt(response.data.past_period_stats.transaction_count)){
            this.statsCards[0].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[0].type = 'danger'
          } else if (parseInt(response.data.stats.transaction_count) > parseInt(response.data.past_period_stats.transaction_count)){
            this.statsCards[0].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[0].type = 'success'
          } else if (parseInt(response.data.stats.transaction_count) == parseInt(response.data.past_period_stats.transaction_count)){
            this.statsCards[0].icon = 'tim-icons icon-simple-delete'
            this.statsCards[0].type = 'success'
          }

          if(parseInt(response.data.stats.sales_total) < parseInt(response.data.past_period_stats.sales_total)){
            this.statsCards[1].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[1].type = 'danger'
          } else if (parseInt(response.data.stats.sales_total) > parseInt(response.data.past_period_stats.sales_total)){
            this.statsCards[1].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[1].type = 'success'
          } else if (parseInt(response.data.stats.sales_total) == parseInt(response.data.past_period_stats.sales_total)){
            this.statsCards[1].icon = 'tim-icons icon-simple-delete'
            this.statsCards[1].type = 'success'
          }

          if(parseInt(response.data.stats.taxes_total) < parseInt(response.data.past_period_stats.taxes_total)){
            this.statsCards[2].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[2].type = 'danger'
          } else if (parseInt(response.data.stats.taxes_total) > parseInt(response.data.past_period_stats.taxes_total)){
            this.statsCards[2].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[2].type = 'success'
          } else if (parseInt(response.data.stats.taxes_total) == parseInt(response.data.past_period_stats.taxes_total)){
            this.statsCards[2].icon = 'tim-icons icon-simple-delete'
            this.statsCards[2].type = 'success'
          }

          if(parseInt(response.data.stats.fee_total) < parseInt(response.data.past_period_stats.fee_total)){
            this.statsCards[3].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[3].type = 'danger'
          } else if (parseInt(response.data.stats.fee_total) > parseInt(response.data.past_period_stats.fee_total)){
            this.statsCards[3].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[3].type = 'success'
          } else if (parseInt(response.data.stats.fee_total) == parseInt(response.data.past_period_stats.fee_total)){
            this.statsCards[3].icon = 'tim-icons icon-simple-delete'
            this.statsCards[3].type = 'success'
          }

          if(parseInt(response.data.stats.paid_total) < parseInt(response.data.past_period_stats.paid_total)){
            this.statsCards[4].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[4].type = 'danger'
          } else if (parseInt(response.data.stats.paid_total) > parseInt(response.data.past_period_stats.paid_total)){
            this.statsCards[4].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[4].type = 'success'
          } else if (parseInt(response.data.stats.paid_total) == parseInt(response.data.past_period_stats.paid_total)){
            this.statsCards[4].icon = 'tim-icons icon-simple-delete'
            this.statsCards[4].type = 'success'
          }

          if(parseInt(response.data.stats.unpaid_total) < parseInt(response.data.past_period_stats.unpaid_total)){
            this.statsCards[5].icon = 'tim-icons el-icon-caret-bottom'
            this.statsCards[5].type = 'danger'
          } else if (parseInt(response.data.stats.unpaid_total) > parseInt(response.data.past_period_stats.unpaid_total)){
            this.statsCards[5].icon = 'tim-icons el-icon-caret-top'
            this.statsCards[5].type = 'success'
          } else if (parseInt(response.data.stats.unpaid_total) == parseInt(response.data.past_period_stats.unpaid_total)){
            this.statsCards[5].icon = 'tim-icons icon-simple-delete'
            this.statsCards[5].type = 'success'
          }

          for(let n in response.data.main_chart){
            let local = months[parseInt(response.data.main_chart[n].transaction_date.split('T')[0].split('-')[1]) - 1] + " "  +
              response.data.main_chart[n].transaction_date.split('T')[0].split('-')[2]

            this.$set(bigChartLabels, n, local);
            this.$set(bigChartData[0], n, response.data.main_chart[n].total);
            this.$set(bigChartData[1], n, response.data.main_chart[n].count);
          }

          this.initBigChart(0)

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  mounted() {
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.getMetrics()
  }
}
</script>

<style scoped>

</style>
