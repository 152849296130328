/**
 * Functions to format date and time
 */


/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns MM-DD-YYYY
 */
function getDate(d){
  if(d == null){
    return
  }
  let dd = d.split('T')[0]
  let date = dd.split('-')
  return date[1] + '-' + date[2] + '-' + date[0]
}

function convertToMilitary(time) {
  // Regular expression to match AM/PM time format (hh:mm AM/PM)
  const amPmRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9])\s?([APap][Mm])$/;
  // Regular expression to match military time format (HH:MM or HH:MM:SS)
  const militaryRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9]))?$/;

  // Check if the time is in AM/PM format
  if (amPmRegex.test(time)) {
    let [_, hours, minutes, period] = time.match(amPmRegex);
    hours = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, '0')}:${minutes}`;
  } else if (militaryRegex.test(time)) {
    // The time is already in military format
    return time;
  } else {
    throw new Error('Invalid time format');
  }
}

function getFormattedDate(format, d){
  if(d == undefined){
    return
  }
  let date = ''
  if(d.length > 10){
    let dd = d.split('T')[0]
    date = dd.split('-')
  } else {
    date = d.split('-')
  }

  if(format === 'MM/DD/YYYY'){
    return ('0' + date[1]).slice(-2)  + '/' +  ('0' + date[2]).slice(-2)  + '/' + date[0]
  } else if (format === 'DD/MM/YYYY'){
    return ('0' + date[2]).slice(-2)  + '/' +  ('0' + date[1]).slice(-2)  + '/' + date[0]
  } else if (format === 'YYYY/MM/DD'){
    return date[0] + '/' +  ('0' + date[1]).slice(-2)  + '/' + ('0' + date[2]).slice(-2)
  } else if (format === 'DD MMM YYYY'){
    let m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return ('0' + date[2]).slice(-2)  + ' ' +  m[date[1]-1]  + ' ' + date[0]
  } else if (format === 'MMMM DD, YYYY'){
    let months = ["January","February","March","April","May","June","July",
      "August","September","October","November","December"]
    return date[2] + ' ' + months[date[1]-1] + ' ' + date[0]
  }
}


function get12HrTime(time){
  let hour = time.split(':')[0]
  let minute = time.split(':')[1]
  let dd = 'AM'

  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
    if (hour < 10) {
      hour = '0' + hour
    }
  }
  if (hour == 0) {
    hour = 12;
  }
  return ('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2) + ' ' + dd
}

function get24HrTime(time){
  let hour = time.split(':')[0]
  let minute = time.split(':')[1]

  return hour + ':' + ('0' + minute).slice(-2)
}
/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns HH-MM AM/PM
 */
function getTime(format, d){
  if(d == undefined){
    return
  }
  let t = ''
  let time = ''

  if(d.includes('T')){
    t = d.split('T')[1]
  } else {
    t = d
  }

  if(format === 'HH:mm'){
    time = get24HrTime(t)
  } else {
    time = get12HrTime(t)
  }

  return time
}

/**
 * Takes HH:MM:SS.000Z and returns HH-MM AM/PM
 */
function getTimeFromTime(d){
  if(d == undefined){
    return
  }

  let time = d.split(':')

  let hour = parseInt(time[0])
  let minute = time[1]

  let dd = 'AM'
  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
  }
  if (hour == 0) {
    hour = 12;
  }
  if (hour < 10) {
    hour = '0' + hour
  }

  return hour + ':' + minute + ' ' + dd
}

/**
 * Takes MM-DD-YYYY and returns YYYY-MM-DD
 */
function setDate(d) {
  console.log('before reversal')
  console.log(d)
  let date = d.split('-')
  return date[2] + '-' + date[0] + '-' + date[1]
}

/**
 * Takes AM/PM format and returns hh:mm
 */
function getReversedTime(d) {
  let dd = d.split(' ')[1]
  let time = d.split(' ')[0]
  let hh = time.split(':')[0]
  let mm = time.split(':')[1]

  // if(parseInt(mm) < 10){
  //   mm = '0' + mm
  // }
  if (dd == 'AM') {

    if (hh == 12) {
      hh = '00'
    }
    // if(parseInt(hh) < 10){
    //   hh = '0' + hh
    // }
    return hh + ':' + mm
  } else {
    if (parseInt(hh) < 12) {
      hh = parseInt(hh) + 12
    }
    return hh + ':' + mm
  }
}

function getAmPm(d){
  if(d == null){
    return
  }
  let hour = d.split(':')[0]
  let minute = d.split(':')[1]
  let dd = 'AM'
  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
    if (hour < 10) {
      hour = '0' + hour
    }
  }
  if (hour == 0) {
    hour = 12;
  }

  return hour + ':' + minute + ' ' + dd
}

function getLocalDate(format, d){
  let local = new Date(d)
  let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
  let final = getFormattedDate(format, form)
  return final
}

function getLocalTime(format, d){
  let local = new Date(d)
  let mins =  local.getMinutes()

  if(mins < 10){
    mins = '0' + mins
  }
  return getTime(format, local.getHours() + ':' + mins)
}

function getDayOfWeek(d) {
  if(d == undefined){
    return
  }
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let dt = new Date()
  let date = ''
  if(d.length > 10){
    let dd = d.split('T')[0]
    date = dd.split('-')
  } else {
    date = d.split('-')
  }
  dt.setFullYear(date[0])
  dt.setMonth(parseInt(date[1] - 1), date[2])
  return days[dt.getDay()]
}

export {convertToMilitary as convertToMilitary, getDayOfWeek as dayOfWeek, getLocalTime as localTime, getLocalDate as localDate, getDate as date, getTimeFromTime as simpleTime, getReversedTime as reverseTime, getFormattedDate as formattedDate, setDate as reverseDate, getTime as time, getAmPm as ampm}
