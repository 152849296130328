<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/settings/user-management">Users</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Deactivated users</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--Medium and large screens-->
    <div class="row d-none d-sm-block">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Deactivated Users</h4>
          <div class="row">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12"
            >
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Search users"
                      v-model="filter"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <div>
                  <base-button class="ml-3" simple size="md" v-on:click="viewActiveUsers()" type="primary">
                    View Active Users
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="noGuideAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noGuideAvailable === false" class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="users"
                       :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :fields="fields"
                       @filtered="onFiltered"
              >
                <template v-slot:cell(actions)="row">
                  <div class="row">
                    <base-button
                      @click.native="enableUserAlert(row.item)"
                      type="success"
                      size="sm"
                      simple
                    >Activate
                    </base-button>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Deactivated Users</h4>
          <div class="row">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12"
            >
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Search users"
                      v-model="filter"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <div>
                  <base-button class="ml-3" simple size="md" v-on:click="viewActiveUsers()" type="primary">
                    View Active Users
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="noGuideAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noGuideAvailable === false" class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="users"
                       :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :fields="mobile_fields"
                       @filtered="onFiltered"
              >
                <template v-slot:cell(users)="row">
                  <div class="row">
                    <div class="col-7">
                      <span class="text-primary">{{row.item.first_name}} {{row.item.last_name}}</span>
                    </div>
                    <div class="col-5">
                      <base-button
                        @click.native="enableUserAlert(row.item)"
                        type="success"
                        class="like btn-link"
                        size="sm"
                        icon
                        simple
                      ><i class="tim-icons icon-triangle-right-17"></i>
                      </base-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span class="small">{{row.item.email}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span class="small">{{row.item.phone}}</span>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="3"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
    import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
    import { Select, Option } from 'element-ui';
    import { Auth } from 'aws-amplify'
    import swal from 'sweetalert2'
    import { API_LOCATION } from '../../../api-config'
    const axios = require('axios')
    import {Badge} from '@/components'
    import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
    import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

    export default {
      name: "DeactivatedUsers",
      components: {
        BreadcrumbItem,
        Breadcrumb,
        [Select.name]: Select,
        [Option.name]: Option,
        BFormInput,
        BTable,
        BPagination,
        Badge
      },
      data() {
        return {
          selectedRole: [],
          selectedGroup: [],
          noGuideAvailable: false,
          loading: false,
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          filter: null,
          fields: ['first_name', 'last_name', 'email', 'phone', 'actions'],
          mobile_fields: ['users'],
          users: [],
          roles: '',
          groups: ''
        };
      },
      methods: {
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        async enableUser(item){
          this.loading = true
          var token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users/activate'
          let data = {
            email: item.email
          }

          axios.post(url, data, config)
            .then(response => {
              swal('Success!', 'User has been activated', 'success')
              this.getUsers()
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        enableUserAlert(item){
          swal({
            title: 'Are you sure?',
            text: "This action will activate the user " + item.email,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Activate',
            cancelButtonText: 'Cancel'
          }).then((response)=> {
            if(response.value == true){
              this.enableUser(item)
            }
          })

        },
        async getUsers() {
          this.loading = true
          var token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users/deactivate'

          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.users = response.data
              if(this.users.length <= 0){
                this.noGuideAvailable = true
              }
              this.totalRows = response.data.length
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        setRole(index) {
          let selectedRole = this.roles.filter(obj => {
            return obj.type === this.selectedRole[index]
          })
          return selectedRole
        },
        setGroup(index) {
          console.log(this.selectedGroup[index].length)
          let selectedGroups = []
          for(let n in this.selectedGroup[index]){
            console.log(this.selectedGroup[index][n])
            selectedGroups.push(this.groups.filter(obj => {
              return obj.name === this.selectedGroup[index][n]
            }))

          }
          console.log(selectedGroups)
          return selectedGroups
        },
        viewActiveUsers(){
          this.$router.push('user-management')

        }
      },
      mounted(){
        this.getUsers()
      }
    }
</script>

<style scoped>
</style>
