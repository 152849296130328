<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/company">Company settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a href="/settings/migration">Data migration</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ log.type }}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="row">
          <div class="col">
            <h4 style="margin-bottom: 0px;">{{ log.type }}
              <badge>{{log.number_of_records}} records</badge></h4>
            <small style=" margin-top:0; font-style: italic">Uploaded by {{log.first_name}} {{log.last_name}}
              on  {{getLocalDate(log.timestamp)}}, {{getLocalTime(log.timestamp)}}
            </small>
          </div>
          <div class="col text-right">
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="perPage"
              placeholder="Per page"
              v-on:change="getMigrationRecords(perPage, getOffset())"
            >
              <el-option
                class="select-primary"
                v-for="(item, index) in perPageOptions"
                :key="'B' + index"
                :label="item.toString()"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>


        <div class="row mt-2" style="overflow-x: scroll">
          <div class="col">
            <b-table :items="migration_records"
                     >
              <template v-slot:cell(type_name)="row">
                <badge>{{row.item.type_name}}</badge>
              </template>
              <template v-slot:cell(opted_out_email)="row">
                <i v-if="row.item.opted_out_email" class="tim-icons icon-check-2 text-success"></i>
                <i v-else-if="!row.item.opted_out_email" class="tim-icons icon-simple-remove text-danger"></i>
              </template>
              <template v-slot:cell(opted_out_photo_sharing)="row">
                <i v-if="row.item.opted_out_photo_sharing" class="tim-icons icon-check-2 text-success"></i>
                <i v-else-if="!row.item.opted_out_photo_sharing" class="tim-icons icon-simple-remove text-danger"></i>
              </template>
              <template v-slot:cell(birthday)="row">
                <span v-if="row.item.birthday !== '0000-00-00'"> {{getFormattedDate(row.item.birthday)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(date_joined)="row">
                <span v-if="!row.item.date_joined.includes('0000-00-00')"> {{getLocalDate(row.item.date_joined)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(date_last_visited)="row">
                <span v-if="!row.item.date_last_visited.includes('0000-00-00')"> {{getLocalDate(row.item.date_last_visited)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(start_date)="row">
                <span v-if="row.item.start_date !== '0000-00-00'"> {{getFormattedDate(row.item.start_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(end_date)="row">
                <span v-if="row.item.end_date !== '0000-00-00'"> {{getFormattedDate(row.item.end_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(delivery_date)="row">
                <span v-if="row.item.delivery_date !== '0000-00-00'"> {{getFormattedDate(row.item.delivery_date)}}</span>
              </template>
              <template v-slot:cell(date_purchased)="row">
                <span v-if="row.item.date_purchased !== '0000-00-00'"> {{getFormattedDate(row.item.date_purchased)}}</span>
              </template>
              <template v-slot:cell(start_time)="row">
                <span v-if="row.item.start_time"> {{getTime(row.item.start_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(end_time)="row">
                <span v-if="row.item.end_time"> {{getTime(row.item.end_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(status)="row">
                <badge type="primary"> {{row.item.status}}</badge>
              </template>
              <template v-slot:cell(total_value)="row">
                {{getAmount(row.item.total_value)}}
              </template>
              <template v-slot:cell(price)="row">
                {{getAmount(row.item.price)}}
              </template>
              <template v-slot:cell(paid)="row">
                {{getAmount(row.item.paid)}}
              </template>
              <template v-slot:cell(due)="row">
                {{getAmount(row.item.due)}}
              </template>
              <template v-slot:cell(taxes)="row">
                {{getAmount(row.item.taxes)}}
              </template>
              <template v-slot:cell(fees)="row">
                {{getAmount(row.item.fees)}}
              </template>
              <template v-slot:cell(total)="row">
                {{getAmount(row.item.total)}}
              </template>
              <template v-slot:cell(refunded)="row">
                {{getAmount(row.item.refunded)}}
              </template>
              <template v-slot:cell(value_amount)="row">
                {{getAmount(row.item.value_amount)}}
              </template>
              <template v-slot:cell(remaining)="row">
                {{getAmount(row.item.remaining)}}
              </template>
              <template v-slot:cell(spent)="row">
                {{getAmount(row.item.spent)}}
              </template>
              <template v-slot:cell(redemption_type)="row">
                <badge v-if="!row.item.redemption_type">percentage</badge>
                <badge v-if="row.item.redemption_type">value</badge>
              </template>
              <template v-slot:cell(order_number)="row">
                #{{row.item.order_number}}
              </template>
              <template v-slot:cell(date_created)="row">
                {{getLocalDate(row.item.date_created)}}, {{getLocalTime(row.item.date_created)}}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              v-on:input="getMigrationRecords(perPage, getOffset())"
            ></b-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { BTable, BPagination } from 'bootstrap-vue'
import {BaseAlert} from "@/components";
import Badge from "@/components/Badge";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";

export default {
  name: "MigrationLog",
  data(){
    return {
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      migration_records: [],
      log: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      perPageOptions: [5, 10, 25, 50],
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
    }
  },
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BaseAlert,
    BTable,
    BPagination,
    Badge
  },
  methods: {
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 0 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getMigrationRecords(count, offset){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/migrations/' + this.$route.query.id + '?type=' + this.$route.query.type + '&count=' + count + '&offset=' + offset
      this.loading = true

      this.axios.get(url, config)
        .then(response => {
          this.migration_records = response.data.records
          this.log = response.data.migration_log
          this.totalRows = response.data.total_rows
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted() {
    this.getMigrationRecords(this.perPage, 0)
  }
}
</script>

<style scoped>

</style>
