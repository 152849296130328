<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/user-management">Users</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Roles</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col">
      <card>

        <div class="row mb-2">
          <div class="col">
            <h4 class="card-title">Roles</h4>
          </div>
          <div class="col text-right">
            <base-button size="sm" v-on:click="createRole()" type="primary" >
              <i class="tim-icons icon-simple-add mr-2"></i> Create role</base-button>
          </div>
        </div>

        <div v-if="roles.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            <h4 class="mt-3">No roles exist.</h4>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div class="row" v-if="roles.length > 0">
            <div class="col">
              <b-table  striped hover :items="roles"
                        :fields="['role_name',  'time_created', 'actions']">
                <template v-slot:cell(role_name)="row">
                  <p>{{row.item.name}} <badge class="ml-2" v-if="row.item.default_role">default</badge>
                  </p>
                  <small>{{row.item.description}}</small>
                </template>
                <template v-slot:cell(time_created)="row">
                  <div v-if="row.item.default_role">
                    -
                  </div>
                  <div v-else>
                    {{getLocalDate(row.item.time_created)}}, {{getLocalTime(row.item.time_created)}}
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <div class="ml-4" v-if="row.item.default_role">
                    -
                  </div>
                  <div v-else>
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2" @click="editRole(row.item.id)" href="#">
                            <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit role
                          </a>
                          <hr>
                          <a class="dropdown-item" @click="deleteRolePrompt(row.item.id)" href="#">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete role
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <div class="row" v-if="roles.length > 0">
            <div class="col">
              <b-table  striped hover :items="roles"
                        :fields="['roles']">
                <template v-slot:cell(roles)="row">
                  <h4>{{row.item.name}}
                    <badge class="ml-2" v-if="row.item.default_role">default</badge>
                  </h4>
                  <small>{{row.item.description}}</small>
                  <div  class="row" v-if="!row.item.default_role">
                    <div class="col">
                      Created on {{getLocalDate(row.item.time_created)}}, {{getLocalTime(row.item.time_created)}}
                    </div>
                  </div>
                  <div v-if="!row.item.default_role"
                    class="btn-group btn-group-toggle mt-3 mb-2"
                    :class="'float-left'"
                    data-toggle="buttons" style="width:100%"
                  >
                    <label class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                      <base-button
                        @click.native="editRole(row.item.id)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </label>
                    <label class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                      <base-button
                        @click.native="deleteRolePrompt(row.item.id)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </label>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import { BTable } from 'bootstrap-vue'
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Badge from "@/components/Badge";

export default {
  name: "UserRoles",
  components: {
    BTable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BreadcrumbItem,
    Breadcrumb,
    Badge
  },
  data() {
    return {
      loading: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      roles: [],
    }
  },
  methods: {
    deleteRolePrompt(id){
      swal({
        title: 'Are you sure?',
        text: `The role will be permanently deleted. Users may lose access.`,
        showCancelButton: true,
        type: 'warning',
      }).then((response)=> {
        if(response.value == true){
          this.deleteRole(id)
        }
      })
    },
    async deleteRole(id){
      let companyId = localStorage.getItem('companyId')
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/roles/' + id
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'The role has been deleted', 'success')
          this.getRoles()
        })
        .catch(err => {
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createRole(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/roles'
      let data = {
        role: {
          name: 'New role',
          description: ''
        },
      }

      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          // swal('Success', 'Survey has been created', 'success')
          this.$router.push({ path: '/settings/roles/edit', query: { id:  response.data }})
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editRole(id){
      this.$router.push({ path: '/settings/roles/edit', query: { id:  id }})
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getRoles() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/roles'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.roles = response.data.default_roles.concat(response.data.roles)
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted(){
    this.getRoles()
  }
}
</script>

<style scoped>

</style>
