<template>
  <div>
    <loading-panel :loading="loading"></loading-panel>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/games/settings">Game settings</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Add new game</span>
      </breadcrumb-item>
    </breadcrumb>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard>
          <template slot="header">
            <h3 class="card-title">Add New Game</h3>
<!--            <h3 class="description">-->
<!--              configure your new game and start collecting data about it-->
<!--            </h3>-->
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-components"></i>
              <p>Details</p>
            </template>
            <game-details
              ref="step1"
              @on-validated="onStepValidated"
            ></game-details>
          </wizard-tab>

          <wizard-tab>
            <template slot="label">
              <i class="tim-icons icon-image-02"></i>
              <p>Images</p>
            </template>
            <h5 class="info-text">
              Add game images
            </h5>
            <div class="row justify-content-center"  v-if="game_images.length > 0">
              <div class="col-lg-5 text-center">
                <el-carousel :autoplay="false" indicator-position="inside">
                  <el-carousel-item style="border-radius: 5px; width: 100%; height: 250px;"
                                    :style="{'background-color': item.background_color}" v-for="(item, index) in game_images"
                                    :key="'CS' + index">
                    <div  style="border-radius: 5px; width: 100%; height: 250px; position: relative">
                      <img :style="{'transform': 'translate(0%, -14%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px'}"
                           style="position: absolute; object-fit: cover; transform-origin: center center; will-change: transform;" :src="item.url">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center">
                <image-editor @saveImage="saveImage"></image-editor>
              </div>
            </div>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-money-coins"></i>
              <p>Pricing</p>
            </template>
            <game-pricing ref="step2" @on-validated="onStepValidated"></game-pricing>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-puzzle-10"></i>
              <p>Puzzles</p>
            </template>
            <game-puzzles ref="step3" @on-validated="onStepValidated"></game-puzzles>
          </wizard-tab>

          <wizard-tab :before-change="() => wizardComplete()">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Review</p>
            </template>
            <game-review @on-validated="onStepValidated" ref="step4"></game-review>
          </wizard-tab>

        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>
  import { SimpleWizard, WizardTab } from 'src/components'
  import GameDetails from "./GameDetails";
  import GamePuzzles from "./GamePuzzles"
  import GameReview from "./GameReview"
  import GamePricing from "@/pages/Games/GameSetup/GamePricing";
  import swal from 'sweetalert2'
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
  import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
  import ImageEditor from "@/components/ImageEditor";
  import { CarouselItem, Carousel } from 'element-ui';

  export default {
    name: "AddGame",
    components: {
      BreadcrumbItem,
      Breadcrumb,
      SimpleWizard,
      WizardTab,
      GameReview,
      GamePuzzles,
      GameDetails,
      GamePricing,
      ImageEditor,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem
    },
    data() {
      return {
        loading: false,
        game_images: []
      }
    },
    methods: {
      saveImage(value){
        this.game_images.push(value)
      },
      validateStep(ref) {
        return this.$refs[ref].validate();
      },
      onStepValidated(validated, model) {
        this.wizardModel = { ...this.wizardModel, ...model };
        this.wizardModel.game_images = this.game_images
        this.$refs['step4'].loadModel(this.wizardModel)
      },
      async wizardComplete() {
        this.loading = true
        var created = await this.$refs['step4'].createGame()
        this.loading = false
        if(created.status == 200){
          swal('Good job!', 'You created a new game!', 'success')
            .then((response)=> {
              localStorage.removeItem('game')
              this.$router.push('settings')
          })
        } else {
          swal('Something went wrong', 'Please review the form!', 'error');

        }
      }
    },
  }
</script>

<style scoped>

</style>
