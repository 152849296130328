<template>
  <div>
<!--    Loading-->
    <loading-panel :loading="loading"></loading-panel>
    <h5 class="info-text">
      Which game are you running?
    </h5>
    <div class="row">
      <div class="col-lg-9 col-md-10 col-sm-10 col-12 ml-auto mr-auto">
        <base-input class="pull-left" style="width: 200px;" v-if="!incomplete_view">
          <el-date-picker
            type="date"
            placeholder="Select date"
            value-format="yyyy-MM-dd"
            :format="day_of_week + date_format_cal"
            v-model="current"
            @change="getBookings()"
          >
          </el-date-picker>
        </base-input>
        <base-button v-on:click="getIncompleteForms()" class="pull-right" size="sm" v-show="!incomplete_view" simple type="primary">Show all incomplete bookings</base-button>
        <base-button v-on:click="toggleStatus(0)" class="pull-right" size="sm" v-show="incomplete_view" simple type="primary">Show bookings by date</base-button>

        <base-button v-on:click="toggleStatus(1)" class="pull-right mr-1"  size="sm" v-show="!show_all && !incomplete_view" simple type="success">Show all game slots</base-button>
        <base-button v-on:click="toggleStatus(0)" class="pull-right" size="sm" v-show="show_all" simple type="success">Show only checked in game slots</base-button>

      </div>
<!--      <div class="col text-right pull-right">-->
<!--        -->
<!--      </div>-->
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-10 col-sm-10 col-12 ml-auto mr-auto">
        <!--   No data panel -->
        <div v-if="empty==true" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No bookings available today.</h4>
          </div>
        </div>
        <b-table  v-if="empty==false" striped hover :items="unvalidatedBookings.bookings" :fields="fields">
          <template v-slot:cell(game)="row">
            <h4 class=" text-primary">{{row.item.game_name}}</h4>
          </template>
          <template v-slot:cell(booked_by)="row">
            <span v-if="row.item.transaction_id">{{row.item.first_name}} {{row.item.last_name}}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(booking_day)="row">
            <span>{{getDate(row.item.booking_date)}}</span>
          </template>
          <template v-slot:cell(booking_time)="row">
            <span>{{getTime(row.item.start_time)}}</span>
          </template>
          <template v-slot:cell(select)="row">
            <div class="row" :key="k">
              <div
                class="form-check form-check-radio form-check-in-table"
              >
                <label class="form-check-label">
                  <input
                    v-model="selectedBooking" v-on:change="setGame"
                    class="form-check-input" ref="radio" :name="row.item.id" :value="row.item"
                    type="radio"
                  />
                  <slot></slot> <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import { Select, Option, DatePicker, TimeSelect, Input } from 'element-ui';
  import BaseRadio from "@/components/Inputs/BaseRadio";
  const axios = require('axios')
  import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
  import {dayOfWeek, formattedDate, time} from '../../../plugins/dateFormatter'
  import swal from "sweetalert2";

  export default {
    components: {
      BaseRadio,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [TimeSelect.name]: TimeSelect,
      [Input.name]: Input,
      BFormInput,
      BTable,
      BPagination
    },
    data() {
      return {
        empty: false,
        loading: false,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        date_format_cal: '',
        day_of_week: '',
        current: '',
        unvalidatedBookings: {
          bookings: []
        },
        selectedBooking: '',
        fields: [
          {key: 'game', sortable: false},
          {key: 'booked_by', sortable: false},
          {key: 'booking_day', sortable: false},
          {key: 'booking_time', sortable: false},
          {key: 'select', sortable: false }],
        k: 0,
        show_all: 0,
        incomplete_view: false,
        model: {
          booking: {
            id: '',
            name: '',
            game_id: '',
            booking_time: ''

          }
        },
      };
    },
    methods: {
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      async getIncompleteForms() {
        this.loading = true
        this.incomplete_view = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/bookings?type=5'
        axios.get(url, config)
          .then(response => {
            this.unvalidatedBookings = response.data
            this.loading = false
          })
          .catch(err => {
            // this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      clearSelection(){
        this.selectedBooking = ''
        this.setToday()
      },
      toggleStatus(status){
        this.incomplete_view = false
        this.show_all = status
        this.getBookings()
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      setToday(){
        let current = new Date()
        this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      },
      async getBookings (state) {
        if(state === 0 && JSON.parse(localStorage.getItem('draft')) && JSON.parse(localStorage.getItem('draft')).booking_date){
          this.current = JSON.parse(localStorage.getItem('draft')).booking_date.split('T')[0]
        }
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        // let url = API_LOCATION + 'companies/' + companyId + '/bookings?t=' + this.current + '&all=' + this.show_all
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings?day='
                      + this.current  + '&all=' + this.show_all + '&type=1'
        axios.get(url, config)
          .then(response => {
            // this.loading = false
            this.unvalidatedBookings = response.data
            if(this.unvalidatedBookings.bookings.length <= 0){
              this.empty = true
            } else {
              this.empty = false
            }

            for(let n in this.unvalidatedBookings.bookings){
              if(JSON.parse(localStorage.getItem('draft'))
                && JSON.parse(localStorage.getItem('draft')).booking_slot_id
               && JSON.parse(localStorage.getItem('draft')).booking_slot_id === this.unvalidatedBookings.bookings[n].id){
                this.selectedBooking = this.unvalidatedBookings.bookings[n]
              }
            }
          })
          .catch(err => {
            // this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      isEmptyBooking(){
        if(!JSON.parse(localStorage.getItem('draft')) || JSON.parse(localStorage.getItem('draft')).booking_date){
          return false
        }
        if(this.model.booking.id.length <= 0 || this.model.booking.game_id <= 0){
          swal({
            title: 'No booking slot selected',
            text: "You must select a booking slot",
            type: 'error',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          return true
        }
        return false
      },
      async setBookingData () {
        if(this.selectedBooking){
          this.model.booking = this.selectedBooking
        }
        localStorage.setItem('bookingInfo', JSON.stringify(this.model))
        //check if booking id is already set and is the same as draft
        if(JSON.parse(localStorage.getItem('draft')) && this.selectedBooking.id === JSON.parse(localStorage.getItem('draft')).booking_slot_id){
          return true
        }
        //save draft
        let companyId = localStorage.getItem('companyId')

        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'companies/' + companyId
          + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'
        let data = {
          booking_slot_id: this.model.booking.id,
          game_id: this.model.booking.game_id
        }

        return new Promise ( function(resolve) {
          axios.put(url, data, config)
            .then(response => {
              // let draft = JSON.parse(localStorage.getItem('draft'))
              // draft.booking_slot_id = data.booking_slot_id
              // draft.game_id = data.game_id
              //
              // localStorage.setItem('draft', JSON.stringify(draft))
              resolve(true)
            })
            .catch(err => {
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              if(err.response.status == 500){
                swal({
                  title: 'This booking slot is already attached to another draft',
                  text: "Delete the draft or select another booking slot",
                  type: 'error',
                  confirmButtonColor: '#1d8cf8',
                  confirmButtonText: 'OK'
                })
                resolve(false)
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        })


      },
      getDate(d){
        return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
      },
      getTime(d){
        return time(this.time_format, d)
      },
      async setGame () {
        this.model.booking = this.selectedBooking
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model);
          return res;
        });
      }
    },
    mounted () {
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      this.setToday()
    }
  };
</script>

<style scoped>
  .form-check-in-table input[type="radio"]:checked + .form-check-sign::after{
    width: 6px;
    height: 6px;
    top: -11px;
    left: 10px;
    opacity: 1;
  }
</style>
