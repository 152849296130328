<template>
  <div class="container not-found-page ">
    <div class="not-found-page">
      <h1>401</h1>
      <img height="300px" src="https://console.offthecouch.io/img/pandas/angry.png">
      <p class="mt-4">You are not authorized to view this page. Please contact a site administrator for access.</p>
      <base-button v-on:click="toProfile()">Back to platform</base-button>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      toProfile(){
        this.$router.push('/')
      }
    },
  };
</script>
<style scoped>
  .not-found-page {
    min-height: calc(140vh - 160px);
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
</style>
