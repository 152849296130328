<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter">{{ option.name }}</span>

              </label>
            </div>
          </div>

          <div class="d-none d-sm-block">
            <div class="col text-right" v-if="hasPermissions('inventory_create')">
              <base-button v-if="activeTabIndex === 0" size="sm" v-on:click="addItem()" type="primary" >
                <i class="tim-icons icon-simple-add mr-2"></i> Add new item</base-button>
              <base-button v-if="activeTabIndex === 1" size="sm" v-on:click="addNewItemCategory()" type="primary" >
                <i class="tim-icons icon-simple-add mr-2"></i> Add new item category</base-button>

            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">
          <div class="d-block d-sm-none">
            <div class="row">
              <div class="col-8" v-if="hasPermissions('inventory_create')">
                <base-button v-if="activeTabIndex === 0" size="sm" v-on:click="addItem()" type="primary" >
                  <i class="tim-icons icon-simple-add mr-2"></i> Add new item</base-button>
              </div>
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row" v-show="show_filters">
              <div
                class="col d-flex"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Item name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Item name"
                            v-model="filters.item_name.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.item_name)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" :simple="!filters.item_name.actual" size="sm" type="primary">
                      Item <span v-if="filters.item_name.actual">: {{filters.item_name.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Category</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.category.value"
                              class="select-primary"
                              name="game"
                              placeholder="Category"
                              value-key="id"
                            >
                              <el-option
                                v-for="(f, i) in available_categories"
                                class="select-primary"
                                :label="f.name"
                                :value="{id: f.id, name: f.name}"
                                :key="'FS' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.category)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.category.actual" size="sm" type="primary">
                      Category <span v-if="filters.category.actual">: {{filters.category.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Unit price</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Unit price"
                            v-model="filters.unit_price.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.unit_price)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.unit_price.actual" size="sm" type="primary">
                      Unit price <span v-if="filters.unit_price.actual">: {{filters.unit_price.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Available</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Available"
                            v-model="filters.available.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.available)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.available.actual" size="sm" type="primary">
                      Available <span v-if="filters.available.actual">: {{filters.available.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Purchased</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Purchased"
                            v-model="filters.purchased.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.purchased)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.purchased.actual" size="sm" type="primary">
                      Purchased <span v-if="filters.purchased.actual">: {{filters.purchased.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Internal only</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div class="col-12">
                            <base-radio v-model="filters.internal_only.value" name="1" class="mb-3">
                              Yes
                            </base-radio>
                          </div>
                          <div class="col-12">
                            <base-radio v-model="filters.internal_only.value" name="0" class="mb-3">
                              No
                            </base-radio>
                          </div>


                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.internal_only)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.internal_only.actual" size="sm" type="primary">
                      Internal only <span v-if="filters.internal_only.actual">: {{filters.internal_only.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                </base-input>
              </div>
            </div>
          </div>

          <div class="d-none d-sm-block">
            <div class="row" :key="j">
              <div
                class="col"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Item name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Item name"
                            v-model="filters.item_name.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.item_name)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" :simple="!filters.item_name.actual" size="sm" type="primary">
                      Item <span v-if="filters.item_name.actual">: {{filters.item_name.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Category</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.category.value"
                              class="select-primary"
                              name="game"
                              placeholder="Category"
                              value-key="id"
                            >
                              <el-option
                                v-for="(f, i) in available_categories"
                                class="select-primary"
                                :label="f.name"
                                :value="{id: f.id, name: f.name}"
                                :key="'FS' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.category)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.category.actual" size="sm" type="primary">
                      Category <span v-if="filters.category.actual">: {{filters.category.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Unit price</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Unit price"
                            v-model="filters.unit_price.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.unit_price)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.unit_price.actual" size="sm" type="primary">
                      Unit price <span v-if="filters.unit_price.actual">: {{filters.unit_price.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Available</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Available"
                            v-model="filters.available.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.available)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.available.actual" size="sm" type="primary">
                      Available <span v-if="filters.available.actual">: {{filters.available.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Purchased</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Purchased"
                            v-model="filters.purchased.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.purchased)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.purchased.actual" size="sm" type="primary">
                      Purchased <span v-if="filters.purchased.actual">: {{filters.purchased.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Internal only</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div class="col-12">
                            <base-radio v-model="filters.internal_only.value" name="1" class="mb-3">
                              Yes
                            </base-radio>
                          </div>
                          <div class="col-12">
                            <base-radio v-model="filters.internal_only.value" name="0" class="mb-3">
                              No
                            </base-radio>
                          </div>


                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.internal_only)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getItems(perPage, getOffset(currentPage, perPage))">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.internal_only.actual" size="sm" type="primary">
                      Internal only <span v-if="filters.internal_only.actual">: {{filters.internal_only.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>


                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="mr-3">
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="getItems(perPage, getOffset(currentPage, perPage))"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div v-if="items.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
              <h4 class="mt-3">No items have been created yet.</h4>
            </div>
          </div>

          <!--        Desktop-->
          <div class="d-none d-sm-block">
            <div class="row" >
              <div class="col">
                <b-table v-if="items.length > 0" striped hover :items="items"
                         :fields="['item_name', 'category', 'unit_price', 'available', 'purchased', 'internal_only', 'actions']">
                  <template v-slot:cell(unit_price)="row">
                    <div>{{getAmount(row.item.price)}}</div>
                  </template>
                  <template v-slot:cell(item_name)="row">
                    <div >
                      <img class="circular" :src="row.item.img.url"/>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div>
                          {{row.item.name}}
                        </div>
                        <div>
                          <small>{{row.item.description}}</small>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(category)="row">
                    <badge>{{row.item.category_name}}</badge>
                  </template>
                  <template v-slot:cell(internal_only)="row">
                    <i v-if="row.item.internal_only" class="text-success tim-icons icon-check-2"></i>
                    <i v-if="!row.item.internal_only" class="text-danger tim-icons icon-simple-remove"></i>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a v-if="hasPermissions('inventory_update')" class="dropdown-item mt-1 mb-2" v-on:click="editItemPrompt(row.item)">
                            <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit item
                          </a>
                          <hr v-if="hasPermissions('inventory_update') && hasPermissions('inventory_delete')">
                          <a v-if="hasPermissions('inventory_delete')" class="dropdown-item mt-1 mb-2"  v-on:click="deleteItemPrompt(row.item)">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete item
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

<!--          Mobile-->
          <div class="d-block d-sm-none">
            <div class="row" >
              <div class="col">
                <b-table v-if="items.length > 0" striped hover :items="items"
                         :fields="['items']">
                  <template v-slot:cell(items)="row">
                    <div class="row">
                      <div class="col">
                        <div>
                          <h4 class="card-title">{{row.item.name}} </h4>
                        </div>
                      </div>
                      <div class="col text-right">
                        <badge type="danger" v-if="row.item.internal_only">internal only</badge>
                        <badge>{{row.item.category_name}}</badge>
                      </div>
                    </div>
                    <div>
                      <small>{{row.item.description}}</small>
                    </div>
                    <p>Unit price: {{getAmount(row.item.price)}}</p>
                    <p>Available: {{row.item.available}}</p>
                    <p>Purchased: {{row.item.purchased}}</p>

                    <div
                      class="btn-group btn-group-toggle mt-3 mb-2"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%"
                    >
                      <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('inventory_update')">
                        <base-button
                          v-on:click="editItemPrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </label>
                      <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('inventory_delete')">
                        <base-button
                          v-on:click="deleteItemPrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            v-on:input="getItems(perPage, getOffset(currentPage, perPage))"
          ></b-pagination>
        </div>

        <div v-if="activeTabIndex === 1">
          <div class="d-block d-sm-none">
            <div class="row">
              <div class="col" v-if="hasPermissions('inventory_create')">
                <base-button v-if="activeTabIndex === 1" size="sm" v-on:click="addNewItemCategory()" type="primary" >
                  <i class="tim-icons icon-simple-add mr-2"></i> Add new item category</base-button>
              </div>
            </div>
          </div>

          <div v-if="item_categories.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
              <h4 class="mt-3">No item categories have been created yet.</h4>
            </div>
          </div>

          <!--        Desktop-->
          <div class="d-none d-sm-block">
            <div class="row" >
              <div class="col">
                <b-table v-if="item_categories.length > 0" striped hover :items="item_categories"
                         :fields="['category_name', 'actions']">
                  <template v-slot:cell(category_name)="row">
                    <div>{{row.item.name}}</div>
                    <small>{{row.item.description}}</small>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <a v-if="hasPermissions('inventory_update')" class="dropdown-item mt-1 mb-2" v-on:click="editItemCategoryPrompt(row.item)">
                              <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit item category
                            </a>
                            <hr v-if="hasPermissions('inventory_update') && hasPermissions('inventory_delete')">
                            <a v-if="hasPermissions('inventory_delete')" class="dropdown-item mt-1 mb-2"  v-on:click="deleteItemCategoryPrompt(row.item)">
                              <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete item category
                            </a>
                          </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
<!--          Mobile-->
          <div class="d-block d-sm-none">
            <div class="row" >
              <div class="col">
                <b-table v-if="item_categories.length > 0" striped hover :items="item_categories"
                         :fields="['categories']">
                  <template v-slot:cell(categories)="row">
                    <h4 class="categories">{{row.item.name}}</h4>
                    <small>{{row.item.description}}</small>
                    <div
                      class="btn-group btn-group-toggle mt-3 mb-2"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%"
                    >
                      <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('inventory_update')">
                        <base-button
                          v-on:click="editItemCategoryPrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </label>
                      <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('inventory_delete')">
                        <base-button
                          v-on:click="deleteItemCategoryPrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <b-pagination
            v-model="currentPageCategories"
            :total-rows="totalRowsCategories"
            :per-page="perPageCategories"
            v-on:input="getItemCategories(perPage, getOffset(currentPageCategories, perPageCategories))"
          ></b-pagination>
        </div>
      </card>
    </div>
  </div>

<!--Add category-->
  <modal :show.sync="modals.new_category"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">
      <h4 v-if="category.hasOwnProperty('id')">Edit item category</h4>
      <h4 v-else>Create item category</h4>
      <div class="row" >
        <div class="col">
          <base-input :error="errs.category_name"
                      label="Category name" type="text" placeholder="Example - Merchandise" v-model="category.name"></base-input>
        </div>
      </div>
      <div class="row" >
        <div class="col">
          <base-input label="Description">
            <el-input
              v-model="category.description"
              :rows="5"
              type="textarea"
              placeholder="Category description"
            />
          </base-input>
        </div>
      </div>
      <div class="row text-center mt-3">
        <div class="col">
          <base-button  type="secondary" @click="modals.new_category = false">Cancel</base-button>
          <base-button v-if="category.hasOwnProperty('id')" class="ml-3" type="primary" @click="updateCategory()">Save</base-button>
          <base-button v-else class="ml-3" type="primary" @click="createCategory()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

  <!--Add item-->
  <modal :show.sync="modals.new_item"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" :key="k"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">
      <h4 v-if="item.hasOwnProperty('id')">Edit item</h4>
      <h4 v-else>Create item</h4>
      <div class="row" >
        <div class="col-lg-7">
          <div class="row" >
            <div class="col">
              <base-input :error="errs.item.name"
                          label="Item name" type="text" placeholder="Example - Water bottle" v-model="item.name"></base-input>
            </div>
          </div>

          <div class="row" >
            <div class="col">
              <base-input label="Description">
                <el-input
                  v-model="item.description"
                  :rows="5"
                  type="textarea"
                  placeholder="Item description"
                />
              </base-input>
            </div>
          </div>
        </div>
        <div class="col mb-2">
          <div class="mb-2" :style="{'background-color': item.img.background_color, 'border-radius': '5px'}">
            <img :src="item.img.url"
                 :style="{'transform': 'translate(0%, 0%) scale(' + item.img.scale*0.01 + ')',
               'left': item.img.x + 'px', 'top': item.img.y + 'px', 'object-fit':  item.img.object_fit, 'height': '80%'}"
                  >
          </div>

          <image-editor @closed="modals.new_item = true" @clickUpload="uploadImage" @saveImage="saveImage"></image-editor>
        </div>
      </div>

      <div class="row" >
        <div class="col">
          <base-input :label="'Unit price (' + currency_symbol + ')'" :error="errs.item.price" placeholder="0" v-model.number="item.price"></base-input>
        </div>
        <div class="col">
          <base-input placeholder="0" label="Available quantity" :error="errs.item.available" v-model.number="item.available"></base-input>
        </div>
      </div>
      <div class="row" >
        <div class="col">
          <base-input label="Category" :error="errs.item.item_category_id">
            <el-select
              label="Select category"
              v-model="item.item_category_id"
              class="select-primary"
              value-key="id"
              placeholder="Category">
              <el-option
                v-for="(ac, i) in available_categories"
                class="select-primary"
                :label="ac.name"
                :value="{name: ac.name, id: ac.id}"
                :key="'AC' + i"
              ></el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col">
          <base-checkbox label="Internal only" v-model="item.internal_only"> Internal only</base-checkbox>
        </div>
      </div>
      <div class="row text-center mt-3">
        <div class="col">
          <base-button  type="secondary" @click="modals.new_item = false">Cancel</base-button>
          <base-button v-if="item.hasOwnProperty('id')" class="ml-3" type="primary" @click="updateItem()">Save</base-button>
          <base-button v-else class="ml-3" type="primary" @click="createItem()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

</div>
</template>

<script>
import {BPagination, BTable} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import currencies from "@/assets/currencies.json";
import Badge from "@/components/Badge";
import ImageEditor from "@/components/ImageEditor";
import _ from "lodash";

export default {
  name: "InventoryManagement",
  components: {
    BTable,
    BPagination,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    Badge,
    ImageEditor
  },
  data() {
    return {
      loading: false,
      show_filters: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      items: [],
      item_categories: [],
      available_categories: [],
      perPageOptions: [5, 10, 25, 50],
      currentPage: Number(this.$route.query.page),
      perPage: Number(this.$route.query.count),
      totalRows: 10,
      totalRowsCategories: 10,
      currentPageCategories: 1,
      perPageCategories: 10,
      activeTabIndex: 0,
      j: 0,
      k: 0,
      modals: {
        new_category: false,
        new_item: false
      },
      errs: {
        category_name: '',
        item: {
          name: '',
          price: '',
          available: '',
          item_category_id: ''
        }
      },
      category: {
        name: '',
        description: ''
      },
      item: {
        name: '',
        description: '',
        price: '',
        item_category_id: '',
        available: '',
        internal_only: false,
        image_id: null,
        img: {
          url: 'https://console.offthecouch.io/img/placeholder.jpg',
          scale: 100,
          background_color: '#ffffff',
          x: 300,
          y: 100,
          object_fit: 'contain'
        }
      },
      filters: {
        item_name: {
          value: '',
          actual: ''
        },
        category: {
          value: '',
          actual: ''
        },
        unit_price: {
          value: '',
          actual: ''
        },
        available: {
          value: '',
          actual: ''
        },
        purchased: {
          value: '',
          actual: ''
        },
        internal_only: {
          value: '',
          actual: ''
        },

      }
    }
  },
  computed: {
    categories() {
      return [
        { name: 'Items', icon: 'tim-icons icon-shape-star', active: true },
        { name: 'Item Categories', icon: 'tim-icons icon-molecule-40', active: false }
      ];
    }
  },
  methods: {
    resetAll(){
      this.filters = {
        item_name: {
          value: '',
          actual: ''
        },
        category: {
          value: '',
          actual: ''
        },
        unit_price: {
          value: '',
          actual: ''
        },
        available: {
          value: '',
          actual: ''
        },
        purchased: {
          value: '',
          actual: ''
        },
        internal_only: {
          value: '',
          actual: ''
        },

      }
      this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage))
    },
    resetField(field1){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage))
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    editItemPrompt(item){
      this.item = JSON.parse(JSON.stringify(item))
      this.item.item_category_id = _.find(this.available_categories, {id: item.item_category_id})
      this.modals.new_item = true
    },
    uploadImage(event){
      this.modals.new_item = false
    },
    async saveImage(value){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images'

      let data = {
        url: value.url,
        scale: value.scale,
        description: value.description,
        background_color: value.background_color,
        x: value.x,
        y: value.y,
        is_primary: 0,
        object_fit: value.object_fit
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.item.img = value
          this.item.image_id = response.data
          this.modals.new_item = true
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addItem(){
      this.errs.item = {
        name: '',
        unit_price: '',
        available: ''
      }
      this.item = {
        name: '',
          description: '',
          price: '',
          item_category_id: '',
          available: '',
          internal_only: false,
          image_id: null,
          img: {
          url: 'https://console.offthecouch.io/img/placeholder.jpg',
            scale: 100,
            background_color: '#ffffff',
            x: 300,
            y: 100,
            object_fit: 'contain'
        }
      }
      this.modals.new_item = true
    },
    deleteItemPrompt(item){
      swal({
        title: 'Are you sure?',
        text: `The item will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.deleteItem(item);
        }
      })
    },
    deleteItemCategoryPrompt(item){
      swal({
        title: 'Are you sure?',
        text: `The item category will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.deleteItemCategory(item);
        }
      })
    },
    async deleteItem(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory/' + item.id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Item has been deleted', 'success')
          this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteItemCategory(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory/categories/' + item.id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Item category has been deleted', 'success')
          this.getItemCategories(this.perPageCategories, this.getOffset(this.currentPageCategories, this.perPageCategories))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addNewItemCategory(){
      this.category = {
        name: '',
        description: ''
      }
      this.errs.category_name = ''
      this.modals.new_category = true
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true

      if(this.activeTabIndex === 1){
        this.getItemCategories(this.perPageCategories, this.getOffset(this.currentPageCategories, this.perPageCategories))
      }
      this.j++
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    editItemCategoryPrompt(item){
      this.category = JSON.parse(JSON.stringify(item))
      this.errs.category_name = ''
      this.modals.new_category = true
    },
    validateCategory(){
      let errors = 0

      if(this.category.name === ''){
        this.errs.category_name = "Category name cannot be empty"
        errors++
      } else {
        this.errs.category_name = ''
      }
      return errors > 0 ? false : true
    },
    validateItem(){
      let errors = 0

      if(this.item.name === ''){
        this.errs.item.name = "Item name cannot be empty"
        errors++
      } else {
        this.errs.item.name = ''
      }

      if(this.item.price === '' || this.item.price < 0 || isNaN(this.item.price)){
        this.errs.item.price = "Invalid price"
        errors++
      } else {
        this.errs.item.price = ''
      }

      if(this.item.item_category_id === '' || this.item.item_category_id === null){
        this.errs.item.item_category_id = "Invalid category"
        errors++
      } else {
        this.errs.item.item_category_id = ''
      }

      if(this.item.available === '' || this.item.available < 0 || isNaN(this.item.available)){
        this.errs.item.available = "Invalid quantity"
        errors++
      } else {
        this.errs.item.available = ''
      }
      this.k++
      return errors > 0 ? false : true
    },
    async updateItem(item){
      if(!this.validateItem()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory/' + this.item.id
      let data = {
        name: this.item.name,
        description: this.item.description,
        image_id: this.item.image_id,
        available: this.item.available,
        price: this.item.price,
        item_category_id: this.item.item_category_id.id,
        internal_only: this.item.internal_only
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.new_item = false
          swal('Success!', 'Item has been updated', 'success')
          this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createItem(){
      if(!this.validateItem()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory'
      let data = {
        name: this.item.name,
        description: this.item.description,
        image_id: this.item.image_id,
        available: this.item.available,
        price: this.item.price,
        item_category_id: this.item.item_category_id.id,
        internal_only: this.item.internal_only
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.new_item = false
          swal('Success!', 'Item has been created', 'success')
          this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createCategory(){
      if(!this.validateCategory()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory/categories'
      let data = this.category

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.new_category = false
          swal('Success!', 'Item category has been created', 'success')
          this.getItemCategories(this.perPageCategories, this.getOffset(this.currentPageCategories, this.perPageCategories))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updateCategory(){
      if(!this.validateCategory()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/inventory/categories/' + this.category.id
      let data = this.category

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.new_category = false
          swal('Success!', 'Item category has been updated', 'success')
          this.getItemCategories(this.perPageCategories, this.getOffset(this.currentPageCategories, this.perPageCategories))
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getItems(count, offset, init){
      this.filters.item_name.actual = this.filters.item_name.value
      this.filters.category.actual = this.filters.category.value.name
      let category = this.filters.category.value.id
      if(init && this.$route.query.category !== ''){
        category = Number(this.$route.query.category)
      }
      if(category === undefined || isNaN(category)){
        category = ''
      }

      this.filters.unit_price.actual = this.filters.unit_price.value > 0 ? this.getAmount(this.filters.unit_price.value) : ''
      this.filters.available.actual = this.filters.available.value
      this.filters.purchased.actual = this.filters.purchased.value
      if(this.filters.internal_only.value === ''){
        this.filters.internal_only.actual = ''
      } else {
        this.filters.internal_only.actual = this.filters.internal_only.value === '1' ? 'Yes' : 'No'
      }



      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId +
        '/inventory?count=' + count + '&offset=' + offset +
        `&item_name=` + encodeURIComponent(this.filters.item_name.value) +
          `&category=` + encodeURIComponent(category) +
        `&unit_price=` + encodeURIComponent(this.filters.unit_price.value) +
        `&available=` + encodeURIComponent(this.filters.available.value) +
        `&purchased=` + encodeURIComponent(this.filters.purchased.value) +
        `&internal_only=` + encodeURIComponent(this.filters.internal_only.value)

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.items = response.data.items
          this.available_categories = response.data.item_categories
          this.totalRows = response.data.totalRows

          for(let n in this.items){
            if(this.items[n].img){
              this.items[n].img = JSON.parse(this.items[n].img)[0]
            } else {
              this.items[n].img = {
                url: 'https://console.offthecouch.io/img/placeholder.jpg',
                scale: 100,
                background_color: '#ffffff',
                x: 300,
                y: 100,
                object_fit: 'contain'
              }
            }

            this.items[n].image_id = this.items[n].img.id
          }

          if(init && this.$route.query.category){
            this.$nextTick(() => {
              let categoryObj = _.find(this.available_categories, {id: category})
              console.log(categoryObj.name)
              this.filters.category.value = categoryObj
              this.filters.category.actual = categoryObj ?  categoryObj.name : ''
              this.j++
            });
          }

          this.addQueryParameters()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getItemCategories(count, offset){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId +
        '/inventory/categories?count=' + count + '&offset=' + offset

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.item_categories = response.data.item_categories
          this.totalRowsCategories = response.data.totalRows
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      console.log(this.filters )
      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        item_name: this.filters.item_name.value,
        unit_price: this.filters.unit_price.value,
        available: this.filters.available.value,
        purchased: this.filters.purchased.value,
        internal_only: this.filters.internal_only.value,
        category: this.filters.category.value.hasOwnProperty('id') ? this.filters.category.value.id : this.filters.category.value,

    }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getOffset(currentPage, perPage){
      return Number((currentPage * perPage) - perPage)
    },
  },
  mounted() {
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)
    this.filters = {
      item_name: {
        value: this.$route.query.item_name ? this.$route.query.item_name : '',
        actual: this.$route.query.item_name ? this.$route.query.item_name : ''
      },
      category: {
        value: this.$route.query.category ? this.$route.query.category : '',
        actual: this.$route.query.category ? this.$route.query.category : ''
      },
      unit_price: {
        value: this.$route.query.unit_price ? this.$route.query.unit_price : '',
        actual: this.$route.query.unit_price ? this.$route.query.unit_price : ''
      },
      available: {
        value: this.$route.query.available ? this.$route.query.available : '',
        actual: this.$route.query.available ? this.$route.query.available : ''
      },
      purchased: {
        value: this.$route.query.purchased ? this.$route.query.purchased : '',
        actual: this.$route.query.purchased ? this.$route.query.purchased : ''
      },
      internal_only: {
        value: this.$route.query.internal_only ? this.$route.query.internal_only : '',
        actual: this.$route.query.internal_only ? this.$route.query.internal_only : ''
      }
    }
    this.getItems(this.perPage, this.getOffset(this.currentPage, this.perPage), 1)
  }
}
</script>

<style scoped>
.circular {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>
