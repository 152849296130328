<template>
  <div>
    <h5 class="info-text">
      Anything to note before submitting the form?
    </h5>
    <div class="row justify-content-center mt-5">

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>End time</label>
          </div>
          <div class="col">
            <base-input :error="modelValidations.end_time">
              <el-time-picker v-on:input="validateTime()"
                v-model="model.end_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
          <div class="col">
            <base-button
              @click.native="setNow()"
              class="now-field"
              type="info"
            >Now
            </base-button>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>Post-game notes</label>
            <el-popover trigger="hover"
                        placement="bottom">
              <div>
                <div class="popover-body">Anything noteworthy that happened<br>
                  during the game but wasn't captured<br> in any of the questions above</div>
              </div>
              <base-button class="like btn-link"
                           type="info"
                           size="sm"
                           icon
                           slot="reference">
                <i class="tim-icons icon-alert-circle-exc"></i>
              </base-button>
            </el-popover>

          </div>
          <div class="col-sm-8">
            <base-input>
              <textarea
                class="mt-1 form-control"
                placeholder="Start typing..."
                name="notes"
                v-model="model.post_notes"
                rows="2"></textarea>
            </base-input>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-4">
            <label>Did players complete the room?
              <el-popover trigger="hover"
                          placement="bottom">
                <div>
                  <div class="popover-body">Select 'Yes' as long as<br> they solved all of the puzzles<br>, even if they went over time</div>
                </div>
                <base-button class="like btn-link"
                             type="info"
                             size="sm"
                             icon
                             slot="reference">
                  <i class="tim-icons icon-alert-circle-exc"></i>
                </base-button>
              </el-popover>
            </label>

          </div>
          <div class="col-sm-8">
            <base-radio name="true" class="mb-3" v-model="model.completed">
              Yes
            </base-radio>

            <base-radio name="false" class="mb-3" v-model="model.completed">
              No
            </base-radio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { BaseRadio } from 'src/components/index';
  import {API_LOCATION} from "@/api-config";
  import axios from "axios";
  import {Auth} from "aws-amplify";
  import {TimePicker} from "element-ui";
  import swal from "sweetalert2";

  export default {
      name: "PostGameNotesStep",
      components: {
        BaseRadio,
        [TimePicker.name]: TimePicker,
      },
      data() {
        return {
          picker_time_format: 'hh:mm A',
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          model: {
            end_time: '',
            post_notes: '',
            completed: true
          },
          dd: 'AM',
          hh: '',
          mm: '',
          modelValidations: {
            end_time: '',
            pre_notes: {
              required: false,
            },
            team_experience: {
              required: false,
            },
          }
        }
      },
      methods: {
        validateTime(){
          let today = new Date()
          this.model.end_time.setMonth(today.getMonth(), today.getDate())
        },
        // convertTime(){
        //   if(this.hh.length < 2 && this.hh != ''){
        //     this.hh = '0' + this.hh
        //   }
        //   if(this.mm.length < 2 && this.mm != ''){
        //     this.mm = '0' + this.mm
        //   }
        //   let hour = ''
        //   if(this.dd == 'PM'){
        //     hour = 12 + parseInt(this.hh)
        //     if(hour == 24){
        //       hour = '12'
        //     }
        //     this.model.end_time = hour + ':' + this.mm
        //   } else {
        //     hour = parseInt(this.hh)
        //     if(hour == 12){
        //       hour = '00'
        //     }
        //     if(hour < 10){
        //       hour = '0' + hour
        //     }
        //     this.model.end_time = hour + ':' + this.mm
        //   }
        // },
        populateDraft(){
          let draft = JSON.parse(localStorage.getItem('draft'))
          if(draft){
            if(draft.actual_end_time && draft.actual_end_time[0] != 0){
              let time = new Date(draft.actual_end_time)
              time.setHours(draft.actual_end_time.split('T')[1].split(':')[0])
              time.setMinutes(draft.actual_end_time.split('T')[1].split(':')[1])
              this.model.end_time = time
            } else {
              this.model.end_time = null
            }

            this.model.completed = Boolean(draft.completed)
            this.model.post_notes = draft.post_game_notes
          }
        },
        setNow () {
          this.model.end_time = new Date()
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        },
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        async setPostNotes () {
          if(!this.model.end_time || this.model.end_time === "NaN:"){
            this.modelValidations.end_time = 'End time cannot be empty'
            return
          } else {
            this.modelValidations.end_time = ''
          }

          localStorage.setItem('postNotes', JSON.stringify(this.model))
          //save draft
          let companyId = localStorage.getItem('companyId')
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'companies/' + companyId
            + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'

          // this.convertTime()
          let data = {
            end_time: this.model.end_time.getFullYear() + '-' + (this.model.end_time.getMonth()+1) + '-' + this.model.end_time.getDate() +
              ' ' + this.model.end_time.getHours() + ':' + this.model.end_time.getMinutes(),
            post_game_notes: this.model.post_notes,
            completed: this.model.completed
          }
          console.log(data)
          axios.put(url, data, config)
            .then(response => {
              //update LS draft
              let draft = JSON.parse(localStorage.getItem('draft'))
              draft.actual_end_time = this.model.end_time.getFullYear() + '-' +
                ('0' + (this.model.end_time.getMonth()+1)).slice(-2) + '-' + ('0' + this.model.end_time.getDate()).slice(-2) +
                'T' + ('0' + (this.model.end_time.getHours())).slice(-2) + ':' + ('0' + (this.model.end_time.getMinutes())).slice(-2) + ':00.000Z'
              draft.post_game_notes = data.post_game_notes
              draft.completed = data.completed
              localStorage.setItem('draft', JSON.stringify(draft))
            })
            .catch(err => {
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async validate() {
          if(!this.model.end_time || this.model.end_time === "NaN:"){
            this.modelValidations.end_time = 'End time cannot be empty'
            return
          } else {
            this.modelValidations.end_time = ''
          }
          return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
          });
        }
      },
      mounted() {
        if (this.time_format === 'HH:mm') {
          this.picker_time_format = 'HH:mm'
        }
      }
    }
</script>

<style scoped>
  .now-field{
    height: 2.3rem;
    margin-top: -0.05rem;
  }
</style>
