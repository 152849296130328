<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--  Feature not enabled-->
  <div v-if="feature_not_enabled" class="mt-3" >
    <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
      <h4 class="mt-3">This feature is not enabled.</h4>
      <base-button type="primary" v-if="hasPermissions('billing_view')"
                   v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
      <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
    </div>
  </div>

  <div v-if="!feature_not_enabled">
    <!--  Take a photo button-->
    <div class="row">
      <div class="col d-sm-block d-none">
      <span class="btn btn-primary btn-file mt-3">Take photo
              <input v-if="isAndroid14" type="file"
                     @change="takePhoto()"/>
                <input v-else type="file"
                       accept="image/*"
                       @change="takePhoto()"

                />
              </span>
      </div>

      <div class="col d-sm-none d-block">
      <span class="btn btn-primary btn-icon btn-file mt-3"><i style="font-size: larger" class="tim-icons icon-camera-18"></i>
              <input v-if="isAndroid14" type="file"
                     @change="takePhoto()"/>
                <input v-else type="file"
                       accept="image/*"
                       @change="takePhoto()"

                />
              </span>
      </div>

      <div class="col-lg-2 col-6">
        <base-input class="mt-3">
          <el-date-picker
            style="border: 0; border-width:0px;"
            type="date"
            placeholder="Select date"
            value-format="yyyy-MM-dd"
            :format="day_of_week + '' + date_format_cal"
            v-model="current"
            @change="changeDate()"
          >
          </el-date-picker>
        </base-input>
      </div>
    </div>
    <p class="mt-3 mb-3" v-if="!email_active">
      <i class="fas fa-exclamation-triangle"></i>
      Warning: You don't have the automated photo email type activated. Please go to settings to activate the photo email.
    </p>

    <!--  Photos-->
    <div class="row">
      <div style="word-break: break-all; display: flex" class="col-lg-4 col-md-6 col-12" v-for="(p, i) in photos" :key="'A'+ i" >
        <card >
          <div class="row justify-content-center" >
            <div class="col text-center">
              <div style="word-break: break-all;" >
                <div style="position: relative; display: inline-block;" :id="'editorContainer' + i">
                  <img v-if="p.overlay_url" :id="'img' + i" :src="p.overlay_url" @load="updateCanvasSize(i, p)" crossorigin="anonymous"
                       style="max-width: 100%; border-radius: 0px" >
                  <img v-else :id="'img' + i" :src="p.url" @load="updateCanvasSize(i, p)" crossorigin="anonymous"
                       style="max-width: 100%; border-radius: 0px" >
                  <canvas :id="'cv' + i" style="position: absolute; top: 0; left: 0;"></canvas>
              </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <h4 class="card-title">
                {{getDayOfWeek(p.timestamp)}} {{getDate(p.timestamp)}}, {{getTime(p.timestamp)}}
                <el-tooltip content="Delete photo" style="margin-top: 0"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    @click="deletePhoto(p.id)"
                    class="like btn-link"
                    type="danger"
                    size="sm"
                    icon
                  ><i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Download photo" style="margin-top: 0"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    @click="saveImage('editorContainer' + i)"
                    class="like btn-link"
                    size="sm"
                    icon
                  ><i class="tim-icons text-white icon-cloud-download-93"></i>
                  </base-button>
                </el-tooltip>
              </h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-input label="Assign photo" >
                <el-select
                  v-model="p.selectedAssignmentOption"
                  class="select-primary"
                  name="assignment"
                  v-on:change="assignmentScreen(p)"
                  placeholder="Assign photo to..."
                  value-key="id"
                >
                  <el-option
                    v-for="(pao, i) in photo_assignment_options"
                    class="select-primary"
                    :label="pao.label"
                    :value="{id: pao.id, label: pao.label}"
                    :key="'PAO' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row" v-if="p.assignees && p.assignees.length > 0">
            <div class="col">
              <b-table :items="p.assignees" :fields="['assigned_to', 'email_status', 'actions']">
                <template v-slot:cell(assigned_to)="row">
                  <div v-if="row.item.hasOwnProperty('booking_slot_id')">
                    <p class="text-white">{{row.item.game_name}}</p>
                    <small class="text-white">
                      {{getDayOfWeek(row.item.booking_date)}} {{getDate(row.item.booking_date)}}, {{getTime(row.item.start_time)}}
                    </small>
                  </div>
                  <div v-if="row.item.hasOwnProperty('user_id')">
                    <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}</p>
                    <small class="text-white">
                      {{row.item.email}}
                    </small>
                  </div>
                </template>
                <template v-slot:cell(email_status)="row">
                  <div v-if="row.item.emailed_to_group">
                    <badge v-if="email_active == 1 && row.item.emailed_to_group" type="success">email sent x{{row.item.emailed_to_group}}</badge>
                    <badge v-if="email_active == 1 && !row.item.emailed_to_group" type="default">email not sent</badge>
                    <p v-if="email_active == 0">-</p>
                  </div>
                  <div v-else>
                    <badge v-if="email_active == 1 && row.item.is_emailed" type="success">email sent x{{row.item.is_emailed}}</badge>
                    <badge v-if="email_active == 1 && !row.item.is_emailed" type="default">email not sent</badge>
                    <p v-if="email_active == 0">-</p>
                  </div>
                </template>
                <template v-slot:cell(booking)="row">
                  <div>
                    <small class="text-white">
                      {{getDayOfWeek(row.item.booking_date)}} {{getDate(row.item.booking_date)}}, {{getTime(row.item.start_time)}}
                    </small>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      link
                      type="primary"
                      size="sm"
                    > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mb-2" @click="emailToGroupPrompt(row.item)" href="#">
                          <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> {{emailButtonLabel(row.item)}}
                          <div v-if="!email_active">
                            <small class="font-italic ml-4">Please activate the photo email type in email settings</small>
                          </div>
                        </a>
                        <a v-if="row.item.hasOwnProperty('booking_slot_id')" class="dropdown-item mb-2" @click="editTeam(row.item)" href="#">
                          <i class="tim-icons text-info icon-satisfied mr-2"></i> Manage team name
                        </a>
                        <hr>
                        <a v-if="row.item.hasOwnProperty('booking_slot_id')"
                           class="dropdown-item" @click="removeAssignment(row.item.photo_id, row.item.id, 1)" href="#">
                          <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Remove assignment
                        </a>
                        <a v-if="row.item.hasOwnProperty('user_id')" class="dropdown-item"
                           @click="removeAssignment(row.item.photo_id, row.item.id, 0)" href="#">
                          <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Remove assignment
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>

          <hr style="background-color: #7e7e7e;" v-if="overlays.length > 0">
          <div class="row" v-if="overlays.length > 0">
            <div class="col">
              <p class="card-title">Overlays</p>
            </div>
          </div>
          <div class="row" v-if="overlays.length > 0">
            <div class="col">
              <base-input label="Select overlay">
                <el-select
                  v-model="p.selectedOverlay"
                  class="select-primary"
                  name="game"
                  placeholder="Apply overlay"
                  v-on:change="saveOverlay(p, i)"
                  value-key="id"
                >
                  <el-option
                    v-for="o in overlays"
                    class="select-primary"
                    :label="o.name + ' [' + o.aspect_ratio +']'"
                    :value="{id: o.id, name: o.name, layers: o.layers,
                    aspect_ratio: o.aspect_ratio, reference_height: o.reference_height,
                    reference_width: o.reference_width}"
                    :key="o.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row"  v-if="overlays.length > 0 && p.selectedOverlay" >
            <div class="col">
              <base-button simple size="sm" v-on:click="clearOverlay(p, i)" type="primary" class="ml-1">
                Clear overlay</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>




    <!--   No data panel -->
    <div v-if="photos.length <= 0" class="mt-3" >
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">No photos have yet been taken.</h4>
      </div>
    </div>


    <!--    Select game instance Modal-->
    <modal :show.sync="modals.select_game_instance"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Select a game</h4>

        <div class="row">
          <div class="col">
            <base-input class="mt-3" style="border: 0; border-width:0px;">
              <el-date-picker
                style="border: 0; border-width:0px;"
                type="date"
                placeholder="Select date"
                value-format="yyyy-MM-dd"
                :format="day_of_week + '' + date_format_cal"
                v-model="current_booking"
                @change="getGameInstances(7)"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <!--   No data panel -->
        <div v-if="game_instances.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">There are no completed games on this day.</h4>
          </div>
        </div>

        <div class="row" v-if="game_instances.length > 0">
          <div class="col">
            <b-table :items="game_instances" :fields="['select', 'game', 'game_master', 'booking_time']">
              <template #head(select)="data">
                <span></span>
              </template>
              <template v-slot:cell(select)="row">
                <base-checkbox style="display: inline-block" :name="row.item.booking_slot_id" v-model="row.item.selected">
                </base-checkbox>
              </template>
              <template v-slot:cell(game)="row">
                <span>{{row.item.name}}</span>
              </template>
              <template v-slot:cell(game_master)="row">
                <span>{{row.item.first_name}} {{row.item.last_name}}</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <span>{{getTime(row.item.booking_time)}}</span>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.select_game_instance = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="assignPhoto()">Save</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    Select game instance Modal-->
    <modal :show.sync="modals.email_group"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Email to group</h4>
        <div class="row" v-if="!add_another_player">
          <div class="col">
            <base-button size="sm" v-on:click="add_another_player = true" type="primary">
              <i class="tim-icons el-icon-plus"></i> Add another player</base-button>
          </div>
        </div>

        <div class="row" v-if="add_another_player">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Customer lookup (search by name, email, or phone)">
              <el-autocomplete style="width: 100%"
                               v-model="lookup_value"
                               :fetch-suggestions="querySearch"
                               placeholder="Search"
                               :trigger-on-focus="false"
                               @select="handleSelect"
              >
                <template #default="{ item }">
                  <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                  <small class="font-italic">
                    <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                  </small>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
          <div class="col">
            <label class="mt-5"></label>
            <base-button type="default" v-on:click="cancelAddAnotherPlayer()">Cancel</base-button>
            <base-button type="primary" class="ml-2" v-on:click="addCustomerWaiver()">Add to booking</base-button>
          </div>
        </div>

        <!--   No data panel -->
        <div v-if="end_users.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No waiver have been signed for this booking.</h4>
          </div>
        </div>

        <div class="row" v-if="end_users.length > 0">
          <div class="col">
            <b-table :items="end_users" :fields="['select', 'customer_name', 'customer_email']">
              <template #head(select)="data">
                <span></span>
              </template>
              <template v-slot:cell(select)="row">
                <base-checkbox style="display: inline-block" :name="row.item.waiver_id" v-model="row.item.selected">
                </base-checkbox>
              </template>
              <template v-slot:cell(customer_name)="row">
                <span>{{row.item.first_name}} {{row.item.last_name}}</span>
              </template>
              <template v-slot:cell(customer_email)="row">
                <span>{{row.item.email}}</span>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.email_group = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="emailToGroup()">Send email</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    Select booking Modal-->
    <modal  :show.sync="modals.select_booking_slot"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-lg">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Select booking</h4>

        <div class="row">
          <div class="col">
            <base-input class="mt-3" style="border: 0; border-width:0px;">
              <el-date-picker
                style="border: 0; border-width:0px;"
                type="date"
                placeholder="Select date"
                value-format="yyyy-MM-dd"
                :format="day_of_week + '' + date_format_cal"
                v-model="current_booking"
                @change="getGameInstances(8)"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <!--   No data panel -->
        <div v-if="game_instances.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">There are no bookings on this day.</h4>
          </div>
        </div>
        <div class="row" v-if="game_instances.length > 0">
          <div class="col">
            <b-table :items="game_instances" :fields="['select', 'game', 'status', 'booking_time']">
              <template #head(select)="data">
                <span></span>
              </template>
              <template v-slot:cell(select)="row">
                <base-checkbox style="display: inline-block" :name="row.item.booking_slot_id" v-model="row.item.selected">
                </base-checkbox>
              </template>
              <template v-slot:cell(status)="row">
                <badge v-if="row.item.status === 'available' || row.item.status === 'completed'" type="success">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'booked'" type="info">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'expired'" type="default">passed</badge>
                <badge v-if="row.item.status === 'cancelled' || row.item.status === 'blocked'"
                       type="danger">{{row.item.status}}</badge>
                <badge v-if="row.item.status === 'call_to_book'"
                       type="warning">call to book</badge>
                <badge v-if="row.item.status === 'checked'"
                       type="warning">checked in, no booking</badge>
              </template>
              <template v-slot:cell(game)="row">
                <span style="display: inline-block">{{row.item.name}}</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <span>{{getTime(row.item.booking_time)}}</span>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.select_booking_slot = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="assignPhoto()">Save</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    Select Players Modal-->
    <modal :show.sync="modals.select_players"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Select customers</h4>

        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + k"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div class="row" v-if="activeTabIndex === 0">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Customer lookup (search by name, email, or phone)">
              <el-autocomplete style="width: 100%"
                               v-model="lookup_value"
                               :fetch-suggestions="querySearch"
                               placeholder="Search"
                               :trigger-on-focus="false"
                               @select="handleSelect"
              >
                <template #default="{ item }">
                  <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                  <small class="font-italic">
                    <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                  </small>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
          <div class="col">
            <label class="mt-5"></label>
            <base-button simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
            <base-button type="primary" class="ml-2" v-on:click="addCustomer()">Add</base-button>
          </div>
        </div>

        <div v-if="activeTabIndex === 1">
          <div class="row">
            <div class="col-6">
              <base-input :error="errs.first_name" label="First name" v-model="user.first_name" placeholder="First name"></base-input>
            </div>
            <div class="col-6">
              <base-input :error="errs.last_name" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <base-input :error="errs.email" label="Email" v-model="user.email" placeholder="Email"></base-input>
            </div>
            <div class="col-6">
              <base-input label="Phone (Optional)" v-model="user.phone" :placeholder="placeholder_phone"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-button type="primary" v-on:click="addCustomer()">Add customer</base-button>
            </div>
          </div>
        </div>

        <!--   No data panel -->
        <div v-if="end_users.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">Add customers to assign to photo.</h4>
          </div>
        </div>

        <div class="row" v-if="end_users.length > 0">
          <div class="col">
            <b-table :items="end_users" :fields="['customer_name', 'customer_email', 'actions']">
              <template #head(actions)="data">
                <span></span>
              </template>
              <template v-slot:cell(actions)="row">
                <base-button
                  @click.native="removeCustomer(row.index)"
                  class="like btn-link"
                  type="danger"
                  size="sm"
                  icon>
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </template>
              <template v-slot:cell(customer_name)="row">
                <span>{{row.item.first_name}} {{row.item.last_name}}</span>
              </template>
              <template v-slot:cell(customer_email)="row">
                <span>{{row.item.email}}</span>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.select_players = false">Cancel</base-button>
            <base-button v-show="end_users.length > 0" class="ml-3" type="primary" @click="assignPhoto()">Assign</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    View Players Modal-->
    <modal :show.sync="modals.view_players"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Assigned players</h4>

        <div class="row" v-for="(p, i) in photo_users" :key="'A' + i">
          <div class="col">
            <div class="row">
              <p>
              <span class="text-success" style="font-size: medium">
                                  {{p.first_name}}</span>
                <span class="text-success" style="font-size: medium">
                                  {{p.last_name}}</span>
                <span class="text-muted ml-1" style="font-size: small">
                                  {{p.email}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.view_players = false">Ok</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.view_players = false">Ok</base-button>
        </div>
      </card>
    </modal>

    <!--    Manage team-->
    <modal :show.sync="modals.edit_team"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card card-body-classes="table-full-width"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Manage team name</h4>
        <div class="row">
          <div class="col">
            <base-input label="Team name" v-model="selectedTeam.team_name"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-button type="default" v-on:click="modals.edit_team = false">Cancel</base-button>
            <base-button class="ml-2" type="primary" v-on:click="saveTeamName()">Save</base-button>
          </div>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.edit_team = false">Cancel</base-button>
        </div>
      </card>
    </modal>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import {formattedDate, time, dayOfWeek} from "@/plugins/dateFormatter";
import axios from "axios";
import swal from "sweetalert2";
import {DatePicker, Dropdown, Autocomplete, DropdownMenu, DropdownItem} from 'element-ui'
import html2canvas from 'html2canvas';
import Badge from "@/components/Badge";
import VueGridLayout from "vue-grid-layout";
import {BAspect, BTable } from 'bootstrap-vue'
import _ from "lodash";
import WebFont from 'webfontloader';

export default {
  name: "TakePhotos",
  components: {
    [DatePicker.name]: DatePicker,
    [Dropdown.name]: Dropdown,
    Badge,
    BAspect,
    BTable,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    [Autocomplete.name]: Autocomplete,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      feature_not_enabled: false,
      resizedImages: {},
      placeholder_phone: '(999) 999-9999',
      errs: {
        first_name: '',
        last_name: '',
        email: ''
      },
      add_another_player: false,
      lookup_value: '',
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        end_user_id: ''
      },
      default_aspect_ratio: '',
      aspect_ratios: [
        {
          label: '16:9'
        },
        {
          label: '9:16'
        },
        {
          label: '4:3'
        }
      ],
      selectedAssignmentOption: '',
      photo_assignment_options: [
        {
          id: 0,
          label: 'Assign to a game'
        },
        {
          id: 1,
          label: 'Assign to players'
        },
        {
          id: 2,
          label: 'Assign to a booking'
        }
      ],
      overlays: [],
      loading: false,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      selectedOverlay: '',
      day_of_week: '',
      date_format_cal: '',
      emptyBooking: false,
      emptyUsers: false,
      end_users: [],
      email_active: 1,
      photo_users: '',
      game_instances: '',
      uploadUrl: '',
      file: '',
      uploaded_image: '',
      photos: '',
      modals: {
        select_game_instance: false,
        select_players: false,
        select_booking_slot: false,
        view_players: false,
        email_group: false,
        edit_team: false
      },
      selectedPhoto: '',
      selectedBooking: '',
      selectedTeam: '',
      current: '',
      current_booking: '',
      j: [],
      a: 0,
      b: 0,
      k: 0,
      activeTabIndex: 0,
      userAgent: navigator.userAgent,
    }
  },
  computed: {
    categories() {
      return [{name: 'Add an existing customer', icon: 'tim-icons icon-user-run', active: true},
        {name: 'Add a new customer', icon: 'tim-icons el-icon-plus', active: false}
      ];
    },
    isAndroid14() {
      return navigator.userAgent.match(/Android/i) !== null;
    },
  },
  methods: {
    async saveTeamName(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/teams'

      let data = {
        team_name: this.selectedTeam.team_name,
        booking_slot_id: this.selectedTeam.booking_slot_id
      }
      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Team has been updated', 'success')
          this.modals.edit_team = false
          this.getPhotos()
          this.loading = false
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: err.response.data,
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })

    },
    editTeam(item){
      this.selectedTeam = item
      this.modals.edit_team = true
    },
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.k++
    },
    removeCustomer(index){
      this.end_users.splice(index, 1);
    },
    cancelAddAnotherPlayer(){
      this.add_another_player = false
      this.clearCustomer()
    },
    validateCustomer(){
      let errors = 0

      if(this.user.first_name === ''){
        this.errs.first_name = "First name cannot be empty"
        errors++
      } else {
        this.errs.first_name = ''
      }

      if(this.user.last_name === ''){
        this.errs.last_name = "Last name cannot be empty"
        errors++
      } else {
        this.errs.last_name = ''
      }

      if(this.user.email === ''){
        this.errs.email = "Email cannot be empty"
        errors++
      } else {
        this.errs.email = ''
      }
      return errors > 0 ? false : true
    },
    addCustomer(){
      if(!this.validateCustomer()){
        return
      }
      this.end_users.push(this.user)
      this.clearCustomer()
    },
    clearCustomer(){
      this.lookup_value = ''
      this.user = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        end_user_id: ''
      }
    },
    async querySearch(queryString, cb) {
      //make API call to /customers with queryString as filter
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.customers){
            if(response.data.customers[n].email != null
              && response.data.customers[n].first_name != null
              && response.data.customers[n].first_name != ''){
              suggestions.push({
                value: JSON.stringify(response.data.customers[n]),
                email: response.data.customers[n].email,
                id: response.data.customers[n].id,
                end_user_id: response.data.customers[n].end_user_id,
                first_name: response.data.customers[n].first_name,
                last_name: response.data.customers[n].last_name,
                phone: response.data.customers[n].phone
              })
            }

          }
          const results = suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createFilter(queryString) {
      return (suggestion) => {
        return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    handleSelect(item){
      this.lookup_value = item.email
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.email = item.email
      this.user.phone = item.phone
      this.user.end_user_id = item.end_user_id
    },
    emailButtonLabel(item){
      if(!item){
        return
      }
      let label = 'Email to group'
      if(item.hasOwnProperty('user_id')){
        label = 'Email to customer'
      }
      return this.email_active ? label : 'You must activate the photo email template'
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    async addCustomerWaiver(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/bookings/' + this.selectedPhoto.booking_slot_id + '/waivers'

      let data = {
        user: this.user
      }
      axios.post(url, data, config)
        .then(response => {
          this.end_users.push(this.user)
          this.loading = false
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })

    },
    async removeAssignment(photo_id, link_id, link_type){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos/' + photo_id + '/assign?link_id=' + link_id + '&link_type=' + link_type

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Photo has been unassigned', 'success')
          this.getPhotos()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    assignmentScreen(p){
      this.selectedPhoto = p
      this.end_users = []
      if(p.selectedAssignmentOption.id == 0){
        this.modals.select_players = false
        this.modals.select_game_instance = true
        this.modals.select_booking_slot = false
        this.getGameInstances(7)
      } else if (p.selectedAssignmentOption.id == 1){
        this.modals.select_game_instance = false
        this.modals.select_players = true
        this.modals.select_booking_slot = false
      } else if (p.selectedAssignmentOption.id == 2){
        this.modals.select_game_instance = false
        this.modals.select_players = false
        this.modals.select_booking_slot = true
        this.getGameInstances(8)
      }
    },
    base64ToFile(base64Data) {
      var byteCharacters = atob(base64Data.split(',')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: 'application/octet-stream' });
      var file = new File([blob], 'full_overlay.png', { type: 'application/octet-stream' });
      return file
    },
    async takeScreenshot(id) {
      // const element = this.$refs.targetDiv[index];
      const element = document.getElementById('editorContainer' + id)
      return new Promise((resolve, reject) => {
        html2canvas(element, {useCORS: true, backgroundColor: null, scale: 6}).then(function(canvas) {
          // const cropWidth = canvas.width - 15; // Crop 10 pixels from the right (adjust this value as needed)
          const cropWidth = canvas.width
          const cropHeight = canvas.height;

          // Create a new canvas with the cropped dimensions
          const croppedCanvas = document.createElement('canvas');
          croppedCanvas.width = cropWidth;
          croppedCanvas.height = cropHeight;

          // Draw the cropped portion of the original canvas onto the new canvas
          const croppedContext = croppedCanvas.getContext('2d');
          croppedContext.drawImage(canvas, 0, 0, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

          // Convert the cropped canvas to a data URL
          const imgData = croppedCanvas.toDataURL('image/png');

          // const imgData = canvas.toDataURL('image/png');
          resolve(imgData);
        }).catch(error => {
          reject(error);
        });
      });
    },
    async clearOverlay(item, index){
      const canvas = document.getElementById('cv' + index);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      item.selectedOverlay = ""
      item.overlay_id = null
      item.overlay_url = ""
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos/' + item.id + '/overlay'

      let data = {
        overlay_url:  "",
        overlay_id: null
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    async saveScreenShot(item, index){
      //save overlay - take screenshot
      let screenshot = await this.takeScreenshot(index)
      this.file = this.base64ToFile(screenshot)
      let file = await this.saveFile()

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos/' + item.id + '/overlay'
      item.overlay_url = this.uploadUrl.split("?")[0]
      item.overlay_id = item.selectedOverlay.id
      let data = {
        overlay_url:  this.uploadUrl.split("?")[0],
        overlay_id: item.selectedOverlay.id
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
        img.src = url;
      });
    },
    loadFont(font) {
      return new Promise((resolve, reject) => {
        WebFont.load({
          google: {
            families: [font]
          },
          active() { // Called when the font is successfully loaded
            resolve();
          },
          inactive() { // Called when the font fails to load
            reject(new Error(`Failed to load font: ${font}`));
          }
        });
      });
    },
    async saveOverlay(item, index, type){
      const canvas = document.getElementById('cv' + index);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let heightRatio = 1
      let widthRatio = 1
      if(item.selectedOverlay.reference_height && item.selectedOverlay.reference_width){
        heightRatio =  parseFloat(canvas.style.height) / item.selectedOverlay.reference_height
        widthRatio =  parseFloat(canvas.style.width) / item.selectedOverlay.reference_width
      }

      if(item.selectedOverlay.layers){
        let layers = JSON.parse(item.selectedOverlay.layers)
        layers = _.orderBy(layers, "position")

        for(let n in layers){

          if (layers[n].type === 'image') {
            let img = await this.loadImage(layers[n].content)
            ctx.drawImage(img, layers[n].x * widthRatio, layers[n].y * heightRatio, layers[n].width * widthRatio, layers[n].height * heightRatio);
          } else if (layers[n].type === 'text') {


            let link = await this.loadFont(layers[n].font)
            //Check Dynamic variables
            let content = layers[n].content
            if(layers[n].content.includes('[Team Name]')){
              if(item.assignees.length > 0 && item.assignees[0].team_name){
                content = item.assignees[0].team_name === null ?
                  layers[n].content.replaceAll('[Team Name]', '') : layers[n].content.replaceAll('[Team Name]', item.assignees[0].team_name)
              } else {
                content = ''
              }
            } else if (layers[n].content.includes('[Company Name]')){
              content = item.company_name === null ?
                layers[n].content.replaceAll('[Company Name]', '') : layers[n].content.replaceAll('[Company Name]', item.company_name)
            } else if (layers[n].content.includes('[Location Name]')){
              content = item.location_name === null ?
                layers[n].content.replaceAll('[Location Name]', '') : layers[n].content.replaceAll('[Location Name]', item.location_name)
            } else if (layers[n].content.includes('[Completion Time]')){
              if(item.assignees.length > 0 && item.assignees[0].game_start_time && item.assignees[0].game_end_time){
                let end_time = new Date(item.assignees[0].game_end_time)
                let start_time = new Date(item.assignees[0].game_start_time)
                let diffTime = (end_time - start_time)
                let total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
                content =  layers[n].content.replaceAll('[Completion Time]', total_minutes + ' minutes')
              } else {
                content = ''
              }
            }
            else if (layers[n].content.includes('[Time Remaining]')){
              if(item.assignees.length > 0 && item.assignees[0].game_start_time && item.assignees[0].game_end_time){
                let end_time = new Date(item.assignees[0].game_end_time)
                let start_time = new Date(item.assignees[0].game_start_time)
                let diffTime = (end_time - start_time)
                let total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
                let remaining = Number(item.assignees[0].game_duration) - total_minutes
                if(remaining < 0){
                  remaining = 0
                }
                content =  layers[n].content.replaceAll('[Time Remaining]', remaining + ' minutes')
              } else {
                content = ''
              }
            }
            else if (layers[n].content.includes('[Number Of Hints]')){
              if(item.assignees.length > 0){
                content = item.assignees[0].number_of_hints === null ?
                  layers[n].content.replaceAll('[Number Of Hints]', '') : layers[n].content.replaceAll('[Number Of Hints]', item.assignees[0].number_of_hints + ' hints')
              } else {
                content = ''
              }
            } else if (layers[n].content.includes('[Game Name]')){
              if(item.assignees.length > 0){
                content = item.assignees[0].game_name === null ||  item.assignees[0].game_name === undefined ?
                  layers[n].content.replaceAll('[Game Name]', '') : layers[n].content.replaceAll('[Game Name]', item.assignees[0].game_name)
              } else {
                content = ''
              }
            }
            ctx.font = `${layers[n].font_size * widthRatio}px ${layers[n].font}`;
            ctx.fillStyle = layers[n].color;
            ctx.fillText(content, layers[n].x * widthRatio, (layers[n].y + 20) * heightRatio); // Adjust for baseline
          }
        }
      }
      this.loading = true
      let screenshot = await this.saveScreenShot(item, index)
      this.loading = false
    },
    changeDate(){
      this.getPhotos()
    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getOverlays(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photo-overlays'

      axios.get(url, config)
        .then(response => {
          this.overlays = response.data.overlays
          this.getPhotos()

          this.loading = false
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    async getGameInstances (type) {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings?type=' + type + '&day=' + this.current_booking

      this.axios.get(url, config)
        .then(response => {
          this.game_instances = response.data.bookings
          this.loading = false
          if(this.game_instances.length <= 0){
            this.emptyBooking = true
          } else {
            this.emptyBooking = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async getLayout(){
      let img = new Image()
      img.src = URL.createObjectURL(this.file)


      return new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
      });

    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload/photos'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    handleUpload(g){
      let file = event.target.files[0];
      if (file && !file.type.match('image.*')) {
        swal('Error!', 'You must upload an image', 'error')
        return
      }
      if(g == undefined){
        this.uploaded_image = URL.createObjectURL(file);
      } else {
        g.uploaded_image = URL.createObjectURL(file);
      }

      this.file = file
      this.$emit('change', file);
    },
    async assignPhoto(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/photos/' + this.selectedPhoto.id + '/assign'


      let booking_slots = []
      // let users = []
      // for(let n in this.end_users){
      //   if(this.end_users[n].selected){
      //     users.push(this.end_users[n])
      //   }
      // }

      for(let n in this.game_instances){
        if(this.game_instances[n].selected){
          booking_slots.push(this.game_instances[n].booking_slot_id)
        }
      }

      let data = {
        users: this.end_users,
        booking_slots: booking_slots,
      }

      axios.post(url, data, config)
        .then(response => {
          this.modals.select_players = false
          this.modals.select_game_instance = false
          this.modals.select_booking_slot = false
          this.game_instances = []
          this.end_users = []
          this.getPhotos()
        }).catch((err) => {
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/')
        } else {
          swal({
            title: 'Error!',
            text: err.response.data,
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Try again',
            buttonsStyling: false
          })
        }
      })
    },
    async deletePhoto(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/photos/' + id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Photo has been deleted',
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          }).then((response)=> {
            this.getPhotos()
          })
          this.getPhotos()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    setToday(){
      let current = new Date()
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      this.current_booking = this.current
    },
    async getPhotos(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos?t=' + this.current

      axios.get(url, config)
        .then(response => {
          this.photos = response.data.photos
          for(let n in this.photos){
            response.data.photos[n].selectedOverlay = _.find(this.overlays, {id: response.data.photos[n].overlay_id})
            if(JSON.parse(this.photos[n].bookings) && JSON.parse(this.photos[n].players)){
              this.photos[n].assignees = JSON.parse(this.photos[n].bookings).concat(JSON.parse(this.photos[n].players))
            } else if (JSON.parse(this.photos[n].bookings) && !JSON.parse(this.photos[n].players)){
              this.photos[n].assignees = JSON.parse(this.photos[n].bookings)
            } else if (!JSON.parse(this.photos[n].bookings) && JSON.parse(this.photos[n].players)){
              this.photos[n].assignees = JSON.parse(this.photos[n].players)
            } else {
              this.photos[n].assignees = []
            }
            // this.$nextTick(() => {
            //   let underlyingImage = document.getElementById('img' + n)
            //   console.log(underlyingImage)
            //   console.log("height: " + underlyingImage.height)
            //   console.log("width: " + underlyingImage.width)
            //   if(underlyingImage.height > underlyingImage.width){
            //     underlyingImage.width = underlyingImage.width * 0.5
            //     console.log("width: " + underlyingImage.width)
            //   }
            // });
          }

          this.default_aspect_ratio =  response.data.default_aspect_ratio
          this.email_active = response.data.email_active
          this.loading = false
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          this.feature_not_enabled = true
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    async takePhoto(){
      let t = new Date().toLocaleString( 'sv' );
      this.handleUpload()
      if(!this.file){return}
      this.loading = true
      await this.saveFile()
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/photos?t=' + t

      let data = {
        uploaded_image:  this.uploadUrl.split("?")[0],
        aspect_ratio: this.default_aspect_ratio
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''
          this.getPhotos()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    async getUsersForBooking(booking_id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/'
        + companyGroupId + '/bookings/' + booking_id + '/waivers'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.end_users = response.data.users
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    emailToGroupPrompt(photo){
      if(!this.email_active){
        return
      }
      this.selectedPhoto = photo
      if(photo.hasOwnProperty('user_id')){
        this.selectedPhoto.link_type = 0
        photo.selected = true
        this.end_users = []
        this.end_users.push(photo)
        this.emailToGroup()
      } else {
        this.selectedPhoto.link_type = 1
        this.end_users = []
        this.getUsersForBooking(photo.booking_slot_id)
        this.modals.email_group = true
      }
    },
    async emailToGroup(){
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/photos/'+ this.selectedPhoto.photo_id + '/email-group'

      let emails = []
      for(let n in this.end_users){
        if(this.end_users[n].selected){
          emails.push(this.end_users[n])
        }
      }
      let data = {
        link_type: this.selectedPhoto.link_type,
        link_id: this.selectedPhoto.id,
        emails: emails,
      }

      // if(photo.overlay_id && photo.overlay_url){
      //   data.uploaded_image = photo.overlay_url
      // } else {
      //   data.uploaded_image =photo.url
      // }
      //

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: 'The photo was emailed to players.',
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          }).then((response)=> {
            this.modals.email_group = false
            this.getPhotos()
          })
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    updateCanvasSize(index, item) {
      //Get underlying image parameters
      let underlyingImage = document.getElementById('img' + index)

      if(underlyingImage.height > underlyingImage.width && !item.unmodified_image){
        item.unmodified_image = true
        underlyingImage.width = underlyingImage.width * 0.5
      }

      let imgHeight = underlyingImage.height
      let imgWidth = underlyingImage.width

      //Set up canvas
      const canvas = document.getElementById('cv' + index);
      let ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      canvas.height = imgHeight
      canvas.width = imgWidth

      let scale = window.devicePixelRatio;
      let originalWidth = canvas.offsetWidth;
      let originalHeight = canvas.offsetHeight;

      canvas.width = originalWidth * scale;
      canvas.height = originalHeight * scale;

      canvas.style.width = originalWidth + 'px';
      canvas.style.height = originalHeight + 'px';

      ctx.scale(scale, scale);
    },
    async saveImage(elementId) {
      // const canvas = document.getElementById(canvasId);
      const element = document.getElementById(elementId);
      // return new Promise((resolve, reject) => {
      //   html2canvas(element, {useCORS: true, scale: 6}).then(function(canvas) {
      //     const imgData = canvas.toDataURL('image/png');
      //     console.log(imgData)
      //     resolve(imgData);
      //   }).catch(error => {
      //     reject(error);
      //   });
      // });

      html2canvas(element, {useCORS: true, backgroundColor: null, scale: 6}).then((canvas) => {
        // Create an image
        const image = canvas.toDataURL("image/png");

        // Create a link to download the image
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = image;
        link.click();
      });
      // const dataURL = this.canvas.toDataURL("image/png");
      // const link = document.createElement('a');
      // link.download = 'canvas-image.png';
      // link.href = dataURL;
      // link.click();
      // element.toBlob(function(blob) {
      //   const url = URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.download = 'canvas-image.png';
      //   link.href = url;
      //   link.click();
      //   URL.revokeObjectURL(url);
      // }, 'image/png');
    },
    drawImageOnCanvas(img, canvas) {
      var ctx = this.setupCanvas(canvas);
      // Draw the image to fit exactly in the canvas. This will ignore the aspect ratio.
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
      ctx.drawImage(img.img, 0, 0, img.width, img.height);
    },
    updateCanvas(canvasId, baseImage, canvas, ctx, self) {
      if (canvas) {
        // this.drawImageOnCanvas(baseImage, canvas);
        // let ctx = this.setupCanvas(canvas);
        // Draw the image to fit exactly in the canvas. This will ignore the aspect ratio.
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
        // ctx.drawImage(img.img, 0, 0, img.width, img.height);
        // const ctx = this.setupCanvas(canvas)
        //
        // // Clear the canvas
        // ctx.clearRect(0, 0, canvas.width, canvas.height);

        //baseImage
        // const img = new Image();
        // img.crossOrigin = 'Anonymous';
        // img.src = baseImageUrl
        // console.log(img)
        // img.onload = function() {
        //   ctx.drawImage(img, 0, 0, 200, 100);
        // };

        // ctx.drawImage(baseImage.img, 0, 0, baseImage.width, baseImage.height, 0, 0, canvas.width, canvas.height);
        // canvas.width = baseImage.naturalWidth;
        // canvas.height = baseImage.naturalHeight;
        ctx.drawImage(baseImage.img, 0, 0, baseImage.width, baseImage.height);
        // ctx.drawImage(baseImage.img, 0, 0, baseImage.width, baseImage.height)

      } else {
        console.error('Canvas with specified ID not found');
      }
      // window.requestAnimationFrame(() => {
      //
      //  // ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      //   // let dpr = window.devicePixelRatio || 1; // Get the device's pixel ratio
      //   // let rect = this.canvas.getBoundingClientRect();
      //   // console.log(ctx)
      //   //
      //   // // Scale the canvas's size by the device pixel ratio
      //   // this.canvas.width = rect.width * dpr;
      //   // this.canvas.height = rect.height * dpr;
      //   //
      //   // // Ensure the style's dimensions match the original dimensions
      //   // this.canvas.style.width = rect.width + 'px';
      //   // this.canvas.style.height = rect.height + 'px';
      //   // console.log(this.canvas.style)
      //   //
      //   // // Scale the context to compensate for the increased canvas size
      //   // ctx.scale(dpr, dpr);
      //
      //   //Draw main image
      //
      //   // let layers = _.orderBy(this.layers, "position")
      //   // layers.forEach(layer => {
      //   //   layer.fontSize = Number(layer.fontSize)
      //   //   if (layer.type === 'image') {
      //   //     ctx.drawImage(layer.content, layer.x, layer.y, layer.width, layer.height);
      //   //   } else if (layer.type === 'text') {
      //   //     console.log(layer)
      //   //     ctx.font = `${layer.fontSize}px ${layer.font}`;
      //   //     ctx.fillStyle = layer.color;
      //   //     ctx.fillText(layer.content, layer.x, layer.y + layer.fontSize); // Adjust for baseline
      //   //   }
      //   // });
      // })
    },
    setupCanvas(canvas) {
      var dpr = window.devicePixelRatio || 1;
      var rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;

      var ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      ctx.scale(dpr, dpr);
      return ctx;
    },
    drawImageFit(url, fitType, canvas_width, canvas_height, index, canvas, ctx) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.width = canvas_width
      img.height = canvas_height
      img.src = url

      let self = this
      img.onload = function() {
        const hRatio = canvas_width / img.width;
        const vRatio = canvas_height / img.height;
        let dx, dy, dWidth, dHeight, ratio;

        switch (fitType) {
          case 'contain':
            ratio = Math.min(hRatio, vRatio);
            dWidth = img.width * ratio;
            dHeight = img.height * ratio;
            dx = (canvas_width - dWidth) / 2;
            dy = (canvas_height - dHeight) / 2;
            break;
          case 'cover':
            ratio = Math.max(hRatio, vRatio);
            dWidth = img.width * ratio;
            dHeight = img.height * ratio;
            dx = (canvas_width - dWidth) / 2;
            dy = (canvas_height - dHeight) / 2;
            break;
          case 'fill':
            dx = 0;
            dy = 0;
            dWidth = canvas_width;
            dHeight = canvas_height;
            break;
          case 'none':
            dx = (canvas_width - img.width) / 2;
            dy = (canvas_height - img.height) / 2;
            dWidth = img.width;
            dHeight = img.height;
            break;
          default:
            console.error('Unsupported fit type');
            return;
        }
        let image = {
          img: img,
          url: url,
          width: dWidth,
          height: dHeight,
          naturalWidth: img.width,
          naturalHeight: img.height
        }

        self.updateCanvas('canvas' + index, image, canvas, ctx)
      };
    },
  },
  async mounted(){
    // var canvas = document.getElementById("imgcanvas");
    // var ctx = canvas.getContext("2d");
    // var img_can = document.getElementById("img_cam");
    // img_can.onload = function() {
    //   canvas.width = this.naturalWidth;
    //   canvas.height = this.naturalHeight;
    //   ctx.drawImage(this, 0, 0);
    // };
    // img_can.src = img_can.getAttribute('data-src');

    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if(JSON.parse(localStorage.getItem('group')).country === 'GB'){
      this.placeholder_phone = '07700 900000'
    }
    await this.getOverlays()
    this.setToday()
  }
}
</script>

<style scoped>

.vue-grid-item .text {
  text-align: center;
  justify-content:center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
canvas {
  image-rendering: optimizeQuality;
}

.outsideWrapper{
  /*width:456px; height:456px;*/
  margin:20px 60px;
  border:1px solid blue;}

.insideWrapper{
  width:100%; height:100%;
  position:relative;}
.coveredImage{
  width:100%; height:100%;
  position:absolute; top:0px; left:0px;
}
.coveringCanvas{
  width:100%; height:100%;
  position:absolute; top:0px; left:0px;
  background-color: rgba(255,0,0,.1);
}
</style>
