<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard :show-next-button="show_next_button" ref="wiz" :finish-button-text="'Submit'">
          <template slot="header">
            <h3 class="card-title">Game Tracker</h3>

          </template>
          <wizard-tab :before-change="() => validateStep('step0')">
            <template slot="label">
              <i class="tim-icons icon-shape-star"></i>
              <p class="d-none d-sm-block">Start</p>
            </template>
            <h5 v-if="!view_user_drafts" class="info-text">
              Start a new form or resume an existing draft.
            </h5>
            <div class="row justify-content-center text-center" style="margin-top: -20px; margin-bottom: 10px;">
              <div class="col d-sm-none d-block">
                <base-button style="width:100%" simple type="success" v-on:click="createNewDraft()">
                  <i class="tim-icons el-icon-plus"></i> New form</base-button>
              </div>
              <div class="col d-sm-block d-none">
                <base-button v-if="!view_user_drafts && hasPermissions('game_history_view')" type="default" class="mr-2" v-on:click="getAllDrafts()">
                  View all user drafts</base-button>
                <base-button v-if="view_user_drafts && hasPermissions('game_history_view')" type="default" class="mr-2 mt-3" v-on:click="view_user_drafts = false">
                  Return to my drafts</base-button>
                <base-button v-if="!view_user_drafts" style="width:30%" simple type="success" v-on:click="createNewDraft()">
                  <i class="tim-icons el-icon-plus"></i> New form</base-button>
              </div>
            </div>
            <div class="row justify-content-center" v-if="view_user_drafts && user_drafts.length <=0">
              <div class="col-11">
                <div class="mt-3">
                  <div class="font-icon-detail">
                    <span style="font-size: 3em">
                      <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    <h4 class="mt-3">No drafts have been created.</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center" v-if="view_user_drafts && user_drafts.length >0">
              <div class="col-11">
                <b-table striped hover :items="user_drafts" :fields="fields">
                  <template v-slot:cell(game)="row">
                    <h4 v-if="row.item.game_name">{{row.item.game_name}}</h4>
                    <h4 v-else>Empty draft</h4>
                    <p v-if="row.item.booking_date" style="font-weight: lighter; font-style: italic" >
                      {{getFormattedDate(row.item.booking_date)}}, {{getTime(row.item.start_time)}}
                    </p>
                  </template>
                  <template v-slot:cell(created)="row">
                    <p style="font-weight: lighter; font-style: italic" >
                      {{getLocalDate(row.item.time_created)}}, {{getLocalTime(row.item.time_created)}}
                    </p>
                  </template>
                  <template v-slot:cell(last_updated)="row">
                    <p v-if="row.item.time_updated" style="font-weight: lighter; font-style: italic" >
                      {{getLocalDate(row.item.time_updated)}}, {{getLocalTime(row.item.time_updated)}}
                    </p>
                    <p v-else style="font-weight: lighter; font-style: italic" >
                      -
                    </p>
                  </template>
                  <template v-slot:cell(game_master)="row">
                    <div v-if="!editing[row.index]">
                      <p>
                        {{row.item.gm_first_name}} {{row.item.gm_last_name}}
                      </p>
                      <small>
                        <i class="tim-icons icon-email-85"></i>
                        {{row.item.gm_email}}
                      </small>
                    </div>
                    <div v-else>
                      <base-input>
                        <el-select
                                   v-model="selectedUser"
                                   class="select-primary"
                                   placeholder="User"
                                   value-key="id"
                        >
                          <el-option
                            v-for="(c, i) in company_users"
                            class="select-primary"
                            :label="c.first_name + ' ' + c.last_name + ' (' + c.email + ')'"
                            :value="{id: c.id, email: c.email}"
                            :key="'C' + i"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>



                  </template>
                  <template v-slot:cell(actions)="row">
                    <base-button
                      @click.native="deleteDraftPrompt(row.item)"
                      class="like btn-link"
                      type="danger"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-trash-simple"></i>
                    </base-button>

                    <base-button v-if="!editing[row.index]"
                      @click.native="editDraft(row.item, row.index)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button v-if="editing[row.index]"
                      @click.native="saveDraft(row.item, row.index)"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-check-2"></i>
                    </base-button>
                  </template>
                </b-table>

              </div>
            </div>

            <start-new-form v-if="!view_user_drafts" @on-get-drafts="onGetDrafts"
              ref="step0"
              @on-validated="onStepValidated"
            ></start-new-form>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-calendar-60"></i>
              <p class="d-none d-sm-block">Booking</p>
            </template>
            <booking-info-step
              ref="step1"
              @on-validated="onStepValidated"
            ></booking-info-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-user-run"></i>
              <p class="d-none d-sm-block">Players</p>
            </template>
            <pre-game-notes-step
              ref="step2"
              @on-validated="onStepValidated"
            ></pre-game-notes-step>
          </wizard-tab>

          <wizard-tab v-if="gt_settings.include_puzzles_step" :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-puzzle-10"></i>
              <p class="d-none d-sm-block">Puzzles</p>
            </template>
            <puzzle-step ref="step3" @on-validated="onStepValidated"></puzzle-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-notes"></i>
              <p class="d-none d-sm-block">Notes</p>
            </template>
            <post-game-notes-step @on-validated="onStepValidated" ref="step4"></post-game-notes-step>
          </wizard-tab>

          <wizard-tab v-if="gt_settings.include_photos_step" :before-change="() => validateStep('step5')">
            <template slot="label">
              <i class="tim-icons icon-camera-18"></i>
              <p class="d-none d-sm-block">Photos</p>
            </template>
            <photo-step ref="step5" @on-validated="onStepValidated"></photo-step>
          </wizard-tab>

          <wizard-tab v-if="gt_settings.include_review_step" :before-change="() => wizardComplete()">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p class="d-none d-sm-block">Review</p>
            </template>
            <review-step @on-validated="onStepValidated" ref="step6"></review-step>
          </wizard-tab>

        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>
  import BookingInfoStep from './BookingInfoStep'
  import PreGameNotesStep from './PreGameNoteStep'
  import PuzzleStep from "./PuzzleStep";
  import PostGameNotesStep from "./PostGameNotesStep"
  import PhotoStep from "@/pages/Games/GameTracker/PhotoStep";
  import ReviewStep from "./ReviewStep"
  import swal from 'sweetalert2'
  import { SimpleWizard, WizardTab } from 'src/components'
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import StartNewForm from "@/pages/Games/GameTracker/StartNewForm";
  const axios = require('axios')
  import { BTable } from 'bootstrap-vue'
  import {formattedDate, localDate, localTime, time} from "@/plugins/dateFormatter";

  export default {
      name: "GameTracker",
      components: {
        StartNewForm,
        PuzzleStep,
        BookingInfoStep,
        PreGameNotesStep,
        PostGameNotesStep,
        PhotoStep,
        ReviewStep,
        SimpleWizard,
        WizardTab,
        BTable
      },
      data() {
        return {
          loading: false,
          date_format: JSON.parse(localStorage.getItem('group')).date_format,
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          show_next_button: true,
          gt_settings: '',
          user_drafts: [],
          company_users: [],
          editing: [],
          selectedUser: '',
          view_user_drafts: false,
          fields: ['game', 'created', 'last_updated', 'game_master', 'actions'],
        }
      },
      methods: {
        hasPermissions(scope){
          let permissions = JSON.parse(localStorage.getItem('permissions'))
          const matchFound = permissions.some(element => element === scope);
          return matchFound
        },
        editDraft(item, index){
          this.selectedUser = {
            email: item.gm_email,
            id: item.gm_id
          }
          this.$set(this.editing, index, true);
        },
        async saveDraft(item, index){
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/games/instances/drafts/all'
          let data = {
            game_master_id: this.selectedUser.id,
            id: item.id
          }

          axios.put(url, data, config)
            .then(response => {
              swal({
                title: 'Success',
                text: "The draft has been updated.",
                type: 'success'
              })
              this.getAllDrafts()
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
          this.$set(this.editing, index, false);
        },
        nextTab(index){
          this.$refs['wiz'].tabIndex(index);
        },
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        async validateStep(ref) {
          window.scrollTo(0,0)

          if(ref === 'step0'){
            await this.$refs['step0'].setDraft()
            await this.$refs['step0'].getDrafts()
            await this.$refs['step1'].getBookings(0)
          }
          else if(ref === 'step1'){
            let isEmpty = this.$refs['step1'].isEmptyBooking()
            if(isEmpty){
              return
            }
            let validBooking = await this.$refs['step1'].setBookingData()
            if(!validBooking){
              return
            }

            let gameId = JSON.parse(localStorage.getItem('bookingInfo')).booking.game_id
            let bookingId = JSON.parse(localStorage.getItem('bookingInfo')).booking.id
            await this.$refs['step2'].getUsers(gameId, bookingId)
            this.$refs['step2'].populateDraft()
            await this.$refs['step0'].setDraft()
            await this.$refs['step0'].getDrafts()
          }
          else if(ref === 'step2'){
            // await this.$refs['step2'].setPreNotesData()
            await this.$refs['step0'].setDraft()
            await this.$refs['step0'].getDrafts()
            if(this.gt_settings.include_puzzles_step) {
              await this.$refs['step3'].createPuzzles()
            }

            // this.$refs['step3'].startTimer()
          }
          else if(ref === 'step3'){
            //this.$refs['step3'].convertTime()
            await this.$refs['step3'].setDraft()
            this.$refs['step4'].populateDraft()
          }
          else if(ref === 'step4'){
            // this.$refs['step4'].convertTime()
            let test = await this.$refs['step4'].setPostNotes()

            if(this.gt_settings.include_photos_step){
              // this.$refs['step0'].setDraft()
              this.$refs['step5'].populateDraft()
            } else {
              // this.$refs['step0'].setDraft()
              this.$refs['step6'].getReviewData(this.gt_settings)
            }
          }
          else if (ref === 'step5'){
            this.$refs['step5'].setPhoto()
            this.$refs['step6'].getReviewData(this.gt_settings)
          }
          else if(ref === 'step6'){
            this.$refs['step6'].submitRequest()
          }
          return this.$refs[ref].validate();
        },
        onStepValidated(validated, model) {
          this.wizardModel = { ...this.wizardModel, ...model };
          window.scrollTo(0,0)
        },
        onGetDrafts(drafts){
          if(drafts.length <= 0){
            this.show_next_button = false
          } else {
            this.show_next_button = true
          }
        },
        async wizardComplete() {
          this.loading = true
          let companyId = localStorage.getItem('companyId')
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'companies/' + companyId
            + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'
          let data = {}
          axios.post(url, data, config)
            .then(response => {
              this.loading = false
              swal({
                title: 'Good job!',
                text: "You successfully filled out the game form!",
                type: 'success',
                confirmButtonColor: '#1d8cf8',
                confirmButtonText: 'OK'
              }).then((response)=> {
                if(response.value == true){
                  this.$router.push({ path: '/games/history/instance', query: { id: localStorage.getItem('gameInstanceId') }})
                }
              })
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }  else if (err.response.status == 502) {
                swal({
                  title: 'Ooops!',
                  text: "There were some issues with your submission. Please check your form and try again!",
                  type: 'warning',
                  confirmButtonColor: '#1d8cf8',
                  confirmButtonText: 'OK'
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })

        },
        async getAllDrafts(){
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/games/instances/drafts/all'

          axios.get(url, config)
            .then(response => {
              this.user_drafts = response.data.drafts
              this.company_users = response.data.company_users
              for(let n in this.company_users){
                if(this.company_users[n].first_name === null){
                  this.company_users[n].first_name = ''
                }
                if(this.company_users[n].last_name === null){
                  this.company_users[n].last_name = ''
                }
              }
              this.view_user_drafts = true
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        deleteDraftPrompt(d){
          swal({
            title: 'Are you sure?',
            text: "The draft will be permanently deleted.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete'
          }).then((response)=> {
            if(response.value == true){
              this.deleteDraft(d)
            }
          })
        },
        async deleteDraft(d){
          this.loading = true
          let companyId = localStorage.getItem('companyId')

          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + d.id + '/drafts'
          axios.delete(url, config)
            .then(response => {
              this.loading = false
              this.selectedDraft = ''
              localStorage.removeItem('draft')
              swal({
                title: 'Success',
                text: "The draft has been deleted.",
                type: 'success'
              })
              this.getAllDrafts()
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async createNewDraft(){
          localStorage.removeItem('draft')
          localStorage.removeItem('gameInstanceId')
          this.$refs['step1'].clearSelection()

          let companyId = localStorage.getItem('companyId')

          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'companies/' + companyId + '/games/instances/drafts'
          let data = {}
          axios.post(url, data, config)
            .then(response => {
              localStorage.setItem('gameInstanceId', response.data)
              this.show_next_button = true
              this.$refs['step1'].getBookings()
              this.$refs['step0'].getDrafts()
              this.$refs['wiz'].tabIndex(1);
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async getGTSettings(){
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/game-tracker-settings/' + JSON.parse(localStorage.getItem('group')).game_tracker_settings_id

          axios.get(url, config)
            .then(response => {
              this.gt_settings = response.data.game_tracker_settings
              this.gt_settings.include_review_step = true
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        getLocalDate(d){
          if(d == undefined){
            return
          }
          return localDate(this.date_format, d)
        },
        getLocalTime(d){
          if(!d){
            return
          }
          return localTime(this.time_format, d)
        },
        getFormattedDate(d){
          return formattedDate(this.date_format, d)
        },
        getTime(d) {
          return time(this.time_format, d)
        },
      },
    mounted() {
        this.getGTSettings()
    },
  }
</script>

<style scoped>
</style>
