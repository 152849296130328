<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--    Fonts-->
    <div v-for="(f, i) in fonts" :key="'C' + i">
      <link rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + f.family">
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-7">
        <card>
          <div class="row mb-3">
            <div class="col-lg-10">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + 'j'"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="col text-right">
              <badge v-if="selectedWaiverConfig.is_active === 1" type="success">Active</badge>
              <badge v-if="selectedWaiverConfig.is_active === 0" type="danger">Inactive</badge>
              <el-tooltip content="More actions"
                          effect="light"

                          :open-delay="300"
                          placement="top">
                <el-dropdown>
                  <base-button
                    link
                    type="primary"
                    size="sm"
                  > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                  </base-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <a class="dropdown-item mt-2" v-if="selectedWaiverConfig.is_active" @click="activateWaiverTemplate(0)" href="#">
                        <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate waiver
                      </a>
                      <a class="dropdown-item mt-2" v-if="!selectedWaiverConfig.is_active" @click="activateWaiverTemplate(1)" href="#">
                        <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate waiver
                      </a>
                      <hr>
                      <a class="dropdown-item mb-2" @click="visitPortal()" href="#">
                        <i class="tim-icons text-info icon-link-72 mr-2"></i> Visit waiver portal
                      </a>
                      <a class="dropdown-item mb-2" @click="visitPortal('/kiosk')" href="#">
                        <i class="tim-icons text-default icon-tablet-2 mr-2"></i> Visit kiosk
                      </a>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>

              </el-tooltip>
            </div>
          </div>
          <!--          General-->
          <div v-if="activeTabIndex === 0">
            <div class="row mt-3">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <p>Header message</p>
                <base-input type="text" placeholder="Enter some text" v-model="selectedWaiverConfig.main_message"></base-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p>Waiver text</p>
                <div class="row">
                  <div class="col">
                    <base-input>
                      <el-select
                        v-model="waiverOption"
                        class="select-primary"
                      >
                        <el-option
                          v-for="option in waiverOptions"
                          class="select-primary"
                          :label="option"
                          :value="option"
                          :key="option"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col" v-if="waiverOption == 'Text'">
                    <base-button style="margin-top: 0px;" v-on:click="fillDefaultWaiver()" simple type="success">Fill with default waiver text</base-button>

                  </div>
                </div>

                <div v-if="waiverOption == 'Text'">
                  <label>Paste your waiver text in the box below:</label>

                  <editor v-model="selectedWaiverConfig.waiver_text"
                          api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                          :init="{
                     height: 500,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent '
                   }"
                  />
                </div>
                <div v-if="waiverOption == 'External link'">
                  <label>Link to your waiver</label>
                  <base-input placeholder="https://example.com" v-model="selectedWaiverConfig.external_link"></base-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="selectedWaiverConfig.hide_past_slot">
                  Hide past slots on the current day</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="selectedWaiverConfig.multiple_games">
                  Allow customers to check in to multiple games per session</base-checkbox>
              </div>
            </div>
            <div class="row ">
              <div class="col">
                <base-checkbox v-model="selectedWaiverConfig.show_participation_field">
                  Allow customers to sign on behalf of minors without participating in the event</base-checkbox>
              </div>
            </div>
            <div class="row mt-2" v-if="selectedWaiverConfig.show_participation_field">
              <div class="col-lg-6">
                <base-input v-model="selectedWaiverConfig.participation_field_text"
                            label="Label for participation field"></base-input>
              </div>
            </div>
          </div>

          <!--          Appearance-->
          <div v-if="activeTabIndex === 1">
            <p>General</p>
            <div class="row">
              <div class="col-4">
                <base-input label="Font" class="text-danger">
                  <el-select
                    v-model="selectedWaiverConfig.font_family"
                    class="select-primary"
                    :style="{'font-family': selectedWaiverConfig.font_family}"
                    name="font"
                    placeholder="Font"
                  >
                    <el-option
                      v-for="(f, i) in orderedFonts" :key="'B' + i"
                      :style="{'font-family': f.family}"
                      class="select-primary"
                      :label="f.family"
                      :value="f.family"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Text color">
                  <el-color-picker v-model="selectedWaiverConfig.text_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Background color">
                  <el-color-picker v-model="selectedWaiverConfig.background_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
              </div>
            </div>
            <p>Cards</p>
            <div class="row">
              <div class="col-4">
                <base-input label="Font" class="text-danger">
                  <el-select
                    v-model="selectedWaiverConfig.font_family_card"
                    class="select-primary"
                    :style="{'font-family': selectedWaiverConfig.font_family_card}"
                    name="font"
                    placeholder="Font"
                  >
                    <el-option
                      v-for="(f, i) in orderedFonts" :key="'B' + i"
                      :style="{'font-family': f.family}"
                      class="select-primary"
                      :label="f.family"
                      :value="f.family"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Text color">
                  <el-color-picker v-model="selectedWaiverConfig.text_color_card" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Link color">
                  <el-color-picker v-model="selectedWaiverConfig.link_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Button color">
                  <el-color-picker v-model="selectedWaiverConfig.button_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Background color">
                  <el-color-picker v-model="selectedWaiverConfig.card_color" show-alpha></el-color-picker>
                </base-input>
              </div>
            </div>

            <p>Logo</p>
            <div class="row">
              <div class="col">
                <div
                  class="thumbnail">
                  <img :height="selectedWaiverConfig.logo_height" :width="selectedWaiverConfig.logo_width" :style="{'object-fit': selectedWaiverConfig.logo_fit}" :src="selectedWaiverConfig.logo" alt="preview" />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
              <span class="btn btn-primary btn-sm btn-simple btn-file">Upload
                  <input
                    accept="image/*"
                    @change="handlePreview"
                    type="file"
                    name="..."
                    class="valid"
                    :multiple="false"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <base-input v-model="selectedWaiverConfig.logo_height" type="number" label="Logo height"></base-input>
              </div>
              <div class="col">
                <base-input v-model="selectedWaiverConfig.logo_width" type="number" label="Logo width"></base-input>
              </div>
              <div class="col">
                <base-input label="Logo fit">
                  <el-select
                    v-model="selectedWaiverConfig.logo_fit"
                    class="select-primary"
                  >
                    <el-option
                      v-for="option in fitOptions"
                      class="select-primary"
                      :label="option"
                      :value="option"
                      :key="option"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>

          <!--          Waiver fields-->
          <div v-if="activeTabIndex === 2">
            <div class="row d-none d-sm-block">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <b-table  striped hover :items="fields" :fields="columns">
                  <template v-slot:cell(field)="row">
                    <div class="row ml-1 main-width">
                    <span>{{row.item.name}}
                      <el-tooltip
                        content="Booking date field is always required"
                        effect="light"
                        :open-delay="300"
                        placement="right"
                      >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Booking Date'"></i>
                      </el-tooltip>
                       <el-tooltip
                         content="Booking time field is always required"
                         effect="light"
                         :open-delay="300"
                         placement="right"
                       >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Booking Time'"></i>
                      </el-tooltip>
                       <el-tooltip
                         content="Email field is always required"
                         effect="light"
                         :open-delay="300"
                         placement="right"
                       >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Email'"></i>
                      </el-tooltip>
                      <el-tooltip
                        content="Ask customers where they heard about you"
                        effect="light"
                        :open-delay="300"
                        placement="right"
                      >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Source'"></i>
                      </el-tooltip>
                      <el-tooltip
                        content="Ask customers where they are from"
                        effect="light"
                        :open-delay="300"
                        placement="right"
                      >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Location'"></i>
                      </el-tooltip>
                      <el-tooltip
                        content="Ask customers how many games they played"
                        effect="light"
                        :open-delay="300"
                        placement="right"
                      >
                        <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Experience'"></i>
                      </el-tooltip>
                    </span>
                    </div>
                  </template>
                  <template v-slot:cell(active)="row">
                    <input v-show="row.item.name !== 'Booking Date' && row.item.name !== 'Booking Time' && row.item.name !== 'Email'"
                           type="checkbox" @click="setActive(row.item, row.index)" class="mt-2" v-model="row.item.is_active">
                    <input v-show="row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                           type="checkbox" disabled class="mt-2" v-model="row.item.is_active">
                  </template>
                  <template v-slot:cell(required)="row">
                    <input type="checkbox"
                           v-show="row.item.name !== 'Booking Date' && row.item.name !== 'Booking Time' && row.item.name !== 'Email'"
                           :disabled="row.item.is_active == 0" class="mt-2" v-model="row.item.is_required">
                    <input type="checkbox"
                           v-show="row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                           :disabled="row.item.is_active == 0 || row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                           class="mt-2" v-model="row.item.is_required">
                  </template>
                </b-table>
              </div>
            </div>
            <!--Mobile-->
            <div class="row mt-3 d-block d-sm-none">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <b-table  striped hover :items="fields" :fields="mobile_columns">
                  <template v-slot:cell(fields)="row">
                    <div class="row">
                      <div class="col-12">
                        <span class="text-primary">{{row.item.name}} </span>
                        <el-tooltip
                          content="Booking date field is always required"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Booking Date'"></i>
                        </el-tooltip>
                        <el-tooltip
                          content="Booking time field is always required"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Booking Time'"></i>
                        </el-tooltip>
                        <el-tooltip
                          content="Email field is always required"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <i class="tim-icons icon-alert-circle-exc" v-show="row.item.name === 'Email'"></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        Active:
                        <input v-show="row.item.name !== 'Booking Date' && row.item.name !== 'Booking Time' && row.item.name !== 'Email'"
                               type="checkbox" @click="setActive(row.item, row.index)" class="mt-2" v-model="row.item.is_active">
                        <input v-show="row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                               type="checkbox" disabled class="mt-2" v-model="row.item.is_active">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        Required:
                        <input type="checkbox"
                               v-show="row.item.name !== 'Booking Date' && row.item.name !== 'Booking Time' && row.item.name !== 'Email'"
                               :disabled="row.item.is_active == 0" class="mt-2" v-model="row.item.is_required">
                        <input type="checkbox"
                               v-show="row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                               :disabled="row.item.is_active == 0 || row.item.name === 'Booking Date' || row.item.name === 'Booking Time' || row.item.name === 'Email'"
                               class="mt-2" v-model="row.item.is_required">
                      </div>
                    </div>

                  </template>
                  <template v-slot:cell(active)="row">

                  </template>
                  <template v-slot:cell(required)="row">

                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <!--    Actions-->
          <div class="row mt-3">
            <div class="col">
              <base-button type="primary" v-on:click="updateWaiverConfigurations()">Save changes</base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-5">
        <card>
          <h4 class="card-title">Waiver sections</h4>
          <div class="row">
            <div class="col">
              <base-checkbox type="checkbox"
                             v-model="selectedWaiverConfig.display_section_titles"> Display section titles on customer waiver</base-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-checkbox v-model="selectedWaiverConfig.is_compact" v-on:input="modifySections(selectedWaiverConfig.is_compact)">
                Show time slots and games on the same step of the check-in process</base-checkbox>
            </div>
          </div>
          <div class="row" v-if="selectedWaiverConfig.display_section_titles">
            <div class="col">
              <b-table :items="sections" :fields="sectionFields">
                <template #head(name)="row">
                  <span></span>
                </template>
                <template #head(position)="row">
                  <span></span>
                </template>
                <template v-slot:cell(position)="row">
                  <p>Section {{ row.index + 1 }}</p>
                </template>
                <template v-slot:cell(name)="row">
                  <base-input type="text" placeholder="Section name" v-model="row.item.name"></base-input>
                </template>
              </b-table>

            </div>
          </div>
          <!--    Actions-->
          <div class="row mt-3">
            <div class="col">
              <base-button type="primary" v-on:click="updateWaiverConfigurations()">Save changes</base-button>
            </div>
          </div>
        </card>


        <card>
          <h4 slot="header" class="card-title">Embed form</h4>
          <p>Copy the following HTML and embed it in your code to display the waiver on your website.</p>
          <div class="mt-2">
            <base-alert type="white" class="text-muted">

              <div class="row">
                <div class="col-10">
                  <code>{{ embedded_text }}</code>
                </div>
                <div class="col text-right">
                  <base-button class="like btn-link"
                               type="default"
                               size="sm" style="padding: 0px 0px 2px"
                               v-on:click="copy()">
                    <i class="tim-icons icon-single-copy-04"></i>
                  </base-button>
                </div>
              </div>
            </base-alert>
          </div>

          <div class="row">
            <div class="col">
              <h4 slot="header" class="card-title">QR code
                <i style="cursor: pointer" v-on:click="downloadQr()" class="tim-icons icon-cloud-download-93 text-white ml-2"></i>
              </h4>
              <div>
                <qrcode-vue id="qr" :value="qr.value" :size="qr.size" level="H"></qrcode-vue>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>

<script>
import { BFormInput, BTable } from 'bootstrap-vue'
import { ColorPicker, Select, Option, Tooltip, Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { ImageUpload, BaseAlert } from 'src/components';
import { Auth } from 'aws-amplify'
import swal from 'sweetalert2'
import { API_LOCATION, WAIVER_URL } from '../../api-config'
const axios = require('axios')
import QrcodeVue from 'qrcode.vue'
import _ from "lodash"
import Editor from '@tinymce/tinymce-vue'
import Badge from  "@/components/Badge"

export default {
  name: "WaiverTemplate",
  components: {
    [ColorPicker.name]: ColorPicker,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BFormInput,
    BTable,
    Badge,
    ImageUpload,
    QrcodeVue,
    BaseAlert,
    'editor': Editor
  },
  computed: {
    categories() {
      return [{ name: 'General settings', icon: 'tim-icons icon-settings', active: true },
        { name: 'Appearance', icon: 'tim-icons icon-palette', active: false },
        { name: 'Waiver fields', icon: 'tim-icons icon-bullet-list-67', active: false },
      ];
    },
    orderedFonts: function () {
      _.remove(this.fonts, {
        family: 'Libre Baskerville'
      });
      _.remove(this.fonts, {
        family: 'Material Icons'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Outlined'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Sharp'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Round'
      });
      _.remove(this.fonts, {
        family: 'M PLUS Rounded 1c'
      });
      return _.orderBy(this.fonts, 'family')
    },
  },
  data() {
    return {
      group: '',
      text: '',
      columns: ['field', 'active', 'required'],
      mobile_columns: ['fields'],
      selectedStep: [],
      loading: false,
      fields: [],
      sectionFields: ['position', 'name'],
      uploadUrl: '',
      fonts: '',
      sections: [],
      embedded_text: 'iframe',
      fitOptions: ['cover', 'fill', 'contain', 'scale-down', 'none'],
      waiverOption: 'None',
      waiverOptions: ['None', 'External link', 'Text'],
      selectedWaiverConfig: {
        id: '',
        logo: '',
        background_color: '',
        button_color: '',
        main_message: '',
        code: '',
        external_link: '',
        waiver_text: ''
      },
      file: '',
      qr: {
        value: 'https://example.com',
        size: 300
      },
      j:0,
      activeTabIndex: 0,
      hiddenSection: ''
    }
  },
  methods: {
    modifySections(is_compact){
      if(is_compact){
        this.hiddenSection = this.sections[1]
        this.sections.splice(1, 1)
      } else {
        this.sections.splice(1, 0, this.hiddenSection)
      }
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    fillDefaultWaiver(){
      this.selectedWaiverConfig.waiver_text = '<p style="color:black">I HEREBY ASSUME ALL OF THE RISKS OF PARTICIPATING IN ANY/ALL ACTIVITIES ASSOCIATED WITH THIS IMMERSIVE EXPERIENCE, including by way of example and not limitation, any risks that may arise from negligence or carelessness on the part of the persons or entities being released, from dangerous or defective equipment or property owned, maintained, or controlled by them, or because of their possible liability without fault.</p>' +
        '<p style="color:black">I certify that I understand this activity has potential risks including but not limited to: 1) Use of simple tools; 2) Potentially moving or lifting objects of not more than twenty pounds; 3) Mental stress and anxiety; 4) Being in a reasonably small space with up to six people; 5) Possibility of failure to complete the experience in the suggested amount of time.</p>' +
        '<p style="color:black">I have no physical or mental illness that precludes my participation in a safe manner for myself or others. I am not under the influence of drugs or alcohol which impairs my ability to maintain my safety awareness or endangers others.</p>' +
        '<p style="color:black">I acknowledge that this Accident Waiver and Release of Liability Form will be used by the organizers of the activity in which I may participate, and that it will govern my actions and responsibilities at said activity. I agree that all staff or authorized agents may, in their sole discretion, determine it is unsafe for myself or others for my participation to continue, remove me from the premises by any lawful means.</p>' +
        '<p style="color:black">In consideration of my application and permitting me to participate in this activity, I hereby take action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows:</p>' +
        '<p style="color:black">(A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not limited to, liability arising from the negligence or fault of the entities or persons released, for my death, disability, personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me, THE FOLLOWING ENTITIES OR PERSONS: The directors, officers, employees, volunteers, representatives, and agents of any and all entities authorizing this activity;</p>' +
        '<p style="color:black">(B) INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the entities or persons mentioned in this paragraph from any and all liabilities or claims made as a result of participation in this activity, whether caused by the negligence of release or otherwise.</p>' +
        '<p style="color:black">I acknowledge that the directors, officers, employees, volunteers, representatives, and agents of any authorizing entity are NOT responsible for the errors, omissions, acts, or failures to act of any party or entity conducting a specific activity on their behalf. I hereby consent to receive medical treatment which may be deemed advisable in the event of injury, accident, and/or illness during this activity.</p>' +
        '<p style="color:black">I understand while participating in this activity and engaging with the business, I may be photographed or recorded. I agree to allow my photo, video, audio, or film likeness to be used for any legitimate purpose this authorizing entity decides and assigns. The Accident Waiver and Release of Liability Form shall be construed broadly to provide a release and waiver to the maximum extent permissible under applicable law.\n' +
        'I CERTIFY THAT I HAVE READ THIS DOCUMENT AND I FULLY UNDERSTAND ITS CONTENT. I AM AWARE THAT THIS IS A RELEASE OF LIABILITY AND A CONTRACT AND I SIGN IT OF MY OWN FREE WILL.</p>' +
        '<p style="color:black">For Parents/Guardians: We agree to take FULL RESPONSIBILITY of all individuals under the age of 18. Furthermore, We will be held responsible for any violations made by individuals under the age of 18.</p>' +
        '<p style="color:black">By signing below, you are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By clicking below, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.</p>'
    },
    getIframe(){
      this.embedded_text =  `<div id="otcContainer"></div>
                      <script>
                        let f = document.createElement('iframe');
                        f.src = 'https://offthecouch.io/checkin/` + this.selectedWaiverConfig.code + `';
                        f.width = '100%';
                        f.style.border = 'none';
                        window.addEventListener('message', e => {
                            if (e.origin === 'https://offthecouch.io') f.style.height = e.data.height + 'px';
                          });
                        document.getElementById('otcContainer').appendChild(f);
                      <\/script>`
    },
    copy() {
      navigator.clipboard.writeText(this.embedded_text);
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getWaiverConfigurations() {
      let waiverConfigId = this.$route.query.id

      this.loading = true
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/waiver-configurations/' + waiverConfigId
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}

      axios.get(url, config)
        .then(response => {
          localStorage.removeItem('editing')
          this.selectedWaiverConfig = response.data.config
          if(this.selectedWaiverConfig.external_link != null && this.selectedWaiverConfig.external_link != ''){
            this.waiverOption = 'External link'
          }
          else if(this.selectedWaiverConfig.waiver_text != null && this.selectedWaiverConfig.waiver_text != ''){
            this.waiverOption = 'Text'
          }
          this.fields = response.data.fields
          this.sections = response.data.sections
          this.generateQrCode()
          this.getIframe()
          if(this.selectedWaiverConfig.is_compact){
            this.modifySections(this.selectedWaiverConfig.is_compact)
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    handlePreview (){
      let file = event.target.files[0];
      this.selectedWaiverConfig.logo = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    setActive(item, index){
      if(item.is_active == true || item.is_active == 1){
        item.is_required = false
      }
    },
    async updateWaiverConfigurations(){

      this.loading = true
      if(this.waiverOption == 'External link'){
        this.selectedWaiverConfig.waiver_text = ''
      }
      else if(this.waiverOption == 'Text'){
        this.selectedWaiverConfig.external_link = ''
      } else {
        this.selectedWaiverConfig.waiver_text = ''
        this.selectedWaiverConfig.external_link = ''
      }
      if(this.file != ''){
        await this.saveFile()
        this.selectedWaiverConfig.logo = this.uploadUrl.split("?")[0]
      }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/waiver-configurations/' + this.selectedWaiverConfig.id

      let payload = {
        'waiverConfigurations': this.selectedWaiverConfig,
        'fields': this.fields,
        'text_option': this.waiverOption,
        'sections': this.sections
      }

      axios.put(url, payload, config)
        .then((res) => {
          this.loading = false
          swal({
            title: 'Success',
            text: `Waiver has been updated`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })

          this.getWaiverConfigurations()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async activateWaiverTemplate(activate){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/waiver-configurations/' + this.selectedWaiverConfig.id + '/activate'

      let payload = {
        'is_active': activate
      }

      this.selectedWaiverConfig.is_active = activate

      axios.post(url, payload, config)
        .then((res) => {
          this.loading = false
          if(activate == 1){
            swal({
              title: 'Success',
              text: `Waiver template has been activated`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
          } else {
            swal({
              title: 'Success',
              text: `Waiver template has been deactivated`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    visitPortal(path){
      let url = WAIVER_URL + this.selectedWaiverConfig.code
      if(path){
        url += path
      }
      window.open(url, "_blank");
    },
    generateQrCode(){
      this.qr.value = WAIVER_URL + this.selectedWaiverConfig.code
    },
    printQr(){
      var prtContent = document.getElementById("qr");
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    downloadQr(){
      var link = document.createElement('a');
      link.download = 'qr.png';
      console.log(document.getElementsByTagName("canvas"))
      link.href = document.getElementsByTagName('canvas')[0].toDataURL()
      link.click();
    },
    getFonts(){
      let url = API_LOCATION + 'fonts'

      axios.get(url)
        .then(response => {
          this.fonts = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted(){
    this.group = JSON.parse(localStorage.getItem('group'))

    this.getWaiverConfigurations()
    this.getFonts()
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito");

.main-width{
  width: 15em;
}

</style>
