<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row mt-2">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
          <div class="col text-right d-sm-block d-none">
            <base-button size="sm" v-on:click="createSchedule()" type="primary" >
              <i class="tim-icons icon-simple-add mr-2"></i> Create schedule</base-button>

            <el-tooltip content="More actions"
                        effect="light"

                        :open-delay="300"
                        placement="top">
              <el-dropdown>
                <base-button
                  simple
                  type="primary"
                  link
                > <i style="font-size: large"  class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item" v-on:click="clearSchedulesModal()" href="#">
                      <i class="tim-icons icon-refresh-01 mr-2"></i> Clear manually created slots
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </el-tooltip>
          </div>
          <div class="col text-right d-sm-none d-block">
            <base-button size="sm" icon v-on:click="createSchedule()" type="primary" >
              <i class="tim-icons icon-simple-add"></i></base-button>

            <el-tooltip content="More actions"
                        effect="light"

                        :open-delay="300"
                        placement="top">
              <el-dropdown>
                <base-button
                  simple
                  type="primary"
                  link
                > <i style="font-size: large"  class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item" v-on:click="clearSchedulesModal()" href="#">
                      <i class="tim-icons icon-refresh-01 mr-2"></i> Clear manually created slots
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </el-tooltip>
          </div>
        </div>
        <div v-if="activeTabIndex === 0">
          <div v-if="booking_schedules.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No schedules have been created.</h4>
            </div>
          </div>

          <div class="row" v-if="booking_schedules.length > 0">
            <div class="col d-sm-none d-block">
              <b-table  striped hover :items="booking_schedules"
                        :fields="['schedules']">
                <template v-slot:cell(schedules)="row">
                  <div class="row">
                    <div class="col">
                      {{row.item.name}}
                      <base-button v-if="row.item.active"
                                   @click="activateSchedulePrompt('deactivate', row.item)"
                                   icon
                                   class="like btn-link"
                                   size="sm"
                      ><span class="active-dot"></span>
                      </base-button>
                      <base-button v-if="!row.item.active"
                                   @click="activateSchedulePrompt('activate', row.item)"
                                   icon
                                   class="like btn-link"
                                   size="sm"
                      ><span class="inactive-dot"></span>
                      </base-button>
                    </div>
                    <div class="col text-right">
                      <badge type="default" v-if="row.item.is_recurring == 2">Closed period</badge>
                      <badge type="default" v-if="row.item.is_recurring == 1">Always recurring</badge>
                      <badge type="default" v-if="row.item.is_recurring == 0">Finite</badge>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col">
                      <small v-if="row.item.start_date">{{getDate(row.item.start_date)}}
                        <small v-if="row.item.end_date && row.item.is_recurring !== 1"> - {{getDate(row.item.end_date)}}</small>
                      </small>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col">
                      <span class="mr-1" v-for="(a, i) in getActiveDays(row.item)" :key="i">
                        <badge type="default">{{a}}</badge>
                      </span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col">
                      <badge v-for="(game, index) in JSON.parse(row.item.games)" :key="'G' + index" class="mr-3" type="primary">{{game.name}}</badge>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="btn-group btn-group-toggle mt-3"
                        :class="'float-left'"
                        data-toggle="buttons" style="width:100%; max-width: 100%"
                      >
                        <label class="btn btn-sm btn-primary btn-simple">
                          <base-button style="padding-left: 0; padding-right: 0"
                                       @click.native="deleteSchedule(row.item, $event)"
                                       type="primary"
                                       class="btn btn-link"
                                       size="sm"
                                       icon
                          ><i class="tim-icons icon-trash-simple"></i>
                          </base-button>
                        </label>
                        <label class="btn btn-sm btn-primary btn-simple">
                          <base-button style="padding-left: 0; padding-right: 0"
                                       @click.native="editSchedule(row.item, $event)"
                                       type="primary"
                                       class="btn btn-link"
                                       size="sm"
                                       icon
                          ><i class="tim-icons icon-pencil"></i>
                          </base-button>
                        </label>
                        <label class="btn btn-sm btn-primary btn-simple">
                          <base-button style="padding-left: 0; padding-right: 0"
                                       @click.native="copyScheduleTemplate(row.item, $event)"
                                       type="primary"
                                       class="btn btn-link"
                                       size="sm"
                                       icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col d-sm-block d-none">
              <b-table striped hover :items="booking_schedules"
                        :fields="fields">
                <template v-slot:cell(name)="row">
                  <span>
                    {{row.item.name}}
                  </span>
                </template>
                <template v-slot:cell(status)="row">
                  <el-progress v-if="((row.item.created_number_of_records  / row.item.expected_number_of_records) * 100).toFixed(0) <= 100 && !row.item.record_creation_completed && (row.item.created_number_of_records > 0 && row.item.expected_number_of_records > 0)"
                               :percentage="parseInt(((row.item.created_number_of_records  / row.item.expected_number_of_records) * 100).toFixed(0))" />
                  <badge type="success" v-if="row.item.record_creation_completed && row.item.active">active</badge>
                  <badge type="danger" v-if="row.item.record_creation_completed && !row.item.active">deactivated</badge>
                </template>
                <template v-slot:cell(start_date)="row">
                  <span v-if="row.item.start_date">{{getDate(row.item.start_date)}}</span>
                  <span v-if="!row.item.start_date">-</span>
                </template>
                <template v-slot:cell(end_date)="row">
                  <span v-if="row.item.end_date && row.item.is_recurring !== 1">{{getDate(row.item.end_date)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(schedule_duration)="row">
                  <span v-if="row.item.is_recurring == 2">Closed period</span>
                  <span v-if="row.item.is_recurring == 1">Always recurring</span>
                  <span v-if="row.item.is_recurring == 0">Finite</span>
                </template>
                <template v-slot:cell(active_days)="row">
                  <span v-if="getActiveDays(row.item).length <= 0">-</span>
                  <span v-for="(a, i) in getActiveDays(row.item)" :key="i">
                    {{a}}
                  </span>
                </template>
                <template v-slot:cell(assigned_games)="row">
                  <span v-if="!row.item.games">-</span>
                  <badge v-else v-for="(game, index) in JSON.parse(row.item.games)" :key="'G' + index" class="mr-3" type="primary">{{game.name}}</badge>
                </template>

                <template v-slot:cell(actions)="row">
                  <el-dropdown v-if="row.item.record_creation_completed">
                    <base-button
                      link
                      type="primary"
                      size="sm"
                    > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mb-2"  v-if="row.item.active" @click="activateSchedulePrompt('deactivate', row.item, $event)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate schedule
                        </a>
                        <a class="dropdown-item mb-2"  v-if="!row.item.active" @click="activateSchedulePrompt('activate', row.item, $event)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate schedule
                        </a>
                        <a class="dropdown-item mb-2" @click="editSchedule(row.item, $event)" href="#">
                          <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit schedule
                        </a>
                        <a class="dropdown-item" @click="copyScheduleTemplate(row.item, $event)" href="#">
                          <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Save as new schedule
                        </a>
                        <hr>
                        <a class="dropdown-item" @click="deleteSchedule(row.item, $event)" href="#">
                          <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete schedule
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div v-if="activeTabIndex === 1">
          <!--  Feature not enabled-->
<!--          <div v-if="feature_not_enabled" class="mt-3" >-->
<!--            <div class="font-icon-detail">-->
<!--            <span style="font-size: 3em">-->
<!--              <i class="fas fa-exclamation-triangle"></i>-->
<!--            </span>-->
<!--              <h4 class="mt-3">This feature is not enabled.</h4>-->
<!--              <base-button type="primary" v-if="hasPermissions('billing_view')"-->
<!--                           v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>-->
<!--              <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div v-if="!feature_not_enabled">-->
            <div v-if="resource_schedules.length <= 0" class="mt-3" >
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No schedules have been created.</h4>
              </div>
            </div>
            <div class="row" v-if="resource_schedules.length > 0">
              <div class="col d-sm-none d-block">
                <b-table  striped hover :items="resource_schedules"
                          :fields="['schedules']">
                  <template v-slot:cell(schedules)="row">
                    <div class="row">
                      <div class="col">
                        {{row.item.name}}
                        <base-button v-if="row.item.active"
                                     @click="activateSchedulePrompt('deactivate', row.item)"
                                     icon
                                     class="like btn-link"
                                     size="sm"
                        ><span class="active-dot"></span>
                        </base-button>
                        <base-button v-if="!row.item.active"
                                     @click="activateSchedulePrompt('activate', row.item)"
                                     icon
                                     class="like btn-link"
                                     size="sm"
                        ><span class="inactive-dot"></span>
                        </base-button>
                      </div>
                      <div class="col text-right">
                        <badge type="default" v-if="row.item.is_recurring == 2">Closed period</badge>
                        <badge type="default" v-if="row.item.is_recurring == 1">Always recurring</badge>
                        <badge type="default" v-if="row.item.is_recurring == 0">Finite</badge>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col">
                        <small v-if="row.item.start_date">{{getDate(row.item.start_date)}}
                          <small v-if="row.item.end_date && row.item.is_recurring !== 1"> - {{getDate(row.item.end_date)}}</small>
                        </small>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col">
                      <span class="mr-1" v-for="(a, i) in getActiveDays(row.item)" :key="i">
                        <badge type="default">{{a}}</badge>
                      </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="btn-group btn-group-toggle mt-3"
                          :class="'float-left'"
                          data-toggle="buttons" style="width:100%; max-width: 100%"
                        >
                          <label class="btn btn-sm btn-primary btn-simple">
                            <base-button style="padding-left: 0; padding-right: 0"
                                         @click.native="deleteSchedule(row.item, $event)"
                                         type="primary"
                                         class="btn btn-link"
                                         size="sm"
                                         icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple">
                            <base-button style="padding-left: 0; padding-right: 0"
                                         @click.native="editSchedule(row.item, $event)"
                                         type="primary"
                                         class="btn btn-link"
                                         size="sm"
                                         icon
                            ><i class="tim-icons icon-pencil"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple">
                            <base-button style="padding-left: 0; padding-right: 0"
                                         @click.native="copyScheduleTemplate(row.item, $event)"
                                         type="primary"
                                         class="btn btn-link"
                                         size="sm"
                                         icon
                            ><i class="tim-icons icon-single-copy-04"></i>
                            </base-button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="col d-sm-block d-none">
                <b-table  striped hover :items="resource_schedules"
                          :fields="['name', 'start_date', 'end_date', 'schedule_duration', 'active_days', 'status', 'actions']">
                  <template v-slot:cell(title)="row">
              <span>
                {{row.item.name}}
              </span>
                  </template>
                  <template v-slot:cell(start_date)="row">
                    <span v-if="row.item.start_date">{{getDate(row.item.start_date)}}</span>
                    <span v-if="!row.item.start_date">-</span>
                  </template>
                  <template v-slot:cell(status)="row">
                    <el-progress v-if="((row.item.created_number_of_records  / row.item.expected_number_of_records) * 100).toFixed(0) <= 100 && !row.item.record_creation_completed && (row.item.created_number_of_records > 0 && row.item.expected_number_of_records > 0)"
                                 :percentage="parseInt(((row.item.created_number_of_records  / row.item.expected_number_of_records) * 100).toFixed(0))" />
                    <badge type="success" v-if="row.item.record_creation_completed && row.item.active">active</badge>
                    <badge type="danger" v-if="row.item.record_creation_completed && !row.item.active">deactivated</badge>
                  </template>
                  <template v-slot:cell(end_date)="row">
                    <span v-if="row.item.end_date && row.item.is_recurring !== 1">{{getDate(row.item.end_date)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(schedule_duration)="row">
                    <span v-if="row.item.is_recurring == 2">Closed period</span>
                    <span v-if="row.item.is_recurring == 1">Always recurring</span>
                    <span v-if="row.item.is_recurring == 0">Finite</span>
                  </template>
                  <template v-slot:cell(active_days)="row">
                    <span v-if="getActiveDays(row.item).length <= 0">-</span>
                    <span v-for="(a, i) in getActiveDays(row.item)" :key="i">
                    {{a}}
                </span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown v-if="row.item.record_creation_completed">
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2"  v-if="row.item.active" @click="activateSchedulePrompt('deactivate', row.item, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate schedule
                          </a>
                          <a class="dropdown-item mb-2"  v-if="!row.item.active" @click="activateSchedulePrompt('activate', row.item, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate schedule
                          </a>
                          <a class="dropdown-item mb-2" @click="editSchedule(row.item, $event)" href="#">
                            <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit schedule
                          </a>
                          <a class="dropdown-item" @click="copyScheduleTemplate(row.item, $event)" href="#">
                            <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Save as new schedule
                          </a>
                          <hr>
                          <a class="dropdown-item" @click="deleteSchedule(row.item, $event)" href="#">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete schedule
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
<!--        </div>-->
      </card>
    </div>
  </div>

  <!--    Clear schedules-->
  <modal :show.sync="modals.clear_schedules"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Clear manually created booking slots</h4>
      <div class="row">
        <div class="col">
          <base-alert type="info">
            <i class="tim-icons el-icon-info mr-1"></i> <span style="font-weight: bold">Note:</span>  this will clear all future booking slots that were created manually
            and are not associated with a schedule. All other booking slots can be removed by deleting the associated schedule.</base-alert>
        </div>
      </div>
      <div class="row">
        <div class="col" >
          <base-input label="Games">
            <el-select
              label="Select game"
              v-model="cleared_games"
              class="select-primary"
              value-key="id"
              multiple
            >
              <el-option
                v-for="(game, i) in games"
                class="select-primary"
                :label="game.name"
                :value="{ name: game.name, id: game.id}"
                :key="'G' + i"
              ></el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <label>Booking slot status</label>
      <div class="row">
        <div class="col">
          <base-checkbox v-model="cleared_status.available">Available</base-checkbox>
          <base-checkbox v-model="cleared_status.block">Blocked</base-checkbox>
          <base-checkbox v-model="cleared_status.call_to_book">Call to book</base-checkbox>
        </div>
      </div>

      <div class="row d-none d-sm-block text-center mt-3">
        <base-button type="secondary" @click="modals.clear_schedules = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="clearSchedules()">Clear</base-button>
      </div>
      <div class="row d-block d-sm-none text-center mt-3">
        <base-button simple size="sm" type="secondary" @click="modals.clear_schedules = false">Cancel</base-button>
        <base-button simple size="sm" class="ml-3" type="primary" @click="clearSchedules()">Clear</base-button>
      </div>
    </card>
  </modal>

  <!--    Future slots-->
  <modal :show.sync="modals.future_slots"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <div class="row">
        <div class="col">
          <h4 class="card-title">Review future slots</h4>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-alert v-if="!resource_check" type="info"><i class="tim-icons el-icon-info mr-1"></i> The following slots will remained unchanged unless selected below.
            Please review and confirm the time slots you want to keep or remove. </base-alert>
<!--          <base-alert v-if="!resource_check && has_insufficient_resource_slots" type="warning"><i class="tim-icons el-icon-warning mr-1"></i> You have existing booking slots with resources allocated to them with automatic blocking enabled.-->
<!--            Any new concurrent slots may be automatically blocked if the resource coverage is insufficient.</base-alert>-->
<!--          <base-alert v-if="resource_check && has_insufficient_resource_slots" type="warning"><i class="tim-icons el-icon-warning mr-1"></i> You have existing booking slots with resources allocated to them with automatic blocking enabled.-->
<!--            Concurrent available slots may be automatically blocked if the resource coverage is insufficient.</base-alert>-->
        </div>
      </div>

      <div class="row" v-if="!resource_check">
        <div class="col">
          <b-table striped hover :items="future_slots"
                   :fields="['time_slot_status', 'slot_count', 'action']">
            <template v-slot:cell(time_slot_status)="row">
              <badge v-if="row.item.status === 'booked'" type="info">booked</badge>
              <badge v-if="row.item.status === 'call_to_book'" type="warning">call to book</badge>
              <badge v-if="row.item.status === 'blocked'" type="danger">blocked</badge>
              <div>
                <small class="text-danger" style="font-style: italic" v-if="row.item.status === 'booked'" type="default">Booked slots are never removed automatically</small>
              </div>
            </template>
            <template v-slot:cell(slot_count)="row">
              <span>{{row.item.count}}</span>
            </template>
            <template v-slot:cell(action)="row">
              <el-switch v-if="row.item.status !== 'booked'" :disabled="row.item.count <= 0" active-color="#e14eca"
                         v-model="row.item.remove"  />
              <label v-if="row.item.status !== 'booked'"> &nbsp; Remove slots</label>

              <el-switch v-if="row.item.status === 'booked'" :disabled="row.item.count <= 0" active-color="#e14eca"
                         v-model="row.item.remove"  />
              <label v-if="row.item.status === 'booked'"> &nbsp; Create overlapping slots</label>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col text-center">
          <base-button type="secondary" @click="modals.future_slots = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="activateSchedule('activate', selectedSchedule)">Activate schedule</base-button>
        </div>
      </div>
    </card>
  </modal>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import {BTable} from "bootstrap-vue";
import {formattedDate} from "@/plugins/dateFormatter";
import {Badge, BaseAlert} from '@/components'
import {Dropdown, DropdownItem, DropdownMenu, Progress, Switch} from 'element-ui';
import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ScheduleList",
  components: {
    BTable,
    Badge,
    BaseAlert,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Progress.name]: Progress,
    [Switch.name]: Switch,
  },
  data() {
    return {
      polling: null,
      feature_not_enabled: false,
      all_complete: 1,
      has_insufficient_resource_slots: 0,
      settings: {
        resource_autoblock_enabled: false
      },
      selectedSchedule: '',
      future_slots: [
        {
          status: 'booked', count: 0, remove: false
        },
        {
          status: 'call_to_book', count: 0, remove: false
        },
        {
          status: 'blocked', count: 0, remove: false
        }
      ],
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      activeTabIndex: 0,
      j: 0,
      booking_schedules: [],
      resource_schedules: [],
      fields: ['name', 'start_date', 'end_date', 'schedule_duration', 'active_days', 'assigned_games', 'status', 'actions'],
      games: [],
      modals: {
        clear_schedules: false,
        future_slots: false
      },
      cleared_games: '',
      cleared_status: {
        available: false,
        block: false,
        call_to_book: false
      },
    }
  },
  computed: {
    categories() {
      return [{ name: 'Booking schedules', icon: 'tim-icons icon-calendar-60', active: true },
        { name: 'Resource schedules', icon: 'tim-icons el-icon-user',active: false },
      ];
    },
  },
  methods: {
    async copyScheduleTemplate(item, event){
      event.preventDefault()

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/schedules/' + item.id

      let data = {}

      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          this.$router.push({ path: '/bookings/schedules/edit', query: { id:  response.data }})

        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status === 500){
            swal.fire({
              title: 'Error!',
              text: err.response.data,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Correct error'
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async checkFutureSlots(action, schedule){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dt = new Date()
      let today = dt.getFullYear() + '-' +  ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)
      let gameIds = []
      if(schedule.hasOwnProperty('games')){
        for(let n in JSON.parse(schedule.games)){
          gameIds.push(JSON.parse(schedule.games)[n].id)
        }
      }

      let sd = schedule.start_date ? moment(schedule.start_date, 'YYYY-MM-DD') : moment()
      let ed =  schedule.end_date ? moment(schedule.end_date, 'YYYY-MM-DD') : moment()

      if(schedule.is_recurring === 1){
        sd = moment()
        ed = sd.clone().add(365, 'days');
      }

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/schedules/check-future-slots?today=' + today + '&start_date=' + sd.format('YYYY-MM-DD') +
        `&end_date=` + ed.format('YYYY-MM-DD') +
        '&games=' + gameIds + '&resources=[]'

      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          if(response.data.booked <= 0 && response.data.call_to_book <= 0 && response.data.blocked <= 0){
            this.activateSchedule(action, schedule)
          } else {
            this.selectedSchedule = schedule
            this.modals.future_slots = true
            this.future_slots = [
              {
                status: 'booked', count: response.data.booked, remove: false
              },
              {
                status: 'call_to_book', count: response.data.call_to_book, remove: false
              },
              {
                status: 'blocked', count: response.data.blocked, remove: false
              }
            ]
            this.has_insufficient_resource_slots = response.data.insufficient_resources > 0 ? 1 : 0
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status === 500){
            swal.fire({
              title: 'Error!',
              text: err.response.data,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Correct error'
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    activateSchedulePrompt(action, item, event){
      event.preventDefault()
      let html = ''
      if(item.type === 0 && action === 'deactivate'){
        html = 'Deactivating the schedule will remove all future unbooked slots created by this schedule.'
      }
      else if (item.type === 0 && action === 'activate'){
        html = 'Activating the schedule will create live booking slots for the valid schedule period.'
      }
      else if (item.type === 1 && action === 'deactivate'){
        html = 'Deactivating the schedule will remove all future resource slots created by this schedule.'
      }
      else if (item.type === 1 && action === 'activate'){
        html = 'Activating the schedule will create live resource slots for the valid schedule period.'
      }
      swal.fire({
        title: 'Are you sure?',
        text: html,
        type: 'warning',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          if(action === 'activate'){
            this.checkFutureSlots('activate', item)
          } else {
            this.activateSchedule(action, item);
          }
        }
      })

    },
    async activateSchedule(action, item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/schedules/' + item.id + '/activate'
      let time = new Date()

      let data = {
        active:  action === 'activate' ? 1 : 0,
        remove_call_to_book: this.future_slots[1].remove,
        remove_block: this.future_slots[2].remove,
        create_overlap_on_booked_slots: this.future_slots[0].remove,
        time: time.getFullYear() + '-' + (time.getMonth()+1) + '-' + time.getDate(),
      }

      axios.post(url, data, config)
        .then(response => {
          this.modals.future_slots = false
          let documentClasses = document.body.classList;
          documentClasses.remove('modal-open');
          this.loading = false
          swal.fire({
            title: 'Success!',
            text: `Schedule has been ` + action + `d`,
            type: 'success',
          })
          this.getSchedules()

        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        } else if (err.response.status === 500){
          swal.fire({
            title: 'Error!',
            text: err.response.data,
            type: 'error'
          })
        }
      })
    },
    editSchedule(item, event){
      event.preventDefault()
      this.$router.push({ path: '/bookings/schedules/edit', query: { id:  item.id }})
    },
    createSchedule(){
      this.$router.push('/bookings/schedules/create')
    },
    deleteSchedule(item, event) {
      event.preventDefault()
      swal.fire({
        title: 'Are you sure?',
        text: `The schedule will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.delete(item);
        }
      })
    },
    async delete(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/schedules/' + item.id
      let current = new Date()
      current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()

      this.axios.delete(url, config)
        .then(response => {
          swal.fire({
            title: 'Success!',
            text: `Schedule deleted.`,
            type: 'success',
            confirmButtonColor: '#fd5d93',
            confirmButtonText: 'Ok!'
          })
          this.getSchedules()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async clearSchedules(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/schedules/clear-booking-slots'
      let date = new Date()
      date = date.getFullYear() + '-' +  ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

      let data = {
        games:  this.cleared_games,
        status: this.cleared_status,
        current_date: date
      }

      axios.post(url, data, config)
        .then(response => {
          this.cleared_games = ''
          this.cleared_status = {
            available: false,
            block: false,
            call_to_book: false
          }
          this.modals.clear_schedules = false
          this.loading = false
          swal.fire({
            title: 'Success!',
            text: `Booking slots have been removed.`,
            type: 'success',
          })

        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        }
      })
    },
    clearSchedulesModal(){
      this.modals.clear_schedules = true
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    getActiveDays(item){
      //order slots by id
      let arr = JSON.parse(item.slots)
      arr.sort(function (a,b) {
        if(a.start_day === 0){
          a.start_day = 7
        }
        if(b.start_day === 0){
          b.start_day = 7
        }
        return a.start_day - b.start_day;
      });

      let activeDays = []
      let dupes = [false, false, false, false, false, false, false]
      for(let n in arr){
        if((arr[n].start_day === 1 || arr[n].end_day === 1) && dupes[0] == false){
          activeDays.push('Mon')
          dupes[0] = true
        }
        if((arr[n].start_day === 2 || arr[n].end_day === 2) && dupes[1] == false){
          activeDays.push('Tue')
          dupes[1] = true
        }
        if((arr[n].start_day === 3 || arr[n].end_day === 3) && dupes[2] == false){
          activeDays.push('Wed')
          dupes[2] = true
        }
        if((arr[n].start_day === 4 || arr[n].end_day === 4) && dupes[3] == false){
          activeDays.push('Thu')
          dupes[3] = true
        }
        if((arr[n].start_day === 5 || arr[n].end_day === 5) && dupes[4] == false){
          activeDays.push('Fri')
          dupes[4] = true
        }
        if((arr[n].start_day === 6 || arr[n].end_day === 6) && dupes[5] == false){
          activeDays.push('Sat')
          dupes[5] = true
        }
        if((arr[n].start_day === 0 || arr[n].end_day === 0) && dupes[6] == false){
          activeDays.push('Sun')
          dupes[6] = true
        }
      }
      return activeDays
    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    async getSchedules(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/schedules'
      this.axios.get(url, config)
        .then(response => {
          this.booking_schedules = response.data.schedules
          this.resource_schedules = response.data.resource_schedules
          let not_all_done_bookings = _.find(this.booking_schedules, {record_creation_completed: 0})
          let not_all_done_resources = _.find(this.resource_schedules, {record_creation_completed: 0})

          if(!not_all_done_bookings && !not_all_done_resources){
            clearInterval(this.polling);
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
  },
  mounted(){
    // if(!(_.find(JSON.parse(localStorage.getItem('features')), {id: 21}))){
    //   this.feature_not_enabled = true
    // } else {
    //   this.feature_not_enabled = false
    // }
    this.getSchedules()
    this.polling = setInterval(this.getSchedules, 3000);
    this.getGames()
  }
}
</script>

<style scoped>

</style>
