<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div class="row">
      <div class="col-lg-12 col-12">
        <card>
          <img slot="image" class="card-img-bottom fixed-size-img" :src="game.url"  alt="Card image cap"/>
          <h3 slot="header" class="card-title">{{game.name}}</h3>
          <b-row>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">All time games count</p>
                      <h3 class="card-title">{{allTimeGamesCount}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Completion rate</p>
                      <h3 class="card-title">{{completionRate}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">All time player count</p>
                      <h3 class="card-title">{{getPlayerCount()}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Average group size</p>
                      <h3 class="card-title">{{getAverageGroupSize()}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Average Play Time</p>
                      <h3 v-if="playTimes.average_play_time" class="card-title">{{formatTime(playTimes.average_play_time)}}</h3>
                      <h3 v-else class="card-title">-</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Average hints by game</p>
                      <h3 class="card-title">{{Math.round(hintAverage)}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Number of breakages</p>
                      <h3 class="card-title">{{totalBreakages}}</h3>
                    </slot>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </card>
      </div>
    </div>

    <div class="row" id="groups">
      <div class="col">
        <card>
          <h4 class="card-title">Game data by group size</h4>
          <div class="mt-3" v-if="game_groups.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div class="row mb-2" v-if="game_groups.length > 0">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <b-table  v-if="game_groups.length > 0 && activeTabIndex === 0" :items="game_groups"
                    :fields="['group_size', 'number_of_groups', 'average_completion_time',
                    'slowest_completion', 'fastest_completion', 'average_number_of_hints', 'average_number_of_breaks']">

            <template v-slot:cell(average_completion_time)="row">
              {{formatTime(row.item.average_completion_time)}}
            </template>
            <template v-slot:cell(slowest_completion)="row">
              {{formatTime(row.item.slowest_completion)}}
              <el-tooltip content="Go to game"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-on:click="getGameId(row.item.slowest_completion, row.item.group_size)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
            <template v-slot:cell(fastest_completion)="row">
              {{formatTime(row.item.fastest_completion)}}
              <el-tooltip content="Go to game"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-on:click="getGameId(row.item.fastest_completion, row.item.group_size)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
            <template v-slot:cell(average_number_of_hints)="row">
              {{Math.round(row.item.average_number_of_hints)}}
            </template>
            <template v-slot:cell(average_number_of_breaks)="row">
              {{Math.round(row.item.average_number_of_breaks)}}
            </template>
          </b-table>

          <div v-if="activeTabIndex === 1">
            <div class="row justify-content-end mr-2">

              <div class="col-lg-2">
                <base-input>
                  <el-select
                    v-model="selectedView"
                    class="select-primary"
                    name="group"
                    v-on:change="initBigChart(0, selectedView.id)"
                    placeholder="Data"
                    value-key="id"
                  >
                    <el-option
                      v-for="(option, index) in groupChartOptions"
                      class="select-primary"
                      :label="option.name"
                      :value="{id: option.id, name: option.name}"
                      :key="'O' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="chart-area mt-3">
              <line-chart
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <card>
          <h4 class="card-title">Puzzle data</h4>
          <div class="mt-3" v-if="puzzles.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div class="row mb-2" v-if="puzzles.length > 0">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in puzzleCategories"
                  :key="index + k"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchPuzzleTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <b-table  v-if="puzzles.length > 0 && activePuzzleTabIndex === 0" :items="puzzles"
                    :fields="['puzzle_name', 'average_solve_time', 'highest_solve_time', 'lowest_solve_time',
                      'average_number_of_hints', 'max_number_of_hints_per_game', 'total_number_of_breaks']">
            <template v-slot:cell(puzzle_name)="row">
              <span class="text-primary">{{row.item.puzzle_name}}</span>
            </template>
            <template v-slot:cell(average_solve_time)="row">
              {{formatTime(row.item.average_solve_time)}}
            </template>
            <template v-slot:cell(highest_solve_time)="row">
              {{formatTime(row.item.max_solve_time)}}
              <el-tooltip content="Go to game"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-on:click="getGameIdForPuzzle(row.item.max_solve_time, row.item.puzzle_id)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
            <template v-slot:cell(lowest_solve_time)="row">
              {{formatTime(row.item.min_solve_time)}}
              <el-tooltip content="Go to game"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-on:click="getGameIdForPuzzle(row.item.min_solve_time, row.item.puzzle_id)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
            <template v-slot:cell(average_number_of_hints)="row">
              {{row.item.average_hints.toFixed(1)}}
              <el-tooltip content="View hints given"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-on:click="getHints(row.item)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
            <template v-slot:cell(max_number_of_hints_per_game)="row">
              {{row.item.max_hints.toFixed(1)}}
            </template>
            <template v-slot:cell(total_number_of_breaks)="row">
              {{Math.round(row.item.total_breaks)}}
              <el-tooltip content="View all breaks"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button v-if="Math.round(row.item.total_breaks) > 0" v-on:click="getBreaks(row.item)" icon class="btn btn-link" simple size="sm" type="primary">
                  <i class="tim-icons el-icon-top-right"></i>
                </base-button>
              </el-tooltip>
            </template>
          </b-table>

          <div v-if="activePuzzleTabIndex === 1">
            <div class="row justify-content-end mr-2">

              <div class="col-lg-2">
                <base-input>
                  <el-select
                    v-model="selectedPuzzleView"
                    class="select-primary"
                    name="group"
                    v-on:change="initPuzzleChart(0, selectedPuzzleView.id)"
                    placeholder="Data"
                    value-key="id"
                  >
                    <el-option
                      v-for="(option, index) in puzzleChartOptions"
                      class="select-primary"
                      :label="option.name"
                      :value="{id: option.id, name: option.name}"
                      :key="'P' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="chart-area mt-3">
              <line-chart
                style="height: 100%"
                ref="bigChart"
                :chart-data="puzzleLineChart.chartData"
                :gradient-colors="puzzleLineChart.gradientColors"
                :gradient-stops="puzzleLineChart.gradientStops"
                :extra-options="puzzleLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="modals.view_hints"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Hints for {{selectedPuzzle.puzzle_name}}</h4>

        <div class="row">
          <div class="col">
            <b-table :items="hints" :fields="['hint']" :per-page="perPageHints"
                     :current-page="currentPageHints">
              <template #head(hint)="data">
                <span></span>
              </template>

              <template v-slot:cell(hint)="row">
                {{row.item.hint}}
                <el-tooltip content="Go to game"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button v-on:click="goToGame(row.item.game_instance_id)" icon class="btn btn-link" simple size="sm" type="primary">
                    <i class="tim-icons el-icon-top-right"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageHints"
              :total-rows="totalRowsHints"
              :per-page="perPageHints"
            ></b-pagination>
          </div>
        </div>

        <div class="row text-center mt-3">
          <base-button type="default" @click="modals.view_hints = false">Return</base-button>
        </div>
      </card>
    </modal>

    <modal :show.sync="modals.view_breakages"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Breaks for {{selectedPuzzle.puzzle_name}}</h4>

        <div class="row">
          <div class="col">
            <b-table :items="breaks" :fields="['break']" :per-page="perPageBreaks"
                     :current-page="currentPageBreaks">
              <template #head(break)="data">
                <span></span>
              </template>
              <template v-slot:cell(break)="row">
                {{row.item.break}}
                <el-tooltip content="Go to game"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button v-on:click="goToGame(row.item.game_instance_id)" icon class="btn btn-link" simple size="sm" type="primary">
                    <i class="tim-icons el-icon-top-right"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageBreaks"
              :total-rows="totalRowsBreaks"
              :per-page="perPageBreaks"
            ></b-pagination>
          </div>
        </div>

        <div class="row text-center mt-3">
          <base-button type="default" @click="modals.view_breakages = false">Return</base-button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
    import {Card, Modal} from 'src/components'
    import LineChart from '@/components/Charts/LineChart';
    import {Tooltip, ButtonGroup, Button} from 'element-ui'
    import BarChart from 'src/components/Charts/BarChart';
    import * as chartConfigs from '../../components/Charts/config';
    import config from '@/config';
    import { Auth } from 'aws-amplify'
    import { BFormInput, BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
    import { API_LOCATION } from '../../api-config'
    import BaseAlert from "@/components/BaseAlert";
    import _ from "lodash";
    import swal from "sweetalert2";
    const axios = require('axios')

    let bigChartDatasetOptions = {
      fill: true,
      borderColor: config.colors.primary,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: config.colors.primary,
      pointBorderColor: 'rgba(255,255,255,0)',
      pointHoverBackgroundColor: config.colors.primary,
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4
    }

    export default {
      name: "GameStats",
      components: {
        BaseAlert,
        Card,
        BFormInput,
        BTable,
        BPagination,
        BRow,
        BCol,
        BarChart,
        LineChart,
        Modal,
        [Tooltip.name]: Tooltip,
        [ButtonGroup.name]: ButtonGroup,
        [Button.name]: Button,
      },
      computed:{
        categories() {
          return [{ name: 'Table', icon: 'tim-icons icon-bullet-list-67', active: true },
            { name: 'Chart', icon: 'tim-icons icon-chart-bar-32', active: false }
          ];
        },
        puzzleCategories() {
          return [{ name: 'Table', icon: 'tim-icons icon-bullet-list-67', active: true },
            { name: 'Chart', icon: 'tim-icons icon-chart-bar-32', active: false }
          ];
        },
      },
      data() {
        return {
          j: 0,
          k: 0,
          activeTabIndex: 0,
          activePuzzleTabIndex: 0,
          currentPageBreaks: 1,
          totalRowsBreaks: 0,
          perPageBreaks: 5,
          currentPageHints: 1,
          totalRowsHints: 0,
          perPageHints: 5,
          loading: false,
          game_groups: [],
          hintAverage: '',
          puzzles: [],
          modals: {
            view_breakages: false,
            view_hints: false
          },
          game: '',
          allTimeGamesCount: '',
          totalBreakages: '',
          completionRate: '',
          hints: [],
          breakages: [],
          breakageDescriptions: [],
          selectedPuzzle: '',
          breaks: [],
          averageGroupSize: '',
          playTimes: '',
          selectedView: {id: 0},
          selectedPuzzleView: {id: 0},
          puzzleChartOptions: [
            {
              id: 0,
              name: 'Average solve time (minutes)'
            },
            {
              id: 1,
              name: 'Highest solve time (minutes)'
            },
            {
              id: 2,
              name: 'Lowest solve time (minutes)'
            },
            {
              id: 3,
              name: 'Average number of hints'
            },
            {
              id: 4,
              name: 'Max number of hints'
            },
            {
              id: 5,
              name: 'Total number of breaks'
            }
          ],
          groupChartOptions: [
            {
              id: 0,
              name: 'Number of groups'
            },
            {
              id: 1,
              name: 'Average completion time (minutes)'
            },
            {
              id: 2,
              name: 'Slowest completion (minutes)'
            },
            {
              id: 3,
              name: 'Fastest completion (minutes)'
            },
            {
              id: 4,
              name: 'Average number of hints'
            },
            {
              id: 5,
              name: 'Average number of breaks'
            }
          ],
          bigLineChart: {
            activeIndex: 0,
            chartData: {
              datasets: [{
                ...bigChartDatasetOptions,
                data: [1, 2, 3]
              }],
              labels: ['a', 'b', 'c']
            },
            extraOptions: chartConfigs.purpleChartOptions,
            gradientColors: config.colors.primaryGradient,
            gradientStops: [1, 0.4, 0],
            categories: []
          },
          puzzleLineChart: {
            activeIndex: 0,
            chartData: {
              datasets: [{
                ...bigChartDatasetOptions,
                data: [1, 2, 3]
              }],
              labels: ['a', 'b', 'c']
            },
            extraOptions: chartConfigs.purpleChartOptions,
            gradientColors: config.colors.primaryGradient,
            gradientStops: [1, 0.4, 0],
            categories: []
          },

        }
      },
      methods: {
        initPuzzleChart(index, view){
          let data = []
          let labels = []
          let mins = 0
          for (let n in this.puzzles){

            if(view === 0){
              mins = (parseInt(this.puzzles[n].average_solve_time.split(':')[0]) * 60) + parseInt(this.puzzles[n].average_solve_time.split(':')[1])
              data.push(mins)
            }
            else if (view === 1){
              mins = (parseInt(this.puzzles[n].max_solve_time.split(':')[0]) * 60) + parseInt(this.puzzles[n].max_solve_time.split(':')[1])
              data.push(mins)
            }
            else if (view === 2){
              mins = (parseInt(this.puzzles[n].min_solve_time.split(':')[0]) * 60) + parseInt(this.puzzles[n].min_solve_time.split(':')[1])
              data.push(mins)
            }
            else if (view === 3){
              data.push(this.puzzles[n].average_hints)
            }
            else if (view === 4){
              data.push(this.puzzles[n].max_hints)
            }
            else if (view === 5){
              data.push(this.puzzles[n].total_breaks)
            }
            labels.push(this.puzzles[n].puzzle_name)

          }
          let chartData = {
            datasets: [{
              ...bigChartDatasetOptions,
              data: data
            }],
            labels: labels
          };
          // this.$refs.bigChart.updateGradients(chartData);
          this.puzzleLineChart.chartData = chartData;
          this.puzzleLineChart.activeIndex = index;
        },
        initBigChart(index, view) {
          let data = []
          let labels = []
          let mins = 0
          for (let n in this.game_groups){
            if(view === 0){
              data.push(this.game_groups[n].number_of_groups)
            } else if (view === 1){
              mins = (parseInt(this.game_groups[n].average_completion_time.split(':')[0]) * 60) + parseInt(this.game_groups[n].average_completion_time.split(':')[1])
              data.push(mins)
            }
            else if (view === 2){
              mins = (parseInt(this.game_groups[n].slowest_completion.split(':')[0]) * 60) + parseInt(this.game_groups[n].slowest_completion.split(':')[1])
              data.push(mins)
            }
            else if (view === 3){
              mins = (parseInt(this.game_groups[n].fastest_completion.split(':')[0]) * 60) + parseInt(this.game_groups[n].fastest_completion.split(':')[1])
              data.push(mins)
            }
            else if (view === 4){
              data.push(this.game_groups[n].average_number_of_hints)
            }
            else if (view === 5){
              data.push(this.game_groups[n].average_number_of_breaks)
            }
            labels.push(this.game_groups[n].group_size + ' people')

          }
          let chartData = {
            datasets: [{
              ...bigChartDatasetOptions,
              data: data
            }],
            labels: labels
          };
          // this.$refs.bigChart.updateGradients(chartData);
          this.bigLineChart.chartData = chartData;
          this.bigLineChart.activeIndex = index;
        },
        switchPuzzleTab(index){
          this.activePuzzleTabIndex = index
          for(let n in this.puzzleCategories){
            this.puzzleCategories[n].active = false
          }
          this.puzzleCategories[index].active = true
          this.k++
          if(this.activePuzzleTabIndex === 1){
            this.initPuzzleChart(0, this.selectedPuzzleView.id)
          }
        },
        switchTab(index){
          this.activeTabIndex = index
          for(let n in this.categories){
            this.categories[n].active = false
          }
          this.categories[index].active = true
          this.j++
          if(this.activeTabIndex === 1){
            this.initBigChart(0, this.selectedView.id)
          }
        },
        goToGame(id){
          let documentClasses = document.body.classList;
          documentClasses.remove('modal-open');
          this.$router.push({ path: '/games/history/instance', query: { id: id }})
        },
        getBreaks(item){
          this.selectedPuzzle = item
          this.breaks = JSON.parse(item.breaks)
          _.remove(this.breaks, {
            break: null
          });
          _.remove(this.breaks, {
            break: ''
          });
          this.totalRowsBreaks = this.breaks.length
          this.modals.view_breakages = true
        },
        getHints(item){
          this.selectedPuzzle = item
          this.hints = JSON.parse(item.hints)
          _.remove(this.hints, {
            hint: null
          });
          _.remove(this.hints, {
            hint: ''
          });
          this.totalRowsHints = this.hints.length
          this.modals.view_hints = true
        },
        async getGameIdForPuzzle(solve_time, puzzle_id){
          this.loading = true
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let companyGroupId = JSON.parse(localStorage.getItem('group')).id
          let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId  +
            '/dashboards/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id +
            '?solve_time=' + solve_time + '&puzzle_id=' + puzzle_id
          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.$router.push({ path: '/games/history/instance', query: { id: response.data.id }})
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async getGameId(completion_time, group_size){
          this.loading = true
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let companyGroupId = JSON.parse(localStorage.getItem('group')).id
          let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId  +
            '/dashboards/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id +
            '?completion_time=' + completion_time + '&group_size=' + group_size
          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.$router.push({ path: '/games/history/instance', query: { id: response.data.id }})
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        getPlayerCount(){
          let number_of_people = 0
          for(let n in this.game_groups){
            number_of_people += parseInt(this.game_groups[n].number_of_groups) * parseInt(this.game_groups[n].group_size)
          }
          return number_of_people
        },
        getAverageGroupSize(){
          let number_of_people = 0
          for(let n in this.game_groups){
            number_of_people += parseInt(this.game_groups[n].number_of_groups) * parseInt(this.game_groups[n].group_size)
          }

          if(this.allTimeGamesCount === 0){
            return 0
          }
          return Math.round(number_of_people / parseInt(this.allTimeGamesCount))
        },
        getJSON(item){
          return JSON.parse(item)
        },
        formatTime(time){
          if(!time || time === undefined){
            return
          }
          return time.split('.')[0]
        },
        getTime(time){
          let hour = parseInt(time.split(':')[0])
          let minute = parseInt(time.split(':')[1])
          return parseInt((hour * 60) + minute)
        },
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        async getGameStats(){
          this.loading = true
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let companyGroupId = JSON.parse(localStorage.getItem('group')).id
          let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId  +
            '/dashboards/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id
          axios.get(url, config)
            .then(response => {

              //Upper tiles
              this.allTimeGamesCount = response.data.allTimeGamesCount

              this.game = response.data.game

              //Breakages
              this.totalBreakages = response.data.breakageTotal
              this.breakages = response.data.breakageCount

              //Completion rate
              this.completionRate = response.data.escapeRate.toFixed(0) + '%'

              //Hints
              this.hints = response.data.hintCount

              //Group
              this.averageGroupSize = Math.round(response.data.averageGroupSize, 0)

              //Game groups
              this.game_groups = response.data.game_groups
              this.hintAverage = response.data.hintAverage
              this.puzzles = response.data.puzzles


              //Average play time
              this.playTimes = response.data.playTimes

              this.loading = false

            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        }
      },
      mounted(){
        this.getGameStats()

      },
    }
</script>

<style scoped>
  .fixed-size-img {
    width: 100%; /* width of container */
    height: 100px; /* height of container */
    object-fit: cover;
  }
  #loading {
    min-height: 1000px;
    display: flex;
    align-items: center;
  }

  .el-loading-spinner .path {
    stroke: #6aff8d !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .stats-margin {
    margin-bottom: -10%;
  }

</style>
