<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <h5 class="info-text">
      Add general information about the game
    </h5>
    <div class="row justify-content-center mt-5">
      <div class="col-11 col-lg-7 col-md-8 col-sm-7">
        <base-input label="Name"
                    v-model="model.name"
                    name="name"
                    v-validate="modelValidations.name"
                    placeholder="Game name"
                    :error="getError('name')">
        </base-input>
      </div>
      <div class="col-11 col-lg-7 col-md-8 col-sm-7">
        <label>Description</label>
        <div class="row mb-3">
          <div class="col-12">
            <editor v-model="model.description" style="background-color: #2bffc6"
                    api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                    :init="{
                     height: 200,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent '
                   }"
            />
          </div>
        </div>
        <div class="row" >
          <div class="col-lg-4">
            <label>Minimum number of players</label>
            <div class="row">
              <div class="col">
                <base-input v-validate="modelValidations.min">
                  <el-input-number size="small" v-model="model.min_players_count" :min="1" :max="model.max_players_count"/>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Maximum number of players</label>
            <div class="row">
              <div class="col">
                <base-input v-validate="modelValidations.max">
                  <el-input-number size="small" v-model="model.max_players_count" :min="model.min_players_count" />
                </base-input>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <base-input label="Duration (minutes)"
                        placeholder="60"
                        v-validate="modelValidations.duration"
                        name="duration"
                        :error="getError('duration')">
              <el-input-number v-model="model.duration_minutes" :controls="false" :min="0" />
            </base-input>
          </div>
        </div>

        <div class="row mt-2" >
          <div class="col">
            <label>Number of resources required to run a single game</label>
            <div class="row">
              <div class="col-lg-4">
                <base-input>
                  <el-input-number size="small" v-model="model.required_resource_count" :min="1" />
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-11 col-lg-7 col-md-8 col-sm-7">
        <div class="row mt-2">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="model.block_concurrent_events_when_booked"  />
            <label> &nbsp; Block concurrent events when booked</label>
          </div>
        </div>
      </div>

      <div class="col-11 col-lg-7 col-md-8 col-sm-7">
        <div class="row mt-2 mb-3">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="model.include_game_difficulty"  />
            <label> &nbsp; Show game difficulty on booking site</label>
          </div>
        </div>
        <div class="row mt-2" v-if="model.include_game_difficulty">
          <div class="col-lg-3">
            <base-input label="Game difficulty">
              <b-form-rating size="lg" show-clear style="cursor: pointer"
                             :icon-empty="model.difficulty_icon"
                             :icon-full="model.difficulty_icon + '-fill'"
                             :color="model.difficulty_color"
                             no-border
                             v-model="model.difficulty_level">

              </b-form-rating>&nbsp;
            </base-input>
          </div>
          <div class="col-lg-3">
            <base-input label="Difficulty label" v-model="model.difficulty_label">
            </base-input>
          </div>
          <div class="col-lg-3">
            <base-input label="Difficulty icon">
              <el-select
                v-model="model.difficulty_icon"
                class="select-primary"
                name="email_group"
              >
                <el-option
                  v-for="(icon, index) in icon_options"
                  class="select-primary"
                  :label="icon"
                  :value="icon"
                  :key="'I' + index"
                >
                      <span style="float: left">
                         <b-icon :icon="icon" scale="1" class="mr-1" color="#e14eca"></b-icon>
                        <!--                        <i :class="'text-primary tim-icons ' + icon "></i>-->
                        {{ capitalizeFirstLetter(icon) }}</span>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-3">
            <base-input label="Difficulty icon color">
              <el-color-picker  v-model="model.difficulty_color" show-alpha></el-color-picker>
            </base-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import {Select, Option, ColorPicker, Switch} from 'element-ui';
  import { ImageUpload } from 'src/components';
  import swal from "sweetalert2";
  const axios = require('axios')
  import Editor from '@tinymce/tinymce-vue'
  import { BFormRating } from 'bootstrap-vue'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [ColorPicker.name]: ColorPicker,
      [Switch.name]: Switch,
      ImageUpload,
      'editor': Editor,
      BFormRating
    },
    data() {
      return {
        loading: false,
        model: {
          name: '',
          min_players_count : 1,
          max_players_count: 10,
          duration_minutes: '',
          difficulty_level: 0,
          difficulty_icon: 'star',
          difficulty_color: '#e14eca',
          difficulty_label: 'Difficulty',
          include_game_difficulty: true,
          company_group_id: JSON.parse(localStorage.getItem('group')).id,
          pricing: [],
          description: '',
          img: '',
          puzzles: [],
          required_resource_count: 1,
          block_concurrent_events_when_booked: false
        },
        uploadUrl: '',
        groups: '',
        difficulty: 0,
        modelValidations: {
          name: {
            required: true
          },
          group: "",
          min: {
            numeric: true,
            min_value: 1
          },
          max: {
            numeric: true,
          },
          duration: {
            numeric: true,
          },
          difficulty: {
            required: false,
            min_value: 1
          },
        }
      };
    },
    computed: {
      icon_options(){
        let arr = ['star', 'heart', 'alarm', 'award', 'bag', 'bell', 'diamond', 'dice3', 'dice5', 'droplet', 'egg', 'pencil',
          'emoji-smile', 'emoji-heart-eyes', 'emoji-sunglasses', 'envelope', 'exclamation-triangle', 'eye', 'gift', 'gear', 'hand-thumbs-up',
          'book', 'bookmark', 'bug', 'calculator', 'clock', 'cloud', 'cpu', 'handbag', 'hexagon', 'lightning', 'lock',
          'camera', 'camera-reels', 'chat', 'check-circle', 'circle', 'plug', 'puzzle', 'question-circle', 'shield-lock', 'signpost',
          'suit-club', 'suit-diamond', 'suit-heart', 'suit-spade', 'stopwatch', 'tag', 'telephone', 'tree', 'trophy', 'unlock',
        ]
        return arr.sort()
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      clearRate(item){
        if(item === this.difficulty){
          item = 0
          this.model.difficulty_level = 0
          this.difficulty = 0
        } else {
          this.difficulty = item
        }
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGroups() {
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/groups'

        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.groups = response.data
          })
          .catch(err => {
            this.loading = false
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })

      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async getSignedURL (file) {
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'upload'

        let payload = {
          filePath: file.name,
          contentType: file.type
        }

        return new Promise ( function(resolve) {
          axios.post(url, payload, config)
            .then((res) => {
              resolve(res.data)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async onFileChange(file) {
        this.model.file = file
        if(file != null){
          this.uploadUrl = await this.getSignedURL(file)
          let uploaded = await this.uploadFile(file)
          //Get the image URL
          let imgUrl = this.uploadUrl.split("?")
          this.model.img = imgUrl[0]
        } else {
          this.model.img = 'https://console.offthecouch.io/img/placeholder.jpg'
        }
      },
      uploadFile (file) {
        let url = this.uploadUrl
        let config = { headers: {
            'Content-Type': file.type
          }}

        let payload = file
        return new Promise ( function(resolve) {
          axios.put(url, payload, config)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model);
          return res;
        });
      }
    },
    mounted () {
      this.getGroups()
    }
  };
</script>

<style scoped>
</style>
