<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a :href="'/photos/settings?id=' + $route.query.id">Photo settings</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{ breadcrumb_name }}</span>
      </breadcrumb-item>
    </breadcrumb>

    <!--    Fonts-->
    <div v-for="(f, i) in fonts" :key="'C' + i">
      <link rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + f.family">
    </div>
    <div class="row">
      <div class="col-lg-8">
        <card>
          <h4 v-if="mode === 0" class="card-title">Create new overlay</h4>
          <h4 v-else class="card-title">Edit overlay</h4>
          <div class="row">
            <div class="col-lg-6">
              <base-input label="Name of overlay" :error="errs.overlay_name_error" placeholder="Name" v-model="overlay_name"></base-input>
            </div>
            <div class="col-lg-6">
              <base-input label="Aspect ratio">
                <el-select
                  v-model="selectedAspectRatio"
                  class="select-primary"
                  placeholder="Aspect ratio"
                  value-key="label"
                  @change="updateCanvasSize"
                >
                  <el-option
                    v-for="(ar, i) in aspect_ratios"
                    class="select-primary"
                    :label="ar.label"
                    :value="{id: ar.id, label: ar.label}"
                    :key="'AR' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input label="Description">
                <el-input
                  v-model="description"
                  :rows="3"
                  type="textarea"
                  placeholder="Description..."
                />
              </base-input>
            </div>
          </div>
<!--          <input type="file" @change="uploadImage" />-->
<!--          <button @click="addTextField">Add Text Field</button>-->
<!--          <button @click="saveImage">Save Image</button>-->
          <div class="row mt-3">
            <div class="col">
              <base-button type="primary" size="sm" @click="addTextField">
                <i class="tim-icons el-icon-plus"></i> Add text field</base-button>
              <span class="btn btn-primary btn-sm btn-file ml-2"><i class="tim-icons icon-upload"></i> Upload image
                <input
                  accept="image/*"
                  @change="uploadImage"
                  type="file"
                  name="..."
                  class="valid"
                  :multiple="false"
                  aria-invalid="false"
                />
              </span>
            </div>
          </div>
          <div class="mt-3" v-if="layers.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Add some elements to your overlay.</h4>
            </div>
          </div>

          <div class="d-none d-sm-block">
            <div class="row" v-if="layers.length > 0">
              <div class="col">
                <b-table striped hover :items="layers"
                         :fields="fields">
                  <template v-slot:cell(element)="row">
                    <div v-if="row.item.type === 'text'">
                      <div class="row">
                        <div class="col">
                          <base-input label="Custom text" v-on:input="updateCanvas" v-model="row.item.content"></base-input>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      {{row.item.file_name}}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2" href="#" @click="pushToFront(row.item, $event)">
                            <i class="tim-icons  el-icon-top mr-2 text-default"></i> Push to front
                          </a>
                          <a class="dropdown-item mb-2" href="#" @click="pushToBack(row.item, $event)">
                            <i class="tim-icons el-icon-bottom mr-2 text-default"></i> Push to bottom
                          </a>
                          <hr>
                          <a class="dropdown-item mb-2" href="#" @click="deleteTextField(row.index, $event)">
                            <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Delete element
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                  <template v-slot:cell(appearance)="row">
                    <div class="row" v-if="row.item.type === 'text'">
                      <div class="col-lg-4">
                        <base-input class="text-danger" label="Font family">
                          <el-select
                            v-model="row.item.font"
                            class="select-primary"
                            :style="{'font-family': row.item.font}"
                            name="font"
                            placeholder="Font"
                            v-on:change="updateCanvas"
                          >
                            <el-option
                              v-for="(f, i) in orderedFonts" :key="'B' + i"
                              :style="{'font-family': f.family}"
                              class="select-primary"
                              :label="f.family"
                              :value="f.family"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="col-lg-3">
                        <base-input label="Font size" v-on:change="updateCanvas" placeholder="5" v-model.number="row.item.fontSize"></base-input>
                      </div>
                      <div class="col-lg-2 mt-4">
                        <el-color-picker v-on:input="updateCanvas" v-model="row.item.color" show-alpha></el-color-picker>
                      </div>
                    </div>

                    <div class="row" v-else>
<!--                      <div class="col-lg-3">-->
<!--                        <base-input label="Image fit">-->
<!--                          <el-select-->
<!--                            v-model="row.item.object_fit"-->
<!--                            class="select-primary"-->
<!--                            v-on:change="drawImageFit(row.item.content, row.item.object_fit, canvasWidth, canvasHeight, row.item.file_name, row.item, 1)"-->
<!--                          >-->
<!--                            <el-option-->
<!--                              v-for="option in fitOptions"-->
<!--                              class="select-primary"-->
<!--                              :label="option"-->
<!--                              :value="option"-->
<!--                              :key="option"-->
<!--                            ></el-option>-->
<!--                          </el-select>-->
<!--                        </base-input>-->
<!--                      </div>-->
<!--                      <div class="col-lg-5">-->
<!--                        <label>Image size</label>-->
<!--                        <el-slider :show-tooltip="false"	:max="canvasWidth" v-model.number="row.item.width" v-on:change="drawImageFit(row.item.content, row.item.object_fit,-->
<!--                                    row.item.width, (row.item.height) * (row.item.width/(row.item.height)),-->
<!--                                    row.item.file_name, row.item)"></el-slider>-->
<!--                      </div>-->
                      <div class="col-lg-6">
                        <base-input label="Image width" v-model.number="row.item.width"
                                    v-on:change="drawImageFit(row.item.content, row.item.object_fit, row.item.width, row.item.height, row.item.file_name, row.item)"
                                    placeholder="5" ></base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input label="Image height"
                                    v-on:change="drawImageFit(row.item.content, row.item.object_fit,
                                    row.item.width, row.item.height, row.item.file_name, row.item)"
                                    placeholder="5" v-model.number="row.item.height"></base-input>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="d-block d-sm-none">
            <div class="row" v-if="layers.length > 0">
              <div class="col">
                <b-table striped hover :items="layers"
                         :fields="['elements']">
                  <template v-slot:cell(elements)="row">
                    <div v-if="row.item.type === 'text'">
                      <div class="row">
                        <div class="col-8">
                          <base-input label="Custom text" v-on:input="updateCanvas" v-model="row.item.content"></base-input>
                        </div>
                        <div class="col text-right">
                          <el-dropdown>
                            <base-button
                              link
                              type="primary"
                              size="sm"
                            > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                            </base-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a class="dropdown-item mb-2" href="#" @click="pushToFront(row.item, $event)">
                                  <i class="tim-icons  el-icon-top mr-2 text-default"></i> Push to front
                                </a>
                                <a class="dropdown-item mb-2" href="#" @click="pushToBack(row.item, $event)">
                                  <i class="tim-icons el-icon-bottom mr-2 text-default"></i> Push to bottom
                                </a>
                                <hr>
                                <a class="dropdown-item mb-2" href="#" @click="deleteTextField(row.index, $event)">
                                  <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Delete element
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row">
                        <div class="col-8">
                          {{row.item.file_name}}
                        </div>
                        <div class="col text-right">
                          <el-dropdown>
                            <base-button
                              link
                              type="primary"
                              size="sm"
                            > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                            </base-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a class="dropdown-item mb-2" href="#" @click="pushToFront(row.item, $event)">
                                  <i class="tim-icons  el-icon-top mr-2 text-default"></i> Push to front
                                </a>
                                <a class="dropdown-item mb-2" href="#" @click="pushToBack(row.item, $event)">
                                  <i class="tim-icons el-icon-bottom mr-2 text-default"></i> Push to bottom
                                </a>
                                <hr>
                                <a class="dropdown-item mb-2" href="#" @click="deleteTextField(row.index, $event)">
                                  <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Delete element
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="row.item.type === 'text'">
                      <div class="col-lg-4">
                        <base-input class="text-danger" label="Font family">
                          <el-select
                            v-model="row.item.font"
                            class="select-primary"
                            :style="{'font-family': row.item.font}"
                            name="font"
                            placeholder="Font"
                            v-on:change="updateCanvas"
                          >
                            <el-option
                              v-for="(f, i) in orderedFonts" :key="'B' + i"
                              :style="{'font-family': f.family}"
                              class="select-primary"
                              :label="f.family"
                              :value="f.family"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="col-lg-3 col-6">
                        <base-input label="Font size" v-on:input="updateCanvas" placeholder="5" v-model.number="row.item.fontSize"></base-input>
                      </div>
                      <div class="col-lg-2 mt-4 col-6">
                        <el-color-picker v-on:input="updateCanvas" v-model="row.item.color" show-alpha></el-color-picker>
                      </div>
                    </div>
                    <div class="row" v-else>
<!--                      <div class="col-lg-3">-->
<!--                        <base-input label="Image fit">-->
<!--                          <el-select-->
<!--                            v-model="row.item.object_fit"-->
<!--                            class="select-primary"-->
<!--                            v-on:change="drawImageFit(row.item.content, row.item.object_fit, row.item.width, row.item.height, row.item.file_name, row.item)"-->
<!--                          >-->
<!--                            <el-option-->
<!--                              v-for="option in fitOptions"-->
<!--                              class="select-primary"-->
<!--                              :label="option"-->
<!--                              :value="option"-->
<!--                              :key="option"-->
<!--                            ></el-option>-->
<!--                          </el-select>-->
<!--                        </base-input>-->
<!--                      </div>-->
<!--                      <div class="col-lg-5">-->
<!--                        <label>Image size</label>-->
<!--                        <el-slider :show-tooltip="false"	:max="canvasWidth" v-model.number="row.item.width" v-on:change="drawImageFit(row.item.content, row.item.object_fit,-->
<!--                                    row.item.width, (row.item.height) * (row.item.width/(row.item.height)),-->
<!--                                    row.item.file_name, row.item)"></el-slider>-->
<!--                      </div>-->

                      <div class="col-lg-6">
                        <base-input label="Image width" v-model.number="row.item.width"
                                    v-on:change="drawImageFit(row.item.content, row.item.object_fit, row.item.width, row.item.height, row.item.file_name, row.item)"
                                    placeholder="5" ></base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input label="Image height"
                                    v-on:change="drawImageFit(row.item.content, row.item.object_fit,
                                    row.item.width, row.item.height, row.item.file_name, row.item)"
                                    placeholder="5" v-model.number="row.item.height"></base-input>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-button size="sm" class="hover-link" link style="padding-left: 0px; padding-top: 0px"
                           v-on:click="show_variables = !show_variables" simple type="primary">
                Supported dynamic variables
                <i v-if="!show_variables" class="tim-icons icon-minimal-down"></i>
                <i v-if="show_variables" class="tim-icons icon-minimal-up"></i>
              </base-button>
            </div>
          </div>

          <div class="d-none d-sm-block">
            <div class="row" v-if="show_variables">
              <div class="col">
                <b-table striped hover :items="dynamic_variables"
                         :fields="['name', 'description']">
                  <template v-slot:cell(name)="row">
                    <div class="row">
                      <div class="col">
                        <span style="display: inline"> {{row.item.name}}</span>
                        <base-button style="display: inline"
                                     @click.native="copy(row.item.name)"
                                     class="like btn-link text-white"
                                     size="sm"
                                     icon
                        >
                          <i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </div>
                    </div>

                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <div class="row" v-if="show_variables">
              <div class="col">
                <b-table striped hover :items="dynamic_variables"
                         :fields="['variables']">
                  <template v-slot:cell(variables)="row">
                    <div class="row">
                      <div class="col">
                        <span style="display: inline"> {{row.item.name}}</span>
                        <base-button style="display: inline"
                                     @click.native="copy(row.item.name)"
                                     class="like btn-link text-white"
                                     size="sm"
                                     icon
                        >
                          <i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </div>
                    </div>
                    <small>{{row.item.description}}</small>

                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <base-button type="default" class="mr-2" @click="returnToSettings()">Cancel</base-button>
              <base-button v-if="mode === 0" type="primary"  @click="saveOverlay()">Create overlay</base-button>
              <base-button v-else type="primary"  @click="updateOverlay()">Save overlay</base-button>
            </div>
          </div>

        </card>

      </div>
      <div class="col-lg-4" >
        <card style="word-break: break-all">
          <h4 class="card-title">Preview</h4>
          <div class="row justify-content-center" >
            <div class="col text-center">
              <div ref="editorContainer" style="word-break: break-all">
                <canvas ref="canvas" style=" cursor: move; word-break: break-all; border: 1px solid white"
                        @mousedown="handleMouseDown"
                        @mousemove="handleMouseMove"
                        @mouseup="handleMouseUp"
                        @mouseleave="handleMouseUp"
                >
                  Your browser does not support the HTML5 canvas tag.
                </canvas>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {BTable} from "bootstrap-vue";
import {ColorPicker, Dropdown, DropdownItem, DropdownMenu, Slider} from "element-ui";
import _ from "lodash";
import {API_LOCATION, CDN_URL} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
export default {
  data() {
    return {
      breadcrumb_name: '',
      mode: 0,
      uploadUrl: '',
      show_variables: false,
      dynamic_variables: [
        {id: 0, name: '[Game Name]', description: 'Name of the game associated with the photo.'},
        {id: 1, name: '[Team Name]', description: 'Name of the team if specified. Requires the photo to be tied to a game tracker form.'},
        {id: 2, name: '[Company Name]', description: 'The name of your company.'},
        {id: 3, name: '[Location Name]', description: 'The name of the location where photo was taken.'},
        {id: 4, name: '[Completion Time]', description: 'Recorded time of event completion. Requires the photo to be tied to a game tracker form.'},
        {id: 5, name: '[Time Remaining]', description: 'Remaining time of the total game duration. Requires the photo to be tied to a game tracker form.'},
        {id: 6, name: '[Number Of Hints]', description: 'The number of hints provided during the game. Requires the photo to be tied to a game tracker form.'},
      ],
      loading: false,
      layers: [],
      selectedLayer: null,
      canvas: null,
      isDragging: false,
      dragOffsetX: 0,
      dragOffsetY: 0,
      canvasWidth: 600,
      canvasHeight: 600,
      fields: ['element', 'appearance', 'actions'],
      errs: {
        overlay_name_error: ''
      },
      overlay_name: '',
      description: '',
      reference_width: '',
      reference_height: '',
      fonts: [],
      updatePending: false,
      selectedAspectRatio: {
        id: 0,
        label: '1:1'
      },
      fitOptions: ['cover', 'fill', 'contain'],
      aspect_ratios: [
        {
          id: 0,
          label: '1:1'
        },
        {
          id: 1,
          label: '2:1'
        },
        {
          id: 2,
          label: '3:2'
        },
        {
          id: 3,
          label: '4:3'
        },
        {
          id: 4,
          label: '9:16'
        },
        {
          id: 5,
          label: '16:9'
        },
        {
          id: 6,
          label: '20:9'
        }
      ],
    };
  },
  computed: {
    orderedFonts: function () {
      _.remove(this.fonts, {
        family: 'Libre Baskerville'
      });
      _.remove(this.fonts, {
        family: 'Material Icons'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Outlined'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Sharp'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Round'
      });
      _.remove(this.fonts, {
        family: 'M PLUS Rounded 1c'
      });
      return _.orderBy(this.fonts, 'family')
    }
  },
  components: {
    BTable,
    [ColorPicker.name]: ColorPicker,
    BreadcrumbItem,
    Breadcrumb,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Slider.name]: Slider,
  },
  methods: {
    pushToFront(item, event){
      event.preventDefault();
      if(item.position >= this.layers.length - 1){
        return
      }
      let oldPosition = item.position

      for(let n in this.layers){
        if(this.layers[n].position === (oldPosition + 1)){
          this.layers[n].position = item.position
        }
      }
      item.position = oldPosition + 1
      this.updateCanvas()
    },
    makeLayersSequential(){
      let layers = _.orderBy(this.layers, "position")
      for(let n in layers){
        this.layers[n].position = Number(n)
      }
    },
    pushToBack(item, event){
      event.preventDefault();
      if(item.position <= 0){
        return
      }
      let oldPosition = item.position

      for(let n in this.layers){
        if(this.layers[n].position === (oldPosition - 1)){
          this.layers[n].position = item.position
        }
      }
      item.position = oldPosition - 1
      this.updateCanvas()
    },
    returnToSettings(){
      this.$router.push({ path: '/photos/settings', query: { id: this.$route.query.id }})
    },
    copy(text){
      navigator.clipboard.writeText(text);
    },
    deleteTextField(index, event){
      event.preventDefault();
      this.layers.splice(index, 1)
      this.updateCanvas()
    },
    updateCanvasSize() {
      const parentWidth = this.$refs.editorContainer.clientWidth; // Get the width of the container
      const aspectRatio = this.selectedAspectRatio.label.split(':');
      const widthRatio = parseInt(aspectRatio[0], 10);
      const heightRatio = parseInt(aspectRatio[1], 10);

      // Assuming a base width of 800, calculate height based on aspect ratio
      if(this.selectedAspectRatio.id === 4){
        this.canvasHeight = parentWidth;
        this.canvasWidth = (parentWidth * widthRatio) / heightRatio;
      } else {
        this.canvasWidth = parentWidth;
        this.canvasHeight = (parentWidth * heightRatio) / widthRatio;
      }

      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;

      this.updateCanvas(); // Re-render the canvas to apply new dimensions
    },
    drawImageFit(img, fitType, canvas_width, canvas_height, file_name, layer, fit_changed) {
      // const hRatio = canvas_width / img.width;
      // const vRatio = canvas_height / img.height;
      // let dx, dy, dWidth, dHeight, ratio;

      // switch (fitType) {
      //   case 'contain':
      //     // 'contain' ensures the image fits within the canvas while maintaining aspect ratio
      //     ratio = Math.min(hRatio, vRatio); // Maintain aspect ratio and fit inside canvas
      //     dWidth = img.width * ratio;
      //     dHeight = img.height * ratio;
      //
      //     // Ensure dWidth and dHeight do not exceed canvas boundaries
      //     if (dWidth > canvas_width) {
      //       dWidth = canvas_width;
      //     }
      //     if (dHeight > canvas_height) {
      //       dHeight = canvas_height;
      //     }
      //
      //     // Center the image within the canvas
      //     dx = (canvas_width - dWidth) / 2;
      //     dy = (canvas_height - dHeight) / 2;
      //     break;
      //
      //   case 'cover':
      //     // 'cover' fills the canvas and might overflow, but maintain aspect ratio
      //     ratio = Math.max(hRatio, vRatio); // Maintain aspect ratio and cover entire canvas
      //     dWidth = img.width * ratio;
      //     dHeight = img.height * ratio;
      //
      //     // Ensure dWidth and dHeight do not exceed canvas boundaries
      //     // if (dWidth > canvas_width) {
      //     //   dWidth = canvas_width;
      //     // }
      //     // if (dHeight > canvas_height) {
      //     //   dHeight = canvas_height;
      //     // }
      //
      //     // Center the image within the canvas
      //     dx = (canvas_width - dWidth) / 2;
      //     dy = (canvas_height - dHeight) / 2;
      //     if(fit_changed){
      //       dx = 0;
      //       dy = 0;
      //     }
      //     break;
      //
      //   case 'fill':
      //     // 'fill' stretches the image to fill the entire canvas
      //     dx = 0;
      //     dy = 0;
      //     dWidth = canvas_width;
      //     dHeight = canvas_height;
      //     break;
      //
      //   case 'none':
      //     // 'none' keeps the image at its original dimensions
      //     dx = (canvas_width - img.width) / 2;
      //     dy = (canvas_height - img.height) / 2;
      //
      //     dWidth = img.width;
      //     dHeight = img.height;
      //
      //     // Ensure the image does not exceed the canvas boundaries
      //     if (dWidth > canvas_width) {
      //       dWidth = canvas_width;
      //     }
      //     if (dHeight > canvas_height) {
      //       dHeight = canvas_height;
      //     }
      //
      //     break;
      //
      //   default:
      //     console.error('Unsupported fit type: ' + fitType);
      //     return;
      // }
      console.log(layer)
      if(layer){
        // layer.x = dx
        // layer.y = dy
        // layer.width = dWidth
        // layer.height = dHeight
        // layer.object_fit = fitType
      } else {
        this.layers.push({
          type: 'image',
          content: img,
          position: this.layers.length,
          file_name: file_name,
          object_fit: fitType,
          color: '#000000',
          font: 'Poppins',
          fontSize: '20',
          id: 1234,
          url: this.uploadUrl.split("?")[0],
          x: 0,
          y: 0,
          width: canvas_width,
          height: canvas_height
        });
      }
      // _.throttle(() => {
      //   this.updateCanvas('event');
      // }, 100);
      this.updateCanvas('event');
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload/photos?bucket=1'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl

      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getUrlKey(url) {
      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split('/');
      return pathSegments[1]; // Return the second element, which is the first path parameter
    },
    async uploadImage(event) {
      const file_name = event.target.files[0].name
      const file = event.target.files[0]
      this.loading = true
      this.uploadUrl = await this.getSignedURL(file)
      let uploaded = await this.uploadFile(file)
      let imgUrl = CDN_URL + this.getUrlKey(this.uploadUrl)
      this.loading = false
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = imgUrl

      img.onload  = () => {
        this.drawImageFit(img, 'fill', this.canvasWidth, this.canvasHeight, file_name);
      };

    },
    addTextField() {
      const ctx = this.canvas.getContext('2d');

      // Set the font and font size on the canvas context
      ctx.font = `${20}px Poppins`; // Default font size and family for the new text

      // Measure the text width
      const textMetrics = ctx.measureText('New Text');
      const textWidth = textMetrics.width; // This is the actual width of the text
      const textHeight = 20; // Approximate text height (font size can be used)

      const newText = {
        type: 'text',
        position: this.layers.length,
        content: 'New Text',
        x: 100,
        y: 100,
        fontSize: 20,
        font: 'Poppins',
        color: '#000000',
        object_fit: 'cover',
        file_name: '',
        width: textWidth,
        height: textHeight
      };
      this.layers.push(newText);
      this.selectedLayer = newText;
      this.updateCanvas();
    },
    async updateCanvas(event) {
      if (this.updatePending) return;
      this.updatePending = true;

      window.requestAnimationFrame(() => {
        this.renderLayers(event);
        this.updatePending = false;  // Reset the flag
      });
    },
    async renderLayers(source) {
      const ctx = this.canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      let layers = _.orderBy(this.layers, "position")
      let heightRatio = 1
      let widthRatio = 1
      if(!source){
        if(this.reference_height && this.reference_width){
          heightRatio = this.canvasHeight / this.reference_height;
          widthRatio = this.canvasWidth / this.reference_width;
        }
      }

      for (const layer of layers) {

        layer.fontSize = Number(layer.fontSize)
        layer.reference_width = layer.width * widthRatio
        layer.reference_height = layer.height * heightRatio
        layer.reference_x = layer.x * widthRatio
        layer.reference_y = layer.y * heightRatio
        if (layer.type === 'image') {
          if(typeof layer.content === "string"){
            await this.loadImage(layer);
            ctx.drawImage(layer.content, layer.x * widthRatio, layer.y * heightRatio, layer.width * widthRatio, layer.height * heightRatio);
          } else {
            ctx.drawImage(layer.content, layer.x * widthRatio, layer.y * heightRatio, layer.width * widthRatio, layer.height * heightRatio);
          }
        } else if (layer.type === 'text') {
          ctx.font = `${layer.fontSize * widthRatio}px ${layer.font}`;

          // Measure the text width
          let textMetrics = ctx.measureText(layer.content);
          let textWidth = textMetrics.width; // This is the actual width of the text
          layer.reference_width = textWidth
          layer.reference_height = layer.fontSize * widthRatio
          layer.reference_font_size = layer.fontSize * widthRatio
          ctx.fillStyle = layer.color;
          ctx.textBaseline = 'top';
          ctx.fillText(layer.content, layer.x * widthRatio, layer.y * heightRatio); // Adjust for baseline
        }
      }
    },
    loadImage(layer) {
      return new Promise((resolve) => {
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = layer.url;
        img.onload = () => {
          layer.content = img;
          resolve();
        };
      });
    },
    saveImage() {
      this.canvas.toBlob(function(blob) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'canvas-image.png';
        link.href = url;
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/png');
    },
    handleMouseDown(event) {
      const x = event.offsetX;
      const y = event.offsetY;
      this.selectedLayer = null;

      for (let i = this.layers.length - 1; i >= 0; i--) {
        const layer = this.layers[i];
        if (x >= layer.reference_x &&
          x <= layer.reference_x + layer.reference_width &&
          y >= layer.reference_y &&
          y <= layer.reference_y + layer.reference_height) {
          this.selectedLayer = layer;
          this.dragOffsetX = x - layer.reference_x;
          this.dragOffsetY = y - layer.reference_y;
          this.isDragging = true;
          return;
        }
      }
    },
    handleMouseMove(event) {
      if (!this.isDragging || !this.selectedLayer) return;
       this.selectedLayer.x = event.offsetX - this.dragOffsetX;
       this.selectedLayer.y = event.offsetY - this.dragOffsetY;
      this.updateCanvas('event');
    },
    handleMouseUp() {
      this.isDragging = false;
      this.selectedLayer = null;
    },
    getFonts(){
      let url = API_LOCATION + 'fonts'

      axios.get(url)
        .then(response => {
          this.fonts = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updateOverlay(){
      let error = 0
      if(this.overlay_name === ""){
        this.errs.overlay_name_error = "Overlay name cannot be empty"
        error++
      } else {
        this.errs.overlay_name_error = ""
      }

      if(error > 0){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let photoSettingsId = this.$route.query.id
      let overlayId = this.$route.query.oid
      let url = API_LOCATION + 'companies/' + companyId + '/photo-settings/' + photoSettingsId + '/overlays/' + overlayId

      for(let n in this.layers){
        if(this.layers[n].type === 'image'){
          this.layers[n].url = CDN_URL + this.getUrlKey(this.layers[n].url)
          this.layers[n].content = this.layers[n].url
        }
        this.layers[n].width = this.layers[n].reference_width
        this.layers[n].height = this.layers[n].reference_height
        this.layers[n].x = this.layers[n].reference_x
        this.layers[n].y = this.layers[n].reference_y
        this.layers[n].fontSize = this.layers[n].reference_font_size ? this.layers[n].reference_font_size : 20
      }

      let data = {
        name: this.overlay_name,
        layers: this.layers,
        aspect_ratio: this.selectedAspectRatio.label,
        description: this.description,
        reference_height: this.canvas.height,
        reference_width: this.canvas.width
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Overlay updated', 'success')
          this.getOverlay()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveOverlay(){
      let error = 0
      if(this.overlay_name === ""){
        this.errs.overlay_name_error = "Overlay name cannot be empty"
        error++
      } else {
        this.errs.overlay_name_error = ""
      }

      if(error > 0){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let photoSettingsId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/photo-settings/' + photoSettingsId + '/overlays'

      for(let n in this.layers){
        if(this.layers[n].type === 'image'){
          this.layers[n].url = CDN_URL + this.getUrlKey(this.layers[n].url)
          this.layers[n].content = this.layers[n].url
        }
        this.layers[n].width = this.layers[n].reference_width
        this.layers[n].height = this.layers[n].reference_height
        this.layers[n].x = this.layers[n].reference_x
        this.layers[n].y = this.layers[n].reference_y
        this.layers[n].fontSize = this.layers[n].reference_font_size ? this.layers[n].reference_font_size : 20
      }

      let data = {
        name: this.overlay_name,
        layers: this.layers,
        aspect_ratio: this.selectedAspectRatio.label,
        description: this.description,
        reference_height: this.canvas.height,
        reference_width: this.canvas.width
      }
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Overlay created', 'success')
            .then((response)=> {
              this.$router.push({ path: '/photos/settings', query: { id: this.$route.query.id }})
            })
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getOverlay(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let photoSettingsId = this.$route.query.id
      let overlayId = this.$route.query.oid
      let url = API_LOCATION + 'companies/' + companyId + '/photo-settings/' + photoSettingsId + '/overlays/' + overlayId
      axios.get(url, config)
        .then(response => {
          this.reference_height = response.data.overlay.reference_height
          this.reference_width = response.data.overlay.reference_width
          this.overlay_name = response.data.overlay.name
          this.breadcrumb_name = response.data.overlay.name
          this.description = response.data.overlay.description
          this.selectedAspectRatio = _.find(this.aspect_ratios, {label: response.data.overlay.aspect_ratio})
          this.layers = response.data.elements
          this.layers.forEach(layer => {
            layer.fontSize = Number(layer.fontSize)
            if (layer.type === 'image') {
              layer.url = layer.content
            }
          })
          this.makeLayersSequential()
          this.updateCanvasSize();
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getFonts()
    this.canvas = this.$refs.canvas;
    this.updateCanvasSize()
    window.addEventListener('resize', this.updateCanvasSize);
    if(this.$route.query.hasOwnProperty('oid')){
      this.mode = 1
      this.getOverlay()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCanvasSize);
  },
}
</script>
