import { render, staticRenderFns } from "./Migration.vue?vue&type=template&id=dd4b333a&scoped=true"
import script from "./Migration.vue?vue&type=script&lang=js"
export * from "./Migration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd4b333a",
  null
  
)

export default component.exports