<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div v-if="photos_not_enabled" class="mt-3" >
      <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
        <h4 class="mt-3">This feature is not enabled.</h4>
        <base-button type="primary" v-if="hasPermissions('billing_view')"
                     v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
        <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
      </div>
    </div>

    <div v-if="!photos_not_enabled">
      <h5 class="info-text">
        Would you like to take a player photo now?
      </h5>
      <div class="row justify-content-center mt-5">




        <div class="col-sm-7">
          <div class="row">
            <div class="col">
              <img slot="image" class="card-img-top photo-size" :src="model.uploaded_image"  alt="Card image cap"/>
              <span class="btn btn-primary btn-simple game-button btn-file mt-3">Take a photo
                <input v-if="isAndroid14" type="file"
                       @change="takePhoto()"/>
                <input v-else type="file"
                       accept="image/*"
                       @change="takePhoto()"

                />
              </span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import _ from "lodash";

export default {
  name: "PhotoStep",
  data() {
    return {
      loading: false,
      photos_not_enabled: false,
      uploadUrl: '',
      file: '',
      layout: 'landscape',
      userAgent: navigator.userAgent,
      model: {
        uploaded_image: 'https://console.offthecouch.io/img/placeholder.jpg'
      }
    }
  },
  computed: {
    isAndroid14() {
      return navigator.userAgent.match(/Android/i) !== null;
    },
  },
  methods: {
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async setPhoto () {
      localStorage.setItem('photo', JSON.stringify(this.model))

      //save draft
      let companyId = localStorage.getItem('companyId')
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId
        + '/games/instances/' + localStorage.getItem('gameInstanceId') + '/drafts'

      // this.convertTime()
      let data = {
        photo: this.model.uploaded_image
      }
      axios.put(url, data, config)
        .then(response => {
          let draft = JSON.parse(localStorage.getItem('draft'))
          draft.photo = data.photo

          localStorage.setItem('draft', JSON.stringify(draft))
        })
        .catch(err => {
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    handleUpload(){
      let file = event.target.files[0];
      if (file && !file.type.match('image.*')) {
        swal('Error!', 'You must upload an image', 'error')
        return
      }
      this.uploaded_image = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload/photos'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    populateDraft(){
      let draft = JSON.parse(localStorage.getItem('draft'))
      if(draft){
        if(draft.url){
          this.model.uploaded_image = draft.url
        }
      }
    },
    async getLayout(){
      let img = new Image()
      img.src = URL.createObjectURL(this.file)


      return new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
      });

    },
    async takePhoto(){
      let t = new Date().toLocaleString( 'sv' );
      this.handleUpload()
      if(!this.file){return}
      let layout = await this.getLayout()
      if(parseFloat(layout.width / layout.height).toFixed(2) == 0.75 ) {
        this.layout = 'portrait'
      }
      this.loading = true
      await this.saveFile()
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/photos?t=' + t

      let data = {
        uploaded_image:  this.uploadUrl.split("?")[0],
        layout: this.layout
      }

      this.model.uploaded_image =  this.uploadUrl.split("?")[0]

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''

        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        } else {
          swal({
            title: 'Something went wrong!',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Try again',
            buttonsStyling: false
          })
        }
      })
    },
  },
  mounted(){
    if(!(_.find(JSON.parse(localStorage.getItem('features')), {id: 5}))){
      this.photos_not_enabled = true
    } else {
      this.photos_not_enabled = false
    }
  }
}
</script>

<style scoped>
.game-button{
  width: 100%;
}
.photo-size{
  width: 100%; /* width of container */
  height: 300px; /* height of container */
  object-fit: cover;
}
</style>
