<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <h5 class="info-text">
      Define your pricing model
    </h5>

    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="row">
          <div class="col-lg-3">
            <base-input label="Pricing type">
              <el-select
                label="Select time"
                v-model="selectedPricingType"
                class="select-primary"
                value-key="id"
                placeholder="Pricing type">
                <el-option
                  v-for="(pt, index) in pricing_types"
                  class="select-primary"
                  :label="pt.name"
                  :value="{id: pt.id,
                          name: pt.name}"
                  :key="'PT' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <div class="row" v-for="(p, i) in pricing_categories" :key="'PP' + i">

              <div class="col-3" >
                <el-tooltip class="d-inline"
                            content="Remove category"
                            effect="light"
                            :open-delay="300"
                            placement="right"
                >
                  <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                </el-tooltip>
                <base-input class="d-inline" :error="errs.pricing_categories[i].category" v-model="p.category" :label="'Category'">
                </base-input>
                <base-input class="mt-2" :error="errs.pricing_categories[i].minimum_participants"  label="Minimum required participants">
                  <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                </base-input>
                <base-input  class="mt-2" :error="errs.pricing_categories[i].default_participants"  label="Default number of participants">
                  <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                </base-input>
              </div>
              <div class="col" :key="cat_key">
                <div class="row" v-for="(k, j) in pricing_categories[i].pricing" :key="'PPP' + j">
                  <div class="col" >
                    <base-input :error="errs.pricing_categories[i].pricing[j].min_players"  :label="'Minimum players'">
                      <el-input-number v-model="k.min_players" :min="getMin(pricing_categories[i].pricing, j)" placeholder="1" :step="1" />
                    </base-input>
                  </div>
                  <div class="col" >
                    <base-input :error="errs.pricing_categories[i].pricing[j].max_players"  :label="'Maximum players'">
                      <el-input-number v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                    </base-input>
                  </div>
                  <div class="col">
                    <base-input :error="errs.pricing_categories[i].pricing[j].price" :label="'Price (' + currency_symbol + ')'">
                      <el-input-number placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                    </base-input>
                  </div>
                  <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                    <el-tooltip
                      content="Remove price range"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                    </el-tooltip>
                    <el-tooltip
                      content="Add price range"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <base-button style="padding: 0;" v-if="j == pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(pricing_categories[i], i)" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-right" >
                <base-button class="mr-2" v-on:click="addNewCategory()" size="sm" type="primary">Add new category</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row ml-5">
      <div class="col">
        <base-checkbox v-model="cumulative_category_pricing">Cumulative category pricing</base-checkbox>
      </div>
    </div>
    <div class="row ml-5">
      <div class="col-lg-2">
        <base-checkbox v-model="allow_deposit">Allow deposit</base-checkbox>
      </div>
      <div class="col-lg-3 mt-2" v-if="allow_deposit">
        <base-input label="Deposit type">
          <el-select
            v-model="deposit_type"
            class="select-primary"
            name="deposit_type"
            placeholder="Value type"
            value-key="id"
          >
            <el-option
              v-for="(type, index) in valueTypes"
              class="select-primary"
              :label="type.name"
              :value="{name: type.name, id: type.id}"
              :key="index + 'VT'"
            ></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-3 mt-2" v-if="allow_deposit">
        <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount" v-on:change="validateAmount(deposit_amount)"
                    v-model="deposit_amount"></base-input>
      </div>
      <div class="col-lg-3 mt-2" v-if="allow_deposit">
        <base-checkbox v-model="require_deposit">Require only deposit amount during checkout</base-checkbox>
      </div>
    </div>
    <div class="row ml-5">
      <div class="col">
        <base-checkbox v-model="hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import currencies from "@/assets/currencies.json";
import _ from "lodash";

const axios = require('axios')

export default {
  data() {
    return {
      valueTypes: [
        {
          name: 'Per booking',
          id: 0
        },
        {
          name: 'Per participant',
          id: 1
        }
      ],
      require_deposit: false,
      deposit_amount: 0,
      allow_deposit: false,
      cumulative_category_pricing: true,
      hide_pricing_rate: false,
      deposit_type: {id: 0},
      loading: false,
      currencies: currencies.currencies,
      currency_symbol: '$',
      model: {},
      selectedPricingType: {id: 1},
      pricing_categories: [{
        category: 'Person',
        minimum_participants: 0,
        default_participants: 0,
        pricing: [{min_players: 1, max_players: 2, price: 0}]
      }],
      cat_key: 0,
      errs: {
        pricing_categories: [{category: '', minimum_participants: '', default_participants: '', pricing: [{min_players: '', max_players: '', price: ''}]}],
      },
      pricing_types: [
        {
          id: 1,
          name: 'Per person'
        },
        {
          id: 2,
          name: 'Flat'
        },
      ]
    };
  },
  methods: {
    validateAmount(){
      if(isNaN(this.deposit_amount)){
        this.deposit_amount = 0
        return
      }
      this.deposit_amount = this.getAmount(this.deposit_amount)
    },
    validatePerPersonPricing(){
      let errors = 0

      for(let n in this.pricing_categories){
        if(this.pricing_categories[n].category === '' || !this.pricing_categories[n].category){
          this.errs.pricing_categories[n].category = 'Category cannot be empty'
          errors++
        } else {
          this.errs.pricing_categories[n].category = ''
        }

        if(this.pricing_categories[n].minimum_participants === '' || this.pricing_categories[n].minimum_participants === undefined){
          this.errs.pricing_categories[n].minimum_participants = 'Minimum participants cannot be empty'
          errors++
        } else {
          this.errs.pricing_categories[n].minimum_participants = ''
        }

        if(this.pricing_categories[n].default_participants === '' || this.pricing_categories[n].default_participants === undefined){
          this.errs.pricing_categories[n].default_participants = 'Default participants cannot be empty'
          errors++
        } else {
          this.errs.pricing_categories[n].default_participants = ''
        }

        for(let m in this.pricing_categories[n].pricing){
          if(this.pricing_categories[n].pricing[m].price === undefined || this.pricing_categories[n].pricing[m].price.length <= 0){
            this.errs.pricing_categories[n].pricing[m].price = 'Price cannot be empty'
            errors++
          } else {
            this.errs.pricing_categories[n].pricing[m].price = ''
          }

          if(this.pricing_categories[n].pricing[m].max_players === undefined || this.pricing_categories[n].pricing[m].max_players.length <= 0){
            this.errs.pricing_categories[n].pricing[m].max_players = 'Invalid value'
            errors++
          } else if (this.pricing_categories[n].pricing[m].max_players < this.pricing_categories[n].pricing[m].min_players){
            this.errs.pricing_categories[n].pricing[m].max_players = 'Invalid range'
            this.errs.pricing_categories[n].pricing[m].min_players = 'Invalid range'
            errors++
          } else {
            this.errs.pricing_categories[n].pricing[m].max_players = ''
          }

          if(this.pricing_categories[n].pricing[m].min_players === undefined || this.pricing_categories[n].pricing[m].min_players.length <= 0){
            this.errs.pricing_categories[n].pricing[m].min_players = 'Invalid value'
            errors++
          } else if (this.pricing_categories[n].pricing[m].max_players < this.pricing_categories[n].pricing[m].min_players){
            this.errs.pricing_categories[n].pricing[m].max_players = 'Invalid range'
            this.errs.pricing_categories[n].pricing[m].min_players = 'Invalid range'
            errors++
          } else {
            this.errs.pricing_categories[n].pricing[m].min_players = ''
          }
        }

      }
      this.cat_key++
      return errors > 0 ? false : true
    },
    getMin(pricing, index){
      if(index < 1){
        return 1
      }
      for(let n in pricing){
        if(parseInt(n) === index){
          return pricing[n-1].max_players + 1
        }
      }
      return 1
    },
    removeCategory(item, index){
      item.splice(index, 1);
      this.errs.pricing_categories.splice(index, 1);
    },
    removePriceRange(item, i1, i2){
      item.splice(i2, 1);
      this.errs.pricing_categories[i1].per_person_pricing.splice(i2, 1);
    },
    addPriceRange(category, index){
      if(category.pricing.length > 0){
        category.pricing.push({
          min_players: category.pricing[category.pricing.length - 1].max_players + 1,
          max_players: category.pricing[category.pricing.length - 1].max_players + 2,
          price: 0})
      } else {
        category.pricing.push({
          min_players: 1,
          max_players: 2,
          price: 0})
      }
      this.errs.pricing_categories[index].pricing.push({
        min_players: '',
        max_players: '',
        price: ''
      })
    },
    addNewCategory(){
      this.pricing_categories.push({
        category: 'Person',
        minimum_participants: '',
        pricing: [{min_players: 1, max_players: 2, price: 0}]
      })
      this.errs.pricing_categories.push({
        category: '',
        minimum_participants: '',
        pricing: [{min_players: '',
          max_players: '',
          price: ''
        }]
      })
    },
    validate() {
      if(!this.validatePerPersonPricing()){
        return
      }
      this.model.pricing_categories = this.pricing_categories
      this.model.pricing_type_id = this.selectedPricingType.id
      this.model.require_deposit = this.require_deposit
      this.model.deposit_type = this.deposit_type
      this.model.allow_deposit = this.allow_deposit
      this.model.cumulative_category_pricing = this.cumulative_category_pricing
      this.model.hide_pricing_rate = this.hide_pricing_rate
      this.model.deposit_amount = this.deposit_amount
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  },
  mounted () {
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
  }
};
</script>

<style scoped>
</style>
