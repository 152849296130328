<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/games/clues/select">Hint settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ game.name }}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row mb-2">
    <div class="col">
      <base-button type="primary"  v-on:click="displayConfiguration()">Display configuration</base-button>
      <base-button v-on:click="copyURL()" simple class="ml-2" type="primary">Copy hint screen link</base-button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Puzzles</h4>
        <div class="mt-3" v-if="empty === true">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No hint have been set up.</h4>
          </div>
        </div>
        <div v-if="empty === false" class="row">
          <div class="col mr-5 ml-3">
            <b-table  striped hover :items="puzzles"
                      :fields="puzzle_fields">
              <template #head(actions)="row">
                <span></span>
              </template>
              <template v-slot:cell(hints)="row">
                <div class="row justify-content-between">
                  <div class="col">
                    <p style="font-size: large" class="text-primary">{{row.item.name}}


                    </p>
                    <p v-if="row.item.description.length > 0">Description: {{row.item.description}}</p>
                    <p v-if="row.item.solution.length > 0">Solution: {{row.item.solution}}</p>
                  </div>
                  <div class="col text-right">
                    <base-button
                                 @click.native="addNewClue(row.item)"
                                 type="primary"
                                 size="sm"
                    ><i class="tim-icons el-icon-plus mr-1"></i> Create hint
                    </base-button>
                  </div>
                </div>
                <span style="opacity: 0; font-size: 1px">{{k}}</span>
                <div class="row">
                  <div class="col">
                    <div
                      class="btn-group btn-group-toggle"
                      :class="'float-left'"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-sm btn-primary btn-simple" :class="{ active: row.item.expanded[0] }"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'text', 0, 1)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Text</span>
                      </label>
                      <label :class="{ active: row.item.expanded[1] }"
                             class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'image', 1, 1)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Image</span>
                      </label>
                      <label :class="{ active: row.item.expanded[2] }"
                             class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'audio', 2, 1)"
                          name="options"
                          autocomplete="off"
                          checked="1"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Audio</span>
                      </label>
                      <label :class="{ active: row.item.expanded[3] }"
                             class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'video', 3, 1)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter">Video</span>
                      </label>
                    </div>
                  </div>
                </div>
                <span>
                  <div :key="'A' + k" class="row mt-3" v-if="row.item.expanded[0] == true">

                     <div v-if="row.item.clues[0].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[0].length > 0">
                      <b-table  striped hover :items="row.item.clues[0]" :fields="['text', 'description', 'count_as_hint', 'actions']">
                        <template #head(actions)="row">
                            <span></span>
                        </template>
                        <template v-slot:cell(count_as_hint)="row">
                          <base-checkbox v-if="!row.item.editing" disabled v-model="row.item.count_as_hint"></base-checkbox>
                          <base-checkbox v-if="row.item.editing" v-model="row.item.count_as_hint"></base-checkbox>
                        </template>
                        <template v-slot:cell(text)="row">
                          <p v-if="!row.item.editing">{{row.item.text}}</p>
                          <base-input v-if="row.item.editing">
                            <textarea class="form-control" name="clue" v-model="row.item.text"
                                      placeholder="Type custom text here..." rows="5"></textarea>
                          </base-input>
                        </template>
                        <template v-slot:cell(description)="row">
                          <p v-if="!row.item.editing">{{row.item.description}}</p>
                          <base-input v-if="row.item.editing">
                            <textarea class="form-control" name="clue" v-model="row.item.description"
                                      placeholder="Type custom text here..." rows="5"></textarea>
                          </base-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                         <div class="row justify-content-end mr-auto">
                                <base-button v-if="!row.item.editing"
                                             @click.native="editClue(row.item, row.index, 'text', 0)"
                                             class="like btn-link"
                                             type="warning"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-pencil"></i>
                                </base-button>
                                <base-button v-if="row.item.editing"
                                             @click.native="saveClue(row.item, row.index, 'text', 0)"
                                             class="like btn-link"
                                             type="success"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-check-2"></i>
                                </base-button>
                                <base-button
                                  @click.native="deleteClue(row.item, row.index, 'text', 0, puzzles)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                            </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'B' + k" class="row" v-if="row.item.expanded[1] == true">
                    <div v-if="row.item.clues[1].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[1].length > 0">
                      <b-table striped hover :items="row.item.clues[1]" :fields="['file_name', 'thumbnail', 'description', 'count_as_hint', 'actions']">
                        <template #head(actions)="row">
                            <span></span>
                        </template>
                         <template v-slot:cell(count_as_hint)="row">
                          <base-checkbox v-if="!row.item.editing" disabled v-model="row.item.count_as_hint"></base-checkbox>
                          <base-checkbox v-if="row.item.editing" v-model="row.item.count_as_hint"></base-checkbox>
                        </template>
                        <template #head(thumbnail)="row">
                            <span></span>
                        </template>
                        <template v-slot:cell(file_name)="row">
                            <span>{{getFileName(row.item.file_url)}}</span>
                        </template>
                        <template v-slot:cell(thumbnail)="row">
                          <img class="thumbnail" :src="row.item.file_url">
                        </template>
                        <template v-slot:cell(description)="row">
                          <p v-if="!row.item.editing">{{row.item.description}}</p>
                          <base-input v-if="row.item.editing">
                            <textarea class="form-control" name="clue" v-model="row.item.description"
                                      placeholder="Type custom text here..." rows="5"></textarea>
                          </base-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <div class="row justify-content-end mr-auto">
                                <base-button v-if="!row.item.editing"
                                             @click.native="editClue(row.item, row.index, 'image', 0)"
                                             class="like btn-link"
                                             type="warning"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-pencil"></i>
                                </base-button>
                                <base-button v-if="row.item.editing"
                                             @click.native="saveClue(row.item, row.index, 'image', 0)"
                                             class="like btn-link"
                                             type="success"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-check-2"></i>
                                </base-button>
                                <base-button
                                  @click.native="deleteClue(row.item, row.index, 'image', 0)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                            </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'C' + k" class="row" v-if="row.item.expanded[2] == true">
                    <div v-if="row.item.clues[2].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[2].length > 0">
                      <b-table striped hover :items="row.item.clues[2]" :fields="['file_name', 'description', 'count_as_hint', 'actions']">
                        <template #head(actions)="row">
                            <span></span>
                        </template>
                         <template v-slot:cell(count_as_hint)="row">
                          <base-checkbox v-if="!row.item.editing" disabled v-model="row.item.count_as_hint"></base-checkbox>
                          <base-checkbox v-if="row.item.editing" v-model="row.item.count_as_hint"></base-checkbox>
                        </template>
                        <template #head(thumbnail)="row">
                            <span></span>
                        </template>
                        <template v-slot:cell(file_name)="row">
                            <span>{{getFileName(row.item.file_url)}}</span>
                        </template>
                        <template v-slot:cell(description)="row">
                          <p v-if="!row.item.editing">{{row.item.description}}</p>
                          <base-input v-if="row.item.editing">
                            <textarea class="form-control" name="clue" v-model="row.item.description"
                                      placeholder="Type custom text here..." rows="5"></textarea>
                          </base-input>
                        </template>
                            <template v-slot:cell(actions)="row">
                              <div class="row justify-content-end mr-auto">
                                <base-button v-if="!row.item.editing"
                                             @click.native="editClue(row.item, row.index, 'audio', 0)"
                                             class="like btn-link"
                                             type="warning"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-pencil"></i>
                                </base-button>
                                <base-button v-if="row.item.editing"
                                             @click.native="saveClue(row.item, row.index, 'audio', 0)"
                                             class="like btn-link"
                                             type="success"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-check-2"></i>
                                </base-button>
                                <base-button
                                  @click.native="deleteClue(row.item, row.index, 'audio', 0)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                          </div>

                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'D' + k" class="row" v-if="row.item.expanded[3] == true">
                    <div v-if="row.item.clues[3].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[3].length > 0">
                      <b-table striped hover :items="row.item.clues[3]" :fields="['file_name', 'description', 'count_as_hint', 'actions']">
                        <template #head(actions)="row">
                            <span></span>
                        </template>
                        <template v-slot:cell(count_as_hint)="row">
                          <base-checkbox v-if="!row.item.editing" disabled v-model="row.item.count_as_hint"></base-checkbox>
                          <base-checkbox v-if="row.item.editing" v-model="row.item.count_as_hint"></base-checkbox>
                        </template>
                        <template #head(thumbnail)="row">
                            <span></span>
                        </template>
                        <template v-slot:cell(file_name)="row">
                            <span>{{getFileName(row.item.file_url)}}</span>
                        </template>
                        <template v-slot:cell(description)="row">
                          <p v-if="!row.item.editing">{{row.item.description}}</p>
                          <base-input v-if="row.item.editing">
                            <textarea class="form-control" name="clue" v-model="row.item.description"
                                      placeholder="Type custom text here..." rows="5"></textarea>
                          </base-input>
                        </template>
                            <template v-slot:cell(actions)="row">
                             <div class="row justify-content-end mr-auto">
                                <base-button v-if="!row.item.editing"
                                             @click.native="editClue(row.item, row.index, 'video', 0)"
                                             class="like btn-link"
                                             type="warning"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-pencil"></i>
                                </base-button>
                                <base-button v-if="row.item.editing"
                                             @click.native="saveClue(row.item, row.index, 'video', 0)"
                                             class="like btn-link"
                                             type="success"
                                             size="sm"
                                             icon
                                ><i class="tim-icons icon-check-2"></i>
                                </base-button>
                                <base-button
                                  @click.native="deleteClue(row.item, row.index, 'video', 0)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                            </div>

                        </template>
                      </b-table>
                    </div>
                  </div>
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </card>
    </div>
  </div>

  <!--    Add Timeslot Modal-->
  <modal :show.sync="modals.create"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add a new hint for {{selectedPuzzle.name}}</h4>
      <div class="row mb-3">
        <div class="col-4">
          <label>Hint type</label>
          <base-input>
            <el-select
              label="Select game"
              v-model="new_hint.type"
              class="select-primary"
              @change="clearFile()"
              placeholder="Game">
              <el-option
                v-for="type in hintTypes"
                class="select-primary"
                :label="type"
                :value="type"
                :key="type"
              ></el-option>
            </el-select>
          </base-input>
          <base-checkbox v-model="new_hint.count_as_hint" class="d-inline">Count as a hint</base-checkbox>
        </div>
        <div class="col-8" v-if="new_hint.type == 'Video'">
          <label>Video</label>
          <base-alert type="info">
            <i class="tim-icons el-icon-info"></i>
            Most modern browsers do not allow autoplay with sound by default.
            Please whitelist https://console.offthecouch.io/ in your browser settings in order for audio and video hints to work.
          </base-alert>
          <p class="text-white">{{file_name}}</p>
          <span class="btn btn-info btn-sm btn-simple btn-file">Upload file
                            <input :key="upload_key + 'C'"
                              accept="video/*"
                              @change="handlePreview"
                              type="file"
                              name="..."
                              class="valid"
                              :multiple="false"
                              aria-invalid="false"
                            />
                </span>
        </div>
        <div class="col-8" v-if="new_hint.type == 'Audio'">
          <label>Audio</label>
          <base-alert type="info">
            <i class="tim-icons el-icon-info"></i>
            Most modern browsers do not allow autoplay with sound by default.
            Please whitelist https://console.offthecouch.io/ in your browser settings in order for audio and video hints to work.
          </base-alert>
          <p class="text-white">{{file_name}}</p>
          <span class="btn btn-info btn-sm btn-simple btn-file">Upload file
                            <input :key="upload_key + 'A'"
                              accept="audio/*"
                              @change="handlePreview"
                              type="file"
                              name="..."
                              class="valid"
                              :multiple="false"
                              aria-invalid="false"
                            />
                </span>
        </div>
        <div class="col-8" v-if="new_hint.type == 'Image'">
          <label>Image</label>
          <img slot="image" class="fixed-size-img" :src="new_hint.img"  alt="Card image cap"/>
          <span class="btn btn-info btn-sm btn-simple btn-file">Upload file
                            <input :key="upload_key + 'B'"
                              accept="image/*"
                              @change="handlePreview"
                              type="file"
                              name="..."
                              class="valid"
                              :multiple="false"
                              aria-invalid="false"
                            />
                </span>
        </div>
        <div class="col-8" v-if="new_hint.type == 'Text'">
          <base-input label="Text">
            <el-input placeholder="Hint text"
                      v-model="new_hint.text"
                      name="hour"
                      data-lpignore="true">
            </el-input>
          </base-input>
          <p v-show="errors.duration" class="ml-3 text-danger small">Duration must be greater than 0</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Description</label>
          <base-input>
                            <textarea class="form-control" name="clue" v-model="new_hint.description"
                                      placeholder="Type custom text here..." rows="5"></textarea>
          </base-input>
          <p v-show="errors.date" class="ml-1 text-danger small">Date cannot be empty</p>
        </div>
      </div>




      <div class="row d-none d-sm-block text-center mt-3">
        <base-button type="secondary" @click="modals.create = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="createClue()">Save</base-button>
      </div>
      <div class="row d-block d-sm-none text-center mt-3">
        <base-button simple size="sm" type="secondary" @click="modals.create = false">Cancel</base-button>
        <base-button simple size="sm" class="ml-3" type="primary" @click="createClue()">Save</base-button>
      </div>
    </card>
  </modal>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Badge} from "@/components";
import {BTable} from "bootstrap-vue";
import {BaseAlert} from 'src/components'
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import swal from "sweetalert2";

export default {
  name: "ConfigureClues",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    Badge,
    BTable,
    BaseAlert
  },
  data() {
    return {
      upload_key: 0,
      loading: false,
      game: '',
      clues: [],
      file_name: '',
      puzzles: [],
      puzzle_fields: ['hints', 'actions'],
      empty: false,
      fields: ['puzzle','templated_hints', 'custom_hints'],
      clue_fields: ['hint', 'hint_type', 'actions'],
      clues_sent_fields: ['hint', 'type', 'timestamp'],
      expanded: [],
      expanded_text: [],
      selectedPuzzle: '',
      new_hint: {
        type: 'Text',
        text: '',
        description: '',
        file_url: '',
        count_as_hint: 1,
        img: 'https://console.offthecouch.io/img/placeholder.jpg'
      },
      hintTypes: ['Text', 'Image', 'Video', 'Audio'],
      k: 0,
      modals: {
        create: false
      },
      file: '',
    }
  },
  methods: {
    clearFile(){
      this.file = ''
      this.file_name = ''
      this.new_hint.img = 'https://console.offthecouch.io/img/placeholder.jpg'
      this.upload_key++
      this.new_hint.file_url = ''
    },
    copyURL(){
      navigator.clipboard.writeText('https://console.offthecouch.io/clues/display?gameId=' + this.game.id);
    },
    displayConfiguration(){
      this.$router.push('configure/display')
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload/clues'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async saveFile() {
      if(this.file != ''){
        this.uploadUrl = await this.getSignedURL(this.file)
        let uploaded = await this.uploadFile(this.file)
        //Get the image URL
        let imgUrl = this.uploadUrl.split("?")
        this.game.img = imgUrl[0]
        this.new_hint.file_url = imgUrl[0]
      }
    },
    handlePreview(event) {
      let file = event.target.files[0];
      this.new_hint.img = URL.createObjectURL(file);
      this.file = file
      this.file_name = file.name
      this.$emit('change', file);
    },
    addNewClue(item){
      this.selectedPuzzle = item
      this.modals.create = true
    },
    getFileName(file_name){
      let name = file_name.split('/')[file_name.split('/').length-1]
      return name
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async createClue(){
      this.loading = true
      if(this.new_hint.type.toLowerCase() == 'image' || this.new_hint.type.toLowerCase() == 'video' || this.new_hint.type.toLowerCase() == 'audio'){
        await this.saveFile()
      }

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles/' + this.selectedPuzzle.id + '/clues'
      let data = {
        text: this.new_hint.text,
        description: this.new_hint.description,
        file_url: this.new_hint.file_url,
        count_as_hint: this.new_hint.count_as_hint,
        type: this.new_hint.type.toLowerCase()
      }
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.create = false
          this.selectedPuzzle.expanded = []
          this.new_hint = {
            type: 'Text',
            text: '',
            description: '',
            file_url: '',
            count_as_hint: 1,
            img: 'https://console.offthecouch.io/img/placeholder.jpg'
          }
          this.k++
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveClue(item){

      item.editing = false
      this.k++
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles/' + item.puzzle_id + '/clues/' + item.id
      let data = {
        text: item.text,
        description: item.description,
        file_url: item.file_url,
        count_as_hint: item.count_as_hint
      }
      axios.put(url, data, config)
        .then(response => {
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editClue(item){
      item.editing = true
      this.k++
    },
    async deleteClue(item, row_index, type, index, origin){

      for(let n in origin){
        let arr = origin[n].clues[0]
        let c = _.remove(arr, {
          id: item.id
        });
        this.k++
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles/' + item.puzzle_id + '/clues/' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getPuzzles(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.puzzles = response.data
          if(this.puzzles.length <= 0){
            this.empty = true
          }
          for(let n in this.puzzles){
            this.puzzles[n].clues = []
            this.puzzles[n].expanded = []
          }
          this.puzzles =  _.orderBy(this.puzzles, 'position')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getClue(item, row_index, type, index, origin){
      if(origin && origin === 1){
        this.$set(item.expanded, index, !item.expanded[index])
        for(let n = 0; n < 4; n++){
          if(n !== index){
            this.$set(item.expanded, n, false)
          }
        }
      }
    //this.loading = true
    let token = await this.getAuthToken()
    let config = { headers: { Authorization: token } }
    let companyId = localStorage.getItem('companyId')
    let gameId = this.game.id
    let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles/' + item.id + '/clues?type=' + type
    axios.get(url, config)
      .then(response => {
        this.loading = false
        this.$set(item.clues, index, response.data.clues)
        this.k += 1
      })
      .catch(err => {
        this.loading = false
        console.log(err)
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        } else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })

    },
  },
  mounted(){
    this.game = JSON.parse(localStorage.getItem("game"))
    this.getPuzzles()
  }
}
</script>

<style scoped>
.thumbnail {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}

.fixed-size-img {
  width: 100%; /* width of container */
  height: 200px; /* height of container */
  object-fit: cover;
}
</style>
