<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--Medium and large screens-->
    <div class="row d-none d-sm-block">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
              <h4 slot="header" class="card-title">Users</h4>
            </div>
            <div class="col text-right">
              <el-dropdown>
                <base-button
                  link
                  type="primary"
                  size="sm"
                > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item mb-2" @click="inviteUser()" href="#">
                      <i class="tim-icons text-info icon-single-02 mr-2"></i> Invite user
                    </a>
                    <a class="dropdown-item mb-2" @click="viewDeactivatedUsers()" href="#">
                      <i class="tim-icons text-default icon-single-02 mr-2"></i> View deactivated users
                    </a>
                    <hr v-show="hasPermissions('roles_update')">
                    <a class="dropdown-item" @click="editRoles()" href="#" v-show="hasPermissions('roles_update')">
                      <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit user roles
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12"
            >
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      placeholder="Search users"
                      v-model="filter"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="noGuideAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noGuideAvailable === false" class="row" >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="users"
                       :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :fields="fields"
                       @filtered="onFiltered"
              >
                <template v-slot:cell(user)="row">
                  <div>{{row.item.first_name}}  {{row.item.last_name}}</div>
                  <div>
                    <small v-if="row.item.email">
                      <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                    </small>
                  </div>
                  <div>
                    <small v-if="row.item.phone">
                      <i class="tim-icons icon-mobile"></i>
                      {{row.item.phone}}
                    </small>
                  </div>
                </template>
                <template v-slot:cell(last_login)="row">
                  <span v-if="row.item.last_login">{{getLocalDate(row.item.last_login)}}, {{getLocalTime(row.item.last_login)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(groups)="row">
                  <badge v-for="(group, i) in JSON.parse(row.item.user_groups)" class="mr-3" :key="'G' + i" type="primary">{{group.name}}</badge>
                </template>
                <template v-slot:cell(role)="row">
                  <div>{{row.item.selectedRole.name}}</div>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      link
                      type="primary"
                      size="sm"
                    > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-1 mb-2" @click="resendConfirmationEmail(row.item, $event)" href="#">
                          <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Resend confirmation email
                        </a>
                        <a class="dropdown-item mt-1 mb-2" @click="editUser(row.item, $event)" href="#">
                          <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit user
                        </a>
                        <hr>
                        <a class="dropdown-item mb-2" @click="disableUserAlert(row.item, $event)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate user
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
              <h4 slot="header" class="card-title">Users</h4>
            </div>
            <div class="col text-right">
              <el-dropdown>
                <base-button
                  link
                  type="primary"
                  size="sm"
                > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item mb-2" @click="inviteUser()" href="#">
                      <i class="tim-icons text-info icon-single-02 mr-2"></i> Invite user
                    </a>
                    <a class="dropdown-item mb-2" @click="viewDeactivatedUsers()" href="#">
                      <i class="tim-icons text-default icon-single-02 mr-2"></i> View deactivated users
                    </a>
                    <hr>
                    <a class="dropdown-item" @click="editRoles()" href="#">
                      <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit user roles
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <div class="row mt-4">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12"
            >
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search users"
                  v-model="filter"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
          </div>
          <div v-if="noGuideAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noGuideAvailable === false" class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="users"
                       :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :fields="mobile_fields"
                       @filtered="onFiltered"
              >
                <template v-slot:cell(users)="row">
                  <div class="row">
                    <div class="col-7">
                      <h4 class="text-primary">{{row.item.first_name}} {{row.item.last_name}}</h4>
                    </div>
                    <div class="col-5 text-right">
                      <el-dropdown>
                        <base-button
                          link
                          type="primary"
                          size="sm"
                        > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                        </base-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <a class="dropdown-item mt-1 mb-2" @click="resendConfirmationEmail(row.item, $event)" href="#">
                              <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Resend confirmation email
                            </a>
                            <a class="dropdown-item mt-1 mb-2" @click="editUser(row.item, $event)" href="#">
                              <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit user
                            </a>
                            <hr>
                            <a class="dropdown-item mb-2" @click="disableUserAlert(row.item, $event)" href="#">
                              <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate user
                            </a>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                  </div>
                  <div class="row" v-if="row.item.email">
                    <div class="col-12">
                      <span class="small"><i class="tim-icons icon-email-85"></i> {{row.item.email}}</span>
                    </div>
                  </div>
                  <div class="row" v-if="row.item.phone">
                    <div class="col-12">
                      <span class="small"><i class="tim-icons icon-mobile"></i> {{row.item.phone}}</span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      Last login: {{getLocalDate(row.item.last_login)}}, {{getLocalTime(row.item.last_login)}}
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      Role:
                      <span>{{row.item.selectedRole.name}}</span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      Groups:
                      <span  v-for="(group, i) in JSON.parse(row.item.user_groups)" :key="'K' + i">
                          <badge  class="mr-3" type="primary">
                            {{group.name}}
                          </badge>
                      </span>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="3"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--    User-->
    <modal :show.sync="modals.user"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg"
    >
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 class="card-title">Edit {{ selectedUser.first_name }} {{ selectedUser.last_name }}</h4>

        <div class="row mt-2">
          <div class="col">
            <label>
              <div>Email
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">By changing the email, the original email will be removed from the system. <br/>
                      The user will receive an account activation email to new email address.<br/> Their account data will be retained. </div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <base-input v-model="selectedUser.email" :error="errs.email"></base-input>
          </div>
          <div class="col-lg-4">
            <base-input label="Role">
              <el-select
                v-model="selectedUser.selectedRole"
                class="select-primary"
                name="role"
                placeholder="Role"
                value-key="id"
              >
                <el-option
                  v-for="(role, i) in roles"
                  class="select-primary"
                  :label="role.name"
                  :value="{id: role.id, name: role.name, default_role: role.default_role}"
                  :key="'R' + i"
                >
                  <div class="row">
                    <div class="col">
                      {{ role.name }}
                    </div>
                    <div class="col text-right">
                      <badge class="ml-2" v-if="role.default_role" type="default">default</badge>
                    </div>
                  </div>
                </el-option>
              </el-select>
            </base-input>


          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <base-input label="Groups">
              <el-select multiple
                         v-model="selectedGroup"
                         class="select-primary"
                         name="group"
                         placeholder="Group"
                         value-key="id"
              >
                <el-option
                  v-for="(group, i) in groups"
                  class="select-primary"
                  :label="group.name"
                  :value="{ name: group.name, id: group.id} "
                  :key="'F' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>


        <div class="row text-center mt-3">
          <div class="col">
            <base-button type="secondary" @click="modals.user = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="saveUser(selectedUser)">
              Save</base-button>
          </div>
        </div>
      </card>
    </modal>

  </div>
</template>

<script>
  import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
  import {Select, Option, Tooltip, Dropdown, DropdownMenu, DropdownItem} from 'element-ui';
  import { Auth } from 'aws-amplify'
  import swal from 'sweetalert2'
  import { API_LOCATION } from '../../../api-config'
  const axios = require('axios')
  import {Badge} from '@/components'
  import _ from "lodash";
  import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";

  export default {
      name: "UserManagement",
      components: {
        [Select.name]: Select,
        [Option.name]: Option,
        BFormInput,
        BTable,
        BPagination,
        Badge,
        Tooltip,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
      },
      data() {
        return {
          j: 0,
          selectedRole: [],
          selectedGroup: [],
          loading: false,
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          noGuideAvailable: false,
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          date_format: JSON.parse(localStorage.getItem('group')).date_format,
          filter: null,
          fields: ['user', 'last_login', 'groups', 'role', 'actions'],
          mobile_fields: ['users'],
          users: [],
          roles: '',
          groups: '',
          selectedUser: '',
          errs: {
            email: ''
          },
          modals: {
            user: false
          }
        };
      },
      methods: {
        getLocalTime(d){
          let local = new Date(d)
          let mins =  local.getMinutes()
          if(mins < 10){
            mins = '0' + mins
          }
          return this.getTime(local.getHours() + ':' + mins)
        },
        getTime(d) {
          return time(this.time_format, d)
        },
        getLocalDate(d){
          let local = new Date(d)
          let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
          let final = this.getFormattedDate(form)
          return this.getDayOfWeek(form) + final
        },
        getDayOfWeek(d){
          if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
            return dayOfWeek(d) + ', '
          }
          return ''
        },
        getFormattedDate(d){
          return formattedDate(this.date_format, d)
        },
        hasPermissions(scope){
          let permissions = JSON.parse(localStorage.getItem('permissions'))
          const matchFound = permissions.some(element => element === scope);
          return matchFound
        },
        async resendConfirmationEmail(item, event){
          event.preventDefault()
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users/resend-confirmation'
          let data = {
            email: item.email
          }

          axios.post(url, data, config)
            .then(response => {
              swal({
                title: `Success`,
                text: 'Activation email sent to user.',
                type: 'success'
              });
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else if (err.response.status == 500){
                swal({
                  title: `Error`,
                  text: 'Activation email cannot be sent because the user already registered their account.',
                  type: 'error'
                });
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        getAuthToken() {
          return new Promise ( function(resolve) {
            Auth.currentSession()
              .then(data =>{
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        editRoles(){
          this.$router.push('/settings/roles')
        },
        inviteUser() {
          this.$router.push('/settings/user-invite')
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        editUser(item, event) {
          event.preventDefault()
          this.selectedUser = JSON.parse(JSON.stringify(item))
          this.modals.user = true
          this.selectedGroup = JSON.parse(this.selectedUser.user_groups)
          this.selectedGroup = this.selectedGroup.filter(group =>
            _.find(this.groups, { id: group.id })
          );
        },
        async disableUser(item){
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users/deactivate'
          let data = {
            email: item.email
          }

          axios.post(url, data, config)
            .then(response => {
              swal('Success!', 'User has been deactivated', 'success')
              this.getUsers()
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        disableUserAlert(item, event){
          event.preventDefault()
          swal({
            title: 'Are you sure?',
            text: "This action will deactivate the user",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deactivate',
            cancelButtonText: 'Cancel'
          }).then((response)=> {
            if(response.value == true){
             this.disableUser(item)
            }
          })

        },
        async getUsers() {
          this.loading = true
          var token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users'

          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.users = response.data

              for(let n in this.users){
                // if(this.users[n].email.includes('demo@offthecouchgames.com')){
                //   // this.users.splice(n, 1)
                //   console.log(this.users[n])
                // } else {
                //   this.users[n].user_groups = this.users[n].user_groups.split(',')
                //   if(this.users[n].default_role_id){
                //     console.log(this.users[n])
                //     this.users[n].selectedRole = _.find(this.roles, {id: this.users[n].default_role_id})
                //   } else if(this.users[n].role_id){
                //     this.users[n].selectedRole = _.find(this.roles, {id: this.users[n].role_id})
                //   }
                // }
                this.users[n].user_groups = this.users[n].user_groups.split(',')
                if(this.users[n].default_role_id){
                  this.users[n].selectedRole = _.find(this.roles, {id: this.users[n].default_role_id})
                } else if(this.users[n].role_id){
                  this.users[n].selectedRole = _.find(this.roles, {id: this.users[n].role_id})
                }
              }
              this.onFiltered(this.users)
              if(this.users.length <= 0){
                this.noGuideAvailable = true
              }
            })
            .catch(err => {
              console.log(err)
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async getRoles() {
          this.loading = true
          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/roles'

          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.roles = response.data.default_roles.concat(response.data.roles)

              this.getUsers()
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async getGroups() {
          this.loading = true
          var token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/groups'

          axios.get(url, config)
            .then(response => {
              this.loading = false
              this.groups = response.data
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        isEmailValid(emailString) {
          let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailRegex.test(emailString);
        },
        async saveUser(item) {
          if(!this.isEmailValid(item.email)){
            this.errs.email = 'The email is invalid'
            return
          } else {
            this.errs.email = ''
          }
          this.loading = true
          let groups = []
          for(let n in this.selectedGroup){
            groups.push(this.selectedGroup[n].id)
          }

          let token = await this.getAuthToken()
          let config = { headers: { Authorization: token } }
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/users'

          let data = {
            id: item.id,
            default_role: item.selectedRole.default_role ? 1 : 0,
            role_id: item.selectedRole.id,
            groups: groups,
            email: item.email
          }
          axios.put(url, data, config)
            .then(response => {
              this.loading = false
              swal('Success!', 'User has been updated', 'success')
              this.modals.user = false
              this.getUsers()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }else if(err.response.status == 500){
                swal('Ooops', err.response.data, 'error');
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })

        },
        viewDeactivatedUsers(){
          this.$router.push('deactivated-users')

        }
      },
      mounted(){
        this.getRoles()
        this.getGroups()
      }
    }
</script>

<style scoped>

</style>
