<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/settings/company">Company settings</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>Create new company group</span>
      </breadcrumb-item>
    </breadcrumb>

    <simple-wizard>
      <template slot="header">
        <h3 class="card-title">Create a new group</h3>
        <small class="description">
          A group can be a distinct business location or a logical way to categorize your business.
        </small>
      </template>

      <wizard-tab :before-change="() => validateStep('step1')">
        <template slot="label">
          <i class="tim-icons el-icon-info"></i>
          <p>Basic information</p>
        </template>
        <basic-group-info
          ref="step1"
          @on-validated="onStepValidated"></basic-group-info>

      </wizard-tab>

      <wizard-tab :before-change="() => validateStep('step2')">
        <template slot="label">
          <i class="tim-icons el-icon-location-information"></i>
          <p>Location details</p>
        </template>
        <group-location-details ref="step2"
                                @on-validated="onStepValidated">
        </group-location-details>
      </wizard-tab>

      <wizard-tab :before-change="() => validateStep('step3')">
        <template slot="label">
          <i class="tim-icons icon-world"></i>
          <p>Locale and formatting</p>
        </template>
        <group-locale ref="step3"
                      @on-validated="onStepValidated"></group-locale>
      </wizard-tab>

      <wizard-tab :before-change="() => wizardComplete()">
        <template slot="label">
          <i class="tim-icons icon-check-2"></i>
          <p>Review</p>
        </template>
        <group-review @on-validated="onStepValidated" ref="step4"></group-review>
      </wizard-tab>

    </simple-wizard>
  </div>

</template>

<script>
import {SimpleWizard, WizardTab} from "@/components";
import BasicGroupInfo from "@/pages/Settings/GroupSettings/BasicGroupInfo";
import GroupLocationDetails from "@/pages/Settings/GroupSettings/GroupLocationDetails";
import GroupLocale from "@/pages/Settings/GroupSettings/GroupLocale";
import GroupReview from "@/pages/Settings/GroupSettings/GroupReview";
import swal from "sweetalert2";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "NewGroupWizard",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    SimpleWizard,
    WizardTab,
    BasicGroupInfo,
    GroupLocationDetails,
    GroupLocale,
    GroupReview
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      return this.$refs['step4'].updateModel(this.wizardModel)
    },
    async wizardComplete() {
      var created = await this.$refs['step4'].createGroup()
      if(created.status == 200){
        swal('Success!', 'New group has been created!', 'success')
          .then((response)=> {
            this.$router.push('/settings/company')
          })
      } else {
        swal('Something went wrong', 'Please review the form!', 'error');

      }
    }
  }
}
</script>

<style scoped>

</style>
