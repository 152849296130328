<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/surveys/view">Surveys</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a :href="'/surveys/results?id=' + survey_response.survey_id">{{ survey_response.name }}</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>  {{survey_response.customer_first_name}} {{survey_response.customer_last_name}}</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col-12">
      <card>
        <div class="row">
          <div class="col">
            <h4 slot="header" class="card-title">{{ survey_response.name }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Submitted by
            <span v-if="survey_response.customer_first_name || survey_response.customer_last_name">
              {{survey_response.customer_first_name}} {{survey_response.customer_last_name}}
            </span>
             <span v-else> anonymous </span>
            on {{getLocalDate(survey_response.timestamp)}}, {{getLocalTime(survey_response.timestamp)}}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <small>
              <i v-if="survey_response.email" class="tim-icons icon-email-85"></i> {{survey_response.email}}
              <i v-if="survey_response.customer_phone" class="tim-icons el-icon-mobile-phone"></i> {{survey_response.customer_phone}}
            </small>
          </div>
        </div>

        <div class="mt-3" v-if="questions.length <= 0 ">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">This survey did not have ay responses.</h4>
          </div>
        </div>

<!--        Mobile-->
        <div class="d-block d-sm-none">
          <div v-if="questions.length > 0" class="row table-content">
            <div class="col">
              <b-table hover :items="questions"
                       :fields="['questions']">
                <template v-slot:cell(questions)="row">
                  <p><span class="text-primary">Question: </span>{{row.item.question}}</p>
                  <p><span class="text-primary">Response: </span>
                    <span v-if="!row.item.response">-</span>
                    <span v-if="row.item.response && row.item.response_field_type === 1">
                    {{getCheckboxResponses(row.item.response)}}
                  </span>
                    <span v-if="row.item.response && row.item.response_field_type !== 1">{{row.item.response}}</span>
                  </p>
                </template>
              </b-table>
            </div>
          </div>
        </div>

<!--        Desktop-->
        <div class="d-none d-sm-block">
          <div v-if="questions.length > 0" class="row table-content">
            <div class="col">
              <b-table hover :items="questions"
                       :fields="['question', 'response']">
                <template v-slot:cell(response)="row">
                  <p>
                    <span v-if="!row.item.response">-</span>
                    <span v-if="row.item.response && row.item.response_field_type === 1">
                    {{getCheckboxResponses(row.item.response)}}
                  </span>
                    <span v-if="row.item.response && row.item.response_field_type !== 1">{{row.item.response}}</span>
                  </p>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-button type="default" v-on:click="returnToSurveys()">Cancel</base-button>
          </div>
        </div>


      </card>
    </div>
  </div>

</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {BTable} from "bootstrap-vue";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";


export default {
  name: "SurveyResponse",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BTable
  },
  data() {
    return {
      loading: false,
      questions: [],
      survey_response: '',
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
    }
  },
  methods: {
    returnToSurveys(){
      this.$router.push({ path: '/surveys/results', query: { id:  this.survey_response.survey_id }})
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getCheckboxResponses(array){
      return JSON.parse(array).join(', ')
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getSurveyResponse(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let responseId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/history/' + responseId

      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.questions = response.data.questions
          this.survey_response = response.data.survey_response
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getSurveyResponse()
  }
}
</script>

<style scoped>

</style>
