<template>
<div>
  <loading-panel :loading="loading"></loading-panel>
  <h5 class="info-text">
    Check one last time and make sure all data is accurate
  </h5>
  <div class="ml-1 ">
    <div class="row justify-content-center">
      <h3>{{model.booking_info.booking.name}}</h3>
    </div>
    <div class="row text-center justify-content-center">
      <div class="col-12 col-lg-3 col-md-4 col-sm-4">
        <i class="tim-icons icon-calendar-60"></i><span class="ml-2">{{getFormattedDate(model.booking_info.booking.booking_date)}}</span>
      </div>
      <div class="col-12 col-lg-3 col-md-4 col-sm-4">
        <i class="tim-icons icon-time-alarm"></i><span class="ml-2">{{getTime(model.booking_info.booking.start_time)}}</span>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12 col-lg-6 col-md-8 col-sm-12">
        <base-alert type="white">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <p class="text-dark"><strong class="mr-1">Start time:</strong> {{getTime(model.pre_game_notes.start_time)}}</p>
              <p class="text-dark"><strong class="mr-1">End time:</strong> {{getTime(model.post_game_notes.end_time)}}</p>
              <p class="text-dark"><strong class="mr-1">Pre-game notes:</strong> {{model.pre_game_notes.pre_notes}}</p>
              <p class="text-dark"><strong class="mr-1">Post-game notes:</strong> {{model.post_game_notes.post_notes}}</p>
              <p class="text-dark"><strong class="mr-1">Did they complete the game?</strong> {{model.post_game_notes.completed}}</p>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <p class="text-dark"><strong class="mr-1">Team experience:</strong> {{model.pre_game_notes.team_experience}}</p>
              <p class="text-dark"><strong class="mr-1">Team members:</strong>
                <span v-for="(u, index) in model.pre_game_notes.users" v-bind:key="index">
                  <span v-if="u.confirmed == true">{{u.first_name}} {{u.last_name}}<br>
                  </span>
                </span>
              </p>
              <p class="text-dark"><strong class="mr-1">Team name:</strong> {{model.pre_game_notes.team}}</p>


            </div>
          </div>


        </base-alert>
      </div>
    </div>
  </div>
  <div v-if="gts.include_photos_step && model.photo.uploaded_image != 'https://console.offthecouch.io/img/placeholder.jpg' && model.photo.uploaded_image != ''" class="row justify-content-center">
    <div class="col-6">
      <img slot="image" class="card-img-top photo-size" :src="model.photo.uploaded_image"  alt="Card image cap"/>
    </div>
  </div>
  <div class="d-none d-sm-block" v-if="gts.include_puzzles_step">
    <div  class="row justify-content-center mt-4">
      <div class="col-sm-12">
        <b-table striped hover :items="model.puzzles" :fields="fields">
          <template v-slot:cell(name)="row">
            <small class=" text-primary">{{row.item.name}}</small>
          </template>
          <template v-slot:cell(description)="row">
            <small>{{row.item.description}}</small>
          </template>
          <template v-slot:cell(was_the_puzzle_completed?)="row">
            <small v-if="row.item.did_not_finish">No</small>
            <small v-if="!row.item.did_not_finish">Yes</small>
          </template>
          <template v-slot:cell(solve_time)="row">
            <small>{{ getTime(row.item.solve_time) }}</small>
          </template>
          <template v-slot:cell(total_hints)="row">
            <small>{{row.item.hints_given_count}}</small>
          </template>
          <template v-slot:cell(what_hints_did_you_give?)="row">
            <small v-if="row.item.hints_given_description">{{row.item.hints_given_description}}</small>
            <small v-if="!row.item.hints_given_description">-</small>
          </template>
          <template v-slot:cell(breakage_occurred?)="row">
            <small v-if="row.item.breakage_occurred">Yes</small>
            <small v-if="!row.item.breakage_occurred">No</small>
          </template>
          <template v-slot:cell(breakage_description)="row">
            <small v-if="row.item.breakage_description">{{row.item.breakage_description}}</small>
            <small v-if="!row.item.breakage_description">-</small>
          </template>
        </b-table>
      </div>
    </div>
  </div>
  <div class="d-block d-sm-none" v-if="gts.include_puzzles_step">
    <div  class="row justify-content-center mt-4">
      <div class="col-sm-12">
        <b-table striped hover :items="model.puzzles" :fields="mobileFields">
          <template v-slot:cell(puzzles)="row">
              <div class="row">
                <div class="col-12">
                  <span class="text-primary">{{ row.item.name }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Description: </small>
                  <small>{{ row.item.description }}</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Was the puzzle completed? </small>
                  <small v-if="row.item.did_not_finish">No</small>
                  <small v-if="!row.item.did_not_finish">Yes</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Solve time: </small>
                  <small>{{ getTime(row.item.solve_time) }}</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Total hints: </small>
                  <small>{{ row.item.hints_given_count }}</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Hints given: </small>
                  <small v-if="row.item.hints_given_description">{{row.item.hints_given_description}}</small>
                  <small v-if="!row.item.hints_given_description"> -</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Did puzzle break? </small>
                  <small v-if="row.item.breakage_occurred">Yes</small>
                  <small v-if="!row.item.breakage_occurred">No</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="text-warning">Breakage description: </small>
                  <small v-if="row.item.breakage_description">{{row.item.breakage_description}}</small>
                  <small v-if="!row.item.breakage_description"> -</small>
                </div>
              </div>

          </template>
        </b-table>
      </div>
    </div>
  </div>

</div>
</template>

<script>
  import { TimeSelect, Popover} from 'element-ui';
  import { BTable } from 'bootstrap-vue'
  import { Auth } from 'aws-amplify'
  import { TimeLine, TimeLineItem } from 'src/components';
  import {BaseAlert} from 'src/components'
  import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
    export default {
      name: "ReviewStep",
      components: {
        [TimeSelect.name]: TimeSelect,
        [Popover.name]: Popover,
        TimeLine,
        TimeLineItem,
        BaseAlert,
        BTable
      },
      data() {
        return {
          loading: false,
          fields: ['name', 'description', 'was_the_puzzle_completed?', 'solve_time', 'total_hints', 'what_hints_did_you_give?', 'breakage_occurred?', 'breakage_description'],
          mobileFields: ['puzzles'],
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          date_format: JSON.parse(localStorage.getItem('group')).date_format,
          gts: '',
          model: {
            puzzles: [],
            booking_info: {
              booking: {
                name: ''
              }
            },
            pre_game_notes: '',
            post_game_notes: '',
            photo: ''
          },
        }
      },
      methods: {
        getDayOfWeek(d){
          if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
            return dayOfWeek(d) + ', '
          }
          return ''
        },
        getTime(dt){
          if(!dt){
            return
          }


          if(dt.includes('T')){
            dt = dt.split('T')[1]
          } else if(dt.includes(' ')){
            dt = dt.split(' ')[1].split(':')[0] + ':' + dt.split(' ')[1].split(':')[1]
          }
          // console.log(dt)
          return time(this.time_format, dt)
        },
        getFormattedDate(d){
          if(d == undefined){
            return
          }
          return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
        },
        getAuthToken() {
          return new Promise(function (resolve) {
            Auth.currentSession()
              .then(data => {
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        validate() {
          return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
          });
        },
        getReviewData (gts) {
          this.loading = true
          setTimeout(() => {
            console.log("gts")
            console.log(gts)
            this.gts = gts
            this.model.booking_info = JSON.parse(localStorage.getItem('bookingInfo'))
            this.model.pre_game_notes = JSON.parse(localStorage.getItem('preNotes'))
            if(gts.include_puzzles_step){
              this.model.puzzles = JSON.parse(localStorage.getItem('puzzles'))
            }
            if(gts.include_photos_step){
              this.model.photo = JSON.parse(localStorage.getItem('photo'))
            }
            this.model.post_game_notes = JSON.parse(localStorage.getItem('postNotes'))


            let draft = JSON.parse(localStorage.getItem('draft'))
            console.log("draft")
            console.log(draft)

            if(draft){
              this.model.pre_game_notes.start_time = draft.actual_start_time.split('T')[1].split(':')[0] + ':' + draft.actual_start_time.split('T')[1].split(':')[1]
              console.log(this.model)
              this.model.post_game_notes.end_time = draft.actual_end_time.split('T')[1].split(':')[0] + ':' + draft.actual_end_time.split('T')[1].split(':')[1]
              console.log(this.model)

            } else {
              let s_time = new Date(this.model.pre_game_notes.start_time)
              let e_time = new Date(this.model.post_game_notes.end_time)
              this.model.pre_game_notes.start_time = ('0' + s_time.getHours()).slice(-2) + ':' + ('0' + s_time.getMinutes()).slice(-2)
              this.model.post_game_notes.end_time = ('0' + e_time.getHours()).slice(-2) + ':' + ('0' + e_time.getMinutes()).slice(-2)
            }
            this.loading = false
          }, 1000);
        },
        submitRequest() {
          localStorage.removeItem('draft')
          localStorage.removeItem('gameInstanceId')
          localStorage.removeItem('bookingInfo')
          localStorage.removeItem('puzzles')
          localStorage.removeItem('postNotes')
          localStorage.removeItem('preNotes')
        }

      }
    }
</script>

<style scoped>
  .photo-size{
    width: 100%; /* width of container */
    height: 200px; /* height of container */
    object-fit: cover;
  }
</style>
