<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/games/clues/select">Hints</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Hint log</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row">
    <div class="col-4">
      <card>
        <h4 class="card-title">
          Hint log details
        </h4>
       <div class="row justify-content-between">
         <div class="col-lg-4">
           <span>Time submitted </span>
         </div>
         <div class="col">
           <p>
             <i class="tim-icons icon-calendar-60 text-primary"></i> {{getLocalDate(hint_instance.timestamp)}}
             <i class="tim-icons el-icon-alarm-clock text-primary"></i> {{getLocalTime(hint_instance.timestamp)}}
           </p>
         </div>
       </div>

        <div class="row mt-2 justify-content-between">
          <div class="col-lg-4">
            <span>Game master </span>
          </div>
          <div class="col">
            <p>{{hint_instance.first_name}} {{hint_instance.last_name}}</p>
          </div>
        </div>

        <div class="row mt-2 justify-content-between">
          <div class="col-lg-4">
            <span>Game </span>
          </div>
          <div class="col">
            <p v-if="hint_instance.booking_slot_id">{{hint_instance.name}}</p>
            <p v-if="hint_instance.booking_slot_id">
              <i class="tim-icons icon-calendar-60 text-primary"></i> {{getDate(hint_instance.booking_date)}}
              <i class="tim-icons el-icon-alarm-clock text-primary"></i> {{getTime(hint_instance.start_time)}}
            </p>
            <p v-else>-</p>
          </div>
        </div>


<!--        {{hint_instance}}-->
      </card>
    </div>
    <div class="col-8">
      <card>
        <h4 class="card-title">
          Hint log
        </h4>
        <div class="row">
          <div class="col">
            <b-table striped hover :items="hints"
                     :fields="['clue', 'type', 'puzzle', 'timestamp']">
              <template v-slot:cell(clue)="row">
                <p> {{getFileNameForHints(row.item)}}</p>
              </template>

              <template v-slot:cell(type)="row">
                <badge type="primary"> {{row.item.type}}</badge>
              </template>
              <template v-slot:cell(puzzle)="row">
                <span v-if="row.item.puzzle_name"> {{row.item.puzzle_name}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(timestamp)="row">
                <p class="text-white">
                  <i class="tim-icons icon-calendar-60 text-primary"></i> {{getLocalDate(row.item.timestamp)}}
                  <i class="tim-icons el-icon-alarm-clock text-primary"></i> {{getLocalTime(row.item.timestamp)}}
                </p>
              </template>
            </b-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {BTable, BListGroup, BListGroupItem} from 'bootstrap-vue'
import {Badge} from 'src/components'
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";

export default {
  name: "ClueHistory",
  components: {BreadcrumbItem, Breadcrumb, BTable, Badge, BListGroup, BListGroupItem},
  data() {
    return {
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      hint_instance: '',
      hints: []
    }
  },
  methods: {
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFileNameForHints(item){
      if(item.clue == undefined){
        return
      }

      if(item.type != 'text' && item.clue.length > 0){
        let name = item.clue.split('/')[item.clue.split('/').length-1]
        return name
      } else {
        return item.clue
      }

    },
    getLocalDate(d){
      let date = new Date(d)
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes();
      return this.getDayOfWeek(date) + formattedDate(this.date_format, date)
    },
    getLocalTime(d){
      let date = new Date(d)
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes();
      return time(this.time_format, date)
    },
    getDate(d){
      return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getHistory(){
      let log_id = localStorage.getItem('hintLogId')
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/clues/' + log_id
      axios.get(url, config)
        .then(response => {
          this.hint_instance = response.data.hint_instance
          this.hints = response.data.hints
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    }
  },
  mounted() {
    this.getHistory()
  }
}
</script>

<style scoped>

</style>
