<template>
  <div>
    <h4 class="info-text">
      Basic information
    </h4>
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8 col-md-8 col-sm-7">
        <div class="row">
          <div class="col-lg-6">
            <label>
              <div>Group name
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Group name is used to identify this group throughout the application. <br>
                      It will not be visible to your customers.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <base-input required
                        v-model="basic_info.name"
                        name="name"
                        placeholder="Group name"
                        :error="errs.name">
            </base-input>
          </div>
          <div class="col-lg-3">
            <label>
              <div>Group code
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Group code is a shorthand used to uniquely identify this group. <br>
                      It will be visible in customer-facing links such as waiver or booking site.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <base-input required
                        v-model="basic_info.code"
                        name="name"
                        placeholder="Group code"
                        :error="errs.code">
            </base-input>
          </div>
          <div class="col-lg-3">
            <label>Company number</label>
            <base-input required
                        v-model="basic_info.company_number"
                        name="name"
                        placeholder="Group code"
                        :error="errs.code">
            </base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11 col-lg-5 col-md-8 col-sm-7">
        <base-input label="Description">
          <el-input
            v-model="basic_info.description"
            :rows="8"
            type="textarea"
            placeholder="Group description"
          />
        </base-input>
      </div>
      <div class="col-11 col-lg-3 col-md-8 col-sm-7">
        <base-input label="Company logo">
          <image-upload @change="onFileChange" select-text="Select Image" />
        </base-input>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageUpload } from 'src/components';
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";

export default {
  name: "BasicGroupInfo",
  components: {
    ImageUpload
  },
  data(){
    return {
      basic_info: {
        name: '',
        code: '',
        description: '',
        img: '',
        company_number: ''
      },
      uploadUrl: '',
      file: '',
      errs: {
        name: '',
        code: ''
      }

    }
  },
  methods: {
    hasWhiteSpace(s) {
      return /\s/g.test(s);
    },
    validate() {
      let errors = 0
      if(!this.basic_info.name){
        this.errs.name = 'Company group name cannot be empty'
        errors++
      } else {
        this.errs.name = ''
      }

      if(this.hasWhiteSpace(this.basic_info.code)){
        this.errs.code = 'Group code cannot contain spaces'
        errors++
      } else if(this.basic_info.code.length > 25){
        this.errs.code = 'Group code cannot be more than 25 characters'
        errors++
      } else if(this.basic_info.code.length <= 0){
        this.errs.code = 'Group code cannot be empty'
        errors++
      }
      else {
        this.errs.group_code = ''
      }

      if(errors > 0){
        return
      }

      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.basic_info);
        return res;
      });
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async onFileChange(file) {
      this.file = file
      if(file != null){
        this.uploadUrl = await this.getSignedURL(file)
        let uploaded = await this.uploadFile(file)
        //Get the image URL
        let imgUrl = this.uploadUrl.split("?")
        this.basic_info.img = imgUrl[0]
      } else {
        this.basic_info.img = 'https://console.offthecouch.io/img/placeholder.jpg'
      }
    },
  }
}
</script>

<style scoped>

</style>
