<template>
<div class="full-screen">
  <div class="row justify-content-center">
    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4" class="gwfw">
      <tr>
        <td align="center" valign="top">
          <!-- Main -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="center" style="padding-bottom: 40px;" class="pb0">
                <!-- Shell -->
                <table width="650" border="0" cellspacing="0" cellpadding="0" class="m-shell">
                  <tr>
                    <td class="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;">
                      <!-- Top Bar -->
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 60px 40px 35px 40px;" class="p30-15">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            </table>
                          </td>
                        </tr>
                      </table>

<!--                      Intro-->
                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button" v-if="template.email_type === 'gift_cards'">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 20px 40px 0px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr v-if="template.sections[0].is_enabled">
                                  <td class="h3 center" style="padding-bottom: 30px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:28px; line-height:37px; font-weight:300; text-align:center;">
                                    {{ template.sections[0].title }}</td>
                                </tr>
                                <tr v-if="template.sections[0].is_enabled">
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:30px; min-width:auto !important; text-align:center;" v-html="template.sections[0].content"></td>
                                </tr>
                                <tr v-if="template.email_type === 'gift_cards'">
                                  <td class="h3 center" style="padding-top: 30px; padding-bottom: 30px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:28px; line-height:37px; font-weight:300; text-align:center;">Your gift card from
                                    {{ company.name }} is here!</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
<!--                      Intro-->

                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button"
                            v-if="template.sections[0].is_enabled && template.email_type !== 'gift_cards'">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 40px 20px 0px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr v-if="template.sections[0].is_enabled">
                                  <td class="h4 center" style="padding-bottom: 20px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:20px; line-height:17px; font-weight:500; text-align:left;">
                                    {{ template.sections[0].title }}</td>
                                </tr>
                                <tr v-if="template.sections[0].is_enabled">
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:23px; min-width:auto !important; text-align:left;" v-html="template.sections[0].content"></td>
                                </tr>
                                <tr v-if="template.email_type === 'gift_cards'">
                                  <td class="h3 center" style="padding-top: 30px; padding-bottom: 30px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:28px; line-height:37px; font-weight:300; text-align:left;">Your gift card from
                                    {{ company.name }} is here!</td>
                                </tr>
                                <tr v-else>
                                  <td class="h3 center" style="padding-top: 30px; padding-bottom: 30px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:28px; line-height:37px; font-weight:300; text-align:center;"></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div v-if="template.email_type === 'surveys'" mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button">
                        <table style="padding-top: 20px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 10px 20px 40px" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td style="color:#444444; border: none;
														    font-family:'Yantramanav', Arial, sans-serif; text-transform: uppercase;
														    font-size:14px; line-height:35px;
														    font-weight:100;
														    text-align:center;">
                                    <a style="border: none;
                                  background-color: #42b883;
                                    color: white;
                                    padding: 10px 32px;
                                    text-align: center;
                                    border-radius: 5px;
                                    text-decoration: none;
                                    display: inline-block;
                                    width: 200px;"
                                       href="#" type="button">Take the survey</a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button"
                            v-if="template.email_type === 'updated_booking' || template.email_type === 'cancelled_transaction' ||
                            template.email_type === 'confirmation' || template.email_type === 'cancelled_booking' || template.email_type === 'payment_request'">
                        <table style="padding-top: 0px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 20px 40px 20px;" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="h5" >
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">

                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:600; text-align:left;">
                                          Jane Doe
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:left;">
                                          Email: jane.doe@gmail.com
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:left;">
                                          Phone: (111) 111-1111
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td class="h5" >
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:right;">
                                          Transaction reference: #123456
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:right;">
                                          Transaction date: Monday, 18 Sep 2023
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:right;" v-if="template.email_type === 'cancelled_transaction'">
                                          Transaction status: <span style="color:#fd5d93; text-transform: uppercase; font-weight:600;">cancelled</span>
                                        </td>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300; text-align:right;" v-else>
                                          Payment status: <span v-if="template.email_type === 'payment_request'" style="color:#fd5d93; text-transform: uppercase; font-weight:600;">unpaid</span>
                                          <span v-else style="color:limegreen; text-transform: uppercase; font-weight:600;">paid</span>
                                        </td>

                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button"
                            v-if="template.email_type === 'cancelled_transaction' || template.email_type === 'updated_booking' || template.email_type === 'payment_request' || template.email_type === 'cancelled_booking' || template.email_type === 'reminder' || template.email_type === 'confirmation'">
                        <table style="padding-top: 0px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 20px 0px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="h5 center" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:10px; font-weight:600; text-align:center;">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">

                                      <tr>
                                        <td class="h5" style="color:#444444; text-transform: uppercase; upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:10px; font-weight:600; text-align:left;">
                                          Booking information</td>
                                        <td class="h5" style="color:#444444; text-transform: uppercase; upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:10px; font-weight:600; text-align:right;">
                                          Quantity</td>
                                        <td v-if="template.email_type === 'cancelled_transaction'" class="h5" style="color:#444444; text-transform: uppercase; upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:10px; font-weight:600; text-align:right;">
                                          Subtotal</td>
                                        <td v-else class="h5" style="color:#444444; text-transform: uppercase; upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:10px; font-weight:600; text-align:right;">
                                          Price</td>
                                      </tr>
                                      <tr>
                                        <td style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                        <td  style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                        <td  style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td style="padding: 0px 0px 0px 0px;" class="p0-15-30">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                  <tr>
                                                    <td style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:16px; line-height:35px; font-weight:300; text-align:left;">
                                                      Test game
                                                    </td>
                                                  </tr>
                                                  <tr v-if="template.email_type === 'cancelled_transaction' || template.email_type === 'cancelled_booking'">
                                                    <td style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:12px; line-height:20px; font-weight:300; text-align:left;">
                                                      <span style="font-weight: 700">Status:</span>  <span style="color:#fd5d93; text-transform: uppercase; font-weight:600;"> cancelled</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:12px; line-height:20px; font-weight:300; text-align:left;">
                                                      <span style="font-weight: 700">Date/time:</span> Wednesday, 11/29/2023, 04:45 PM
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:12px; line-height:20px; font-weight:300; text-align:left;">
                                                      <span style="font-weight: 700">Booking reference:</span> #1234567
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>

                                            </tr>
                                          </table>

                                        </td>
                                        <td class="h5" style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:16px; line-height:37px; font-weight:300; text-align:right;">
                                          x4</td>
                                        <td v-if="template.email_type === 'cancelled_transaction'" class="h5" style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:16px; line-height:37px; font-weight:300; text-align:right;">
                                          $0.00</td>
                                        <td v-else class="h5" style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:16px; line-height:37px; font-weight:300; text-align:right;">
                                          $25.00</td>
                                      </tr>
                                      <tr>
                                        <td style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                        <td  style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                        <td  style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                      </tr>
                                    </table>
                                    <table v-if="!template.hide_pricing && template.email_type !== 'cancelled_booking' && template.email_type !== 'reminder'" style="margin-left: 50%; padding-top: 10px; margin-bottom: 20px; text-align:right;" width="50%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:left;">
                                          Subtotal
                                        </td>
                                        <td class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $25.00</td>
                                      </tr>
                                      <tr>
                                        <td class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:left;">
                                          Tax
                                        </td>
                                        <td class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $5.00</td>
                                      </tr>
                                      <tr>
                                        <td style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                        <td  style="color:#444444;  line-height:10px; font-weight:600;">
                                          <hr></td>
                                      </tr>
                                      <tr>
                                        <td class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:600; text-align:left;">
                                          Total
                                        </td>
                                        <td v-if="template.email_type === 'cancelled_transaction' || template.email_type === 'cancelled_booking'" class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:600; text-align:right;">
                                          $0.00
                                        </td>
                                        <td v-else class="h5 right" style="color:#444444; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:600; text-align:right;">
                                          $30.00
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5 right" style="color:limegreen; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:left;">
                                          Amount paid
                                        </td>
                                        <td v-if="template.email_type === 'payment_request'" class="h5 right" style="color:limegreen; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $0.00
                                        </td>
                                        <td v-else class="h5 right" style="color:limegreen; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $30.00
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5 right" style="color:#fd5d93; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:left;">
                                          Amount due
                                        </td>
                                        <td v-if="template.email_type === 'cancelled_transaction'" class="h5 right" style="color:#fd5d93; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          -$30.00
                                        </td>
                                        <td v-else-if="template.email_type === 'payment_request'" class="h5 right" style="color:#fd5d93; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $30.00
                                        </td>
                                        <td v-else class="h5 right" style="color:#fd5d93; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:25px; font-weight:300; text-align:right;">
                                          $0.00
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>


                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button"
                            v-if="template.email_type === 'confirmation' && !template.hide_pricing">
                        <table style="padding-top: 0px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 20px 20px 40px 20px;" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="h5" >
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td class="h5" style="color:#444444;  text-transform: uppercase; upfont-family:'Yantramanav', Arial, sans-serif; font-size:14px; line-height:20px; font-weight:600;">
                                          Payment information
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300;">
                                          Transaction reference: #123456
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300;">
                                          Payment type: card
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300;">
                                          Payment date: Monday, 18 Sep 2023
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="h5" style="color:#444444;  upfont-family:'Yantramanav', Arial, sans-serif; font-size:11px; line-height:20px; font-weight:300;">
                                          Cardholder: Jane Doe
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div v-if="template.email_type === 'payment_request'" mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button">
                        <table style="padding-top: 20px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 10px 20px 40px" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td style="color:#444444; border: none;
														    font-family:'Yantramanav', Arial, sans-serif; text-transform: uppercase;
														    font-size:14px; line-height:35px;
														    font-weight:100;
														    text-align:center;">
                                    <a style="border: none;
                                  background-color: #42b883;
                                    color: white;
                                    padding: 10px 32px;
                                    text-align: center;
                                    border-radius: 5px;
                                    text-decoration: none;
                                    display: inline-block;
                                    width: 200px;"
                                       href="#" type="button">Make a payment</a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div v-if="template.display_waiver_link && (template.email_type === 'updated_booking' || template.email_type === 'confirmation' || template.email_type === 'reminder')" mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button">
                        <table style="padding-top: 20px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 30px 20px 40px" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td style="color:#444444; border: none;
														    font-family:'Yantramanav', Arial, sans-serif; text-transform: uppercase;
														    font-size:14px; line-height:35px;
														    font-weight:100;
														    text-align:center;">
                                    <a style="border: none;
                                  background-color: #42b883;
                                    color: white;
                                    padding: 10px 32px;
                                    text-align: center;
                                    border-radius: 5px;
                                    text-decoration: none;
                                    display: inline-block;
                                    width: 230px;"
                                       href="https://offthecouch.io/waiver/" type="button">Sign waiver</a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>


<!--Additional information-->
                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button" v-if="(template.email_type === 'confirmation' || template.email_type === 'reminder') && template.sections[1].is_enabled">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 20px 20px 40px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr v-if="template.sections[1].is_enabled">
                                  <td class="h4 center" style="padding-bottom: 20px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:20px; line-height:17px; font-weight:500; text-align:left;">
                                    {{ template.sections[1].title }}</td>
                                </tr>
                                <tr v-if="template.sections[1].is_enabled">
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:23px; min-width:auto !important; text-align:left;" v-html="template.sections[1].content"></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <!--Reschedule policy-->
                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button" v-if="(template.email_type === 'confirmation' || template.email_type === 'reminder') && template.sections[2].is_enabled">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 20px 20px 40px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr v-if="template.sections[2].is_enabled">
                                  <td class="h4 center" style="padding-bottom: 20px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:20px; line-height:17px; font-weight:500; text-align:left;">
                                    {{ template.sections[2].title }}</td>
                                </tr>
                                <tr v-if="template.sections[2].is_enabled">
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:23px; min-width:auto !important; text-align:left;" v-html="template.sections[2].content"></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <!--Cancellation policy-->
                      <div  mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button" v-if="(template.email_type === 'confirmation' || template.email_type === 'reminder') && template.sections[3].is_enabled">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 20px 20px 40px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr v-if="template.sections[3].is_enabled">
                                  <td class="h4 center" style="padding-bottom: 20px; color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:20px; line-height:17px; font-weight:500; text-align:left;">
                                    {{ template.sections[3].title }}</td>
                                </tr>
                                <tr v-if="template.sections[3].is_enabled">
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:23px; min-width:auto !important; text-align:left;" v-html="template.sections[3].content"></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <!-- Title + Full-Width Image + Text Center + Button -->
                      <div mc:repeatable="Select" mc:variant="Title + Full-Width Image + Text Center + Button" v-if="template.email_type === 'gift_cards' || template.email_type === 'photos'">
                        <table style="padding-top: 50px;" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 40px 0px 40px;" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">

                                <tr>
                                  <td class="fluid-img" style="padding-bottom: 30px; font-size:0pt; line-height:0pt; text-align:left;"><a href="#" target="_blank"><img src="https://console.offthecouch.io/img/pandas/pandaflip.jpg" style="object-fit: cover" width="570" height="295" border="0" alt="" /></a></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <!-- END Title + Full-Width Image + Text Center + Button -->

                      <!-- Content + Images -->
                      <div mc:repeatable="Select" mc:variant="Content + Images">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff" v-if="template.email_type === 'gift_cards'">
                          <tr>
                            <td style="padding: 0px 40px 40px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="h2 center" style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:58px; line-height:37px; font-weight:300; text-align:center;">$100.00</td>
                                </tr>
                                <tr>
                                  <td class="text center" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:30px; min-width:auto !important; text-align:center;">Current balance</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 0px 40px 40px 40px;" class="p0-15-30" v-if="template.email_type === 'gift_cards'">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="text" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:30px; text-align:center; min-width:auto !important;">Personal gift card message.</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <!-- END Content + Images -->

                      <!-- Content / Title + Copy + Button -->
                      <div mc:repeatable="Select" mc:variant="Content / Title + Copy + Button" v-if="template.email_type === 'gift_cards'">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 40px 0px 40px;" class="p0-15-30">
                              <table style="margin-left: 280px;" width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td class="h3 center" style="color:#444444; font-family:'Yantramanav', Arial, sans-serif; font-size:28px; line-height:37px; font-weight:300; text-align:center;">Gift card code</td>
                                </tr>
                                <tr>
                                  <td class="text center" style="padding-bottom: 16px; color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:30px; min-width:auto !important; text-align:center;">Provide the gift card code at checkout to redeem the gift card.</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div mc:repeatable="Select" mc:variant="Content + Images" v-if="template.email_type === 'gift_cards'">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 40px 20px 40px;" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td style="padding-bottom: 10px;">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <th class="column" width="300" bgcolor="#f4f4f4" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;">
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td style="padding: 40px;" class="p30-15">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                  <tr>
                                                    <td class="text" style=" color:#666666; font-family:Arial, sans-serif; font-size:16px; line-height:30px; text-align:center; min-width:auto !important;">1111 2222 3333 4444</td>
                                                  </tr>

                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </th>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>


                      <div mc:repeatable="Select" mc:variant="Content / Title + Copy + Button" v-if="template.email_type === 'gift_cards' && template.display_button">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                          <tr>
                            <td style="padding: 0px 40px 40px 40px;" class="p0-15-30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td align="center">
                                    <table>

                                      <tr>
                                        <td class="text-button text-button-white" :style="{'background': template.button_color}"
                                            style="color:#ffffff; border-radius:5px; font-family:'Yantramanav', Arial, sans-serif;
                                            font-size:14px; line-height:18px; text-align:center; font-weight:500; padding:12px 25px;">
                                          <div class="row justify-content-center">
                                            <div class="col text-center">
                                              <a :href="redirect_url" target="_blank" class="link-white" style="color:#ffffff; text-decoration:none;">
                                                <span class="link-white" :style="{'color': template.button_text_color}"
                                                      style="text-decoration:none;">
                                                {{ template.button_text }}</span>
                                              </a>
                                            </div>
                                          </div>

                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <!-- END Purple Content / Title + Copy + Button -->

                      <!-- Footer -->
                      <table v-if="template.show_location" width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                        <tr>
                          <td style="padding: 40px 40px; border-top: 3px solid #f4f4f4;" class="p30-15">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr>
                                <td style="padding-bottom: 40px;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tr>
                                      <th class="column-top" width="110" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                          <tr>
                                            <td class="img m-center" style="font-size:0pt; line-height:0pt; text-align:left;"><a href="#" target="_blank"><img :src="company.img" width="50" border="0" alt="" /></a></td>
                                          </tr>
                                        </table>
                                      </th>
                                      <th style="padding-bottom:25px !important; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;" class="column" width="20"></th>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>

                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr>
                                <th class="column" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tr>
                                      <td class="text-footer m-center" style="padding-bottom: 15px; color:#999999; font-family:Arial, sans-serif; font-size:14px; line-height:18px; text-align:left; min-width:auto !important;">{{company.name}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-footer2 m-center" style="color:#999999; font-family:Arial, sans-serif; font-size:12px; line-height:16px; text-align:left; min-width:auto !important;">
                                        {{ getAddressFields() }}</td>
                                    </tr>
                                  </table>
                                </th>
                                <th style="padding-bottom:25px !important; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;" class="column" width="20"></th>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <!-- END Footer -->
                    </td>
                  </tr>
                </table>
                <!-- END Shell -->
              </td>
            </tr>
          </table>
          <!-- END Main -->
        </td>
      </tr>
    </table>
<!--    <div class="col">-->
<!--      <h4 style="color: #777777">{{getSection(template, 'introduction').title}}</h4>-->
<!--      <span style="background-color: #2bffc6;" v-html="getSection(template, 'introduction').content"></span>-->
<!--      <hr style="margin-top: 20px; border-top: 1px solid #ececec;"><div style="display: grid;-->
<!--                            grid-template-columns: auto auto auto auto;-->
<!--                            grid-gap: 10px;-->
<!--                            padding: 10px;">-->
<!--      <div style="grid-row: 1" v-if="template.email_type=='confirmation' || template.email_type=='updated_booking' || template.email_type=='cancelled_booking'">-->
<!--        <table  align="left" width="800" border="0" cellspacing="0" cellpadding="0">-->
<!--          <tr>-->
<!--            <td> <h4 style="color: #777777">Booking information</h4> </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--          <tr v-if="template.email_type=='cancelled_booking'">-->
<!--            <td> <b>Booking status: </b> Cancelled</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Escape Room Name</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td> <b>Date/time: </b> 22 September, 2022, 10:00 AM </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td> <b>Number of players: </b> x3 </td>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--      <div v-if="template.email_type=='confirmation' || template.email_type=='updated_booking'" style="display: grid;-->
<!--                            grid-template-columns: auto auto auto auto;-->
<!--                            grid-gap: 10px;-->
<!--                            padding: 10px;">-->
<!--        <div style="grid-row: 1" v-if="template.hide_pricing==false" >-->
<!--          <table align="center" width="300" border="0" cellspacing="0" cellpadding="0">-->
<!--            <tr>-->
<!--              <td> Subtotal:  </td>-->
<!--              <td>  $50.00 </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td><b>Total: </b></td>-->
<!--              <td> $50.00 </td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="template.email_type=='confirmation' || template.email_type=='updated_booking' || template.email_type=='cancelled_transaction'" style="display: grid;-->
<!--                            grid-template-columns: auto auto auto auto;-->
<!--                            grid-gap: 10px;-->
<!--                            padding: 10px;">-->
<!--        <div style="grid-row: 1">-->
<!--          <table align="left" width="300" border="0" cellspacing="0" cellpadding="0">-->
<!--            <tr>-->
<!--              <td> <h4 style="color: #777777">Payments</h4></td>-->
<!--            </tr>-->
<!--            <tr >-->
<!--              <td><b> Transaction reference number: </b> #111111</td>-->
<!--            </tr>-->
<!--            <tr v-if="template.email_type=='cancelled_transaction'" >-->
<!--              <td><b> Transaction status: </b> Cancelled</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td><b> Payment type: </b> Card</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td> <b>Payment date: </b>  22 September 2022</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td> <b>Cardholder: </b> Jane Doe </td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
<!--      <br><br> <a style="border: none;-->
<!--                                background-color: #4CAF50;-->
<!--                                  color: white;-->
<!--                                  padding: 15px 32px;-->
<!--                                  text-align: center;-->
<!--                                  text-decoration: none;-->
<!--                                  display: inline-block;-->
<!--                                  font-size: 12px;-->
<!--                                  width: 200px;"-->
<!--                  href="https://offthecouch.io" type="button">View your booking</a>-->
<!--      <div v-if="template.email_type=='confirmation'">-->
<!--        <hr style="margin-top: 20px; border-top: 1px solid #ececec;">-->
<!--        <div v-if="getSection(template, 'additional_information').is_enabled == 1">-->
<!--          <h4 style="color: #777777">{{getSection(template, 'additional_information').title}}</h4>-->
<!--          <span v-html="getSection(template, 'additional_information').content"></span>-->
<!--        </div>-->
<!--        <div v-if="getSection(template, 'reschedule_policy').is_enabled == 1">-->
<!--          <h4 class="mt-4" style="color: #777777">{{getSection(template, 'reschedule_policy').title}}</h4>-->
<!--          <span v-html="getSection(template, 'reschedule_policy').content"></span>-->
<!--        </div>-->
<!--        <div v-if="getSection(template, 'cancellation_policy').is_enabled == 1">-->
<!--          <h4 class="mt-4" style="color: #777777">{{getSection(template, 'cancellation_policy').title}}</h4>-->
<!--          <span v-html="getSection(template, 'cancellation_policy').content"></span>-->
<!--        </div>-->
<!--        <div class="mb-5" v-if="template.show_location==true">-->
<!--          <hr style="margin-top: 20px; border-top: 1px solid #ececec;">-->
<!--          <h4  class="mt-1" style="color: #777777">Where to find us</h4>-->
<!--        <span style="color: black">123 Example Street San Francisco CA </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

</div>
</template>

<script>
export default {
  name: "PreviewEmail",
  data() {
    return {
      loading: false,
      template: '',
      company: JSON.parse(localStorage.getItem('company')),
      company_group: JSON.parse(localStorage.getItem('group')),
      group_code: JSON.parse(localStorage.getItem('group')).code,
      redirect_url: ''
    }
  },
  methods: {
    getTemplate(){
      this.template = JSON.parse(localStorage.getItem('emailTemplate'))
      for(let n in this.template.sections){
        this.template.sections[n] = this.replaceDynamicVariables(this.template.sections[n])
      }
      if(this.template.email_type === 'gift_cards'){
        this.redirect_url = 'https://offthecouch.io/book/' + this.group_code
        if(this.template.redirect_off_domain){
          this.redirect_url = this.template.redirect_url
        }
      }

    },
    replaceDynamicVariables(html) {
      html = JSON.stringify(html)
      html = html.replaceAll('[Company Name]', this.company.name)
      html = html.replaceAll('[Order Number]', '#1234567')
      html = html.replaceAll('[Booking Id]', '#9876543')
      html = html.replaceAll('[Customer First Name]', 'John')
      html = html.replaceAll('[Customer Last Name]', 'Doe')
      return JSON.parse(html)
    },
    getSection(template, type){
      for(let n in template.sections){
        if(template.sections[n].section_type == type){
          console.log(template.sections[n].content)
          return template.sections[n]
        }
      }
    },
    getAddressFields(){
      let addressFields = []
      if(this.company_group.address_line_1){
        addressFields.push(this.company_group.address_line_1)
      }
      if(this.company_group.address_line_2){
        addressFields.push(this.company_group.address_line_2)
      }
      if(this.company_group.city){
        addressFields.push(this.company_group.city)
      }
      if(this.company_group.state){
        addressFields.push(this.company_group.state)
      }
      if(this.company_group.zip){
        addressFields.push(this.company_group.zip)
      }
      addressFields = addressFields.join(', ');
      return addressFields
    }
  },
  mounted(){
    this.getTemplate()
  }
}
</script>

<style scoped>
.full-screen{
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left:0px;
  overflow: auto;
  padding:0 !important;
  margin:0 !important;
  display:block !important;
  min-width:100% !important;
  width:100% !important;
  -webkit-text-size-adjust:none;
}
::v-deep p {
  color: black;
}
.center {  margin-left: 150px }

</style>
