<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row mt-2">
      <div class="col">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
              <h4 class="card-title">Games</h4>
            </div>
            <div class="col-9 text-right">
              <base-button size="sm" v-on:click="addGame()" type="primary" >
                <i class="tim-icons icon-simple-add mr-2"></i> Add new game</base-button>

              <el-tooltip content="More actions"
                          effect="light"

                          :open-delay="300"
                          placement="top">
                <el-dropdown>
                  <base-button
                    simple
                    type="primary"
                    link
                  > <i class="tim-icons el-icon-more"></i>
                  </base-button>
                  <template #dropdown>
                    <el-dropdown-menu >
                      <base-button v-show="!hide_archived_games"
                                   size="sm" class="btn btn-link hover-link hover-link" v-on:click="hideInactiveGames()" type="default" >
                        <b-icon icon="eye-slash" scale="1" class="mr-2"></b-icon>
                        Hide archived games</base-button>
                      <base-button v-show="hide_archived_games" size="sm" class="btn btn-link hover-link hover-link" v-on:click="unhideInactiveGames()" type="default" >
                        <b-icon icon="eye" scale="1" class="mr-2"></b-icon>
                        Show all games</base-button>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>

              </el-tooltip>
            </div>
          </div>

          <!--  No content-->
          <div class="mt-3" v-if="games.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No games have been created.</h4>
            </div>
          </div>
          <div class="d-none d-sm-block">
            <div class="row mt-3 mb-5">
              <div class="col">
                <table class='table' v-if="games.length > 0">
                  <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'>Name</th>
                    <th scope='col'></th>
                    <th scope='col'>Duration</th>
                    <th scope='col'>Difficulty</th>
                    <th scope='col'>Number of Players</th>
                    <th scope='col'>Actions</th>
                  </tr>
                  </thead>
                  <tbody tag='tbody' v-model='games' is='draggable'>
                  <tr  v-for='(item, index) in games' :key="'G' + index">
                    <td scope='row'>
                      <i style="cursor: move" class="ml-3 tim-icons el-icon-rank"></i>
                    </td>
                    <td scope='row' style="width: 100px">
                      <div class="row">
                        <div class="col">
                          <img v-if="item.url" :src="item.url" style="border-radius: 60px; float: left; width: 60px; height: 60px; "/>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row" >
                        <div class="col">
                          <span>{{item.name}}</span>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row" >
                        <div class="col">
                          <span>{{item.duration_minutes}} minutes</span>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row" >
                        <div class="col">
                          <div v-if="item.include_game_difficulty && (item.difficulty_level != null && item.difficulty_level < 6)" style="max-width: 100px"> &nbsp;
                            <b-form-rating size="lg" show-clear
                                           :icon-empty="item.difficulty_icon"
                                           :icon-full="item.difficulty_icon + '-fill'"
                                           :color="item.difficulty_color"
                                           no-border
                                           readonly
                                           v-model="item.difficulty_level">

                            </b-form-rating>&nbsp;
                          </div>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row" >
                        <div class="col">
                          <span>{{item.min_players_count}} - {{item.max_players_count}}</span>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row">
                        <el-dropdown>
                          <base-button
                            link
                            type="primary"
                            size="sm"
                          > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                          </base-button>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <a class="dropdown-item mt-1 mb-2" href="#" v-on:click="editGame(item, $event)">
                                <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit game
                              </a>
                              <a class="dropdown-item mt-1 mb-2" href="#" v-on:click="duplicateGame(item, $event)">
                                <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Duplicate game
                              </a>
                              <hr>
                              <a class="dropdown-item" v-if="item.archived" @click="toggleActivationPrompt(item)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate game
                              </a>
                              <a class="dropdown-item" v-if="!item.archived" @click="toggleActivationPrompt(item)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate game
                              </a>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="d-block d-sm-none">
            <div class="row mt-3 mb-5">
              <div class="col">
                <table class='table' v-if="games.length > 0">
                  <thead>
                  <tr>
                    <th scope='col'>Game</th>
                  </tr>
                  </thead>
                  <tbody tag='tbody' v-model='games' is='draggable'>
                  <tr v-for='(item, index) in games' :key="'GA' + index">
                    <td scope='row' style="width: 100px">
                      <div class="row">
                        <div class="col">
                          <h4>{{item.name}}</h4>
                        </div>
                        <div class="col-2 text-right">
                          <i style="cursor: move" class="ml-3 tim-icons el-icon-rank"></i>
                        </div>
                      </div>
                      <div class="row" >
                        <div class="col">
                          <span>Difficulty: {{item.difficulty_level}}/5</span>
                        </div>
                      </div>
                      <div class="row" >
                        <div class="col">
                          <span>Duration: {{item.duration_minutes}} minutes</span>
                        </div>
                      </div>
                      <div class="row" >
                        <div class="col">
                          <span>Player count: {{item.min_players_count}} - {{item.max_players_count}}</span>
                        </div>
                      </div>

                      <div
                        class="btn-group btn-group-toggle mt-3 mb-2"
                        :class="'float-left'"
                        data-toggle="buttons" style="width:100%"
                      >
                        <label
                               class="btn btn-sm btn-primary btn-simple"
                               style="padding-left: 10px; padding-right: 10px">
                          <base-button
                            @click.native="editGame(item, $event)"
                            type="primary"
                            class="btn btn-link"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-pencil"></i>
                          </base-button>
                        </label>
                        <label
                               class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                          <base-button
                            @click.native="duplicateGame(item, $event)"
                            type="primary"
                            class="btn btn-link"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-single-copy-04"></i>
                          </base-button>
                        </label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {Card} from 'src/components'
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import swal from "sweetalert2";
  const axios = require('axios')
  import { BTable, BFormRating } from 'bootstrap-vue'
  import {Dropdown, DropdownItem, DropdownMenu} from 'element-ui';
  import _ from "lodash";
  import draggable from 'vuedraggable'


  export default {
    name: "GameSetup",
    components: {
      Card,
      BTable,
      BFormRating,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      draggable
    },
    data() {
      return {
        loading: false,
        games: [],
        noGuideAvailable: false,
        hide_archived_games: false
      }
    },
    watch: {
      games: {
        handler(newValue, oldValue) {
          if (!this.isArrayOrderSame(newValue, oldValue)) {
            this.updateGameOrder();
          }
        },
        deep: true
      }
    },
    methods: {
      isArrayOrderSame(newArray, oldArray) {
        if (newArray.length !== oldArray.length) {
          return false;
        }
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i] !== oldArray[i]) {
            return false;
          }
        }
        return true;
      },
      async updateGameOrder(){
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        let data = {
          games:  this.games
        }
        axios.put(url, data, config)
          .then(response => {
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      toggleActivationPrompt(item){
        if(!item.archived){
          swal({
            title: 'Are you sure?',
            text: `This action will archive the game. It will no longer be available for bookings or waivers.`,
            type: 'warning',
            showCancelButton: true
          }).then((response)=> {
            if(response.value == true){
              this.toggleArchive(item, 1)
            }
          })
        } else {
          swal({
            title: 'Are you sure?',
            text: `This action will unarchive the game. It will become immediately available for bookings and waivers.`,
            type: 'warning',
            showCancelButton: true
          }).then((response)=> {
            if(response.value == true){
              this.toggleArchive(item, 0)
            }
          })
        }
      },
      hideInactiveGames(){
        this.hide_archived_games = true
        this.games = _.filter(this.games, { archived: 0 })
      },
      unhideInactiveGames(){
        this.hide_archived_games = false
        this.getGames('unhide')
      },
      async toggleArchive(item, archived){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games/' + item.id + '/archive'
        let data = {
          archive:  archived
        }
        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            swal('Success!', 'The game has been updated.', 'success')
            this.getGames()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      addGame() {
        this.$router.push('/games/create')
      },
      configureClues(game){
        localStorage.setItem('game', JSON.stringify(game))
        this.$router.push('/games/clues/configure')
      },
      async duplicateGame(item, event){
        event.preventDefault()
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games/' + item.id

        let data = {
          duplicate: true,
        }
        this.axios.put(url, data, config)
          .then(response => {
            swal('Success', 'Game has been duplicated', 'success')
            this.$router.push({ path: '/games/edit', query: { id:  response.data }})
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      editGame(game, event){
        event.preventDefault()
        this.$router.push({ path: '/games/edit', query: { id:  game.id }})
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGames (action) {
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games?status=all'
        axios.get(url, config)
          .then(response => {
            this.games = response.data
            for (var n in this.games){
              if (this.games[n].img === null){
                this.games[n].img = 'https://console.offthecouch.io/img/placeholder.jpg'
              }
            }
            if(this.games.length <= 0){
              this.noGuideAvailable = true
            }
            if(action !== 'unhide'){
              this.hideInactiveGames()
            }

            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
    },
    mounted () {
      this.getGames()
    }
  }
</script>

<style scoped>
.hover-link:hover{
   color: #344675 !important;
 }
</style>
