<template>
<div :style="layoutCss">
<!--  Fullscreen image-->
  <div v-if="hint_type == 'image' && gameConfig.is_image_fullscreen && fullscreen">
    <img :style="fullscreenCss" :src="file_url">
  </div>
  <div  v-if="hint_type == 'video' && gameConfig.is_video_fullscreen && fullscreen" >
    <video refs="vid" :loop="loop_video"
           :style="fullscreenCss" crossorigin="anonymous" autoplay :src="file_url">
<!--      <source :src="file_url">-->
    </video>
  </div>
  <div class="row mr-5 ml-5" v-if="!fullscreen">
    <div class="col-12">
<!--      <card>-->
      <grid-layout :layout.sync="layout"
                     :col-num="3"
                     :max-rows="4"
                      :row-height="gameConfig.is_portrait_mode ? 167 : row_height"
                     :is-draggable="true"
                     :is-resizable="true"
                     :vertical-compact="false"
                     :use-css-transforms="true"
        >
          <grid-item v-for="(item, index) in layout" :key="index + 'B'"
                     static
                     :x="item.x"
                     :is-bounded="true"
                     :y="item.y"
                     :w="item.w"
                     :h="item.h"
                     :i="item.i"
          >
            <div v-if="item.name == 'Timer'" v-show="show_timer" class="text-center" :key="totalSeconds"
                 style="height: 100%; display: flex;
                        justify-content: center;
                        align-items: center;">
              <p :style="{'font-size': gameConfig.font_size + 'px',
              'font-family': gameConfig.font_family, 'color': gameConfig.text_color}">{{hours}}:{{minutes}}:{{seconds}}
              </p>
            </div>
            <div v-if="item.name == 'Logo'" class="text-center"
                 style="height: 100%; display: flex;
                        justify-content: center;
                        align-items: center;">
              <img :style="{'object-fit': 'contain', 'max-height': row_height * item.h + 'px'}" :src="gameConfig.company_logo">
            </div>
            <div v-if="item.name == 'Hints'" class="text-center" :key="h"
                 style="height: 100%; display: flex;
                        justify-content: center;
                        align-items: center;">
              <i v-for="j in gameConfig.clues_remaining" :key="j + 'C'"
                 :style="{'font-size': gameConfig.hint_icon_font_size + 'px', 'color': gameConfig.available_hint_color}"
                 class="ml-1" :class="'tim-icons ' + gameConfig.icon_style"></i>
              <i v-for="i in gameConfig.clues_used" :key="i  + 'D'"
                 :style="{'font-size': gameConfig.hint_icon_font_size + 'px', 'color': gameConfig.used_hint_color}"
                 class="ml-1" :class="'tim-icons ' + gameConfig.icon_style"></i>
            </div>
            <div v-if="item.name == 'Content'" class="text-center"
                        style="height: 100%; display: flex;
                        justify-content: center;
                        align-items: center;">
<!--              Text-->
              <div v-if="hint_type == 'text' || hint_type == 'custom'" >
                <p v-if="gameConfig.has_timer"
                   :style="{'font-family': gameConfig.hint_text_font, 'font-size': gameConfig.hint_font_size + 'px', 'color': gameConfig.hint_text_color}">
                  {{text_message}}
                </p>
                <p v-else :style="{'font-family': gameConfig.hint_text_font, 'font-size': gameConfig.hint_font_size + 'px', 'margin-top': '300px', 'color': gameConfig.hint_text_color}">
                  {{text_message}}
                </p>
              </div>
<!--              Image-->
              <div v-if="hint_type == 'image' && !gameConfig.is_image_fullscreen">
                <img :style="{'object-fit': 'contain', 'max-height': row_height * item.h + 'px'}" :src="file_url">
              </div>

<!--              Video-->
              <div class="col" v-if="hint_type == 'video' && !gameConfig.is_video_fullscreen">
                <video :loop="loop_video" :style="{'object-fit': 'contain', 'max-height': row_height * item.h + 'px'}" crossorigin="anonymous"
                       refs="vid" autoplay :key="'F' + videoKey" :src="file_url">
                </video>
              </div>
            </div>
          </grid-item>
        </grid-layout>
    </div>
  </div>

  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + gameConfig.font_family">
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + gameConfig.hint_text_font">

</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import VueGridLayout from 'vue-grid-layout';
import swal from "sweetalert2";

export default {
  name: "ClueDisplay",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
      background_image: [],
      layout: [
        {"x":0,"y":1,"w":3,"h":3,"i":"3", "name": "Content"},
      ],
      fullscreenCss: '',
      row_height: 210,
      layoutCss: '',
      fullscreen: false,
      background: '',
      loading: false,
      loop_video: false,
      time_status: '',
      gameConfig: '',
      text_message: '',
      show_timer: true,
      file_url: '',
      data: '',
      type: '',
      hint_type: '',
      h: 0,
      audioKey: 0,
      videoKey: 0,
      hours: '00',
      seconds: '00',
      minutes: '00',
      totalSeconds: 66,
      timerEnabled: false,
      temp_time: 0,
      time_elapsed: 0,
      lastTime: new Date(),
      testVideo: '',
      originalTotalSeconds: 0,
      keeper: false,
      backgroundAudio: null
    }
  },
  created(){
    this.startWebSocket()
    window.addEventListener("resize", this.setLayoutCss);
  },
  beforeDestroy(){
    if (this.socket) {
      this.socket.close();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.setLayoutCss);
  },
  watch: {
    totalSeconds: {
      handler(value) {
        if(value === 0){
          this.timerEnabled = false
          this.seconds = '00'
        }

        if(this.totalSeconds > parseInt(this.originalTotalSeconds + 1) && this.timerEnabled){
          this.seconds = '00'
          this.minutes = '00'
          this.hours = '00'
          this.timerEnabled = false
        }

        if (value > 0 && this.timerEnabled) {
          this.keeper = true

          let date = new Date()
          date.setHours(this.hours)
          date.setMinutes(this.minutes)
          date.setSeconds(this.seconds)
          let elapsedTime = Date.now() - this.lastTime
          this.lastTime = Date.now()

          let newDate = new Date(date.valueOf() - elapsedTime)
          let temp = newDate.toTimeString().split(" ")
          let tempsplit = temp[0].split(':')

          this.hours = tempsplit[0]
          this.minutes = tempsplit[1]
          this.seconds = tempsplit[2]
          if(this.keeper == true){
            setTimeout(() => {
              if(this.keeper === false){
                this.totalSeconds++
                return
              }
              this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) - 1
              this.keeper = false
            }, 1000);
          }

        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
    timerEnabled(value) {

      if (value) {
        setTimeout(() => {
          if(this.totalSeconds === -1){
            this.totalSeconds = 0
          }
          this.totalSeconds++;
        }, 1000);
      }
    },
  },
  methods: {
    startWebSocket(){
      let id = this.$route.query.gameId
      // if(localStorage.getItem("selectedGameDetailsForClues") && JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).hasOwnProperty('id')){
      //   id = JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id
      // } else {
      //   id = this.$route.query.gameId
      // }
      let url = 'wss://3bqa7hkquf.execute-api.us-east-1.amazonaws.com/production?gameId=' +  id
      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        console.log('WebSocket connection opened.');
        console.log(this.socket)
      };

      this.socket.onmessage = (event) => {
        console.log('Message received:', event.data);
        this.event = event.data
        this.loadMessage(this.event)
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed.');
        this.socket = null
        this.startWebSocket()
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    getTime(timer){
      let hours = timer.split(':')[0] * 3600000
      let minutes = timer.split(':')[1] * 60000
      let seconds = timer.split(':')[2] * 1000
      return hours + minutes + seconds
    },
    loadMessage(msg){
      this.type = JSON.parse(msg).type

      //background music
      if(this.type === 'start_background_music'){
        if(this.gameConfig.loop_background_music === 1){
          this.backgroundAudio.loop = true
        } else {
          this.backgroundAudio.loop = false
        }
        this.backgroundAudio.play();
      }
      if(this.type === 'pause_background_music'){
        this.backgroundAudio.pause();
      }

      if(this.type == 'image'){
        this.fullscreen = false

        //Play pre hint sound
        if(this.gameConfig.play_sound_before_hints == true && this.gameConfig.play_sound_before_img == true && this.gameConfig.pre_hint_sound_file){
          let audio = new Audio(this.gameConfig.pre_hint_sound_file);
          audio.play();
        }
        this.file_url = JSON.parse(msg).data

        if(this.gameConfig.is_image_fullscreen){
          this.fullscreen = true
        }
        this.hint_type = this.type
      }
      if(this.type == 'audio'){
        this.fullscreen = false
        if(this.gameConfig.play_sound_before_hints == true && this.gameConfig.play_sound_before_audio == true && this.gameConfig.pre_hint_sound_file){
          let audio = new Audio(this.gameConfig.pre_hint_sound_file);
          audio.play();
          setTimeout(() => {
            this.file_url = JSON.parse(msg).data
            let audio = new Audio(this.file_url);
            audio.play();
          }, 1000)

        } else {

          this.file_url = JSON.parse(msg).data
          let audio = new Audio(this.file_url);
          audio.play();
        }
        this.hint_type = this.type
      }
      if(this.type == 'video'){
        this.fullscreen = false
        if(this.gameConfig.play_sound_before_hints == true && this.gameConfig.play_sound_before_video == true && this.gameConfig.pre_hint_sound_file){
          //this.hint_type = this.type
          let audio = new Audio(this.gameConfig.pre_hint_sound_file);
          audio.play();
          setTimeout(() => {
            this.file_url = JSON.parse(msg).data
            this.videoKey++
          }, 1000)
        } else {
          //this.hint_type = this.type
          this.file_url = JSON.parse(msg).data
          this.loop_video = Boolean(JSON.parse(msg).loop)
          this.videoKey++
        }

        if(this.gameConfig.is_video_fullscreen){
          this.fullscreen = true
        }
        this.hint_type = this.type
      }
      this.data = JSON.parse(msg)
      if(this.type == 'new_timer_value'){
        this.timerEnabled = false
        const hours = parseInt(this.data.data.split(':')[0])
        const minutes = parseInt(this.data.data.split(':')[1])
        this.hours = ('0' + hours).slice(-2)
        this.minutes = ('0' + minutes).slice(-2)
        this.seconds = '00'
        this.gameConfig.max_time = hours + ": " + minutes + ":00"
        this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
        this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2
        return
      }
      if(this.type == 'timer'){
        if(this.data.data == 'start'){
          //autosync === off
          if(!this.gameConfig.auto_sync){
            this.lastTime = new Date()
            this.timerEnabled = true
          }
          this.time_status = 'start'
        } else if(this.data.data == 'reset') {
          this.timerEnabled = false
          this.hours = ('0' +  parseInt(this.gameConfig.max_time.split(':')[0])).slice(-2)
          this.minutes = ('0' +  parseInt(this.gameConfig.max_time.split(':')[1])).slice(-2)
          this.seconds = ('0' +  parseInt(this.gameConfig.max_time.split(':')[2])).slice(-2)
          this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
          this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2
          this.time_status = 'reset'
        } else if(this.data.data == 'show') {
          this.show_timer = true
        } else if(this.data.data == 'hide') {
          this.show_timer = false
        } else {
          this.timerEnabled = false
          this.time_status = 'paused'
        }
        return
      }
      if(this.type == 'text' || this.type == 'custom'){
        this.fullscreen = false
        if(this.type == 'custom' && this.gameConfig.play_sound_before_hints == true && this.gameConfig.play_sound_before_custom == true && this.gameConfig.pre_hint_sound_file){
          let audio = new Audio(this.gameConfig.pre_hint_sound_file);
          audio.play();
        }
        if(this.type == 'text' && this.gameConfig.play_sound_before_hints == true && this.gameConfig.play_sound_before_text == true && this.gameConfig.pre_hint_sound_file){
          let audio = new Audio(this.gameConfig.pre_hint_sound_file);
          audio.play();
        }
        this.hint_type = this.type
        this.text_message = JSON.parse(msg).data
      }

      if((JSON.parse(msg).count_as_hint || this.type === 'sync') && this.gameConfig.clues_remaining > 0
        && this.type != 'extra_hint'
        && this.type != 'remove_extra_hint'
        && this.type != 'clear'
        && JSON.parse(msg).type != 'reset_hints'
        && JSON.parse(msg).type != 'remove_hint'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) - 1
        this.gameConfig.clues_used = parseInt(this.gameConfig.clues_used) + 1
        this.h++
      }

      if(this.type == 'clear'){
        this.fullscreen = false
        this.hint_type = this.type
      }

      if(this.type == 'extra_hint'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) + 1
        this.h++
      }
      if(JSON.parse(msg).type == 'remove_extra_hint'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) - 1
        this.gameConfig.clues_remaining = this.gameConfig.clues_remaining > 0 ? this.gameConfig.clues_remaining : 0
        this.h++
      }
      else if(JSON.parse(msg).type == 'reset_hints'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.max_clues)
        this.gameConfig.clues_used = 0
        this.h++
      } else if (JSON.parse(msg).type == 'remove_hint'){
        if(parseInt(this.gameConfig.clues_used) > 0){
          this.gameConfig.clues_used--
          this.gameConfig.clues_remaining++
        }
        this.h++
      }
      if(JSON.parse(msg).type == 'sync'){
        if(!this.gameConfig.auto_sync){
          this.lastTime = new Date()
          this.timerEnabled = false
        }

        this.hours = ('0' +  parseInt(this.data.data.timer.split(':')[0])).slice(-2)
        this.minutes = ('0' +  parseInt(this.data.data.timer.split(':')[1])).slice(-2)
        this.seconds = ('0' +  parseInt(this.data.data.timer.split(':')[2])).slice(-2)
        this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
        this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2

        if(!this.gameConfig.auto_sync){
          if(this.timerEnabled != this.data.data.timer_active){
            this.timerEnabled = false
            this.timerEnabled = this.data.data.timer_active
          }
        }
        this.time_status = 'start'
        this.gameConfig.clues_used = this.data.data.clues_used
        this.gameConfig.clues_remaining = this.data.data.clues_remaining
      }
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    setLayoutCss(){
      let windowHeight = window.innerHeight;

      if(this.gameConfig.display_background_image){
        let url = this.gameConfig.img
        if(this.background_image.length > 0){
          url = this.background_image[0].url
        }

        let fit = ' / cover'
        if(this.background_image.length > 0 && this.background_image[0].object_fit === 'cover'){ fit = ' / cover'}
        else if(this.background_image.length > 0 && this.background_image[0].object_fit === 'none'){ fit = ''}

        let background_color = 'black'
        let additional_image_formatting = ''
        if(this.background_image.length > 0){
          background_color = this.background_image[0].background_color
          additional_image_formatting = background_color + ' no-repeat center' + fit
        }

        this.layoutCss = { 'background': 'url('+ url + ') '
            + additional_image_formatting, 'overflow': 'hidden',
          'position' : 'absolute',
          'top' : '0px',
          'right' : '0px',
          'bottom':'0px',
          'left':'0px',
          'object-fit': this.background_image.length > 0 ? '' : 'cover',
            'background-size': this.background_image.length > 0 ? '' : 'cover',
          'height': windowHeight + 'px', 'overflow-x': 'hidden'}

      } else {
        this.layoutCss = {'background-color': this.gameConfig.background_color, 'height': windowHeight + 'px', 'overflow-x': 'hidden'}
      }

      this.fullscreenCss = {
        'width': '100%',
        'height': windowHeight + 'px',
        'padding': '0',
        'margin': '0',
        'object-fit': 'contain'
      }
    },
    async getGameConfigurations(){
      let gameId = this.$route.query.gameId
      this.loading = true
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/clue-display-configurations'
      axios.get(url)
        .then(response => {
          this.loading = false
          this.gameConfig = response.data.configurations
          this.background_image = response.data.background_image
          this.layout = response.data.layout
          this.setLayoutCss()
          this.gameConfig.clues_used = 0
          this.gameConfig.clues_remaining = parseInt(this.gameConfig.max_clues) - parseInt(this.gameConfig.clues_used)

          this.hours = ('0' +  parseInt(this.gameConfig.max_time.split(':')[0])).slice(-2)
          this.minutes = ('0' +  parseInt(this.gameConfig.max_time.split(':')[1])).slice(-2)
          this.seconds = ('0' +  parseInt(this.gameConfig.max_time.split(':')[2])).slice(-2)
          this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
          this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2

          if(this.gameConfig.play_background_music){
            this.backgroundAudio = new Audio(this.gameConfig.background_music_sound_file);
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted(){
    this.getGameConfigurations()
  }
}
</script>

<style scoped>
</style>
