<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/company">Company settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{group.name}}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

<!--  New group-->
  <div class="row d-flex justify-content-center" v-if="create_new_group">
    <div class="col-10">
      <new-group-wizard></new-group-wizard>
    </div>
  </div>
  <div class="row d-flex justify-content-center" v-else>
    <div class="col-4">
      <card class="card-user">
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-two"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
          <a href="javascript:void(0)">
            <img v-if="group.img != null" class="avatar" :src="group.img" alt="..." />
            <img v-if="group.img == null" class="avatar" src="https://console.offthecouch.io/img/placeholder.jpg" alt="..." />
          </a>
          <div>
            <span class="btn btn-primary btn-sm btn-simple btn-file">Change group image
                <input
                  accept="image/*"
                  @change="handleImageUpload"
                  type="file"
                  name="..."
                  class="valid"
                  :multiple="false"
                  aria-invalid="false"
                />
            </span>
          </div>
        </div>
        <h3 slot="header" class="card-title">{{group.name}}</h3>
        <div class="card-description">
          <div class="row">
            <div class="col">
              <base-input required label="Group name"
                          type="text"
                          placeholder="Group name"
                          v-model="group.name"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input required label="Group code"
                          type="text"
                          placeholder="Group code"
                          v-model="group.code"
                          :error="errs.group_code"
              >
              </base-input>
            </div>
            <div class="col">
              <base-input label="Company number"
                          type="text"
                          placeholder="Company number"
                          v-model="group.company_number"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input label="Description">
                <el-input
                  v-model="group.description"
                  :rows="5"
                  type="textarea"
                  placeholder="Group description"
                />
              </base-input>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <base-button v-on:click="updateGroup()" type="primary">
              Update group
            </base-button>
          </div>
        </div>

      </card>
    </div>

<!--    Locale, Games-->
    <div class="col-8">
      <card>
<!--        Sections-->
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

<!--        Location details-->
        <div v-if="activeTabIndex === 0">
          <div class="row">
            <div class="col-7">
              <base-input label="Email"
                          type="text"
                          placeholder="Email"
                          v-model="group.email"
              >
              </base-input>
            </div>
            <div class="col">
              <base-input label="Phone"
                          :placeholder="group.country.phone_placeholder"
                          v-model="group.phone"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input label="Address line 1"
                          type="text"
                          placeholder="Address line 1"
                          v-model="group.address_line_1"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input label="Address line 2"
                          type="text"
                          placeholder="Address line 2"
                          v-model="group.address_line_2"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-input :label="group.country.city_label"
                          type="text"
                          :placeholder="group.country.city_label"
                          v-model="group.city"
              >
              </base-input>
            </div>
            <div class="col">
              <base-input v-if="group.country.state_label === 'State'" :label="group.country.state_label">
                <el-select
                  v-model="group.state"
                  class="select-primary"
                  name="email_group"
                  :placeholder="group.country.state_label"
                >
                  <el-option
                    v-for="(s, i) in states"
                    class="select-primary"
                    :label="s.name"
                    :value="s.name"
                    :key="'S' + i"
                  ></el-option>
                </el-select>
              </base-input>
              <base-input v-else :placeholder="group.country.state_label" :label="group.country.state_label" v-model="group.state"></base-input>
            </div>
            <div class="col-2">
              <base-input :label="group.country.zip_label"
                          type="text"
                          :placeholder="group.country.zip_placeholder"
                          v-model="group.zip"
              >
              </base-input>
            </div>
            <div class="col-3">
              <base-input label="Country">
                <el-select
                           v-model="group.country"
                           class="select-primary"
                           name="email_group"
                           placeholder="Country"
                           value-key="code"
                >
                  <el-option
                    v-for="(c, i) in countries"
                    class="select-primary"
                    :label="c.name"
                    :value="{name: c.name, code: c.code, city_label: c.city_label,
                      state_label: c.state_label, zip_label: c.zip_label, zip_placeholder: c.zip_placeholder,
                      phone_placeholder: c.phone_placeholder}"
                    :key="'C' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <base-button v-on:click="updateGroup()" type="primary">
                Update location details
              </base-button>
            </div>
          </div>
        </div>


        <!--       Games -->
        <div v-if="activeTabIndex === 1">
          <!--   No data panel -->
          <div v-if="games.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No games associated with this group.</h4>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <b-table striped hover :fields="fields" :items="games">
                <template v-slot:cell(game)="row">
                  <div class="row">
                    <div class="mt-1">
                      <img class="round-img" :src="row.item.img"/>
                    </div>
                    <div class="col mt-2">
                      <p class="card-title">{{row.item.name}}</p>
                      <small>{{row.item.description}}</small>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-tooltip content="Edit game"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="editGame(row.item)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon
                    ><i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <!--        Location details-->
        <div v-if="activeTabIndex === 2">
          <div class="row">
            <div class="col-lg-4">
              <h4 class="card-title">Locale</h4>
              <small >These are regional settings for your location.</small>
            </div>
            <div class="col-lg-3">
              <base-input label="Currency">
                <el-select
                  v-model="group.currency"
                  class="select-primary"
                  name="currency"
                  placeholder="Currency"
                >
                  <el-option
                    v-for="(c, i) in currencies"
                    class="select-primary"
                    :label="c.currency + ' (' + c.symbol + ')'"
                    :value="c.code"
                    :key="'C' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-5">
              <base-input label="Timezone">
                <el-autocomplete style="width: 100%"
                                 v-model="group.timezone"
                                 :fetch-suggestions="querySearch"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 :value="group.timezone"
                                 @select="handleSelect"
                >
                  <template #default="{ item }">
                    <span>{{ item.value }} - {{item.abbr}} ({{item.offset}})</span>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-4">
              <h4 class="card-title">Formats</h4>
              <small>Define how dates, times, currency, and numbers will be displayed throughout the application.</small>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-4">
                  <base-input label="Date format">
                    <el-select
                      v-model="group.date_format"
                      class="select-primary"
                      name="date_format"
                      placeholder="Date format"
                      value-key="format"
                    >
                      <el-option
                        v-for="(d, i) in date_formats"
                        class="select-primary"
                        :label="d.example + ' (' + d.format + ')'"
                        :value="{format: d.format, example: d.example}"
                        :key="'D' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                  <base-checkbox v-model="group.show_day_of_week">Display day of the week</base-checkbox>
                </div>
                <div class="col-lg-4">
                  <base-input label="Time format">
                    <el-select
                      v-model="group.time_format"
                      class="select-primary"
                      name="time_format"
                      placeholder="Time format"
                      value-key="format"
                    >
                      <el-option
                        v-for="(f, i) in time_formats"
                        class="select-primary"
                        :label="f.example + ' (' + f.format + ')'"
                        :value="{format: f.format, example: f.example}"
                        :key="'F' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-4">
                  <base-input label="First day of the week">
                    <el-select
                      v-model="group.first_day_of_week"
                      class="select-primary"
                      name="first_day_of_week"
                      placeholder="First day of the week"
                      value-key="id"
                    >
                      <el-option
                        v-for="(w, i) in days_of_the_week"
                        class="select-primary"
                        :label="w.name"
                        :value="{id: w.id, name: w.name}"
                        :key="'W' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col">-->
<!--                  <base-checkbox>Include day of the week</base-checkbox>-->
<!--                </div>-->
<!--              </div>-->
              <div class="row mt-3">
                <div class="col-lg-6">
                  <base-input label="Currency format">
                    <el-select
                      v-model="group.currency_format"
                      class="select-primary"
                      name="currency_format"
                      placeholder="Currency format"
                      value-key="id"
                    >
                      <el-option
                        v-for="(cf, i) in currency_formats"
                        class="select-primary"
                        :label="cf.example"
                        :value="{id: cf.id}"
                        :key="'CF' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-6">
                  <base-input label="Number format">
                    <el-select
                      v-model="group.number_format"
                      class="select-primary"
                      name="time_format"
                      placeholder="Time format"
                      value-key="id"
                    >
                      <el-option
                        v-for="(n, i) in number_formats"
                        class="select-primary"
                        :label="n.example"
                        :value="{ id: n.id }"
                        :key="'N' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-2">
            <div class="col">
              <base-button v-on:click="updateGroup()" type="primary">
                Update locale and formatting
              </base-button>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 3">
          <div v-if="!selectedIntegration">
            <h4 class="card-title">Marketing</h4>
            <div class="row">
              <!--          Google tag-->

              <div class="col-lg-4" v-for="(p, i) in integrations" :key="'I' + i">
                <card class="card-white">
                  <div class="row">
                    <div class="col text-right">
                      <span v-if="p.id === 1">
                         <badge v-if="group.google_tag_active" type="success">active</badge>
                        <badge v-else type="default">inactive</badge>
                      </span>
                      <span v-if="p.id === 2">
                         <badge v-if="group.pixel_active" type="success">active</badge>
                        <badge v-else type="default">inactive</badge>
                      </span>
                      <span v-if="p.id === 3">
                         <badge v-if="group.ga4_active" type="success">active</badge>
                        <badge v-else type="default">inactive</badge>
                      </span>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <img :src="p.logo" style="object-fit: scale-down" height="60"/>
                  </div>
                  <div class="row mt-1 justify-content-center">
                    {{p.name}}
                  </div>
                  <div class="row mt-2 justify-content-center">
                    <base-button class="button-wide" v-on:click="configureIntegration(p)" type="info" >Configure integration</base-button>
                  </div>
                </card>
              </div>
            </div>
            <h4 class="card-title">Productivity</h4>
            <div class="row">
              <!--          Google tag-->

              <div class="col-lg-4" v-for="(pr, i) in productivity_integrations" :key="'PR' + i">
                <card class="card-white">
                  <div class="row">
                    <div class="col text-right">
                      <span v-if="pr.id === 4">
                         <badge v-if="group.gcal_active" type="success">active</badge>
                        <badge v-else type="default">inactive</badge>
                      </span>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <img :src="pr.logo" style="object-fit: scale-down" height="60"/>
                  </div>
                  <div class="row mt-1 justify-content-center">
                    {{pr.name}}
                  </div>
                  <div class="row mt-2 justify-content-center">
                    <base-button class="button-wide" v-on:click="configureIntegration(pr)" type="info" >Configure integration</base-button>
                  </div>
                </card>
              </div>
            </div>
          </div>

<!--          Google tag-->
          <div v-if="selectedIntegration.id === 1">
            <div class="row">
              <div class="col-lg-3">
                <h4 class="card-title">Google Tag Manager
                  <img :src="selectedIntegration.logo" style="object-fit: scale-down" height="20"/>
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input v-model="group.google_tag_container_id" label="Container ID"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="togglebutton switch-change-color mt-3">
                  <el-switch active-color="#e14eca"
                             v-model="group.google_tag_active"  />
                  <label> &nbsp; Active</label>
                </div>
              </div>
            </div>
          </div>

          <!--         Meta Pixel-->
          <div v-if="selectedIntegration.id === 2">
            <div class="row">
              <div class="col-lg-3">
                <h4 class="card-title">Meta Pixel
                  <img :src="selectedIntegration.logo" style="object-fit: scale-down" height="30"/>
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input v-model="group.pixel_id" label="Meta Pixel ID"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="togglebutton switch-change-color mt-3">
                  <el-switch active-color="#e14eca"
                             v-model="group.pixel_active"  />
                  <label> &nbsp; Active</label>
                </div>
              </div>
            </div>
          </div>

          <!--         GA4-->
          <div v-if="selectedIntegration.id === 3">
            <div class="row">
              <div class="col-lg-3">
                <h4 class="card-title">Google Analytics 4
                  <img :src="selectedIntegration.logo" style="object-fit: scale-down" height="30"/>
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input v-model="group.ga4_id" label="Measurement ID"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="togglebutton switch-change-color mt-3">
                  <el-switch active-color="#e14eca"
                             v-model="group.ga4_active"  />
                  <label> &nbsp; Active</label>
                </div>
              </div>
            </div>

            <p class="mt-3">Important: When embedding the booking page in an iframe, it's necessary for your site to handle the analytics events.
              We provide these events to the parent domain using postMessage.
              You will need to implement code to capture and manage these events accordingly. Below is example code snipped you can include:</p>
            <div class="mt-2">
              <base-alert type="white" class="text-muted">

                <div class="row">
                  <div class="col-10" style="padding-right: 0">
                    <code>{{ embedded_text }}</code>
                  </div>
                  <div class="col text-right">
                    <base-button class="like btn-link"
                                 type="default"
                                 size="sm" style="padding: 0px 0px 2px"

                                 v-on:click="copy(embedded_text)">
                      <i class="tim-icons icon-single-copy-04"></i>
                    </base-button>
                  </div>
                </div>
              </base-alert>
            </div>
          </div>

          <!--          GCal-->
          <div v-if="selectedIntegration.id === 4">
            <div class="row">
              <div class="col-lg-3">
                <h4 class="card-title">Google calendar
                  <img :src="selectedIntegration.logo" style="object-fit: scale-down" height="20"/>
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>To set up the integration with Google Calendar,
                  you must first create a Service Account in Google Cloud and upload the credentials file to Off The Couch.
                  This allows our app to securely communicate with Google Calendar on your behalf. Follow these steps: </p>
                <p class="mt-3 card-title">1. Create a Service Account</p>
                <ul>
                  <li>Go to the <a target="_blank" href="https://console.cloud.google.com/">Google Cloud Console</a>.</li>
                  <li>Navigate to <b>IAM & Admin</b> > <b>Service Accounts</b> and click <b>Create Service Account</b>.</li>
                  <li>Name the service account and assign it a role with <b>Calendar API</b> permissions.</li>
                </ul>
                <p class="mt-3 card-title">2. Enable the Calendar API</p>
                <ul>
                  <li>Go to <b>APIs & Services</b> > <b>Library</b> in <b>Google Cloud Console</b>.</li>
                  <li>Search for "Google Calendar API" and click <b>Enable</b>.</li>
                </ul>
                <p class="mt-3 card-title">3. Generate a key file</p>
                <ul>
                  <li>In the <b>Service Accounts</b> page, select the service account you created.</li>
                  <li>Click <b>Keys</b> > <b>Add Key</b> > <b>Create New Key</b>.</li>
                  <li>Choose JSON format and download the key file.</li>
                </ul>
                <p class="mt-3 card-title">4. Add your service account to calendar</p>
                <ul>
                  <li>For your desired Calendar, navigate to <b>Calendar Settings</b> and scroll to <b>Share with specific people or groups</b>.</li>
                  <li>Click <b>Add people and groups</b> and add the email of your service account with <b>Make changes to events</b> permission selected.</li>
                </ul>
                <p class="mt-3 card-title">5. Upload credentials to Off The Couch</p>
                <ul>
                  <li>Locate the service account email and the private key in the downloaded JSON file and paste them into the form below.</li>
                  <li>For your desired Calendar, navigate to <b>Calendar Settings</b> and scroll to <b>Integrate Calendar</b> section to get your Calendar ID.</li>
                  <li>Off The Couch will now be able to create and modify Google Calendar events.</li>
                </ul>
                <p class="mt-3 card-title">6. Configure custom event fields</p>
                <ul>
                  <li>Use dynamic variables to configure <code>Title</code> and <code>Descriptions</code> fields for your events.</li>
                </ul>
                <p class="mt-3 card-title text-primary">Supported dynamic variables
                  <i class="tim-icons icon-minimal-down ml-1" v-on:click="show_dynamic_variables = !show_dynamic_variables" style="cursor: pointer"></i>
                </p>
                <div v-if="show_dynamic_variables">
                  <div>
                    <code>[Booking Id]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Booking Id]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Game Name]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Game Name]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Group Size]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Group Size]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Customer First Name]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Customer First Name]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Customer Last Name]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Customer Last Name]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Customer Phone]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Customer Phone]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Customer Email]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Customer Email]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Transaction Id]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Transaction Id]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Transaction Link]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Transaction Link]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Amount Paid]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Amount Paid]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                  <div>
                    <code>[Amount Due]
                      <el-tooltip content="Copy"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button
                          @click.native="copy('[Amount Due]')"
                          class="like btn-link text-white"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-single-copy-04"></i>
                        </base-button>
                      </el-tooltip>
                    </code>
                  </div>
                </div>
              </div>
            </div>
            <hr style="border-top: 1px solid #f4f5f7;">
            <div v-if="edit_gcal">
              <div class="row">
                <div class="col">
                  <base-input :error="errs.gcal_client_email" v-model="group.gcal_client_email"
                              placeholder="your-service-account@your-project-id.iam.gserviceaccount.com"
                              label="Service account email (client email)"></base-input>
                </div>
                <div class="col-lg-6">
                  <base-input :error="errs.calendar_id"
                              placeholder="team-calendar-id@group.calendar.google.com"
                              v-model="group.calendar_id" label="Calendar ID"></base-input>
                </div>

              </div>
              <div class="row">
                <div class="col">
                  <base-input label="Private key" :error="errs.gcal_private_key">
                    <el-input
                      v-model="gcal_private_key"
                      :rows="7"
                      type="textarea"
                      placeholder="Your service account private key goes here..."
                    />
                  </base-input>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col">
                  <label>Service account email (client email)
                  </label>
                  <p>{{group.gcal_client_email}}</p>
                </div>
                <div class="col-lg-3 text-right">
                  <base-button link size="sm" type="primary" v-on:click="edit_gcal = true">
                    <i class="tim-icons icon-refresh-02 mr-1"></i> Change credentials
                  </base-button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <label>Calendar ID</label>
                  <p>{{group.calendar_id}}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input :error="errs.gcal_event_title"
                            placeholder="Event summary"
                            v-model="group.gcal_event_title" label="Calendar event summary"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-input label="Calendar event description">
                  <el-input
                    v-model="group.gcal_event_description"
                    :rows="3"
                    type="textarea"
                    placeholder="Add a custom description..."
                  />
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="togglebutton switch-change-color mt-3">
                  <el-switch active-color="#e14eca"
                             v-model="group.gcal_active"  />
                  <label> &nbsp; Active</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedIntegration">
            <div class="row mt-2">
              <div class="col">
                <base-button v-on:click="cancelIntegration()" type="default">
                  Cancel
                </base-button>
                <base-button class="ml-2" v-on:click="updateIntegration()" type="primary">
                  Save
                </base-button>
              </div>
            </div>
          </div>

        </div>

      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import NewGroupWizard from './NewGroupWizard'
import swal from "sweetalert2";
import currencies from "@/assets/currencies.json";
import date_formats from "@/assets/date_formats.json";
import time_formats from "@/assets/time_formats.json";
import days_of_the_week from "@/assets/days_of_the_week.json";
import countries from "@/assets/countries.json";
import number_formats from "@/assets/number_formats.json";
import { BTable } from 'bootstrap-vue'
import _ from "lodash";
import states from "@/assets/states.json";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Badge from "@/components/Badge"
import {BaseAlert} from 'src/components';
import moment from 'moment-timezone'
import {Autocomplete, Switch} from "element-ui";


export default {
  name: "GroupSettings",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    NewGroupWizard,
    BTable,
    Badge,
    [Switch.name]: Switch,
    BaseAlert,
    [Autocomplete.name]: Autocomplete,
  },
  computed: {
    categories() {
      return [{ name: 'Location details', icon: 'tim-icons el-icon-location-information', active: true },
        { name: 'Games', icon: 'tim-icons  icon-puzzle-10', active: false },
        { name: 'Locale and formatting', icon: 'tim-icons  icon-world', active: false },
        { name: 'Integrations', icon: 'tim-icons  icon-molecule-40', active: false }
      ];
    },
    embedded_text(){
      return 'window.addEventListener(\'message\',function(e){if(e.origin!==\'https://offthecouch.io\')return;gtag(\'event\',e.data.eventType);});\n'
    }
  },
  data(){
    return {
      show_dynamic_variables: false,
      edit_gcal: '',
      gcal_private_key: null,
      create_new_group: false,
      loading: false,
      group: {
        country:{
          "city_label": "City",
          "state_label": "State",
          "zip_label": "Zip",
          "zip_placeholder": "95133",
          "phone_placeholder": "(212) 555-1234"
        }
      },
      games: '',
      file: '',
      uploadUrl: '',
      j: 0,
      activeTabIndex: 0,
      timezones: [],
      currencies: currencies.currencies,
      date_formats: date_formats.dateFormats,
      currency_formats: currencies.formats,
      time_formats: time_formats.timeFormats,
      days_of_the_week: days_of_the_week.daysOfTheWeek,
      number_formats: number_formats.numberFormats,
      countries: countries.countries,
      states: states.states,
      fields: ['game', 'actions'],
      errs: {
        group_code: '',
        gcal_client_email: '',
        gcal_private_key: '',
        calendar_id: '',
        gcal_event_title: ''
      },
      integrations: [],
      productivity_integrations: [],
      selectedIntegration: '',
    }
  },
  methods:{
    handleSelect(item){
      this.group.timezone = item.name
      this.group.timezone_object = item
    },
    async querySearch(queryString, cb) {
      const results = queryString
        ? this.timezones.filter(this.createFilter(queryString))
        : this.timezones
      cb(results)
    },
    createFilter(queryString) {
      return (timezone) => {
        // Normalize query for case-insensitive matching
        const lowerCaseQuery = queryString.toLowerCase();

        // Check if the query matches any field of the timezone object
        return (
          timezone.value.toLowerCase().includes(lowerCaseQuery) || // Match on timezone name
          timezone.abbr.toLowerCase().includes(lowerCaseQuery) || // Match on abbreviation
          timezone.offset.includes(lowerCaseQuery) // Match on offset
        );
      };
    },
    copy(item) {
      navigator.clipboard.writeText(item);
    },
    cancelIntegration(){
      if(this.edit_gcal && this.selectedIntegration.id === 4){
        this.edit_gcal = false
      } else {
        this.selectedIntegration = ''
      }
    },
    configureIntegration(item){
      this.selectedIntegration = item
    },
    editGame(game){
      this.$router.push({ path: '/games/edit', query: { id:  game.id }})
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    handleImageUpload(){
      let file = event.target.files[0];
      this.group.img = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async saveFile() {
      if(this.file != ''){
        this.uploadUrl = await this.getSignedURL(this.file)
        let uploaded = await this.uploadFile(this.file)
        //Get the image URL
        let imgUrl = this.uploadUrl.split("?")
        this.group.img = imgUrl[0]
      }
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    validFields(){
      let errors = 0
      if(this.hasWhiteSpace(this.group.code)){
        this.errs.group_code = 'Group code cannot contain spaces'
        errors++
      } else if(this.group.code.length > 25){
        this.errs.group_code = 'Group code cannot be more than 25 characters'
        errors++
      } else if(this.group.code.length <= 0){
        this.errs.group_code = 'Group code cannot be empty'
        errors++
      } else {
        this.errs.group_code = ''
      }
      return errors > 0 ? false : true
    },
    hasWhiteSpace(s) {
      return /\s/g.test(s);
    },
    validateGcalFields(){
      let errors = 0

      if(this.edit_gcal && (this.gcal_private_key === null || this.gcal_private_key.length <= 0)){
        this.errs.gcal_private_key = 'Private key cannot be empty'
        errors++
      } else {
        this.errs.gcal_private_key = ''
      }

      if(this.edit_gcal && this.group.calendar_id.length <= 0){
        this.errs.calendar_id = 'Calendar ID cannot be empty'
        errors++
      } else {
        this.errs.calendar_id = ''
      }

      if(this.edit_gcal && this.group.gcal_client_email.length <= 0){
        this.errs.gcal_client_email = 'Service account email cannot be empty'
        errors++
      } else {
        this.errs.gcal_client_email = ''
      }

      if(this.group.gcal_event_title.length <= 0){
        this.errs.gcal_event_title = 'Calendar event summary cannot be empty'
        errors++
      } else {
        this.errs.gcal_event_title = ''
      }

      return errors > 0 ? false : true
    },
    async updateIntegration(){
      if(this.selectedIntegration.id === 4 && !this.validateGcalFields()){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = this.$route.query.id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/integrations'
      let data = {
        pixel_id: this.group.pixel_id,
        pixel_active: this.group.pixel_active,
        google_tag_container_id: this.group.google_tag_container_id,
        google_tag_active: this.group.google_tag_active,
        ga4_active: this.group.ga4_active,
        ga4_id: this.group.ga4_id
      }
      if(this.selectedIntegration.id === 4){
        data = {
          gcal_active: this.group.gcal_active,
          gcal_event_title: this.group.gcal_event_title,
          gcal_event_description: this.group.gcal_event_description
        }
        if(this.gcal_private_key){
          data.gcal_private_key = this.gcal_private_key
          data.calendar_id = this.group.calendar_id
          data.gcal_client_email = this.group.gcal_client_email
        }
      }
      axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Integration has been updated.', 'success')
          this.selectedIntegration = ''
          this.getGroup()
        })
        .catch(err => {
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
        .finally(response => {
          this.loading = false
          this.gcal_private_key = null
        })
    },
    async updateGroup(){
      if(!this.validFields()){
        return
      }
      this.loading = true
      await this.saveFile()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = this.$route.query.id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId
      let data = {...this.group}
      data.timezone = {
        value: data.timezone_object.value,
        abbr: data.timezone_object.abbr,
        offset: moment.tz(data.timezone_object.value).utcOffset() / 60
      }

      axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Group has been updated.', 'success')
          this.getGroup()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGroup(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId

      this.loading = true
      axios.get(url, config)
        .then(response => {
          localStorage.removeItem('editing')
          this.group = response.data.company_group
          this.games = response.data.games
          //Set correct locale and formats
          this.group.date_format = _.find(this.date_formats, { format: this.group.date_format });
          this.group.time_format = _.find(this.time_formats, { format: this.group.time_format });
          this.group.first_day_of_week = _.find(this.days_of_the_week, { id: this.group.first_day_of_week });
          this.group.number_format = _.find(this.number_formats, { id: this.group.number_format });
          this.group.currency_format = _.find(this.currency_formats, { id: this.group.currency_format });
          this.group.country = _.find(this.countries, { code: this.group.country })
          if(!this.group.country){
            this.group.country = {code: ''}
          }

          this.group.timezone_object = _.find(this.timezones, {value: this.group.tz_name});
          this.group.timezone = this.group.timezone_object.value + ' - '
            + this.group.timezone_object.abbr + ' (' + this.group.timezone_object.offset + ')'
          this.group.google_tag_active = Boolean(this.group.google_tag_active)
          this.group.ga4_active = Boolean(this.group.ga4_active)
          this.group.pixel_active = Boolean(this.group.pixel_active)
          this.group.google_tag_active = Boolean(this.group.google_tag_active)
          this.group.gcal_active = Boolean(this.group.gcal_active)
          this.edit_gcal = this.group.gcal_client_email ? 0 : 1
          this.integrations = response.data.integrations
          this.productivity_integrations = response.data.productivity_integrations
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    fetchTimezonesWithAbbreviations() {
      const timezones = moment.tz.names();
      const timezoneAbbreviations = timezones.map((timezone) => {
        return {
          value: timezone,
          abbr: moment.tz(timezone).format("z"), // Get the current abbreviation
          offset: moment.tz(timezone).format("Z"),
          name: timezone + ' - ' + moment.tz(timezone).format("z") + ' (' + moment.tz(timezone).format("Z") + ')'
        };
      });
      this.timezones = timezoneAbbreviations; // Update the component's data
    }
  },
  async mounted() {
    await this.fetchTimezonesWithAbbreviations();
    let query = this.$route.query
    if(!query.hasOwnProperty('id')){
      this.create_new_group = true
    } else {
      await this.getGroup()
    }

  }
}
</script>

<style scoped>
.round-img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>
