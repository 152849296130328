<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--  Feature not enabled-->
  <div v-if="feature_not_enabled" class="mt-3" >
    <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
      <h4 class="mt-3">This feature is not enabled.</h4>
      <base-button type="primary" v-if="hasPermissions('billing_view')"
                   v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
      <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
    </div>
  </div>

  <div v-if="!feature_not_enabled">
    <!--    Create and clear schedule button -->
    <div class="row mb-2" v-if="hasPermissions('checklists_update')">
      <div class="col">
        <base-button v-on:click="createChecklistPrompt()" type="primary" >
          <i class="tim-icons icon-simple-add mr-2"></i> Create new checklist</base-button>
      </div>

    </div>

    <div v-if="!isTouchDevice">
      <div class="col">
        <card>
          <h4 class="card-title">Checklists</h4>
          <!--   No data panel -->
          <div v-if="checklists.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No checklists have been created.</h4>
            </div>
          </div>

          <div class="row d-none d-sm-block">
            <div class="col">
              <table class='table' v-if="checklists.length > 0">
                <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>Checklist</th>
                  <th scope='col'>Game instance</th>
                  <th scope='col'></th>
                  <th scope='col'>Actions</th>
                </tr>
                </thead>
                <tbody tag='tbody' v-model='checklists' is='draggable' >
                  <tr v-on:dragend="saveOrder"  v-for='(item, index) in checklists' :key="'A' + index + j" style="cursor: pointer">
                    <td scope='row'>
                      <i style="cursor: move" class="ml-3 tim-icons el-icon-rank"></i>
                    </td>
                    <td  scope='row' style="vertical-align: top">
                      <p>{{item.name}}
                      </p>
                      <small>{{item.description}}</small>
                    </td>
                    <td scope='row' style="vertical-align: top">
                      <badge v-if="item.game_id && getGameName(item.game_id)" type="primary">{{getGameName(item.game_id)}}</badge>
                      <span v-else>-</span>
                      <div class="row" v-if="getInstances(item.game_instances).length > 0 && getInstances(item.game_instances)[0].id !== null">
                        <div class="col">
                          <base-input v-if="item.game_id" label="Game instance">
                            <el-select clearable
                                       class="select-primary"
                                       name="games"
                                       placeholder="Game instance"
                                       v-model="item.selectedGameInstance"
                                       value-key="id"
                            >
                              <el-option
                                v-for="(g, i) in getInstances(item.game_instances)"
                                class="select-primary"
                                :label="getDate(g.booking_date) + ', ' + getTime(g.start_time)"
                                :value="{ game_name: g.game_name, id: g.id, booking_date: g.booking_date, start_time: g.start_time}"
                                :key="'G' + i"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
                    </td>
                    <td scope='row' class="text-right" style="vertical-align: top">
                      <el-dropdown v-if="hasPermissions('checklists_delete')
                          || hasPermissions('checklists_update')">
                        <base-button
                          link
                          type="primary"
                          size="sm"
                        > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                        </base-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <a class="dropdown-item mt-1 mb-2" v-if="hasPermissions('checklists_update')"
                               @click="editChecklist(item, $event)" href="#">
                              <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit checklist
                            </a>
                            <a class="dropdown-item mt-1 mb-2" v-if="hasPermissions('checklists_update')"
                               @click="duplicateChecklist(item, $event)" href="#">
                              <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Duplicate checklist
                            </a>
                            <hr v-if="hasPermissions('checklists_delete') && hasPermissions('checklists_update')" >
                            <a class="dropdown-item" v-if="hasPermissions('checklists_delete')"
                               @click="deleteChecklistPrompt(item, $event)" href="#">
                              <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete checklist
                            </a>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </td>
                    <td scope='row' style="vertical-align: top">
                      <base-button
                        @click.native="startChecklist(item)"
                        type="primary"
                        size="sm"
                      >
                        Start
                      </base-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div v-else>
      <div class="col">
        <card>
          <h4 class="card-title">Checklists</h4>
          <b-table  striped hover :items="checklists"
                    :fields="['checklist']">
            <template #head(checklist)="data">
              <span> </span>
            </template>
            <template v-slot:cell(checklist)="row">
              <div class="row">
                <div class="col">
                  <p>{{row.item.name}}</p>
                </div>
                <div class="col-3 text-right">
                  <el-dropdown v-if="hasPermissions('checklists_delete')
                          || hasPermissions('checklists_update')">
                    <base-button
                      link
                      type="primary"
                      size="sm"
                    > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-1 mb-2" v-if="hasPermissions('checklists_update')"
                           @click="editChecklist(row.item, $event)" href="#">
                          <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit checklist
                        </a>
                        <a class="dropdown-item mt-1 mb-2" v-if="hasPermissions('checklists_update')"
                           @click="duplicateChecklist(row.item, $event)" href="#">
                          <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Duplicate checklist
                        </a>
                        <hr v-if="hasPermissions('checklists_delete') && hasPermissions('checklists_update')" >
                        <a class="dropdown-item" v-if="hasPermissions('checklists_delete')"
                           @click="deleteChecklistPrompt(row.item, $event)" href="#">
                          <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete checklist
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
              <small>{{row.item.description}}</small>

              <div class="row">
                <div class="col">
                  <badge v-if="row.item.game_id" type="primary">{{getGameName(row.item.game_id)}}</badge>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-input v-if="row.item.game_id" label="Game instance">
                    <el-select clearable
                               class="select-primary"
                               name="games"
                               placeholder="Game instance"
                               v-model="row.item.selectedGameInstance"
                               value-key="id"
                    >
                      <el-option
                        v-for="(g, i) in getInstances(row.item.game_instances)"
                        class="select-primary"
                        :label="getDate(g.booking_date) + ', ' + getTime(g.start_time)"
                        :value="{ game_name: g.game_name, id: g.id, booking_date: g.booking_date, start_time: g.start_time}"
                        :key="'G' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                  <base-button style="width: 100%"
                               @click.native="startChecklist(row.item)"
                               type="primary"
                               size="sm"
                  >
                    Start
                  </base-button>
                </div>
              </div>
            </template>
            <template v-slot:cell(game_instance)="row">
              <badge v-if="row.item.game_id" type="primary">{{getGameName(row.item.game_id)}}</badge>
              <span v-else>-</span>
              <div class="row">
                <div class="col-6">
                  <base-input v-if="row.item.game_id" label="Game instance">
                    <el-select clearable
                               class="select-primary"
                               name="games"
                               placeholder="Game instance"
                               v-model="row.item.selectedGameInstance"
                               value-key="id"
                    >
                      <el-option
                        v-for="(g, i) in getInstances(row.item.game_instances)"
                        class="select-primary"
                        :label="getDate(g.booking_date) + ', ' + getTime(g.start_time)"
                        :value="{ game_name: g.game_name, id: g.id, booking_date: g.booking_date, start_time: g.start_time}"
                        :key="'G' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </template>
          </b-table>
        </card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import { BTable } from 'bootstrap-vue'
import Badge from "@/components/Badge";
import _ from "lodash";
import {formattedDate, time} from "@/plugins/dateFormatter";
import draggable from 'vuedraggable'
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import axios from "axios";

export default {
  name: "Checklists",
  components: {
    BTable,
    Badge,
    draggable,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      loading: false,
      feature_not_enabled: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      checklists: [],
      fields: ['checklist', 'game_instance', 'actions', 'start'],
      games: [],
      selectedGameInstance: '',
      j: 0,
      isTouchDevice: false
    };
  },
  methods: {
    async duplicateChecklist(item, event){
      event.preventDefault()
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + item.id

      let data = {
        duplicate: true,
      }
      this.axios.put(url, data, config)
        .then(response => {
          swal('Success', 'Checklist has been duplicated', 'success')
          this.$router.push({ path: '/checklists/edit', query: { id:  response.data }})
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveOrder(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/checklists'

      let data = {
        checklists:  this.checklists,
      }

      axios.put(url, data, config)
        .then(response => {
          this.getChecklists(1)
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    deleteChecklistPrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        text: `The checklist will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deleteChecklist(item)
        }
      })
    },
    async deleteChecklist(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/' + item.id
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'Checklist has been deleted', 'success')
          this.getChecklists()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editChecklist(item, event){
      event.preventDefault()
      this.$router.push({ path: '/checklists/edit', query: { id:  item.id }})
    },
    createChecklistPrompt() {
      this.$router.push('/checklists/settings')
    },
    startChecklist(checklist){
      let gid = ''
      if(checklist.selectedGameInstance) {
        gid = checklist.selectedGameInstance.id
      }
      let time = new Date()
      localStorage.setItem('checklist_start', time.toISOString())
      // localStorage.setItem('checklistId', checklist.id)
      this.$router.push({ path: '/checklists/active', query: { id:  checklist.id, gid: gid }})
    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d) {
      return time(this.time_format, d);
    },
    getInstances(item){
      return _.orderBy(JSON.parse(item), 'booking_date', 'desc')
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getGameName(id){
      if(!id){
        return '-'
      } else if (!(_.find(this.games, { id: id }))){
        return false
      }
      return _.find(this.games, { id: id }).name
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getChecklists(no_load){
      if(no_load){
        this.loading = false
      } else {
        this.loading = true
      }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/start'
      this.axios.get(url, config)
        .then(response => {
          this.checklists = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            this.feature_not_enabled = true
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    checkTouchDevice() {
      this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia("(pointer: coarse)").matches;
    }
  },
  mounted() {
    this.checkTouchDevice();
    this.getGames()
    this.getChecklists()
  }
}
</script>

<style scoped>

</style>
