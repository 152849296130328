<template>
  <div>
<!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--    Add booking-->
    <div class="d-sm-block d-none">
      <div class="row">
        <div class="col">
          <div class="mt-3 " style="max-width: 200px"
               v-if="(hasPermissions('bookings_create') && hasPermissions('booking_slot_update')) || hasPermissions('resource_slots_update')">
            <base-dropdown
              title-classes="dropdown-toggle btn btn-primary btn-block"
              title="Create new"
            >
              <a class="dropdown-item" v-if="hasPermissions('bookings_create') && hasPermissions('booking_slot_update')" @click="newBooking($event)" href="#">Booking</a>
              <a class="dropdown-item" v-if="hasPermissions('booking_slot_update')" @click="newBookingSlot($event)" href="#">Custom time slot</a>
              <a class="dropdown-item" v-if="hasPermissions('resource_slots_update')" @click="newResourceSlot($event)" href="#">Custom resource slot</a>
            </base-dropdown>
          </div>
        </div>
        <div>
          <base-button v-if="getCart() > 0" style="height: 38px; margin-top: 16px;" simple class="mr-2" type="primary" v-on:click="goToCart()">
            <i class="tim-icons icon-cart"></i>
            Cart ({{getCart()}})</base-button>
          <el-dropdown>
            <base-button style="height: 38px; margin-top: 16px;"
                         simple
                         type="primary" class="mr-2"

            > More actions <i class="tim-icons icon-minimal-down ml-1"></i>
            </base-button>
            <template #dropdown>
              <el-dropdown-menu>
                <a class="dropdown-item mb-2" v-if="hasPermissions('booking_slot_update')" href="#" @click="bulkUpdatePrompt($event)">
                  <i style="color:  #42b883" class="tim-icons icon-components mr-2"></i> Bulk actions
                </a>
                <a v-if="show_schedule_name" class="dropdown-item mb-2" href="#" @click="toggleScheduleName(0)">
                  <i class="tim-icons icon-tag mr-2"></i> Hide schedule name
                </a>
                <a v-if="!show_schedule_name" class="dropdown-item mb-2" href="#" @click="toggleScheduleName(1)">
                  <i class="tim-icons icon-tag mr-2"></i> Show schedule name
                </a>
                <a v-if="!staff_settings && show_staff_settings" class="dropdown-item mb-2" href="#" @click="toggleStaffVisibility(1)">
                  <i class="tim-icons el-icon-user-solid text-default mr-2"></i> Show staff
                </a>
                <a v-if="staff_settings && show_staff_settings" class="dropdown-item mb-2" href="#" @click="toggleStaffVisibility(0)">
                  <i class="tim-icons el-icon-user-solid text-default mr-2"></i> Hide staff
                </a>
                <a class="dropdown-item mb-2" @click="goToCalendar()" href="#">
                  <i class="tim-icons icon-calendar-60 text-info mr-2"></i> Switch to calendar mode
                </a>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <div class="btn-group mr-2" >
            <button v-on:click="decrementDate()" style="height: 38px; margin-top: 16px;" type="button" class="btn btn-icon btn-primary btn-outline">
              <i class="tim-icons icon-minimal-left"></i>
            </button>
            <button v-on:click="incrementDate()" style="height: 38px; margin-top: 16px;" type="button" class="btn btn-icon btn-primary btn-outline">
              <i class="tim-icons icon-minimal-right"></i>
            </button>
            <button v-on:click="setTodayDate()" style="height: 38px; margin-top: 16px; padding-right: 15px; padding-left: 15px" type="button" class="btn btn-primary btn-outline">
              <span style="font-weight: lighter"> Today</span>
            </button>
          </div>
        </div>
        <div class="mr-3">
          <base-input class="mt-3" style="border: 0; border-width:0px; width: 200px;" :key="j">
            <el-date-picker
              style="border: 0; border-width:0px;"
              type="date"
              placeholder="Select date"
              :format="day_of_week + date_format_cal"
              v-model="current"
              @change="getBookings()"
            >
            </el-date-picker>
          </base-input>
        </div>
      </div>
    </div>

    <div class="row d-sm-none d-block">
      <div class="col-12">
<!--        <base-button  icon class="btn btn-link" type="primary" v-on:click="goToCalendar()">-->
<!--          <i style="font-size: x-large" class="tim-icons icon-calendar-60"></i>-->
<!--          </base-button>-->
        <el-dropdown>
          <base-button style="height: 38px; margin-top: 16px;"
                       simple
                       type="primary" class="mr-2"

          > More actions <i class="tim-icons icon-minimal-down ml-1"></i>
          </base-button>
          <template #dropdown>
            <el-dropdown-menu>
              <a class="dropdown-item mb-2" v-if="hasPermissions('booking_slot_update')" href="#" @click="bulkUpdatePrompt($event)">
                <i style="color:  #42b883" class="tim-icons icon-components mr-2"></i> Bulk actions
              </a>
              <a v-if="show_schedule_name" class="dropdown-item mb-2" href="#" @click="toggleScheduleName(0)">
                <i class="tim-icons icon-tag mr-2"></i> Hide schedule name
              </a>
              <a v-if="!show_schedule_name" class="dropdown-item mb-2" href="#" @click="toggleScheduleName(1)">
                <i class="tim-icons icon-tag mr-2"></i> Show schedule name
              </a>
              <a v-if="!staff_settings && show_staff_settings" class="dropdown-item mb-2" href="#" @click="toggleStaffVisibility(1)">
                <i class="tim-icons el-icon-user-solid text-default mr-2"></i> Show staff
              </a>
              <a v-if="staff_settings && show_staff_settings" class="dropdown-item mb-2" href="#" @click="toggleStaffVisibility(0)">
                <i class="tim-icons el-icon-user-solid text-default mr-2"></i> Hide staff
              </a>
              <a class="dropdown-item mb-2" @click="goToCalendar()" href="#">
                <i class="tim-icons icon-calendar-60 text-info mr-2"></i> Switch to calendar mode
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <base-button v-if="getCart() > 0" style="height: 38px; margin-top: 16px;" simple class="mr-2" type="primary" v-on:click="goToCart()">
          <i class="tim-icons icon-cart"></i>
          Cart ({{getCart()}})</base-button>
      </div>
      <div class="col-12">
        <base-input class="mt-3">
          <el-date-picker
            type="date"
            placeholder="Select date"
            :format="day_of_week + date_format_cal"
            v-model="current"
            @change="getBookings()"
          >
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-12" v-if="hasPermissions('bookings_create')
      || hasPermissions('booking_slot_update')
      || hasPermissions('resource_slots_update')">
        <base-dropdown
          title-classes="dropdown-toggle btn btn-primary btn-block"
          title="Create new"
        >
          <a class="dropdown-item" v-if="hasPermissions('bookings_create')" @click="newBooking($event)" href="#">Booking</a>
          <a class="dropdown-item" v-if="hasPermissions('booking_slot_update')" @click="newBookingSlot($event)" href="#">Custom time slot</a>
          <a class="dropdown-item" v-if="hasPermissions('resource_slots_update')" @click="newResourceSlot($event)" href="#">Custom resource slot</a>
        </base-dropdown>
      </div>
    </div>

    <!--    Bulk update slots-->
    <modal :show.sync="modals.bulk_update_slots"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Bulk update</h4>
        <base-alert type="info">
          <i class="tim-icons el-icon-info mr-2"></i> Bulk actions will update all slots marked as available, blocked,
          or call to book with the specified action in the selected time frame. All other slots will remain unchanged.
        </base-alert>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Action">
              <el-select
                label="Select period"
                v-model="selectedBulkUpdateAction"
                class="select-primary"
                value-key="id"
              >
                <el-option
                  v-for="(ba, index) in bulk_actions"
                  class="select-primary"
                  :label="ba.label"
                  :value="{id: ba.id, label: ba.label}"
                  :key="'BA' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div v-if="selectedBulkUpdateAction.id === 1" class="col-lg-6 col-md-6 col-12">
            <base-input label="Status">
              <el-select
                label="Select period"
                v-model="selectedBulkUpdateActionStatus"
                class="select-primary"
                value-key="id"
              >
                <el-option
                  v-for="(status, index) in slot_statuses"
                  class="select-primary"
                  :label="status.label"
                  :value="{id: status.id, label: status.label, status: status.status}"
                  :key="'STATUS' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-lg-6" v-if="selectedBulkUpdateAction.id === 2">
            <label>
              <div>Minimum booking notice
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how soon an event can be booked.<br/>
                      This setting will help prevent last minute bookings.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>

            <div class="row">
              <div class="col-lg-4">
                <base-input v-model="min_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-lg-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="min_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in min_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'M' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col" v-if="selectedBulkUpdateAction.id === 3">
            <label>
              <div>Maximum bookings in advance
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how far in advance customers can book this event.<br/>
                      By default, bookings are generate 12 months in advance.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <div class="row">
              <div class="col-lg-4">
                <base-input v-model="max_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-lg-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="max_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in max_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'X' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2" v-if="selectedBulkUpdateAction.id === 4">
          <div class="col">
            <base-checkbox v-model="reassign_to_resource_slots">Attempt reassignment to resource slots</base-checkbox>
          </div>
        </div>

        <div class="row" v-if="selectedBulkUpdateAction.id === 2">
          <div class="col-lg-6" >
            <label>Minimum notice block off text
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <base-input v-model="slot_text"></base-input>
          </div>
          <div class="col-lg-6">
            <base-input label="External link" v-if="has_external_link"  v-model="external_link" placeholder="https://example.com"></base-input>
            <base-checkbox v-model="has_external_link">Include an external link
            </base-checkbox>
          </div>
        </div>

        <div class="row" v-if="selectedBulkUpdateAction.id === 5">
          <div class="col">
            <base-input label="Time slot notes" :error="errs.bulk_note">
              <el-input
                v-model="note"
                :rows="5"
                type="textarea"
                placeholder="Add a new comment for slots in this range"
              />
            </base-input>
          </div>
        </div>

        <div v-if="selectedBulkUpdateAction.id === 6" :key="'CPB' + j">
          <div v-if="enable_custom_pricing">
            <div class="row mt-2">
              <div class="col-lg-6">
                <base-input label="Pricing type">
                  <el-select
                    label="Select time"
                    v-model="custom_pricing_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Pricing type">
                    <el-option
                      v-for="(pt, index) in pricing_types"
                      class="select-primary"
                      :label="pt.name"
                      :value="{id: pt.id,
                          name: pt.name}"
                      :key="'PT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col" >
                <base-button class="mr-2" v-on:click="addNewCategory(custom_pricing_categories)" size="sm" type="primary">
                  <i class="tim-icons el-icon-plus"></i> Add new pricing category</base-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-for="(p, i) in custom_pricing_categories" :key="'PP' + i">

                  <div class="row">
                    <div class="col-lg-4" >
                      <label>Pricing category name</label>

                      <el-tooltip class="d-inline"
                                  content="Remove category"
                                  effect="light"
                                  :open-delay="300"
                                  placement="right"
                      >
                        <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(custom_pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                      </el-tooltip>
                      <base-input class="d-inline" placeholder="Category name" v-model="p.category">
                      </base-input>
                    </div>
                    <div class="col-lg-4" >
                      <base-input label="Minimum required participants">
                        <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                    <div class="col-lg-4">
                      <base-input label="Default number of participants">
                        <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                  </div>

                  <div class="col">
                    <div class="row" v-for="(k, j) in p.pricing" :key="'PPP' + j">
                      <div class="col" >
                        <base-input  :label="'Minimum participants'">
                          <el-input-number size="small" v-model="k.min_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col" >
                        <base-input :label="'Maximum participants'">
                          <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col">
                        <base-input :label="'Price (' + currency_symbol + ')'">
                          <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                        </base-input>
                      </div>
                      <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                        <el-tooltip
                          content="Remove price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(custom_pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                        </el-tooltip>
                        <el-tooltip
                          content="Add price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button v-if="j == custom_pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(custom_pricing_categories[i], i)" style="padding: 0;" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-checkbox v-model="cumulative_category_pricing">Cumulative category pricing</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-checkbox v-model="allow_deposit">Allow deposit</base-checkbox>
                <base-checkbox v-if="allow_deposit" v-model="require_deposit">Require only deposit amount during checkout</base-checkbox>
              </div>
              <div class="col-lg-3 mt-2" v-if="allow_deposit">
                <base-input label="Deposit type">
                  <el-select
                    v-model="deposit_type"
                    class="select-primary"
                    name="deposit_type"
                    placeholder="Value type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in valueTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'VT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 mt-2" v-if="allow_deposit">
                <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount"
                            v-model.number="deposit_amount"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="selectedBulkUpdateAction.id !== 4">
          <div class="col">
            <base-input label="Events">
              <el-select
                label="Select period" multiple
                v-model="selectedBulkUpdateGames"
                class="select-primary"
                value-key="id"
              >
                <el-option
                  v-for="(ga, index) in events_for_bulk_update"
                  class="select-primary"
                  :label="ga.name"
                  :value="{id: ga.id, name: ga.name}"
                  :key="'GA' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-input label="From" :error="errs.bulk_from">
              <el-date-picker
                type="date"
                placeholder="Select date"
                :format="day_of_week + date_format_cal"
                v-model="bulk_from"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="To" :error="errs.bulk_to">
              <el-date-picker
                type="date"
                placeholder="Select date"
                :format="day_of_week + date_format_cal"
                v-model="bulk_to"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="bulk_time_range_on"  />
            <label> &nbsp; Only apply to time slots within a specific time range on each day</label>
          </div>
        </div>

        <div class="row" v-if="bulk_time_range_on">
          <div class="col">
            <base-input label="Start time" :error="errs.bulk_from_time">
              <el-time-picker v-model="bulk_from_time"
                              :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="End time" :error="errs.bulk_to_time">
              <el-time-picker v-model="bulk_to_time"
                              :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.bulk_update_slots = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" :disabled="loading" v-if="selectedBulkUpdateAction.id !== 4"
                       @click="bulkUpdate()">Update slots</base-button>
          <base-button class="ml-3" type="primary" :disabled="loading" v-else
                       @click="bulkUpdateResources()">Update slots</base-button>
        </div>
      </card>
    </modal>

    <!--    Customer alerts-->
    <modal :show.sync="modals.customer_alerts"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Special customer notes</h4>

        <div class="row">
          <div class="col">
            <b-table :items="customer_alerts" :fields="['question', 'response', 'date_added']">
              <template v-slot:cell(question)="row">
                {{row.item.label}}
              </template>
              <template v-slot:cell(response)="row">
                <div v-if="row.item.type === 1">
                  <i class="tim-icons icon-check-2 text-success" v-if="row.item.response"></i>
                  <i class="tim-icons icon-simple-remove text-danger" v-if="!row.item.response"></i>
                </div>
                <div v-else>
                  {{row.item.response}}
                </div>
              </template>
              <template v-slot:cell(date_added)="row">
                {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
              </template>
            </b-table>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.customer_alerts = false">Cancel</base-button>
        </div>
      </card>
    </modal>

<!--    Add Bookings Modal-->
    <modal :show.sync="modals.new_booking"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <badge v-if="selectedTimeSlot" class="mb-2"
               type="default">#{{selectedTimeSlot.id}}</badge>
        <h4 v-if="selectedTimeSlot.status !== 'completed'">Create a new booking</h4>
        <div v-if="selectedTimeSlot.status === 'completed'">
          <div class="row">
            <div class="col">
              <h3 class="card-title text-primary">{{selectedGame}}</h3>
            </div>
          </div>
        </div>

        <div v-if="hasPermissions('booking_slot_update')">
          <div v-if="selectedTimeSlot.status !== 'completed'">
            <label>Game</label>
            <base-input :error="errs.game">
              <el-select
                label="Select game"
                v-model="selectedGame"
                class="select-primary"
                v-on:change="getInitialSubtotal(selectedGame, 'new')"
                placeholder="Game">
                <el-option
                  v-for="game in games"
                  class="select-primary"
                  :label="game.name"
                  :value="game.name"
                  :key="game.id + 'M'"
                ></el-option>
              </el-select>
            </base-input>
          </div>

          <div v-if="selectedGame != ''" class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <label >Booking date</label>
              <base-input :error="errs.booking_date">
                <el-date-picker
                  type="date"
                  placeholder="mm-dd-yyyy"
                  :format="day_of_week + date_format_cal"
                  name="date"
                  v-model="booking_date"
                  v-on:change="updateRescheduleDate(booking_date)"
                >
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <label>Time slot</label>
              <base-input>
                <el-select v-on:change="getNewSubtotalForBookingSlot(selectedTimeSlot)"
                           label="Select time"
                           v-model="selectedTimeSlot"
                           class="select-primary"
                           value-key="id"
                           placeholder="Time">
                  <el-option
                    v-for="(booking, index) in available_bookings"
                    class="select-primary"
                    :label="getTime(booking.start_time)  + '-' + getTime(booking.end_time)"
                    :value="{id: booking.id,
                          booking_date: booking.booking_date,
                          end_date: booking.end_date,
                          pricing: booking.pricing,
                          custom_pricing: booking.custom_pricing,
                          game_id: booking.game_id,
                          game_name: booking.game_name,
                          start_time: booking.start_time,
                          is_custom_pricing_enabled: booking.is_custom_pricing_enabled,
                          slot_cumulative_category_pricing: booking.slot_cumulative_category_pricing,
                          slot_hide_pricing_rate: booking.slot_hide_pricing_rate,
                          slot_pricing_type_id: booking.slot_pricing_type_id,
                          pricing_type_id: booking.pricing_type_id,
                          cumulative_category_pricing: booking.cumulative_category_pricing,
                          hide_pricing_rate: booking.hide_pricing_rate,
                          end_time: booking.end_time}"
                    :key="'A' + index"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>

        <div class="row" v-if="selectedGame != ''">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Customer lookup (search by name, email, or phone)">
              <el-autocomplete style="width: 100%"
                               v-model="lookup_value"
                               :fetch-suggestions="querySearch"
                               placeholder="Search"
                               :trigger-on-focus="false"
                               @select="handleSelect"
              >
                <template #default="{ item }">
                  <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                  <small class="font-italic">
                    <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                  </small>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
          <div class="col-lg-3 col-md-3 col-4">
            <base-button v-if="isMobile" size="sm" simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
            <base-input label=" " v-else>
              <base-button simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="selectedGame != ''">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.first_name" label="First name" v-model="user.first_name" placeholder="First name"></base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.last_name" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
          </div>
        </div>
        <div class="row" v-if="selectedGame != ''">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.email" label="Email" v-model="user.email" placeholder="Email"></base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Phone (Optional)" v-model="user.phone" :placeholder="country.phone_placeholder"></base-input>
          </div>
        </div>

        <div v-if="selectedGame != '' && !modals.edit_booking">
          <div class="row mt-1">
            <div class="col-lg-4 col-md-4 col-12" v-if="!price_override">
              <p class="text-white text-uppercase">Quantity</p>
              <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'CP' + i">
                <label class="text-primary text-uppercase">{{c.category}}
                </label>
                <base-input>
                  <el-input-number @change="recalculateDiscount(selectedTimeSlot)" v-model="c.number_of_players" :min="0" />
                </base-input>
              </div>

              <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'C' + i">
                <label class="text-primary text-uppercase">{{c.category}}
                </label>
                <base-input>
                  <el-input-number @change="recalculateDiscount(selectedTimeSlot)" v-model="c.number_of_players" :min="0" />
                </base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-3 col-6" v-if="!price_override">
              <p class="text-white text-uppercase">price ({{currency_symbol}})</p>
              <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(subtotal) }}</h2>
              <small>
                <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'CC' + i">
                  <span v-if="pricing_type_id === 1" class="text-primary">{{getAmount(getRate(c, selectedTimeSlot))}} per {{c.category}}</span>
                  <span v-if="pricing_type_id === 2 && subtotal > 0" class="text-primary">{{getRate(c, selectedTimeSlot)}}</span>
                </div>
                <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'CPC' + i">
                  <span v-if="pricing_type_id === 1" class="text-primary">{{getAmount(getRate(c, selectedTimeSlot))}} per {{c.category}}</span>
                  <span v-if="pricing_type_id === 2 && subtotal > 0" class="text-primary">{{getRate(c, selectedTimeSlot)}}</span>
                </div>
              </small>
              <base-button v-if="custom_pricing_categories.length > 0 || pricing_categories.length > 0"
                           size="sm" type="primary" v-on:click="overridePrice(selectedTimeSlot.is_custom_pricing_enabled, selectedTimeSlot)">Override price</base-button>
            </div>
            <div class="col-lg-6 col-md-6 col" v-if="price_override">
              <p class="text-white text-uppercase">Price override</p>
              <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, index) in pricing_categories" :key="'CCOVERRIDE' + index">
                <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                <div class="row">
                  <div class="col">
                    <base-input>
                      <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                    </base-input>
                  </div>
                  <div class="col">
                    <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                    <el-tooltip class="d-inline"
                                content="Set to original price"
                                effect="light"
                                :open-delay="300"
                                placement="right"
                    >
                      <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, index) in custom_pricing_categories" :key="'CCPOVERRIDE' + index">
                <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                <div class="row">
                  <div class="col">
                    <base-input>
                      <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                    </base-input>
                  </div>
                  <div class="col">
                    <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                    <el-tooltip class="d-inline"
                                content="Set to original price"
                                effect="light"
                                :open-delay="300"
                                placement="right"
                    >
                      <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <base-button size="sm" type="primary" v-on:click="saveOverride(selectedTimeSlot.is_custom_pricing_enabled, selectedTimeSlot)">Save</base-button>
            </div>
          </div>
        </div>

<!--        Totals-->
        <div class="mt-2" v-if="selectedGame != ''">
          <base-checkbox v-model="waive_tax" v-on:input="recalculateDiscount(selectedTimeSlot)" class="d-inline">Waive taxes</base-checkbox>
          <base-checkbox v-model="waive_fee"  v-on:input="recalculateDiscount(selectedTimeSlot)" class="d-inline ml-2">Waive fees</base-checkbox>
          <div class="row justify-content-end">
            <div class="col text-right">
              <hr style="border-top: 1px solid #f4f5f7;">
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col" >
              <div class="row">
                <div class="col-9">
                  <p class="text-white"><span class="text-uppercase">
                  Subtotal</span>
                  </p>
                </div>
                <div class="col text-right">
                  <p class="text-white"><span class="text-uppercase">
                    {{getAmount(subtotal)}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end" v-show="subtotal > 0" v-for="(p, i) in promos" :key="pc_key + i">
            <div class="col" >
              <div class="row">
                <div class="col-9">
                  <p class="text-white"><span class="text-uppercase">
                    {{p.name}}</span>
                    <base-button @click="removePromoCode(p)" style="padding: 0; margin: 0" type="danger" icon class="btn-link d-inline">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>

                  </p>
                </div>
                <div class="col text-right">
                  <p class="text-white"><span class="text-uppercase">
                       -{{getAmount(getDiscount(p))}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end" v-show="!waive_tax && subtotal > 0" v-for="(t, i) in taxes" :key="'T' + i">
            <div class="col" >
              <div class="row">
                <div class="col-9">
                  <p class="text-white"><span class="text-uppercase">
                  {{t.name}}</span>
                  </p>
                </div>
                <div class="col text-right">
                  <p class="text-white"><span class="text-uppercase">
                   {{getAmount(getTax(t))}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end" v-show="!waive_fee && subtotal > 0" v-for="(f, i) in fees" :key="'F' + i">
            <div class="col" >
              <div class="row">
                <div class="col-9">
                  <p class="text-white"><span class="text-uppercase">
                  {{f.name}}</span>
                  </p>
                </div>
                <div class="col text-right">
                  <p class="text-white"><span class="text-uppercase">
                   {{getAmount(getFee(f))}}</span>
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div class="row justify-content-end" v-show="subtotal > 0 && taxes.length > 0">
            <div class="col text-right">
              <hr style="border-top: 1px solid #f4f5f7;">
            </div>
          </div>
          <div class="row justify-content-end" v-show="subtotal > 0">
            <div class="col">
              <div class="row">
                <div class="col">
                  <p class="text-white"><span class="text-uppercase">
                  Total: </span>
                  </p>
                </div>
                <div class="col text-right">
                  <p v-if="!waive_tax && !waive_fee" class="text-white"><span class="text-uppercase">
                      {{getAmount(getTotal())}}</span>
                  </p>
                  <p v-if="waive_tax && !waive_fee" class="text-white"><span class="text-uppercase">
                    {{getAmount(getTotal('edit') - getTaxes())}}</span>
                  </p>
                  <p v-if="!waive_tax && waive_fee" class="text-white"><span class="text-uppercase">
                      {{getAmount(getTotal('edit') - getFees())}}</span>
                  </p>
                  <p v-if="waive_tax && waive_fee" class="text-white"><span class="text-uppercase">
                      {{getAmount(getTotal('edit') - getTaxes() - getFees())}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label v-if="selectedGame != ''">Promo code</label>
        <div class="row" v-if="selectedGame != ''">
          <div class="col-lg-8" style="display: flex; width: 100%;">
            <base-input style="flex-grow: 1; border-radius: 0px;">
              <el-autocomplete style="width: 100%"
                               v-model="promo_code"
                               :fetch-suggestions="promoSearch"
                               placeholder="Search promo..."
                               :trigger-on-focus="false"
                               @select="handlePromoSelect"
              >
                <template #default="{ item }">
                  <div class="row">
                    <div class="col">
                      <div ><span class="text-primary">{{ item.name }} - </span>
                        <small>
                          <span v-if="item.redemption_type === 0">{{item.value_amount}}%</span>
                          <span v-if="item.redemption_type === 1">{{getAmount(item.value_amount)}}</span>
                          <span v-if="item.value_type === 0"> per transaction</span>
                          <span v-if="item.value_type === 2"> per participant</span>
                          <span class="ml-2"
                                style="text-transform: uppercase; line-height: 9px; text-decoration: none; border: none;
                            color: white; background-color: #344675; border-radius: 2px; padding: 2px 5px 2px 5px; font-size: 9px"
                                v-if="item.internal_only">internal only</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </base-input>
            <base-button v-on:click="applyPromoCode()" class="mt-0 ml-2" style="flex-grow: 0; margin-bottom: 10px" type="primary">Apply</base-button>
          </div>
        </div>

        <div class="row mt-2" v-if="selectedGame != ''">
          <div class="col-lg-4">
            <base-button v-if="add_payment == false " v-on:click="showPaymentForm()" type="primary">Add payment now</base-button>
            <base-button v-if="add_payment == true "  v-on:click="payOnArrival()" type="primary">Pay on arrival</base-button>
          </div>
          <div class="col">
            <base-checkbox v-model="no_confirmation_email">Do not send booking confirmation email</base-checkbox>
            <base-checkbox v-if="(add_payment == false) || (pay_partial_amount && partial_amount < total)" v-model="send_payment_request">Send payment request email to customer</base-checkbox>
            <base-checkbox v-on:input="setPartialAmount()" v-if="add_payment == true && selectedPaymentOption.name !== 'Customer credit'" v-model="pay_partial_amount">Pay partial amount</base-checkbox>
          </div>
        </div>
        <div class="row" v-if="pay_partial_amount && selectedPaymentOption.name !== 'Customer credit'">
          <div class="col-lg-6">
            <base-input :error="errs.partial_amount" :key="pa_key" v-model.number="partial_amount" placeholder="0.00" :label="'Amount (' + currency_symbol + ')'"></base-input>
          </div>
        </div>

        <div class="row" v-show="add_payment == true">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Payment options">
              <el-select
                class="select-primary mb-3"
                v-model="selectedPaymentOption"
                placeholder="Option"
                value-key="id"
              >
                <el-option
                  class="select-primary"
                  v-for="(item, index) in paymentOptions"
                  :key="index + 'P'"
                  :label="item.name"
                  :value="{id: item.id, name: item.name, content: item.content, is_default: item.is_default}"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="!selectedPaymentOption.is_default">
          <div class="col">
            <div v-html="selectedPaymentOption.content"></div>
          </div>
        </div>

        <div class="row" v-show="add_payment == true && selectedPaymentOption.name === 'Customer credit'">
          <div class="col-lg-3 col-md-3 col-6">
            <base-input label="Available ($)" v-model="available_credit" disabled></base-input>
          </div>
          <div class="col-lg-3 col-md-3 col-6">
            <base-input label="Amount to apply($)" v-model="applied_credit"></base-input>
          </div>
        </div>

        <div class="row" v-show="add_payment == true && selectedPaymentOption.name === 'Gift card' && selectedGiftCardCode">
          <div class="col-lg-4 col-md-3 col-6">
            <base-input label="Available balance ($)" v-model="selectedGiftCardCode.remaining" disabled></base-input>
          </div>
        </div>

        <div class="row mb-3" v-show="add_payment === true && selectedPaymentOption.name === 'Gift card'">
          <div class="col">
            <base-input label="Gift card code" :error="errs.gift_card_code">
              <el-autocomplete style="width: 100%"
                               v-model="gift_card_code"
                               :fetch-suggestions="giftCardSearch"
                               placeholder="Search by code..."
                               :trigger-on-focus="false"
                               @select="handleGiftCardSelect"
              >
                <template #default="{ item }">
                  <div class="row">
                    <div class="col">
                      <p style="margin-bottom: -12px" class="text-primary">{{ getGiftCardCode(item.code) }}</p>
                      <small class="font-italic">
                        <i class="tim-icons icon-money-coins"></i> Total {{getAmount(item.total)}} Remaining {{getAmount(item.remaining)}}
                      </small>
                      <br/>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
        </div>

        <!--                  Square-->
        <div class="row" v-show="selectedPaymentOption.name === 'Card' && add_payment == true && payment_settings.payment_gateway_id === 2">
          <div class="col-12">
            <form id="payment-form-square" >
              <div id="card-container"></div>
            </form>
            <div id="payment-status-container"></div>
          </div>
        </div>

<!--        Stripe-->
        <div class="row mb-5" v-show="add_payment == true && selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 3">
          <div class="col">
            <form id="payment-form">
              <label for="card-element">
                Credit or debit card
              </label>
              <div id="card-element">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div id="card-errors" role="alert"></div>
            </form>
          </div>
        </div>


        <div class="row" v-show="add_payment == true && selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
          <div class="col-12">
            <base-input :error="errs.ccn" v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
          </div>

        </div>

        <div class="row" v-show="add_payment == true && selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
          <div class="col-4">
            <base-input :error="errs.zip" :placeholder="country.zip_placeholder" v-model="payment.zip" type="text"
                        :label="country.zip_label"></base-input>
          </div>
          <div class="col-4">
            <base-input :error="errs.expiration" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
          </div>
          <div class="col-4">
            <base-input :error="errs.cvv" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.new_booking = false">Cancel</base-button>
          <base-button class="ml-3" :disabled="loading"
                       type="primary" @click="createBookingPrompt()">Create booking</base-button>
        </div>
      </card>
    </modal>

    <!--    Edit Bookings Modal-->
    <modal :show.sync="modals.edit_booking"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <badge class="mb-2"
               type="default">#{{selectedBookingSlot.id}}</badge>
        <div v-if="!selectedBookingSlot.transaction_id">
          <div v-if="selectedBookingSlot.status === 'completed'">
            <div class="row">
              <div class="col">
                <h3 class="card-title text-primary">{{selectedBookingSlot.game_name}}</h3>
              </div>
            </div>
          </div>
          <div v-else>
            <h4>Edit time slot</h4>
            <div class="row">
              <div class="col">
                <base-input label="Game">
                  <el-select
                    label="Select game"
                    v-model="selectedGame"
                    class="select-primary"
                    placeholder="Game">
                    <el-option
                      v-for="game in games"
                      class="select-primary"
                      :label="game.name"
                      :value="game.name"
                      :key="game.id + 'K'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label>Booking start date</label>
              <base-input :error="errs.booking_date">
                <el-date-picker
                  type="date"
                  placeholder="mm-dd-yyyy"
                  :format="day_of_week + date_format_cal"
                  name="date"
                  v-model="selectedBookingSlot.booking_date"
                >
                </el-date-picker>
              </base-input>
            </div>
            <div class="col">
              <base-input label="Start time" :error="errs.start_time">
                <el-time-picker
                  v-model="start_time"
                  :format="picker_time_format" placeholder="HH:MM"
                ></el-time-picker>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label>Booking end date</label>
              <base-input :error="errs.booking_end_date">
                <el-date-picker
                  type="date"
                  placeholder="mm-dd-yyyy"
                  :format="day_of_week + date_format_cal"
                  name="date"
                  v-model="selectedBookingSlot.end_date"
                >
                </el-date-picker>
              </base-input>
            </div>
            <div class="col">
              <base-input label="End time" :error="errs.end_time">
                <el-time-picker
                  v-model="end_time"
                  :format="picker_time_format" placeholder="HH:MM"
                ></el-time-picker>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-4 col-12" v-if="selectedBookingSlot.status !== 'completed'">
              <base-input label="Status">
                <el-select
                  label="Select status"
                  v-model="selectedStatus"
                  class="select-primary"
                  placeholder="Status">
                  <el-option
                    v-for="(s, i) in statuses"
                    class="select-primary"
                    :label="s"
                    :value="s"
                    :key="'S' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-3">
              <base-button v-on:click="showCustomPricingSettings(selectedBookingSlot.custom_pricing)" style="font-weight: normal; font-size: small; margin-top: 40px" link type="primary">
                Custom pricing
                <i v-if="!expand_price_settings" class="tim-icons icon-minimal-down"></i>
                <i v-if="expand_price_settings" class="tim-icons icon-minimal-up"></i>
              </base-button>
            </div>
            <div class="col">
              <base-button v-on:click="expand_slot_settings = !expand_slot_settings" style="font-weight: normal; font-size: small; margin-top: 40px" link type="primary">
                Additional settings
                <i v-if="!expand_slot_settings" class="tim-icons icon-minimal-down"></i>
                <i v-if="expand_slot_settings" class="tim-icons icon-minimal-up"></i>
              </base-button>
            </div>
          </div>
          <div v-if="expand_price_settings && selectedStatus !== 'booked'" :key="j+'EPS'">
            <div class="row">
              <div class="col">
                <base-checkbox v-model="enable_custom_pricing" v-on:input="prepopulateCustomPricingForGame()">Enable custom pricing</base-checkbox>
              </div>
            </div>
            <div v-if="enable_custom_pricing">
              <div class="row mt-2">
                <div class="col-lg-6">
                  <base-input label="Pricing type">
                    <el-select
                      label="Select time"
                      v-model="custom_pricing_type"
                      class="select-primary"
                      value-key="id"
                      placeholder="Pricing type">
                      <el-option
                        v-for="(pt, index) in pricing_types"
                        class="select-primary"
                        :label="pt.name"
                        :value="{id: pt.id,
                          name: pt.name}"
                        :key="'PT' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col" >
                  <base-button class="mr-2" v-on:click="addNewCategory(custom_pricing_categories)" size="sm" type="primary">
                    <i class="tim-icons el-icon-plus"></i> Add new pricing category</base-button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div v-for="(p, i) in custom_pricing_categories" :key="'PP' + i">

                    <div class="row">
                      <div class="col-lg-4" >
                        <label>Pricing category name</label>

                        <el-tooltip class="d-inline"
                                    content="Remove category"
                                    effect="light"
                                    :open-delay="300"
                                    placement="right"
                        >
                          <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(custom_pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                        </el-tooltip>
                        <base-input class="d-inline" placeholder="Category name" v-model="p.category">
                        </base-input>
                      </div>
                      <div class="col-lg-4" >
                        <base-input label="Minimum required participants">
                          <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col-lg-4">
                        <base-input label="Default number of participants">
                          <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                    </div>

                    <div class="col">
                      <div class="row" v-for="(k, j) in p.pricing" :key="'PPP' + j">
                        <div class="col" >
                          <base-input  :label="'Minimum participants'">
                            <el-input-number size="small" v-model="k.min_players" :min="1" placeholder="1" :step="1" />
                          </base-input>
                        </div>
                        <div class="col" >
                          <base-input :label="'Maximum participants'">
                            <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                          </base-input>
                        </div>
                        <div class="col">
                          <base-input :label="'Price (' + currency_symbol + ')'">
                            <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                          </base-input>
                        </div>
                        <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                          <el-tooltip
                            content="Remove price range"
                            effect="light"
                            :open-delay="300"
                            placement="right"
                          >
                            <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(custom_pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                          </el-tooltip>
                          <el-tooltip
                            content="Add price range"
                            effect="light"
                            :open-delay="300"
                            placement="right"
                          >
                            <base-button v-if="j == custom_pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(custom_pricing_categories[i], i)" style="padding: 0;" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-checkbox v-model="cumulative_category_pricing">Cumulative category pricing</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <base-checkbox v-model="allow_deposit">Allow deposit</base-checkbox>
                  <base-checkbox v-if="allow_deposit" v-model="require_deposit">Require only deposit amount during checkout</base-checkbox>
                </div>
                <div class="col-lg-3 mt-2" v-if="allow_deposit">
                  <base-input label="Deposit type">
                    <el-select
                      v-model="deposit_type"
                      class="select-primary"
                      name="deposit_type"
                      placeholder="Value type"
                      value-key="id"
                    >
                      <el-option
                        v-for="(type, index) in valueTypes"
                        class="select-primary"
                        :label="type.name"
                        :value="{name: type.name, id: type.id}"
                        :key="index + 'VT'"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-3 mt-2" v-if="allow_deposit">
                  <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount"
                              v-model.number="deposit_amount"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="selectedStatus === 'Available' && expand_slot_settings">
            <div class="col">
              <label>
                <div>Minimum booking notice
                  <el-popover trigger="hover"
                              placement="right">

                    <div>
                      <div class="popover-body">Choose how soon an event can be booked.<br/>
                        This setting will help prevent last minute bookings.</div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </div>
              </label>

              <div class="row">
                <div class="col-lg-4">
                  <base-input v-model="min_booking_value"
                              type="number">
                  </base-input>
                </div>
                <div class="col-lg-8">
                  <base-input>
                    <el-select
                      label="Select period"
                      v-model="min_booking_period"
                      class="select-primary"
                      value-key="id"
                    >
                      <el-option
                        v-for="(p, index) in min_periods"
                        class="select-primary"
                        :label="p.label"
                        :value="{id: p.id, label: p.label}"
                        :key="'M' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <label>
                <div>Maximum bookings in advance
                  <el-popover trigger="hover"
                              placement="right">

                    <div>
                      <div class="popover-body">Choose how far in advance customers can book this event.<br/>
                        By default, bookings are generate 12 months in advance.</div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </div>
              </label>
              <div class="row">
                <div class="col-lg-4">
                  <base-input v-model="max_booking_value"
                              type="number">
                  </base-input>
                </div>
                <div class="col-lg-8">
                  <base-input>
                    <el-select
                      label="Select period"
                      v-model="max_booking_period"
                      class="select-primary"
                      value-key="id"
                    >
                      <el-option
                        v-for="(p, index) in max_periods"
                        class="select-primary"
                        :label="p.label"
                        :value="{id: p.id, label: p.label}"
                        :key="'X' + index"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="expand_slot_settings">
            <!--              Call to book or blocked-->
            <div class="col-lg-6" >
              <label v-if="selectedStatus === 'Available'">Minimum notice block off text
                <el-popover trigger="hover" placement="right">
                  <div>
                    <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                    </div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </label>
              <label v-if="selectedStatus === 'Call to book'">Time slot text
                <el-popover trigger="hover" placement="right">
                  <div>
                    <div class="popover-body">This text will be visible to customers when a slot has the status 'Call to book'.
                    </div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </label>
              <base-input v-if="selectedStatus === 'Available' || selectedStatus === 'Call to book'" v-model="slot_text"></base-input>
            </div>
            <div class="col-lg-6" v-if="selectedStatus === 'Available' || selectedStatus === 'Call to book'">
              <base-input label="External link" v-if="has_external_link"  v-model="external_link" placeholder="https://example.com"></base-input>
              <base-checkbox v-model="has_external_link">Include an external link
              </base-checkbox>
            </div>
          </div>
          <div class="row" v-if="selectedStatus !== 'Available' && expand_slot_settings">
            <div class="col">
              <label>
                Block note
                <el-popover trigger="hover" placement="right">
                  <div>
                    <div class="popover-body">This note is for internal reference only.
                    </div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </label>
              <base-input>
                <el-input
                  v-model="block_note"
                  :rows="5"
                  type="textarea"
                  placeholder="Notes..."
                />
              </base-input>
            </div>
          </div>
        </div>

        <div v-if="selectedBookingSlot.transaction_id">
<!--          <h4 class="card-title">Edit booking</h4>-->
          <div class="row">
            <div class="col">
              <h3 class="card-title text-primary">{{selectedBookingSlot.game_name}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-white">
                <i class="tim-icons icon-calendar-60 mr-1"></i>
                {{getFormattedDate(selectedBookingSlot.booking_date_label)}},
                {{getTime(selectedBookingSlot.start_time)}}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-white">
                Booked for {{selectedBookingSlot.group_size}} people
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <hr style="border-top: 1px solid #f4f5f7;">
              <span style="font-size: large" class="card-title">Transaction #{{selectedBookingSlot.order_number}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <span class="card-title">Purchased by {{selectedBookingSlot.first_name}} {{selectedBookingSlot.last_name}}
                on {{getLocalDate(selectedBookingSlot.timestamp)}}, {{getLocalTime(selectedBookingSlot.timestamp)}}
              </span>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-4">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row stats-margin">
                  <div class="col-3">
                    <div class="info-icon icon-primary text-center">
                      <i class="tim-icons icon-money-coins"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Transaction total</p>
                        <h4 class="card-title">{{ getAmount(selectedBookingSlot.total) }}</h4>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-lg-4">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row stats-margin">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-check-2"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Paid</p>
                        <h4 class="card-title">{{ getAmount(selectedBookingSlot.paid) }}</h4>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-lg-4">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row stats-margin">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-alert-circle-exc"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Due</p>
                        <h4 class="card-title">{{ getAmount(selectedBookingSlot.due) }}</h4>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
<!--          Edit customer-->
          <div v-show="activeTabIndex === 2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input label="Customer lookup (search by name, email, or phone)">
                  <el-autocomplete style="width: 100%"
                                   v-model="lookup_value"
                                   :fetch-suggestions="querySearch"
                                   placeholder="Search..."
                                   :trigger-on-focus="false"
                                   @select="handleSelect"
                  >
                    <template #default="{ item }">
                      <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                      <small class="font-italic">
                        <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                        <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                      </small>

                    </template>
                  </el-autocomplete>
                </base-input>
              </div>
              <div class="col">
                <base-button v-if="isMobile" size="sm" simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
                <base-input v-else label=" ">
                  <base-button simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.first_name" label="First name" v-model="user.first_name" placeholder="First name"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.last_name" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.email" label="Email" v-model="user.email" placeholder="Email"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.phone" label="Phone (Optional)"
                            v-model="user.phone" :placeholder="country.phone_placeholder"></base-input>
              </div>
            </div>
          </div>
<!--Edit/reschedule booking-->
          <div v-if="activeTabIndex === 0 && selectedBookingSlot.transaction_id">
            <div class="row">
              <div class="col">
                <base-input label="Game">
                  <el-select
                    label="Select game"
                    v-model="selectedGame"
                    class="select-primary"
                    v-on:change="getInitialSubtotal(selectedGame)"
                    placeholder="Game">
                    <el-option
                      v-for="game in games"
                      class="select-primary"
                      :label="game.name"
                      :value="game.name"
                      :key="game.id + 'K2'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row" :key="j">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <base-input label="Date" :error="errs.booking_date">
                      <el-date-picker
                        type="date"
                        placeholder="mm-dd-yyyy"
                        value-format="yyyy-MM-dd"
                        name="date"
                        v-model="selectedBookingSlot.booking_date"
                        :format="day_of_week + date_format_cal"
                        v-on:change="updateRescheduleDate(selectedBookingSlot.booking_date)"
                      >
                      </el-date-picker>
                    </base-input>
                  </div>
                  <div class="col">
                    <base-input label="Time" :error="errs.time_slot">
                      <el-select v-on:change="getNewSubtotalForBookingSlot(time_slot)"
                        label="Select time"
                        v-model="time_slot"
                        class="select-primary"
                        value-key="id"
                        placeholder="Time">
                        <el-option
                          v-for="(booking, index) in available_bookings"
                          class="select-primary"
                          :label="getTime(booking.start_time)  + '-' + getTime(booking.end_time)"
                          :value="{id: booking.id,
                          booking_date: booking.booking_date,
                          end_date: booking.end_date,
                          pricing: booking.pricing,
                          custom_pricing: booking.custom_pricing,
                          game_id: booking.game_id,
                          game_name: booking.game_name,
                          start_time: booking.start_time,
                          is_custom_pricing_enabled: booking.is_custom_pricing_enabled,
                          slot_cumulative_category_pricing: booking.slot_cumulative_category_pricing,
                          slot_hide_pricing_rate: booking.slot_hide_pricing_rate,
                          slot_pricing_type_id: booking.slot_pricing_type_id,
                          pricing_type_id: booking.pricing_type_id,
                          cumulative_category_pricing: booking.cumulative_category_pricing,
                          hide_pricing_rate: booking.hide_pricing_rate,
                          end_time: booking.end_time}"
                          :key="'A11' + index"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-12" v-if="!price_override">
                <div v-if="!time_slot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'COOP' + i">
                  <label class="text-primary text-uppercase">{{c.category}}
                  </label>
                  <base-input>
                    <el-input-number @change="recalculateDiscount(time_slot, c.archived)" v-model="c.number_of_players" :min="0" />
                  </base-input>
                </div>

                <div v-if="time_slot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'CO' + i">
                  <label class="text-primary text-uppercase">{{c.category}}
                  </label>
                  <base-input>
                    <el-input-number @change="recalculateDiscount(time_slot)" v-model="c.number_of_players" :min="0" />
                  </base-input>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6" v-if="!price_override">
                <p class="text-white text-uppercase">subtotal ({{currency_symbol}})</p>
                <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(subtotal) }}</h2>

                <small>
                  <div v-if="!time_slot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'CC' + i">
                    <span v-if="pricing_type_id === 1" class="text-primary">{{getAmount(getRate(c, time_slot))}} per {{c.category}}</span>
                    <span v-if="pricing_type_id === 2 && subtotal > 0" class="text-primary">{{getRate(c, time_slot)}}</span>
                  </div>
                  <div v-if="time_slot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'CPC' + i">
                    <span v-if="pricing_type_id === 1" class="text-primary">{{getAmount(getRate(c, time_slot))}} per {{c.category}}</span>
                    <span v-if="pricing_type_id === 2 && subtotal > 0" class="text-primary">{{getRate(c, time_slot)}}</span>
                  </div>
                </small>
                <base-button v-if="custom_pricing_categories.length > 0 || pricing_categories.length > 0"
                             size="sm" type="primary" v-on:click="overridePrice(time_slot.is_custom_pricing_enabled, time_slot)">
                  Override price
                </base-button>

              </div>
              <div class="col-lg-6 col-md-6 col" v-if="price_override">
                <p class="text-white text-uppercase">Price override</p>
                <div v-if="!time_slot.is_custom_pricing_enabled" v-for="(c, index) in pricing_categories" :key="'CCEDITOVERRIDE' + index">
                  <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                  <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                  <div class="row">
                    <div class="col">
                      <base-input>
                        <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                      </base-input>
                    </div>
                    <div class="col">
                      <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                      <el-tooltip class="d-inline"
                                  content="Set to original price"
                                  effect="light"
                                  :open-delay="300"
                                  placement="right"
                      >
                        <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div v-if="time_slot.is_custom_pricing_enabled" v-for="(c, index) in custom_pricing_categories" :key="'CCPEDITOVERRIDE' + index">
                  <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                  <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                  <div class="row">
                    <div class="col">
                      <base-input>
                        <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                      </base-input>
                    </div>
                    <div class="col">
                      <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                      <el-tooltip class="d-inline"
                                  content="Set to original price"
                                  effect="light"
                                  :open-delay="300"
                                  placement="right"
                      >
                        <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <base-button size="sm" type="primary" v-on:click="saveOverride(time_slot.is_custom_pricing_enabled, time_slot)">Save</base-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-input :error="errs.number_of_players"><p></p></base-input>
              </div>
            </div>
          </div>

<!--          Edit booking slot-->
          <div v-show="activeTabIndex === 1">
            <div class="row">
              <div class="col-lg-6">
                <label>Booking start date</label>
                <base-input :error="errs.booking_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    :format="day_of_week + date_format_cal"
                    name="date"
                    v-model="selectedBookingSlot.booking_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="Start time" :error="errs.start_time">
                  <el-time-picker
                    v-model="start_time"
                    :format="picker_time_format" placeholder="HH:MM"
                  ></el-time-picker>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <label>Booking end date</label>
                <base-input :error="errs.booking_end_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    :format="day_of_week + date_format_cal"
                    name="date"
                    v-model="selectedBookingSlot.end_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="End time" :error="errs.end_time">
                  <el-time-picker
                    v-model="end_time"
                    :format="picker_time_format" placeholder="HH:MM"
                  ></el-time-picker>
                </base-input>
              </div>
            </div>
          </div>


          <div v-show="activeTabIndex === 3">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-6">
                <label>Amount ({{currency_symbol}})</label>
                <base-input :error="errs.payment_amount" v-model.number="payment_amount"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-6" v-if="selectedPaymentOption.name === 'Customer credit'">
                <label>Available credit ({{currency_symbol}})</label>
                <base-input v-model="available_credit" disabled></base-input>
              </div>
              <div class="col-lg-6 col-md-3 col-6" v-if="selectedPaymentOption.name === 'Gift card' && selectedGiftCardCode">
                <base-input label="Available balance ($)" v-model="selectedGiftCardCode.remaining" disabled></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input label="Payment options">
                  <el-select
                    class="select-primary mb-3"
                    v-model="selectedPaymentOption"
                    placeholder="Option"
                    value-key="id"
                  >
                    <el-option
                      class="select-primary"
                      v-for="(item, index) in paymentOptions"
                      :key="index + 'P'"
                      :label="item.name"
                      :value="{id: item.id, name: item.name, content: item.content, is_default: item.is_default}"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row" v-if="!selectedPaymentOption.is_default">
              <div class="col">
                <div v-html="selectedPaymentOption.content"></div>
              </div>
            </div>

            <div class="row mb-3" v-show="selectedPaymentOption.name === 'Gift card'">
              <div class="col">
                <base-input label="Gift card code" :error="errs.gift_card_code">
                  <el-autocomplete style="width: 100%"
                                   v-model="gift_card_code"
                                   :fetch-suggestions="giftCardSearch"
                                   placeholder="Search by code..."
                                   :trigger-on-focus="false"
                                   @select="handleGiftCardSelect"
                  >
                    <template #default="{ item }">
                      <div class="row">
                        <div class="col">
                          <p style="margin-bottom: -12px" class="text-primary">{{ getGiftCardCode(item.code) }}</p>
                          <small class="font-italic">
                            <i class="tim-icons icon-money-coins"></i> Total {{getAmount(item.total)}} Remaining {{getAmount(item.remaining)}}
                          </small>
                          <br/>
                        </div>
                      </div>
                    </template>
                  </el-autocomplete>
                </base-input>
              </div>
            </div>

            <div class="row" v-if="selectedPaymentOption.name === 'Card'">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.cardholder_first_name" label="Cardholder first name" v-model="cardholder_first_name" placeholder="First name"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.cardholder_last_name" label="Cardholder last name" v-model="cardholder_last_name" placeholder="Last name"></base-input>
              </div>
            </div>

<!--            Authorize.net-->
            <div class="row" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
              <div class="col-12">
                <base-input :error="errs.ccn" v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
              </div>

            </div>
            <div class="row mb-3" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
              <div class="col-4">
                <base-input :error="errs.zip" :placeholder="country.zip_placeholder" v-model="payment.zip" type="text"
                            :label="country.zip_label"></base-input>
              </div>
              <div class="col-4">
                <base-input :error="errs.expiration" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
              </div>
              <div class="col-4">
                <base-input :error="errs.cvv" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
              </div>
            </div>

            <!--                  Square-->
            <div class="row mt-2" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 2">
              <div class="col">
                <form id="payment-form-square-2">
                  <div id="card-container-2"></div>
                </form>
                <div id="payment-status-container-2"></div>
              </div>
            </div>

            <!--        Stripe-->
            <div class="row mb-5" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 3">
              <div class="col">
                <form id="payment-form-2">
                  <label for="card-element">
                    Credit or debit card
                  </label>
                  <div id="card-element-2">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                  <div id="card-errors-2" role="alert"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.edit_booking = false">Cancel</base-button>
            <base-button :disabled="disable_submit" v-if="selectedBookingSlot.transaction_id" class="ml-3" type="primary" @click="updateSlotWithTransactionPrompt()">Update booking</base-button>
            <base-button v-else class="ml-3" type="primary" @click="updateBookingSlotPrompt()">Update time slot</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    Add Timeslot Modal-->
    <modal :show.sync="modals.new_booking_slot"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Add new custom time slot</h4>
        <div class="row">
          <div class="col-lg-8 col-md-12 col-12">
            <label>Game</label>
            <base-input :error="errs.game">
              <el-select
                label="Select game"
                v-model="selectedGame"
                class="select-primary"
                v-on:change="getInitialSubtotal(selectedGame)"
                placeholder="Game">
                <el-option
                  v-for="game in games"
                  class="select-primary"
                  :label="game.name"
                  :value="game.name"
                  :key="'L' + game.id"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label>Booking start date</label>
            <base-input :error="errs.booking_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                :format="day_of_week + date_format_cal"
                name="date"
                v-model="booking_date"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="Start time" :error="errs.start_time">
              <el-time-picker
                v-model="start_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row" :key="j">
          <div class="col-lg-6">
            <label>Booking end date</label>
            <base-input :error="errs.booking_end_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                :format="day_of_week + date_format_cal"
                name="date"
                v-model="booking_end_date"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="End time" :error="errs.end_time">
              <el-time-picker
                v-model="end_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <base-input label="Status">
              <el-select
                label="Select game"
                v-model="selectedStatus"
                class="select-primary"
                placeholder="Status">
                <el-option
                  v-for="(s, i) in statuses"
                  class="select-primary"
                  :label="s"
                  :value="s"
                  :key="'S' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-3">
            <base-button v-on:click="showCustomPricingSettings()" style="font-weight: normal; font-size: small; margin-top: 40px" link type="primary">
              Custom pricing
              <i v-if="!expand_price_settings" class="tim-icons icon-minimal-down"></i>
              <i v-if="expand_price_settings" class="tim-icons icon-minimal-up"></i>
            </base-button>
          </div>
          <div class="col">
            <base-button v-on:click="expand_slot_settings = !expand_slot_settings" style="font-weight: normal; font-size: small; margin-top: 40px" link type="primary">
              Additional settings
              <i v-if="!expand_slot_settings" class="tim-icons icon-minimal-down"></i>
              <i v-if="expand_slot_settings" class="tim-icons icon-minimal-up"></i>
            </base-button>
          </div>
        </div>

        <div v-if="expand_price_settings && selectedStatus !== 'booked'">
          <div class="row">
            <div class="col">
              <base-checkbox v-model="enable_custom_pricing" v-on:input="prepopulateCustomPricingForGame()">Enable custom pricing</base-checkbox>
            </div>
          </div>
          <div v-if="enable_custom_pricing">
            <div class="row mt-2">
              <div class="col-lg-6">
                <base-input label="Pricing type">
                  <el-select
                    label="Select time"
                    v-model="custom_pricing_type"
                    class="select-primary"
                    value-key="id"
                    placeholder="Pricing type">
                    <el-option
                      v-for="(pt, index) in pricing_types"
                      class="select-primary"
                      :label="pt.name"
                      :value="{id: pt.id,
                          name: pt.name}"
                      :key="'PT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col" >
                <base-button class="mr-2" v-on:click="addNewCategory(custom_pricing_categories)" size="sm" type="primary">
                  <i class="tim-icons el-icon-plus"></i> Add new pricing category</base-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-for="(p, i) in custom_pricing_categories" :key="'PP' + i">

                  <div class="row">
                    <div class="col-lg-4" >
                      <label>Pricing category name</label>

                      <el-tooltip class="d-inline"
                                  content="Remove category"
                                  effect="light"
                                  :open-delay="300"
                                  placement="right"
                      >
                        <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(custom_pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                      </el-tooltip>
                      <base-input class="d-inline" placeholder="Category name" v-model="p.category">
                      </base-input>
                    </div>
                    <div class="col-lg-4" >
                      <base-input label="Minimum required participants">
                        <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                    <div class="col-lg-4">
                      <base-input label="Default number of participants">
                        <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                      </base-input>
                    </div>
                  </div>

                  <div class="col">
                    <div class="row" v-for="(k, j) in p.pricing" :key="'PPP' + j">
                      <div class="col" >
                        <base-input  :label="'Minimum participants'">
                          <el-input-number size="small" v-model="k.min_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col" >
                        <base-input :label="'Maximum participants'">
                          <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col">
                        <base-input :label="'Price (' + currency_symbol + ')'">
                          <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                        </base-input>
                      </div>
                      <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                        <el-tooltip
                          content="Remove price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(custom_pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                        </el-tooltip>
                        <el-tooltip
                          content="Add price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button v-if="j == custom_pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(custom_pricing_categories[i], i)" style="padding: 0;" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-checkbox v-model="cumulative_category_pricing">Cumulative category pricing</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-checkbox v-model="allow_deposit">Allow deposit</base-checkbox>
                <base-checkbox v-if="allow_deposit" v-model="require_deposit">Require only deposit amount during checkout</base-checkbox>
              </div>
              <div class="col-lg-3 mt-2" v-if="allow_deposit">
                <base-input label="Deposit type">
                  <el-select
                    v-model="deposit_type"
                    class="select-primary"
                    name="deposit_type"
                    placeholder="Value type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in valueTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'VT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 mt-2" v-if="allow_deposit">
                <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount"
                            v-model.number="deposit_amount"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="selectedStatus === 'Available' && expand_slot_settings">
          <div class="col">
            <label>
              <div>Minimum booking notice
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how soon an event can be booked.<br/>
                      This setting will help prevent last minute bookings.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>

            <div class="row">
              <div class="col-lg-4">
                <base-input v-model="min_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-lg-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="min_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in min_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'M' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <label>
              <div>Maximum bookings in advance
                <el-popover trigger="hover"
                            placement="right">

                  <div>
                    <div class="popover-body">Choose how far in advance customers can book this event.<br/>
                      By default, bookings are generate 12 months in advance.</div>
                  </div>
                  <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                </el-popover>
              </div>
            </label>
            <div class="row">
              <div class="col-lg-4">
                <base-input v-model="max_booking_value"
                            type="number">
                </base-input>
              </div>
              <div class="col-lg-8">
                <base-input>
                  <el-select
                    label="Select period"
                    v-model="max_booking_period"
                    class="select-primary"
                    value-key="id"
                  >
                    <el-option
                      v-for="(p, index) in max_periods"
                      class="select-primary"
                      :label="p.label"
                      :value="{id: p.id, label: p.label}"
                      :key="'X' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="expand_slot_settings">
          <!--              Call to book or blocked-->
          <div class="col-lg-6" >
            <label v-if="selectedStatus === 'Available'">Minimum notice block off text
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This text will be visible to customers when a slot is blocked off due <br/> to minimum booking notice coming into effect.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <label v-if="selectedStatus === 'Call to book'">Time slot text
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This text will be visible to customers when a slot has the status 'Call to book'.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <base-input v-if="selectedStatus === 'Available' || selectedStatus === 'Call to book'" v-model="slot_text"></base-input>
          </div>
          <div class="col-lg-6" v-if="selectedStatus === 'Available' || selectedStatus === 'Call to book'">
            <base-input label="External link" v-if="has_external_link"  v-model="external_link" placeholder="https://example.com"></base-input>
            <base-checkbox v-model="has_external_link">Include an external link
            </base-checkbox>
          </div>
        </div>
        <div class="row" v-if="selectedStatus !== 'Available' && expand_slot_settings">
          <div class="col">
            <label>
              Block note
              <el-popover trigger="hover" placement="right">
                <div>
                  <div class="popover-body">This note is for internal reference only.
                  </div>
                </div>
                <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
              </el-popover>
            </label>
            <base-input>
              <el-input
                v-model="block_note"
                :rows="5"
                type="textarea"
                placeholder="Notes..."
              />
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.new_booking_slot = false">Cancel</base-button>
          <base-button class="ml-3" :disabled="loading" type="primary" @click="createTimeSlotPrompt()">Create time slot</base-button>
        </div>
      </card>
    </modal>

    <!--    Add Notes Modal-->
    <modal :show.sync="modals.add_notes"
           body-classes="p-0"
           modal-classes="modal-dialog-centered">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Notes for {{selectedTimeSlot.game_name}}</h4>
        <div v-if="getJSON(selectedTimeSlot.notes, 'timestamp') && getJSON(selectedTimeSlot.notes, 'timestamp').length >0"
             style="overflow-y: scroll; overflow-x: hidden; max-height: 250px;">
          <div class="row" v-for="(n, i) in getJSON(selectedTimeSlot.notes, 'timestamp')" :key="'N' + i">
            <div class="col">
              <base-alert type="white" style="margin-bottom: -5px;" class="text-black-50">
                <div class="row">
                  <div class="col text-right">
                    <el-tooltip content="Mark note as important" v-if="!n.is_important"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-right: -20px"
                                   type="default"
                                   class="closed btn btn-link"
                                   aria-label="Close"
                                   icon
                                   @click="markNoteAsImportant(n.id)"
                      >
                        <i class="tim-icons el-icon-star-off"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Important" v-if="n.is_important"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-right: -20px"
                                   type="default"
                                   class="closed btn btn-link"
                                   aria-label="Close"
                                   icon
                      >
                        <i v-if="n.is_important" style="font-size: large;" class="text-warning tim-icons el-icon-star-on"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Delete note"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-right: 0px"
                                   type="default"
                                   class="closed btn btn-link"
                                   aria-label="Close"
                                   icon
                                   @click="deleteNote(n.id)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </div>
                <div class="row">
                  <div class="col-11">
                    {{n.note}}
                  </div>
                </div>
              </base-alert>
              <small class="text-white font-italic">{{getLocalDate(n.timestamp)}}, {{getLocalTime(n.timestamp)}}, {{n.first_name}} {{n.last_name}}
              </small>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col">
            <base-input label="Time slot notes">
              <el-input
                v-model="selectedTimeSlot.note"
                :rows="5"
                type="textarea"
                placeholder="Add a new note for this timeslot"
              />
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.add_notes = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="addNewComment()">Create note</base-button>
        </div>
      </card>
    </modal>

    <!--Medium and large screens-->
    <div class="row mt-3 d-none d-sm-block">
      <div class="col-12">
        <card>
          <h4 class="card-title">Bookings </h4>
          <div class="row">
            <div class="col">
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by status</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select multiple
                            v-model="filters.status.value"
                            class="select-primary"
                            name="game"
                            placeholder="Status"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(s, i) in filter_statuses"
                              class="select-primary"
                              :label="s.label"
                              :value="{id: s.id, label: s.label, status: s.status}"
                              :key="'FS' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getBookings()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>


                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container"  style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select multiple
                                     v-model="filters.game.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Game"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(game, i) in games"
                              class="select-primary"
                              :label="game.name"
                              :value="{ id: game.id, name: game.name}"
                              :key="'GG' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getBookings()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
              </base-input>
            </div>
          </div>

          <div class="row" v-if="resource_slots.length > 0">
            <div class="col">
              <base-button v-if="show_resources" size="sm" class="hover-link" v-on:click="show_resources = false"
                           link style="padding-left: 0px; padding-top: 0px" simple type="primary">Hide assigned resources
                <i class="tim-icons icon-minimal-down"></i>
              </base-button>
              <base-button v-if="!show_resources" size="sm" class="hover-link" v-on:click="show_resources = true"
                           link style="padding-left: 0px; padding-top: 0px" simple type="primary">Show assigned resources
                <i class="tim-icons icon-minimal-down"></i>
              </base-button>
            </div>
          </div>

          <div class="row mb-2" style="position: sticky; top: 0; padding-top: 20px; background-color: #27293c; z-index: 100" v-if="show_resources && resource_slots.length > 0" >
            <div class="col">
              <h4 class="card-title">Resources</h4>
              <b-table striped hover :items="resource_slots"
                  :fields="['resource', 'start', 'end', 'behavior', 'schedule_name', 'actions']"     >
                <template v-slot:cell(resource)="row">
                  <span v-if="row.item.company_user_id">{{row.item.first_name}} {{row.item.last_name}}</span>
                  <span v-else>{{row.item.label}}</span>
                </template>
                <template v-slot:cell(start)="row">
                  <span>{{getFormattedDate(row.item.start_date)}}, {{getTime(row.item.start_time)}}</span>
                </template>
                <template v-slot:cell(end)="row">
                  <span>{{getFormattedDate(row.item.end_date)}}, {{getTime(row.item.end_time)}}</span>
                </template>
                <template v-slot:cell(behavior)="row">
                  <badge type="default" v-if="row.item.enable_autoblock && row.item.block_status === 'call_to_book'">Auto Call To Book</badge>
                  <badge type="default" v-if="row.item.enable_autoblock && row.item.block_status === 'blocked'">Auto Block</badge>
                  <badge type="default" v-if="!row.item.enable_autoblock">No action</badge>
                </template>
                <template v-slot:cell(assigned_resources)="row">
                  <span>{{row.item.count}}</span>
                </template>
                <template v-slot:cell(schedule_name)="row">
                  <badge type="default"  v-if="row.item.schedule_id">
                    <i class="tim-icons icon-calendar-60 mr-1"></i> {{row.item.schedule_name}}</badge>
                  <badge type="default" v-else><i class="tim-icons icon-calendar-60 mr-1"></i> Manually created</badge>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-2 mb-2" @click="updateResourceSlotPrompt(row.item, $event)" href="#">
                          <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit resource slot
                        </a>
                        <hr>
                        <a class="dropdown-item mt-2 mb-2" @click="deleteResourceSlotPrompt(row.item, $event, 'menu')" href="#">
                          <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete resource slot
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
              <hr style="border-top: 1px solid #f4f5f7;">
            </div>
          </div>

          <!--   No data panel -->
          <div v-if="bookings.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>

          <b-table v-if="bookings.length > 0" striped hover :items="bookings"
                    :fields="fields">
            <template v-slot:cell(time)="row">
              <div style="min-width: 100px;" class="row justify-content-center">
                <h4>{{getTime(row.item.start_time)}}</h4>
              </div>
            </template>
            <template v-slot:cell(staff)="row">
              <div v-if="row.item.staff.length > 0">
                <div v-for="(staff, index) in row.item.staff" :key="'STAFF' + index">
                  <p class="text-white">{{staff.first_name}}  {{staff.last_name}}</p>
                </div>
              </div>
              <span v-else>{{getAvailableStaffCount(row.item).assigned}} assigned</span>
              <div class="row">
                <div class="col">
                  {{getAvailableStaffCount(row.item).available}}/{{getAvailableStaffCount(row.item).total}} available
                </div>
              </div>
            </template>
            <template #head(time)="row">
              <div class="row justify-content-center" style="min-width: 100px;">
                <badge type="primary"> {{getFormattedDate(current)}}</badge>
              </div>
            </template>
            <template v-slot:cell(booking)="row">
              <div class="row" >
                <div class="mt-1 circular">
                  <img :src="row.item.url"/>
                </div>
                <div class="col">
                  <p v-if="row.item.status == 'booked' && row.item.transaction_status !== 2" style="font-size: medium" class="text-info">Booked</p>
                  <p v-if="row.item.status == 'booked' && row.item.transaction_status === 2" style="font-size: medium" class="text-secondary">Pending</p>
                  <p v-if="row.item.status == 'checked'" style="font-size: medium" class="text-danger">Waiver signed, no booking</p>
                  <p v-if="row.item.status == 'expired'" style="font-size: medium" class="text-warning">Passed</p>
                  <p v-if="row.item.status == 'available'" style="font-size: medium" class="text-success">
                    Available <badge type="danger" class="ml-2" v-if="row.item.in_cart">In cart</badge>
                  </p>
                  <p v-if="row.item.status == 'completed'" style="font-size: medium" class="text-info">Completed</p>
                  <p v-if="row.item.status == 'blocked'" style="font-size: medium" class="text-danger">Blocked</p>
                  <p v-if="row.item.status == 'call_to_book'" style="font-size: medium" class="text-warning">
                    Call to book
                    <badge type="danger" class="ml-2" v-if="row.item.in_cart">In cart</badge>
                  </p>
                  <p>{{row.item.game_name}} </p>
                  <badge type="default" v-if="row.item.schedule_id && show_schedule_name">
                    <i class="tim-icons icon-calendar-60 mr-1"></i> {{row.item.schedule_name}}</badge>
                  <badge type="default" v-if="!row.item.schedule_id && show_schedule_name"><i class="tim-icons icon-calendar-60 mr-1"></i> Manually created</badge>
                </div>
              </div>
            </template>
            <template v-slot:cell(due)="row">
              <span v-if="row.item.transaction_status === 2">
                <span class="text-danger">{{getAmount(row.item.total)}}</span>
              </span>
              <span v-else>
                <span class="text-danger" v-if="row.item.due > 0">{{getAmount(row.item.due)}}</span>
                <span v-if="row.item.due <= 0">{{getAmount(row.item.due)}}</span>
                <span v-if="row.item.due === null">-</span>
              </span>
            </template>
            <template v-slot:cell(paid)="row">
              <span v-if="row.item.transaction_status === 2">
                <span>{{getAmount(0)}}</span>
              </span>
              <span v-else>
                <span v-if="row.item.paid >= 0">{{getAmount(row.item.paid)}}</span>
                <span v-if="row.item.paid === null">-</span>
              </span>
            </template>
            <template v-slot:cell(number_of_players)="row">
              <div class="row">
                <div class="col">
                  <p v-if="row.item.group_size > 0">{{row.item.group_size}} booked</p>
                  <p v-if="row.item.group_size > 0 && !row.item.waivers">0 checked in</p>
                  <div :key="'TEAM' + j">
                    <el-popover trigger="hover"
                                placement="bottom">
                      <div>
                        <div class="popover-body">
                          <div class="mb-2" v-for="(p, i) in JSON.parse(row.item.waivers)" :key="'C' + i">
                            <p class="text-default">{{p.first_name}} {{p.last_name}}
                              <badge type="danger" class="ml-1 mr-2" v-if="p.not_participating">not participating</badge>
                              <badge type="primary" class="ml-1 mr-2" v-if="!p.email">minor</badge>
                              <i v-if="hasPermissions('customer_view') && p.email" style="cursor: pointer" v-on:click="viewCustomer(p.user_id)" class="tim-icons el-icon-top-right text-primary"></i>
                            </p>
                            <small style="font-style: italic" v-if="p.email">
                              <i class="tim-icons icon-email-85"></i> {{p.email}}</small>
                          </div>
                        </div>
                      </div>
                      <p slot="reference" v-if="row.item.waivers"  style="cursor: pointer" >{{JSON.parse(row.item.waivers).length}} checked in
                        <i class="tim-icons icon-minimal-down" style="font-size: smaller"></i>
                      </p>
                    </el-popover>
                  </div>
                  <p v-if="row.item.group_size <= 0 && !row.item.waivers">-</p>
                </div>
              </div>
            </template>
            <template v-slot:cell(booked_by)="row">
              <div v-if="row.item.end_user_id">
                <p class="text-white">{{row.item.first_name}}  {{row.item.last_name}}
                  <el-tooltip content="View customer"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button v-if="hasPermissions('customer_view')" v-on:click="viewCustomer(row.item.end_user_id)" icon class="btn btn-link" simple size="sm" type="primary">
                      <i class="tim-icons el-icon-top-right"></i>
                    </base-button>
                  </el-tooltip>
                  <base-button
                    v-if="row.item.customer_alerts" style="padding: 3px 5px 3px 5px; line-height: 12px; text-decoration: none;
                  border-radius: 3px; font-size: xx-small" class="text-uppercase"
                         v-on:click="viewCustomerAlerts(row.item)"
                         type="primary">customer alert</base-button>
                </p>
                <small class="text-white">
                  <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                </small>
                <small class="text-white ml-2" v-if="row.item.phone">
                  <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}
                </small>
              </div>
              <span v-else>-</span>
            </template>

            <template v-slot:cell(notes)="row">
              <div class="row" style="max-width: 350px; min-width: 250px">
                <div class="col" v-if="JSON.parse(row.item.notes).length <= 1 && JSON.parse(row.item.notes)[0].id == null">
                  <base-button type="default" size="sm" @click="addNote(row.item)">
                    Add note </base-button>
                </div>
                <div class="col">
                  <p style="cursor: pointer;" v-on:click="addNote(row.item)"> {{getHeadlineNote(row.item.notes)}}
                    <span class="ml-1" v-on:click="addNote(row.item)" v-if="JSON.parse(row.item.notes).length > 1" style="cursor: pointer; font-size: small; background-color: #e14eca; border-radius: 10px; padding-left: 4px; padding-right: 4px">
                      +{{JSON.parse(row.item.notes).length - 1}}
                    </span>
                  </p>
                </div>

              </div>
            </template>
            <template v-slot:cell(actions)="row">
              <el-dropdown>
                <base-button
                  simple
                  type="primary"
                  link
                > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item mt-1 mb-2" v-if="row.item.transaction_id && hasPermissions('transactions_view')"
                       target="_blank" :href="'/purchases/transactions/details?id=' + row.item.transaction_id">
                      <i class="tim-icons text-info icon-notes mr-2"></i> View transaction details
                    </a>
                    <a class="dropdown-item mb-2" href="#"
                       v-if="hasPermissions('bookings_create') && (!row.item.transaction_id)"
                       @click="addToCart(row.item)">
                      <i style="color: #42b883" class="tim-icons icon-cart mr-2"></i> Add to cart
                    </a>
                    <a class="dropdown-item mb-2" href="#"
                       v-if="hasPermissions('bookings_create') && (!row.item.transaction_id)"
                       @click="newBooking($event, row.item)">
                      <i style="color: #42b883" class="tim-icons el-icon-circle-plus mr-2"></i> Book now
                    </a>
                    <a v-if="row.item.transaction_id && hasPermissions('bookings_update')" class="dropdown-item mb-2" @click="editBooking(row.item, $event)" href="#">
                      <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit booking
                    </a>
                    <a v-else-if="!row.item.transaction_id && hasPermissions('booking_slot_update')" class="dropdown-item mb-2" @click="editBooking(row.item, $event)" href="#">
                      <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit time slot
                    </a>
                    <a v-if="staff_settings && show_staff_settings" class="dropdown-item mb-2" href="#" @click="manageStaff(row.item)">
                      <i class="tim-icons text-default el-icon-user-solid mr-2"></i> Manage staff on booking
                    </a>
                    <a class="dropdown-item mb-2" href="#" @click="viewActivityLog(row.item, $event)">
                      <i class="tim-icons text-info el-icon-notebook-2 mr-2"></i> View activity log
                    </a>
                    <hr v-if="(hasPermissions('booking_slot_delete') && !(row.item.status == 'booked'  || row.item.status == 'completed'))
                    || (hasPermissions('bookings_cancel') && (row.item.status == 'booked' || row.item.status == 'completed'))">
                    <a v-if="hasPermissions('booking_slot_delete') && !(row.item.status == 'booked'  || (row.item.status == 'completed' && row.item.transaction_id))"
                       class="dropdown-item mb-2" @click="deleteBooking(row.item)" href="#">
                      <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete time slot
                    </a>
                    <a v-if="(row.item.status == 'booked'  || (row.item.status == 'completed' && row.item.transaction_id))
                    && hasPermissions('bookings_cancel')"
                       class="dropdown-item mb-2" @click="deleteBooking(row.item)" href="#">
                      <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Cancel booking
                    </a>

                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </b-table>
        </card>
      </div>
    </div>

<!--    Mobile-->
    <!--Medium and large screens-->
    <div class="row mt-3 d-block d-sm-none overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="row justify-content-between">
            <div class="col">
              <h4 class="card-title">Bookings </h4>
            </div>
            <div class="col text-right">
              <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
              <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
            </div>
          </div>
          <div class="row justify-content-between" v-show="show_filters">
            <div class="col">
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by status</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select multiple
                                     v-model="filters.status.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Status"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(s, i) in filter_statuses"
                              class="select-primary"
                              :label="s.label"
                              :value="{id: s.id, label: s.label, status: s.status}"
                              :key="'FS' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getBookings()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>


                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container"  style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select multiple
                                     v-model="filters.game.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Game"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(game, i) in games"
                              class="select-primary"
                              :label="game.name"
                              :value="{ id: game.id, name: game.name}"
                              :key="'GG' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getBookings()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
              </base-input>
            </div>
          </div>
          <!--   No data panel -->
          <div v-if="bookings.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b-table v-if="bookings.length > 0" striped hover :items="bookings"
                       :fields="mobileFields">
                <template v-slot:cell(booking)="row">
                  <div class="row justify-content-between">
                    <div class="col-8">
                      <p v-if="row.item.status == 'booked'" style="font-size: medium" class="text-primary">Booked</p>
                      <p v-if="row.item.status == 'checked'" style="font-size: medium" class="text-danger">Checked in, no booking</p>
                      <p v-if="row.item.status == 'expired'" style="font-size: medium" class="text-warning">Passed</p>
                      <p v-if="row.item.status == 'available'" style="font-size: medium" class="text-success">Available</p>
                      <p v-if="row.item.status == 'completed'" style="font-size: medium" class="text-info">Completed</p>
                      <p v-if="row.item.status == 'blocked'" style="font-size: medium" class="text-danger">Blocked</p>
                      <p v-if="row.item.status == 'call_to_book'" style="font-size: medium" class="text-warning">Call to book</p>
                      <p>{{row.item.game_name}}
                        <base-button v-if="hasPermissions('bookings_create')
                         && (row.item.status === 'available' || row.item.status == 'checked' || row.item.status === 'call_to_book' || row.item.status === 'blocked')"
                                     @click.native="addToCart(row.item)"
                                     type="primary"
                                     class="btn btn-link d-inline"
                                     size="sm"
                                     icon
                        ><i class="tim-icons icon-cart"></i>
                        </base-button>
                      </p>
                    </div>
                    <div class="col text-right">
                      <h4>{{getTime(row.item.start_time)}}</h4>
                    </div>

                  </div>
                  <badge type="default"  v-if="row.item.schedule_id && show_schedule_name">
                    <i class="tim-icons icon-calendar-60 mr-1"></i> {{row.item.schedule_name}}</badge>
                  <badge type="default" v-if="!row.item.schedule_id && show_schedule_name"><i class="tim-icons icon-calendar-60 mr-1"></i> Manually created</badge>

                  <div class="mt-2 " v-if="row.item.order_number">
                    <p>Transaction #{{row.item.order_number}}
                    <small class="ml-2 text-danger" v-if="row.item.due > 0">Due: {{getAmount(row.item.due)}}</small>
                    <small class="ml-2" v-if="row.item.due <= 0">Due: {{getAmount(row.item.due)}}</small>
                    <small class="ml-2" v-if="row.item.paid >= 0">Paid: {{getAmount(row.item.paid)}}</small>
                    </p>
                  </div>

                  <div class="row">
                    <div class="col">
                      <p v-if="row.item.group_size > 0">Booked for {{row.item.group_size}} people</p>
                    </div>
                    <div class="col">
                      <p v-if="row.item.group_size > 0 && !row.item.waivers">0 checked in</p>
                      <div>
                        <el-popover trigger="hover"
                                    placement="bottom">
                          <div>
                            <div class="popover-body">
                              <div class="mb-2" v-for="(p, i) in JSON.parse(row.item.waivers)" :key="'C' + i">
                                <p class="text-default">{{p.first_name}} {{p.last_name}}
                                  <i v-if="hasPermissions('customer_view')" style="cursor: pointer" v-on:click="viewCustomer(p.user_id)" class="tim-icons el-icon-top-right text-primary"></i>
                                </p>
                                <small style="font-style: italic">
                                  <i class="tim-icons icon-email-85"></i> {{p.email}}</small>
                              </div>
                            </div>
                          </div>
                          <p slot="reference" v-if="row.item.waivers"  style="cursor: pointer" >{{JSON.parse(row.item.waivers).length}} checked in
                            <i class="tim-icons icon-minimal-down" style="font-size: smaller"></i>
                          </p>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div v-if="row.item.end_user_id">
                    <p class="text-white">Booked by {{row.item.first_name}}  {{row.item.last_name}}
                      <el-tooltip content="View customer"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                        <base-button v-if="hasPermissions('customer_view')" v-on:click="viewCustomer(row.item.end_user_id)" icon class="btn btn-link" simple size="sm" type="primary">
                          <i class="tim-icons el-icon-top-right"></i>
                        </base-button>
                      </el-tooltip>
                      <base-button
                        v-if="row.item.customer_alerts" style="padding: 3px 5px 3px 5px; line-height: 12px; text-decoration: none;
                  border-radius: 3px; font-size: xx-small" class="text-uppercase"
                        v-on:click="viewCustomerAlerts(row.item)"
                        type="primary">customer alert</base-button>
                    </p>
                    <small class="text-white">
                      <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                    </small>
                    <small class="text-white ml-2" v-if="row.item.phone">
                      <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}
                    </small>
                  </div>

                  <div v-if="JSON.parse(row.item.notes).length > 1" class="row mt-2" style="max-width: 350px; min-width: 250px">
                    <div class="col">
                      <small class="text-white">
                        <i class="tim-icons icon-pin"></i> {{getHeadlineNote(row.item.notes)}}
                        <sup v-if="JSON.parse(row.item.notes).length > 1" style="font-size: xx-small; background-color: #e14eca; border-radius: 10px; padding-left: 4px; padding-right: 4px">
                          +{{JSON.parse(row.item.notes).length - 1}}
                        </sup>
                      </small>
                    </div>

                  </div>

                  <div
                    class="btn-group btn-group-toggle mt-3"
                    :class="'float-left'"
                    data-toggle="buttons" style="width:100%; max-width: 100%"
                  >
                    <label v-if="hasPermissions('booking_slot_update') || hasPermissions('bookings_update')"
                      class="btn btn-sm btn-primary btn-simple" >
                      <base-button style="padding-left: 0; padding-right: 0"
                        @click.native="editBooking(row.item, $event)"
                        type="primary"
                        class="btn btn-link"
                        size="sm"
                        icon
                      ><i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </label>
                    <label class="btn btn-sm btn-primary btn-simple" v-if="row.item.transaction_id && hasPermissions('transactions_view')">
                      <base-button style="padding-left: 0; padding-right: 0"
                                   @click.native="viewBookingDetails(row.item)"
                                   type="primary"
                                   class="btn btn-link"
                                   size="sm"
                                   icon
                      ><i class="tim-icons icon-notes"></i>
                      </base-button>
                    </label>

                    <label class="btn btn-sm btn-primary btn-simple">
                      <base-button style="padding-left: 0; padding-right: 0"
                                   @click.native="addNote(row.item)"
                                   class="like btn-link"
                                   type="primary"
                                   size="sm"
                                   icon
                      ><i class="tim-icons icon-chat-33"></i>
                      </base-button>
                    </label>

                    <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('booking_slot_delete') || hasPermissions('bookings_cancel')">
                      <base-button style="padding-left: 0; padding-right: 0"
                                   @click.native="deleteBooking(row.item)"
                                   type="primary"
                                   class="btn btn-link"
                                   size="sm"
                                   icon
                      ><i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </label>


                  </div>

                </template>

                <template #head(booking)="row">
                  <span></span>
                </template>
              </b-table>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--    Create time slot-->
    <modal :show.sync="modals.create_resource_slot"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Create a new resource time slot </h4>

        <div class="row" >
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Start date" :error="errs.start_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                :format="day_of_week + date_format_cal"
                name="date"
                v-model="selectedSlot.start_date"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="Start time" :error="errs.start_time">
              <el-time-picker
                v-model="selectedSlot.start_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="End date" :error="errs.end_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                :format="day_of_week + date_format_cal"
                name="date"
                v-model="selectedSlot.end_date"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="End time" :error="errs.end_time">
              <el-time-picker
                v-model="selectedSlot.end_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-checkbox v-model="selectedSlot.enable_autoblock">Block events with insufficient resource coverage</base-checkbox>
          </div>
        </div>
        <div class="row mt-2" v-if="selectedSlot.enable_autoblock">
          <div class="col-lg-6">
            <base-input label="Block slots with the following status">
              <el-select
                v-model="selectedSlot.block_status"
                class="select-primary"
                placeholder="Status"
                value-key="id"
              >
                <el-option
                  v-for="(s, i) in block_statuses"
                  class="select-primary"
                  :label="s.name"
                  :value="{id: s.id, name: s.name, label: s.label}"
                  :key="'S' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <hr style="border-top: 1px solid #f4f5f7;">
        <div class="row">
          <div class="col-lg-4">
            <p class="text-uppercase card-title">Assignment</p>
          </div>
        </div>

        <div v-if="!edit_resource_user && !edit_resource_booking">
          <div class="row" >
            <div class="col-lg-8">
              <label>Staff
                <el-tooltip content="Edit assigned staff"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <i style="cursor: pointer" class="tim-icons text-primary icon-pencil" v-on:click="editAssignedStaffOnResourceSlot()"></i>
                </el-tooltip>

              </label>
              <p v-if="selectedSlot.company_user_id" class="text-white">{{selectedSlot.first_name}} {{selectedSlot.last_name}}</p>
              <div>
                <badge type="default" v-if="!selectedSlot.company_user_id">{{selectedSlot.label}}</badge>
              </div>
            </div>
            <div class="col">
              <label>
                <div>Concurrent events
                  <el-popover trigger="hover"
                              placement="top">

                    <div>
                      <div class="popover-body">Number of events this resource can support at the same time</div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </div>
              </label>
              <div class="row">
                <div class="col-lg-8">
                  <base-input>
                    <el-input-number size="small" v-model="selectedSlot.supported_event_count" :min="1" />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <base-button v-on:click="edit_resource_booking = true" type="primary" size="sm">
                <i class="tim-icons icon-simple-add"></i>
                Assign to a booking
              </base-button>
            </div>
          </div>

          <div v-if="selectedSlot.hasOwnProperty('assignment') && selectedSlot.assignment.length > 0">
            <div class="row">
              <div class="col">
                <b-table  v-if="selectedSlot.assignment.length > 0 && activeTabIndex === 0" :items="selectedSlot.assignment"
                          :fields="['event', 'time_assigned_to_event', 'time_freed_up', 'actions']">
                  <template v-slot:cell(event)="row">
                    <div style="margin-bottom: -8px" >
                      <span class="text-primary">{{ row.item.name }}</span>
                      <badge class="ml-2">#{{ row.item.booking_slot_id}}</badge>  &nbsp;
                    </div>
                    <small class="font-italic">
                      <i class="tim-icons icon-calendar-60"></i> {{getTime( row.item.start_time)}}-{{getTime( row.item.end_time)}}
                    </small>
                  </template>
                  <template v-slot:cell(time_assigned_to_event)="row">
                    <span v-if="row.item.time_assigned">{{getLocalDate(row.item.time_assigned)}}, {{getLocalTime(row.item.time_assigned)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(time_freed_up)="row">
                    <span v-if="row.item.time_freed_up">{{getLocalDate(row.item.time_freed_up)}}, {{getLocalTime(row.item.time_freed_up)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!--                          <a class="dropdown-item mt-2 mb-2" @click="deleteSurveyPrompt(row.item)" href="#">-->
                          <!--                            <i class="tim-icons icon-refresh-02 text-default mr-2"></i> Tag out-->
                          <!--                          </a>-->
                          <!--                          <hr>-->
                          <a class="dropdown-item mt-2 mb-2" @click="removeBookingAssignment(row.item, row.index, $event)" href="#">
                            <i class="tim-icons icon-simple-remove text-danger mr-2"></i> Remove booking assignment
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>

                </b-table>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center">
              <base-button type="secondary" @click="modals.create_resource_slot = false">Cancel</base-button>
              <base-button class="ml-3" :disabled="!hasPermissions('resource_slots_update') || loading" type="primary"
                           @click="createResourceSlotPrompt()">Create resource slot</base-button>
            </div>
          </div>
        </div>


        <div v-if="edit_resource_booking">
          <div class="row">
            <div class="col">
              <base-input label="Add booking">
                <el-autocomplete style="width: 100%"
                                 v-model="selectedSlot.resource_assigned_value"
                                 :fetch-suggestions="querySearchBu"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelectBu"
                >
                  <template #default="{ item }">
                    <div style="margin-bottom: -12px" >
                      <span class="text-primary">{{ item.name }}</span>
                    </div>
                    <small class="font-italic">
                      <i class="tim-icons icon-calendar-60"></i> {{getTime(item.start_time)}}-{{getTime(item.end_time)}},  #{{item.id}}  &nbsp;
                    </small>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col">
              <base-button size="sm" type="secondary" @click="edit_resource_booking = false">Cancel</base-button>
              <base-button size="sm" class="ml-2" :disabled="!hasPermissions('resource_slots_update')" type="primary"
                           @click="updateAssignedBookingOnResourceSlot()">Update</base-button>
            </div>
          </div>

        </div>

        <div v-if="edit_resource_user">
          <div class="row mb-2">
            <div class="col">
              <el-switch active-color="#e14eca" v-on:change="toggleAnonymousResource()"
                         v-model="selectedSlot.anomymous_resource"  />
              <label> &nbsp; Anonymous resource</label>
            </div>
          </div>

          <div class="row" v-if="selectedSlot.anomymous_resource">
            <div class="col">
              <base-input label="Resource label" placeholder="Anonymous" v-model="selectedSlot.resource_assignee_value"></base-input>
            </div>
          </div>

          <div class="row" v-if="!selectedSlot.anomymous_resource">
            <div class="col">
              <base-input label="Add staff (search by name or email)">
                <el-autocomplete style="width: 100%"
                                 v-model="selectedSlot.resource_assignee_value"
                                 :fetch-suggestions="querySearchCu"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelectRu"
                >
                  <template #default="{ item }">
                    <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                    <small class="font-italic">
                      <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    </small>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col">
              <base-button size="sm" type="secondary" @click="edit_resource_user = false">Cancel</base-button>
              <base-button size="sm" class="ml-2" :disabled="!hasPermissions('resource_slots_update')" type="primary"
                           @click="updateAssignedStaffOnResourceSlot()">Update</base-button>
            </div>
          </div>
        </div>
      </card>
    </modal>

    <!--    Resources edit-->
    <modal :show.sync="modals.edit_resource_modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <div class="row justify-content-between">
          <div class="col">
            <h4>Edit resource time slot</h4>
          </div>
          <div class="col text-right">
            <el-tooltip content="Delete resource slot"
                        effect="light"
                        :open-delay="300"
                        placement="top">
              <base-button
                type="danger"
                class="closed btn btn-link"
                aria-label="Close"
                icon
                @click="deleteResourceSlotPrompt(selectedSlot, $event, 'menu')"
              >
                <i class="tim-icons icon-trash-simple"></i>
              </base-button>
            </el-tooltip>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Start date" :error="errs.start_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                name="date"
                v-model="selectedSlot.start_date"
                :format="day_of_week + date_format_cal"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="Start time" :error="errs.start_time">
              <el-time-picker
                v-model="selectedSlot.start_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="End date" :error="errs.end_date">
              <el-date-picker
                type="date"
                placeholder="mm-dd-yyyy"
                name="date"
                v-model="selectedSlot.end_date"
                :format="day_of_week + date_format_cal"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col">
            <base-input label="End time" :error="errs.end_time">
              <el-time-picker
                v-model="selectedSlot.end_time"
                :format="picker_time_format" placeholder="HH:MM"
              ></el-time-picker>
            </base-input>
          </div>
        </div>

        <div class="row" :key="'Z' + j">
          <div class="col">
            <base-checkbox v-on:input="j++" v-model="selectedSlot.enable_autoblock">Block events with insufficient resource coverage</base-checkbox>
          </div>
        </div>
        <div class="row mt-2" v-if="selectedSlot.enable_autoblock" :key="'X' + j">
          <div class="col-lg-6">
            <base-input label="Block slots with the following status">
              <el-select
                v-model="selectedSlot.block_status"
                class="select-primary"
                placeholder="Status"
                value-key="id"
                v-on:change="j++"
              >
                <el-option
                  v-for="(s, i) in block_statuses"
                  class="select-primary"
                  :label="s.name"
                  :value="{id: s.id, name: s.name, label: s.label}"
                  :key="'S' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <hr style="border-top: 1px solid #f4f5f7;">
        <div class="row">
          <div class="col-lg-4">
            <p class="text-uppercase card-title">Assignment</p>
          </div>
        </div>

        <div v-if="!edit_resource_user && !edit_resource_booking">
          <div class="row" >
            <div class="col-lg-8">
              <label>Staff
                <el-tooltip content="Edit assigned staff"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <i style="cursor: pointer" class="tim-icons text-primary icon-pencil" v-on:click="editAssignedStaffOnResourceSlot()"></i>
                </el-tooltip>

              </label>
              <p v-if="selectedSlot.company_user_id" class="text-white">{{selectedSlot.first_name}} {{selectedSlot.last_name}}</p>
              <div>
                <badge type="default" v-if="!selectedSlot.company_user_id">{{selectedSlot.label}}</badge>
              </div>
            </div>
            <div class="col">
              <label>
                <div>Concurrent events
                  <el-popover trigger="hover"
                              placement="top">

                    <div>
                      <div class="popover-body">Number of events this resource can support at the same time</div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </div>
              </label>
              <div class="row">
                <div class="col-lg-8">
                  <base-input>
                    <el-input-number size="small" v-model="selectedSlot.supported_event_count" :min="1" />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <base-button v-on:click="edit_resource_booking = true" type="primary" size="sm">
                <i class="tim-icons icon-simple-add"></i>
                Assign to a booking
              </base-button>
            </div>
          </div>

          <div v-if="selectedSlot.hasOwnProperty('assignment') && selectedSlot.assignment.length > 0">
            <div class="row">
              <div class="col">
                <b-table  v-if="selectedSlot.assignment.length > 0 && activeTabIndex === 0" :items="selectedSlot.assignment"
                          :fields="['event', 'time_assigned_to_event', 'time_freed_up', 'actions']">
                  <template v-slot:cell(event)="row">
                    <div style="margin-bottom: -8px" >
                      <span class="text-primary">{{ row.item.name }}</span>
                      <badge class="ml-2">#{{ row.item.booking_slot_id}}</badge>  &nbsp;
                    </div>
                    <small class="font-italic">
                      <i class="tim-icons icon-calendar-60"></i> {{getTime( row.item.start_time)}}-{{getTime( row.item.end_time)}}
                    </small>
                  </template>
                  <template v-slot:cell(time_assigned_to_event)="row">
                    <span v-if="row.item.time_assigned">{{getLocalDate(row.item.time_assigned)}}, {{getLocalTime(row.item.time_assigned)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(time_freed_up)="row">
                    <span v-if="row.item.time_freed_up">{{getLocalDate(row.item.time_freed_up)}}, {{getLocalTime(row.item.time_freed_up)}}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!--                          <a class="dropdown-item mt-2 mb-2" @click="deleteSurveyPrompt(row.item)" href="#">-->
                          <!--                            <i class="tim-icons icon-refresh-02 text-default mr-2"></i> Tag out-->
                          <!--                          </a>-->
                          <!--                          <hr>-->
                          <a class="dropdown-item mt-2 mb-2" @click="removeBookingAssignment(row.item, row.index, $event)" href="#">
                            <i class="tim-icons icon-simple-remove text-danger mr-2"></i> Remove booking assignment
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>

                </b-table>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center">
              <base-button type="secondary" @click="modals.create_resource_slot = false">Cancel</base-button>
              <base-button class="ml-3" :disabled="!hasPermissions('resource_slots_update') || loading" type="primary"
                           @click="updateResourceSlotSavePrompt()">Update resource slot</base-button>
            </div>
          </div>
        </div>


        <div v-if="edit_resource_booking">
          <div class="row">
            <div class="col">
              <base-input label="Add booking">
                <el-autocomplete style="width: 100%"
                                 v-model="selectedSlot.resource_assigned_value"
                                 :fetch-suggestions="querySearchBu"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelectBu"
                >
                  <template #default="{ item }">
                    <div style="margin-bottom: -12px" >
                      <span class="text-primary">{{ item.name }}</span>
                    </div>
                    <small class="font-italic">
                      <i class="tim-icons icon-calendar-60"></i> {{getTime(item.start_time)}}-{{getTime(item.end_time)}},  #{{item.id}}  &nbsp;
                    </small>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col">
              <base-button size="sm" type="secondary" @click="edit_resource_booking = false">Cancel</base-button>
              <base-button size="sm" class="ml-2" :disabled="!hasPermissions('resource_slots_update')" type="primary"
                           @click="updateAssignedBookingOnResourceSlot()">Update</base-button>
            </div>
          </div>

        </div>

        <div v-if="edit_resource_user">
          <div class="row mb-2">
            <div class="col">
              <el-switch active-color="#e14eca" v-on:change="toggleAnonymousResource()"
                         v-model="selectedSlot.anomymous_resource"  />
              <label> &nbsp; Anonymous resource</label>
            </div>
          </div>

          <div class="row" v-if="selectedSlot.anomymous_resource">
            <div class="col">
              <base-input label="Resource label" placeholder="Anonymous" v-model="selectedSlot.resource_assignee_value"></base-input>
            </div>
          </div>

          <div class="row" v-if="!selectedSlot.anomymous_resource">
            <div class="col">
              <base-input label="Add staff (search by name or email)">
                <el-autocomplete style="width: 100%"
                                 v-model="selectedSlot.resource_assignee_value"
                                 :fetch-suggestions="querySearchCu"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelectRu"
                >
                  <template #default="{ item }">
                    <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                    <small class="font-italic">
                      <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    </small>
                  </template>
                </el-autocomplete>
              </base-input>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col">
              <base-button size="sm" type="secondary" @click="edit_resource_user = false">Cancel</base-button>
              <base-button size="sm" class="ml-2" :disabled="!hasPermissions('resource_slots_update')" type="primary"
                           @click="updateAssignedStaffOnResourceSlot()">Update</base-button>
            </div>
          </div>
        </div>
      </card>
    </modal>

<!--    Staff-->
    <modal :show.sync="modals.add_staff"
           body-classes="p-0"
           modal-classes="modal-dialog-centered">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 class="card-title">Manage staff</h4>
        <div class="row">
          <div class="col">
            <base-input label="Add staff (search by name or email)">
              <el-autocomplete style="width: 100%"
                               v-model="company_user_value"
                               :fetch-suggestions="querySearchCu"
                               placeholder="Search"
                               :trigger-on-focus="false"
                               @select="handleSelectCu"
              >
                <template #default="{ item }">
                  <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                  <small class="font-italic">
                    <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                  </small>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-button size="sm" type="primary" v-on:click="addStaffToBookingSlot(selectedSlot.staff)">
              <i class="tim-icons icon-simple-add mr-1"></i> Add staff to booking</base-button>
          </div>
        </div>

        <div class="row" v-if="selectedSlot && selectedSlot.hasOwnProperty('staff') && selectedSlot.staff.length > 0">
          <div class="col">
            <b-table :items="selectedSlot.staff" :fields="['staff', 'actions']">
              <template v-slot:cell(staff)="row">
                <p class="text-white">{{row.item.first_name}}  {{row.item.last_name}} </p>
                <small class="text-white">
                  <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                </small>
              </template>
              <template v-slot:cell(actions)="row">
                <el-tooltip
                  content="Remove"
                  effect="light"
                  :open-delay="300"
                  placement="bottom"
                >
                  <i class="tim-icons icon-simple-remove text-danger btn btn-link btn-icon" v-on:click="removeStaff(row.index)"></i>
                </el-tooltip>

              </template>
            </b-table>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.add_staff = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="saveStaff()">Save</base-button>
          </div>
        </div>
      </card>
    </modal>

    <!--    Activity log-->
    <modal :show.sync="modals.view_booking_log" id="activityModal"
           body-classes="p-0" style="overflow-y: scroll"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <badge type="default" v-if="booking_activity_log.length > 0">#{{booking_activity_log[0].booking_slot_id}}</badge>
        <h4 class="card-title">Activity log</h4>
        <div class="mt-3" v-if="booking_activity_log.length <= 0">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No event have been recorded.</h4>
          </div>
        </div>
        <div v-if="booking_activity_log.length > 0">
          <b-table :items="booking_activity_log" :fields="['event', 'performed_by', 'timestamp']">
            <template v-slot:cell(event)="row">
              {{row.item.description}}
              <span v-if="row.item.new_value && row.item.old_value && isTime(row.item.new_value)"> from
                {{getTime(row.item.old_value)}} to {{getTime(row.item.new_value)}}
              </span>
              <span v-if="row.item.new_value && row.item.old_value && isDate(row.item.new_value)"> from
                {{getFormattedDate(row.item.old_value)}} to {{getFormattedDate(row.item.new_value)}}
              </span>
              <span v-if="row.item.new_value && row.item.old_value && !isTime(row.item.new_value) && !isDate(row.item.new_value)"> from
                {{row.item.old_value}} to {{row.item.new_value}}
              </span>
            </template>
            <template v-slot:cell(timestamp)="row">
              {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
            </template>
            <template v-slot:cell(performed_by)="row">
              <span v-if="!row.item.end_user_id && !row.item.company_user_id">System</span>
              <span v-if="row.item.end_user_id && !row.item.company_user_id">{{ row.item.end_user_name }}
                <el-tooltip content="View customer"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                    <base-button v-if="hasPermissions('customer_view')" v-on:click="viewCustomer(row.item.end_user_id)" icon class="btn btn-link" simple size="sm" type="primary">
                      <i class="tim-icons el-icon-top-right"></i>
                    </base-button>
                  </el-tooltip>
              </span>
              <span v-if="!row.item.end_user_id && row.item.company_user_id">{{ row.item.company_user_name }}
              </span>
            </template>

          </b-table>
        </div>


        <div class="row justify-content-center mt-3">
          <div class="col text-center">
            <base-button type="secondary" @click="modals.view_booking_log = false">Back to bookings</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { API_LOCATION } from '../../../api-config'
import {Auth} from "aws-amplify";
import { BFormInput, BTable, BPagination} from 'bootstrap-vue'
import {date, time, formattedDate, ampm, dayOfWeek} from '../../../plugins/dateFormatter'
import swal from "sweetalert2";
import BaseAlert from "@/components/BaseAlert";
import {Autocomplete, Dropdown, DropdownItem, DropdownMenu, Popover, Switch, TimePicker} from "element-ui";
import axios from "axios";
import currencies from "@/assets/currencies.json";
import _ from "lodash";
import Badge from "@/components/Badge";
import countries from "@/assets/countries.json";
import moment from 'moment';

export default {
  name: "Bookings",
  components: {
    Badge,
    BaseAlert,
    BFormInput,
    BTable,
    BPagination,
    [Autocomplete.name]: Autocomplete,
    [TimePicker.name]: TimePicker,
    [Popover.name]: Popover,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Switch.name]: Switch
  },
  data() {
    return {
      note: '',
      reassign_to_resource_slots: false,
      edit_resource_user: false,
      edit_resource_booking: false,
      disable_submit: false,
      time_slot: '',
      show_schedule_name: localStorage.getItem('showScheduleNameOnCalendar'),
      show_filters: false,
      events_for_bulk_update:[],
      selectedBulkUpdateGames: [],
      selectedBulkUpdateAction: {id: 1, label: 'Change slot status to "Available"', status: 'available'},
      bulk_to: new Date(),
      bulk_from: new Date(),
      bulk_time_range_on: false,
      bulk_to_time: new Date(),
      bulk_from_time: new Date(),
      selectedBulkUpdateActionStatus:  {id: 1, label: 'Available', status: 'available'},
      bulk_actions: [
        {id: 1, label: 'Change slot status'},
        {id: 2, label: 'Change minimum booking notice'},
        {id: 3, label: 'Change maximum booking in advance'},
        {id: 4, label: 'Clear resource assignments'},
        {id: 5, label: 'Add a booking note'},
        {id: 6, label: 'Change slot pricing'},
      ],
      slot_statuses: [
        {id: 1, label: 'Available', status: 'available'},
        {id: 2, label: 'Blocked', status: 'blocked'},
        {id: 3, label: 'Call to book', status: 'call_to_book'},
      ],
      max_periods: [{id: 0,label: 'day(s)'}, {id: 1, label: 'week(s)'}, {id: 2, label: 'month(s)'}, {id: 3,label: 'year(s)'}],
      min_periods: [{id: 0, label: 'minute(s)'}, {id: 1, label: 'hour(s)'}, {id: 2, label: 'day(s)'}, {id: 3, label: 'week(s)'}, {id: 4, label: 'month(s)'}],
      min_booking_period: {id: 1, label: 'hour(s)'},
      min_booking_value: 1,
      max_booking_period: {id: 2, label: 'month(s)'},
      max_booking_value: 6,
      slot_text: '',
      gift_card_code: '',
      selectedGiftCardCode: '',
      has_external_link: false,
      external_link: '',
      selectedStatus: '',
      expand_slot_settings: false,
      expand_price_settings: false,
      enable_custom_pricing: false,
      pricing_types: [],
      custom_pricing_categories: [],
      custom_pricing_type: 1,
      cumulative_category_pricing: 1,
      hide_pricing_rate: 0,
      rescheduling: false,
      valueTypes: [
        {
          name: 'Per booking',
          id: 0
        },
        {
          name: 'Per participant',
          id: 1
        }
      ],
      allow_deposit: 0,
      deposit_amount: 1,
      deposit_type: 0,
      require_deposit: 0,
      polling: null,
      selectedSlot: {assignment: [], label: 'Anonymous', supported_event_count: 1},
      show_resources: false,
      resource_slots: [],
      no_confirmation_email: false,
      waive_fee: false,
      waive_tax: false,
      block_type: 'blocked',
      block_note: '',
      duration: '',
      selectedTimeSlot: '',
      available_bookings: [],
      current: '',
      payment: {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      add_payment: false,
      price_override: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [5, 10, 25, 50],
      selectedBookingSlot: '',
      loading: false,
      fields: ["time", "booking", "booked_by", "number_of_players", "paid", "due", "notes", "staff", "actions"],
      mobileFields: ['booking'],
      bookings: [],
      games: '',
      selectedGame: '',
      ppp: 0,
      subtotal: 0,
      total: 0,
      total_tax: 0,
      total_fee: 0,
      taxes: [],
      fees: [],
      booking_date: '',
      booking_end_date: '',
      booking_time: '',
      number_of_players: 0,
      number_of_players_for_categories: [],
      total_group_size: 0,
      total_amount: 0,
      empty: false,
      modals: {
        new_booking: false,
        new_booking_slot: false,
        edit_booking: false,
        new_closed_period: false,
        add_notes: false,
        create_resource_slot: false,
        edit_resource_modal: false,
        bulk_update_slots: false,
        customer_alerts: false,
        add_staff: false,
        view_booking_log: false
      },
      filters: {
        status: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        }
      },
      lookup_value: '',
      errs: {
        number_of_players: '',
        gift_card_code: '',
        start_date: '',
        end_date: '',
        err_key: 0,
        game: '',
        bulk_from: '',
        bulk_to: '',
        bulk_to_time: '',
        bulk_from_time: '',
        bulk_note: '',
        time_slot: '',
        booking_date: '',
        booking_end_date: '',
        cardholder_first_name: '',
        cardholder_last_name: '',
        start_time: '',
        end_time: '',
        ccn: '',
        cvv: '',
        zip: '',
        expiration: '',
        first_name: '',
        last_name: '',
        email: '',
        payment_amount: '',
        partial_amount: ''
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      cardholder_first_name: '',
      cardholder_last_name: '',
      statuses: ['Available', 'Blocked', 'Call to book'],
      filter_statuses: [
        {id: 1, label: 'Available', status: 'available'},
        {id: 2, label: 'Call to book', status: 'call_to_book'},
        {id: 3, label: 'Blocked', status: 'blocked'},
        {id: 4, label: 'Booked', status: 'booked'},
        {id: 5, label: 'Checked-in', status: 'checked'},
        {id: 6, label: 'Completed', status: 'completed'},
        {id: 7, label: 'Passed', status: 'passed'}],
      selectedPaymentOption: {name: '', content: '', id: '', is_default: ''},
      paymentOptions: [],
      customer_credits: 0,
      available_credit: 0,
      applied_credit: 0,
      card: '',
      square: '',
      squareAppId: '',
      squareLocationId: '',
      elements: '',
      stripe: '',
      start_time: '',
      end_time: '',
      payment_settings: '',
      country: '',
      countries: countries.countries,
      payment_amount: parseFloat(0).toFixed(2),
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      picker_time_format: 'hh:mm A',
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      date_format_cal: '',
      day_of_week: '',
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      j: 0,
      activeTabIndex: 0,
      categories: [],
      send_payment_request: false,
      pay_partial_amount: false,
      partial_amount: 0,
      pa_key: 0,
      promos: [],
      promo_code: '',
      selectedPromoCode: '',
      pc_key: 0,
      pricing_type_id: 1,
      pricing_categories: [],
      total_discount: 0,
      customer_alerts: [],
      company_user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      company_user_value: '',
      staff_settings: 0,
      show_staff_settings: 0,
      selectedCategory: '',
      block_statuses: [
        {id: 1, name: 'Blocked', label: 'blocked'},
        {id: 2, name: 'Call to book', label: 'call_to_book'}
      ],
      booking_activity_log: []
    }
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return mobileRegex.test(userAgent);
    }
  },
  methods: {
    async bulkUpdateResources(){
      if(!this.validBulkUpdateForm()){
        return
      }
      let start = moment(this.bulk_from, 'YYYY-MM-DD').format('YYYY-MM-DD')
      let end = moment(this.bulk_to, 'YYYY-MM-DD').format('YYYY-MM-DD')
      let start_time = moment(this.bulk_from_time, 'HH:mm').format('HH:mm')
      let end_time = moment(this.bulk_to_time, 'HH:mm').format('HH:mm')
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId +
        '/staff/clear?start=' + start + '&end=' + end + '&reassign=' + Number(this.reassign_to_resource_slots)
        + '&has_time_range=' + Number(this.bulk_time_range_on)
        + '&time_from=' + start_time
        + '&time_to=' + end_time

      axios.delete(url, config)
        .then(response => {
          this.modals.bulk_update_slots = false
          swal('Success!', 'Resource assignment has been updated', 'success')
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    removeBookingAssignment(item, index, event){
      event.preventDefault()
      this.selectedSlot.assignment.splice(index, 1)
    },
    toggleAnonymousResource(){
      if(this.selectedSlot.anomymous_resource){
        this.selectedSlot.resource_assignee_value = this.selectedSlot.label
      } else {
        this.selectedSlot.resource_assignee_value = ''
      }
    },
    editAssignedStaffOnResourceSlot(){
      this.edit_resource_user = true
      if(this.selectedSlot.anomymous_resource){
        this.selectedSlot.resource_assignee_value = this.selectedSlot.label
      } else {
        this.selectedSlot.resource_assignee_value = ''
      }
    },
    updateAssignedStaffOnResourceSlot(){
      this.edit_resource_user = false
      if(this.selectedSlot.anomymous_resource){
        this.selectedSlot.label = this.selectedSlot.resource_assignee_value
        this.selectedSlot.company_user_id = null
      } else {
        this.selectedSlot.first_name = this.selectedSlot.resource_assignee_object.first_name
        this.selectedSlot.last_name = this.selectedSlot.resource_assignee_object.last_name
        this.selectedSlot.company_user_id = this.selectedSlot.resource_assignee_object.id
      }
    },
    updateAssignedBookingOnResourceSlot(){
      let found = _.find(this.selectedSlot.assignment, {booking_slot_id: this.selectedSlot.resource_assigned_object.id})
      if(!found){
        this.selectedSlot.assignment.push(this.selectedSlot.resource_assigned_object)
      }
      this.edit_resource_booking = false
    },
    prepopulateCustomPricingForGame(){
      let game = this.getGameObj(this.selectedGame)[0]
      this.custom_pricing_type = {id: game.pricing_type_id}
      this.cumulative_category_pricing = game.cumulative_category_pricing
      this.allow_deposit = game.allow_deposit
      this.require_deposit = game.require_deposit
      this.deposit_amount = game.deposit_amount
      this.deposit_type = {id: game.deposit_type}
      this.hide_pricing_rate = game.hide_pricing_rate
      this.custom_pricing_categories = this.getCategories(JSON.parse(game.game_pricing))
    },
    isDate(date){
      const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      return regex.test(date);
    },
    isTime(time){
      const regex = /^([01]\d|2[0-3]):[0-5]\d$/;
      return regex.test(time);
    },
    async viewActivityLog(item, event){
      event.preventDefault()
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + item.id + '/log'

      this.loading = true
      axios.get(url, config)
        .then(response => {
          const root = document.getElementById('activityModal');
          window.requestAnimationFrame(() => {
            root.scrollTo({
              top: 600,
              left: 0,
              behavior: 'smooth',
            });
          });
          this.modals.view_booking_log = true
          this.booking_activity_log = response.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    getAvailableStaffCount(item) {
      let start = moment(`${item.booking_date} ${item.start_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
      let end = moment(`${item.end_date} ${item.end_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");

      let total_count = 0
      let total_assigned_count = 0
      let total_assigned_to_slot = 0
      for (let n in this.resource_slots) {
        let resource_start = moment(`${this.resource_slots[n].start_date} ${this.resource_slots[n].start_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
        let resource_end = moment(`${this.resource_slots[n].end_date} ${this.resource_slots[n].end_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");

        if (
          moment(start).isSameOrAfter(resource_start) &&
          moment(end).isSameOrBefore(resource_end)
        ) {
          total_count = total_count + this.resource_slots[n].supported_event_count;
        }

        let assignments = JSON.parse(this.resource_slots[n].assignment)
        for(let m in assignments){
          let assignment_start = moment(assignments[m].time_assigned, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
          let assignment_end = moment(assignments[m].time_freed_up, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");

          // Check for overlap
          if (
            moment(start).isBefore(assignment_end) && // Booking starts before assignment ends
            moment(end).isAfter(assignment_start)    // Booking ends after assignment starts
          ) {
            total_assigned_count++;
          }

          if(assignments[m].booking_slot_id === item.id){
            total_assigned_to_slot++
          }
        }
      }
      return { assigned: total_assigned_to_slot,
        available: (total_count - total_assigned_count)  >= 0 ? total_count - total_assigned_count : 0,
        total: total_count }
    },
    payOnArrival(){
      this.pay_partial_amount = false
      this.add_payment = false
    },
    overridePrice(is_custom, item){

      if(is_custom){
        for(let n in this.custom_pricing_categories){
          if(item.cumulative_category_pricing){
            //Price
            if(!this.custom_pricing_categories[n].hasOwnProperty('original_price')){
              for(let m in this.custom_pricing_categories[n].category_pricing){
                if(this.number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                  && this.number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players){
                  this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                  this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[m].price
                }
              }
              let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
              if(last_index && this.number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
                this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
                this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
              if(smallest_price_category && this.number_of_players !== 0 &&
                this.number_of_players < smallest_price_category.min_players){
                this.custom_pricing_categories[n].original_price = smallest_price_category.price
                this.custom_pricing_categories[n].price = smallest_price_category.price
              }
            }
            //Original price
            if(this.custom_pricing_categories[n].hasOwnProperty('original_price')){
              for(let m in this.custom_pricing_categories[n].category_pricing){
                if(this.number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                  && this.number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players){
                  this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                }
              }

              let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
              if(last_index && this.number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
                this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
              if(smallest_price_category && this.number_of_players !== 0 &&
                this.number_of_players < smallest_price_category.min_players){
                this.custom_pricing_categories[n].original_price = smallest_price_category.price
              }
            }
          }
          else {

            for(let m in this.custom_pricing_categories[n].category_pricing){
              if(this.custom_pricing_categories[n].number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                && this.custom_pricing_categories[n].number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players) {
                this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[m].price
              }
            }
            let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
            if(last_index && this.custom_pricing_categories[n].number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
              this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
              this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
            if(smallest_price_category && this.custom_pricing_categories[n].number_of_players !== 0 &&
              this.custom_pricing_categories[n].number_of_players < smallest_price_category.min_players){
              this.custom_pricing_categories[n].original_price = smallest_price_category.price
              this.custom_pricing_categories[n].price = smallest_price_category.price
            }
          }
        }
      } else {
        for(let n in this.pricing_categories){
          if(item.cumulative_category_pricing){
            if(!this.pricing_categories[n].hasOwnProperty('original_price')){
              // this.pricing_categories[n].original_price = this.pricing_categories[n].price
              for(let m in this.pricing_categories[n].category_pricing){
                if(this.number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                  && this.number_of_players <= this.pricing_categories[n].category_pricing[m].max_players){
                  this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                  this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[m].price
                }
              }
              let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
              if(last_index && this.number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
                this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
                this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
              if(smallest_price_category && this.number_of_players !== 0 &&
                this.number_of_players < smallest_price_category.min_players){
                this.pricing_categories[n].original_price = smallest_price_category.price
                this.pricing_categories[n].price = smallest_price_category.price
              }
            }
            //Original price
            if(this.pricing_categories[n].hasOwnProperty('original_price')){
              for(let m in this.pricing_categories[n].category_pricing){
                if(this.number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                  && this.number_of_players <= this.pricing_categories[n].category_pricing[m].max_players){
                  this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                }
              }

              let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
              if(last_index && this.number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
                this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
              if(smallest_price_category && this.number_of_players !== 0 &&
                this.number_of_players < smallest_price_category.min_players){
                this.pricing_categories[n].original_price = smallest_price_category.price
              }
            }
          } else {
            for(let m in this.pricing_categories[n].category_pricing){
              if(this.pricing_categories[n].number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                && this.pricing_categories[n].number_of_players <= this.pricing_categories[n].category_pricing[m].max_players) {
                this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[m].price
              }
            }
            let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
            if(last_index && this.pricing_categories[n].number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
              this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
              this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
            if(smallest_price_category && this.pricing_categories[n].number_of_players !== 0 &&
              this.pricing_categories[n].number_of_players < smallest_price_category.min_players){
              this.pricing_categories[n].original_price = smallest_price_category.price
              this.pricing_categories[n].price = smallest_price_category.price
            }
          }
        }
      }
      this.price_override = true
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async saveStaff(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/staff/bookings'

      let data = {
        staff: this.selectedSlot.staff,
        booking_slot_id: this.selectedSlot.id
      }
      this.loading = true
      axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'Staff allocation has been updated', 'success')
          this.modals.add_staff = false
          this.company_user_value = ''
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async toggleStaffVisibility(status){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/staff/settings'

      let data = {
        add_staff_to_booking_slots: status
      }
      axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Calendar settings have been updated', 'success')
          this.getSettings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    removeStaff(index){
      this.selectedSlot.staff.splice(index, 1)
    },
    addStaffToBookingSlot(staff_array){
      if(this.company_user.email === '' || staff_array.find(item => item.email === this.company_user.email)){
        return
      }

      staff_array.push({id: this.company_user.id,
        first_name: this.company_user.first_name,
        last_name: this.company_user.last_name,
        email: this.company_user.email})
      this.company_user_value = ''
    },
    handleSelectCu(item){
      this.company_user_value = item.email
      this.company_user.first_name = item.first_name
      this.company_user.last_name = item.last_name
      this.company_user.email = item.email
      this.company_user.id = item.id
    },
    handleSelectRu(item){
      this.selectedSlot.resource_assignee_value = item.email
      this.selectedSlot.resource_assignee_object = { first_name: item.first_name, last_name: item.last_name, id: item.id }
    },
    handleSelectBu(item){
      this.selectedSlot.resource_assigned_value = item.id.toString()
      this.selectedSlot.resource_assigned_object = { name: item.name,
        booking_date: item.booking_date, start_time: item.start_time, end_date: item.end_date, end_time: item.end_time,
        status: item.status, booking_slot_id: item.id }
    },
    async querySearchBu(queryString, cb) {
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings/assignable?booking=' + encodeURIComponent(queryString) +
        `&start_date=` + moment(this.selectedSlot.start_date).format('YYYY-MM-DD') +
        `&start_time=` +  moment(this.selectedSlot.start_time).format('HH:mm') +
        `&end_date=` +  moment(this.selectedSlot.end_date).format('YYYY-MM-DD') +
        `&end_time=` +  moment(this.selectedSlot.end_time).format('HH:mm')
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.bookings){
            suggestions.push({
              value: JSON.stringify(response.data.bookings[n]),
              id: response.data.bookings[n].id,
              name: response.data.bookings[n].name,
              booking_date: response.data.bookings[n].booking_date,
              end_date: response.data.bookings[n].end_date,
              end_time: response.data.bookings[n].end_time,
              start_time: response.data.bookings[n].start_time,
              status: response.data.bookings[n].status
            })
          }
          const results = suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async querySearchCu(queryString, cb) {
      //make API call to /customers with queryString as filter
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/company-users?count=10000000&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.company_users){
            suggestions.push({
              value: JSON.stringify(response.data.company_users[n]),
              email: response.data.company_users[n].email,
              id: response.data.company_users[n].id,
              first_name: response.data.company_users[n].first_name,
              last_name: response.data.company_users[n].last_name
            })
          }
          const results = suggestions;
          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    manageStaff(item){
      this.selectedSlot = JSON.parse(JSON.stringify(item))
      this.modals.add_staff = true
    },
    addNewCategory(pricing){
      pricing.push({
        category: 'Person',
        minimum_participants: 0,
        default_participants: 0,
        pricing: [{min_players: 1, max_players: 2, price: 0}]
      })
      this.c++
    },
    removePriceRange(item, i1, i2){
      item.splice(i2, 1);
      // this.errs.pricing_categories[i1].per_person_pricing.splice(i2, 1);
    },
    addPriceRange(category, index){
      if(category.pricing.length > 0){
        category.pricing.push({
          min_players: category.pricing[category.pricing.length - 1].max_players + 1,
          max_players: category.pricing[category.pricing.length - 1].max_players + 2,
          price: 0})
      } else {
        category.pricing.push({
          min_players: 1,
          max_players: 2,
          price: 0})
      }
    },
    removeCategory(item, index){
      item.splice(index, 1);
      this.c++
    },
    getCategories(items){

      const groupedArray = [];

      items.forEach(item => {
        const { category_id, category, minimum_participants, default_participants } = item;

        // Find if the category_id already exists in groupedArray
        let group = groupedArray.find(g => g.category_id === category_id);

        if (!group) {
          // If it doesn't exist, create a new group
          group = {
            category_id: category_id,
            category: category,
            minimum_participants: minimum_participants,
            default_participants: default_participants,
            pricing: []
          };
          groupedArray.push(group);
        }

        group.pricing.push(item);
      });
      return groupedArray;
    },
    showCustomPricingSettings(custom_pricing){
      this.expand_price_settings = !this.expand_price_settings

      this.enable_custom_pricing = custom_pricing ? this.selectedBookingSlot.is_custom_pricing_enabled : 0
      this.custom_pricing_type = custom_pricing ? _.find(this.pricing_types, {id: this.selectedBookingSlot.pricing_type_id}) : {id : 1}
      this.deposit_type = custom_pricing ? _.find(this.valueTypes, {id: this.selectedBookingSlot.deposit_type}) : {id : 0}

      this.allow_deposit = false
      this.cumulative_category_pricing = true
      this.hide_pricing_rate = false
      this.deposit_amount = 1
      this.require_deposit = false

      if(this.expand_price_settings){
        if(!custom_pricing || custom_pricing.length <= 0){
          this.custom_pricing_categories = []
          this.custom_pricing_categories.push({
            category: 'Person',
            minimum_participants: 0,
            default_participants: 0,
            pricing: [{min_players: 1, max_players: 2, price: 0}]
          })
        } else {
          this.custom_pricing_type = _.find(this.pricing_types, {id: this.selectedBookingSlot.pricing_type_id})
          this.custom_pricing_categories = this.getCategories(custom_pricing)
          this.allow_deposit = this.selectedBookingSlot.allow_deposit
          this.cumulative_category_pricing = this.selectedBookingSlot.cumulative_category_pricing
          this.hide_pricing_rate = this.selectedBookingSlot.hide_pricing_rate
          this.deposit_amount = this.selectedBookingSlot.deposit_amount
          this.require_deposit = this.selectedBookingSlot.require_deposit
        }
      }
      this.j++
    },
    decrementDate(){
      this.current.setDate(this.current.getDate() - 1)
      this.j++
      this.getBookings()
    },
    incrementDate(){
      this.current.setDate(this.current.getDate() + 1)
      this.j++
      this.getBookings()
    },
    setTodayDate(){
      this.setToday()
      this.j++
      this.getBookings()
    },
    updateRescheduleDate(date){
      this.time_slot = ''
      this.price_override = false
      this.getAvailableBookings(date)
    },
    viewCustomerAlerts(item){
      this.customer_alerts = JSON.parse(item.customer_alerts)
      this.modals.customer_alerts = true
    },
    toggleScheduleName(enable){
      if(enable){
        this.show_schedule_name = 1
        localStorage.setItem('showScheduleNameOnCalendar', '1')
      } else {
        this.show_schedule_name = 0
        localStorage.removeItem('showScheduleNameOnCalendar')
      }
    },
    bulkUpdatePrompt(event){
      event.preventDefault()
      this.reassign_to_resource_slots = false
      this.bulk_from = this.current
      this.bulk_to = this.current
      this.selectedBulkUpdateGames = this.events_for_bulk_update
      this.selectedBulkUpdateAction = {id: 1, label: 'Change slot status'}
      this.min_booking_period = {id: 1, label: 'hour(s)'}
      this.min_booking_value = 1
      this.max_booking_period = {id: 2, label: 'month(s)'}
      this.max_booking_value = 6
      this.slot_text = ''
      this.has_external_link = false
      this.external_link = ''
      this.note = ''
      this.enable_custom_pricing = true
      this.custom_pricing_type = {id: 1}
      this.modals.bulk_update_slots = true
    },
    validBulkUpdateForm(){
      let errors = 0
      if(!this.bulk_to){
        this.errs.bulk_to = 'Invalid end date'
        errors++
      } else {
        this.errs.bulk_to = ''
      }

      if(this.bulk_from && this.bulk_to && this.bulk_from > this.bulk_to){
        this.errs.bulk_from = 'Start date cannot be greater than end date'
        errors++
      } else if(!this.bulk_from){
        this.errs.bulk_from = 'Invalid start date'
        errors++
      } else {
        this.errs.bulk_from = ''
      }

      if(this.bulk_time_range_on && !this.bulk_to_time){
        this.errs.bulk_to_time = 'Invalid end time'
        errors++
      } else {
        this.errs.bulk_to_time = ''
      }

      if(this.bulk_time_range_on && this.bulk_from_time && this.bulk_to_time && this.bulk_from_time > this.bulk_to_time){
        this.errs.bulk_from_time = 'Start time cannot be greater than end time'
        errors++
      } else if(!this.bulk_from_time){
        this.errs.bulk_from_time = 'Invalid start time'
        errors++
      } else {
        this.errs.bulk_from_time = ''
      }

      if(this.selectedBulkUpdateAction.id === 5 && this.note === ''){
        this.errs.bulk_note = 'Include a note'
        errors++
      } else {
        this.errs.bulk_note = ''
      }

      return errors > 0 ? false : true
    },
    async bulkUpdate(){
      if(!this.validBulkUpdateForm()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/bookings'

      let data = {
        action: this.selectedBulkUpdateAction.id,
        min_booking_value: this.min_booking_value,
        min_booking_period: this.min_booking_period.id,
        slot_text: this.slot_text,
        external_link: this.external_link,
        has_external_link: this.has_external_link,
        max_booking_period: this.max_booking_period.id,
        max_booking_value: this.max_booking_value,
        status: this.selectedBulkUpdateActionStatus.status,
        to: moment(this.bulk_to, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        from: moment(this.bulk_from, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        time_to: moment(this.bulk_to_time, 'HH:mm').format('HH:mm'),
        time_from: moment(this.bulk_from_time, 'HH:mm').format('HH:mm'),
        has_time_range: this.bulk_time_range_on,
        events: this.selectedBulkUpdateGames,
        note: this.note,
        pricing_type_id: this.custom_pricing_type.id,
        allow_deposit: this.allow_deposit,
        cumulative_category_pricing : this.cumulative_category_pricing,
        hide_pricing_rate: this.hide_pricing_rate,
        deposit_amount: this.deposit_amount,
        require_deposit: this.require_deposit,
        deposit_type: this.deposit_type.id,
        custom_pricing: this.custom_pricing_categories
      }
      axios.put(url, data, config)
        .then(response => {
          this.modals.bulk_update_slots = false
          swal('Success!', 'Booking slots have been updated', 'success')
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async giftCardSearch(queryString, cb){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/gifts/autocomplete?q=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.gift_cards){
            if(response.data.gift_cards[n].code != null){
              suggestions.push({
                value: JSON.stringify(response.data.gift_cards[n]),
                id: response.data.gift_cards[n].id,
                code: response.data.gift_cards[n].code,
                customer_id: response.data.gift_cards[n].customer_id,
                date_purchased: response.data.gift_cards[n].date_purchased,
                email: response.data.gift_cards[n].email,
                first_name: response.data.gift_cards[n].first_name,
                last_name: response.data.gift_cards[n].last_name,
                remaining: response.data.gift_cards[n].remaining,
                total: response.data.gift_cards[n].total
              })
            }

          }
          const results = suggestions

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handleGiftCardSelect(item){
      this.gift_card_code = this.getGiftCardCode(item.code)
      this.selectedGiftCardCode = item
    },
    async payWithPaystack(type) {
      let amount = (parseFloat(this.getTotal()) * 100).toFixed(0).toString()
      if(type !== 'new'){
        amount = (parseFloat(this.payment_amount) * 100).toFixed(0).toString()
      }
      let handler = PaystackPop.setup({
        key: this.payment_settings.api_name, // Replace with your public key
        email: this.user.email,
        amount: amount,
        ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        callback: (response) => {
          if(type === 'new'){
            this.handlePaystackPayment(response)
          } else {
            this.handlePaystackAdditionalPayment(response)
          }
        }
      });

      handler.openIframe();
    },
    async createTimeSlotPrompt(){
      if(!this.isValidSlot(this.booking_date, this.booking_end_date)){
        return
      }
      let validator = await this.validateResources(this.booking_date, this.booking_end_date,
        this.start_time, this.end_time)
      if(validator !== 'Success') {
        this.modals.new_booking_slot = false
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response) => {
          if (response.value == true) {
            this.createTimeSlot()
          } else {
            this.modals.new_booking_slot = true
          }
        })
      } else {
        await this.createTimeSlot()
      }

    },
    async updateSlotWithTransactionPrompt(){
      this.disable_submit = true
      this.loading = true
      let booking_dt = this.time_slot.booking_date ?
        moment(this.time_slot.booking_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD') :
        moment(this.selectedBookingSlot.booking_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD')
      let end_dt = this.time_slot.end_date ?
        moment(this.time_slot.end_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD') :
        moment(this.selectedBookingSlot.end_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD')
      let start_tm = this.time_slot.start_time ? this.time_slot.start_time : this.start_time
      let end_tm = this.time_slot.end_time ? this.time_slot.end_time : this.end_time
      let validator = await this.validateResources(booking_dt, end_dt, start_tm, end_tm)
      if(validator !== 'Success') {
        this.modals.edit_booking = false
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response) => {
          if (response.value == true) {
            this.updateSlotWithTransaction()
          } else {
            this.modals.edit_booking = true
            this.disable_submit = false
            this.loading = true
          }
        })
      } else {
        await this.updateSlotWithTransaction()
      }
    },
    async updateSlotWithTransaction(){
      this.disable_submit = true
      let data = {
        transaction: this.selectedBookingSlot.transaction_id,
        booking_date: moment(this.selectedBookingSlot.booking_date).format('YYYY-MM-DD'),
        end_date: this.selectedBookingSlot.end_date ? moment(this.selectedBookingSlot.end_date).format('YYYY-MM-DD')
          : moment(this.selectedBookingSlot.booking_date).format('YYYY-MM-DD')
      }
      if(this.activeTabIndex === 2){
        //Customer
        if(!this.isValidUserForm()){
          this.disable_submit = false
          this.loading = false
          return
        }
        data.user = this.user
      } else if(this.activeTabIndex === 0){
        //Group
        if(!this.isValidRescheduleSlot()){
          this.loading = false
          this.disable_submit = false
          return
        }
        data.tickets = this.time_slot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        data.group_size = this.number_of_players
        data.price = this.subtotal
        data.game_id = _.find(this.games, { name: this.selectedGame }).id
        data.booking_copy = this.time_slot.id
        data.booking_date = this.time_slot.booking_date ?
          moment(this.time_slot.booking_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD') :
          moment(this.selectedBookingSlot.booking_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD')
        data.end_date = this.time_slot.end_date ?
          moment(this.time_slot.end_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD') :
          moment(this.selectedBookingSlot.end_date, 'YYYY-MM-DD').format( 'YYYY-MM-DD')
        data.start_time = this.time_slot.start_time
        data.end_time =  this.time_slot.end_time
      } else if(this.activeTabIndex === 1){
        //Edit Time slot
        if(!this.isValidSlot(this.selectedBookingSlot.booking_date, this.selectedBookingSlot.end_date)){
          this.disable_submit = false
          this.loading = false
          return
        }
        delete data['transaction'];
        data.start_time = moment(this.start_time).format('HH:mm:ss')
        data.end_time = moment(this.end_time).format('HH:mm:ss')
        data.game_id = this.getGameObj(this.selectedGame)[0].id

      } else if(this.activeTabIndex === 3){
        //Add payment
        let payment_type = 'pos'
        if(this.selectedPaymentOption.name === 'Cash'){
          payment_type = 'cash'
        } else if(this.selectedPaymentOption.name === 'Card'){
          payment_type = 'card'
        } else if(this.selectedPaymentOption.name === 'Customer credit'){
          payment_type = 'customer_credit'
        } else if(this.selectedPaymentOption.name === 'Gift card'){
          payment_type = 'gift_card'
        } else if(!this.selectedPaymentOption.is_default){
          payment_type = this.selectedPaymentOption.name
        } else if(this.selectedPaymentOption.name === 'Yappy'){
          payment_type = 'Yappy'
        }

        if(!this.isValidPaymentForm()){
          this.disable_submit = false
          this.loading = false
          return
        }
        data.user = this.user
        data.payment = {
          total: this.payment_amount,
          payment_type: payment_type,
          cardholder_first_name: this.cardholder_first_name,
          cardholder_last_name: this.cardholder_last_name,
          custom_payment_type: !this.selectedPaymentOption.is_default
        }

        if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 1) {
          if(!this.isValidPaymentForm()){
            this.disable_submit = false
            this.loading = false
            return
          }
          data.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
          data.payment.expirationDate = this.payment.expirationDate
          data.payment.cardCode = this.payment.cardCode
          data.payment.zip = this.payment.zip
        }
        else if( this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 2) {
          this.loading = true
          try {
            const token = await this.tokenize(this.card);
            const verificationToken = await this.verifyBuyer(this.square, token);
            const token2 = await this.tokenize(this.card);
            data.payment.locationId = this.squareLocationId
            data.payment.sourceId = token
            data.payment.sourceId2 = token2
            data.payment.verificationToken = verificationToken
          } catch (error) {
            //Square
            this.loading = false
          }
        }
        else if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 3){
          const {token, error} = await this.stripe.createToken(this.card);
          if(error){
            this.disable_submit = false
          }
          data.payment.token = token.id
        }
        else if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 4){
          let paystack = this.payWithPaystack()
          this.disable_submit = false
          return
        }
        else if(this.selectedPaymentOption.name === 'Gift card') {
          data.gift_card = this.selectedGiftCardCode
        }
      }

      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + this.selectedBookingSlot.id
      this.loading = true
      this.axios.put(url, data, config)
        .then(response => {
          this.modals.edit_booking = false
          swal('Success', 'Booking has been updated' , 'success')
          this.getBookings()
          this.loading = false
          this.disable_submit = false
        })
        .catch(err => {
          this.loading = false
          this.disable_submit = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }  else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }  else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updateBookingSlotPrompt(){
      if(!this.isValidSlot(this.selectedBookingSlot.booking_date, this.selectedBookingSlot.end_date)){
        return
      }
      let validator = await this.validateResources(this.selectedBookingSlot.booking_date, this.selectedBookingSlot.end_date,
        this.start_time, this.end_time)
      if(validator !== 'Success') {
        this.modals.edit_booking = false
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response) => {
          if (response.value == true) {
            this.updateBookingSlot()
          } else {
            this.modals.edit_booking = true
          }
        })
      } else {
        await this.updateBookingSlot()
      }

    },
    async deleteResourceSlotPrompt(item, event, source){
      event.preventDefault()
      let validator = await this.validateResources(item.start_date, item.end_date, item.start_time, item.end_time)
      if(validator !== 'Success'){
        this.modals.edit_resource_modal = false
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response)=> {
          if(response.value == true){
            this.deleteResourceSlot(item)
          } else {
            if(source === 'modal'){
              this.modals.edit_resource_modal = true
            }
          }
        })
      } else {
        swal({
          title: 'Are you sure?',
          text: "The resource slot will be permanently deleted.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response)=> {
          if(response.value == true){
            this.deleteResourceSlot(item)
          }
        })
      }
    },
    async validateResources(start_dt, end_dt, start_t, end_t) {
      let start_date = moment(start_dt, "YYYY-MM-DD").format("YYYY-MM-DD");
      let start_time = moment(start_t, "HH:mm").format("HH:mm");
      let start = moment(`${start_date} ${start_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
      let end_date = moment(end_dt, "YYYY-MM-DD").format("YYYY-MM-DD");
      let end_time = moment(end_t, "HH:mm").format("HH:mm");
      let end = moment(`${end_date} ${end_time}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId +
        '/staff/validate?start=' + start + '&end=' + end

      return new Promise ( function(resolve) {
        axios.get(url, config)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      })
    },
    async createResourceSlotPrompt(){
      if(!this.validateResourceFields()){
        return
      }
      this.modals.create_resource_slot = false
      let validator = await this.validateResources(this.selectedSlot.start_date, this.selectedSlot.end_date, this.selectedSlot.start_time, this.selectedSlot.end_time)
      if(validator !== 'Success'){
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response)=> {
          if(response.value == true){
            this.createResourceSlot()
          } else {
            this.modals.create_resource_slot = true
          }
        })
      } else {
        await this.createResourceSlot()
      }
    },
    async updateResourceSlotSavePrompt(){
      if(!this.validateResourceFields()){
        return
      }
      this.modals.edit_resource_modal = false
      let validator = await this.validateResources(this.selectedSlot.start_date, this.selectedSlot.end_date, this.selectedSlot.start_time, this.selectedSlot.end_time)
      if(validator !== 'Success'){
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response)=> {
          if(response.value == true){
            this.updateResourceSlot()
          } else {
            this.modals.edit_resource_modal = true
          }
        })
      } else {
        await this.updateResourceSlot()
      }
    },
    updateResourceSlotPrompt(item, event){
      event.preventDefault()
      this.selectedSlot = {
        count: item.count,
        start_date: moment(item.start_date, 'YYYY-MM-DD'),
        end_date: moment(item.end_date, 'YYYY-MM-DD'),
        start_time: moment(item.start_time, 'HH:mm:ss'),
        end_time: moment(item.end_time, 'HH:mm:ss'),
        id: item.id,
        schedule_id: item.schedule_id,
        supported_event_count: item.supported_event_count,
        label: item.label,
        company_user_id: item.company_user_id,
        first_name: item.first_name,
        last_name: item.last_name,
        anomymous_resource: item.anomymous_resource,
        assignment: JSON.parse(item.assignment),
        resource_assigned_value: item.resource_assigned_value,
        resource_assignee_value: item.resource_assignee_value
      }

      this.selectedSlot.enable_autoblock = item.enable_autoblock
      this.selectedSlot.block_status = _.find(this.block_statuses, {label: item.block_status})
      this.modals.edit_resource_modal = true
      this.edit_resource_user = false
      this.edit_resource_booking = false
    },
    async updateResourceSlot(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/staff/' + this.selectedSlot.id

      let data = {
        start_time:  moment(this.selectedSlot.start_time).format('HH:mm:ss'),
        end_time:  moment(this.selectedSlot.end_time).format('HH:mm:ss'),
        count: this.selectedSlot.count,
        start_date: moment(this.selectedSlot.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.selectedSlot.end_date).format('YYYY-MM-DD'),
        schedule_id: this.selectedSlot.schedule_id,
        block_status: this.selectedSlot.block_status,
        enable_autoblock: this.selectedSlot.enable_autoblock,
        today: this.current,
        label: this.selectedSlot.label,
        supported_event_count: this.selectedSlot.supported_event_count,
        company_user_id: this.selectedSlot.company_user_id,
        assignment: this.selectedSlot.assignment
      }

      this.axios.put(url, data, config)
        .then(response => {
          this.modals.edit_resource_modal = false
          swal("Success", "Resource slot has been updated", 'success')
          this.loading = false
          this.getBookings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }  else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createResourceSlot(){
      if(!this.validateResourceFields()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/staff'

      let data = {
        start_time: moment(this.selectedSlot.start_time).format('HH:mm:ss'),
        end_time:  moment(this.selectedSlot.end_time).format('HH:mm:ss'),
        count: this.selectedSlot.count,
        start_date: moment(this.selectedSlot.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.selectedSlot.end_date).format('YYYY-MM-DD'),
        enable_autoblock: this.selectedSlot.enable_autoblock,
        block_status: this.selectedSlot.block_status,
        label: this.selectedSlot.label,
        supported_event_count: this.selectedSlot.supported_event_count,
        company_user_id: this.selectedSlot.company_user_id,
        assignment: this.selectedSlot.assignment
      }

      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.create_resource_slot = false
          swal('Success', 'Resource slot has been created', 'success')
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status === 401){
            this.$router.push('/forbidden')
          } else if(err.response.status === 500){
            swal('Error', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    validateResourceFields(){
      let error = 0

      if(this.selectedSlot.start_date === null){
        this.errs.start_date = 'Invalid start date'
        error++
      } else {
        this.errs.start_date = ''
      }

      let start = moment(this.selectedSlot.start_date, 'YYYY-MM-DD')
      let end = moment(this.selectedSlot.end_date, 'YYYY-MM-DD')
      const isDateSequenceBeforeOrSame = start.isBefore(end, 'day') || start.isSame(end, 'day')

      if(this.selectedSlot.end_date === null){
        this.errs.end_date = "End date cannot be empty"
        error++
      } else if(!isDateSequenceBeforeOrSame){
        this.errs.end_date = "End date cannot be before start date"
        error++
      } else {
        this.errs.end_date = ''
      }

      if(this.selectedSlot.start_time === null) {
        this.errs.start_time = "Start time cannot be empty"
        error++
      } else {
        this.errs.start_time = ''
      }

      let start_time = moment(this.selectedSlot.start_time, 'HH:mm:ss')
      let end_time = moment(this.selectedSlot.end_time, 'HH:mm:ss')

      start.set({
        hour: start_time.hour(),
        minute: start_time.minute(),
        second: start_time.second(),
      });

      end = end.set({
        hour: end_time.hour(),
        minute: end_time.minute(),
        second: end_time.second(),
      });

      if(this.selectedSlot.end_time === null) {
        this.errs.end_time = "End time cannot be empty"
        error++
      } else if(!start.isBefore(end)) {
        this.errs.end_time = "End time cannot be before start time"
        error++
      } else {
        this.errs.end_time = ''
      }
      return error > 0 ? false : true
    },
    newResourceSlot(event){
      event.preventDefault()
      let start = new Date()
      let end = new Date()
      start.setHours(10)
      start.setMinutes(0)
      end.setHours(20)
      end.setMinutes(0)

      this.selectedSlot = {
        count: 1,
        start_date: new Date(),
        end_date: new Date(),
        start_time: start,
        end_time: end,
        enable_autoblock: false,
        block_status: {id: 1, name: "Blocked", label: 'blocked'},
        supported_event_count: 1,
        label: 'Anonymous',
        assignment: [],
        resource_assigned_value: '',
        resource_assignee_value: ''
      }
      this.modals.create_resource_slot = true
    },
    async deleteResourceSlot(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/staff/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.modals.edit_resource_modal = false
          swal('Success!', 'Resource slot has been deleted', 'success')
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    viewCustomer(id){
      this.$router.push({ path: '/customers/details', query: { id:  id }})
    },
    getRate(item, slot){
      if(!slot || !this.selectedGame){return}
      let selectedGame = this.getGameObj(this.selectedGame)[0]
      let pricing = slot.hasOwnProperty('price') ? JSON.parse(slot.price) : JSON.parse(selectedGame.game_pricing)
      if(slot.is_custom_pricing_enabled){
        pricing = slot.custom_pricing
      }
      if(item.archived){
        pricing = item.pricing
      }
      if(selectedGame.cumulative_category_pricing){
        for(let n in pricing){
          if(pricing[n].category_id === item.category_id
            && this.number_of_players >= pricing[n].min_players
            && this.number_of_players <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return this.pricing_type_id === 1 ? item.price
                : this.getAmount(item.price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            } else {
              return this.pricing_type_id === 1 ? pricing[n].price
                : this.getAmount(pricing[n].price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            }

          }
        }

        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(last_index && this.number_of_players > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return this.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          } else {
            return this.pricing_type_id === 1 ? pricing[last_index].price
              : this.getAmount(pricing[last_index].price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          }

        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(smallest_price_category && this.number_of_players !== 0 &&
          this.number_of_players < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return this.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          } else {
            return this.pricing_type_id === 1 ? smallest_price_category.price
              : this.getAmount(smallest_price_category.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          }

        }
      } else {
        for(let n in pricing){
          if(pricing[n].category_id === item.category_id
            && item.number_of_players >= pricing[n].min_players
            && item.number_of_players <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return this.pricing_type_id === 1 ? item.price
                : this.getAmount(item.price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            } else {
              return this.pricing_type_id === 1 ? pricing[n].price
                : this.getAmount(pricing[n].price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            }
          }
        }

        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(last_index && item.number_of_players > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return this.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          } else {
            return this.pricing_type_id === 1 ? pricing[last_index].price
              : this.getAmount(pricing[last_index].price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          }
        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(smallest_price_category && item.number_of_players !== 0 &&
          item.number_of_players < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return this.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          } else {
            return this.pricing_type_id === 1 ? smallest_price_category.price
              : this.getAmount(smallest_price_category.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          }
        }
      }

      return this.pricing_type_id === 1 ? 0 : ''
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    removePromoCode(item){
      _.remove(this.promos, {
        id: item.id
      });
      this.pc_key++
    },
    applyPromoCode(){
      if(this.selectedPromoCode.hasOwnProperty('id')){
        let dupe = 0
        for(let n in this.promos){
          if(this.selectedPromoCode.id === this.promos[n].id){
            dupe++
          }
          if(this.promos[n].is_combineable === 0 || this.selectedPromoCode.is_combineable === 0){
            swal('Error', 'You cannot apply multiple non-combineable promo codes', 'error')
            return
          }
        }
        if(dupe === 0){
          if(this.selectedPromoCode.hasOwnProperty('code')){
            //Handle gift card
            if(!(isNaN(this.selectedPromoCode.code))){
              let c = this.selectedPromoCode.code.match(/.{1,4}/g);
              this.selectedPromoCode.name = 'Gift card (' + c.join(' ') + ')'
            } else {
              this.selectedPromoCode.name = 'Gift card (' + this.selectedPromoCode.code + ')'
            }
            if(this.subtotal >= this.selectedPromoCode.remaining){
              this.selectedPromoCode.value_amount = this.selectedPromoCode.remaining
            } else {
              this.selectedPromoCode.value_amount = this.subtotal
            }
          }

          if(this.selectedPromoCode.value_amount > this.total && this.selectedPromoCode.redemption_type === 1){
            //get the value of taxes that are applied after discount
            let apply_after_discount_taxes = 0
            let waived_taxes = 0
            let waived_fees = 0
            for(let n in this.taxes){
              if(this.taxes[n].apply_after_discount === 1 && !this.waive_tax){
                apply_after_discount_taxes += parseFloat(this.getTax(this.taxes[n]))
              } else if (this.waive_tax){
                waived_taxes += parseFloat(this.getTax(this.taxes[n]))
              }
            }
            if(this.waive_fee){
              for(let n in this.fees){
                waived_fees += parseFloat(this.getFee(this.fees[n]))
              }
            }
            this.selectedPromoCode.value_amount = this.total - apply_after_discount_taxes - waived_taxes - waived_fees
          }
          this.promos.push(this.selectedPromoCode)
        }
      }
      this.promo_code = ''
    },
    setPartialAmount(){
      this.partial_amount = this.total.toFixed(2)
      if(this.waive_tax){
        // this.partial_amount = this.partial_amount -
      }
    },
    async markNoteAsImportant(note_id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/bookings/' + this.selectedTimeSlot.id + '/notes/' + note_id
      let data = {
        is_important: 1
      }
      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_notes = false
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }  else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    getHeadlineNote(notes){
      let important_note = ''
      for (let n in JSON.parse(notes)){
        if(JSON.parse(notes)[n].is_important){
          important_note = JSON.parse(notes)[n].note
        }
      }
      return important_note
    },
    getGiftCardCode(code){
      if(!code){
        return
      }
      if(isNaN(code)){
        return code
      }
      let c = code.match(/.{1,4}/g);
      return c.join(' ')
    },
    getFee(f){
      let total_fee = 0
      //value(total)
      if(f.value_type === 0){
        total_fee = f.amount
      }
      //value (per person)
      if(f.value_type === 1){
        total_fee = (f.amount * this.number_of_players)
      }
      //percentage
      if(f.value_type === 2){
        total_fee = this.getPercentage(f.amount, this.subtotal)
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      return total_fee
    },
    getCompoundTax(tax){
      let total_tax = 0
      for(let t in this.taxes){
        //exclusive of price
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0){
            total_tax += this.taxes[t].amount
          }
          //value (per person)
          if(this.taxes[t].value_type === 1){
            total_tax += (this.taxes[t].amount * this.number_of_players)
          }
          //percentage
          if(this.taxes[t].value_type === 2){
            //not compound
            if(!this.taxes[t].is_compound){
              total_tax += this.getPercentage(this.taxes[t].amount, this.subtotal)
            }
          }
        }
        if(tax.id === this.taxes[t].id && this.taxes[t].is_compound){
          total_tax = parseFloat(total_tax).toFixed(2)
          return total_tax
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      return total_tax
    },
    getTax(t){
      if(parseFloat(this.subtotal) == 0){
        return 0
      }
      let total_tax = 0

      if(t.pricing_type === 0){
        //value (total)
        if(t.value_type === 0){
          total_tax = t.amount
        }
        //value (per person)
        if(t.value_type === 1){
          total_tax = (t.amount * this.number_of_players)
        }
        //percentage
        if(t.value_type === 2){
          let amount = t.amount
          //not compound
          if(!t.is_compound){
            if(t.apply_after_discount){

              let after_discount = this.subtotal - this.getDiscounts()
              if(after_discount <= 0){
                amount = 0
              }
              total_tax = this.getPercentage(amount, after_discount)
            } else {
              total_tax = this.getPercentage(amount, this.subtotal)
            }
          } else {
            let compound_tax = this.getCompoundTax(t)
            let compound_subtotal = parseFloat(this.subtotal) + parseFloat(compound_tax)
            if(t.apply_after_discount){
              let after_discount = compound_subtotal - this.getDiscounts()
              if(after_discount < 0){
                amount = 0
              }
              total_tax = this.getPercentage(amount, after_discount)
            } else {
              total_tax = this.getPercentage(amount, compound_subtotal)
            }
          }
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      return total_tax
    },
    recalculateDiscount(slot, archived){
      this.promos = []
      this.getSubtotal(slot, archived)
    },
    getTotal(){
      this.total = parseFloat(this.subtotal) + parseFloat(this.getTaxes()) + parseFloat(this.getFees()) - parseFloat(this.getDiscounts())
      return this.total.toFixed(2)
    },
    getDiscount(promo){
      let discount = 0
      if(promo.hasOwnProperty('code')){
        discount = promo.value_amount
      }

      else if(promo.value_type === 0){
        if(promo.redemption_type === 1){
          discount = promo.value_amount
          if(discount > this.subtotal){
            discount = this.subtotal
          }
        } else {
          discount = this.getPercentage(promo.value_amount, this.subtotal)
        }
      }
      // Per participant
      else if (promo.value_type === 2){
        if(promo.redemption_type === 1){
          discount = promo.value_amount * this.number_of_players
          if(discount > this.subtotal){
            discount = this.subtotal
          }
        } else {
          discount = this.getPercentage(promo.value_amount, this.subtotal) * this.number_of_players
        }
      }

      if(discount > this.total){
        discount = parseFloat(this.subtotal) + parseFloat(this.total_fee) + parseFloat(this.total_tax)
      }
      return discount
    },
    getDiscounts(){
      let total_discounts = 0
      for(let n in this.promos){
        if(this.promos[n].hasOwnProperty('code')){
          total_discounts += this.promos[n].value_amount
        }
        //Per transaction
        else if(this.promos[n].value_type === 0){
          if(this.promos[n].redemption_type === 1){
            total_discounts += this.promos[n].value_amount
            if(total_discounts > this.subtotal){
              total_discounts = this.subtotal
            }
          } else {
            total_discounts += this.getPercentage(this.promos[n].value_amount, this.subtotal)
          }
        }
        // Per participant
        else if (this.promos[n].value_type === 2){
          if(this.promos[n].redemption_type === 1){
            if(this.promos[n].value_amount * this.number_of_players > this.subtotal){
              total_discounts += this.subtotal
            } else {
              total_discounts += this.promos[n].value_amount * this.number_of_players
            }
          } else {
            total_discounts += this.getPercentage(this.promos[n].value_amount, this.subtotal) * this.number_of_players
          }
        }
      }
      this.total_discounts = total_discounts
      return total_discounts
    },
    getFees(){
      let total_fee = 0
      for(let n in this.fees){
        //value
        if(this.fees[n].value_type === 0){
          total_fee += this.fees[n].amount
        }
        //value (per person)
        if(this.fees[n].value_type === 1){
          total_fee += (this.fees[n].amount * this.number_of_players)
        }
        //percentage
        if(this.fees[n].value_type === 2){
          //not compound
          total_fee += this.getPercentage(this.fees[n].amount, this.subtotal)
        }
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      this.total_fee = total_fee
      return total_fee
    },
    getTaxes(){
      if(parseFloat(this.subtotal) == 0){
        this.total_tax = 0
        return 0
      }
      let total_tax = 0
      for(let t in this.taxes){
        //exclusive of price
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0){
            total_tax += this.taxes[t].amount
          }
          //value (per person)
          if(this.taxes[t].value_type === 1){
            total_tax += (this.taxes[t].amount * this.number_of_players)
          }
          //percentage
          if(this.taxes[t].value_type === 2){
            let amount = this.taxes[t].amount
            //not compound
            if(!this.taxes[t].is_compound){
              if(this.taxes[t].apply_after_discount) {
                let after_discount = this.subtotal - this.getDiscounts()
                if(after_discount < 0){
                  amount = 0
                }
                total_tax += this.getPercentage(amount, after_discount)
              } else {
                total_tax += this.getPercentage(amount, this.subtotal)
              }
            } else {
              let compound_subtotal = parseFloat(this.subtotal) + parseFloat(total_tax)
              if(this.taxes[t].apply_after_discount){
                let after_discount = compound_subtotal - this.getDiscounts()
                if(after_discount < 0){
                  amount = 0
                }
                total_tax += this.getPercentage(amount, after_discount)
              } else {
                total_tax += this.getPercentage(amount, compound_subtotal)
              }
            }
          }
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      this.total_tax = total_tax
      return total_tax
    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    getCart(){
      if(!JSON.parse(localStorage.getItem('cart'))){
        return 0
      }
      return parseInt(JSON.parse(localStorage.getItem('cart')).length)
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
      if(this.activeTabIndex === 3){
        if(this.payment_settings.payment_gateway_id === 2){
          this.initializeSquare('add_payment')
        }
        if(this.payment_settings.payment_gateway_id === 3){
          this.initializeStripeAddPayment()
        }
      } else if(this.activeTabIndex === 0){
        this.getAvailableBookings(this.selectedBookingSlot.booking_date)
      }
    },
    showPaymentForm(){
      this.add_payment = true
      this.send_payment_request = false
      if(this.payment_settings.payment_gateway_id === 2){
        this.initializeSquare()
      }
    },
    getCurrencySymbol(){
      if(this.currency_format === 1){
        let c = _.find(this.currencies, { code: this.currency });
        return c.symbol
      }
    },
    async initializeCardAddPayment(payments){
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          // borderColor: 'inherit',
          // backgroundColor: this.bss.card_color,
          // color: this.bss.text_color_card,
          // 'font-family': this.bss.font_family_card,
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
          // 'font-family': this.bss.font_family_card
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container-2');
      return card;
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          // borderColor: 'inherit',
          // backgroundColor: this.bss.card_color,
          // color: this.bss.text_color_card,
          // 'font-family': this.bss.font_family_card,
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
          // 'font-family': this.bss.font_family_card
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    async verifyBuyer(payments, token) {
      const verificationDetails = {
        amount: this.subtotal.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.user.first_name,
          givenName: this.user.last_name,
        },
        currencyCode: this.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async createSquarePayment(type){
      this.loading = true
      let token = ''
      let token2 = ''
      try {
        token = await this.tokenize(this.card);
        token2 = await this.tokenize(this.card);
        // If the line above executes without error, token is successfully obtained.
      } catch (error) {
        // If an error occurs during the await operation, it will be caught here.
        console.error('Error:', error);
        this.loading = false
        return
      }
      let verificationToken = ''
      try {
        verificationToken = await this.verifyBuyer(
          this.square,
          token
        );
        // If the line above executes without error, token is successfully obtained.
      } catch (error) {
        // If an error occurs during the await operation, it will be caught here.
        console.error('Error:', error);
        this.loading = false
        return
      }

      let selectedGame = this.getGameObj(this.selectedGame)
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = ''
      let data = {}
      this.checkWaivedTaxFee()
      if(type === 'new'){
        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/square'
        data = {
          amount: this.subtotal,
          subtotal: this.subtotal,
          tax: this.total_tax,
          fee: this.total_fee,
          total: this.total,
          user: this.user,
          discount: this.getDiscounts(),
          no_confirmation_email: this.no_confirmation_email,
          send_payment_request: this.send_payment_request,
          promo_codes: this.promos,
          bookings: [{
            id: this.selectedTimeSlot.id,
            group_size: this.number_of_players,
            game_id: selectedGame[0].id,
            game_name: selectedGame[0].name,
            booking_date: this.selectedTimeSlot.booking_date,
            end_date: this.selectedTimeSlot.end_date,
            start_time: this.selectedTimeSlot.start_time,
            end_time: this.selectedTimeSlot.end_time,
            total: this.subtotal,
            tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
          }],
          locationId: this.squareLocationId,
          sourceId: token,
          sourceId2: token2,
          verificationToken: verificationToken
        }
      } else {
        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.selectedGame.transaction_id + '/payments/square'
        data = {
          amount: this.payment_amount,
          user: this.user,
          locationId: this.squareLocationId,
          sourceId: token,
          verificationToken: verificationToken
        }
      }

      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone

      let response = await this.paymentRequest(type, url, data, config)
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }

        throw new Error(errorMessage);
      }
    },
    saveOverride(is_custom_pricing, time_slot){
      if(is_custom_pricing){
        for(let n in this.custom_pricing_categories){
          if(this.custom_pricing_categories[n].hasOwnProperty('original_price') &&
            this.custom_pricing_categories[n].original_price  === this.custom_pricing_categories[n].price){
            delete this.custom_pricing_categories[n].original_price
          }
        }
      } else {
        for(let n in this.pricing_categories){
          if(this.pricing_categories[n].hasOwnProperty('original_price') &&
            this.pricing_categories[n].original_price  === this.pricing_categories[n].price){
            delete this.pricing_categories[n].original_price
          }
        }
      }
      this.getSubtotal(time_slot)
      this.price_override = false
    },
    setOriginalPrice(category){
      category.price = category.original_price
    },
    getAvailableCredit(){
      let available = 0
      for(let n in this.customer_credits){
        available = available + parseFloat(this.customer_credits[n].remaining)
      }
      this.available_credit = available.toFixed(2)
      if(parseFloat(this.available_credit) < parseFloat(this.subtotal)){
        this.applied_credit = this.available_credit
      } else {
        this.applied_credit = this.subtotal
      }
    },
    async getCredits(id){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers/' + id + '/customer-credit'
      axios.get(url, config)
        .then(response => {
          this.customer_credits = response.data.customer_credits
          this.getAvailableCredit()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    clearCustomer(){
      this.lookup_value = ''
      this.user = {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      }
    },
    async promoSearch(queryString, cb){
      let booking_date = this.booking_date
      let purchase_date = new Date()
      purchase_date = purchase_date.getFullYear() +  "-" +  ('0' + (purchase_date.getMonth() + 1)).slice(-2) + "-" + ('0' + purchase_date.getDate()).slice(-2);

      let day_of_week = ''
      if(this.booking_date instanceof Date){
        day_of_week = this.booking_date.getDay()
        booking_date = this.booking_date.getFullYear() +  "-" +  ('0' + (this.booking_date.getMonth() + 1)).slice(-2) + "-" + ('0' + this.booking_date.getDate()).slice(-2);
      }
      else {
        let dt = new Date()
        dt.setFullYear(this.booking_date.split('-')[0])
        dt.setMonth((this.booking_date.split('-')[1] - 1), this.booking_date.split('-')[2])
        booking_date =  dt.getFullYear() +  "-" +  ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
        day_of_week = dt.getDay()
      }

      // if(this.current instanceof Date) {
      //   purchase_date = this.current.getFullYear() +  "-" +  ('0' + (this.current.getMonth() + 1)).slice(-2) + "-" + ('0' + this.current.getDate()).slice(-2);
      // }


      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/promos?autocomplete=' + encodeURIComponent(queryString) + `&purchase_date=` + purchase_date +
        `&event_date=` + booking_date + `&event_time=` + this.selectedTimeSlot.start_time
        + `&day_of_week=` + day_of_week
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          if(response.data.hasOwnProperty('gift_card')){
            this.selectedPromoCode = response.data.gift_card
          }
          for(let n in response.data.promos){
            if(response.data.promos[n].name != null){

              //Check excluded dates
              let date_allowed = true
              if(response.data.promos[n].event_exlusions){
                let dates = response.data.promos[n].event_exlusions.split(',')
                for(let k in dates){
                  if(booking_date === dates[k]){
                    date_allowed = false
                  }
                }
              }

              if(response.data.promos[n].games){
                let allowed = false
                for(let m in JSON.parse(response.data.promos[n].games)){
                  if(JSON.parse(response.data.promos[n].games)[m].name === this.selectedGame){
                    allowed = true
                  }
                }
                if(allowed && date_allowed){
                  suggestions.push({
                    value: JSON.stringify(response.data.promos[n]),
                    id: response.data.promos[n].id,
                    name: response.data.promos[n].name,
                    description: response.data.promos[n].description,
                    value_amount: response.data.promos[n].value_amount,
                    redemption_type: response.data.promos[n].redemption_type,
                    value_type: response.data.promos[n].value_type,
                    internal_only: response.data.promos[n].internal_only
                  })
                }

              }
            }

          }
          const results = queryString
            ? suggestions.filter(this.createFilter(queryString))
            : suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async querySearch(queryString, cb) {
      //make API call to /customers with queryString as filter
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.customers){
            if(response.data.customers[n].email != null
              && response.data.customers[n].first_name != null
              && response.data.customers[n].first_name != ''){
              suggestions.push({
                value: JSON.stringify(response.data.customers[n]),
                email: response.data.customers[n].email,
                id: response.data.customers[n].id,
                end_user_id: response.data.customers[n].end_user_id,
                first_name: response.data.customers[n].first_name,
                last_name: response.data.customers[n].last_name,
                phone: response.data.customers[n].phone
              })
            }

          }
          const results = suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createFilter(queryString) {
      return (suggestion) => {
        return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    handlePromoSelect(item){
      this.promo_code = item.name
      this.selectedPromoCode = item
    },
    handleSelect(item){
      this.lookup_value = item.email
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.email = item.email
      this.user.phone = item.phone
      this.getCredits(item.end_user_id)
    },
    goToCart(){
      this.$router.push('/bookings/transactions/new')
    },
    addToCart(item){
      //prevent duplication
      let cart = JSON.parse(localStorage.getItem('cart')) || []
      let cart_string = localStorage.getItem('cart') || ''
      if(!cart_string.includes(item.id)){
        item.group_size = item.max_players_count
        item.tickets = []
        let game = this.getGameObj(item.game_name)[0]
        let gameObj = { game_name: game.game_name,
          cumulative_category_pricing: game.cumulative_category_pricing,
          hide_pricing_rate: game.hide_pricing_rate,
          game_pricing: game.game_pricing,
          is_custom_pricing_enabled: game.is_custom_pricing_enabled,
          custom_pricing: game.custom_pricing,
          pricing_type_id: game.pricing_type_id}
        cart.push(_.merge(item, gameObj))
        localStorage.setItem('cart', JSON.stringify(cart))
        localStorage.setItem('taxes', JSON.stringify(this.taxes))
        localStorage.setItem('fees', JSON.stringify(this.fees))
      }
      localStorage.setItem('payment_settings', JSON.stringify(this.payment_settings))
      this.$router.push('/bookings/transactions/new')
    },
    async goToCalendar(){
      //update profile settings
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let url = API_LOCATION + 'users/site-settings'

      let data = {
        booking_page_mode: 1
      }

      axios.put(url, data, config)
        .then(response => {
          let profile = JSON.parse(localStorage.getItem('profile'))
          profile.booking_page_mode = 1
          localStorage.setItem('profile', JSON.stringify(profile))
          this.$router.push('/bookings/calendar')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    resetField(field){
      field.value = ''
      field.actual = ''
      this.getBookings()
    },
    resetAll(){
      this.filters = {
        status: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        }
      }
      this.getBookings()
    },
    async deleteNote(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + this.selectedTimeSlot.id + '/notes/' + id

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.modals.add_notes = false
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    async addNewComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/bookings/' + this.selectedTimeSlot.id + '/notes'
      let data = {
        note: this.selectedTimeSlot.note
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_notes = false
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addNote(item){
      this.selectedTimeSlot = item
      this.modals.add_notes = true
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    clearFields(){
      this.price_override = false
      this.add_payment = false
      this.errs ={
        booking_date: '',
        date: '',
        start_time: '',
        end_time: '',
        cardholder_first_name: '',
        cardholder_last_name: '',
        ccn: '',
        cvv: '',
        zip: '',
        expiration: '',
        first_name: '',
        last_name: '',
        email: '',
        payment_amount: ''
      }
      this.selectedGame = ''
      this.selectedTimeSlot = ''
      this.booking_date = ''
      this.start_time = ''
      this.end_time = ''
      this.booking_time = ''
      this.user = {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      }
      this.promos = []
      this.payment =  {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      }
      this.min_booking_period = {id: 1, label: 'hour(s)'}
      this.min_booking_value = 1
      this.max_booking_period = {id: 2, label: 'month(s)'}
      this.max_booking_value = 6
      this.slot_text = ''
      this.has_external_link = false
      this.external_link = ''
      this.block_note = ''
      this.expand_slot_settings = false
    },
    newBookingSlot(event){
      event.preventDefault()
      this.clearFields()
      this.selectedStatus = 'Available'
      this.expand_price_settings = false
      this.enable_custom_pricing = false
      this.pricing_type_id = {id: 1}
      this.allow_deposit = false
      this.cumulative_category_pricing = true
      this.hide_pricing_rate = false
      this.deposit_amount = 1
      this.require_deposit = false
      this.deposit_type = {id: 0}
      this.custom_pricing_categories = []
      this.selectedGame = this.games[0].name

      this.booking_date = this.current
      this.booking_end_date = this.current
      this.start_time = this.current
      this.end_time = moment(this.current).add(1, 'hours')
      this.modals.new_booking_slot=true
    },
    async getPaymentMethods(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/payment-methods'

      axios.get(url, config)
        .then((response) => {
          this.paymentOptions = response.data.payment_methods
          this.selectedPaymentOption = this.paymentOptions.length > 0 ?
            this.paymentOptions[0] : {name: '', content: '', id: '', is_default: ''}
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async newBooking(event, item){
      event.preventDefault()
      this.clearFields()
      this.booking_date = this.current

      if(item){
        this.selectedGame = item.game_name
        this.selectedTimeSlot = item

        let pricing = []
        if(item.is_custom_pricing_enabled){
          this.pricing_type_id = item.pricing_type_id
          pricing = item.custom_pricing
          this.number_of_players = 0
          this.custom_pricing_categories = []
          let categories = this.getCategories(pricing)
          for(let n in pricing){
            if(!(_.find(this.custom_pricing_categories, { category_id: pricing[n].category_id }))){
              this.custom_pricing_categories.push({
                category_id: pricing[n].category_id,
                category: pricing[n].category.toLowerCase(),
                number_of_players: 1,
                price: pricing[n].price,
                category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
              })
              this.number_of_players++
            }
          }

        } else {
          this.pricing_type_id = this.getGameObj(item.game_name)[0].pricing_type_id
          pricing = JSON.parse(this.getGameObj(item.game_name)[0].game_pricing)
          this.number_of_players = 0
          this.pricing_categories = []
          let categories = this.getCategories(pricing)
          for(let n in pricing){
            if(!(_.find(this.pricing_categories, { category_id: pricing[n].category_id }))){
              this.pricing_categories.push({
                category_id: pricing[n].category_id,
                category: pricing[n].category.toLowerCase(),
                number_of_players: 1,
                price: pricing[n].price,
                category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
              })
              this.number_of_players++
            }
          }
        }
        this.getSubtotal(item)
        if(item){this.updateRescheduleDate(this.booking_date)}
      }

      this.modals.new_booking=true
      if(this.payment_settings.payment_gateway_id === 3){
        this.initializeStripe()
      }
    },
    getNewSubtotalForBookingSlot(new_time_slot){
      let pricing = []
      if(new_time_slot.is_custom_pricing_enabled){
        pricing = new_time_slot.custom_pricing
        this.number_of_players = 0
        this.custom_pricing_categories = []

        for(let n in pricing){

          if(!(_.find(this.custom_pricing_categories, { category_id: pricing[n].category_id }))){
            this.custom_pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: 0,
              price: pricing[n].price
            })
            this.number_of_players++
          }
        }
      } else {
        pricing = new_time_slot.pricing
        this.number_of_players = 0
        this.pricing_categories = []
        for(let n in pricing){

          if(!(_.find(this.pricing_categories, { category_id: pricing[n].category_id }))){
            this.pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players:  0,
              price: pricing[n].price
            })
            this.number_of_players++
          }
        }
      }

      this.getSubtotal(new_time_slot)
    },
    getGroupSize(item){
      let transactions = this.getJSON(item, 'order_number')
      let size = 0
      for(let n in transactions){
        if(transactions[n].group_size != null){
          size = size + transactions[n].group_size
        }
      }
      return size
    },
    getJSON(item, order_by){
      if(item == undefined){
        return
      }
      let it = _.orderBy(JSON.parse(item), order_by)
      if(it[0].id == null){
        return []
      }
      return it
    },
    setToday(){
      let current = new Date()
      this.current = current
    },
    getInitialSubtotal(){
      if(this.selectedBookingSlot.hasOwnProperty('booking_date')){
        this.selectedBookingSlot.booking_date = null
      }
      this.time_slot = ''
      this.subtotal = 0
      this.available_bookings = []
      this.number_of_players = 0
      this.pricing_categories = []
      this.custom_pricing_categories = []
      this.price_override = false
      this.getAvailableBookings(this.booking_date)
    },
    getTotalGroupSize(original_size){
      let additional_players = 0
      for(let n in this.pricing_categories){
        additional_players = additional_players + this.pricing_categories[n].number_of_players
      }
      return original_size + additional_players
    },
    getSubtotal(game, archived){
      let selectedGame = ''
      if(game.hasOwnProperty('game_name')){
        selectedGame = this.getGameObj(game.game_name)
      }else{
        // selectedGame = this.getGame(game)
        selectedGame = this.games.filter(obj => {
          return obj.id === game.game_id
        })
      }
      let pricing_type_id = game.hasOwnProperty('slot_pricing_type_id') ? game.slot_pricing_type_id : game.pricing_type_id
      let cumulative_category_pricing =  game.hasOwnProperty('slot_cumulative_category_pricing')? game.slot_cumulative_category_pricing : game.cumulative_category_pricing
      let pricing =  game.hasOwnProperty('slot_cumulative_category_pricing') && game.is_custom_pricing_enabled
                    ? game.custom_pricing : JSON.parse(selectedGame[0].game_pricing)

      let pricing_categories = this.pricing_categories

      if(game.is_custom_pricing_enabled){
        pricing = game.custom_pricing
        pricing_categories = this.custom_pricing_categories
      }
      this.subtotal = 0
      this.number_of_players = 0
      if(cumulative_category_pricing){
        if(pricing_type_id === 1) {
          for (let m in pricing_categories) {
            this.number_of_players += pricing_categories[m].number_of_players
          }
          for (let m in pricing_categories) {
            if(archived){
              pricing = pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === pricing_categories[m].category_id
                && this.number_of_players >= pricing[n].min_players
                && this.number_of_players <= pricing[n].max_players) {
                if(pricing_categories[m].hasOwnProperty('original_price')){
                  this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
                } else {
                  this.subtotal += pricing[n].price * pricing_categories[m].number_of_players
                }
                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: pricing_categories[m].category_id})
            if(this.number_of_players > pricing[last_index].max_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
              } else {
                this.subtotal += pricing[last_index].price * pricing_categories[m].number_of_players
              }

            }
            let smallest_price_category = _.find(pricing, {category_id : pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
              } else {
                this.subtotal += smallest_price_category.price * pricing_categories[m].number_of_players
              }

            }
          }
        }
        else if(pricing_type_id === 2){
          for (let m in pricing_categories) {
            this.number_of_players += pricing_categories[m].number_of_players
          }
          for(let m in pricing_categories){
            if(archived){
              pricing = pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === pricing_categories[m].category_id
                && this.number_of_players >= pricing[n].min_players
                && this.number_of_players <= pricing[n].max_players) {
                if(pricing_categories[m].hasOwnProperty('original_price')){
                  this.subtotal += pricing_categories[m].price
                } else {
                  this.subtotal += pricing[n].price
                }
                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: pricing_categories[m].category_id})
            if(this.number_of_players > pricing[last_index].max_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price
              } else {
                this.subtotal += pricing[last_index].price
              }
            }

            let smallest_price_category = _.find(pricing, {category_id : pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price
              } else {
                this.subtotal += smallest_price_category.price
              }
            }
          }
        }
      } else {
        if(pricing_type_id === 1){
          for(let m in pricing_categories){

            this.number_of_players += pricing_categories[m].number_of_players
            if(archived){
              pricing = pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === pricing_categories[m].category_id
                && pricing_categories[m].number_of_players >= pricing[n].min_players
                && pricing_categories[m].number_of_players <= pricing[n].max_players) {
                if(pricing_categories[m].hasOwnProperty('original_price')){
                  this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
                } else {
                  this.subtotal += pricing[n].price * pricing_categories[m].number_of_players
                }
                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: pricing_categories[m].category_id})
            if(pricing_categories[m].number_of_players > pricing[last_index].max_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
              } else {
                this.subtotal += pricing[last_index].price * pricing_categories[m].number_of_players
              }
            }

            let smallest_price_category = _.find(pricing, {category_id : pricing_categories[m].category_id})
            if(pricing_categories[m].number_of_players !== 0 &&
              pricing_categories[m].number_of_players < smallest_price_category.min_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price * pricing_categories[m].number_of_players
              } else {
                this.subtotal += smallest_price_category.price * pricing_categories[m].number_of_players
              }

            }
          }
        }
        else if(pricing_type_id === 2){
          for(let m in pricing_categories){
            this.number_of_players += pricing_categories[m].number_of_players
            if(archived){
              pricing = pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === pricing_categories[m].category_id
                && pricing_categories[m].number_of_players >= pricing[n].min_players
                && pricing_categories[m].number_of_players <= pricing[n].max_players) {
                if(pricing_categories[m].hasOwnProperty('original_price')){
                  this.subtotal += pricing_categories[m].price
                } else {
                  this.subtotal += pricing[n].price
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: pricing_categories[m].category_id})
            if(pricing_categories[m].number_of_players > pricing[last_index].max_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price
              } else {
                this.subtotal += pricing[last_index].price
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : pricing_categories[m].category_id})
            if(pricing_categories[m].number_of_players !== 0 &&
              pricing_categories[m].number_of_players < smallest_price_category.min_players){
              if(pricing_categories[m].hasOwnProperty('original_price')){
                this.subtotal += pricing_categories[m].price
              } else {
                this.subtotal += smallest_price_category.price
              }
            }
          }
        }
      }
    },

    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    viewBookingDetails(item){
      let documentClasses = document.body.classList;
      documentClasses.remove('modal-open');
      this.$router.push({ path: '/purchases/transactions/details', query: { id: item.transaction_id }})
    },
    async getGames () {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.events_for_bulk_update = response.data
          this.games = response.data
          const statusArray = this.$route.query.status ? this.$route.query.status.split(',') : ''
          const statuses = this.filter_statuses.filter(slot => statusArray.includes(slot.status));
          const gamesArray = this.$route.query.game ? this.$route.query.game.split(',') : ''
          const games = this.games.filter(slot => gamesArray.includes(slot.id.toString()));
          this.filters = {
            game: {
              value: games,
              actual: games.filter(item => item.id !== undefined) // Filter out objects without an `id`
                .map(item => item.name)
                .join(', ')
            },
            status: {
              value: statuses,
              actual:  statuses.filter(item => item.id !== undefined) // Filter out objects without an `id`
                .map(item => item.label)
                .join(', ')
            }
          }

          this.current = this.$route.query.date ? moment(this.$route.query.date).toDate() : moment(this.current).toDate()
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getAvailableBookings(date){
      if(date instanceof Date){
        date = date.getFullYear() + '-' +  ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/available?t=' + date + '&game_id=' + this.getGameObj(this.selectedGame)[0].id
      this.axios.get(url, config)
        .then(response => {
          this.available_bookings = response.data.bookings
          for(let n in this.available_bookings){
            this.available_bookings[n].pricing = JSON.parse(this.available_bookings[n].pricing)
            this.available_bookings[n].custom_pricing = JSON.parse(this.available_bookings[n].custom_pricing)
          }
          if(this.selectedBookingSlot && this.selectedBookingSlot.booking_date_label === date){
            this.available_bookings.push({id: this.selectedBookingSlot.id,
              game_name: this.selectedBookingSlot.game_name,
              start_time: this.selectedBookingSlot.start_time,
              end_time: this.selectedBookingSlot.end_time,
              is_custom_pricing_enabled: this.selectedBookingSlot.is_custom_pricing_enabled,
              cumulative_category_pricing: this.selectedBookingSlot.cumulative_category_pricing,
              hide_pricing_rate: this.selectedBookingSlot.hide_pricing_rate,
              slot_hide_pricing_rate: this.selectedBookingSlot.hide_pricing_rate,
              slot_cumulative_category_pricing: this.selectedBookingSlot.cumulative_category_pricing,
              pricing_type_id:  this.selectedBookingSlot.pricing_type_id,
              slot_pricing_type_id:  this.selectedBookingSlot.pricing_type_id,
              custom_pricing: this.selectedBookingSlot.custom_pricing,
              booking_date: this.selectedBookingSlot.booking_date,
              end_date: this.selectedBookingSlot.end_date
            })
            this.available_bookings = _.orderBy(this.available_bookings, 'start_time')
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getBokingDate(bookingId){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings/' + bookingId

      return new Promise ( function(resolve) {
        axios.get(url, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async getBookings(){
      let selected_booking_id = ''
      let booking_date = ''
      if(this.$route.hasOwnProperty('query') && this.$route.query.hasOwnProperty('selected_booking')){
        selected_booking_id = this.$route.query.selected_booking
        booking_date = await this.getBokingDate(this.$route.query.selected_booking)
        const query = { ...this.$route.query };
        delete query.selected_booking;
        this.$router.replace({ query });
      }

      this.filters.game.actual = this.filters.game.value.name
      let status = ''
      if(this.filters.status.value){
        this.filters.status.actual = this.filters.status.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.label)
          .join(', ');
        status = this.filters.status.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.status)
          .join(',');
      }

      let game_id = ''
      if(this.filters.game.value){
        this.filters.game.actual = this.filters.game.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.name)
          .join(', ');
        game_id = this.filters.game.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.id)
          .join(',');
      }

      if(booking_date){
        let dt = new Date()
        dt.setFullYear(booking_date.booking_date.split('T')[0].split('-')[0])
        dt.setMonth(booking_date.booking_date.split('T')[0].split('-')[1] - 1, booking_date.booking_date.split('T')[0].split('-')[2])
        this.current = dt
      }
      let current = this.current
      if(this.current instanceof Date){
        current = this.current.getFullYear() + '-' +  ('0' + (this.current.getMonth()+1)).slice(-2) + '-' + ('0' +this.current.getDate()).slice(-2)
      } else {
        current = moment(this.current, 'YYYY-MM-DD').format('YYYY-MM-DD')
      }
      // if(booking_date){
      //   current = booking_date.booking_date
      // }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/bookings?type=3&day='
        + current + '&status=' + status.toLowerCase().replace(/\s/g, "_") + '&game=' + game_id

      this.axios.get(url, config)
        .then(response => {
          this.pricing_types = response.data.pricing_types
          this.bookings = response.data.bookings
          this.resource_slots = response.data.resource_slots
          this.payment_settings = response.data.payment_settings

          if(this.bookings.length <= 0){
            this.empty = true
          } else {
            this.empty = false
          }
          this.taxes = response.data.taxes
          this.fees = response.data.fees

          //Check if item is in cart and mark it accordingly
          for(let n in this.bookings){
            this.bookings[n].staff = JSON.parse(this.bookings[n].staff)
            this.bookings[n].custom_pricing = JSON.parse(this.bookings[n].custom_pricing)
            this.bookings[n].pricing_type = this.pricing_types[0]
            if(localStorage.getItem('cart')){
              let cart = JSON.parse(localStorage.getItem('cart'))
              for(let m in cart){
                if(cart[m].id === this.bookings[n].id){
                  response.data.bookings[n].in_cart = true
                }
              }
            }

            let startDate = moment(this.bookings[n].booking_date, 'YYYY-MM-DD')
            let startTime = moment(this.bookings[n].start_time, 'HH:mm:ss')
            startDate.set({
              hour: startTime.hour(),
              minute: startTime.minute(),
              second: startTime.second()
            });
            let endDate =  moment(this.bookings[n].end_date, 'YYYY-MM-DD')
            let endTime = moment(this.bookings[n].end_time, 'HH:mm:ss')
            endDate.set({
              hour: endTime.hour(),
              minute: endTime.minute(),
              second: endTime.second()
            });
            if(!startDate.isBefore(endDate)){
              this.bookings[n].end_date = this.bookings[n].start_date
            }
            startDate.set({
              hour: 0,
              minute: 0,
              second: 0
            });
            let selectedDate = moment(this.current, 'YYYY-MM-DD')
            selectedDate.set({
              hour: 0,
              minute: 0,
              second: 0
            });
            if(!startDate.isSame(selectedDate, 'day')){
              this.bookings.splice(Number(n), 1)
            }
          }

          if(this.payment_settings.payment_gateway_id === 2){
            this.squareAppId = this.payment_settings.api_name
            this.squareLocationId = this.payment_settings.api_location
            this.loadSquare(this.payment_settings.is_live)
          }
          if(this.payment_settings.payment_gateway_id === 3){
            this.loadStripe()
          }
          if(this.payment_settings.payment_gateway_id === 4){
            this.loadPaystack()
          }
          this.j++
          this.loading = false
          if(selected_booking_id){
            let item = _.find(this.bookings, {id: Number(selected_booking_id)})
            if(item){
              this.editBooking(item)
            }
          }
          this.addQueryParameters()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            // this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getGameObj(game){
      if(this.games != ''){
        let selectedGame = this.games.filter(obj => {
          return obj.name === game
        })
        return selectedGame
      }
    },
    async updateBookingSlot(){

      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + this.selectedBookingSlot.id

      let data = {
        status: this.selectedBookingSlot.status === 'completed' ? this.selectedBookingSlot.status : this.selectedStatus.toLowerCase().replace(/\s/g, "_"),
        min_booking_value: this.min_booking_value,
        min_booking_period: this.min_booking_period,
        max_booking_value: this.max_booking_value,
        max_booking_period: this.max_booking_period,
        slot_text: this.slot_text,
        block_note: this.block_note,
        has_external_link: this.has_external_link,
        external_link: this.external_link,
        game_id: this.getGameObj(this.selectedGame)[0].id,
        booking_date: moment(this.selectedBookingSlot.booking_date).format('YYYY-MM-DD'),
        end_date: moment(this.selectedBookingSlot.end_date).format('YYYY-MM-DD'),
        start_time: moment(this.start_time).format('HH:mm:ss'),
        end_time: moment(this.end_time).format('HH:mm:ss'),
        is_custom_pricing_enabled: this.enable_custom_pricing
      }

      if(this.enable_custom_pricing && this.expand_price_settings){
        data.pricing_type_id = this.custom_pricing_type.id
        data.allow_deposit = this.allow_deposit
        data.cumulative_category_pricing = this.cumulative_category_pricing
        data.hide_pricing_rate = this.hide_pricing_rate
        data.deposit_amount = this.deposit_amount
        data.require_deposit = this.require_deposit
        data.deposit_type = this.deposit_type.id
        data.custom_pricing = this.custom_pricing_categories
      }
      this.loading = true
      this.axios.put(url, data, config)
        .then(response => {
          this.modals.edit_booking = false
          swal('Success', 'Booking has been updated' , 'success')
          this.gift_card_code = ''
          this.selectedGiftCardCode = ''
          this.getBookings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }  else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }  else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getTickets(bookingId){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings/' + bookingId + '/tickets'

      return new Promise ( function(resolve) {
        axios.get(url, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      })

    },
    async editBooking(item, event){
      if(event){
        event.preventDefault()
      }
      this.price_override = false
      let booking_time_slot = _.find(this.available_bookings, {id: item.id})
      if(!booking_time_slot){
        this.available_bookings.push({id: item.id,
          booking_date: item.booking_date,
          end_date: item.end_date,
          start_time: item.start_time,
          pricing: item.pricing,
          custom_pricing: item.custom_pricing,
          game_id: item.game_id,
          game_name: item.game_name,
          is_custom_pricing_enabled: item.is_custom_pricing_enabled,
          slot_cumulative_category_pricing: item.cumulative_category_pricing,
          slot_hide_pricing_rate: item.hide_pricing_rate,
          slot_pricing_type_id: item.pricing_type_id,
          pricing_type_id: item.pricing_type_id,
          cumulative_category_pricing: item.cumulative_category_pricing,
          hide_pricing_rate: item.hide_pricing_rate,
          end_time: item.end_time})
        this.available_bookings = _.orderBy(this.available_bookings, 'start_time')
      }

      this.time_slot = _.find(this.available_bookings, {id: item.id})
      this.selectedBookingSlot = item
      this.selectedBookingSlot.booking_date = moment(this.selectedBookingSlot.booking_date, 'YYYY-MM-DD').toDate()
      this.selectedBookingSlot.end_date = moment(this.selectedBookingSlot.end_date, 'YYYY-MM-DD')
      if(!this.selectedBookingSlot.end_date.isValid()){
        this.selectedBookingSlot.end_date = this.selectedBookingSlot.booking_date
      } else {
        this.selectedBookingSlot.end_date = this.selectedBookingSlot.end_date.toDate()
      }
      this.selectedBookingSlot.booking_date_label = this.selectedBookingSlot.booking_date
      this.selectedGame = item.game_name
      this.start_time = item.start_time
      //Min /max values
      this.min_booking_period = _.find(this.min_periods, {id: this.selectedBookingSlot.min_booking_period})
      this.min_booking_value = this.selectedBookingSlot.min_booking_value
      this.max_booking_period = _.find(this.max_periods, {id: this.selectedBookingSlot.max_booking_period})
      this.max_booking_value = this.selectedBookingSlot.max_booking_value

      this.selectedStatus = 'Available'
      if(item.status === 'blocked'){
        this.selectedStatus = 'Blocked'
      } else if (item.status === 'call_to_book'){
        this.selectedStatus = 'Call to book'
      }
      this.block_note = ''
      this.has_external_link = item.has_external_link
      this.external_link = item.external_link
      this.slot_text = item.slot_text
      this.expand_slot_settings = false
      this.expand_price_settings = false
      this.end_time = item.end_time
      if(!(this.start_time instanceof Date)){
        let dt = new Date()
        dt.setHours(this.start_time.split(':')[0])
        dt.setMinutes(this.start_time.split(':')[1])
        this.start_time = dt
      }
      if(!(this.end_time instanceof Date)){
        let dte = new Date()
        dte.setHours(this.end_time.split(':')[0])
        dte.setMinutes(this.end_time.split(':')[1])
        this.end_time = dte
      }

      let pricing = []
      let archived_pricing = false

      if(item.is_custom_pricing_enabled){
        pricing = item.custom_pricing
        this.custom_pricing_categories = []
        this.allow_deposit = item.allow_deposit
        this.require_deposit = item.require_deposit
        this.cumulative_category_pricing = item.cumulative_category_pricing
        this.hide_pricing_rate = item.hide_pricing_rate
        this.deposit_amount = item.deposit_amount
        this.deposit_type = _.find(this.valueTypes, { id: item.deposit_type})
        this.number_of_players = 0
        let ticket = ''
        let categories = this.getCategories(pricing)

        for(let n in pricing){
          if(!(_.find(this.custom_pricing_categories, { category_id: pricing[n].category_id }))){
            ticket = _.filter(JSON.parse(item.tickets), { pricing_category_id: pricing[n].category_id })
            let num = 0
            for(let m in ticket){
              num += ticket[m].number_of_players
            }
            this.custom_pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: num,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
          }
        }
      } else if((item.status !== 'booked' && item.status !== 'completed') || !item.tickets) {
        pricing = JSON.parse(this.getGameObj(item.game_name)[0].game_pricing)
        this.pricing_categories = []
        this.number_of_players = 0
        let ticket = ''
        let categories = this.getCategories(pricing)
        for(let n in pricing){
          if(!(_.find(this.pricing_categories, { category_id: pricing[n].category_id }))){
            ticket = _.filter(JSON.parse(item.tickets), { pricing_category_id: pricing[n].category_id })
            let num = 0
            for(let m in ticket){
              num += ticket[m].number_of_players
            }

            this.pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: num,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
          }
        }
      } else {
        this.pricing_categories = await this.getTickets(item.id)
        for(let n in this.pricing_categories){
          this.pricing_categories[n].pricing = JSON.parse(this.pricing_categories[n].pricing)
          this.pricing_categories[n].category_pricing = this.pricing_categories[n].pricing
        }
        archived_pricing = true
      }

      if(item.transaction_id){
        this.user.first_name = item.first_name
        this.user.last_name = item.last_name
        this.user.email = item.email
        this.user.phone = item.phone

        this.subtotal = 0
        this.total_group_size = item.group_size
        this.total_amount = item.booking_price


        this.getSubtotal(this.selectedBookingSlot, archived_pricing)

        this.payment_amount = parseFloat(item.due).toFixed(2)
        this.cardholder_first_name = ''
        this.cardholder_last_name = ''
        this.payment = {
          cardNumber: '',
          expirationDate: '',
          cardCode: '',
          zip: ''
        }
        this.getCredits(item.end_user_id)

        if(parseFloat(item.due) <= 0){
          this.categories = [
            { name: 'Edit booking', icon: 'tim-icons  icon-calendar-60',active: true },
            { name: 'Edit time slot', icon: 'tim-icons el-icon-edit-outline',active: false },
            { name: 'Edit customer', icon: 'tim-icons icon-single-02', active: false },
          ];
        } else {
          this.categories = [
            { name: 'Edit booking', icon: 'tim-icons  icon-calendar-60',active: true },
            { name: 'Edit time slot', icon: 'tim-icons el-icon-edit-outline',active: false },
            { name: 'Edit customer', icon: 'tim-icons icon-single-02', active: false },
            { name: 'Add payment', icon: 'tim-icons icon-money-coins', active: false }
          ];
        }
        this.switchTab(0)
      }
      this.errs = {
        booking_date: '',
        booking_end_date: '',
        date: '',
        start_time: '',
        end_time: '',
        cardholder_first_name: '',
        cardholder_last_name: '',
        ccn: '',
        cvv: '',
        zip: '',
        expiration: '',
        first_name: '',
        last_name: '',
        email: '',
        payment_amount: ''
      }

      this.waive_tax = false
      this.waive_fee = false
      this.pay_partial_amount = false
      this.partial_amount = 0
      this.promos = []
      this.promo_code = ''
      this.selectedPromoCode = ''
      this.no_confirmation_email = false
      this.gift_card_code = ''
      this.selectedGiftCardCode = ''
      this.j++
      this.modals.edit_booking = true
    },
    isValidUserForm(){
      let errors = 0
      if(this.user.first_name == ''){
        this.errs.first_name = 'First name cannot be empty'
        errors++
      } else {
        this.errs.first_name = ''
      }

      if(this.user.last_name == ''){
        this.errs.last_name = 'Last name cannot be empty'
        errors++
      } else {
        this.errs.last_name = ''
      }

      if(!this.isEmailValid(this.user.email)){
        this.errs.email = 'Invalid email'
        errors++
      } else {
        this.errs.email = ''
      }
      if(errors > 0){
        return false
      }
      return true
    },
    isValidPaymentForm(){
      let errors = 0
      if(this.selectedPaymentOption.name === 'Card' && this.cardholder_first_name == ''){
        this.errs.cardholder_first_name = 'First name cannot be empty'
        errors++
      } else {
        this.errs.cardholder_first_name = ''
      }

      if(this.selectedPaymentOption.name === 'Card' && this.cardholder_last_name == ''){
        this.errs.cardholder_last_name = 'Last name cannot be empty'
        errors++
      } else {
        this.errs.cardholder_last_name = ''
      }

      if(this.selectedPaymentOption.name === 'Card' &&  this.payment_settings.payment_gateway_id === 1 &&
        this.payment.cardNumber.toString().length < 15 ){
        this.errs.ccn = 'Invalid credit card number'
        errors++
      } else {
        this.errs.ccn = ''
      }

      let dt = new Date()
      const year = dt.getFullYear().toString().slice(-2);
      if(this.selectedPaymentOption.name === 'Card'
        &&  this.payment_settings.payment_gateway_id === 1
        && (this.payment.expirationDate === ''
          || (this.payment.expirationDate.split('/')[0] > 12  || this.payment.expirationDate.split('/')[0] <= 0)
          || this.payment.expirationDate.split('/')[1] < parseInt(year))) {
        this.errs.expiration = 'Invalid expiration date'
        errors++
      } else {
        this.errs.expiration = ''
      }

      // if(this.selectedPaymentOption === 'Card'
      //   &&  this.payment_settings.payment_gateway_id === 1) {
      //   this.errs.zip = 'Invalid zip'
      //   errors++
      // } else {
      //   this.errs.zip = ''
      // }

      if(this.selectedPaymentOption.name === 'Card'
        &&  this.payment_settings.payment_gateway_id === 1
        && (this.payment.cardCode.toString().length < 3 || this.payment.cardCode.toString().length > 4)) {
        this.errs.cvv = 'Invalid CVV'
        errors++
      } else {
        this.errs.cvv = ''
      }

      if(parseFloat(this.payment_amount) > parseFloat(this.selectedBookingSlot.due)){
        this.errs.payment_amount = 'Amount cannot exceed due'
        errors++
      } else if(parseFloat(this.payment_amount) <= 0 || isNaN(this.payment_amount)){
        this.errs.payment_amount = 'Invalid payment amount'
        errors++
      } else if(this.selectedPaymentOption.name === 'Customer credit' && parseFloat(this.payment_amount) > parseFloat(this.available_credit)){
        this.errs.payment_amount = 'Amount cannot exceed available credit'
        errors++
      } else if (this.selectedPaymentOption.name === 'Gift card'
        && parseFloat(this.payment_amount) > parseFloat(this.selectedGiftCardCode.remaining)) {
        this.errs.payment_amount = 'Amount cannot exceed available gift card balance'
        errors++
      } else {
        this.errs.payment_amount = ''
      }

      if(this.selectedPaymentOption.name === 'Gift card' && (!this.gift_card_code
        || !this.selectedGiftCardCode.hasOwnProperty('id')
        || this.getGiftCardCode(this.selectedGiftCardCode.code) !== this.gift_card_code)){
        this.errs.gift_card_code = 'You must choose a valid gift card'
        errors++
      } else {
        this.errs.gift_card_code = ''
      }

      if(errors > 0){
        return false
      }
      return true
    },
    getEndTime(start, duration){
      let hh = start.split(':')[0]
      let mm = start.split(':')[1]
      let minutes = (parseInt(hh) * 60) + parseInt(mm)
      let total_minutes = parseInt(minutes) + parseInt(duration)
      let end_hours = Math.floor(total_minutes / 60);
      let end_minutes = total_minutes % 60;
      return end_hours + ':' + end_minutes

    },
    isValidRescheduleSlot(){
      let error = 0
      if(this.selectedGame == '') {
        this.errs.game = 'Invalid game'
        error++
      } else {
        this.errs.game = ''
      }
      if(this.selectedBookingSlot.booking_date == '' || this.selectedBookingSlot.booking_date === null) {
        this.errs.booking_date = 'Invalid booking date'
        error++
      } else {
        this.errs.booking_date = ''
      }
      if(this.time_slot === null || this.time_slot == ''){
        this.errs.time_slot = 'Invalid time slot'
        error++
      } else {
        this.errs.time_slot = ''
      }
      if(this.number_of_players <= 0){
        this.errs.number_of_players = 'Please select a valid group size'
        error++
      } else {
        this.errs.number_of_players = ''
      }
      this.j++
      if(error > 0){
        return false
      }
      return true
    },
    isValidSlot(start_date, end_date) {
      let error = 0
      if(this.selectedGame == '') {
        this.errs.game = 'Invalid game'
        error++
      } else {
        this.errs.game = ''
      }
      if(start_date == '' || start_date === null) {
        this.errs.booking_date = 'Start date cannot be empty'
        error++
      } else {
        this.errs.booking_date = ''
      }
      let start = moment(start_date, 'YYYY-MM-DD')
      let end = moment(end_date, 'YYYY-MM-DD')
      const isDateSequenceBeforeOrSame = start.isBefore(end, 'day') || start.isSame(end, 'day')

      if(end_date === null){
        this.errs.booking_end_date = "End date cannot be empty"
        error++
      } else if(!isDateSequenceBeforeOrSame){
        this.errs.booking_end_date = "End date cannot be before start date"
        error++
      } else {
        this.errs.booking_end_date = ''
      }

      if(this.start_time === null) {
        this.errs.start_time = "Start time cannot be empty"
        error++
      } else {
        this.errs.start_time = ''
      }

      let start_time = moment(this.start_time, 'HH:mm:ss')
      let end_time = moment(this.end_time, 'HH:mm:ss')

      start.set({
        hour: start_time.hour(),
        minute: start_time.minute(),
        second: start_time.second(),
      });

      end = end.set({
        hour: end_time.hour(),
        minute: end_time.minute(),
        second: end_time.second(),
      });
      if(this.end_time === null) {
        this.errs.end_time = "End time cannot be empty"
        error++
      } else if(!start.isBefore(end)) {
        this.errs.end_time = "End time cannot be before start time"
        error++
      } else {
        this.errs.end_time = ''
      }

      if(error > 0){
        return false
      }
      return true
    },
    async createTimeSlot() {
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/bookings'

      let status = 'available'
      if(this.selectedStatus === 'Blocked'){
        status = 'blocked'
      } else if (this.selectedStatus === 'Call to book'){
        status = 'call_to_book'
      }

      let data = {
        game_id: selectedGame[0].id,
        game_name: selectedGame[0].name,
        booking_date: moment(this.booking_date).format('YYYY-MM-DD'),
        booking_end_date: moment(this.booking_end_date).format('YYYY-MM-DD'),
        start_time: moment(this.start_time).format('HH:mm:ss'),
        end_time: moment(this.end_time).format('HH:mm:ss'),
        min_booking_value: this.min_booking_value,
        min_booking_period: this.min_booking_period.id,
        max_booking_period: this.max_booking_period.id,
        max_booking_value: this.max_booking_value,
        slot_text: this.slot_text,
        has_external_link: this.has_external_link,
        external_link: this.external_link,
        block_note: this.block_note,
        status: status,
        is_custom_pricing_enabled: this.enable_custom_pricing
      }

      if(this.enable_custom_pricing && this.expand_price_settings){
        data.pricing_type_id = this.custom_pricing_type.id
        data.allow_deposit = this.allow_deposit
        data.cumulative_category_pricing = this.cumulative_category_pricing
        data.hide_pricing_rate = this.hide_pricing_rate
        data.deposit_amount = this.deposit_amount
        data.require_deposit = this.require_deposit
        data.deposit_type = this.deposit_type.id
        data.custom_pricing = this.custom_pricing_categories
      }
      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          // this.current = this.booking_date.getFullYear() + '-' +  ('0' + (this.booking_date.getMonth()+1)).slice(-2) + '-' + ('0' + this.booking_date.getDate()).slice(-2)
          this.current = new Date(this.booking_date)
          this.selectedTimeSlot = ''
          this.modals.new_booking = false
          this.modals.new_booking_slot = false
          this.booking_time = ''
          this.booking_date = ''
          this.selectedGame = ''

          this.user.first_name = ''
          this.user.last_name = ''
          this.user.email = ''
          this.payment = {
            cardNumber: '',
            expirationDate: '',
            cardCode: '',
            zip: ''
          }
          this.loading = false
          // swal('Success!', 'Time slot has been created', 'success')
          this.getBookings()

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    paymentRequest(type, url, data, config){

      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          if(type === 'new'){
            this.current = this.booking_date
            this.modals.new_booking = false
            this.selectedPaymentOption = this.paymentOptions.length > 0 ?
              this.paymentOptions[0] : {name: '', content: '', id: '', is_default: ''}
            this.user.first_name = ''
            this.user.last_name = ''
            this.user.email = ''
            this.payment = {
              cardNumber: '',
              expirationDate: '',
              cardCode: '',
              zip: ''
            }
            swal('Success', 'New booking has been created' , 'success')
          } else {
            this.modals.add_payment = false
            swal('Success', 'Payment has been added to booking' , 'success')
          }
          this.send_payment_request = false
          localStorage.removeItem('cart')
          localStorage.removeItem('taxes')
          localStorage.removeItem('fees')
          localStorage.removeItem('customer')
          this.getBookings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async handlePaystackAdditionalPayment(reference){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.selectedBookingSlot.transaction_id + '/payments'
      let data = {
        amount: this.payment_amount,
        user: this.user,
        paystack_reference: reference
      }

      this.axios.post(url, data, config)
        .then(response => {
          this.add_payment_form = false
          this.modals.edit_modal = false
          this.selectedPaymentOption = this.paymentOptions.length > 0 ?
            this.paymentOptions[0] : {name: '', content: '', id: '', is_default: ''}
          swal('Success', 'Payment has been added to booking' , 'success')
          this.loading = false
          this.getBookings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async handlePaystackPayment(reference){
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'

      this.checkWaivedTaxFee()
      let data = {
        subtotal: this.subtotal,
        send_payment_request: this.send_payment_request,
        total: this.total,
        tax: this.total_tax,
        fee: this.total_fee,
        user: this.user,
        paystack_reference: reference,
        payment: {cardNumber: '1111'},
        discount: this.getDiscounts(),
        promo_codes: this.promos,
        no_confirmation_email: this.no_confirmation_email,
        bookings: [{
          id: this.selectedTimeSlot.id,
          group_size: this.number_of_players,
          game_id: selectedGame[0].id,
          game_name: selectedGame[0].name,
          booking_date: this.selectedTimeSlot.booking_date,
          end_date: this.selectedTimeSlot.end_date,
          start_time: this.selectedTimeSlot.start_time,
          end_time: this.selectedTimeSlot.end_time,
          total: this.subtotal,
          tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        }],
      }
      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone
      let response = await this.paymentRequest(type, url, data, config)
    },
    async handleStripePayment(type){
      this.loading = true
      const {token, error} = await this.stripe.createToken(this.card);
      if(error){
        console.log(error)
        swal('Error!', 'There was an issue with your payment', 'error')
        this.loading = false
        return
      }
      let selectedGame = this.getGameObj(this.selectedGame)
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = ''
      let data = {}

      this.checkWaivedTaxFee()
      if(type === 'new'){
        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/stripe'
        data = {
          amount: this.total,
          subtotal: this.subtotal,
          tax: this.total_tax,
          fee: this.total_fee,
          total: this.total,
          user: this.user,
          send_payment_request: this.send_payment_request,
          discount: this.getDiscounts(),
          promo_codes: this.promos,
          no_confirmation_email: this.no_confirmation_email,
          bookings: [{
            id: this.selectedTimeSlot.id,
            group_size: this.number_of_players,
            game_id: selectedGame[0].id,
            game_name: selectedGame[0].name,
            booking_date: this.selectedTimeSlot.booking_date,
            end_date: this.selectedTimeSlot.end_date,
            start_time: this.selectedTimeSlot.start_time,
            end_time: this.selectedTimeSlot.end_time,
            total: this.subtotal,
            tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
          }],
          token: token.id
        }
      } else {
        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.selectedGame.transaction_id + '/payments/stripe'
        data = {
          amount: this.payment_amount,
          user: this.user,
          token: token.id
        }
      }

      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone

      let response = await this.paymentRequest(type, url, data, config)
    },
    async handleAuthorizeNetPayment(type){
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'

      this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
      this.checkWaivedTaxFee()
      let data = {
        subtotal: this.subtotal,
        total: this.total,
        tax: this.total_tax,
        fee: this.total_fee,
        user: this.user,
        payment: this.payment,
        send_payment_request: this.send_payment_request,
        discount: this.getDiscounts(),
        promo_codes: this.promos,
        no_confirmation_email: this.no_confirmation_email,
        bookings: [{
          id: this.selectedTimeSlot.id,
          group_size: this.number_of_players,
          game_id: selectedGame[0].id,
          game_name: selectedGame[0].name,
          booking_date: this.selectedTimeSlot.booking_date,
          end_date: this.selectedTimeSlot.end_date,
          start_time: this.selectedTimeSlot.start_time,
          end_time: this.selectedTimeSlot.end_time,
          total: this.subtotal,
          tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        }],
      }
      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone
      let response = await this.paymentRequest(type, url, data, config)
    },
    checkWaivedTaxFee(){
      if(this.waive_tax){
        this.total = this.total - this.total_tax
        this.total_tax = 0
      }
      if(this.waive_fee){
        this.total = this.total - this.total_fee
        this.total_fee = 0
      }
    },
    async handlePayOnArrival(type){
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'

      this.checkWaivedTaxFee()

      let data = {
        subtotal: this.subtotal,
        total: this.total,
        tax: this.total_tax,
        fee: this.total_fee,
        user: this.user,
        promo_codes: this.promos,
        discount: this.getDiscounts(),
        send_payment_request: this.send_payment_request,
        no_confirmation_email: this.no_confirmation_email,
        bookings: [{
          id: this.selectedTimeSlot.id,
          group_size: this.number_of_players,
          game_id: selectedGame[0].id,
          game_name: selectedGame[0].name,
          booking_date: this.selectedTimeSlot.booking_date,
          end_date: this.selectedTimeSlot.end_date,
          start_time: this.selectedTimeSlot.start_time,
          end_time: this.selectedTimeSlot.end_time,
          total: this.subtotal,
          tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        }],
      }
      let response = await this.paymentRequest(type, url, data, config)
    },
    async handleGiftCardPayment(type){
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = ''
      let data = {}
      this.checkWaivedTaxFee()
      if(type === 'new'){
        url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'
        data = {
          gift_card: this.selectedGiftCardCode,
          subtotal: this.subtotal,
          total: this.total,
          tax: this.total_tax,
          fee: this.total_fee,
          user: this.user,
          payment_type: 'gift_card',
          discount: this.getDiscounts(),
          promo_codes: this.promos,
          no_confirmation_email: this.no_confirmation_email,
          bookings: [{
            id: this.selectedTimeSlot.id,
            group_size: this.number_of_players,
            game_id: selectedGame[0].id,
            game_name: selectedGame[0].name,
            booking_date: this.selectedTimeSlot.booking_date,
            end_date: this.selectedTimeSlot.end_date,
            start_time: this.selectedTimeSlot.start_time,
            end_time: this.selectedTimeSlot.end_time,
            total: this.subtotal,
            tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
          }],
        }
      } else {
        url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId + '/transactions/' + this.selectedGame.transaction_id + '/payments'
        data = {
          amount: this.payment_amount,
          user: this.user,
          payment_type: 'gift_card',
        }
      }
      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone
      let response = await this.paymentRequest(type, url, data, config)
    },
    async handleCashPayment(type, payment_type, is_default){
      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = ''
      let data = {}
      this.checkWaivedTaxFee()
      if(type === 'new'){
        url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'
        data = {
          subtotal: this.subtotal,
          total: this.total,
          tax: this.total_tax,
          send_payment_request: this.send_payment_request,
          fee: this.total_fee,
          user: this.user,
          payment_type: payment_type,
          custom_payment_type: !is_default,
          discount: this.getDiscounts(),
          promo_codes: this.promos,
          no_confirmation_email: this.no_confirmation_email,
          bookings: [{
            id: this.selectedTimeSlot.id,
            group_size: this.number_of_players,
            game_id: selectedGame[0].id,
            game_name: selectedGame[0].name,
            booking_date: this.selectedTimeSlot.booking_date,
            end_date: this.selectedTimeSlot.end_date,
            start_time: this.selectedTimeSlot.start_time,
            end_time: this.selectedTimeSlot.end_time,
            total: this.subtotal,
            tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
          }],
        }
      } else {
        url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId + '/transactions/' + this.selectedGame.transaction_id + '/payments'
        data = {
          amount: this.payment_amount,
          user: this.user,
          payment_type: payment_type,
        }
      }
      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone
      let response = await this.paymentRequest(type, url, data, config)
    },
    async handleCustomerCreditPayment(type){
      if(parseFloat(this.applied_credit) > parseFloat(this.total)){
        swal('Ooops!', 'Charged amount cannot be greater than the total value of the booking.', 'error')
        return
      }
      if(parseFloat(this.applied_credit) > parseFloat(this.available_credit)){
        swal('Ooops!', 'You cannot apply more credits than available.', 'error')
        return
      }

      let selectedGame = this.getGameObj(this.selectedGame)
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'
      this.checkWaivedTaxFee()
      let data = {
        subtotal: this.subtotal,
        total: this.total,
        tax: this.total_tax,
        send_payment_request: this.send_payment_request,
        fee: this.total_fee,
        user: this.user,
        discount: this.getDiscounts(),
        promo_codes: this.promos,
        no_confirmation_email: this.no_confirmation_email,
        bookings: [{
          id: this.selectedTimeSlot.id,
          group_size: this.number_of_players,
          game_id: selectedGame[0].id,
          game_name: selectedGame[0].name,
          booking_date: this.selectedTimeSlot.booking_date,
          end_date: this.selectedTimeSlot.end_date,
          start_time: this.selectedTimeSlot.start_time,
          end_time: this.selectedTimeSlot.end_time,
          total: this.subtotal,
          tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        }],
        customer_credit: this.applied_credit
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone

      let response = await this.paymentRequest(type, url, data, config)
    },
    validateBooking(){
      let error = 0
      if(this.selectedGame == '') {
        this.errs.game = 'Invalid game'
        error++
      } else {
        this.errs.game = ''
      }
      if(this.booking_date == '' || this.booking_date === null) {
        this.errs.booking_date = 'Invalid booking date'
        error++
      } else {
        this.errs.booking_date = ''
      }

      if(error > 0){
        return false
      }
      return true
    },
    validateUser(){
      let error = 0
      if(this.user.first_name == '') {
        this.errs.first_name = 'Invalid name'
        error++
      } else {
        this.errs.first_name = ''
      }
      if(this.user.last_name == '' ) {
        this.errs.last_name = 'Invalid name'
        error++
      } else {
        this.errs.last_name = ''
      }
      if(this.isEmailValid(this.user.email) == false) {
        this.errs.email = 'Invalid email'
        error++
      } else {
        this.errs.email = ''
      }
      if(error > 0){
        return false
      }
      return true
    },
    validateCard(){
      let error = 0

      if(this.add_payment == true && this.selectedPaymentOption.name === 'Card'){
        if(this.payment.cardNumber == '' ) {
          this.errs.ccn = 'Invalid card number'
          error++
        } else {
          this.errs.ccn = ''
        }
        if(this.payment.expirationDate == '' ) {
          this.errs.expiration = 'Invalid expiration date'
          error++
        } else {
          this.errs.expiration = ''
        }
        if(this.payment.cardCode == '' ) {
          this.errs.cvv = 'Invalid CVV'
          error++
        } else {
          this.errs.cvv = ''
        }
        if(this.payment.zip == '' ) {
          this.errs.zip = 'Invalid zip'
          error++
        } else {
          this.errs.zip = ''
        }
      }

      if(error > 0){
        return false
      }
      return true
    },
    async createBookingPrompt(){
      if(!this.validateBooking() || !this.validateUser()){
        return
      }

      let validator = await this.validateResources(this.selectedTimeSlot.booking_date, this.selectedTimeSlot.end_date,
        this.selectedTimeSlot.start_time, this.selectedTimeSlot.end_time, this.selectedTimeSlot.id)
      if(validator !== 'Success'){
        this.modals.new_booking = false
        swal({
          title: 'Are you sure?',
          text: validator,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I am sure'
        }).then((response)=> {
          if(response.value == true){
            this.createBooking()
          } else {
            this.modals.new_booking = true
          }
        })
      } else {
        await this.createBooking()
      }
    },
    async createBooking() {

      if(this.selectedPaymentOption.name === 'Customer credit'){
        let customerCreditPayment = await this.handleCustomerCreditPayment('new')
      }
      else if (this.add_payment === false){
        let payOnArrival = await this.handlePayOnArrival('new')
      }
      else if (!this.selectedPaymentOption.is_default || this.selectedPaymentOption.name === 'Yappy' ||
        this.selectedPaymentOption.name === 'Cash' ||
        this.selectedPaymentOption.name === 'POS payment'){
        let payment_type = 'pos'
        if(this.selectedPaymentOption.name === 'Cash'){
          payment_type = 'cash'
        }
        if(!this.selectedPaymentOption.is_default){
          payment_type = this.selectedPaymentOption.name
        }
        if(this.selectedPaymentOption.name === 'Yappy'){
          payment_type = 'Yappy'
        }
        let cashPayment = await this.handleCashPayment('new', payment_type, this.selectedPaymentOption.is_default)
      }
      else if (this.selectedPaymentOption.name === 'Gift card' ){
        if(!this.gift_card_code
          || !this.selectedGiftCardCode.hasOwnProperty('id')
          || this.getGiftCardCode(this.selectedGiftCardCode.code) !== this.gift_card_code){
          this.errs.gift_card_code = 'You must choose a valid gift card'
          return
        } else {
          this.errs.gift_card_code = ''
        }
        let giftCard = await this.handleGiftCardPayment('new')
      }
      else if (this.payment_settings.payment_gateway_id === 1 && this.selectedPaymentOption.name === 'Card' ){
        if(!this.validateCard()){
          return
        }
        let authNetPayment = await this.handleAuthorizeNetPayment('new')
      }
      else if (this.payment_settings.payment_gateway_id === 2 && this.selectedPaymentOption.name === 'Card' ){
        let squarePayment = await this.createSquarePayment('new')
      }
      else if (this.payment_settings.payment_gateway_id === 3 && this.selectedPaymentOption.name === 'Card' ){
        let stripePayment = await this.handleStripePayment('new')
      } else if (this.payment_settings.payment_gateway_id === 4 && this.selectedPaymentOption.name === 'Card' ){
        let payStack = await this.payWithPaystack('new')
      }
      else {
        //TODO: No payment gateway has been set up

      }
    },
    async delete(item){
      let html =  ''
      if(item.status !== 'booked' && !(item.status == 'completed' && item.transaction_id)){
        html = 'Time slot has been deleted'
      } else {
        html = 'Booking has been cancelled'
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success',
            text: html,
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getBookings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }

          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteBooking(item) {
      let html =  ''
      let title = ''
      if(item.status !== 'booked' && !(item.status == 'completed' && item.transaction_id)){
        title = 'Delete time slot'
        html = 'This time slot will be <b>permanently deleted</b> and will no longer be available for customers to book.'
      } else {
        title = 'Cancel booking'
        html = '<small> Cancelling this booking will result in the time slot becoming available to book. Any amount paid will need to be manually refunded to the customer. <br>' +
          '</small>'
      }

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.delete(item);
        }
      })
    },
    getDate(d){
      return date(this.date_format, d)
    },
    getTime(d){
      if(!d){
        return
      }
      return time(this.time_format, d)
    },
    async loadPaystack(){
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v2/inline.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async loadStripe(){
      const stripeExists = document.getElementById('stripev3');
      if (!stripeExists) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.id = 'stripev3';
        document.body.appendChild(script);
      }
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeSquare(type){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
       await this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      try {
        if(type == 'add_payment'){
          this.card = await this.initializeCardAddPayment(this.square);
        } else {
          this.card = await this.initializeCard(this.square);
        }

      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeStripe(){
      this.stripe = window.Stripe(this.payment_settings.api_name);
      this.elements = this.stripe.elements();

      const style = {
        base: {
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff'
        },
      };

      // Create an instance of the card Element.
      this.card = this.elements.create('card', {style});
      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');
    },
    async initializeStripeAddPayment(){
      this.stripe = window.Stripe(this.payment_settings.api_name);
      this.elements = this.stripe.elements();
      const style = {
        base: {
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff'
        },
      };
      this.card = this.elements.create('card', {style});
      this.card.mount('#card-element-2');
    },
    async getSettings(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'users/site-settings?group=' + companyGroupId
      this.axios.get(url, config)
        .then(response => {
          this.staff_settings = response.data.staff_settings
          this.show_staff_settings  = response.data.show_staff_settings
          if(this.staff_settings){
            this.fields = ["time", "booking", "booked_by", "number_of_players", "paid", "due", "notes", "staff", "actions"]
          } else {
            this.fields = ["time", "booking", "booked_by", "number_of_players", "paid", "due", "notes", "actions"]
          }
          if(response.data.booking_page_mode == 1){
            if(this.$route.hasOwnProperty('query') && this.$route.query.hasOwnProperty('selected_booking')){
              this.$router.push({ path: '/bookings/calendar', query: { selected_booking:  this.$route.query.selected_booking }})
            }
            else {
              this.$router.push('/bookings/calendar')
            }

          }
          // this.getBookings()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addQueryParameters() {
      const newQuery = {
        ...this.$route.query,
        date: moment(this.current).format('YYYY-MM-DD'),
        status: this.filters.status.value ? this.filters.status.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.status)
          .join(',') : '',
        game: this.filters.game.value ? this.filters.game.value
          .filter(item => item.id !== undefined) // Filter out objects without an `id`
          .map(item => item.id)
          .join(',') : '',
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
  mounted() {

    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if (this.time_format === 'HH:mm') {
      this.picker_time_format = 'HH:mm'
    }

    this.country = _.find(this.countries, { code: JSON.parse(localStorage.getItem('group')).country })
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.setToday()
    this.getSettings()
    this.polling = setInterval(this.getBookings, 60000);  // Poll every minute
    this.getGames()
    this.getPaymentMethods()
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.polling);
  },
}
</script>

<style scoped>
.colon-field{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.5rem;
}

img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}


.calendar_default_main,
.calendar_default_main * {
  color: #1d8cf8 !important;
  background-color: #00f2c3 !important;
  font-family: "Comic Sans MS", sans-serif;
}

.closed{
  position: absolute;
  pointer-events: all;
  z-index:10;
  right: 15px;
  right: 15px;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
  padding: 3px;
}
.closed:hover {
  color: #560606 !important;
}

.mark-information sup {
  background-color: rgb(153, 173, 207) !important;
}
</style>
