<template>
<div>
  <h4 class="info-text">
    Review and save
  </h4>
  <div class="row justify-content-center">
    <div class="col-11 col-lg-6 col-md-8 col-sm-7">
      <div class="row">
        <div class="col-lg-5">
          <h4 class="card-title">Basic info</h4>
        </div>
        <div class="col-lg-7">
          <div class="row">
            <div class="col-4">
              <p>Group name:</p>
            </div>
            <div class="col-8">
              <p class="text-primary">{{group.name}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <p>Group code:</p>
            </div>
            <div class="col-8">
              <p class="text-primary">{{group.code}}</p>
            </div>
          </div>
          <div class="row" v-if="group.company_number">
            <div class="col-6">
              <p>Company number:</p>
            </div>
            <div class="col-6">
              <p class="text-primary">{{group.company_number}}</p>
            </div>
          </div>
          <div v-if="group.description" class="row">
            <div class="col-4">
              <p>Description:</p>
            </div>
            <div class="col-8">
              <p class="text-primary">{{group.description}}</p>
            </div>
          </div>
          <div v-if="group.img" class="row">
            <div class="col-8">
              <img :src="group.img">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col-11 col-lg-6 col-md-8 col-sm-7">
      <div class="row">
        <div class="col-lg-5">
          <h4 class="card-title">Location details</h4>
        </div>
        <div class="col-lg-7">
          <p v-if="group.phone"><i  class="tim-icons el-icon-mobile-phone"></i> {{group.phone}}</p>
          <p v-if="group.email"><i  class="tim-icons icon-email-85"></i> {{group.email}}</p>
          <p v-if="group.address_line_1"><i  class="tim-icons el-icon-location-information"></i> {{group.address_line_1}}</p>
          <p v-if="group.address_line_2" class="ml-3">{{group.address_line_2}}</p>
          <p v-if="group.city && group.state && group.zip && group.country" class="ml-3">{{group.city}}, {{group.state}} {{group.zip}} {{group.country.name}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col-11 col-lg-6 col-md-8 col-sm-7">
      <div class="row">
        <div class="col-lg-5">
          <h4 class="card-title">Locale and formatting</h4>
        </div>
        <div class="col-lg-7">
          <div v-if="group.currency" class="row">
            <div class="col-lg-5">
              <p>Currency:</p>
            </div>
            <div class="col">
              <p>{{group.currency.currency}}</p>
            </div>
          </div>
          <div v-if="group.timezone" class="row">
            <div class="col-lg-5">
              <p>Timezone:</p>
            </div>
            <div class="col">
              <p>{{group.timezone}}</p>
            </div>
          </div>
          <div v-if="group.date_format" class="row">
            <div class="col-lg-5">
              <p>Date format:</p>
            </div>
            <div class="col">
              <p>{{group.date_format.example}} ({{group.date_format.format}})</p>
            </div>
          </div>
          <div v-if="group.time_format" class="row">
            <div class="col-lg-5">
              <p>Time format:</p>
            </div>
            <div class="col">
              <p>{{group.time_format.example}} ({{group.time_format.format}})</p>
            </div>
          </div>
          <div v-if="group.first_day_of_week" class="row">
            <div class="col-lg-5">
              <p>First day of the week:</p>
            </div>
            <div class="col">
              <p>{{group.first_day_of_week.name}}</p>
            </div>
          </div>
          <div v-if="group.currency_format" class="row">
            <div class="col-lg-5">
              <p>Currency format:</p>
            </div>
            <div class="col">
              <p>{{group.currency_format.example}}</p>
            </div>
          </div>
          <div v-if="group.number_format" class="row">
            <div class="col-lg-5">
              <p>Number format:</p>
            </div>
            <div class="col">
              <p>{{group.number_format.example}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import moment from "moment-timezone";

export default {
  name: "GroupReview",
  data(){
    return {
      group: ''
    }
  },
  methods: {
    updateModel(data){
      this.group = data
    },
    async createGroup () {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/groups'
      let data = {...this.group}

      data.timezone = {
        value: data.timezone_object.value,
        abbr: data.timezone_object.abbr,
        offset: moment.tz(data.timezone_object.value).utcOffset() / 60
      }

      return new Promise( function(resolve) {
        axios.post(url, data, config)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
  }
}
</script>

<style scoped>

</style>
