<template>
  <div>

    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--Medium and large screens-->
    <div class="row mt-3 d-none d-sm-block overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Contacts</h4>
          <div class="row">
            <div class="col-lg-10 col-md-8 col-sm-10 col-8">

              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by first name</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.first_name.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.first_name)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    First name
                    <span v-if="filters.first_name.actual">: {{filters.first_name.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by last name</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.last_name.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.last_name)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Last name
                    <span v-if="filters.last_name.actual">: {{filters.last_name.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by email</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Email"
                          v-model="filters.email.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts()">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Email
                    <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select multiple
                                     v-model="filters.game.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Game"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="game.name"
                              :key="game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateContacts()">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Game: <span v-if="filters.game.actual" v-for="(g, i) in filters.game.actual" :key="'GA' + i">&nbsp; {{g}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <base-button size="sm" v-if="add_button == true" v-on:click="addContactsToExistingGroupModal()" simple class="ml-2" type="success">Add to email group</base-button>
                <span class="ml-3 text-white">Number of&nbsp;contacts:&nbsp;{{totalRows}}</span>
              </base-input>

            </div>
            <div class="row justify-content-end">
              <base-button icon class="btn-link text-white" v-on:click="downloadCSV()">
                <i class="tim-icons icon-cloud-download-93"></i>
              </base-button>
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="getContacts()"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!--   No data panel -->
          <div v-if="empty==true" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">You have no contacts at this time.</h4>
            </div>
          </div>

          <b-table v-if="empty==false"  striped hover :items="contacts"
                    :fields="fields">
            <template #head(select)="data">
              <base-checkbox v-on:input="selectAll()" v-model="select_all"></base-checkbox>
            </template>
            <template v-slot:cell(select)="row">
              <base-checkbox v-on:input="showActionButtons()" v-model="row.item.selected"></base-checkbox>
            </template>
            <template v-slot:cell(games)="row">
               <span v-for="(g, i) in getJSON(row.item.games)" :key="'G' + i">
                <badge type="primary">{{g.game_name}}</badge>&nbsp;
              </span>
            </template>
            <template v-slot:cell(email_groups)="row">
               <span v-for="(eg, i) in getJSON(row.item.email_groups)" :key="'M' + i">
                <badge type="success">{{eg.email_group_name}}</badge>&nbsp;
              </span>
            </template>
            <template v-slot:cell(subscribed)="row">
              <i v-if="row.item.subscribed == 1" class="text-success tim-icons icon-check-2"></i>
              <i v-if="row.item.subscribed == 0" class="text-danger tim-icons icon-simple-remove"></i>

            </template>
          </b-table>
          <b-pagination v-on:input="getContacts()"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
          ></b-pagination>

        </card>
      </div>
    </div>

    <modal :show.sync="modals.add_group"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Add {{selectCount()}} contact(s) to email group</h4>
        <base-input>
          <el-select
            label="Select group"
            v-model="selectedGroup"
            class="select-primary"
            placeholder="Group"
          >
            <el-option
              v-for="group in email_groups"
              class="select-primary"
              :label="group.name"
              :value="{ name: group.name, id: group.id }"
              :key="'b' + group.id"
            ></el-option>
          </el-select>
        </base-input>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.add_group = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="addContactsToGroup()">Add</base-button>
        </div>
        <div class="row text-center d-block d-sm-none mt-3">
          <base-button type="secondary" size="sm" simple @click="modals.add_group = false">Cancel</base-button>
          <base-button class="ml-3" size="sm" simple type="primary" @click="addContactsToGroup()">Add</base-button>
        </div>
      </card>
    </modal>

  </div>
</template>

<script>
import { API_LOCATION } from '../../api-config'
import {Auth} from "aws-amplify";
import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
import axios from "axios";
import { Popover, Select, Option, DatePicker, TimeSelect, TimePicker, Tooltip } from 'element-ui';
import {Badge} from 'src/components'
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import swal from "sweetalert2";


export default {
  name: "Customers",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    [Popover.name]: Popover,
    BFormInput,
    BTable,
    BPagination,
    Badge
  },
  data() {
    return {
      loading: false,
      empty: false,
      selectedGroup: '',
      perPageOptions: [5, 10, 25, 50, 100, 250],
      fields: ["select", "first_name", "last_name", "email", "email_groups", "games", "subscribed"],
      contacts: [],
      email_groups: '',
      add_button: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      select_all: false,
      gameOptions: [],
      modals: {
        add_group: false
      },
      filters: {
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: '',
        },
        game: {
          value: '',
          actual: ''
        }
      },
    }
  },
  methods: {
    async addContactsToGroup(){
      let c = []
      let r = []
      for(let n in this.contacts){
        if(this.contacts[n].selected == true){
          c.push(this.contacts[n])
        } else {
          r.push(this.contacts[n])
        }
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/contacts'
      let data = {
        email_group_id: this.selectedGroup.id,
        contacts: c,
        select_all: this.select_all,
        removed_contacts: r
      }
      this.axios.put(url, data, config)
        .then(response => {
          this.modals.add_group = false
          this.selectedGroup = ''
          this.getContacts()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    selectCount(){
      let count = 0
      let r_count = 0
      for(let n in this.contacts){
        if(this.contacts[n].selected == true){
          count++
        } else {
          r_count++
        }
      }
      if(this.select_all == true){
        return this.totalRows - r_count
      } else {
        return count
      }
    },
    addContactsToExistingGroupModal(){
      this.selectedGroup = ''
      this.modals.add_group = true
    },
    resetAll(){
      this.filterContacts = {
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: '',
        },
        game: {
          value: '',
          actual: ''
        }
      }
      this.getContacts()
    },
    getJSON(item){
      return JSON.parse(item)
    },
    async getGames () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameOptions = response.data
          console.log(this.gameOptions)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    selectAll(){
      for(let n in this.contacts){
        if(this.select_all == true){
          this.contacts[n].selected = true
        } else {
          this.contacts[n].selected = false
        }
      }
      this.showActionButtons()
    },
    showActionButtons(){
      let count = 0
      for(let n in this.contacts){
        if(this.contacts[n].selected == true){
          count++
        }
      }
      if(count > 0){
        this.add_button = true
      } else {
        this.add_button = false
      }
    },
    resetField(field){
      field.value = ''
      field.actual = ''
      this.getContacts()
    },
    updateContacts(){
      this.filters.first_name.actual = this.filters.first_name.value
      this.filters.last_name.actual = this.filters.last_name.value
      this.filters.email.actual = this.filters.email.value
      this.filters.game.actual = this.filters.game.value
      this.getContacts()
    },
    async downloadCSV(){
      //get the entire data set
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      var count = 1000000
      var csvData = await this.getAllContacts(config, count)
      this.loading = false

      let csv = 'First Name,Last Name,Email,Subscribed\n';
      csvData.forEach(row => {
        csv += [row.first_name, row.last_name, row.email, this.formatSubscribed(row.subscribed)]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'contacts.csv';
      anchor.click();
    },
    formatSubscribed(subscribed){
      if(subscribed === 1){
        return true
      } else {
        return false
      }
    },
    async getAllContacts(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')

      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId  + '/mail/contacts?count='
          + count + "&offset=" + offset
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.contacts)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  swal({
                    title: 'Error',
                    html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                    type: 'error',
                  }).then((response)=> {
                    this.$router.push('/')
                  })
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getContacts () {
      var count = this.perPage
      var offset = parseInt((this.currentPage * this.perPage) - this.perPage)

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/contacts?count=' + count + '&offset=' + offset +
                 "&first_name=" + encodeURIComponent(this.filters.first_name.value) +  "&last_name=" + encodeURIComponent(this.filters.last_name.value) +
                  "&email=" + encodeURIComponent(this.filters.email.value) + '&game=' + encodeURIComponent(this.filters.game.actual)

      this.axios.get(url, config)
        .then(response => {
          this.contacts = response.data.contacts
          if(this.contacts.length <= 0 ){
            this.empty = true
          } else {
            this.empty = false
          }
          this.totalRows = response.data.totalRows
          console.log('getting data')
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getEmailGroups () {

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/mail/groups'
      this.axios.get(url, config)
        .then(response => {
          this.email_groups = response.data.email_groups
          if(this.email_groups.length <= 0 ){
            this.emptyGroup = true
          } else {
            this.emptyGroup = false
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getGames()
    this.getEmailGroups()
    this.getContacts()
  }
}
</script>

<style scoped>

</style>
