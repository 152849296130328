<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
<!--    <base-button v-on:click="lightMode()">Light mode</base-button>-->
<!--    <base-button v-on:click="darkMode()">Dark mode</base-button>-->

    <div class="row">
      <div class="col">
        <card class="card-user">
          <div class="author mt-5">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <a href="javascript:void(0)">
              <img v-if="company.img != null" class="avatar" :src="company.img" alt="..." />
              <img v-if="company.img == null" class="avatar" src="https://console.offthecouch.io/img/placeholder.jpg" alt="..." />
            </a>
            <div class="row mb-4">
              <div class="col text-center">
                <span class="btn btn-primary btn-sm btn-simple btn-file">Upload Company Logo
                  <input
                    accept="image/*"
                    @change="handleCompanyLogoUpload"
                    type="file"
                    name="..."
                    class="valid"
                    :multiple="false"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>
          </div>
          <h3 slot="header" class="card-title">{{company_name}}</h3>

        </card>
      </div>
      <div class="col-lg-8">
        <card card-body-classes="table-full-width">
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + 'j'"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="activeTabIndex === 0">
            <div class="row">
              <div class="col">
                <label>Company name</label>
                <base-input
                  type="text"
                  placeholder="Company name"
                  v-model="company.name"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <label>Email</label>
                <base-input
                  type="text"
                  placeholder="Email"
                  v-model="company.email"
                >
                </base-input>
              </div>
              <div class="col-lg-5">
                <label>Phone</label>
                <base-input
                  :placeholder="company.country.phone_placeholder"
                  v-model="company.phone"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Address line 1</label>
                <base-input
                  type="text"
                  placeholder="Address line 1"
                  v-model="company.address"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Address line 2</label>
                <base-input
                  type="text"
                  placeholder="Address line 2"
                  v-model="company.address_line_2"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <base-input
                  type="text" :label="company.country.city_label"
                  :placeholder="company.country.city_label"
                  v-model="company.city"
                >
                </base-input>
              </div>
              <div class="col">
                <base-input v-if="company.country.state_label === 'State'" :label="company.country.state_label">
                  <el-select
                    v-model="company.state"
                    class="select-primary"
                    name="email_group"
                    :placeholder="company.country.state_label"
                  >
                    <el-option
                      v-for="(s, i) in states"
                      class="select-primary"
                      :label="s.name"
                      :value="s.name"
                      :key="'S' + i"
                    ></el-option>
                  </el-select>
                </base-input>
                <base-input v-else :placeholder="company.country.state_label" :label="company.country.state_label" v-model="company.country.state"></base-input>
              </div>
              <div class="col-lg-3">
                <base-input :label="company.country.zip_label"
                  type="text"
                  :placeholder="company.country.zip_placeholder"
                  v-model="company.zip"
                >
                </base-input>
              </div>
              <div class="col-3">
                <base-input label="Country">
                  <el-select
                             v-model="company.country"
                             class="select-primary"
                             name="email_group"
                             placeholder="Country"
                             value-key="code"
                  >
                    <el-option
                      v-for="(c, i) in countries"
                      class="select-primary"
                      :label="c.name"
                      :value="{name: c.name, code: c.code, city_label: c.city_label,
                      state_label: c.state_label, zip_placeholder: c.zip_placeholder, zip_label: c.zip_label,
                      phone_placeholder: c.phone_placeholder}"
                      :key="'C' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label>Social media</label>
                <base-input
                  type="text"
                  placeholder="Website"
                  addon-left-icon="fa fa-globe"
                  v-model="socials[4].link"
                >
                </base-input>
              </div>
              <div class="col-lg-6">
                <label>&nbsp;</label>
                <base-input
                  type="text"
                  placeholder="Facebook"
                  v-model="socials[0].link"
                  addon-left-icon="fab fa-facebook"

                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="text"
                  placeholder="Yelp"
                  v-model="socials[3].link"
                  addon-left-icon="fab fa-yelp"
                >
                </base-input>
              </div>
              <div class="col-lg-6">
                <base-input
                  type="text"
                  placeholder="Instagram"
                  addon-left-icon="fab fa-instagram"
                  v-model="socials[1].link"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="text"
                  placeholder="Twitter"
                  addon-left-icon="fab fa-twitter"
                  v-model="socials[2].link"
                >
                </base-input>
              </div>
              <div class="col-lg-6">
                <base-input
                  type="text"
                  placeholder="Tripadvisor"
                  v-model="socials[5].link"
                  addon-left-icon="fab fa-tripadvisor"
                >
                </base-input>
              </div>
            </div>
            <base-button v-on:click="saveCompanyData()" type="primary">
              Save changes
            </base-button>
          </div>

          <div v-if="activeTabIndex === 1">
            <div class="row justify-content-between" v-show="hasPermissions('company_group_settings_configure')">
              <div class="col text-right">
                <base-button size="sm" type="primary" v-on:click="createNewGroup()">
                  <i class="tim-icons el-icon-plus"></i>&nbsp; Create group</base-button>
              </div>
            </div>
            <!--  No content-->
            <div class="mt-3" v-if="groups.length <= 0">
              <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
                <h4 class="mt-3">No groups have been created.</h4>
              </div>
            </div>
            <div v-else class="row table-full-width">
              <div class="col table-full-width">
                <b-table striped hover :items="groups"
                         :fields="fields">

                  <template v-slot:cell(name)="row">
                    <div class="row">
                      <div class="mt-1">
                        <img class="round-img" :src="row.item.img"/>
                      </div>
                      <div class="col mt-2">
                        <span class="card-title">{{row.item.name}}</span>
                      </div>
                    </div>

                  </template>
                  <template v-slot:cell(games)="row">
                    <badge v-for="(game, index) in getGroupGames(row.item.games)" :key="index" class="mr-3" type="primary">
                      <span v-if="game != null">{{game.name}}</span>
                    </badge>
                    <span v-if="getGroupGames(row.item.games)[0] == null">-</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <span v-show="!hasPermissions('company_group_settings_configure')">-</span>
                    <span v-show="hasPermissions('company_group_settings_configure')">
                      <el-tooltip content="Edit group"
                                  effect="light"
                                  :open-delay="300"
                                  placement="top">
                      <base-button
                        @click.native="editGroup(row.item)"
                        class="like btn-link"
                        type="warning"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Delete group"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click.native="deleteGroup(row.item)"
                        class="like btn-link"
                        type="danger"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </el-tooltip>
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

<!--          API-->
          <div v-if="activeTabIndex === 2">
            <div v-if="api">
              <div class="row">
                <div class="col">
                  <p>You must include the API key in the <code>X-API-Key</code> HTTP header in each authenticated request.</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3">
                  <label>ID</label>
                  <p>
                    <i style="cursor:pointer;" v-on:click="copy(api.api_id)" class="tim-icons text-white icon-single-copy-04 mr-1"></i>
                    {{api.api_id}}
                  </p>
                </div>
                <div class="col">
                  <label>API key</label>
                  <p v-if="!show_key">
                    <i style="cursor:pointer;" v-on:click="copy(api.api_key)" class="tim-icons text-white icon-single-copy-04 ml-1"></i>
                    ******************************
                    <span style="cursor:pointer; text-decoration: underline" class="text-primary ml-2" v-on:click="show_key = true" >Show</span>
                  </p>
                  <p v-if="show_key">
                    <i style="cursor:pointer;" v-on:click="copy(api.api_key)" class="tim-icons text-white icon-single-copy-04 ml-1"></i>
                    {{ api.api_key }}
                    <span style="cursor:pointer; text-decoration: underline" class="text-primary ml-2" v-on:click="show_key = false" >Hide</span>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label>Creation date</label>
                  <p>{{getLocalDate(api.creation_date)}}, {{getLocalTime(api.creation_date)}}</p>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <base-button type="primary" v-on:click="createAPIKeyPrompt()" v-if="api">Roll key</base-button>
                <base-button type="primary" v-on:click="createAPIKey()" v-if="!api">Create key</base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../api-config'
  import swal from 'sweetalert2'
  const axios = require('axios')
  import {Modal, ImageUpload, Badge} from 'src/components'
  import {BTable} from "bootstrap-vue";
  import _ from "lodash";
  import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
  import countries from "@/assets/countries.json";
  import states from "@/assets/states.json";

  export default {
      name: "CompanySettings",
      components: {
        Modal,
        ImageUpload,
        Badge,
        BTable,
      },
      data() {
        return {
          api: '',
          show_key: false,
          loading: false,
          customColWidth: 'col-lg-8 col-12',
          time_format: JSON.parse(localStorage.getItem('group')).time_format,
          date_format: JSON.parse(localStorage.getItem('group')).date_format,
          countries: countries.countries,
          states: states.states,
          activeTabIndex: 0,
          j: 0,
          groups: '',
          fields: ['name', 'games', 'actions'],
          socials: [
            {link: '', type_id: 1},
            {link: '', type_id: 2},
            {link: '', type_id: 3},
            {link: '', type_id: 4},
            {link: '', type_id: 5},
            {link: '', type_id: 6}
          ],
          company: {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            img: '',
            phone: '',
            country:{
              "city_label": "City",
              "state_label": "State",
              "zip_label": "Zip",
              "zip_placeholder": "95133",
              "phone_placeholder": "(212) 555-1234"
            }
          },
          company_name: '',
          uploadUrl: '',
          file: '',
          group: {
            name: '',
            img: '',
          },
        }
      },
      computed: {
        categories() {
          return [{ name: 'General settings', icon: 'tim-icons icon-settings', active: true },
            { name: 'Company groups', icon: 'tim-icons icon-palette', active: false },
            { name: 'API', icon: 'tim-icons icon-bullet-list-67', active: false },
          ];
        }
      },
      methods: {
        hasPermissions(scope){
          let permissions = JSON.parse(localStorage.getItem('permissions'))
          const matchFound = permissions.some(element => element === scope);
          return matchFound
        },
        createAPIKeyPrompt(){
          swal({
            title: 'Are you sure?',
            text: `Rolling will block this API key and generate a new one`,
            type: 'warning',
            showCancelButton: true,
          }).then((response)=> {
            if(response.value == true){
              this.createAPIKey()
            }
          })
        },
        async createAPIKey(){
          this.loading = true
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/api'

          let data = {}

          axios.post(url, data, config)
            .then(response => {
              this.loading = false
              swal('Success', 'New API key created' , 'success')
              this.getCompany()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        copy(item) {
          navigator.clipboard.writeText(item);
        },
        getDayOfWeek(d){
          console.log(d)
          if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
            return dayOfWeek(d) + ', '
          }
          return ''
        },
        getLocalTime(d){
          let local = new Date(d)
          return this.getTime(local.getHours() + ':' + local.getMinutes())
        },
        getLocalDate(d){
          let local = new Date(d)
          let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
          let final = this.getFormattedDate(form)
          return this.getDayOfWeek(form) + final
        },
        getTime(d) {
          return time(this.time_format, d)
        },
        getFormattedDate(d){
          return formattedDate(this.date_format, d)
        },
        switchTab(index){
          this.activeTabIndex = index
          for(let n in this.categories){
            this.categories[n].active = false
          }
          if(this.activeTabIndex !== 0){
            this.customColWidth = 'col-lg-12'
          } else {
            this.customColWidth = 'col-lg-8 col-12'
          }
          this.categories[index].active = true
          this.j++
        },
        lightMode(){
          let docClasses = document.body.classList;
          docClasses.add('white-content');
        },
        darkMode(){
          let docClasses = document.body.classList;
          docClasses.remove('white-content');
        },
        async createNewGroup(){
          this.$router.push({ path: '/settings/group'})
        },
        async delete(group){
          this.loading = true
          var token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/groups/' + group.id

          axios.delete(url, config)
            .then(response => {
              this.getGroups()
              swal('Success!', 'Group has been deleted', 'success')
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              }
            })

        },
        deleteGroup(group){
          if(group.games == null || group.games.length <= 0 ){
            swal({
              title: 'Are you sure?',
              text: `The group will be permanently deleted`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!'
            }).then((response)=> {
              if(response.value == true){
                this.delete(group);
              }
            })
          } else {
            swal({
              title: 'The group cannot be deleted',
              text: 'You must first assign the games belonging to this group to a different group',
              type: 'error',
              showCancelButton: true,
              cancelButtonColor: '#1d8cf8',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Take me to game settings!'
            }).then((response)=> {
              if(response.value == true){
                this.$router.push('/games/settings')
              }
            })
          }

        },
        async changeDefaultGroup(group){
          var token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/groups/' + group.id + '/default'

          let data = {}

          axios.put(url, data, config)
            .then(response => {
              location.reload()
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async editGroup(group){
          let test = await this.changeDefaultGroup(group)
          localStorage.setItem('editing', 1)
          this.$router.push({ path: '/settings/group', query: { id:  group.id }})
        },
        getAuthToken() {
          return new Promise(function (resolve) {
            Auth.currentSession()
              .then(data => {
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        getGroupGames(games) {
          return JSON.parse('[' + games + ']')
        },
        async getSignedURL (file) {
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'upload'

          let payload = {
            filePath: file.name,
            contentType: file.type
          }

          return new Promise ( function(resolve) {
            axios.post(url, payload, config)
              .then((res) => {
                resolve(res.data)
              })
              .catch((err) => {
                console.error(err)
              })
          })
        },
        async saveFile() {
          if(this.file != ''){
            this.uploadUrl = await this.getSignedURL(this.file)
            let uploaded = await this.uploadFile(this.file)
            //Get the image URL
            let imgUrl = this.uploadUrl.split("?")
            this.group.img = imgUrl[0]
            this.company.img = imgUrl[0]
          }
        },
        uploadFile (file) {
          let url = this.uploadUrl
          let config = { headers: {
              'Content-Type': file.type
            }}

          let payload = file
          return new Promise ( function(resolve) {
            axios.put(url, payload, config)
              .then((res) => {
                resolve(res)
              })
              .catch((err) => {
                console.error(err)
              })
          })
        },
        handleCompanyLogoUpload(){
          let file = event.target.files[0];
          this.company.img = URL.createObjectURL(file);
          this.file = file
          this.$emit('change', file);
        },
        async getCompany() {
          this.loading = true
          var token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId

          axios.get(url, config)
            .then(response => {
              this.company = response.data.company
              this.api = response.data.api
              this.company.country = _.find(this.countries, { code: this.company.country })
              this.company_name = response.data.company.name
              if(response.data.social_media.length > 0){
                this.socials = response.data.social_media
              }
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async getGroups() {
          this.loading = true
          var token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/groups'

          axios.get(url, config)
            .then(response => {
              this.groups = response.data
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
        async saveCompanyData() {
          this.editing = false
          this.loading = true
          await this.saveFile()
          var token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId

          let data = {
            address: this.company.address,
            address_line_2: this.company.address_line_2,
            city: this.company.city,
            country: this.company.country.code,
            state: this.company.state,
            zip: this.company.zip,
            phone: this.company.phone,
            img: this.company.img,
            email: this.company.email,
            social_media: this.socials,
            name: this.company.name
          }

          axios.put(url, data, config)
            .then(response => {
              this.loading = false
              this.editing = false
              swal('Success', 'Company settings have been updated' , 'success')
              this.getCompany()
            })
            .catch(err => {
             console.log(err)
              this.loading = false
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              } else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        },
      },
      mounted () {
        this.getCompany()
        this.getGroups()
      }
    }
</script>

<style scoped>
  .round-img {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 60px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    border-radius: 60px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    float: left;
    background-size: cover;
    background-position: center center;
    margin-top: -7px;
    margin-bottom: -7px;
  }

</style>
