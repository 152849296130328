<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/games/clues/select">Hints</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Hints for {{ game.name }}</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row justify-content-between">
    <div class="col">
      <h3 slot="header" class="card-title">Hints for {{ game.name }} </h3>
    </div>
    <div class="col text-right">
      <p v-if="connected_clients">Connected screens: {{connected_clients - 1}}</p>
      <base-button size="sm" v-if="!gameConfig.auto_sync" simple :disabled="sync_disabled" v-on:click="autoSync()" class="mb-3" type="primary">
        <i class="tim-icons icon-refresh-02 mr-1"></i>Sync screens</base-button>
      <base-button size="sm" v-on:click="copyURL()" simple class="mb-3 ml-1" type="primary">
        <i class="tim-icons icon-single-copy-04 mr-1"></i> Copy hint screen link</base-button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3">
<!--      <card>-->
      <div class="row" v-if="gameConfig.play_background_music">
        <div class="col text-center">
          <base-button v-if="!background_playing" v-on:click="playBackgroundMusic()" class="mb-3 mr-1" type="primary">
            <i class="tim-icons icon-triangle-right-17 mr-1"></i> Play background music</base-button>
          <base-button v-if="background_playing" v-on:click="pauseBackgroundMusic()" class="mb-3 mr-1" type="primary">
            <i class="tim-icons icon-button-pause mr-1"></i> Pause background music</base-button>
        </div>
      </div>

      <div class="row mb-4">
        <div v-if="gameConfig.has_timer" class="col">
          <div class="row justify-content-center" v-if="!time_override">
            <div class="col text-center">
              <p style="font-size: xxx-large">{{hours}}:{{minutes}}:{{seconds}}</p>
            </div>
          </div>

          <div class="row justify-content-center" v-if="!time_override">
            <div class="col text-center">
              <base-button type="primary" icon v-on:click="startTimer()">
                <i class="tim-icons icon-triangle-right-17"></i>
              </base-button>
              <base-button icon class="ml-2" type="primary" v-on:click="pauseTimer()">
                <i class="tim-icons icon-button-pause"></i>
              </base-button>
              <base-button icon class="ml-2" type="primary" v-on:click="resetTimer()">
                <i class="tim-icons icon-refresh-01"></i>
              </base-button>
            </div>
          </div>

          <div class="row justify-content-center">
            <!--          <div class="col-3">-->
            <!--            <h4 class="card-title">Timer</h4>-->
            <!--          </div>-->
            <div class="col text-center" v-if="!time_override">
              <base-button size="sm" v-show="show_timer" type="primary" v-on:click="toggleTimer(0)">
                Hide timer
              </base-button>
              <base-button size="sm" v-show="!show_timer" type="primary" v-on:click="toggleTimer(1)">
                Show timer
              </base-button>
              <base-button size="sm" v-if="!time_override" class="ml-2" type="primary" v-on:click="time_override = true">
                Override time
              </base-button>
            </div>
          </div>


          <div class="row" v-if="time_override">
            <div class="col-10">
              <label class="text-white">New time (minutes)</label>
              <base-input  v-if="time_override" v-model="timer_value"></base-input>
            </div>
          </div>
          <div class="row" v-if="time_override">
            <div class="col">
              <base-button size="sm" type="default" v-on:click="time_override = false">
                Cancel
              </base-button>
              <base-button  size="sm" class="ml-lg-2" type="success" v-on:click="overrideTimer()">
                Save
              </base-button>
            </div>
          </div>
          <div class="row" v-if="!time_override">

          </div>
          <div class="row" v-if="!time_override">
          </div>
        </div>
      </div>
<!--      </card>-->

      <card>
        <h4 class="card-title">Send custom hints</h4>
        <div class="row">
          <div class="col">
            <base-input>
              <el-input
                v-model="custom_clue"
                :rows="5"
                type="textarea"
                placeholder="Type custom text here..."
              />
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-button size="sm"
                         @click.native="sendClue(custom_clue, 'custom')"
                         type="primary"
            >Send
            </base-button>
          </div>
        </div>
      </card>

      <card>
        <h4 class="card-title">Active hints</h4>
        <div class="row justify-content-center" v-if="gameConfig.has_clue_limit" :key="'Z' + h">
          <div class="col-2 text-left">
            <base-button  size="sm" type="default" v-on:click="removeExtraHints()">
                            <i class="tim-icons el-icon-minus"></i>
              </base-button>
          </div>

          <div class="col text-center" style="margin-left: 0px; margin-right: -200px">
            <div style="float:left" v-for="i in gameConfig.clues_used" :key="i + 'M'">
              <i :style="{'font-size': '20px'}" :class="'tim-icons ' + gameConfig.icon_style + ' text-primary'" class="ml-1"></i>
            </div>
            <div style="float:left;" v-for="j in gameConfig.clues_remaining" :key="j + 'N'">
              <p>
                <i :style="{'font-size': '20px', 'color': 'white' }" :class="'tim-icons ' + gameConfig.icon_style " class="ml-1"></i>
              </p>
            </div>
          </div>
          <div class="col text-right">
            <base-button  size="sm" type="default" v-on:click="giveExtraHints()">
              <i class="tim-icons el-icon-plus"></i>
            </base-button>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">

            <base-button  size="sm" type="primary" v-on:click="takeBackHint()">
              Remove hint</base-button>
            <base-button  size="sm" class="ml-lg-2" type="primary" v-on:click="resetHints()">
              Reset hints</base-button>
          </div>
        </div>
        <hr style="border-color: #e14eca; background-color: #e14eca">
        <div class="row mt-2" >
          <div class="col">
            <h4 class="card-title">Currently displayed</h4>
            <p v-if="!cleared && clues_sent.length > 0" >
              {{getFileNameForHints(clues_sent[clues_sent.length - 1])}}
            </p>
            <badge v-if="clues_sent
              && clues_sent.length > 0 && !cleared &&
              clues_sent[clues_sent.length - 1].type == 'video' && video_looping">
              Looping</badge>
            <p v-if="cleared || clues_sent.length <= 0">-</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <base-button size="sm" v-if="!cleared && clues_sent.length > 0" type="primary" v-on:click="clearHints()">
              <i class="tim-icons icon-refresh-01"></i> Clear hint screen</base-button>
          </div>
        </div>
      </card>


    </div>
    <div class="col">
      <card card-body-classes="table-full-width" :style="{'max-height': windowHeight + 'px', 'overflow-y': 'scroll'}">
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="activeTabIndex === 0 && empty === true">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No hint have been set up.</h4>
          </div>
        </div>
        <div v-if="activeTabIndex === 0 && empty === false" class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-12">
            <b-table  striped hover :items="puzzles"
                      :fields="puzzle_fields">
              <template #head(puzzle)="row">
                <span></span>
              </template>
              <template v-slot:cell(hints)="row">
                <div class="row">
                  <div class="col-10">
                    <p style="font-size: large" class="text-primary">{{row.item.name}}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p v-if="row.item.description.length > 0">Description: {{row.item.description}}</p>
                    <p v-if="row.item.solution.length > 0">Solution: {{row.item.solution}}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <img
                      v-on:click="expandImage(row.item.solution_img)"
                      v-if="row.item.solution_img" slot="image" style="width: 200px; border-radius: 5px; height: 150px; cursor: pointer"
                      :src="row.item.solution_img"  alt="Card image cap"/>
                  </div>
                </div>
                <span style="opacity: 0; font-size: 1px">{{k}}</span>
                <div class="row">
                  <div class="col">
                    <div
                      class="btn-group btn-group-toggle"
                      :class="'float-left'"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-sm btn-primary btn-simple" :class="{ active: row.item.expanded[0] }"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'text', 0)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Text</span>
                      </label>
                      <label :class="{ active: row.item.expanded[1] }"
                        class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'image', 1)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Image</span>
                      </label>
                      <label :class="{ active: row.item.expanded[2] }"
                        class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'audio', 2)"
                          name="options"
                          autocomplete="off"
                          checked="1"
                        />
                        <span style="font-size: medium; font-weight: lighter" >Audio</span>
                      </label>
                      <label :class="{ active: row.item.expanded[3] }"
                        class="btn btn-sm btn-primary btn-simple"
                      >
                        <input
                          type="radio"
                          v-on:click="getClue(row.item, row.index, 'video', 3)"
                          name="options"
                          autocomplete="off"
                          :checked="true"
                        />
                        <span style="font-size: medium; font-weight: lighter">Video</span>
                      </label>
                    </div>
                  </div>
                </div>
                <span>
                  <div :key="'A' + k" class="row mt-3" v-if="row.item.expanded[0] == true">

                     <div v-if="row.item.clues[0].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[0].length > 0">
                      <b-table  striped hover :items="row.item.clues[0]" :fields="['text', 'description', 'actions']">
                            <template #head(actions)="row">
                                <span></span>
                            </template>
                            <template v-slot:cell(actions)="row">
                              <div class="row justify-content-end mr-auto">
                                  <base-button
                                    @click.native="sendClue(row.item, 'text')"
                                    type="primary"
                                    size="sm"
                                  >Send
                                </base-button>
                              </div>
                            </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'B' + k" class="row" v-if="row.item.expanded[1] == true">
                    <div v-if="row.item.clues[1].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[1].length > 0">
                      <b-table striped hover :items="row.item.clues[1]" :fields="['file_name', 'thumbnail', 'description', 'actions']">
                            <template #head(actions)="row">
                                <span></span>
                            </template>
                            <template #head(thumbnail)="row">
                                <span></span>
                            </template>
                            <template v-slot:cell(file_name)="row">
                                <span>{{getFileName(row.item.file_url)}}</span>
                            </template>
                            <template v-slot:cell(thumbnail)="row">
                              <img :src="row.item.file_url">
                            </template>
                            <template v-slot:cell(actions)="row">
                              <div class="row justify-content-end mr-auto">
                                  <base-button
                                    @click.native="sendClue(row.item, 'image')"
                                    type="primary"
                                    size="sm"
                                  >Send
                                  </base-button>
                              </div>

                            </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'C' + k" class="row" v-if="row.item.expanded[2] == true">
                    <div v-if="row.item.clues[2].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[2].length > 0">
                      <b-table striped hover :items="row.item.clues[2]" :fields="['file_name', 'description', 'actions']">
                            <template #head(actions)="row">
                                <span></span>
                            </template>
                            <template #head(thumbnail)="row">
                                <span></span>
                            </template>
                            <template v-slot:cell(file_name)="row">
                                <span>{{getFileName(row.item.file_url)}}</span>
                            </template>
                            <template v-slot:cell(actions)="row">
                              <div class="row justify-content-end mr-auto">
                                  <base-button
                                    @click.native="sendClue(row.item, 'audio')"
                                    type="primary"
                                    size="sm"
                                  >Send
                                  </base-button>
                              </div>

                            </template>
                      </b-table>
                    </div>
                  </div>
                  <div :key="'D' + k" class="row" v-if="row.item.expanded[3] == true">
                    <div v-if="row.item.clues[3].length <= 0" class="col-12" >
                        <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
                          <h4 class="mt-3">No hints have been configured.</h4>
                        </div>
                    </div>
                    <div class="col" v-if="row.item.clues[3].length > 0">
                      <b-table striped hover :items="row.item.clues[3]" :fields="['file_name', 'description', 'actions']">
                            <template #head(actions)="row">
                                <span></span>
                            </template>
                            <template #head(thumbnail)="row">
                                <span></span>
                            </template>
                            <template v-slot:cell(file_name)="row">
                                <span>{{getFileName(row.item.file_url)}}</span>
                            </template>
                            <template v-slot:cell(actions)="row">
                              <div class="row justify-content-end mr-auto">
                                <base-button class="mr-2"
                                             @click.native="sendClue(row.item, 'video', 1)"
                                             type="primary"
                                             size="sm"
                                >Send on loop
                                </base-button>
                                <base-button
                                  @click.native="sendClue(row.item, 'video')"
                                  type="primary"
                                  size="sm"
                                >Send
                                </base-button>
                              </div>

                            </template>
                      </b-table>
                    </div>
                  </div>
                </span>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row" style="overflow-x: hidden" v-if="activeTabIndex === 1">
          <div v-if="clues_sent.length <= 0" class="col-12" >
            <div class="font-icon-detail">
                                <span style="font-size: 3em">
                                  <i class="fas fa-exclamation-triangle"></i>
                                </span>
              <h4 class="mt-3">No hints have been sent to players yet.</h4>
            </div>
          </div>

          <div class="col" v-if="clues_sent.length > 0">
            <div class="row">
              <div class="col">
                <base-checkbox v-on:input="getBookingSlots()" v-model="attach_to_booking">Attach to a game</base-checkbox>
              </div>
              <div class="col text-right">
                <base-button v-if="hasPermissions('download_data')" icon class="btn-link text-white" v-on:click="downloadCSV()">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
              </div>
            </div>

            <div class="row" v-if="attach_to_booking">
              <div class="col-3">
                <base-input label="Today's games" >
                  <el-select
                             v-model="selectedBooking"
                             class="select-primary"
                             name="bookings"
                             placeholder="Games"
                             value-key="id"
                  >
                    <el-option
                      v-for="(b, i) in bookings"
                      class="select-primary"
                      :label="getFormattedTime(b.start_time) + '-' + getFormattedTime(b.end_time)"
                      :value="{id: b.id}"
                      :key="'R' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-table striped hover :items="clues_sent"
                         :fields="clues_sent_fields">
                  <template v-slot:cell(hint)="row">
                    <span> {{getFileNameForHints(row.item)}}</span>
                  </template>
                  <template v-slot:cell(type)="row">
                    <badge type="primary"> {{row.item.type}}</badge>
                  </template>
                </b-table>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-button type="primary" v-on:click="saveHintLog()">Save hint log</base-button>
              </div>
            </div>
          </div>

        </div>
      </card>
    </div>
  </div>
  <modal :show.sync="modals.image"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">
      <img slot="image" style="width: 100%; height: 400px; border-radius: 5px" :src="selectedImage"  alt="Card image cap"/>
    </card>
  </modal>

</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import {BTable} from 'bootstrap-vue'
import {Badge} from 'src/components'
import {time} from '../../../plugins/dateFormatter'
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import swal from "sweetalert2";

export default {
  name: "Clues",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    Badge,
    BTable,
  },
  data() {
    return {
      background_playing: false,
      sync_disabled: false,
      attach_to_booking: false,
      selectedBooking: '',
      bookings: [],
      loading: false,
      k: 0,
      h: 0,
      load: false,
      game: '',
      clues: [],
      puzzles: [],
      empty: false,
      custom_clue: '',
      clues_sent: [],
      fields: ['puzzle','templated_hints', 'custom_hints'],
      puzzle_fields: ['hints'],
      clue_fields: ['hint', 'hint_type', 'actions'],
      clues_sent_fields: ['hint', 'type', 'timestamp'],
      expanded: [],
      expanded_text: [],
      gameConfig: '',
      cleared: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      show_timer: true,
      socket: null,
      event: '',
      time_override: false,
      timer_value: '60',
      t:0,
      totalMinutes: 0,
      hours: '00',
      seconds: '00',
      minutes: '00',
      totalSeconds: 66,
      originalTotalSeconds: 0,
      timerEnabled: false,
      temp_time: 0,
      time_elapsed: 0,
      lastTime: new Date(),
      activeTabIndex: 0,
      j: 0,
      windowHeight: 0,
      connected_clients: 0,
      timer_status: '',
      selectedImage: '',
      video_looping: false,
      modals: {
        image: false
      }
    }
  },
  computed: {
    categories() {
      return [{ name: 'Hints', icon: 'tim-icons icon-bulb-63', active: true },
        { name: 'Hint log', icon: 'tim-icons icon-note',active: false }
      ];
    },
  },
  created(){
    this.startWebSocket()
    window.addEventListener("resize", this.setLayoutCss);
  },
  beforeDestroy(){
    if (this.socket) {
      this.socket.close();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.setLayoutCss);
  },
  watch: {
    totalSeconds: {
      handler(value) {
        if(value === 0){
          this.timerEnabled = false
          this.seconds = '00'
        }

        if(this.totalSeconds > (parseInt(this.originalTotalSeconds) + 1) && this.timerEnabled){
          this.seconds = '00'
          this.minutes = '00'
          this.hours = '00'
          this.timerEnabled = false
        }

        if (value >= 0 && this.timerEnabled) {
          let date = new Date()
          date.setHours(this.hours)
          date.setMinutes(this.minutes)
          date.setSeconds(this.seconds)
          let elapsedTime = Date.now() - this.lastTime
          this.lastTime = Date.now()

          let newDate = new Date(date.valueOf() - elapsedTime)
          let temp = newDate.toTimeString().split(" ")
          let tempsplit = temp[0].split(':')

          this.hours = tempsplit[0]
          this.minutes = tempsplit[1]
          this.seconds = tempsplit[2]
          setTimeout(() => {
            this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) - 1
          }, 1000);
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          if(this.totalSeconds === -1){
            this.totalSeconds = 0
          }
          this.totalSeconds++;
        }, 1000);
      }
    },
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    expandImage(image){
      this.selectedImage = image
      this.modals.image = true
    },
    getFormattedTime(d){
      return time(this.time_format, d)
    },
    async getBookingSlots(){
      if(this.attach_to_booking == false){
        return
      }
      let day = new Date()
      day = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings?day='
        + day + `&game_id=` + this.game.id + `&type=6`

      axios.get(url, config)
        .then((res) => {
          this.bookings = res.data.bookings
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })

    },
    async saveHintLog(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/clues'

      let data = {
        clues: this.clues_sent,
        booking_slot_id: this.selectedBooking.id

      }
      axios.post(url, data, config)
        .then((res) => {
          swal('Success!', 'Hint log has been saved', 'success');
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
        })

    },
    setLayoutCss() {
      this.windowHeight = window.innerHeight - 200;
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    overrideTimer(){
      this.timerEnabled = false
      this.timer_status = 'paused'
      this.time_override = false
      const hours = Math.floor(this.timer_value / 60)
      const remainingMinutes = this.timer_value % 60
      this.gameConfig.max_time = hours + ": " + remainingMinutes + ":00"
      this.hours = ('0' + hours).slice(-2)
      this.minutes = ('0' + remainingMinutes).slice(-2)
      this.seconds = '00'
      this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
      this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2

      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": this.gameConfig.max_time,
        "type": 'new_timer_value',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    startWebSocket(){
      let id = ''
      if(localStorage.getItem("selectedGameDetailsForClues") && JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).hasOwnProperty('id')){
        id = JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id
      } else {
        id = this.$route.query.gameId
      }
      let url = 'wss://3bqa7hkquf.execute-api.us-east-1.amazonaws.com/production?gameId=' +  id
      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        //send synch message
        this.autoSync()
      };

      this.socket.onmessage = (event) => {
        console.log('Message received:', event.data);
        this.event = event.data
        this.storeClues(this.event)
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed.');
        this.socket = null
        this.startWebSocket()
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    downloadCSV(){
      let csvData = this.clues_sent
      let csv = 'Hint,Type,Timestamp\n';
      csvData.forEach(row => {
        csv += [this.escapeCSVValue(this.getFileNameForHints(row)), this.escapeCSVValue(row.type), row.timestamp]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'hint_log.csv';
      anchor.click();
    },
    copyURL(){
      navigator.clipboard.writeText('https://console.offthecouch.io/clues/display?gameId=' + JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id);
    },
    getFileName(file_name){
      let name = file_name.split('/')[file_name.split('/').length-1]
      return name
    },
    getFileNameForHints(item){
      if(item.hint == undefined){
        return
      }
      if(item.type != 'text' && item.hint.length > 0){
        let name = item.hint.split('/')[item.hint.split('/').length-1]
        return name
      } else {
        return item.hint
      }

    },
    async getClue(item, row_index, type, index){
      this.$set(item.expanded, index, !item.expanded[index])
      for(let n = 0; n < 4; n++){
        if(n !== index){
          this.$set(item.expanded, n, false)
        }

      }
      if(item.expanded[index] == true && type != 'custom'){
        // this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let gameId = this.game.id
        let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles/' + item.id + '/clues?type=' + type
        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.$set(item.clues, index, response.data.clues)
            this.k += 1
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      } else {
        this.k += 1
      }
    },
    getTime(t){
      let minutes = t.getMinutes()
      let hours = t.getHours()
      if (minutes <10){
        minutes = '0' + minutes
      }
      if (hours <10){
        hours = '0' + hours
      }
      return time(this.time_format, hours + ':' + minutes)
    },
    getJSON(item){
      return JSON.parse(item)
    },
    storeClues(msg){
      if(JSON.parse(msg).type == 'timer'){
        if(JSON.parse(msg).data == 'start'){
          this.lastTime = new Date()
          this.timerEnabled = true
        } else if(JSON.parse(msg).data == 'reset') {
          this.timerEnabled = false
          this.hours = ('0' +  parseInt(this.gameConfig.max_time.split(':')[0])).slice(-2)
          this.minutes = ('0' +  parseInt(this.gameConfig.max_time.split(':')[1])).slice(-2)
          this.seconds = ('0' +  parseInt(this.gameConfig.max_time.split(':')[2])).slice(-2)
          this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
          this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2
        } else if(JSON.parse(msg).data == 'show') {
          this.show_timer = true
        } else if(JSON.parse(msg).data == 'hide') {
          this.show_timer = false
        } else{
          //pause time
          this.timerEnabled = false
        }
        return
      }

      if(JSON.parse(msg).type != 'sync' && JSON.parse(msg).type != 'timer' && JSON.parse(msg).type != 'clear' && JSON.parse(msg).type != 'new_timer_value'
        && JSON.parse(msg).type != 'reset_hints' && JSON.parse(msg).type != 'extra_hint' && JSON.parse(msg).type != 'remove_extra_hint' && JSON.parse(msg).type != 'remove_hint'){
        this.clues_sent.push({
          type: JSON.parse(msg).type,
          timestamp: this.getTime(new Date()),
          timestamp_value: new Date(),
          hint: JSON.parse(msg).data,
          clue_id: JSON.parse(msg).clue_id
        })
        this.h++
      }


      if(JSON.parse(msg).type == 'reset_hints'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.max_clues)
        this.gameConfig.clues_used = 0
        this.h++
      }
      if(JSON.parse(msg).type == 'remove_hint'){
        if(parseInt(this.gameConfig.clues_used) > 0){
          this.gameConfig.clues_used--
          this.gameConfig.clues_remaining++
        }
        this.h++
      }
      if(JSON.parse(msg).type == 'extra_hint'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) + 1
        this.h++
      }
      if(JSON.parse(msg).type == 'remove_extra_hint'){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) - 1
        this.gameConfig.clues_remaining = this.gameConfig.clues_remaining > 0 ? this.gameConfig.clues_remaining : 0
        this.h++
      }
      this.connected_clients = JSON.parse(msg).connections
      this.sync_disabled = false
    },
    playBackgroundMusic(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'start_background_music',
        "clue_id": '',
      }
      this.background_playing = true
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    pauseBackgroundMusic(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'pause_background_music',
        "clue_id": '',
      }
      this.background_playing = false
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    takeBackHint(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'remove_hint',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    removeExtraHints(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'remove_extra_hint',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    giveExtraHints(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'extra_hint',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    resetHints(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'reset_hints',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);

    },
    clearHints(type){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": '',
        "type": 'clear',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
      this.cleared = true
      if(type === 'vid'){this.cleared = false}
    },
    toggleTimer(status){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": 'hide',
        "type": 'timer',
        "clue_id": '',
      }
      if(status === 1){
        message = {
          "action":"sendclue",
          "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
          "data": 'show',
          "type": 'timer',
          "clue_id": '',
        }
      }
      message = JSON.stringify(message)
      this.socket.send(message);
    },
    autoSync(){
      this.sync_disabled = true
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": {
          "timer" : this.hours + ':' + this.minutes + ':' + this.seconds,
          "clues_used": this.gameConfig.clues_used,
          "clues_remaining": this.gameConfig.clues_remaining,
          "timer_active": this.timerEnabled
        } ,
        "type": 'sync',
        "clue_id": '',
      }

      message = JSON.stringify(message)
      this.socket.send(message);
      if(this.gameConfig.auto_sync){
        setTimeout(this.autoSync, 1000);
      }
    },
    startTimer(){
      if(this.timer_status === 'start'){
        return
      }

      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": 'start',
        "type": 'timer',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
      this.timer_status = 'start'
    },
    resetTimer(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": 'reset',
        "type": 'timer',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
      this.timer_status = 'reset'
    },
    pauseTimer(){
      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": 'pause',
        "type": 'timer',
        "clue_id": '',
      }
      message = JSON.stringify(message)
      this.socket.send(message);
      this.timer_status = 'paused'
    },
    sendClue: async function(item, type, loop) {

      let count_as_hint = item.count_as_hint
      if(type == 'custom'){ count_as_hint = 1 }
      this.cleared = false
      let data = ''
      if(type == 'custom'){ data = this.custom_clue}
      else if(type == 'text'){ data = item.text}
      else if(type == 'video'){
        this.video_looping = loop
        this.clearHints('vid')
        data =  item.file_url
        setTimeout(() => {
          let message = {
            "action":"sendclue",
            "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
            "data": data,
            "type": type,
            "clue_id": item.id,
            "count_as_hint": count_as_hint,
            "loop": loop ? loop : 0
          }
          message = JSON.stringify(message)
          try {
            // Send message to the WebSocket server

            this.socket.send(message);
            console.log('Message sent successfully:', message);
          } catch (error) {
            console.error('Error sending message:', error);
          }

        }, 500);

        if((item.count_as_hint || type == 'custom') && this.gameConfig.clues_remaining > 0){
          this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) - 1
          this.gameConfig.clues_used = parseInt(this.gameConfig.clues_used) + 1
        }
        return
      }
      else { data = item.file_url}

      let message = {
        "action":"sendclue",
        "gameId": JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id,
        "data": data,
        "type": type,
        "clue_id": item.id,
        "count_as_hint": count_as_hint
      }
      message = JSON.stringify(message)
      try {
        // Send message to the WebSocket server

        this.socket.send(message);
        console.log('Message sent successfully:', message);
      } catch (error) {
        console.error('Error sending message:', error);
      }

      if(type == 'custom'){ this.custom_clue = ''}
      if((item.count_as_hint || type == 'custom') && this.gameConfig.clues_remaining > 0){
        this.gameConfig.clues_remaining = parseInt(this.gameConfig.clues_remaining) - 1
        this.gameConfig.clues_used = parseInt(this.gameConfig.clues_used) + 1
      }
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getPuzzles(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let gameId = this.game.id
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/puzzles'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.puzzles = response.data

          if(this.puzzles.length <= 0){
            this.empty = true
          }
          for(let n in this.puzzles){
            this.puzzles[n].clues = []
            this.puzzles[n].expanded = []
          }
          this.puzzles =  _.orderBy(this.puzzles, 'position')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGameConfigurations(){
      let gameId = JSON.parse(localStorage.getItem("selectedGameDetailsForClues")).id
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/games/' + gameId + '/clue-display-configurations'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameConfig = response.data.configurations

          this.gameConfig.clues_used = 0
          this.gameConfig.clues_remaining = parseInt(this.gameConfig.max_clues) - parseInt(this.gameConfig.clues_used)
          this.hours =  ('0' + parseInt(this.gameConfig.max_time.split(':')[0])).slice(-2)
          this.minutes = ('0' + parseInt(this.gameConfig.max_time.split(':')[1])).slice(-2)
          this.seconds = ('0' + parseInt(this.gameConfig.max_time.split(':')[2])).slice(-2)
          this.totalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds)
          this.originalTotalSeconds = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds) + 2

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async loadSocket(id){
      let url = 'wss://3bqa7hkquf.execute-api.us-east-1.amazonaws.com/production?gameId=' +  id
      this.socket = new WebSocket(url)
      console.log(this.socket)
    }
  },
  mounted(){
    // document.addEventListener("visibilitychange", this.handleVisibilityChange);
    this.setLayoutCss()
    this.game = JSON.parse(localStorage.getItem("selectedGameDetailsForClues"))
    // this.loadSocket(this.game.id)
    this.getPuzzles()
    this.getGameConfigurations()
  }

}
</script>

<style scoped>
.button-width{
  width: 200px;
}
img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>
