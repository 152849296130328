<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
<!--    Actions-->
    <div class="row mt-3">
      <div class="col">
        <card>
          <div class="row">
            <div class="col">
              <h4 class="card-title">Settings for {{template.name}}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-radio v-model="template.mode" name="0">Simple mode</base-radio>
              <base-radio v-model="template.mode" name="1">Advanced mode</base-radio>
            </div>
          </div>
          <div class="row mt-3" v-if="template.mode == 1">
            <div class="col">
              <label>Optional steps</label>
              <base-checkbox v-model="template.include_puzzles_step">
                Puzzles
                <el-popover trigger="hover"
                            placement="right">
                  <div>
                    <div class="popover-body">Tracking of puzzle solve times, hints, breakages, and more.</div>
                  </div>
                  <base-button style="margin-top: -5px; margin-bottom: -5px" class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons icon-alert-circle-exc"></i>
                  </base-button>
                </el-popover>
              </base-checkbox>
              <base-checkbox :disabled="photos_not_enabled" v-model="template.include_photos_step">
                <span>Photo</span>
                <el-popover trigger="hover"
                            placement="right">
                  <div>
                    <div class="popover-body">Enables you to attach a photo to the game tracker form before completing it.</div>
                  </div>
                  <base-button style="margin-top: -5px; margin-bottom: -5px" class="like btn-link"
                               type="info"
                               size="sm"
                               icon
                               slot="reference">
                    <i class="tim-icons icon-alert-circle-exc"></i>
                  </base-button>
                </el-popover>
              </base-checkbox>
            </div>
          </div>
          <div class="row mt-3" v-if="template.mode == 1 && photos_not_enabled">
            <div class="col">
              <base-button simple size="sm" type="danger" v-on:click="goToBilling()" v-if="hasPermissions('billing_view')">
                <i class="tim-icons mr-1 icon-alert-circle-exc"></i> Enable photo feature for a full experience
              </base-button>
              <p class="text-danger" v-else><i class="tim-icons mr-1 icon-alert-circle-exc"></i> Please contact a site administrator to activate photos feature.</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <base-button type="primary" v-on:click="updateTracker()">Update settings</base-button>
            </div>
          </div>



        </card>
      </div>
    </div>

  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import { Badge } from 'src/components';
import _ from "lodash";

export default {
  name: "GameTrackerSettingsTemplate",
  components: {
    Badge
  },
  data() {
    return {
      photos_not_enabled: false,
      group: '',
      loading: false,
      template: {
        mode: 0,
        include_puzzles_step: false,
        include_photos_step: false
      }
    }
  },
  methods: {
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async updateTracker(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let settingsId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/game-tracker-settings/' + settingsId

      let data = this.template

      axios.put(url, data, config)
        .then(response => {
          swal('Success', 'Game tracker settings have been updated', 'success')
          this.getGameTracker()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGameTracker() {
      let settingsId = this.$route.query.id
      if(!localStorage.getItem('editing')){
        settingsId = JSON.parse(localStorage.getItem('group')).game_tracker_settings_id
        this.$router.push({ query: { id:  settingsId } });
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/game-tracker-settings/' + settingsId

      axios.get(url, config)
        .then(response => {
          localStorage.removeItem('editing')
          this.template = response.data.game_tracker_settings
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  async mounted(){
    if(!(_.find(JSON.parse(localStorage.getItem('features')), {id: 5}))){
      this.photos_not_enabled = true
    } else {
      this.photos_not_enabled = false
    }
    this.group = JSON.parse(localStorage.getItem('group'))
    this.getGameTracker()
  }
}
</script>

<style scoped>

</style>
