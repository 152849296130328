<template>
<span>
  <loading-panel :loading="loading"></loading-panel>
  <el-tooltip v-if="edit"
    content="Edit image"
    effect="light"
    :open-delay="300"
    placement="top"
  >
                <base-button :size="size"
                  @click="beginEdit(edited_image)" icon class="btn btn-link" type="warning">
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </el-tooltip>
  <base-button :size="size" v-else type="primary" @click="uploadNew()">{{text}}</base-button>
  <modal :show.sync="open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white"
          class="border-0 mb-0">

      <div class="row" >
        <div class="col">
          <h4 class="card-title mt-2">Upload image</h4>
        </div>
        <div class="col text-right" v-if="image">
          <base-button class="btn btn-link text-primary card-title" link v-on:click="clearImage()">Upload a new image</base-button>
        </div>
      </div>

      <div id="image-container"
           v-if="image" style="border-radius: 5px; width: 100%; height: 250px; overflow: hidden; position: relative;"
           :style="{'background-color': image_background_color}"
           v-on:drop="dropHandler"
           v-on:dragover="dragOverHandler">
        <img id="img" draggable="true" v-on:dragstart="dragStartHandler"
             :style="{'transform': 'translate(0%, 0%) scale(' + image_size*0.01 + ')', 'object-fit': object_fit, 'height': '100%'}"
             v-if="image" class="card-img"
             style="position: absolute; cursor: move; transform-origin: center center; will-change: transform"
             slot="image" :src="image"  alt="Card image cap"/>

      </div>
      <div class="row justify-content-center">
        <div class="col text-center">
          <el-upload v-if="!image"
                     class="upload-demo mt-5 mb-5"
                     drag :on-change="previewFile"
                     v-model="file"
                     action=""
                     multiple
          >
            <i class="tim-icons el-icon-upload"></i>
            <div class="el-upload__text">
              Drop file here or <em class="text-primary">click to upload</em>
            </div>
            <template #file>
              <div class="upload__tip">
                This is where file goes
              </div>
            </template>
          </el-upload>
        </div>
      </div>

      <div class="row mt-3" v-if="image">
        <div class="col">
          <base-input label="Description">
            <el-input
              v-model="description"
              :rows="2"
              type="textarea"
              placeholder="Description"
            />
          </base-input>
        </div>
      </div>

      <div class="row mt-3" v-if="image">
        <div class="col">
          <base-input label="Background color">
            <el-color-picker v-model="image_background_color" show-alpha></el-color-picker>
          </base-input>
        </div>
        <div class="col">
          <base-input label="Image fit">
            <el-select
              v-model="object_fit"
              class="select-primary"
              v-on:change="setFit()"
            >
              <el-option
                v-for="option in fitOptions"
                class="select-primary"
                :label="option"
                :value="option"
                :key="option"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col" v-if="show_slider">
          <base-input label="Image scale">
            <el-slider :show-tooltip="false" v-model="image_size" />
          </base-input>
        </div>
      </div>


      <div class="row text-center">
        <div class="col">
          <base-button type="secondary" @click="cancelModal()">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="handleSave">Save</base-button>
        </div>

      </div>

    </card>
  </modal>
</span>
</template>

<script>
import {Upload, Slider, ColorPicker } from 'element-ui';
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
export default {
  name: "ImageEditor",
  components: {
    [Upload.name]: Upload,
    [Slider.name]: Slider,
    [ColorPicker.name]: ColorPicker,
  },
  props:{
    edit: Boolean,
    edited_image: Object,
    text: {
      default: "Upload new image",
    },
    size: '',
    show_slider: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      file: '',
      uploadUrl: '',
      image: '',
      image_id: '',
      image_size: 100,
      image_background_color: '#e8e7ec',
      isOpen: this.open,
      loading: false,
      open: false,
      draggedElement: null,
      position: {
        x: 0,
        y: 0
      },
      description: '',
      object_fit: 'cover',
      image_height: 200,
      fitOptions: ['cover', 'fill', 'contain', 'scale-down', 'none']
    };
  },
  methods: {
    beginEdit(image){
      this.object_fit = image.object_fit
      this.position.x = image.x
      this.position.y = image.y
      this.description = image.description
      this.image_background_color = image.background_color
      this.image_size = image.scale
      this.image = image.url
      this.uploadUrl = image.url
      this.image_id = image.id
      this.open = true
    },
    setFit(){
      // if(this.object_fit === 'fill'){
      //   this.image_height = '100%'
      // }
      // let img = document.getElementById("img");
      //
      // console.log(img.height,           img.width);
      // console.log(img.naturalHeight,    img.naturalWidth);
    },
    dropHandler(event){
      event.preventDefault();

      if (this.draggedElement) {
        // Get the mouse coordinates
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        // Get the position of the parent container
        const containerRect = document.getElementById('image-container').getBoundingClientRect();

        // Calculate the new position relative to the container
        const newPositionX = mouseX - containerRect.left - this.draggedElement.width / 2;
        const newPositionY = mouseY - containerRect.top - this.draggedElement.height / 2;

        // Set the new position
        this.draggedElement.style.position = 'absolute';
        this.draggedElement.style.left = newPositionX + 'px';
        this.draggedElement.style.top = newPositionY + 'px';
        this.position = { x: newPositionX, y: newPositionY}

        // Reset draggedElement to null
        this.draggedElement = null;
      }
    },
    dragStartHandler(event){
      this.draggedElement = event.target;
    },
    dragOverHandler(event){
      event.preventDefault();
    },
    cancelModal(){
      this.clearImage()
      this.open = false
      this.$emit('closed');
    },
    uploadNew(){
      this.open = true
      this.clearImageFully()
      this.$emit('clickUpload');
    },
    clearImageFully(){
      this.image = ''
      this.file = ''
      this.uploadUrl = ''
      this.image_id = ''
      this.image_size = 100,
      this.image_background_color = '#e8e7ec'
      this.position = {
        x: 0,
        y: 0
      }
      this.description = ''
      this.object_fit = 'cover'
      this.image_height = 200
    },
    clearImage(){
      this.image = ''
      this.file = ''
    },
    previewFile(file){
      this.file = file.raw
      this.image = URL.createObjectURL(file.raw);
      this.$emit('change', file);
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async saveFile() {
      if(this.file != ''){
        this.uploadUrl = await this.getSignedURL(this.file)
        let uploaded = await this.uploadFile(this.file)
        //Get the image URL
        let imgUrl = this.uploadUrl.split("?")
        this.uploadUrl = imgUrl[0]
      }
    },
    async handleSave() {
      this.loading = true
      await this.saveFile()
      this.loading = false
      this.open = false
      let image = {
        file : this.image,
        scale: this.image_size,
        background_color: this.image_background_color,
        description: this.description,
        url: this.uploadUrl,
        x: this.position.x,
        y: this.position.y,
        object_fit: this.object_fit,
        image_id: this.image_id
      }
      this.$emit('saveImage', image);
    }
  }
}
</script>

<style scoped>

</style>
