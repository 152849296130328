<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/customers/all?count=10&page=1">Customers</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{ customer.first_name }} {{ customer.last_name }}</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col-lg-3 col-12">
        <card class="card-user">
          <p class="card-text"></p>
          <div class="author mt-5">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <a href="javascript:void(0)">
              <img v-if="customer.img != null" class="avatar" :src="customer.img" alt="..." />
              <img v-if="customer.img == null" class="avatar" src="https://console.offthecouch.io/img/placeholder.jpg" alt="..." />
              <h4 class="title">{{customer.first_name}} {{customer.last_name}}</h4>
            </a>
          </div>
          <p></p>
          <div class="card-description text-center mx-3 mb-4">
            <p>
              <i v-if="customer.email != null" class="tim-icons icon-email-85"></i>
              {{ customer.email }}
            </p>
            <p>
              <i v-if="customer.phone != null && customer.phone != ''" class="tim-icons el-icon-mobile-phone"></i>
              {{ customer.phone }}
            </p>
            <p>
              <i v-if="customer.address_line_1 != '' || customer.address_line_2 != ''" class="tim-icons el-icon-location"></i>
              {{ customer.address_line_1 }} <span v-if="customer.address_line_1 != '' && customer.address_line_2 != ''">,</span>
              {{ customer.address_line_2 }}
            </p>
            <p>{{customer.city}} {{customer.state}} {{customer.zip}} {{getCountry()}}</p>
            <p v-if="customer.birthday && !customer.birthday.includes('0000')" class="text-warning">
              <i class="tim-icons icon-gift-2"></i> {{getFormattedDate(customer.birthday)}}
            </p>
          </div>
          <span v-for="(t, i) in tags" :key="'TT' + i" class="mr-2">
                <badge type="warning">{{ t.name }}</badge>
          </span>
          <div class="row mb-3">
            <div class="col">
              <base-checkbox disabled v-model="company_customer.opted_out_email">
                <span class="text-white">Opted out of email communication</span></base-checkbox>
              <base-checkbox disabled v-model="company_customer.opted_out_photo_sharing">
                <span class="text-white">Opted out of photo sharing on social media</span>
              </base-checkbox>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label>Date joined</label>
              <p>{{ getLocalDate(company_customer.date_joined) }}</p>
            </div>
            <div class="col">
              <label>Date last visited</label>
              <p>{{ getLocalDate(company_customer.date_last_visited) }}</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-9" :key="j + 'O'">
        <card style="min-height: 150px; word-break: break-word">
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label style="padding-left: 8px; padding-right: 8px"
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>

<!--          Customer details-->
          <div class="row" v-if="activeTabIndex === 0">
            <div class="col">
              <div class="row">
                <div class="col">
                  <base-alert type="primary">

                    <p class="font-weight-bold text-uppercase">
                      <i class="tim-icons el-icon-info mr-2"></i>Note</p>When you update customer information, it will only be visible within the scope of your company.
                    Information set by customer in their profile will remain unchanged.
                  </base-alert>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-12">
                  <base-input v-model="company_customer.first_name" label="First name" placeholder="First name"></base-input>
                </div>
                <div class="col-lg-4 col-12">
                  <base-input v-model="company_customer.last_name" label="Last name" placeholder="Last name"></base-input>
                </div>
                <div class="col-lg-4 col-12">
                  <base-input label="Birthday" :error="errs.birthday">
                    <el-date-picker placeholder="Birthday"
                      v-model="company_customer.birthday"
                      :format="day_of_week + '' + date_format_cal"
                    ></el-date-picker>
                  </base-input>
<!--                  <base-input :error="errs.birthday" v-mask="'##/##/####'" v-model="company_customer.birthday" label="Date of birth" :placeholder="placeholder_dob"></base-input>-->
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-12">
                  <base-input v-model="company_customer.email" label="Email" disabled placeholder="Email"></base-input>
                </div>
                <div class="col-lg-6 col-12">
                  <base-input v-model="company_customer.phone" label="Phone" :placeholder="company_customer.country.phone_placeholder"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-input v-model="company_customer.address_line_1" label="Address line 1" placeholder="Address line 1"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-input v-model="company_customer.address_line_2" label="Address line 2" placeholder="Address line 2"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-12">
                  <base-input v-model="company_customer.city" :label="company_customer.country.city_label" :placeholder="company_customer.country.city_label"></base-input>
                </div>
                <div class="col-lg-3 col-12">
                  <base-input v-if="company_customer.country.state_label === 'State'" :label="company_customer.country.state_label">
                    <el-select
                      v-model="company_customer.state"
                      class="select-primary"
                      name="email_group"
                      :placeholder="company_customer.country.state_label"
                    >
                      <el-option
                        v-for="(s, i) in states"
                        class="select-primary"
                        :label="s.name"
                        :value="s.name"
                        :key="'S' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                  <base-input v-else :placeholder="company_customer.country.state_label" :label="company_customer.country.state_label"
                              v-model="company_customer.state"></base-input>
                </div>
                <div class="col-lg-2 col-12">
                  <base-input v-model="company_customer.zip" :label="company_customer.country.zip_label"
                              :placeholder="company_customer.country.zip_placeholder"></base-input>
                </div>
                <div class="col">
                  <base-input label="Country">
                    <el-select
                               v-model="company_customer.country"
                               class="select-primary"
                               name="email_group"
                               placeholder="Country"
                               value-key="code"
                    >
                      <el-option
                        v-for="(c, i) in countries"
                        class="select-primary"
                        :label="c.name"
                        :value="{name: c.name, code: c.code, city_label: c.city_label,
                      state_label: c.state_label, zip_label: c.zip_label, zip_placeholder: c.zip_placeholder,
                      phone_placeholder: c.phone_placeholder}"
                        :key="'C' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <base-button v-on:click="updateCustomer()" type="primary">
                    Update customer details
                  </base-button>
                </div>
              </div>
            </div>
          </div>

<!--          Games-->
          <div v-if="activeTabIndex === 1 && games.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No games have been completed.</h4>
            </div>
          </div>
          <div class="row" v-if="activeTabIndex === 1 && games.length > 0">
            <div class="col d-block d-sm-none">
              <b-table :items="games" :fields="['game']">
                <template v-slot:cell(game)="row">
                  <div class="card-title text-primary" style="font-size: medium">{{row.item.name}}</div>
                  <div>
                    <i class="tim-icons icon-calendar-60"></i>
                    {{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}
                    <i class="tim-icons ml-2 icon-time-alarm"></i>
                    {{getTime(row.item.start_time)}}
                  </div>

                  <div class="mt-2">
                    <small><span class="text-primary">Completion time:</span>  {{getCompletionTime(row.item)}}</small>
                  </div>
                  <div class="mt-1">
                    <small><span class="text-primary">Number of hints:</span>  {{row.item.number_of_hints}}</small>
                  </div>
                  <div v-if="row.item.pre_game_notes">
                    <small><span class="text-primary">Pre-game notes:</span>
                      {{truncatedText(row.item.pre_game_notes)}}
                        <a href="#" style="font-size: large" v-if="isTextLong(row.item.pre_game_notes)" v-on:click="setNotes(row.item.pre_game_notes)">...</a>

                    </small>
                  </div>
                  <div v-if="row.item.post_game_notes">
                    <small><span class="text-primary">Post-game notes:</span>
                      {{truncatedText(row.item.post_game_notes)}}
                      <a href="#" style="font-size: large" v-if="isTextLong(row.item.post_game_notes)" v-on:click="setNotes(row.item.post_game_notes)">...</a>

                    </small>
                  </div>
                  <div class="mt-3">
                    <base-button
                      @click="viewDetails(row.item)"
                      simple style="width: 100%"
                      type="primary"
                      size="sm"
                    >View game history
                    </base-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col d-none d-sm-block">
              <b-table :items="games" :fields="gameFields">
                <template v-slot:cell(game)="row">
                  <span>{{row.item.name}}</span>
                </template>
                <template v-slot:cell(booking)="row">
                  <div>{{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}</div>
                   <div>{{getTime(row.item.start_time)}}</div>
                </template>
                <template v-slot:cell(completion_time)="row">
                  <span>{{getCompletionTime(row.item)}}</span>
                </template>
                <template v-slot:cell(hints)="row">
                  <span>{{row.item.number_of_hints}}</span>
                </template>
                <template v-slot:cell(notes)="row">
                  <span v-if="!row.item.pre_game_notes && !row.item.post_game_notes">-</span>
                  <div v-if="row.item.pre_game_notes">
                    <label>Pre-game</label>
                    <p>{{truncatedText(row.item.pre_game_notes)}}
                      <a href="#" style="font-size: large" v-if="isTextLong(row.item.pre_game_notes)" v-on:click="setNotes(row.item.pre_game_notes)">...</a>
                    </p>
                  </div>
                  <div v-if="row.item.post_game_notes">
                    <label>Post-game</label>
                    <p v-on:>{{truncatedText(row.item.post_game_notes)}}
                    <a href="#" style="font-size: large" v-if="isTextLong(row.item.post_game_notes)" v-on:click="setNotes(row.item.post_game_notes)">...</a>
                    </p>
                  </div>
                  <modal :show.sync="modals.notes"
                         body-classes="p-0"
                         modal-classes="modal-dialog-centered">
                    <card type="secondary"
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-4 py-lg-4s"
                          class="border-0 mb-0">

                      <p class="mt-3 text-white">{{selectedNote}}</p>
                      <div class="row mt-3 justify-content-center">
                        <div class="col text-center">
                          <base-button v-on:click="modals.notes = false" type="primary">Ok</base-button>
                        </div>
                      </div>
                    </card>
                  </modal>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item" target="_blank" :href="'/games/history/instance?id=' + row.item.id">
                          <i class="tim-icons text-success icon-puzzle-10 mr-2"></i> View game details
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>

<!--          Transactions-->
          <div v-if="activeTabIndex === 2 && transactions.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No transactions have been made.</h4>
            </div>
          </div>
          <div class="row" v-if="activeTabIndex === 2 && transactions.length > 0">
            <div class="col d-block d-sm-none">
              <b-table :items="transactions" :fields="['transaction']">
                <template v-slot:cell(transaction)="row">
                  <p style="font-size: medium" class="text-white">Order #{{row.item.order_number}}
                    <el-tooltip
                      content="View transaction details"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <i style="cursor: pointer" v-on:click="viewTransactionDetails(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                    </el-tooltip>
                  </p>
                  <small style="font-style: italic">Purchased by {{row.item.first_name}} {{row.item.last_name}}
                    on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>

                  <div v-if="row.item.bookings" v-for="(b, i) in JSON.parse(row.item.bookings)" :key="'BB' + i">
                    <p class="text-white mt-2">{{b.game_name}}</p>
                    <small style="font-style: italic"><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(b.booking_date)}} {{getFormattedDate(b.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(b.start_time)}}</small>
                  </div>
                  <small v-if="row.item.custom_field_count > 0">
                    {{row.item.custom_field_count}} responses from the booking site
                    <el-tooltip
                      content="View customer responses"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <i style="cursor: pointer" v-on:click="viewCustomFields(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                    </el-tooltip>
                  </small>
                </template>
              </b-table>
            </div>
            <div class="col d-none d-sm-block">
              <b-table :items="transactions" :fields="['transaction_details', 'bookings', 'additional_information']">
                <template v-slot:cell(bookings)="row">
                  <div v-if="row.item.bookings" v-for="(b, i) in JSON.parse(row.item.bookings)" :key="'BB' + i">
                    <p class="text-white mt-2">{{b.game_name}}</p>
                    <small style="font-style: italic"><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(b.booking_date)}} {{getFormattedDate(b.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(b.start_time)}}</small>
                  </div>
                  <div v-if="!row.item.bookings">-</div>
                </template>
                <template v-slot:cell(additional_information)="row">
                  <small v-if="row.item.custom_field_count > 0">
                    {{row.item.custom_field_count}} responses from the booking site
                    <el-tooltip
                      content="View customer responses"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <i style="cursor: pointer" v-on:click="viewCustomFields(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                    </el-tooltip>
                  </small>
                  <small v-else>-</small>
                </template>
                <template v-slot:cell(transaction_details)="row">
                  <p style="font-size: medium" class="text-white">Order #{{row.item.order_number}}
                    <el-tooltip
                      content="View transaction details"
                      effect="light"
                      :open-delay="300"
                      placement="right"
                    >
                      <i style="cursor: pointer" v-on:click="viewTransactionDetails(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                    </el-tooltip>
                  </p>
                  <small style="font-style: italic">Purchased by {{row.item.first_name}} {{row.item.last_name}}
                    on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                </template>
              </b-table>
            </div>
          </div>
          <modal :show.sync="modals.custom_fields"
                 body-classes="p-0"
                 modal-classes="modal-dialog-centered modal-lg">
            <card type="secondary"
                  header-classes="bg-white pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="border-0 mb-0">

              <h4>Customer responses</h4>
              <div class="row">
                <div class="col">
                  <b-table :items="customer_responses" :fields="['custom_field', 'response', 'timestamp']">
                    <template v-slot:cell(custom_field)="row">
                      {{row.item.label}}
                      <span  v-if="row.item.type === 1">
                      <span v-if="row.item.external_links" v-for="(el, i) in JSON.parse(row.item.external_links)" :key="'ELI' + i">
                        <a target="_blank" :href="el.link">{{el.link_text}}</a>
                      </span>
                      <span v-if="row.item.policies" v-for="(p, i) in JSON.parse(row.item.policies)" :key="'PLI' + i">
                        <base-button style="color: #ba54f5; font-weight:300; font-size: small; padding: 0"
                                     size="sm" link class="btn btn-link" href="" v-on:click="openPolicy(p)">{{p.policy_link_text}}</base-button>
                      </span>
                      <modal :show.sync="modals.policy"
                             body-classes="p-0"
                             modal-classes="modal-dialog-centered">
                        <card type="secondary"
                              header-classes="bg-white pb-5"
                              body-classes="px-lg-4 py-lg-4s"
                              class="border-0 mb-0">

                            <p class="text-white" v-html="selectedPolicy"></p>
                            <div class="row mt-3 justify-content-center">
                              <div class="col text-center">
                                <base-button v-on:click="modals.policy = false" type="primary">I agree</base-button>
                              </div>
                            </div>
                          </card>
                        </modal>
                      </span>
                    </template>
                    <template v-slot:cell(response)="row">
                      <span v-if="row.item.type === 1 && !row.item.response">No</span>
                      <span v-if="row.item.type === 1 && row.item.response">Yes</span>
                      <span v-if="row.item.type !== 1">{{row.item.response}}</span>
                    </template>
                    <template v-slot:cell(timestamp)="row">
                      {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row text-center mt-3">
                <div class="col">
                  <base-button type="secondary" @click="modals.custom_fields = false">Ok</base-button>
                </div>
              </div>
            </card>
          </modal>

<!--          Customer credit-->
          <div class="row mb-2" v-show="activeTabIndex === 3">
            <div class="col">
              <base-button size="sm" type="primary" v-on:click="newCustomerCredit()">
                <i class="tim-icons el-icon-plus"></i> Add customer credit</base-button>
            </div>
          </div>

          <div v-show="activeTabIndex === 3 && customer_credits.length > 0" class="row" >
            <div class="col d-block d-sm-none">
              <b-table :items="customer_credits" :fields="['customer_credit']">
                <template v-slot:cell(customer_credit)="row">
                  <h4 class="card-title">{{getCurrencySymbol()}}{{getNumberFormat(row.item.total.toFixed(2))}} {{getCurrencyCode()}}</h4>
                  <small>{{row.item.description}}</small>
                  <div class="mt-2">Spent: {{getCurrencySymbol()}}{{getNumberFormat(row.item.spent.toFixed(2))}} {{getCurrencyCode()}}</div>
                  <div class="mt-2">Remaining: {{getCurrencySymbol()}}{{getNumberFormat(row.item.remaining.toFixed(2))}} {{getCurrencyCode()}}</div>
                  <div class="mt-2">
                    <span>Created by {{row.item.first_name}} {{row.item.last_name}}</span><br/>
                    <small v-if="row.item.timestamp" class="font-italic">{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                  </div>
                  <div class="mt-2">
                    <base-button
                      @click="editCustomerCredit(row.item)"
                      simple
                      type="warning"
                      size="sm"
                      style="width: 100%"
                    > <i class="tim-icons mr-2 icon-pencil"> </i> Edit
                    </base-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col d-none d-sm-block">
              <b-table :items="customer_credits" :fields="customerCreditFields">
                <template v-slot:cell(credits)="row">
                  <h4 class="card-title">{{getAmount(row.item.total)}}</h4>
                  <small>{{row.item.description}}</small>
                </template>
                <template v-slot:cell(spent)="row">
                  <h4>{{getAmount(row.item.spent)}}
                  </h4>
<!--                  <el-tooltip-->
<!--                    content="View "-->
<!--                    effect="light"-->
<!--                    :open-delay="300"-->
<!--                    placement="right"-->
<!--                  >-->
<!--                    <i class="tim-icons el-icon-top-right text-primary"></i>-->
<!--                  </el-tooltip>-->
                </template>
                <template v-slot:cell(remaining)="row">
                  <h4>{{getAmount(row.item.remaining)}}</h4>
                </template>
                <template v-slot:cell(created_by)="row">
                  <span>{{row.item.first_name}} {{row.item.last_name}}</span><br/>
                  <small v-if="row.item.timestamp" class="font-italic">{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-tooltip content="Delete customer credit" v-if="row.item.spent === 0"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click="deleteCustomerCredit(row.item)"
                      simple
                      type="danger"
                      link
                    > <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Edit customer credit"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click="editCustomerCredit(row.item)"
                      simple
                      type="warning"
                      link
                    > <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </b-table>
            </div>
          </div>
          <div class="row" v-if="activeTabIndex === 3">
            <div class="col-lg-2">
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Total credits</p>
                      <h2 class="card-title">{{ getTotalCredits() }}</h2>
                    </slot>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Total spent</p>
                      <h2 class="card-title">{{ getSpentCredits() }}</h2>
                    </slot>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <hr style="border: 1px solid #e14eca">
              <div class="row card-stats">
                <div class="col">
                  <div class="numbers">
                    <slot>
                      <p class="card-category">Total remaining</p>
                      <h2 class="card-title">{{ getRemainingCredits() }}</h2>
                    </slot>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 3 && customer_credits.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No customer credits has been created yet.</h4>
            </div>
          </div>

<!--          Gift cards-->
          <div v-if="activeTabIndex === 4">
            <div class="mt-3" v-if="gift_cards.length <= 0">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No gift purchases have been made.</h4>
              </div>
            </div>

            <div class="row d-sm-block d-none" style="word-break: break-all">
              <div class="col">
                <b-table v-if="gift_cards.length > 0" striped hover :items="gift_cards"
                         :fields="['gift_card_code', 'recipient', 'value', 'purchase_date', 'delivery_date', 'expiration_date', 'actions']">
                  <template v-slot:cell(gift_card_code)="row">
                    <p class="mt-2" style="font-weight: lighter; font-size: small">{{getGiftCardCode(row.item.code)}}</p>
                    <badge v-if="row.item.is_active" type="success">active</badge>
                    <badge v-if="!row.item.is_active" type="danger">deactivated</badge>
                  </template>
                  <template v-slot:cell(recipient)="row">
                    <div class="row" v-for="(r, i) in JSON.parse(row.item.recipients)" :key="'A' + i">
                      <div class="col">
                        <p>{{r.first_name}} {{r.last_name}}
                          <el-tooltip content="View customer"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                          </el-tooltip>
                        </p>
                        <small>{{r.email}}</small>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(value)="row">
                    <p>Total: {{ getAmount(row.item.total) }}</p>
                    <p>Remaining: {{ getAmount(row.item.remaining) }}</p>
                  </template>

                  <template v-slot:cell(purchase_date)="row">
                    {{getLocalDate(row.item.date_purchased)}}, {{getLocalTime(row.item.date_purchased)}}
                  </template>
                  <template v-slot:cell(delivery_date)="row">
                    {{ getDeliveryDate(row.item.delivery_date, row.item.date_purchased) }}
                  </template>
                  <template v-slot:cell(expiration_date)="row">
                    <badge v-if="!row.item.expiration_date">does not expire</badge>
                    <span v-if="row.item.expiration_date">{{ getFormattedDate(row.item.expiration_date) }}</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item" @click="sendGiftCardEmailPrompt(row.item)" href="#">
                            <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Resend delivery email
                          </a>
                          <a class="dropdown-item" @click="viewTransaction(row.item)" href="#">
                            <i class="tim-icons text-info icon-notes mr-2"></i> View transaction
                          </a>
                          <hr>
                          <a class="dropdown-item" @click="deactivateGiftCardPrompt(row.item)" href="#">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Deactivate gift card
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row d-sm-none d-block">
              <div class="col">
                <b-table v-if="gift_cards.length > 0" striped hover :items="gift_cards"
                         :fields="['gift_cards']">
                  <template v-slot:cell(gift_cards)="row">
                    <div class="row justify-content-between">
                      <div class="col">
                        <p style="font-weight: lighter; font-size: small">{{getGiftCardCode(row.item.code)}}</p>
                      </div>
                      <div class="col text-right">
                        <badge v-if="row.item.is_active" type="success">active</badge>
                        <badge v-if="!row.item.is_active" type="danger">deactivated</badge>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>{{row.item.first_name}} {{row.item.last_name}}
                          <el-tooltip content="View customer"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                          </el-tooltip>
                        </p>

                        <small><i class="tim-icons icon-email-85 mr-1"></i>  {{row.item.email}}</small>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <small class="text-white">
                          Purchase date: {{getLocalDate(row.item.date_purchased)}}, {{getLocalTime(row.item.date_purchased)}}
                        </small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <small class="text-white">
                          Delivery date: {{ getDeliveryDate(row.item.delivery_date, row.item.date_purchased) }}
                        </small>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col">
                        <small class="text-white">
                          Total:  {{ getAmount(row.item.total) }}
                        </small>
                      </div>
                      <div class="col text-right">
                        <small class="text-white">
                          Remaining: {{ getAmount(row.item.remaining) }}
                        </small>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col">
                        <div
                          class="btn-group btn-group-toggle mt-1 mb-2"
                          :class="'float-left'"
                          data-toggle="buttons" style="width:100%"
                        >
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="deactivateGiftCardPrompt(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="viewTransaction(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-notes"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="sendGiftCardEmailPrompt(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-email-85"></i>
                            </base-button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
<!--          Waivers-->
          <div v-if="activeTabIndex === 5">
            <div class="row mb-2" v-if="minors.length > 0">
              <div class="col">
                <base-button v-if="!showMinorTable" v-on:click="showMinorTable = true" type="primary" size="sm">Show associated child waivers</base-button>
                <base-button v-if="showMinorTable" v-on:click="showMinorTable = false" type="primary" size="sm">Show customer waivers</base-button>
              </div>
            </div>
            <div v-if="waivers.length <= 0" class="mt-3" >
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No waiver have been signed.</h4>
              </div>
            </div>
            <div class="row" v-if="minors.length > 0 && showMinorTable">
              <div class="col d-block d-sm-none">
                <b-table :items="minors" :fields="['waiver']">
                  <template v-slot:cell(waiver)="row">
                    <div class="row">
                      <div class="col-7">
                        <p style="font-size: medium" class="text-primary">{{row.item.first_name}} {{row.item.last_name}}</p>
                        <span>{{row.item.game_name}}</span>
                      </div>
                      <div class="col text-right">
                        <badge v-if="row.item.status == 'completed'" type="success">completed</badge>
                        <badge v-if="row.item.status == 'checked'" type="warning">checked in</badge>
                        <badge v-if="row.item.status == 'call_to_book'" type="warning">checked in</badge>
                        <badge v-if="row.item.status == 'blocked'" type="danger">checked in</badge>
                        <badge v-if="row.item.status == 'cancelled'" type="danger">cancelled</badge>
                        <badge v-if="row.item.status == 'removed'" type="danger">removed</badge>
                        <badge v-if="row.item.status == 'expired'" type="default">passed</badge>
                        <badge v-if="row.item.status == 'booked'" type="info">booked</badge>
                      </div>
                    </div>
                    <div class="mt-2"><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(row.item.start_time)}}</div>
                  </template>
                </b-table>
              </div>
              <div class="col d-none d-sm-block">
                <b-table :items="minors" :fields="['booking_details', 'child_name', 'status', 'date_signed', 'signature', 'actions']">
                  <template v-slot:cell(booking_details)="row">
                    <p style="font-size: medium" class="text-primary">{{row.item.game_name}}</p>
                    <p><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(row.item.start_time)}}</p>
                  </template>
                  <template v-slot:cell(child_name)="row">
                    <span>{{row.item.first_name}} {{row.item.last_name}}</span>
                  </template>
                  <template v-slot:cell(status)="row">
                    <badge v-if="row.item.status == 'completed'" type="success">completed</badge>
                    <badge v-if="row.item.status == 'checked'" type="warning">checked in</badge>
                    <badge v-if="row.item.status == 'call_to_book'" type="warning">checked in</badge>
                    <badge v-if="row.item.status == 'blocked'" type="danger">checked in</badge>
                    <badge v-if="row.item.status == 'cancelled'" type="danger">cancelled</badge>
                    <badge v-if="row.item.status == 'removed'" type="danger">removed</badge>
                    <badge v-if="row.item.status == 'expired'" type="default">passed</badge>
                    <badge v-if="row.item.status == 'booked'" type="info">booked</badge>
                  </template>
                  <template v-slot:cell(date_signed)="row">
                    <p><i class="tim-icons icon-calendar-60 mr-1"></i>{{ getLocalDate(row.item.created_time) }}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getLocalTime(row.item.created_time)}}</p>
                  </template>
                  <template v-slot:cell(signature)="row">
                    <p v-if="!row.item.signature" class="text-white">-</p>
                    <img v-if="row.item.signature" style="background-color: white" :src="renderSignature(row.item.signature)" height="50">
                  </template>
                  <template v-slot:cell(actions)="row">
                    <vue-html2pdf
                      :show-layout="false"
                      :float-layout="true"
                      :enable-download="false"
                      :paginate-elements-by-height="800"
                      filename="waiver"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="portrait"
                      pdf-content-width="800px"
                      @beforeDownload="beforeDownload($event)"
                      ref="html2Pdf"
                    >
                      <section slot="pdf-content">
                        <!-- Main Container -->
                        <div style="margin: 40px; font-family: Arial, sans-serif; color: #333;">
                          <!-- Game Name -->
                          <p style="color: black; font-size: 24px; font-weight: bold; color: #3358f4;">
                            {{ selectedWaiver.game_name }}
                          </p>

                          <!-- Booking Date and Time -->
                          <p style="color: black; font-size: 18px;">
                            {{ getFormattedDate(selectedWaiver.booking_date) }}, {{ getTime(selectedWaiver.start_time) }}
                          </p>

                          <!-- Waiver Text -->
                          <p style="color: black; font-size: 16px; margin-top: 20px;" v-if="selectedWaiver.waiver_text">Waiver</p>
                          <p v-if="selectedWaiver.waiver_text" v-html="selectedWaiver.waiver_text"
                             style="color: black; padding: 10px 10px 10px 10px; font-size: 14px; margin-top: 10px; line-height: 1.6; padding: 10px; background-color: #f0f0f0; border-radius: 5px;">
                          </p>

                          <!-- Signature Date and Customer Information -->
                          <p style="color: black; font-size: 16px; margin-top: 20px;">
                            Signed on {{ getLocalDate(selectedWaiver.created_time) }}, {{ getLocalTime(selectedWaiver.created_time) }}
                          </p>
                          <p style="color: black; font-size: 16px;">
                            Signed by {{ selectedWaiver.first_name && selectedWaiver.last_name ?
                            selectedWaiver.first_name + ' ' + selectedWaiver.last_name : selectedWaiver.email }}
                          </p>
                        </div>

                        <!-- Signature Section -->
                        <section class="pdf-item" style="margin-left: 40px; margin-top: 20px;">
                          <p style="color: black; font-size: 16px;">Signature</p>
                          <img :src="selectedWaiver.signature" style="max-width: 200px; height: auto;">
                        </section>
                      </section>
                    </vue-html2pdf>
                    <el-tooltip content="Download PDF"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="downloadWaiver(row.item)"
                        simple
                        type="success"
                        link
                        style="font-size: large"
                      > <i class="tim-icons icon-cloud-download-93"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit waiver"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="editWaiver(row.item)"
                        simple
                        type="warning"
                        link
                        style="font-size: large"
                      > <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row" v-if="waivers.length > 0 && !showMinorTable">
              <div class="col d-block d-sm-none">
                <b-table :items="waivers" :fields="['waiver']">
                  <template v-slot:cell(waiver)="row">
                    <div class="row">
                      <div class="col-7">
                        <p style="font-size: medium" class="text-primary">{{row.item.game_name}}</p>
                      </div>
                      <div class="col text-right">
                        <badge v-if="row.item.status == 'completed'" type="success">completed</badge>
                        <badge v-if="row.item.status == 'checked'" type="warning">checked in</badge>
                        <badge v-if="row.item.status == 'call_to_book'" type="warning">checked in</badge>
                        <badge v-if="row.item.status == 'blocked'" type="danger">checked in</badge>
                        <badge v-if="row.item.status == 'cancelled'" type="danger">cancelled</badge>
                        <badge v-if="row.item.status == 'removed'" type="danger">removed</badge>
                        <badge v-if="row.item.status == 'expired'" type="default">passed</badge>
                        <badge v-if="row.item.status == 'booked'" type="info">booked</badge>
                      </div>
                    </div>
                    <div class="mt-2"><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(row.item.start_time)}}</div>
                  </template>
                </b-table>
              </div>
              <div class="col d-none d-sm-block">
                <b-table :items="waivers" :fields="waiverFields">
                  <template v-slot:cell(booking_details)="row">
                    <p style="font-size: medium" class="text-primary">{{row.item.game_name}}</p>
                    <p><i class="tim-icons icon-calendar-60 mr-1"></i>{{getDayOfWeek(row.item.booking_date)}} {{getFormattedDate(row.item.booking_date)}}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getTime(row.item.start_time)}}</p>
                  </template>
                  <template v-slot:cell(status)="row">
                    <badge v-if="row.item.status == 'completed'" type="success">completed</badge>
                    <badge v-if="row.item.status == 'checked'" type="warning">checked in</badge>
                    <badge v-if="row.item.status == 'call_to_book'" type="warning">checked in</badge>
                    <badge v-if="row.item.status == 'blocked'" type="danger">checked in</badge>
                    <badge v-if="row.item.status == 'cancelled'" type="danger">cancelled</badge>
                    <badge v-if="row.item.status == 'removed'" type="danger">removed</badge>
                    <badge v-if="row.item.status == 'expired'" type="default">passed</badge>
                    <badge v-if="row.item.status == 'booked'" type="info">booked</badge>
                  </template>
                  <template v-slot:cell(date_signed)="row">
                    <p><i class="tim-icons icon-calendar-60 mr-1"></i>{{ getLocalDate(row.item.created_time) }}
                      <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getLocalTime(row.item.created_time)}}</p>
                  </template>
                  <template v-slot:cell(signature)="row">
                    <p v-if="!row.item.signature" class="text-white">-</p>
                    <img v-if="row.item.signature" style="background-color: white" :src="renderSignature(row.item.signature)" height="50">
                  </template>
                  <template v-slot:cell(actions)="row">
                    <vue-html2pdf
                      :show-layout="false"
                      :float-layout="true"
                      :enable-download="false"
                      :paginate-elements-by-height="800"
                      filename="waiver"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="portrait"
                      pdf-content-width="800px"
                      @beforeDownload="beforeDownload($event)"
                      ref="html2Pdf"
                    >
                      <section slot="pdf-content">
                        <!-- Main Container -->
                        <div style="margin: 40px; font-family: Arial, sans-serif; color: #333;">
                          <!-- Game Name -->
                          <p style="color: black; font-size: 24px; font-weight: bold; color: #3358f4;">
                            {{ selectedWaiver.game_name }}
                          </p>

                          <!-- Booking Date and Time -->
                          <p style="color: black; font-size: 18px;">
                            {{ getFormattedDate(selectedWaiver.booking_date) }}, {{ getTime(selectedWaiver.start_time) }}
                          </p>

                          <!-- Waiver Text -->
                          <p style="color: black; font-size: 16px; margin-top: 20px;" v-if="selectedWaiver.waiver_text">Waiver</p>
                          <p v-if="selectedWaiver.waiver_text" v-html="selectedWaiver.waiver_text"
                             style="color: black; padding: 10px 10px 10px 10px; font-size: 14px; margin-top: 10px; line-height: 1.6; padding: 10px; background-color: #f0f0f0; border-radius: 5px;">
                          </p>

                          <!-- Signature Date and Customer Information -->
                          <p style="color: black; font-size: 16px; margin-top: 20px;">
                            Signed on {{ getLocalDate(selectedWaiver.created_time) }}, {{ getLocalTime(selectedWaiver.created_time) }}
                          </p>
                          <p style="color: black; font-size: 16px;">
                            Signed by {{ selectedWaiver.first_name && selectedWaiver.last_name ?
                            selectedWaiver.first_name + ' ' + selectedWaiver.last_name : selectedWaiver.email }}
                          </p>
                        </div>

                        <!-- Signature Section -->
                        <section class="pdf-item" style="margin-left: 40px; margin-top: 20px;">
                          <p style="color: black; font-size: 16px;">Signature</p>
                          <img :src="selectedWaiver.signature" style="max-width: 200px; height: auto;">
                        </section>
                      </section>
                    </vue-html2pdf>
                    <el-tooltip content="Download PDF"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="downloadWaiver(row.item)"
                        simple
                        type="success"
                        link
                        style="font-size: large"
                      > <i class="tim-icons icon-cloud-download-93"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit waiver"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click="editWaiver(row.item)"
                        simple
                        type="warning"
                        link
                        style="font-size: large"
                      > <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 6">
            <div v-if="surveys.length <= 0" class="mt-3" >
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No surveys have been filled out.</h4>
              </div>
            </div>
            <div class="row" v-if="surveys.length > 0">
              <div class="col d-block d-sm-none">
                <b-table :items="surveys" :fields="['surveys']">
                  <template v-slot:cell(surveys)="row">
                    <p>{{row.item.name}}
                      <el-tooltip
                        content="View response details"
                        effect="light"
                        :open-delay="300"
                        placement="right"
                      >
                        <i style="cursor: pointer" v-on:click="viewSurveyResponse(row.item)" class="tim-icons el-icon-top-right text-primary"></i>
                      </el-tooltip>
                    </p>
                    <small v-if="row.item.timestamp" style="font-style: italic">Date completed {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
                  </template>
                </b-table>
              </div>
              <div class="col d-none d-sm-block">
                <b-table :items="surveys" :fields="['survey', 'date_completed', 'actions']">
                  <template v-slot:cell(survey)="row">
                    <p>{{row.item.name}}</p>
                    <small> {{row.item.description}}</small>
                  </template>

                  <template v-slot:cell(date_completed)="row">
                    {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mt-1 mb-2" target="_blank" v-on:click="viewSurveyResponse(row.item)" href="#">
                            <i class="tim-icons text-info icon-notes mr-2"></i> View response details
                          </a>
                          <hr>
                          <a class="dropdown-item mb-2" @click="deleteSurveyResponsePrompt(row.item)" href="#">
                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete survey
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 7">
            <div class="row mt-2">
              <div class="col-lg-5">
                <h4 class="card-title">Notes</h4>
                <div v-if="notes.length > 0 && !new_note_field" class="row" v-for="(bn, i) in notes" :key="'BN' + i">
                  <div class="col mt-2">
                    <base-alert type="white">
                      <div class="row">
                        <div class="col text-black-50">
                          {{bn.note}}
                        </div>
                        <div class="col-lg-2 text-right">
                          <i style="cursor: pointer" v-on:click="deleteNote(bn.id)" class="tim-icons text-danger icon-simple-remove"></i>
                        </div>
                      </div>
                    </base-alert>
                    <div class="mb-2" style="margin-top: -15px">
                      <small class="font-italic">{{getLocalDate(bn.timestamp)}}, {{getLocalTime(bn.timestamp)}}, {{bn.first_name}} {{bn.last_name}}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="new_note_field">
                  <div class="col">
                    <base-input label="Add a new note">
                      <el-input
                        v-model="note"
                        :rows="5"
                        type="textarea"
                        placeholder="Notes..."
                      />
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button v-if="!new_note_field" size="sm" type="primary" v-on:click="newNoteField(1)"><i class="tim-icons el-icon-plus mr-1"></i> Add note</base-button>
                    <base-button v-if="new_note_field" class="mr-2" size="sm" v-on:click="newNoteField(0)">Cancel</base-button>
                    <base-button v-if="new_note_field" size="sm" simple type="success" v-on:click="saveNote()">Save</base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-5">
                <h4 class="card-title">Tags</h4>
                <span v-if="!new_tag_field" v-for="(t, i) in tags" :key="'T' + i">
                  <badge type="warning">{{ t.name }}</badge>
                  <base-button
                    @click="deleteTag(t.id)"
                    icon
                    class="like btn-link"
                    type="danger"
                    style="margin-left: -10px; margin-top: -3px; margin-right: -20px"
                  ><i class="tim-icons icon-simple-remove text-danger" style="font-size: xx-small"></i>
                  </base-button>
                  <span class="ml-3"></span>
                </span>
                <div class="row" v-if="new_tag_field">
                  <div class="col-lg-8">
                    <base-input placeholder="Tag" v-model="new_tag"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button v-if="!new_tag_field" size="sm" type="primary" v-on:click="newTagField(1)"><i class="tim-icons el-icon-plus mr-1"></i> Add tag</base-button>
                    <base-button v-if="new_tag_field" class="mr-2" size="sm" v-on:click="newTagField(0)">Cancel</base-button>
                    <base-button v-if="new_tag_field" size="sm" simple type="success" v-on:click="saveTag()">Save</base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
<!--Photos -->
          <div v-if="activeTabIndex === 8">
            <div class="row mt-5 mb-5" v-if="photos.length > 0">
              <div class="col text-center">
                <el-carousel v-on:change="setActivePhoto" :autoplay="false" indicator-position="outside">
                  <el-carousel-item v-for="(item, index) in photos" :key="'CS' + index">
                    <img v-if="item.overlay_url" :id="'img' + index" :src="item.overlay_url" crossorigin="anonymous"
                         style="max-height: 100%; max-width: 100%; border-radius: 0px" >
                    <img v-else :id="'img' + index" :src="item.url"  crossorigin="anonymous"
                         style="max-height: 100%; max-width: 100%; border-radius: 0px" >
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>

  <!--        Email events-->
          <div v-if="activeTabIndex === 9">
            <base-button class="text-white" style="padding-left: 0" link v-on:click="getCustomerDetails()">
              <i class="tim-icons icon-refresh-01 mr-1 text-white"></i> Refresh
            </base-button>
            <div v-if="email_events.length <= 0">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No emails associated with this customer.</h4>
              </div>
            </div>
            <!--          Mobile-->
            <div v-if="email_events.length > 0" class="d-sm-none d-block">
              <b-table :items="email_events" :fields="['email_event']">
                <template v-slot:cell(email_event)="row">
                  <div class="row">
                    <div class="col">
                      <p style="font-weight: bold; font-size: medium">{{capitalizeFirstLetter(row.item.email_type)}}</p>
                    </div>
                    <div class="col text-right">
                      <badge v-if="row.item.event_type==='Open' || row.item.event_type==='Click' || row.item.event_type==='Subscription'" type="success">{{row.item.event_type}}</badge>
                      <badge v-if="row.item.event_type==='Bounce' || row.item.event_type==='Complaint' || row.item.event_type==='Reject'
                      || row.item.event_type==='Rendering Failure' || row.item.event_type==='DeliveryDelay'"
                             type="danger">{{row.item.event_type}}</badge>
                      <badge v-if="row.item.event_type==='Delivery' || row.item.event_type==='Send'" type="default">{{row.item.event_type}}</badge>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <small style="font-style: italic">{{getLocalDate(row.item.timestamp)}}, {{ getLocalTime(row.item.timestamp)}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      Recipient: {{row.item.customer_first_name}} {{row.item.customer_last_name}}
                    </div>
                  </div>
                  <div class="row" v-if="row.item.sender_email">
                    <div class="col">
                      Sender: {{row.item.sender_first_name}} {{row.item.sender_last_name}}
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div v-if="email_events.length > 0" class="d-sm-block d-none">
              <b-table :items="email_events" :fields="['email_type', 'email_status', 'time_of_event', 'recipient', 'sender']">
                <template v-slot:cell(email_type)="row">
                  {{capitalizeFirstLetter(row.item.email_type)}}
                </template>
                <template v-slot:cell(email_status)="row">
                  <badge v-if="row.item.event_type==='Open' || row.item.event_type==='Click' || row.item.event_type==='Subscription'" type="success">{{row.item.event_type}}</badge>
                  <badge v-if="row.item.event_type==='Bounce' || row.item.event_type==='Complaint' || row.item.event_type==='Reject'
                      || row.item.event_type==='Rendering Failure' || row.item.event_type==='DeliveryDelay'"
                         type="danger">{{row.item.event_type}}</badge>
                  <badge v-if="row.item.event_type==='Delivery' || row.item.event_type==='Send'" type="default">{{row.item.event_type}}</badge>
                </template>
                <template v-slot:cell(time_of_event)="row">

                  {{getLocalDate(row.item.timestamp)}}, {{ getLocalTime(row.item.timestamp)}}
                </template>
                <template v-slot:cell(recipient)="row">
                  <div>{{row.item.customer_first_name}} {{row.item.customer_last_name}}</div>
                  <small> <i class="tim-icons icon-email-85 ml-1"></i> {{row.item.customer_email}}</small>
                </template>
                <template v-slot:cell(sender)="row">
                  <div v-if="row.item.sender_email">
                    <div>{{row.item.sender_first_name}} {{row.item.sender_last_name}}</div>
                    <small><i class="tim-icons icon-email-85 ml-1"></i>  {{row.item.sender_email}}</small>
                  </div>
                  <div v-else>System</div>
                </template>
              </b-table>
            </div>

          </div>
        </card>
      </div>
    </div>


    <modal :show.sync="modals.bookings"
           body-classes="p-0"
           modal-classes="modal-dialog-centered">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Booking slots</h4>
        <!--   No data panel -->
        <div v-if="emptyBooking==true" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">There are no bookings on this day.</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <label>Select booking date</label>
            <base-input style="border: 0; border-width:0px;">
              <el-date-picker
                style="border: 0; border-width:0px;"
                type="date"
                :prefix-icon="' '"
                :placeholder="current"
                value-format="yyyy-MM-dd"
                format="ddd, dd MMMM yyyy"
                v-model="current"
                @change="changeDate()"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>



        <div v-if="emptyBooking==false" class="row" v-for="(b, i) in bookings" :key="'A' + i">
          <div class="col">
            <div class="row">
              <div
                class="form-check form-check-radio form-check-in-table"
              >
                <label class="form-check-label">
                  <input
                    v-model="selectedBooking"
                    class="form-check-input"  :name="b.id" :value="b"
                    type="radio"
                  />
                  <slot></slot> <span class="form-check-sign"></span>
                  <span class="text-success ml-1" style="font-size: medium">
                      {{b.game_name}}</span>
                </label>

                <span class="ml-2 text-white"><i class="text-success tim-icons icon-calendar-60"></i>
               {{getFormattedDate(b.booking_date)}}</span>
                <span class="ml-2 text-white"><i class="text-success tim-icons icon-time-alarm"></i>
               {{getTime(b.start_time)}}</span>&nbsp;
                <badge v-if="b.status == 'available'" type="info">{{b.status}}</badge>
                <badge v-if="b.status == 'booked'" type="success">{{b.status}}</badge>
                <badge v-if="b.status == 'checked'" type="info">available</badge>
                <badge v-if="b.status == 'completed'" type="warning">completed</badge>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.bookings = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="updateWaiver()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.bookings = false">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="updateWaiver()">Save</base-button>
        </div>
      </card>
    </modal>

    <modal :show.sync="modals.customer_credit"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Add customer credit</h4>
        <div class="row mt-2">
          <div class="col-lg-3 col-12">
            <base-input label="Amount ($)" placeholder="0.00" v-model="customer_credit.amount" type="number"></base-input>
          </div>
          <div class="col">
            <base-input label="Description" placeholder="Description" v-model="customer_credit.description" ></base-input>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.customer_credit = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="addCredit()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.customer_credit = false">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="addCredit()">Save</base-button>
        </div>
      </card>
    </modal>

    <modal :show.sync="modals.edit_customer_credit"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Edit customer credit</h4>
        <div class="row mt-2">
          <div class="col-lg-3 col-12">
            <base-input label="Amount ($)" placeholder="0.00" v-model="customer_credit.amount" type="number"></base-input>
          </div>
          <div class="col">
            <base-input label="Description" placeholder="Description" v-model="customer_credit.description" ></base-input>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <base-button type="secondary" @click="modals.edit_customer_credit = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="editCredit()">Save</base-button>
        </div>
        <div class="row d-block d-sm-none text-center mt-3">
          <base-button simple size="sm" type="secondary" @click="modals.edit_customer_credit = false">Cancel</base-button>
          <base-button simple size="sm" class="ml-3" type="primary" @click="editCredit()">Save</base-button>
        </div>
      </card>
    </modal>

  </div>
</template>
<script>
  import { Auth } from 'aws-amplify'
  import {Modal, BaseAlert} from 'src/components'
  import { BTable, BRow, BCol } from 'bootstrap-vue'
  import { API_LOCATION } from '../../api-config'
  import {time, formattedDate, simpleTime, dayOfWeek} from "@/plugins/dateFormatter";
  import Badge from "@/components/Badge";
  import VueHtml2pdf from 'vue-html2pdf'
  import swal from "sweetalert2";
  import currencies from "@/assets/currencies.json";
  import _ from "lodash";
  import countries from "@/assets/countries.json";
  import states from "@/assets/states.json";
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
  import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
  import {Carousel, CarouselItem, Dropdown, DropdownItem, DropdownMenu} from "element-ui";


  const axios = require('axios')
  export default {
    name: "PlayerInfo",
    components: {
      BreadcrumbItem,
      Breadcrumb,
      BTable,
      BRow,
      BCol,
      Modal,
      Badge,
      VueHtml2pdf,
      BaseAlert,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [CarouselItem.name]: CarouselItem,
      [Carousel.name]: Carousel,
    },
    data() {
      return {
        gift_cards: [],
        surveys: [],
        showMinorTable: false,
        selectedPolicy: '',
        custom_fields: [],
        loading: false,
        new_tag_field: false,
        new_note_field: false,
        notes: [],
        note: '',
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        number_format: JSON.parse(localStorage.getItem('group')).number_format,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        currency: JSON.parse(localStorage.getItem('group')).currency,
        currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
        group: JSON.parse(localStorage.getItem('group')),
        date_format_cal: '',
        day_of_week: '',
        currencies: currencies.currencies,
        countries: countries.countries,
        states: states.states,
        placeholder_phone: '(999) 999-9999',
        placeholder_dob: 'MM/DD/YYYY',
        new_tag: '',
        tags: [],
        customer: '',
        company_customer: {
          first_name: '',
          last_name: '',
          birthday: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          state: '',
          zip: '',
          email: '',
          phone: ''
        },
        photos: '',
        waivers: '',
        transactions: [],
        games: [],
        games_booked: '',
        gameFields: ['game', 'booking', 'completion_time', 'hints', 'notes', 'actions'],
        transactionFields: ['booking_details', 'customer_details', 'transaction_details', 'actions'],
        waiverFields: ['booking_details', 'status', 'date_signed', 'signature', 'actions'],
        customerCreditFields: ['credits', 'spent', 'remaining', 'created_by', 'actions'],
        minors: [],
        team: [],
        teamFields: ['first_name', 'last_name', 'email', 'birthday'],
        selectedGame: null,
        selectedWaiver: '',
        bookings: [],
        selectedBooking: '',
        emptyBooking: false,
        customer_credits: [],
        customer_credit: {
          amount: 0.00,
          description: ''
        },
        current: '',
        activeTabIndex: 0,
        j: 0,
        modals: {
          bookings: false,
          customer_credit: false,
          edit_customer_credit: false,
          policy: false,
          custom_fields: false,
          notes: false
        },
        customer_responses: [],
        email_events: [],
        selectedNote: '',
        errs: {
          birthday: ''
        }
      };
    },
    computed: {
      categories() {
        return [
          { name: 'General', icon: 'tim-icons  icon-single-02',active: true },
          { name: 'Games', icon: 'tim-icons icon-puzzle-10', active: false },
          { name: 'Transactions', icon: 'tim-icons  icon-money-coins', active: false },
          { name: 'Customer credit', icon: 'tim-icons  icon-credit-card', active: false },
          { name: 'Gift cards', icon: 'tim-icons  icon-gift-2', active: false },
          { name: 'Waivers', icon: 'tim-icons  icon-paper', active: false },
          { name: 'Surveys', icon: 'tim-icons  icon-pencil', active: false },
          { name: 'Notes/tags', icon: 'tim-icons  icon-notes', active: false },
          { name: 'Photos', icon: 'tim-icons  icon-camera-18', active: false },
          { name: 'Emails', icon: 'tim-icons icon-email-85',active: false },
        ];
      }
    },
    methods: {
      setNotes(note){
        this.selectedNote = note
        this.modals.notes = true
      },
      isTextLong(fulltext){
        return fulltext.length > 25
      },
      truncatedText(fulltext) {
        return fulltext.length > 25
          ? fulltext.slice(0, 25)
          : fulltext;
      },
      getCompletionTime(game){
        let start = ''
        let end = ''
        let time = ''
        if(game.game_end_time.includes('0000')){
          if(game.game_end_time_by_puzzle){
            start = new Date(game.game_start_time)
            end = new Date(game.game_end_time_by_puzzle)
            time = (end - start) / (1000 * 60)

            if(time === 1) {
              time = time + ' minute'
            } else {
              time = time + ' minutes'
            }
            return  time + ' minutes'

          } else {
            return '-'
          }
        } else {
          start = new Date(game.game_start_time)
          end = new Date(game.game_end_time)
          time = (end - start) / (1000 * 60)
          return time + ' minutes'
        }
        return '-'
      },
      capitalizeFirstLetter(string){
        if (!string) {
          return string;
        }
        string = string.replace(/_/g, ' ');
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      viewTransaction(item){
        this.$router.push({ path: '/purchases/transactions/details', query: { id: item.transaction_id }})
      },
      async sendGiftCardEmail(item){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + item.transaction_id + '/gift-cards'
        let data = {
          email: JSON.parse(item.recipients)[0].email,
          gift_card_id: item.id
        }

        this.axios.post(url, data, config)
          .then(response => {
            swal({
              title: 'Success',
              text: "Gift card delivery email sent to customer",
              type: 'success',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: 'OK'
            })
            this.getCustomerDetails()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      sendGiftCardEmailPrompt(item){
        swal({
          title: 'Are you sure?',
          html: "This will send the gift card delivery email to the recipient.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.sendGiftCardEmail(item);
          }
        })
      },
      deactivateGiftCardPrompt(item){
        swal({
          title: 'Are you sure?',
          html: "This action will deactivate the gift card. You will need to manually refund any remaining balance.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.deactivateGiftCard(item);
          }
        })
      },
      async deactivateGiftCard(item){
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id

        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/transactions/' + item.transaction_id + '/gift-cards?id=' + item.id

        this.loading = true
        axios.delete(url, config)
          .then(response => {
            this.loading = false

            swal({
              title: 'Success!',
              text: "Gift card has been deactivated",
              type: 'success'
            })

            this.getCustomerDetails()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      getDeliveryDate(delivery_date, date_purchased){
        if(!delivery_date){
          return this.getFormattedDate(date_purchased)
        } else {
          return this.getFormattedDate(delivery_date)
        }
      },
      getGiftCardCode(code){
        if(!code){
          return
        }
        if(isNaN(code)){
          return code
        }
        let c = code.match(/.{1,4}/g);
        return c.join(' ')
      },
      setActivePhoto(index){
        this.photo = this.photos[index]
      },
      deleteSurveyResponsePrompt(item){
        swal({
          title: 'Are you sure?',
          text: 'This will permanently delete the survey response.',
          type: 'warning',
          showCancelButton: true,
        }).then((response)=> {
          if(response.value == true){
            this.deleteSurveyResponse(item)
          }
        })
      },
      async deleteSurveyResponse(item){
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/history/' + item.id

        this.loading = true
        this.axios.delete(url, config)
          .then(response => {
            swal('Success!', 'Survey response has been deleted.', 'success')
            this.getCustomerDetails()
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      viewSurveyResponse(item){
        this.$router.push({ path: '/surveys/history/response', query: { id:  item.id }})
      },
      viewCustomFields(item){
        this.customer_responses =  _.filter(this.custom_fields, { transaction_id: item.id })
        this.modals.custom_fields = true
      },
      openPolicy(item){
        this.selectedPolicy = item.policy
        this.modals.policy = true
      },
      getTotalCredits(){
        let total = 0
        for(let n in this.customer_credits){
          total += this.customer_credits[n].total
        }
        return this.getAmount(total)
      },
      getRemainingCredits(){
        let total = 0
        for(let n in this.customer_credits){
          total += this.customer_credits[n].remaining
        }
        return this.getAmount(total)
      },
      getSpentCredits(){
        let total = 0
        for(let n in this.customer_credits){
          total += this.customer_credits[n].spent
        }
        return this.getAmount(total)
      },
      viewTransactionDetails(item){
        this.$router.push({ path: '/purchases/transactions/details', query: { id: item.id }})
      },
      getCountry(){
        if(!this.customer.hasOwnProperty('country')){
          return ''
        } else {
          return this.customer.country.name
        }
      },
      isValidCustomerForm(){
        let errors = 0
        // if(!this.company_customer.birthday){
        //   this.errs.birthday = 'Invalid birthday'
        //   errors++
        // } else {
        //   this.errs.birthday = ''
        // }

        if(errors > 0){
          return false
        }
        return true
      },
      async updateCustomer(){
        if(!this.isValidCustomerForm()){
          return
        }

        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let customerId = this.$route.query.id
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION  + 'companies/' + companyId + '/groups/'
          + companyGroupId + '/customers/' + customerId

        let data = {
          first_name: this.company_customer.first_name,
          last_name: this.company_customer.last_name,
          email: this.company_customer.email,
          phone: this.company_customer.phone || '',
          birthday: this.company_customer.birthday ? this.company_customer.birthday.getFullYear() + '-' +  ('0' + (this.company_customer.birthday.getMonth()+1)).slice(-2) + '-' + ('0' + this.company_customer.birthday.getDate()).slice(-2) : null,
          address_line_1: this.company_customer.address_line_1,
          address_line_2: this.company_customer.address_line_2,
          city: this.company_customer.city,
          state: this.company_customer.state,
          zip: this.company_customer.zip,
          country: this.company_customer.country
        }


        axios.put(url, data, config)
          .then(response => {
            swal('Success!', 'Customer details have been updated', 'success')
            this.loading = false
            this.getCustomerDetails()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      getNumberFormat(value){
        if(this.number_format === 1){
          value = value.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        }
        return value
      },
      editCustomerCredit(item){
        this.customer_credit = {
          amount: item.total.toFixed(2),
          description: item.description,
          spent: item.spent,
          id: item.id
        }
        this.modals.edit_customer_credit = true
      },
      async editCredit(){
        //check that total isn't lower than spent
        if(this.customer_credit.spent > this.customer_credit.amount){
          return
        }
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let customerId = this.$route.query.id
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION  + 'companies/' + companyId + '/groups/'
          + companyGroupId + '/customers/' + customerId + '/customer-credit/' + this.customer_credit.id

        let data = this.customer_credit
        axios.put(url, data, config)
          .then(response => {
            swal('Success!', 'Customer credit has been updated', 'success')
            this.modals.edit_customer_credit = false
            this.getCustomerDetails()
          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async deleteCustomerCredit(item){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let customerId = this.$route.query.id
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION  + 'companies/' + companyId + '/groups/'
          + companyGroupId + '/customers/' + customerId + '/customer-credit/' + item.id

        axios.delete(url, config)
          .then(response => {
            swal('Success!', 'Customer credit has been deleted', 'success')
            this.getCustomerDetails()

          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async addCredit(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let customerId = this.$route.query.id
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION  + 'companies/' + companyId + '/groups/'
          + companyGroupId + '/customers/' + customerId + '/customer-credit'
        let data = this.customer_credit
        axios.post(url, data, config)
          .then(response => {
            this.modals.customer_credit = false
            swal('Success!', 'Customer credit has been added', 'success')
            this.getCustomerDetails()

          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      newCustomerCredit(){
        this.customer_credit = {
          amount: '0.00',
          description: ''
        }
        this.modals.customer_credit = true
        this.add_customer_credit = true
      },
      switchTab(index){
        this.activeTabIndex = index
        for(let n in this.categories){
          this.categories[n].active = false
        }
        this.categories[index].active = true
        this.j++
      },
      newTagField(status){
        this.new_tag_field = status
      },
      newNoteField(status){
        this.new_note_field = status
      },
      editWaiver(item){
        this.selectedWaiver = item
        this.modals.bookings = true
      },
      getPDFDate(d, t){
        if(d == undefined){
          return
        }
        let months = ["January","February","March","April","May","June","July",
          "August","September","October","November","December"]
        let date = ''
        if(d.length > 10){
          let dd = d.split('T')[0]
          date = dd.split('-')
        } else {
          date = d.split('-')
        }
        return date[2] + ' ' + months[parseInt(date[1]-1)] + ' ' + date[0] + ', ' + t.split(':')[0] + ':' + t.split(':')[1]

      },
      async beforeDownload ({ html2pdf, options, pdfContent }) {
        this.loading = true
        await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
          }
        }).save()
        this.loading = false
      },
      downloadWaiver(waiver){
        this.selectedWaiver = {
          game_name: waiver.game_name,
          first_name: waiver.first_name,
          last_name: waiver.last_name,
          waiver_text: waiver.waiver_text,
          created_time: waiver.created_time,
          booking_date: waiver.booking_date,
          start_time: waiver.start_time,
          customer: this.customer.first_name + ' ' + this.customer.last_name,
          email: this.customer.email,
          signature: this.renderSignature(waiver.signature)
        }
        this.$refs.html2Pdf.generatePdf()

      },
      renderSignature(blob){
        if(blob == null){
          return ''
        }
        var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(blob.data)));
        let final = atob(base64String)
        return final
      },
      getLocalTime(d){
        if(!d){
          return
        }
        let local = new Date(d)
        return this.getTime(local.getHours() + ':' + local.getMinutes())
      },
      getLocalDate(d){

        if(!d){
          return
        }
        if(d.includes('0000')){
          return '-'
        }
        let local = new Date(d)
        let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
        let final = this.getFormattedDate(form)
        return this.getDayOfWeek(form) + final
      },
      getFormattedDate(d) {
        return formattedDate(this.date_format, d);
      },
      getTime(d) {
        return time(this.time_format, d)
      },
      getAmount(integer){
        if(integer == null){
          return
        }
        return '$' + parseFloat(integer).toFixed(2);
      },
      getCurrencyCode(){
        if(this.currency_format === 0){
          return this.currency
        }
      },
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      getCurrencySymbol(){
        if(this.currency_format === 1){
          let c = _.find(this.currencies, { code: this.currency });
          return c.symbol
        }
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      viewDetails(item){
        this.$router.push({ path: '/games/history/instance', query: { id: item.id }})
      },
      async getCustomerDetails () {
        this.loading = true

        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let customerId = this.$route.query.id
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION  + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers/' + customerId
        axios.get(url, config)
          .then(response => {
            this.customer = response.data.customer
            this.games = response.data.games
            this.photos = response.data.photos
            this.waivers = response.data.waivers
            this.transactions = response.data.transactions
            this.tags = response.data.tags
            this.notes = response.data.notes
            this.surveys = response.data.surveys
            this.customer_credits = response.data.customer_credits
            this.company_customer = response.data.customer_company_link
            this.custom_fields = response.data.custom_fields
            this.minors = response.data.minors
            this.gift_cards = response.data.gift_cards
            this.email_events = response.data.email_events

            this.company_customer.country = _.find(this.countries, { code: this.company_customer.country })
            if(!this.company_customer.country){
              this.company_customer.country = {code: ''}
            }
            this.customer.country = _.find(this.countries, { code: this.customer.country })
            if(!this.customer.country){
              this.customer.country = {code: ''}
            }
            if(!this.company_customer.birthday || this.company_customer.birthday.includes('0000')){
              this.company_customer.birthday = null
            } else {
              let birthday = new Date()
              birthday.setFullYear(response.data.customer_company_link.birthday.split('T')[0].split('-')[0])
              birthday.setMonth(response.data.customer_company_link.birthday.split('T')[0].split('-')[1] - 1, response.data.customer_company_link.birthday.split('T')[0].split('-')[2])
              this.company_customer.birthday = birthday
            }
            this.loading = false

          })
          .catch(err => {
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async getBookings(){
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/bookings?day=' + this.current + '&type=2'

        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.bookings = response.data.bookings
            if(this.bookings.length <= 0){
              this.emptyBooking = true
            } else {
              this.emptyBooking = false
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      changeDate(){
        this.getBookings()
      },
      setToday(){
        let current = new Date()
        this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
        this.getBookings()
      },
      async deleteTag(tagId){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let customerId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers/' + customerId + `/tags/` + tagId

        axios.delete(url, config)
          .then(response => {
            this.loading = false
            this.getCustomerDetails()
            swal({
              title: 'Success!',
              text: "Tag has been deleted",
              type: 'success'
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async saveNote(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let customerId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers/' + customerId + `/notes`

        let data = {
          note: this.note
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.note = ''
            this.new_note_field = false
            this.getCustomerDetails()
            swal({
              title: 'Success!',
              text: "Note has been added",
              type: 'success'
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async deleteNote(id){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let customerId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers/' + customerId + `/notes/` + id

        this.axios.delete(url, config)
          .then(response => {
            this.getCustomerDetails()
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async saveTag(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let customerId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers/' + customerId + `/tags`

        let data = {
          name: this.new_tag
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.new_tag = ''
            this.new_tag_field = false
            this.getCustomerDetails()
            swal({
              title: 'Success!',
              text: "New tag has been added",
              type: 'success'
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async updateWaiver(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers/' + this.selectedWaiver.id

        let data = {
          booking_slot_id: this.selectedBooking.id
        }

        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            this.modals.bookings = false
            this.getCustomerDetails()
            swal({
              title: 'Congratulations!',
              text: "The waiver has been updated",
              type: 'success',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: "OK"
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
    },
    beforeMount() {
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      this.getCustomerDetails()
      this.setToday()
    }
  };
</script>
<style></style>
