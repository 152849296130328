<template>
  <div class="content">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--  Feature not enabled-->
    <div v-if="feature_not_enabled" class="mt-3" >
      <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
        <h4 class="mt-3">This feature is not enabled.</h4>
        <base-button type="primary" v-if="hasPermissions('billing_view')"
                     v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
        <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
      </div>
    </div>

    <div v-if="!feature_not_enabled">
      <!--Medium and large screens-->
      <div class="row d-none d-sm-block overflow-scroll">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">Waivers</h4>
            <div class="row">
              <div
                class="col d-flex justify-content-between"
              >

                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by last name</h3>
                      <div class="pop popover-body">


                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select
                              v-model="filters.game.value"
                              class="select-primary"
                              name="game"
                              placeholder="Game"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="game.name"
                                :key="game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by booking date range</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.booking_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.booking_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.booking_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_day, filters.booking_range)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Booking Date
                      <span v-if="filters.booking_day.actual">: within {{filters.booking_day.actual}} days</span>
                      <span v-if="filters.booking_range.actual_from">:
                          between {{filters.booking_range.actual_from}} and {{filters.booking_range.actual_to}}
                        </span>
                      &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <base-button simple size="sm" type="primary" v-on:click="getTodaysBookings()" class="ml-2">Today's Bookings</base-button>
                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="mr-5">
                <base-button icon class="btn-link text-white" v-if="hasPermissions('download_data')" v-on:click="downloadCSV()">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="updateWaivers()"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="mt-3" v-if="noDataAvailable === true">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No data available.</h4>
              </div>
            </div>
            <div v-if="noDataAvailable === false" class="row">
              <div v-if="load" class="col-md-12 col-lg-12 col-sm-12 col-12">
                <b-table  striped hover :items="waivers"
                          :current-page="currentPage"
                          :fields="fields">

                  <template v-slot:cell(name)="row">
                    <span>{{row.item.first_name}} {{row.item.last_name}}</span>
                    &nbsp;<badge v-if="row.item.is_minor === 1" type="primary">minor</badge>
                    <badge v-if="row.item.not_participating === 1" type="danger">not participating</badge>
                  </template>
                  <template v-slot:cell(game)="row">
                    <span v-if="!editing[row.index]">{{row.item.game}}</span>
                    <span v-if="editing[row.index]">
                    <base-input>
                          <el-select
                            v-model="row.item.game"
                            class="select-primary"
                            name="game"
                            placeholder="Game"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="game.name"
                              :key="game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                   </span>
                  </template>
                  <template v-slot:cell(booking_day)="row">
                    <span>{{getDayOfWeek(row.item.booking_date)}} {{getDate(row.item.booking_date)}}</span>
                  </template>
                  <template v-slot:cell(booking_time)="row">
                    <span>{{getTime(row.item.start_time)}}</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mb-2" v-if="hasPermissions('customer_view')" href="" @click="viewCustomerDetails(row.item)">
                            <i class="tim-icons text-info icon-single-02 mr-2"></i> View customer details
                          </a>
                          <a class="dropdown-item mb-2" v-if="row.item.game_instance_id && hasPermissions('game_history_view')" href="" @click="viewGameInstance(row.item)">
                            <i style="color: #42b883" class="tim-icons icon-puzzle-10 mr-2"></i> View game details
                          </a>
                          <a
                            class="dropdown-item mb-2" v-if="hasPermissions('waivers_update')" @click="editWaiverFields(row.item)" href="#">
                            <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit waiver fields
                          </a>
                          <a
                            class="dropdown-item" v-if="hasPermissions('waivers_update')" @click="editWaiver(row.item)" href="#">
                            <i class="tim-icons el-icon-d-arrow-right text-default mr-2"></i> Move to another date and time
                          </a>
                          <hr v-if="hasPermissions('waivers_delete')">
                          <a
                            class="dropdown-item" v-if="hasPermissions('waivers_delete')"
                            @click="deleteWaiverPrompt(row.item)" href="#">
                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete waiver
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
                <b-pagination v-on:input="updateWaivers('b')"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </card>
        </div>

      </div>
      <!--Mobile-->
      <div class="row d-block d-sm-none">

        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div class="row justify-content-between">
              <div class="col">
                <h4 slot="header" class="card-title">Waivers</h4>
              </div>
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row" v-if="show_filters">
              <div
                class="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by last name</h3>
                      <div class="pop popover-body">


                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select
                              v-model="filters.game.value"
                              class="select-primary"
                              name="game"
                              placeholder="Game"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="game.name"
                                :key="game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by booking date range</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.booking_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.booking_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + '' + date_format_cal"
                              v-model="filters.booking_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_day, filters.booking_range)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateWaivers()">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Booking Date
                      <span v-if="filters.booking_day.actual">: within {{filters.booking_day.actual}} days</span>
                      <span v-if="filters.booking_range.actual_from">:
                        between {{filters.booking_range.actual_from}} and {{filters.booking_range.actual_to}}
                      </span>
                      &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <base-button simple size="sm" type="primary" v-on:click="getTodaysBookings()" class="ml-2">Today's Bookings</base-button>

                </base-input>
              </div>
            </div>

            <div v-if="noDataAvailable === true">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No data available.</h4>
              </div>
            </div>
            <div v-if="noDataAvailable === false" class="row">
              <div v-if="load" class="col-md-12 col-lg-12 col-sm-12 col-12">
                <b-table  striped hover :items="waivers"
                          :current-page="currentPage"
                          :fields="mobile_fields">
                  <template v-slot:head(waivers)="row">
                    <span>Total results: {{totalRows}}</span>
                  </template>
                  <template v-slot:cell(waivers)="row">
                    <div class="row justify-content-between">
                      <div class="col">
                        <h4 style="margin-bottom: 0" class="card-title">{{row.item.first_name}} {{row.item.last_name}}
                          <base-button v-if="hasPermissions('customer_view')"
                            @click.native="viewCustomerDetails(row.item)"
                            type="primary"
                            class="btn btn-link"
                            size="sm"
                            icon
                          ><i class="tim-icons el-icon-top-right"></i>
                          </base-button>
                        </h4>
                      </div>
                      <div class="col text-right">
                        <badge v-if="row.item.is_minor === 1" class="ml-2" type="primary">minor</badge>
                        <badge v-if="row.item.not_participating === 1" class="ml-2" type="danger">not participating</badge>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <small class="card-subtitle">{{row.item.email}}</small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <img :src="row.item.img"/>
                      </div>
                      <div class="col mt-2">
                        <p>{{row.item.game}}</p>
                        <div class="row">
                          <div class="col">
                            <i class="text-primary tim-icons icon-calendar-60"></i> {{getDate(row.item.booking_date)}}
                            <i class="ml-2 text-primary tim-icons icon-time-alarm"></i> {{getTime(row.item.start_time)}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="btn-group btn-group-toggle mt-3 mb-2"
                      :class="'float-left'"
                      data-toggle="buttons" style="width:100%"
                    >
                      <label v-if="hasPermissions('waivers_update')"
                             class="btn btn-sm btn-primary btn-simple"
                             style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="editWaiverFields(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </label>
                      <label v-if="hasPermissions('waivers_update')"
                        class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="editWaiver(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons el-icon-d-arrow-right"></i>
                        </base-button>
                      </label>
                      <label v-if="hasPermissions('game_history_view')"
                        class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="viewGameInstance(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-puzzle-10"></i>
                        </base-button>
                      </label>
                      <label v-if="hasPermissions('waivers_delete')"
                        class="btn btn-sm btn-primary btn-simple" style="padding-left: 10px; padding-right: 10px">
                        <base-button
                          @click.native="deleteWaiverPrompt(row.item)"
                          type="primary"
                          class="btn btn-link"
                          size="sm"
                          icon
                        ><i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </label>
                    </div>
                  </template>
                </b-table>
                <b-pagination v-on:input="updateWaivers('b')"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              :limit="3"
                ></b-pagination>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!--    Edit waiver details-->
      <modal :show.sync="modals.edit_waiver"
             body-classes="p-0"
             modal-classes="modal-dialog-centered">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">

          <h4 class="card-title">Edit waiver fields</h4>

          <div class="row">
            <div class="col">
              <base-checkbox v-model="selectedWaiver.not_participating">Not participating in the event</base-checkbox>
            </div>
          </div>


          <div class="row justify-content-center mt-3">
            <div class="col text-center">
              <base-button type="secondary" @click="modals.edit_waiver = false">Cancel</base-button>
              <base-button class="ml-3" type="primary" @click="updateWaiver()">Save</base-button>
            </div>
          </div>
        </card>
      </modal>

      <modal :show.sync="modals.bookings"
             body-classes="p-0"
             modal-classes="modal-dialog-centered">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">

          <h4 class="card-title">Booking slots</h4>

          <div class="row">
            <div class="col-8">
              <base-input style="border: 0; border-width:0px;">
                <el-date-picker
                  style="border: 0; border-width:0px;"
                  type="date"
                  :placeholder="current"
                  value-format="yyyy-MM-dd"
                  :format="day_of_week + '' + date_format_cal"
                  v-model="current"
                  @change="changeDate()"
                >
                </el-date-picker>
              </base-input>
            </div>
          </div>
          <!--   No data panel -->
          <div v-if="emptyBooking==true" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">There are no bookings on this day.</h4>
            </div>
          </div>


          <div v-if="emptyBooking==false" class="row" v-for="(b, i) in bookings" :key="'A' + i">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div
                    class="form-check form-check-radio form-check-in-table"
                  >
                    <label class="form-check-label">
                      <input
                        v-model="selectedBooking"
                        class="form-check-input"  :name="b.id" :value="b"
                        type="radio"
                      />
                      <slot></slot> <span class="form-check-sign"></span>
                      <span class="text-success ml-1" style="font-size: medium">
                      {{b.game_name}}</span>
                    </label>

                    <span class="ml-2 text-white"><i class="text-success tim-icons icon-calendar-60"></i>
               {{getDate(b.booking_date)}}</span>
                    <span class="ml-2 text-white"><i class="text-success tim-icons icon-time-alarm"></i>
               {{getTime(b.start_time)}}</span>&nbsp;
                    <badge v-if="b.status == 'available'" type="info">{{b.status}}</badge>
                    <badge v-if="b.status == 'booked'" type="success">{{b.status}}</badge>
                    <badge v-if="b.status == 'checked'" type="info">available</badge>
                    <badge v-if="b.status == 'completed'" type="warning">completed</badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-3">
            <div class="col text-center">
              <base-button type="secondary" @click="modals.bookings = false">Cancel</base-button>
              <base-button class="ml-3" type="primary" @click="updateWaiver()">Save</base-button>
            </div>
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  Popover,
  Select,
  Option,
  DatePicker,
  TimeSelect,
  TimePicker,
  Tooltip,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from 'element-ui';
  import { BaseCheckbox, BaseRadio } from 'src/components/index';
  import { Auth } from 'aws-amplify'
  import { BFormInput, BTable, BPagination, BIcon, BIconFilter, BIconArrowCounterclockwise } from 'bootstrap-vue'
  import { API_LOCATION } from '../../api-config'
  import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
  const axios = require('axios')
  import {time, formattedDate, dayOfWeek} from "@/plugins/dateFormatter";
  import Badge from "@/components/Badge";
  import swal from "sweetalert2";
  import _ from "lodash";

  export default {
    name: "Waivers",
    components: {
      Badge,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [TimeSelect.name]: TimeSelect,
      [TimePicker.name]: TimePicker,
      [Popover.name]: Popover,
      BFormInput,
      BIconFilter,
      BIconArrowCounterclockwise,
      BTable,
      BIcon,
      BPagination,
      BaseCheckbox,
      BaseRadio,
      VueTimepicker,
      Tooltip,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        feature_not_enabled: false,
        to: '',
        from: '',
        polling: null,
        loading: false,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        day_of_week: '',
        date_format_cal: '',
        noDataAvailable: false,
        load: false,
        show_filters: false,
        filters: {
          firstName: {
            value: '',
            actual: ''
          },
          lastName: {
            value: '',
            actual: ''
          },
          email: {
            value: '',
            actual: ''
          },
          game: {
            value: '',
            actual: ''
          },
          booking_day: {
            value: '',
            actual: '',
          },
          booking_range:{
            value_from: '',
            actual_from: '',
            value_to: '',
            actual_to: '',
          },
          today: false
        },
        waivers: [],
        perPage: Number(this.$route.query.count),
        currentPage: Number(this.$route.query.page),
        totalRows: 0,
        perPageOptions: [5, 10, 25, 50],
        selectedDateRangeOption: 'radio0',
        fields: ['name', 'email', 'game', 'booking_day' , 'booking_time', 'actions'],
        mobile_fields: ['waivers'],
        value: '',
        gameOptions: '',
        selectedGame: [],
        editing: [],
        selectedBooking: '',
        selectedWaiver: '',
        bookings: [],
        emptyBooking: false,
        current: '',
        todayTo: '',
        todayFrom: '',
        modals: {
          bookings: false,
          edit_waiver: false
        },
      };
    },
    methods: {
      goToBilling(){
        this.$router.push('/settings/billing')
      },
      editWaiverFields(item){
        this.selectedWaiver = { id: item.id, not_participating: item.not_participating }
        this.selectedBooking = { id: item.booking_slot_id }
        this.modals.edit_waiver = true
      },
      hasPermissions(scope){
        let permissions = JSON.parse(localStorage.getItem('permissions'))
        const matchFound = permissions.some(element => element === scope);
        return matchFound
      },
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      setToday(no_load){
        let current = new Date()
        this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
        this.getBookings(no_load)
      },
      getDate(d){
        return formattedDate(this.date_format, d)
      },
      getTime(d){
        return time(this.time_format, d)
      },
      getTodaysBookings(){
        this.filters.today = true
        let t = new Date()
        this.todayTo = t.getFullYear() + '-' + String(t.getMonth() + 1).padStart(2, '0') + '-' + String(t.getDate()).padStart(2, '0')
        this.todayFrom = t.getFullYear() + '-' + String(t.getMonth() + 1).padStart(2, '0') + '-' + String(t.getDate()).padStart(2, '0')
        this.updateWaivers()
      },
      getReversedTime(d){
        if(d.A == 'AM'){

          if(d.hh == 12){
            d.hh = '00'
          }
          return d.hh + ':' + d.mm
        } else {
          if(parseInt(d.hh) < 12){
            d.hh = parseInt(d.hh) + 12
          }
          return d.hh + ':' + d.mm
        }

      },
      async updateWaivers(source){
        //set first name
        this.filters.firstName.actual = this.filters.firstName.value

        //set last name
        this.filters.lastName.actual = this.filters.lastName.value

        //set email
        this.filters.email.actual = this.filters.email.value

        //set game
        this.filters.game.actual = this.filters.game.value

        // set booking date
        this.to = ''
        this.from = ''
        if (this.selectedDateRangeOption === 'radio0' && this.filters.booking_day.value !== ''){
          this.filters.booking_range.actual_from = ''
          this.filters.booking_range.actual_to = ''
          this.filters.booking_range.value_from = ''
          this.filters.booking_range.value_to = ''
          this.filters.booking_day.actual = this.filters.booking_day.value
          let today = new Date()
          let dt = new Date()
          dt.setDate(dt.getDate() - this.filters.booking_day.actual)
          let userStartDate = dt.getFullYear() + "-"  + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
          let userEndDate = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);


          let startLocalDate = new Date(`${userStartDate}`);
          let endLocalDate = new Date(`${userEndDate}`);

          this.from = startLocalDate.toISOString();
          this.to = endLocalDate.toISOString();

          this.filters.today = false
        }
        if (this.selectedDateRangeOption === 'radio1' && this.filters.booking_range.value_to !== '' && this.filters.booking_range.value_from !== ''){
          this.filters.booking_day.actual = ''
          this.filters.booking_day.value = ''
          this.filters.booking_range.actual_from = this.filters.booking_range.value_from
          this.filters.booking_range.actual_to = this.filters.booking_range.value_to

          let userStartDate = this.filters.booking_range.actual_from
          let userEndDate = this.filters.booking_range.actual_to


          let startLocalDate = new Date(`${userStartDate}`);
          let endLocalDate = new Date(`${userEndDate}`);

          this.from = startLocalDate.toISOString();
          this.to = endLocalDate.toISOString();

          this.filters.today = false
        }
        if(this.filters.today == true){
          this.filters.booking_range.actual_to = ''
          this.filters.booking_range.value_from = ''
          this.filters.booking_range.value_to = ''
          this.filters.booking_day.actual_from = ''
          this.filters.booking_day.actual = ''
          this.filters.booking_day.value = ''
          this.to = this.todayTo
          this.from = this.todayFrom

        }

        var count = this.perPage
        var offset = Number((this.currentPage * this.perPage) - this.perPage)

        await this.getWaivers(count, offset, this.filters.firstName.actual,
          this.filters.lastName.actual, this.filters.email.actual,
          this.filters.game.actual, this.to, this.from)
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGames () {
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.gameOptions = response.data
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async getWaivers (count, offset, firstName, lastName, email, game, to, from, no_load) {
        if(firstName === undefined){
          firstName = ''
        }
        if(lastName === undefined){
          lastName = ''
        }
        if(email === undefined){
          email = ''
        }
        if(game === undefined){
          game = ''
        }
        if(to === undefined){
          to = ''
        }
        if(from === undefined){
          from = ''
        }

        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers?count=' + count + "&offset=" + offset + "&first_name=" + encodeURIComponent(firstName) +
          "&last_name=" + encodeURIComponent(lastName) + "&email=" + encodeURIComponent(email) + "&game=" + encodeURIComponent(game) + "&to=" + to + "&from=" + from
        this.load = false
        await this.getWaiverURL(url, config, no_load)

        this.load = true
      },
      getWaiverURL(url, config, no_load){

        if(!no_load){
          this.loading = true
        }
          this.axios.get(url, config)
            .then(response => {
              this.loading = false
              if(this.waivers.length <=0){
                this.currentPage = Number(this.$route.query.page)
              }
              this.waivers = response.data.waivers
              this.setToday(no_load)

              if(this.waivers.length <= 0){
                this.noDataAvailable = true
              } else {
                this.noDataAvailable = false
              }
              this.totalRows = response.data.totalRows
              this.addQueryParameters()

            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else if(err.response.status == 500){
                this.feature_not_enabled = true
              }
              else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
      },
      resetField(field1, field2){
        if(field1){
          field1.value = ''
          field1.actual = ''
        }
        if(field2){
          field2.actual_from = ''
          field2.value_from = ''
          field2.actual_to = ''
          field2.value_to = ''
        }
        this.updateWaivers()
      },
      resetAll(){
        this.filters = {
          firstName: {
            value: '',
              actual: ''
          },
          lastName: {
            value: '',
              actual: ''
          },
          email: {
            value: '',
              actual: ''
          },
          game: {
            value: '',
              actual: ''
          },
          booking_day: {
            value: '',
              actual: '',
          },
          booking_range:{
            value_from: '',
              actual_from: '',
              value_to: '',
              actual_to: '',
          },
          today: false
        }
        this.updateWaivers()
      },
      escapeCSVValue(value) {
        if (typeof value === 'string') {
          // Escape double quotes by doubling them
          value = value.replace(/"/g, '""');

          // If the value contains a comma, wrap it in double quotes
          if (value.includes(',') || value.includes('\n')) {
            value = `"${value}"`;
          }
        }
        return value;
      },
      async downloadCSV(){
        //get the entire data set
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }

        this.loading = true
        const count = 5000
        const csvData = await this.getAllWaivers(config, count, this.filters.firstName.actual,
          this.filters.lastName.actual, this.filters.email.actual,
          this.filters.game.actual, this.to, this.from)
        this.loading = false

        let csv = 'First Name,Last Name,Email,Game,Booking Day,Booking Time\n';
        csvData.forEach(row => {
          csv += [this.escapeCSVValue(row.first_name), this.escapeCSVValue(row.last_name),
            this.escapeCSVValue(row.email), this.escapeCSVValue(row.game),
            this.getDate(row.booking_date.substring(0,10)),
            this.getTime(row.start_time.substring(0,5))]
          csv += "\n";
        });

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'waivers.csv';
        anchor.click();
      },
      async getAllWaivers(config, count, firstName, lastName, email, game, to, from){
        let url = ''
        let promises = []
        let csvData = []
        if(firstName === undefined){
          firstName = ''
        }
        if(lastName === undefined){
          lastName = ''
        }
        if(email === undefined){
          email = ''
        }
        if(game === undefined){
          game = ''
        }
        if(to === undefined){
          to = ''
        }
        if(from === undefined){
          from = ''
        }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id

        let offset = 0
        while(offset <= this.totalRows){

          url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers?count=' + count + "&offset=" + offset + "&first_name=" + encodeURIComponent(firstName) +
            "&last_name=" + encodeURIComponent(lastName) + "&email=" + encodeURIComponent(email) + "&game=" + encodeURIComponent(game) + "&to=" + to + "&from=" + from
          promises.push(
            axios.get(url, config)
              .then(response =>{
                csvData.push(response.data.waivers)
              })
              .catch(err => {
                  console.log(err)
                  if (err.response.status == 401) {
                    this.$router.push('/')
                  } else {
                    swal('Error!', 'Something went wrong', 'error')
                  }
                }
              )
          )
          offset = offset + count
        }

        await Promise.all(promises)

        // merge all response arrays into one
        let arr = []
        csvData.forEach((element) => {
          arr = arr.concat(element)

        })
        return arr
      },
      async deleteWaiver(item){
        this.loading = true
        var offset = parseInt((this.currentPage * this.perPage) - this.perPage)
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers/' + item.id

        axios.delete(url, config)
          .then(response => {
            this.loading = false
            this.getWaivers(this.perPage, offset)
            swal({
              title: 'Congratulations!',
              text: "The waiver has been deleted",
              type: 'success',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: "OK"
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.data == 'Not enabled'){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if (err.response.data == 'Waiver is part of a completed game'){
              swal({
                title: 'Ooops!',
                text: "You cannot delete a waiver for a completed or in-progress game",
                type: 'error'
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })

      },
      deleteWaiverPrompt(item){
        swal({
          title: 'Are you sure?',
          text: "The waiver will be permanently deleted",
          type: 'warning',
          confirmButtonText: "I'm sure"
        }).then((response)=> {
          if(response.value == true){
            this.deleteWaiver(item)
          }
        })
      },
      async updateWaiver(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers/' + this.selectedWaiver.id

        let data = {
          booking_slot_id: this.selectedBooking.id,
          not_participating: this.selectedWaiver.not_participating
        }

        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            this.selectedWaiver = ''
            this.selectedBooking = ''
            this.modals.bookings = false
            this.modals.edit_waiver = false

            this.getWaivers(this.perPage, this.getOffset())
            swal({
              title: 'Congratulations!',
              text: "The waiver has been updated",
              type: 'success',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: "OK"
            })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      editWaiver(item, index){
        this.selectedWaiver = item
        this.modals.bookings = true
      },
      viewGameInstance(waiver){
        this.$router.push({ path: '/games/history/instance', query: { id:  waiver.game_instance_id }})
      },
      viewCustomerDetails(waiver){
        this.$router.push({ path: '/customers/details', query: { id:  waiver.user_id }})
      },
      async getBookings(no_load){
        if(!no_load){
          this.loading = true
        }
        let token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
                '/bookings?day=' + this.current + '&type=2'

        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.bookings = response.data.bookings
            if(this.bookings.length <= 0){
              this.emptyBooking = true
            } else {
              this.emptyBooking = false
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      changeDate(){
        this.getBookings()
      },
      getOffset(){
        return Number((this.currentPage * this.perPage) - this.perPage)
      },
      addQueryParameters() {
        this.$nextTick(() => {
          this.currentPage = this.$route.query.page
        });
        const newQuery = {
          ...this.$route.query,
          count: this.perPage,
          page: this.currentPage,
          first_name: this.filters.firstName.value,
          last_name: this.filters.lastName.value,
          email: this.filters.email.value,
          game: this.filters.game.value,
          to: this.filters.booking_range.value_to,
          from: this.filters.booking_range.value_from,
          today: this.filters.today
        }
        this.$router.replace({query: newQuery}).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    mounted() {
      if(this.hasPermissions('waivers_update')
        || this.hasPermissions('waivers_delete')
        || this.hasPermissions('customer_view')){
        this.fields = ['name', 'email', 'game', 'booking_day' , 'booking_time', 'actions']
      } else {
        this.fields = ['name', 'email', 'game', 'booking_day' , 'booking_time']
      }
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      this.setToday()
      this.perPage = Number(this.$route.query.count)
      this.currentPage = Number(this.$route.query.page)
      this.filters = {
        firstName: {
          value: this.$route.query.first_name ? this.$route.query.first_name : '',
          actual: this.$route.query.first_name ? this.$route.query.first_name : ''
        },
        lastName: {
          value: this.$route.query.last_name ? this.$route.query.last_name : '',
          actual: this.$route.query.last_name ? this.$route.query.last_name : ''
        },
        email: {
          value: this.$route.query.email ? this.$route.query.email : '',
          actual: this.$route.query.email ? this.$route.query.email : ''
        },
        game: {
          value: this.$route.query.game ? this.$route.query.game : '',
          actual: this.$route.query.game ? this.$route.query.game : ''
        },
        booking_day: {
          value: '',
          actual: ''
        },
        booking_range:{
          value_from: this.$route.query.from ? this.$route.query.from : '',
          actual_from: this.$route.query.from ? this.$route.query.from : '',
          value_to: this.$route.query.to ? this.$route.query.to : '',
          actual_to: this.$route.query.to ? this.$route.query.to : '',
        },
        today: this.$route.query.today ? this.$route.query.today : '',
      }
      this.selectedDateRangeOption = 'radio1'
      this.updateWaivers()
      this.polling = setInterval(() => {
        this.getWaivers(this.perPage, this.getOffset(), this.filters.firstName.actual,
          this.filters.lastName.actual, this.filters.email.actual,
          this.filters.game.actual, this.to, this.from, 1)
      }, 60000);

      this.getGames()
    },
    beforeDestroy() {
      // Clear the interval when the component is destroyed
      clearInterval(this.polling);
    },
  };
</script>
<style scoped>
  .pagination-select{
    width: 100px;
  }
  .pop {
    max-width: 600px;
  }

  img {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 60px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    border-radius: 60px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    float: left;
    background-size: cover;
    background-position: center center;
  }
</style>
