<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--    Fonts-->
    <div v-for="(f, i) in fonts" :key="'C' + i">
      <link rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + f.family">
    </div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/surveys/view">Surveys</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{ breadcrumb_name }}</span>
      </breadcrumb-item>
    </breadcrumb>

    <div class="row">
      <div class="col-lg-4">
        <card>
          <div class="row">
            <div class="col-lg-8">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j" style="padding-left: 15px; padding-right: 15px"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i  :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="col text-right">
              <badge v-if="survey.active === 1" type="success">Active</badge>
              <badge v-if="survey.active === 0" type="danger">Inactive</badge>
              <el-dropdown>
                <base-button
                  link
                  type="primary"
                  size="sm"
                > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a class="dropdown-item mt-1 mb-2" target="_blank" :href="'https://offthecouch.io/surveys/' + survey.hash">
                      <i class="tim-icons text-info icon-link-72 mr-2"></i> Go to survey page
                    </a>
                    <a class="dropdown-item mt-1 mb-2" @click="modals.qr = true" href="#">
                      <i class="tim-icons text-default icon-cloud-download-93 mr-2"></i> Download QR code
                    </a>
                    <a class="dropdown-item" v-if="survey.active" @click="activateSurveyPrompt('deactivate')" href="#">
                      <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate survey
                    </a>
                    <a class="dropdown-item" v-if="!survey.active" @click="activateSurveyPrompt('activate')" href="#">
                      <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate survey
                    </a>
                    <hr>
                    <a class="dropdown-item" @click="deleteSurveyPrompt()" href="#">
                      <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete survey
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>

          <div v-if="activeTabIndex === 0">
            <div class="row mt-3">
              <div class="col">
                <base-input v-model="survey.name" placeholder="Survey name" label="Name"></base-input>
                <base-input label="Description">
                  <el-input
                    v-model="survey.description"
                    :rows="5"
                    type="textarea"
                    placeholder="Survey description"
                  />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>
                  <div>Redirect link
                    <el-popover trigger="hover"
                                placement="right">

                      <div>
                        <div class="popover-body">The URL customer will be redirected to after completing the survey.</div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>
                <base-input :error="errs.redirect_url" type="text" placeholder="http://example.com" v-model="survey.redirect_url"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-table :items="customer_fields" :fields="['customer_information_fields', 'included', 'required']">
                  <template v-slot:cell(customer_information_fields)="row">
                    <span>{{row.item.label}}</span>
                  </template>
                  <template v-slot:cell(included)="row">
                    <span v-if="row.item.id === 1">
                       <input :style="{ 'accent-color': '#e14eca'}" v-on:change="checkRequiredFields()" type="checkbox" v-model="survey.include_customer_first_name">
                    </span>
                    <span v-if="row.item.id === 2">
                       <input :style="{ 'accent-color': '#e14eca'}" v-on:change="checkRequiredFields()" type="checkbox" v-model="survey.include_customer_last_name">
                    </span>
                    <span v-if="row.item.id === 3">
                       <input :style="{ 'accent-color': '#e14eca'}" v-on:change="checkRequiredFields()" type="checkbox" v-model="survey.include_customer_email">
                    </span>
                    <span v-if="row.item.id === 4" >
                       <input :style="{ 'accent-color': '#e14eca'}" v-on:change="checkRequiredFields()" type="checkbox" v-model="survey.include_customer_phone">
                    </span>
                  </template>
                  <template v-slot:cell(required)="row">
                     <span v-if="row.item.id === 1">
                       <input :disabled="!survey.include_customer_first_name"
                              :style="{ 'accent-color': '#e14eca'}" type="checkbox" v-model="survey.require_customer_first_name">
                    </span>
                    <span v-if="row.item.id === 2">
                       <input :disabled="!survey.include_customer_last_name"
                              :style="{ 'accent-color': '#e14eca'}" type="checkbox" v-model="survey.require_customer_last_name">
                    </span>
                    <span v-if="row.item.id === 3">
                       <input :disabled="!survey.include_customer_email"
                              :style="{ 'accent-color': '#e14eca'}" type="checkbox" v-model="survey.require_customer_email">
                    </span>
                    <span v-if="row.item.id === 4">
                       <input :disabled="!survey.include_customer_phone"
                              :style="{ 'accent-color': '#e14eca'}" type="checkbox" v-model="survey.require_customer_phone">
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 1">
            <div class="row mt-3">
              <div class="col-8">
                <base-input label="Font" class="text-danger">
                  <el-select
                    v-model="survey.font_family"
                    class="select-primary"
                    :style="{'font-family': survey.font_family}"
                    name="font"
                    placeholder="Font"
                  >
                    <el-option
                      v-for="(f, i) in orderedFonts" :key="'C' + i"
                      :style="{'font-family': f.family}"
                      class="select-primary"
                      :label="f.family"
                      :value="f.family"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-4">
                <base-input label="Text color">
                  <el-color-picker v-model="survey.text_color" show-alpha></el-color-picker>
                </base-input>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-6">
                <base-input label="Card color">
                  <el-color-picker v-model="survey.card_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col-lg-4 col-6">
                <base-input label="Button color">
                  <el-color-picker v-model="survey.button_color" show-alpha></el-color-picker>
                </base-input>
              </div>
              <div class="col-lg-4 col-6">
                <base-input label="Background color">
                  <el-color-picker v-model="survey.background_color" show-alpha></el-color-picker>
                </base-input>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <base-button v-on:click="cancelCreate()" type="default" >
                Cancel</base-button>
              <base-button class="ml-2" v-on:click="updateSurvey()" type="primary" >
                Update</base-button>
            </div>

          </div>

        </card>
      </div>
      <div class="col">
        <card>
          <div class="row mb-3" v-if="!editing">
            <div class="col">
              <h4 class="card-title">Survey questions</h4>
            </div>
            <div class="col text-right">
              <base-button size="sm" v-on:click="addNewQuestionPrompt()" type="primary" >
                <i class="tim-icons el-icon-plus"></i>
                <span class="d-sm-block d-none">Add question</span>
              </base-button>
            </div>
          </div>
          <div v-if="questions.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
              <h4 class="mt-3">No questions have been created.</h4>
            </div>
          </div>
          <div class="row d-none d-sm-block">
            <div class="col">
              <table class='table' v-if="questions.length > 0">
                <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>Question</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Required</th>
                  <th class="text-right" scope='col'>Actions</th>
                </tr>
                </thead>
                <tbody tag='tbody' v-model='questions' is='draggable' >
                <tr v-on:dragend="saveOrder"  v-for='(item, index) in questions' :key="'A' + index + j" style="cursor: pointer">
                  <td scope='row'>
                    <i style="cursor: move" class="ml-3 tim-icons el-icon-rank"></i>
                  </td>
                  <td  scope='row' style="vertical-align: top">
                    <div >
                      <base-input label="What would you like to ask?" v-if="item.editing" placeholder="Did you like our game?" v-model="item.question"></base-input>
                      <span v-else>{{item.question}}</span>
                    </div>
                  </td>
                  <td scope='row' style="vertical-align: top">
                    <badge v-if="item.response_field_type === 1">checkbox</badge>
                    <badge v-if="item.response_field_type === 2">input</badge>
                    <badge v-if="item.response_field_type === 3">dropdown</badge>
                    <badge v-if="item.response_field_type === 4">rating</badge>
                  </td>
                  <td scope='row' style="vertical-align: top">
                    <badge v-if="item.active === 1" type="success">active</badge>
                    <badge v-if="item.active === 0" type="danger">deactivated</badge>
                  </td>
                  <td scope='row' style="vertical-align: top">
                    <span v-if="item.required"><i class="tim-icons text-success icon-check-2"></i></span>
                    <span v-if="!item.required"><i class="tim-icons text-danger icon-simple-remove"></i></span>
                  </td>
                  <td scope='row' class="text-right" style="vertical-align: top">
                    <el-dropdown>
                      <base-button
                        link
                        type="primary"
                        size="sm"
                      > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mt-1 mb-2" @click="editQuestion(item, index, $event)" href="#">
                            <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit field
                          </a>
                          <a class="dropdown-item" v-if="item.active" @click="activateSurveyQuestion(item, 0, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate field
                          </a>
                          <a class="dropdown-item" v-if="!item.active" @click="activateSurveyQuestion(item, 1, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate field
                          </a>
                          <hr>
                          <a class="dropdown-item" @click="deleteQuestion(item, $event)" href="#">
                            <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete field
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div class="row d-block d-sm-none">
            <div class="col">
              <table class='table' v-if="questions.length > 0">
                <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>Question</th>
                </tr>
                </thead>
                <draggable tag="tbody" v-model="questions" @end="saveOrder">
                <tr  v-for='(item, index) in questions' :key="'A' + index + j" style="cursor: pointer">
                  <td scope='row'>
                    <i style="cursor: move" class="ml-3 tim-icons el-icon-rank"></i>
                  </td>
                  <td  scope='row' style="vertical-align: top">
                    <div class="row mb-3">
                      <div class="col-8">
                        <span>{{item.question}}</span>
                      </div>
                      <div class="col text-right">
                        <el-dropdown>
                          <base-button
                            link
                            type="primary"
                            size="sm"
                          > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                          </base-button>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <a class="dropdown-item mt-1 mb-2" @click="editQuestion(item, index, $event)" href="#">
                                <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit field
                              </a>
                              <a class="dropdown-item" v-if="item.active" @click="activateSurveyQuestion(item, 0, $event)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate field
                              </a>
                              <a class="dropdown-item" v-if="!item.active" @click="activateSurveyQuestion(item, 1, $event)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate field
                              </a>
                              <hr>
                              <a class="dropdown-item" @click="deleteQuestion(item, $event)" href="#">
                                <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete field
                              </a>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <badge class="mr-2" v-if="item.response_field_type === 1">checkbox</badge>
                        <badge class="mr-2" v-if="item.response_field_type === 2">input</badge>
                        <badge class="mr-2" v-if="item.response_field_type === 3">dropdown</badge>
                        <badge class="mr-2" v-if="item.response_field_type === 4">rating</badge>
                        <badge class="mr-2" v-if="item.active === 1" type="success">active</badge>
                        <badge class="mr-2" v-if="item.active === 0" type="danger">deactivated</badge>
                        <badge v-if="item.required">required</badge>
                        <badge v-if="!item.required">not required</badge>
                      </div>
                    </div>
                  </td>
                </tr>
                </draggable>
              </table>
            </div>
          </div>
        </card>
      </div>
    </div>

<!--    Qr code-->
    <modal :show.sync="modals.qr"
           body-classes="p-0"
    >
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 class="card-title">QR code</h4>

        <div class="row mt-2 justify-content-center">
          <div class="col text-center">
            <qrcode-vue id="qr" :value="qr.value" :size="qr.size" level="H"></qrcode-vue>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <div class="col">
            <base-button type="secondary" @click="modals.qr = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="downloadQr()">
              <i class="tim-icons icon-cloud-download-93 mr-1"></i>Download</base-button>
          </div>
        </div>
      </card>
    </modal>

<!--    Create/edit field-->
    <modal :show.sync="modals.field"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg"
           >
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 v-if="selectedField.hasOwnProperty('id')" class="card-title">Edit field</h4>
        <h4 v-else class="card-title">Create field</h4>

        <div class="row">
          <div class="col">
            <base-input label="Field label" v-model="selectedField.question"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div>
              <base-input label="Field type" >
                <el-select
                  v-model="selectedField.type"
                  class="select-primary"
                  value-key="id"
                  v-on:change="adjustCustomFieldType(selectedField)"
                  placeholder="Type">
                  <el-option
                    v-for="(c, i) in field_types"
                    class="select-primary"
                    :label="c.name"
                    :value="{name: c.name, id: c.id}"
                    :key="'C' + i"
                  ></el-option>
                </el-select>
              </base-input>
              <!--Checkbox-->
              <div v-if="selectedField.type.hasOwnProperty('id') && selectedField.type.id === 1">
                <div class="row" v-for="(co, i) in selectedField.checkbox_options" :key="'CO' + i">
                  <div class="col">
                    <base-input v-model="co.label" :label="'Checkbox ' + (i+1)"></base-input>
                  </div>
                  <div class="col-1">
                    <i v-on:click="removeCheckboxOption(selectedField, co, i)"
                       style="cursor: pointer; margin-top: 35px; margin-left: -10px"
                       class="tim-icons icon-simple-remove text-danger"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button type="primary" size="sm" v-on:click="addCheckbox(selectedField)">
                      <i class="tim-icons el-icon-plus mr-1"></i> Add checkbox
                    </base-button>
                  </div>
                </div>
              </div>

              <!--Dropbox-->
              <div v-if="selectedField.type.hasOwnProperty('id') && selectedField.type.id === 3" :key="j">
                <div class="row" v-for="(d, i) in selectedField.dropdown_options" :key="'CO' + i">
                  <div class="col">
                    <base-input v-model="d.label" :label="'Option ' + (i+1)"></base-input>
                  </div>
                  <div class="col-1">
                    <el-tooltip content="Remove option"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-top: 28px; margin-left: -20px; margin-right: -20px" type="danger" icon link
                                   v-on:click="removeDropdownOption(selectedField, d, i)" size="sm">
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </el-tooltip>
                    <!--                            <i v-on:click="removeDropdownOption(item, d, i)"-->
                    <!--                               style="cursor: pointer; margin-top: 35px; margin-left: -10px"-->
                    <!--                               class="tim-icons icon-simple-remove text-danger"></i>-->
                    <el-tooltip content="Add follow up field"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-top: 28px; margin-left: 10px; margin-right: -30px" type="success" icon link v-if="!d.has_follow_up_field"
                                   v-on:click="addFollowUpField(d)" size="sm">
                        <i class="tim-icons icon-simple-add"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                  <div class="col" v-if="d.has_follow_up_field">
                    <base-input :label="'Follow up field ' + (i+1)" v-model="d.follow_up"></base-input>
                  </div>
                  <div class="col-lg-1" v-if="d.has_follow_up_field">
                    <el-tooltip content="Remove follow up field"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button style="margin-top: 28px; margin-left: -20px; margin-right: -20px" type="danger" icon link
                                   v-on:click="removeFollowUpField(d)" size="sm">
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button type="primary" size="sm" v-on:click="addDropdownOption(selectedField)">
                      <i class="tim-icons el-icon-plus mr-1"></i> Add dropdown option
                    </base-button>
                  </div>
                </div>
              </div>

              <!--                      Rating-->
              <div v-if="selectedField.type.hasOwnProperty('id') && selectedField.type.id === 4">
                <div class="row" v-for="(ro, i) in selectedField.rating_options" :key="'RO' + i">
                  <div class="col-lg-12">
                    <base-input label="Difficulty icon">
                      <el-select
                        v-model="ro.icon"
                        class="select-primary"
                        name="email_group"
                      >
                        <el-option
                          v-for="(icon, index) in icon_options"
                          class="select-primary"
                          :label="icon"
                          :value="icon"
                          :key="'I' + index"
                        >
                                  <span style="float: left">
                                     <b-icon :icon="icon" scale="1" class="mr-1" color="#e14eca"></b-icon>
                                    <!--                        <i :class="'text-primary tim-icons ' + icon "></i>-->
                                    {{ capitalizeFirstLetter(icon) }}</span>
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-3">
                    <base-input label="Color">
                      <el-color-picker v-model="ro.color" show-alpha></el-color-picker>
                    </base-input>
                  </div>
                  <div class="col">
                    <base-input v-model="ro.count" label="Number of stars" type="number">
                    </base-input>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <el-switch active-color="#e14eca"
                       v-model="selectedField.required"  />
            <label>&nbsp; Required</label>
          </div>
        </div>

        <div class="row text-center mt-3">
          <div class="col">
            <base-button type="secondary" @click="modals.field = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="saveQuestion(selectedField)">
              Save</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import draggable from "vuedraggable";
import {Auth} from "aws-amplify";
import {API_LOCATION, WAIVER_URL} from "@/api-config";
import swal from "sweetalert2";
import Badge from "@/components/Badge";
import {ColorPicker, Dropdown, DropdownItem, DropdownMenu, Switch} from 'element-ui';
import axios from "axios";
import _ from "lodash";
import {BFormRating, BIcon, BTable} from "bootstrap-vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "EditSurvey",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    draggable,
    Badge,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [ColorPicker.name]: ColorPicker,
    [Switch.name]: Switch,
    BFormRating,
    BIcon,
    BTable,
    QrcodeVue
  },
  computed: {
    categories() {
      return [{name: 'Settings', icon: 'tim-icons icon-settings-gear-63', active: true},
        {name: 'Appearance', icon: 'tim-icons icon-palette', active: false},
      ];
    },
    icon_options(){
      let arr = ['star', 'heart', 'alarm', 'award', 'bag', 'bell', 'diamond', 'dice3', 'dice5', 'droplet', 'egg', 'pencil',
        'emoji-smile', 'emoji-heart-eyes', 'emoji-sunglasses', 'envelope', 'exclamation-triangle', 'eye', 'gift', 'gear', 'hand-thumbs-up',
        'book', 'bookmark', 'bug', 'calculator', 'clock', 'cloud', 'cpu', 'handbag', 'hexagon', 'lightning', 'lock',
        'camera', 'camera-reels', 'chat', 'check-circle', 'circle', 'plug', 'puzzle', 'question-circle', 'shield-lock', 'signpost',
        'suit-club', 'suit-diamond', 'suit-heart', 'suit-spade', 'stopwatch', 'tag', 'telephone', 'tree', 'trophy', 'unlock',
      ]
      return arr.sort()
    },
    orderedFonts: function () {
      _.remove(this.fonts, {
        family: 'Libre Baskerville'
      });
      _.remove(this.fonts, {
        family: 'Material Icons'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Outlined'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Sharp'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Round'
      });
      _.remove(this.fonts, {
        family: 'M PLUS Rounded 1c'
      });
      return _.orderBy(this.fonts, 'family')
    },
  },
  data() {
    return {
      editing: false,
      modals: {
        qr: false,
        field: false
      },
      errs:{
        redirect_url: ''
      },
      fonts: [],
      customer_fields: [
        {id: 1, label: 'First name', required: false, included: false},
        {id: 2, label: 'Last name', required: false, included: false},
        {id: 3, label: 'Email', required: false, included: false},
        {id: 4, label: 'Phone', required: false, included: false},
      ],
      selectedField: {
        type: {id: 1, type: 'Checkbox'},
        label: '',
        editing: true,
        is_active: false,
        required: 0,
        checkbox_options: [{label : ''}],
        removed_checkbox_options: [],
        rating_options: [{count: 10, icon: 'star', color: '#e14eca', value: 10}],
        dropdown_options: [{label: '', follow_up: ''}],
        removed_dropdown_options: []
      },
      survey: { name: '',
        description: '',
        anonymous_survey: false,
        require_email: true
      },
      breadcrumb_name: '',
      questions: [],
      field_types: [],
      loading: false,
      j: 0,
      activeTabIndex: 0,
      qr: {
        value: 'https://example.com',
        size: 300
      },
      temp_state: {
        question: '',
        type: '',
        required: 0,
        id: ''
      }
    }
  },
  methods: {
    generateQrCode(){
      this.qr.value = 'https://offthecouch.io/surveys/' + this.survey.hash
    },
    downloadQr(){
      var link = document.createElement('a');
      link.download = 'qr.png';
      console.log(document.getElementsByTagName("canvas"))
      link.href = document.getElementsByTagName('canvas')[0].toDataURL()
      link.click();
    },
    checkRequiredFields(){
      if(!this.survey.include_customer_first_name){
        this.survey.require_customer_first_name = false
      }
      if(!this.survey.include_customer_last_name){
        this.survey.require_customer_last_name = false
      }
      if(!this.survey.include_customer_phone){
        this.survey.require_customer_phone = false
      }
      if(!this.survey.include_customer_email){
        this.survey.require_customer_email = false
      }
    },
    addFollowUpField(field){
      console.log(field)
      field.has_follow_up_field = true
      this.j++
    },
    removeFollowUpField(field){
      console.log(field)
      field.has_follow_up_field = false
      this.j++
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    adjustCustomFieldType(item){
      console.log(item)
      if(item.type.id === 1 && !item.checkbox_options){
        item.checkbox_options = [{label: ''}]
        item.removed_checkbox_options = []
      }
      if(item.type.id === 3 && !item.dropdown_options){
        item.dropdown_options = [{label: '', follow_up: ''}]
        item.removed_dropdown_options = []
      }
      this.j++
    },
    async saveOrder(event){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/surveys/' + this.survey.id + '/questions'

      let data = {
        questions:  this.questions,
      }

      axios.put(url, data, config)
        .then(response => {
          this.getSurvey()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    addDropdownOption(item){
      item.dropdown_options.push({label: '', follow_up: ''})
    },
    addCheckbox(item){
      item.checkbox_options.push({label: ''})
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    editQuestion(item, index, event){
      event.preventDefault()
      console.log(item)
      // this.temp_state =  {
      //   active: item.active,
      //   question: item.question,
      //   type: item.type,
      //   response_field_type: item.type.id,
      //   required: item.required,
      //   id: item.id
      // }
      this.selectedField = JSON.parse(JSON.stringify(item))
      this.modals.field = true
    },
    async saveQuestion(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/surveys/' + this.survey.id + '/questions'

      let data = {
        id: item.id,
        question:  item.question,
        type: item.type,
        position: item.hasOwnProperty('position') ? item.position : this.questions.length,
        required: item.required,
        checkbox_options: item.checkbox_options,
        removed_checkbox_options: item.removed_checkbox_options,
        rating_options: item.rating_options,
        dropdown_options: item.dropdown_options,
        removed_dropdown_options: item.removed_dropdown_options
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.field = false
          let text = 'Question has been created'
          if(item.id){
            text = 'Question has been updated'
          }
          swal.fire({
            title: 'Success!',
            text: text,
            type: 'success'
          })
          this.getSurvey()
        }).catch((err) => {
        console.log(err)
        this.loading = false

        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    removeDropdownOption(item, option, index){
      if(option.hasOwnProperty('id')){
        item.removed_dropdown_options.push(option)
      }
      item.dropdown_options.splice(index, 1);
    },
    removeCheckboxOption(item, option, index){
      if(option.hasOwnProperty('id')){
        item.removed_checkbox_options.push(option)
      }
      item.checkbox_options.splice(index, 1);
    },
    async deleteQuestion(item, event){
      event.preventDefault()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/surveys/' + this.survey.id + '/questions/' + item.id

      axios.delete(url, config)
        .then(response => {
          item.editing = false
          swal.fire({
            title: 'Success!',
            text: 'Question has been deleted',
            type: 'success'
          })
          this.getSurvey()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    addNewQuestionPrompt(){
      this.modals.field = true
      this.selectedField = {
        type: {id: 1, type: 'Checkbox'},
        label: '',
        editing: true,
        is_active: false,
        required: 0,
        checkbox_options: [{label : ''}],
        removed_checkbox_options: [],
        rating_options: [{count: 10, icon: 'star', color: '#e14eca', value: 10}],
        dropdown_options: [{label: '', follow_up: ''}],
        removed_dropdown_options: []
      }
      // this.questions.push({
      //   type: {id: 1, type: 'Checkbox'},
      //   label: '',
      //   editing: true,
      //   is_active: false,
      //   required: 0,
      //   checkbox_options: [{label : ''}],
      //   removed_checkbox_options: [],
      //   rating_options: [{count: 10, icon: 'star', color: '#e14eca', value: 10}],
      //   dropdown_options: [{label: '', follow_up: ''}],
      //   removed_dropdown_options: []
      // })
    },
    activateSurveyPrompt(action){
      let html = ''
      if(action === 'deactivate'){
        html = 'Deactivating the survey will make it inaccessible to customers.'
      }
      else if (action === 'activate'){
        html = 'Activating the survey will make it accessible to customers.'
      }

      swal.fire({
        title: 'Are you sure?',
        text: html,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.activateSurvey(action);
        }
      })

    },
    async activateSurvey(action){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let groupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + groupId + '/surveys/' + this.survey.id + '/activate'

      let data = {
        active:  action === 'activate' ? 1 : 0,
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal.fire({
            title: 'Success!',
            text: `Survey has been ` + action + `d`,
            type: 'success',
          })
          this.getSurvey()

        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          swal({
            title: 'Error',
            html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else if(err.response.status == 500){
          swal({
            title: 'Error',
            html: 'The feature has not been enabled as part of your subscription plan.',
            type: 'error',
          }).then((response)=> {
            this.$router.push('/')
          })
        }
        else {
          swal('Error!', 'Something went wrong', 'error')
        }
      })
    },
    deleteSurveyPrompt(){
      swal({
        title: 'Are you sure?',
        text: `The survey will be permanently deleted`,
        showCancelButton: true,
        type: 'warning',
      }).then((response)=> {
        if(response.value == true){
          this.deleteSurvey()
        }
      })
    },
    async deleteSurvey(){
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + this.survey.id
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'The survey has been deleted', 'success')
          this.$router.push('/surveys/view')
        })
        .catch(err => {
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    cancelCreate() {
      this.$router.push('/surveys/view')
    },

    async updateSurvey(){

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId
      let data = {
        survey: this.survey,
        questions: this.questions
      }

      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success', 'Survey has been updated', 'success')
          this.getSurvey()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async activateSurveyQuestion(item, active, event){
      event.preventDefault()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId + '/questions/' + item.id
      let data = {
        active: active
      }

      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success', 'Question has been activated', 'success')
          this.getSurvey()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getSurvey(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let surveyId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/surveys/' + surveyId

      this.axios.get(url, config)
        .then(response => {
          this.survey = response.data.survey
          this.breadcrumb_name = this.survey.name
          this.field_types = response.data.field_types
          this.questions = response.data.questions
          for(let n in this.questions){
            this.questions[n].required = Boolean(this.questions[n].required)
            this.questions[n].type = _.find(this.field_types, {id: this.questions[n].response_field_type})
            if(this.questions[n].checkbox_options){
              this.questions[n].checkbox_options = _.orderBy(JSON.parse(this.questions[n].checkbox_options), 'id')
              this.questions[n].removed_checkbox_options = []
            }
            if(this.questions[n].rating_options){
              this.questions[n].rating_options = JSON.parse(this.questions[n].rating_options)
            }
            if(this.questions[n].dropdown_options){
              this.questions[n].dropdown_options = _.orderBy(JSON.parse(this.questions[n].dropdown_options), 'id')
              this.questions[n].removed_dropdown_options = []
            }
          }
          this.loading = false
          this.generateQrCode()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getFonts(){
      let url = API_LOCATION + 'fonts'

      axios.get(url)
        .then(response => {
          this.fonts = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getFonts()
    this.getSurvey()
  }
}
</script>

<style scoped>
</style>
